import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  width: 100%;
  line-height: normal;
  text-align: left;
  font-family: Roboto;
  font-size: 14px;
  color: green;
  margin: 0px 16px 16px 16px;
`;

interface IProps {
  success?: string;
  active: boolean | undefined;
  className?: string | undefined;
  directSet?: boolean;
  refresh?: () => void;
  translateID?: string;
}

interface IState {
  active?: boolean;
  success?: string;
  className: string | undefined;
}

export class FormSuccess extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      success: props.success,
      className: props.className,
      active: props.active,
    };
  }

  UNSAFE_componentWillReceiveProps(props: IProps) {
    if (this.state.success !== props.success) {
      this.setState({ success: props.success });
    }
    if (this.state.active !== props.active) {
      this.setState({
        active: props.active,
      });
    }
  }

  render() {
    if (this.state.active !== true || this.state.success == null) {
      return null;
    }
    return <StyledDiv>{this.state.success}</StyledDiv>;
  }
}
