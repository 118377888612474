import React, { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Title from '../../../../../components/compositcomponents/title';
import { Account, api, apis, ApiError, Ident } from '../../../../../logic/api';
import { Log, Logs } from '../../../../../logic/log';
import { Image } from '../../../../../components/atomiccompoents/image';
import KeyTextComponent from '../../../../../components/atomiccompoents/keyTextComponent';
import { format } from '../../../../../logic/helper/format';
import { coalesce, evaluateErrorMessage } from '../../../../../logic/helper/Common';
import { getCountry } from '../../../../../common/language/getCountry';
import { translate } from '../../../../../common/language/translate';
import { BigInput } from '../../../../../components/atomiccompoents/form/inputs.css';
import ButtonCancel from '../../../../../components/atomiccompoents/buttons/buttonCancel';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import {
  ImageCircle,
  StyledViewButtons,
} from '../../customers/basicStyledComponents/customerDetails.css';
import {
  OverlayHandler,
  Overlays,
} from '../../../../../logic/handler/overlayhandler/overlayHandler';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../../logic/handler/messagehandler/messageHandlerConfig';

const Main = styled.div`
    display: flex,
    flex-direction: column;
    align-items: center;
`;

const TestTypeBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px;
  align-items: center;
`;

const Name = styled.div`
  font-weight: 500;
  text-align: center;
  font-size: 18px;
  color: ${props => props.theme.Global.primaryColor};
`;

const TestKitBox = styled.div`
  font-size: 24px;
  font-weight: 550;
  text-align: center;
  margin-top: 16px;
`;

const InputBox = styled(TestTypeBox)`
  width: 60%;
`;

const DataBox = styled.div`
  display: flex;
  width: 70%;
`;

const KeyBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin: ;
`;

const ImageBox = styled.div`
  width: 25%;
  margin: 16px;
  display: flex;
  justify-content: center;
`;

interface IProps {
  person: Ident.Person;
  testKit?: Account.ProductTypeOut;
  room: Account.EyesonRecording;
  passportImage?: Blob;
  callback: () => void;
  videoApproval: Account.EyesonRecordingPutRequest;
  details: Account.ProductOrderDetails;
}

export const TestResultComponent: FunctionComponent<IProps> = (props: IProps) => {
  const [data, setData] = useState<{ details?: Account.ProductOrderDetails }>({});
  // const [passport, setPassport]   = useState<Ident.Passport | undefined>(undefined);
  const [comment, setComment] = useState<string | undefined>('');
  const [keyForSpinner, setKeyForSpinner] = useState<number>(
    Math.floor(Math.random() * 10000000),
  );

  async function fetchMissingData() {
    try {
      const detailsresponse = await api.asyncRequest<Account.ProductOrderDetails>(
        { ta_id: props.room.ta_id, transaction_id: props.room.transaction_id },
        apis.DefaultApi,
        'productOrderGet',
      );
      data.details = detailsresponse;
      setData({ ...data });
    } catch (error) {
      Log.error(Logs.API, error);
      setData({ ...data });
    }
  }

  useEffect(() => {
    fetchMissingData();
  }, []);

  function setUpTestResult(res: Account.EyesonRecordingValidationTestResultEnum) {
    OverlayHandler.showOverlay(Overlays.ConfirmationRequestOverlay, {
      confirm: (callback: (success: boolean, close?: boolean) => void) => {
        const videoReq = props.videoApproval;
        if (videoReq.EyesonRecordingValidation != null) {
          videoReq.EyesonRecordingValidation.comment = comment;
          videoReq.EyesonRecordingValidation.test_result = res;
        }
        api
          .asyncRequest(videoReq, apis.EyesonApi, 'eyesonRecordingPut')
          .then(() => {
            MessageHandler.onSuccess(Reporter['videoqueue.approve.result.put']);
            props.callback();
            callback(true, true);
          })
          .catch((error: ApiError) => {
            Log.error(Logs.API, error);
            MessageHandler.onError(
              Reporter['videoqueue.approve.result.put'],
              evaluateErrorMessage(error, true),
              evaluateErrorMessage(error, false),
            );
            callback(false, true);
          });
      },
      onClose: () => {
        setKeyForSpinner(Math.floor(Math.random() * 10000000));
      },
      heading: translate('service_center.overlay.title'),
      message: translate('service_center.overlay.text'),
    });
    setKeyForSpinner(Math.floor(Math.random() * 10000000));
  }
  console.log(props.person.nationality, getCountry(props.person.nationality));
  return (
    <Main>
      <Title title="Testresult" />
      <TestTypeBox>
        <div style={{ display: 'flex', width: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '70%',
              margin: '16px',
            }}
          >
            <Name>{props.person.given_name + ' ' + props.person.name}</Name>
            <DataBox style={{ width: '100%' }}>
              <KeyBox>
                <KeyTextComponent
                  key="testtype"
                  translationkey={'service_center.testtype'}
                  value={coalesce('not set', data.details?.statement_text)}
                />
                <KeyTextComponent
                  key="datetime"
                  translationkey={'service_center.testTime'}
                  value={format.datetime(data.details?.datetime_sampletaking)}
                />
                <KeyTextComponent
                  key="birthday"
                  translationkey={'customers.birthdate'}
                  value={format.date(props.person.birth_date)}
                />
                <KeyTextComponent
                  key="address"
                  translationkey={'customers.search.input.address.label'}
                  value={data.details?.customer_address}
                />
                <KeyTextComponent
                  key="docNr"
                  translationkey={'customers.documentNumber'}
                  value={data.details?.passport_number ?? 'not set'}
                />
                <KeyTextComponent
                  key="nationality"
                  translationkey={'input.nationality.label'}
                  value={getCountry(data.details?.nationality)}
                />
              </KeyBox>
            </DataBox>
          </div>
          <ImageBox>
            {props.passportImage != null ? (
              <Image image={props.passportImage} />
            ) : (
              <ImageCircle>
                {props.person.given_name != null && props.person.name != null
                  ? props.person.given_name?.substring(0, 1) +
                    props.person.name?.substring(0, 1)
                  : ''}
              </ImageCircle>
            )}
          </ImageBox>
        </div>
      </TestTypeBox>
      <TestTypeBox>
        <InputBox>
          <TestKitBox>
            {coalesce('No Manufactor set', props.testKit?.manufacturer_name) +
              ': ' +
              coalesce('no Testkit set', props.testKit?.commercial_name)}
          </TestKitBox>
          <BigInput
            placeHolder={translate('service_center.comment.label')}
            label={translate('service_center.comment.placeholder')}
            type="text"
            id="comment"
            valueFromState={true}
            value={comment}
            notification={(n: any) => {
              setComment(n.comment.value);
            }}
          />
        </InputBox>
      </TestTypeBox>
      <TestTypeBox>
        <StyledViewButtons style={{ justifyContent: 'center', width: '60%' }}>
          <ButtonCancel
            key={3 * keyForSpinner}
            style={{ margin: 'auto' }}
            id="btnVoid"
            onClick={(event: SyntheticEvent) => {
              event.stopPropagation();
              event.preventDefault();
              setUpTestResult(Account.EyesonRecordingValidationTestResultEnum.Void);
            }}
          >
            {translate('button.void')}
          </ButtonCancel>
          <ButtonOk
            key={2 * keyForSpinner}
            style={{ margin: 'auto' }}
            onClick={(event: SyntheticEvent) => {
              event.stopPropagation();
              event.preventDefault();
              setUpTestResult(Account.EyesonRecordingValidationTestResultEnum.Negative);
            }}
          >
            {' '}
            {translate('button.negative')}
          </ButtonOk>
          <ButtonOk
            style={{ margin: 'auto' }}
            key={keyForSpinner}
            onClick={(event: SyntheticEvent) => {
              event.stopPropagation();
              event.preventDefault();
              setUpTestResult(Account.EyesonRecordingValidationTestResultEnum.Positive);
            }}
          >
            {' '}
            {translate('button.positive')}
          </ButtonOk>
        </StyledViewButtons>
      </TestTypeBox>
    </Main>
  );
};
