// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
  NewLocationPerson,
  NewUnregisteredLocationPerson,
  OpeningTimesAllWeek,
  PersonDocumentList,
  PhoneNumberList,
  ReplPassport,
  ReplPerson,
} from '../models/index';

export interface AccountAccountNumberPersonGetRequest {
  account_number: string;
}

export interface ReplicationMerchantUnregisteredPutRequest {
  merchant_account_number: string;
  person_id: number;
  token: string;
  NewUnregisteredLocationPerson: NewUnregisteredLocationPerson;
}

export interface ReplicationMobileTagUnregisteredPutRequest {
  link: string;
  token: string;
  NewLocationPerson: NewLocationPerson;
}

export interface ReplicationPassportPostRequest {
  token: string;
  passport_id: number;
  ReplPassport: ReplPassport;
}

export interface ReplicationPersonDocumentsPutRequest {
  token: string;
  person_id: number;
  PersonDocumentList: PersonDocumentList;
}

export interface ReplicationPersonPutRequest {
  token: string;
  person_id: number;
  ReplPerson: ReplPerson;
}

export interface ReplicationPharmacyOpeningTimesPutRequest {
  token: string;
  pharmacy_id: string;
  OpeningTimesAllWeek: OpeningTimesAllWeek;
}

export interface ReplicationPhoneNumbersPutRequest {
  token: string;
  person_id: number;
  PhoneNumberList: PhoneNumberList;
}

/**
 * no description
 */
export class ReplicationApi extends BaseAPI implements IOperationIdMap {
  /**
   * get all persons that belong to an existing account-nr
   * show persons for an account
   */
  accountAccountNumberPersonGet = (
    requestParameters: AccountAccountNumberPersonGetRequest,
  ): Observable<Array<number>> => {
    throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberPersonGet');

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<Array<number>>({
      path: '/account/{account_number}/personlist'.replace(
        '{account_number}',
        encodeURI(requestParameters.account_number),
      ),
      method: 'GET',
      headers,
    });
  };

  /**
   * register an un registered person at location
   */
  replicationMerchantUnregisteredPut = (
    requestParameters: ReplicationMerchantUnregisteredPutRequest,
  ): Observable<void> => {
    throwIfRequired(
      requestParameters,
      'merchant_account_number',
      'replicationMerchantUnregisteredPut',
    );
    throwIfRequired(requestParameters, 'person_id', 'replicationMerchantUnregisteredPut');
    throwIfRequired(requestParameters, 'token', 'replicationMerchantUnregisteredPut');
    throwIfRequired(
      requestParameters,
      'NewUnregisteredLocationPerson',
      'replicationMerchantUnregisteredPut',
    );

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    const query: HttpQuery = {
      ...(requestParameters.merchant_account_number && {
        merchant_account_number: requestParameters.merchant_account_number,
      }),
      ...(requestParameters.person_id && { person_id: requestParameters.person_id }),
      ...(requestParameters.token && { token: requestParameters.token }),
    };

    return this.request<void>({
      path: '/replication/merchant_unregistered',
      method: 'PUT',
      headers,
      query,
      body: requestParameters.NewUnregisteredLocationPerson,
    });
  };

  /**
   * used to replicate merchants transactions
   * register client with merchant mobile tag
   */
  replicationMobileTagUnregisteredPut = (
    requestParameters: ReplicationMobileTagUnregisteredPutRequest,
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'link', 'replicationMobileTagUnregisteredPut');
    throwIfRequired(requestParameters, 'token', 'replicationMobileTagUnregisteredPut');
    throwIfRequired(
      requestParameters,
      'NewLocationPerson',
      'replicationMobileTagUnregisteredPut',
    );

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    const query: HttpQuery = {
      ...(requestParameters.link && { link: requestParameters.link }),
      ...(requestParameters.token && { token: requestParameters.token }),
    };

    return this.request<void>({
      path: '/replication/mobile_tag',
      method: 'PUT',
      headers,
      query,
      body: requestParameters.NewLocationPerson,
    });
  };

  /**
   * update the persons passport data
   */
  replicationPassportPost = (
    requestParameters: ReplicationPassportPostRequest,
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'token', 'replicationPassportPost');
    throwIfRequired(requestParameters, 'passport_id', 'replicationPassportPost');
    throwIfRequired(requestParameters, 'ReplPassport', 'replicationPassportPost');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    const query: HttpQuery = {
      ...(requestParameters.token && { token: requestParameters.token }),
      ...(requestParameters.passport_id && { passport_id: requestParameters.passport_id }),
    };

    return this.request<void>({
      path: '/replication/passport',
      method: 'POST',
      headers,
      query,
      body: requestParameters.ReplPassport,
    });
  };

  /**
   * update persons documents
   */
  replicationPersonDocumentsPut = (
    requestParameters: ReplicationPersonDocumentsPutRequest,
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'token', 'replicationPersonDocumentsPut');
    throwIfRequired(requestParameters, 'person_id', 'replicationPersonDocumentsPut');
    throwIfRequired(requestParameters, 'PersonDocumentList', 'replicationPersonDocumentsPut');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    const query: HttpQuery = {
      ...(requestParameters.token && { token: requestParameters.token }),
      ...(requestParameters.person_id && { person_id: requestParameters.person_id }),
    };

    return this.request<void>({
      path: '/replication/person_documents',
      method: 'PUT',
      headers,
      query,
      body: requestParameters.PersonDocumentList,
    });
  };

  /**
   * update the persons data
   */
  replicationPersonPut = (
    requestParameters: ReplicationPersonPutRequest,
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'token', 'replicationPersonPut');
    throwIfRequired(requestParameters, 'person_id', 'replicationPersonPut');
    throwIfRequired(requestParameters, 'ReplPerson', 'replicationPersonPut');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    const query: HttpQuery = {
      ...(requestParameters.token && { token: requestParameters.token }),
      ...(requestParameters.person_id && { person_id: requestParameters.person_id }),
    };

    return this.request<void>({
      path: '/replication/person',
      method: 'PUT',
      headers,
      query,
      body: requestParameters.ReplPerson,
    });
  };

  /**
   * update the pharmacies open data
   */
  replicationPharmacyOpeningTimesPut = (
    requestParameters: ReplicationPharmacyOpeningTimesPutRequest,
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'token', 'replicationPharmacyOpeningTimesPut');
    throwIfRequired(requestParameters, 'pharmacy_id', 'replicationPharmacyOpeningTimesPut');
    throwIfRequired(
      requestParameters,
      'OpeningTimesAllWeek',
      'replicationPharmacyOpeningTimesPut',
    );

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    const query: HttpQuery = {
      ...(requestParameters.token && { token: requestParameters.token }),
      ...(requestParameters.pharmacy_id && { pharmacy_id: requestParameters.pharmacy_id }),
    };

    return this.request<void>({
      path: '/replication/pharmacy/opening_times',
      method: 'PUT',
      headers,
      query,
      body: requestParameters.OpeningTimesAllWeek,
    });
  };

  /**
   * update the phone numbers of a person
   */
  replicationPhoneNumbersPut = (
    requestParameters: ReplicationPhoneNumbersPutRequest,
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'token', 'replicationPhoneNumbersPut');
    throwIfRequired(requestParameters, 'person_id', 'replicationPhoneNumbersPut');
    throwIfRequired(requestParameters, 'PhoneNumberList', 'replicationPhoneNumbersPut');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    const query: HttpQuery = {
      ...(requestParameters.token && { token: requestParameters.token }),
      ...(requestParameters.person_id && { person_id: requestParameters.person_id }),
    };

    return this.request<void>({
      path: '/replication/phone_numbers',
      method: 'PUT',
      headers,
      query,
      body: requestParameters.PhoneNumberList,
    });
  };

  operationToOperationId = {
    accountAccountNumberPersonGet: 'account_account_number_person_get',
    replicationMerchantUnregisteredPut: 'replication_merchant_unregistered_put',
    replicationMobileTagUnregisteredPut: 'replication_mobile_tag_unregistered_put',
    replicationPassportPost: 'replication_passport_post',
    replicationPersonDocumentsPut: 'replication_person_documents_put',
    replicationPersonPut: 'replication_person_put',
    replicationPharmacyOpeningTimesPut: 'replication_pharmacy_opening_times_put',
    replicationPhoneNumbersPut: 'replication_phone_numbers_put',
  };
}
