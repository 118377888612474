import React from 'react';
import styled from 'styled-components';

interface IProps {
  text?: string;
  x: number;
  y: number;
}

interface IState {}

export default class Tooltip extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <StyledDiv x={this.props.x} y={this.props.y}>
        {/* <Arrow /> */}
        <Label>{this.props.text}</Label>
      </StyledDiv>
    );
  }
}

const StyledDiv = styled.div<{ x: number; y: number }>`
  position: absolute;
  left: ${props => props.x + 'px'};
  top: ${props => props.y + 'px'};

  width: 0;
  display: ;
`;

const Label = styled.span`
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
  word-wrap: normal;
`;
