//@ts-nocheck
import React, { ReactElement, ReactNode } from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import {
  Container,
  AuthLeft,
  AuthRight,
  Powered,
  PageWrapperOuter,
  PageWrapperInner,
  FlexBox,
} from './auth.css';
import { authConfig, indexOfDefault } from './authConfig';
import { Ident, api, apis, ApiError } from '../../logic/api';
import { IAuthConfig } from './authcomponents/IAuthConfig';
import { evaluateErrorMessage, performLogin } from '../../logic/helper/Common';
import { translate } from '../../common/language/translate';
import { MessageHandler } from '../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../logic/handler/messagehandler/messageHandlerConfig';
import { Log, Logs } from '../../logic/log';
import { Images, Background } from '../../images';
import { LoginComponent } from './authcomponents/loginComponent';
import { EmailComponent } from './authcomponents/emailComponent';
import { PasswordComponent } from './authcomponents/passwordComponent';
import { SignupComponent } from './authcomponents/signupComponent';
import { VerifyComponent } from './authcomponents/verifyComponent';
import { ContactComponent } from './authcomponents/contactComponent';
import { FaceLoginComponent } from './authcomponents/faceLoginComponent';
import { Actions } from '../../logic/flux';
import { BuildNumberComponent } from '../../components/compositcomponents/buildNumberComponent';

interface IProps {
  response: Ident.AuthParams;
}

interface IState {
  component: ReactElement | null;
  redirectOrError: ReactElement | null;
  routes: Array<ReactElement> | null;
  response?: Ident.AuthParams;
}

const notLoggedInPaths = [
  '/',
  '/signin',
  '/faceLogin',
  '/resetPassword',
  '/contactAdmin',
  '/processComplete',
  '/password',
];

export default class Auth extends React.Component<IProps, IState> {
  constructor(p: IProps) {
    super(p);

    this.state = {
      component: null,
      routes: null,
      redirectOrError: null,
      response: this.props.response,
    };
  }

  componentDidMount() {
    this.setComponent();
  }

  static getDerivedStateFromProps(props: IProps, state: IState): IState | null {
    if (props.response !== state.response) {
      return {
        component: state.component,
        routes: state.routes,
        redirectOrError: state.redirectOrError,
        response: props.response,
      };
    }

    return null;
  }

  private isAuthParams(arg: any): arg is Ident.AuthParams {
    if (arg == null) {
      return false;
    }
    return (
      arg.connection !== undefined && arg.idp_state !== undefined && arg.exp !== undefined
    );
  }

  private isPermissionResponse(arg: any): arg is Ident.PermissionResponse {
    if (arg == null) {
      return false;
    }
    return arg.person !== undefined && arg.operation_ids !== undefined;
  }

  private getResponse(
    callback: (
      error?: ApiError,
      response?: Ident.AuthParams | Ident.PermissionResponse,
    ) => void,
  ) {
    if (this.props.response != null) {
      callback(undefined, this.props.response);
    } else {
      api.request(
        [],
        apis.OpenIDConnectApi,
        'authAuthorizeGet',
        (error: ApiError, response: Ident.AuthParams | Ident.PermissionResponse) => {
          if (this.isAuthParams(response)) {
            Actions.setLogoutAbsTime(response.exp);
          }
          callback(error, response);
        },
      );
    }
  }

  private setComponent(): void {
    this.getResponse((error, response) => {
      if (this.isAuthParams(response)) {
        const r: Ident.AuthParams = response as Ident.AuthParams;
        Actions.setLogoutAbsTime(response.exp);
        const connectionConfig: IAuthConfig | undefined = this.getAuthconfigForConnections(
          r.connection,
        );

        if (connectionConfig != null) {
          this.refreshElement(
            this.getComponent(
              this.getConnectionForAuthconfig(r.connection, connectionConfig),
              r.idp_state,
              r.connection,
            ),
          );
        } else {
          this.refreshElement(<div>{translate('messages.noConfig.error')}</div>);
        }
      } else if (this.isPermissionResponse(response)) {
        performLogin(response).catch((error: ApiError) => {
          MessageHandler.onError(
            Reporter['auth.is.permission.response'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
          Log.error(Logs.AUTH, error);
        });
      } else if (error != null) {
        this.refreshElement(<div>{error.statusText}</div>);
      } else {
        this.refreshElement(<div>{translate('messages.noBackend.error')}</div>);
      }
    });
  }

  private refreshElement(element: ReactElement): void {
    this.setState({ component: element });
  }

  getNDBitLogo() {
    return (
      <Powered>
        <div>{translate('auth.footer.powered')}</div>
        {Images.logoNdbit()}
      </Powered>
    );
  }

  private getComponent(
    authConnection: Ident.AuthConnection | undefined,
    idpState: string,
    authConnections: Array<Ident.AuthConnection>,
  ): JSX.Element {
    Log.debug(Logs.AUTH, window.location);
    if (authConnection == null) {
      return <div></div>;
    }

    let currentAuthConfig: IAuthConfig | undefined = authConfig[indexOfDefault];
    switch (authConnection) {
      default:
      case Ident.AuthConnection.LoginUserPassword:
        currentAuthConfig = this.getAuthconfigForConnection(
          Ident.AuthConnection.LoginUserPassword,
        );
        Log.debug(Logs.AUTH, 'Displaying LoginUserPassword');
        Log.debug(Logs.AUTH, currentAuthConfig);
        return (
          <div>
            <LoginComponent
              idpState={idpState}
              connection={authConnections}
              authConfig={authConfig}
              currentAuthConfig={currentAuthConfig}
            />
            {this.getNDBitLogo()}
            <BuildNumberComponent />
          </div>
        );
      case Ident.AuthConnection.ResetPassword:
        currentAuthConfig = this.getAuthconfigForConnection(
          Ident.AuthConnection.ResetPassword,
        );
        Log.debug(Logs.AUTH, 'Displaying ResetPassword');
        Log.debug(Logs.AUTH, currentAuthConfig);
        return (
          <EmailComponent
            idpState={idpState}
            connection={authConnections}
            authConfig={authConfig}
            currentAuthConfig={currentAuthConfig}
          />
        );
      case Ident.AuthConnection.SetPassword:
        currentAuthConfig = this.getAuthconfigForConnection(Ident.AuthConnection.SetPassword);
        Log.debug(Logs.AUTH, 'Displaying SetPassword');
        Log.debug(Logs.AUTH, currentAuthConfig);
        return (
          <PasswordComponent
            idpState={idpState}
            connection={authConnections}
            authConfig={authConfig}
            currentAuthConfig={currentAuthConfig}
          />
        );
      case Ident.AuthConnection.Signup:
        currentAuthConfig = this.getAuthconfigForConnection(Ident.AuthConnection.Signup);
        Log.debug(Logs.AUTH, 'Displaying Signup');
        Log.debug(Logs.AUTH, currentAuthConfig);
        return (
          <SignupComponent
            idpState={idpState}
            connection={authConnections}
            authConfig={authConfig}
            currentAuthConfig={currentAuthConfig}
          />
        );
      case Ident.AuthConnection.Verify:
        currentAuthConfig = this.getAuthconfigForConnection(Ident.AuthConnection.Verify);
        Log.debug(Logs.AUTH, 'Displaying Verify');
        Log.debug(Logs.AUTH, currentAuthConfig);
        return (
          <VerifyComponent
            idpState={idpState}
            connection={authConnections}
            authConfig={authConfig}
            currentAuthConfig={currentAuthConfig}
          />
        );
      case Ident.AuthConnection.ContactAdmin:
        currentAuthConfig = this.getAuthconfigForConnection(Ident.AuthConnection.ContactAdmin);
        Log.debug(Logs.AUTH, 'Displaying ContactAdmin');
        Log.debug(Logs.AUTH, currentAuthConfig);
        return (
          <ContactComponent
            idpState={idpState}
            connection={authConnections}
            authConfig={authConfig}
            currentAuthConfig={currentAuthConfig}
          />
        );
      case Ident.AuthConnection.FaceLogin:
        currentAuthConfig = this.getAuthconfigForConnection(Ident.AuthConnection.FaceLogin);
        Log.debug(Logs.AUTH, 'Displaying FaceLogin');
        Log.debug(Logs.AUTH, currentAuthConfig);
        return (
          <FaceLoginComponent
            idpState={idpState}
            connection={authConnections}
            authConfig={authConfig}
            currentAuthConfig={currentAuthConfig}
          />
        );
    }
  }

  private getAuthconfigForConnections(
    connection: Array<Ident.AuthConnection>,
  ): IAuthConfig | undefined {
    return authConfig.find((el: IAuthConfig) => {
      return (
        connection.find((c: Ident.AuthConnection) => {
          return c === el.authConnection && el.enable === true;
        }) != null
      );
    });
  }

  private getAuthconfigForConnection(
    connection: Ident.AuthConnection,
  ): IAuthConfig | undefined {
    return authConfig.find((el: IAuthConfig) => {
      return connection === el.authConnection;
    });
  }

  private getConnectionForAuthconfig(
    connections: Array<Ident.AuthConnection>,
    authConfig: IAuthConfig | undefined,
  ): Ident.AuthConnection | undefined {
    if (authConfig == null) {
      return undefined;
    }

    return connections.find((c: Ident.AuthConnection) => {
      return c === authConfig.authConnection && authConfig.enable === true;
    });
  }

  render() {
    return (
      <Container>
        <AuthLeft>
          <PageWrapperOuter>
            <PageWrapperInner>
              <FlexBox>{Images.invrs()}</FlexBox>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(): ReactElement | null => {
                    return this.state.component;
                  }}
                />
                {authConfig.map((config: IAuthConfig): ReactElement | null => {
                  if (
                    config.enable !== true ||
                    config.uri === '' ||
                    config.authConnection == null
                  ) {
                    return null;
                  } else {
                    return (
                      <Route
                        key={config.id}
                        path={config.uri}
                        render={(): ReactElement | null => {
                          if (
                            this.state.response == null ||
                            config.authConnection == null ||
                            this.state.response.connection.indexOf(config.authConnection) < 0
                          ) {
                            Log.debug(
                              Logs.AUTH,
                              'Auth route disabled (), reason: state.authConnections == null || currentAuthConfig == null || currentAuthConfig.authConnection == null || state.authConnections.indexof(currentAuthConfig.authConnection) < 0',
                            );
                            Log.debug(Logs.AUTH, this.state.response);
                            Log.debug(Logs.AUTH, config);

                            return <Redirect to="/" />;
                          }

                          return this.getComponent(
                            this.getConnectionForAuthconfig(
                              this.state.response.connection,
                              config,
                            ),
                            this.state.response.idp_state,
                            this.state.response.connection,
                          );
                        }}
                      />
                    );
                  }
                })}
                <Route
                  default
                  render={(props: RouteComponentProps): ReactNode => {
                    const path = props.location.pathname;
                    if (notLoggedInPaths.indexOf(path) < 0) {
                      window.sessionStorage.setItem('lastPath', path);
                      window.sessionStorage.setItem('lastSearchPath', props.location.search);
                    }
                    return <Redirect to="/" />;
                  }}
                />
              </Switch>
            </PageWrapperInner>
          </PageWrapperOuter>
        </AuthLeft>
        <AuthRight>{Background.loginBackground()}</AuthRight>
      </Container>
    );
  }
}
