import React from 'react';
import { RowType } from '../../../../components/compositcomponents/table/tableTypes';
import { Icons } from '../../../../images';
import { Account } from '../../../../logic/api';
import { AccountStore, BackofficeStore } from '../../../../logic/flux';
import { CustomerDataStore } from '../../../../logic/flux/stores/customerDataStore';
import {
  OverlayHandler,
  Overlays,
} from '../../../../logic/handler/overlayhandler/overlayHandler';
import { format } from '../../../../logic/helper/format';
import { IParamsTableCellArrow } from './tableCellArrowComponent';
import { IParamsTableCellLink } from './tableCellLinkComponent';
import { Container, ContainerText } from './transfersComponent.css';

export const getPendingConverter = (that: any) => {
  return (
    line: Account.RequestMoneyResponse,
    index: number,
    fields: Array<string>,
  ): RowType => {
    const row: RowType = { cells: [], ref: line };
    if (row.cells == null) {
      return row;
    }

    const keys: Array<string> = Object.keys(line);
    const taid = line.ta_id;
    const id = line.request_money_id;

    for (let i = 0; i < fields.length; i++) {
      const field: string = fields[i];
      switch (field) {
        case 'description': {
          const descr =
            line.in_out === Account.RequestMoneyResponseInOutEnum.O
              ? 'Send money request'
              : 'Received money request';
          row.cells.push({
            value: descr,
            display: <ContainerText>{descr} </ContainerText>,
            copyVal: descr,
            methods: {
              revertTransactionCallback: () => {
                if (taid != null && id != null) {
                  that.revertTransaction(taid, id, 'none');
                }
              },
            },
          });
          break;
        }
        case 'counterpartyName': {
          let name: string = line.counterpartyGivenName + ' ' + line.counterpartyName;
          name = name.trim() === '' ? line.counterpartyAccount : name;
          row.cells.push({
            value: line.counterpartyName + ' ' + line.counterpartyAccount,
            display: (
              <Container
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                }}
              >
                <ContainerText>{name}</ContainerText>
              </Container>
            ),
            copyVal: name,
            methods: {
              revertTransactionCallback: () => {
                if (taid != null && id != null) {
                  that.revertTransaction(taid, id, 'none');
                }
              },
            },
          });
          break;
        }
        case 'datetime_create': {
          const valueDate: string | undefined = format.date(line.datetime_create);
          row.cells.push({
            value: valueDate == null ? '' : valueDate,
            display: valueDate == null ? '' : <ContainerText>{valueDate} </ContainerText>,
            copyVal: valueDate == null ? '' : valueDate,
            methods: {
              revertTransactionCallback: () => {
                if (taid != null && id != null) {
                  that.revertTransaction(taid, id, 'none');
                }
              },
            },
          });
          break;
        }
        case 'instructedAmount': {
          const sign = line.in_out === Account.RequestMoneyResponseInOutEnum.O ? '' : '-';
          const amount: Account.Amount =
            line.instructedAmount == null
              ? { amount: '0', currency: Account.Iso4217Currency.EUR }
              : line.instructedAmount;
          // const amountNumber: number = line.in_out === Account.RequestMoneyResponseInOutEnum.I ? Number.parseFloat(amount.amount.trim()) * -1 : Number.parseFloat(amount.amount.trim());
          row.cells.push({
            value: sign + format.currency(Number.parseFloat(amount.amount), amount.currency),
            display: (
              <ContainerText>
                {sign + format.currency(Number.parseFloat(amount.amount), amount.currency)}{' '}
              </ContainerText>
            ),
            copyVal: sign + format.currency(Number.parseFloat(amount.amount), amount.currency),
            methods: {
              revertTransactionCallback: () => {
                if (taid != null && id != null) {
                  that.revertTransaction(taid, id, 'none');
                }
              },
            },
          });
          break;
        }
        case 'request_money_id': {
          const request_money_id: number =
            line.request_money_id == null ? -1 : line.request_money_id;
          row.cells.push({
            value: request_money_id,
            display: <ContainerText>{request_money_id} </ContainerText>,
            copyVal: request_money_id.toString(),
            methods: {
              revertTransactionCallback: () => {
                if (taid != null && id != null) {
                  that.revertTransaction(taid, id, 'none');
                }
              },
            },
          });
          break;
        }
        case 'attachment': {
          if (line.attachments == null || line.attachments.length === 0) {
            row.cells.push({
              value: '',
            });
          } else {
            const attachment = line.attachments[0];
            row.cells.push({
              value: attachment.name,
              display: (
                <a href={attachment.link} target={attachment.name}>
                  {Icons.attachment()}
                </a>
              ),
              copyVal: attachment.name,
              methods: {
                revertTransactionCallback: () => {
                  if (taid != null && id != null) {
                    that.revertTransaction(taid, id, 'none');
                  }
                },
              },
            });
          }
          break;
        }
        default: {
          if (Object.prototype.hasOwnProperty.call(line, field)) {
            row.cells.push({
              value: Object.values(line)[keys.indexOf(field)],
              display: (
                <ContainerText>{Object.values(line)[keys.indexOf(field)]} </ContainerText>
              ),
              copyVal: Object.values(line)[keys.indexOf(field)],
              methods: {
                revertTransactionCallback: () => {
                  if (taid != null && id != null) {
                    that.revertTransaction(taid, id, 'none');
                  }
                },
              },
            });
          } else {
            row.cells.push({ value: '' });
          }
          break;
        }
      }
    }
    return row;
  };
};

export const getTransactionConverter = (that: any) => {
  return (line: Account.ActivityStatements, index: number, fields: Array<string>): RowType => {
    const row: RowType = { cells: [], ref: line };
    if (row.cells == null) {
      return row;
    }

    const keys: Array<string> = Object.keys(line);
    const taId: number = line.ta_id == null ? -1 : line.ta_id;
    const transactionId: number = line.transaction_id == null ? -1 : line.transaction_id;
    const postingJournalParams: IParamsTableCellLink = {
      taId: taId,
      openedFromSearch: true,
      transactionId: transactionId,
      callback:
        that.isBackofficeScreen() && that.props.redirectCallback != null
          ? that.props.redirectCallback
          : () => {
              that._onChangeUserData();
            },
      boCallback:
        that.isCustomerScreen() && that.props.redirectCallback != null
          ? that.props.redirectCallback
          : () => {},
      account:
        window.location.pathname.indexOf('/backoffice') < 0
          ? CustomerDataStore.getCurrentAccount()
          : BackofficeStore.getAccount(),
    };
    const accounts = AccountStore.getAccounts();
    let accountNumber: string = '1';
    if (accounts != null && accounts.currentAccount != null) {
      accountNumber = accounts.currentAccount.account_number;
    }
    const transactionDetailsParams: IParamsTableCellArrow = {
      accountNumber: accountNumber,
      taId: line.ta_id,
      transactionId: line.transaction_id,
      attachments: line.attachments,
      statemenData: line,
    };

    for (let i = 0; i < fields.length; i++) {
      const field: string = fields[i];
      switch (field) {
        case 'participant_email_address': {
          const name: string = format.nameByParts(
            line.participant_given_name,
            line.participant_name,
            line.participant_email_address,
          );
          row.cells.push({
            value: name + ' ' + line.statement_text,
            display: (
              <Container>
                <ContainerText title={name}>{name}</ContainerText>
              </Container>
            ),
            copyVal: name + ' ' + line.statement_text,
            methods: {
              postingJournalCallback: () => {
                OverlayHandler.showOverlay(Overlays.postingJournal, postingJournalParams);
              },
              transactioNDetailsCallback: () => {
                OverlayHandler.showOverlay(
                  Overlays.transactionDetail,
                  transactionDetailsParams,
                );
              },
              revertTransactionCallback: () => {
                that.cancelTransaction(line.ta_id, line.transaction_id);
              },
              stornoProductCallback:
                line.source === 'product_order' || line.source === 'product_package_request'
                  ? () => {
                      that.refundProductOrder(line.ta_id, line.transaction_id);
                    }
                  : undefined,
              invoiceCallback:
                line.source === 'product_order'
                  ? () => {
                      that.requestInvoice(line.ta_id, line.transaction_id);
                    }
                  : undefined,
              stornoCallback:
                line.source === 'reversal_transaction'
                  ? () => {
                      that.requestInvoice(line.ta_id, line.transaction_id, true);
                    }
                  : undefined,
            },
          });
          break;
        }
        case 'statement_text': {
          row.cells.push({
            value: line.statement_text == null ? '' : line.statement_text,
            display: (
              <ContainerText title={line.statement_text}>
                {line.statement_text == null ? '' : line.statement_text}
              </ContainerText>
            ),
            copyVal: line.statement_text == null ? '' : line.statement_text,
            methods: {
              postingJournalCallback: () => {
                OverlayHandler.showOverlay(Overlays.postingJournal, postingJournalParams);
              },
              transactioNDetailsCallback: () => {
                OverlayHandler.showOverlay(
                  Overlays.transactionDetail,
                  transactionDetailsParams,
                );
              },
              revertTransactionCallback: () => {
                that.cancelTransaction(line.ta_id, line.transaction_id);
              },
              stornoCallback:
                line.source === 'reversal_transaction'
                  ? () => {
                      that.requestInvoice(line.ta_id, line.transaction_id, true);
                    }
                  : undefined,
              stornoProductCallback:
                line.source === 'product_order' || line.source === 'product_package_request'
                  ? () => {
                      that.refundProductOrder(line.ta_id, line.transaction_id);
                    }
                  : undefined,
              invoiceCallback:
                line.source === 'product_order'
                  ? () => {
                      that.requestInvoice(line.ta_id, line.transaction_id);
                    }
                  : undefined,
            },
          });
          break;
        }
        case 'booking_datetime': {
          const bookingDatetime: string | undefined = format.datetime(line[field]);
          row.cells.push({
            value: bookingDatetime == null ? '' : bookingDatetime,
            display: (
              <ContainerText title={bookingDatetime}>
                {bookingDatetime == null ? '' : bookingDatetime}
              </ContainerText>
            ),
            copyVal: bookingDatetime == null ? '' : bookingDatetime,
            methods: {
              postingJournalCallback: () => {
                OverlayHandler.showOverlay(Overlays.postingJournal, postingJournalParams);
              },
              transactioNDetailsCallback: () => {
                OverlayHandler.showOverlay(
                  Overlays.transactionDetail,
                  transactionDetailsParams,
                );
              },
              revertTransactionCallback: () => {
                that.cancelTransaction(line.ta_id, line.transaction_id);
              },
              stornoProductCallback:
                line.source === 'product_order' || line.source === 'product_package_request'
                  ? () => {
                      that.refundProductOrder(line.ta_id, line.transaction_id);
                    }
                  : undefined,
              invoiceCallback:
                line.source === 'product_order'
                  ? () => {
                      that.requestInvoice(line.ta_id, line.transaction_id);
                    }
                  : undefined,
              stornoCallback:
                line.source === 'reversal_transaction'
                  ? () => {
                      that.requestInvoice(line.ta_id, line.transaction_id, true);
                    }
                  : undefined,
            },
          });
          break;
        }
        case 'transaction_datetime': {
          const valueDate: string | undefined = format.datetime(line[field]);
          row.cells.push({
            value: valueDate == null ? '' : valueDate,
            display: (
              <ContainerText title={valueDate}>
                {valueDate == null ? '' : valueDate}
              </ContainerText>
            ),
            copyVal: valueDate != null ? valueDate.toString() : '',
            methods: {
              postingJournalCallback: () => {
                OverlayHandler.showOverlay(Overlays.postingJournal, postingJournalParams);
              },
              transactioNDetailsCallback: () => {
                OverlayHandler.showOverlay(
                  Overlays.transactionDetail,
                  transactionDetailsParams,
                );
              },
              revertTransactionCallback: () => {
                that.cancelTransaction(line.ta_id, line.transaction_id);
              },
              stornoProductCallback:
                line.source === 'product_order' || line.source === 'product_package_request'
                  ? () => {
                      that.refundProductOrder(line.ta_id, line.transaction_id);
                    }
                  : undefined,
              invoiceCallback:
                line.source === 'product_order'
                  ? () => {
                      that.requestInvoice(line.ta_id, line.transaction_id);
                    }
                  : undefined,
              stornoCallback:
                line.source === 'reversal_transaction'
                  ? () => {
                      that.requestInvoice(line.ta_id, line.transaction_id, true);
                    }
                  : undefined,
            },
          });
          break;
        }
        case 'value_date': {
          const valueDate: string | undefined = format.date(line[field]);
          row.cells.push({
            value: valueDate == null ? '' : valueDate,
            display: (
              <ContainerText title={valueDate}>
                {valueDate == null ? '' : valueDate}
              </ContainerText>
            ),
            copyVal: valueDate != null ? valueDate.toString() : '',
            methods: {
              postingJournalCallback: () => {
                OverlayHandler.showOverlay(Overlays.postingJournal, postingJournalParams);
              },
              transactioNDetailsCallback: () => {
                OverlayHandler.showOverlay(
                  Overlays.transactionDetail,
                  transactionDetailsParams,
                );
              },
              revertTransactionCallback: () => {
                that.cancelTransaction(line.ta_id, line.transaction_id);
              },
              stornoProductCallback:
                line.source === 'product_order' || line.source === 'product_package_request'
                  ? () => {
                      that.refundProductOrder(line.ta_id, line.transaction_id);
                    }
                  : undefined,
              invoiceCallback:
                line.source === 'product_order'
                  ? () => {
                      that.requestInvoice(line.ta_id, line.transaction_id);
                    }
                  : undefined,
              stornoCallback:
                line.source === 'reversal_transaction'
                  ? () => {
                      that.requestInvoice(line.ta_id, line.transaction_id, true);
                    }
                  : undefined,
            },
          });
          break;
        }
        case 'amount': {
          let amount: number = line.amount == null ? 0 : line.amount;
          if (line.dc != null && line.dc === Account.ActivityStatementsDcEnum.D) {
            amount *= -1;
          }
          row.cells.push({
            value: format.currency(amount, line.currency),
            display: (
              <ContainerText title={format.currency(amount, line.currency)}>
                {format.currency(amount, line.currency)}
              </ContainerText>
            ),
            copyVal: format.currency(amount, line.currency),
            methods: {
              postingJournalCallback: () => {
                OverlayHandler.showOverlay(Overlays.postingJournal, postingJournalParams);
              },
              transactioNDetailsCallback: () => {
                OverlayHandler.showOverlay(
                  Overlays.transactionDetail,
                  transactionDetailsParams,
                );
              },
              revertTransactionCallback: () => {
                that.cancelTransaction(line.ta_id, line.transaction_id);
              },
              stornoProductCallback:
                line.source === 'product_order' || line.source === 'product_package_request'
                  ? () => {
                      that.refundProductOrder(line.ta_id, line.transaction_id);
                    }
                  : undefined,
              invoiceCallback:
                line.source === 'product_order'
                  ? () => {
                      that.requestInvoice(line.ta_id, line.transaction_id);
                    }
                  : undefined,
              stornoCallback:
                line.source === 'reversal_transaction'
                  ? () => {
                      that.requestInvoice(line.ta_id, line.transaction_id, true);
                    }
                  : undefined,
            },
          });
          break;
        }
        case 'transaction_id': {
          row.cells.push({
            value: transactionId + '.' + line.ta_id,
            display: (
              <ContainerText title={line.ta_id + '/' + transactionId}>
                {line.ta_id + '/' + transactionId}
              </ContainerText>
            ),
            copyVal: (line.ta_id + '/' + transactionId).toString(),
            methods: {
              postingJournalCallback: () => {
                OverlayHandler.showOverlay(Overlays.postingJournal, postingJournalParams);
              },
              transactioNDetailsCallback: () => {
                OverlayHandler.showOverlay(
                  Overlays.transactionDetail,
                  transactionDetailsParams,
                );
              },
              revertTransactionCallback: () => {
                that.cancelTransaction(line.ta_id, line.transaction_id);
              },
              stornoProductCallback:
                line.source === 'product_order' || line.source === 'product_package_request'
                  ? () => {
                      that.refundProductOrder(line.ta_id, line.transaction_id);
                    }
                  : undefined,
              invoiceCallback:
                line.source === 'product_order'
                  ? () => {
                      that.requestInvoice(line.ta_id, line.transaction_id);
                    }
                  : undefined,
              stornoCallback:
                line.source === 'reversal_transaction'
                  ? () => {
                      that.requestInvoice(line.ta_id, line.transaction_id, true);
                    }
                  : undefined,
            },
          });
          break;
        }
        default: {
          if (Object.prototype.hasOwnProperty.call(line, field)) {
            row.cells.push({
              value: Object.values(line)[keys.indexOf(field)],
              display: (
                <ContainerText title={Object.values(line)[keys.indexOf(field)]}>
                  {Object.values(line)[keys.indexOf(field)]}
                </ContainerText>
              ),
              copyVal: Object.values(line)[keys.indexOf(field)],
              methods: {
                postingJournalCallback: () => {
                  OverlayHandler.showOverlay(Overlays.postingJournal, postingJournalParams);
                },
                transactioNDetailsCallback: () => {
                  OverlayHandler.showOverlay(
                    Overlays.transactionDetail,
                    transactionDetailsParams,
                  );
                },
                revertTransactionCallback: () => {
                  that.cancelTransaction(line.ta_id, line.transaction_id);
                },
                stornoProductCallback:
                  line.source === 'product_order' || line.source === 'product_package_request'
                    ? () => {
                        that.refundProductOrder(line.ta_id, line.transaction_id);
                      }
                    : undefined,
                invoiceCallback:
                  line.source === 'product_order'
                    ? () => {
                        that.requestInvoice(line.ta_id, line.transaction_id);
                      }
                    : undefined,
                stornoCallback:
                  line.source === 'reversal_transaction'
                    ? () => {
                        that.requestInvoice(line.ta_id, line.transaction_id, true);
                      }
                    : undefined,
              },
            });
          } else {
            row.cells.push({ value: '' });
          }
          break;
        }
      }
    }

    return row;
  };
};

export const getDispoConverter = (that: any) => {
  return (
    line: Account.DispoActivityDispoStatements,
    index: number,
    fields: Array<string>,
  ): RowType => {
    const row: RowType = { cells: [], ref: line };
    if (row.cells == null) {
      return row;
    }

    const keys: Array<string> = Object.keys(line);
    const taId: number = line.ta_id == null ? -1 : line.ta_id;
    const transactionId: number = line.transaction_id == null ? -1 : line.transaction_id;
    const postingJournalParams: IParamsTableCellLink = {
      taId: taId,
      openedFromSearch: true,
      transactionId: transactionId,
      callback:
        that.isBackofficeScreen() && that.props.redirectCallback != null
          ? that.props.redirectCallback
          : () => {
              that._onChangeUserData();
            },
      boCallback:
        that.isCustomerScreen() && that.props.redirectCallback != null
          ? that.props.redirectCallback
          : () => {},
      account:
        window.location.pathname.indexOf('/backoffice') < 0
          ? CustomerDataStore.getCurrentAccount()
          : BackofficeStore.getAccount(),
    };
    const accounts = AccountStore.getAccounts();
    let accountNumber: string = '1';
    if (accounts != null && accounts.currentAccount != null) {
      accountNumber = accounts.currentAccount.account_number;
    }
    const transactionDetailsParams: IParamsTableCellArrow = {
      accountNumber: accountNumber,
      taId: line.ta_id,
      transactionId: line.transaction_id,
      statemenData: line,
    };

    for (let i = 0; i < fields.length; i++) {
      const field: string = fields[i];
      switch (field) {
        case 'statement_text': {
          row.cells.push({
            value: line.statement_text == null ? '' : line.statement_text,
            display: (
              <ContainerText title={line.statement_text}>
                {line.statement_text == null ? '' : line.statement_text}
              </ContainerText>
            ),
            copyVal: line.statement_text == null ? '' : line.statement_text,
            methods: {
              postingJournalCallback: () => {
                OverlayHandler.showOverlay(Overlays.postingJournal, postingJournalParams);
              },
              transactioNDetailsCallback: () => {
                OverlayHandler.showOverlay(
                  Overlays.transactionDetail,
                  transactionDetailsParams,
                );
              },
              revertTransactionCallback: () => {
                that.cancelTransaction(line.ta_id, line.transaction_id);
              },
            },
          });
          break;
        }
        case 'booking_datetime': {
          const bookingDatetime: string | undefined = format.datetime(line[field]);
          row.cells.push({
            value: bookingDatetime == null ? '' : bookingDatetime,
            display: (
              <ContainerText title={bookingDatetime}>
                {bookingDatetime == null ? '' : bookingDatetime}
              </ContainerText>
            ),
            copyVal: bookingDatetime == null ? '' : bookingDatetime,
            methods: {
              postingJournalCallback: () => {
                OverlayHandler.showOverlay(Overlays.postingJournal, postingJournalParams);
              },
              transactioNDetailsCallback: () => {
                OverlayHandler.showOverlay(
                  Overlays.transactionDetail,
                  transactionDetailsParams,
                );
              },
              revertTransactionCallback: () => {
                that.cancelTransaction(line.ta_id, line.transaction_id);
              },
            },
          });
          break;
        }
        case 'transaction_datetime': {
          const valueDate: string | undefined = format.datetime(line[field]);
          row.cells.push({
            value: valueDate == null ? '' : valueDate,
            display: (
              <ContainerText title={valueDate}>
                {valueDate == null ? '' : valueDate}
              </ContainerText>
            ),
            copyVal: valueDate != null ? valueDate.toString() : '',
            methods: {
              postingJournalCallback: () => {
                OverlayHandler.showOverlay(Overlays.postingJournal, postingJournalParams);
              },
              transactioNDetailsCallback: () => {
                OverlayHandler.showOverlay(
                  Overlays.transactionDetail,
                  transactionDetailsParams,
                );
              },
              revertTransactionCallback: () => {
                that.cancelTransaction(line.ta_id, line.transaction_id);
              },
            },
          });
          break;
        }
        case 'value_date': {
          const valueDate: string | undefined = format.date(line[field]);
          row.cells.push({
            value: valueDate == null ? '' : valueDate,
            display: (
              <ContainerText title={valueDate}>
                {valueDate == null ? '' : valueDate}
              </ContainerText>
            ),
            copyVal: valueDate != null ? valueDate.toString() : '',
            methods: {
              postingJournalCallback: () => {
                OverlayHandler.showOverlay(Overlays.postingJournal, postingJournalParams);
              },
              transactioNDetailsCallback: () => {
                OverlayHandler.showOverlay(
                  Overlays.transactionDetail,
                  transactionDetailsParams,
                );
              },
              revertTransactionCallback: () => {
                that.cancelTransaction(line.ta_id, line.transaction_id);
              },
            },
          });
          break;
        }
        case 'amount': {
          let amount: number = line.amount == null ? 0 : line.amount;
          if (line.dc != null && line.dc === Account.DispoActivityDispoStatementsDcEnum.D) {
            amount *= -1;
          }
          row.cells.push({
            value: format.currency(amount, line.currency),
            display: (
              <ContainerText title={format.currency(amount, line.currency)}>
                {format.currency(amount, line.currency)}
              </ContainerText>
            ),
            copyVal: format.currency(amount, line.currency),
            methods: {
              postingJournalCallback: () => {
                OverlayHandler.showOverlay(Overlays.postingJournal, postingJournalParams);
              },
              transactioNDetailsCallback: () => {
                OverlayHandler.showOverlay(
                  Overlays.transactionDetail,
                  transactionDetailsParams,
                );
              },
              revertTransactionCallback: () => {
                that.cancelTransaction(line.ta_id, line.transaction_id);
              },
            },
          });
          break;
        }
        case 'transaction_id': {
          row.cells.push({
            value: transactionId + '.' + line.ta_id,
            display: (
              <ContainerText title={line.ta_id + '/' + transactionId}>
                {line.ta_id + '/' + transactionId}
              </ContainerText>
            ),
            copyVal: (line.ta_id + '/' + transactionId).toString(),
            methods: {
              postingJournalCallback: () => {
                OverlayHandler.showOverlay(Overlays.postingJournal, postingJournalParams);
              },
              transactioNDetailsCallback: () => {
                OverlayHandler.showOverlay(
                  Overlays.transactionDetail,
                  transactionDetailsParams,
                );
              },
              revertTransactionCallback: () => {
                that.cancelTransaction(line.ta_id, line.transaction_id);
              },
            },
          });
          break;
        }
        case 'is_closed': {
          row.cells.push({
            value: line.is_closed != null ? line.is_closed.toString() : ' - ',
            display: (
              <ContainerText
                title={line.is_closed != null ? line.is_closed.toString() : ' - '}
              >
                {line.is_closed != null && line.is_closed
                  ? Icons.checkBoxIcon()
                  : Icons.closingCross()}
              </ContainerText>
            ),
            copyVal: line.is_closed != null ? line.is_closed.toString() : ' - ',
            methods: {
              postingJournalCallback: () => {
                OverlayHandler.showOverlay(Overlays.postingJournal, postingJournalParams);
              },
              transactioNDetailsCallback: () => {
                OverlayHandler.showOverlay(
                  Overlays.transactionDetail,
                  transactionDetailsParams,
                );
              },
              revertTransactionCallback: () => {
                that.cancelTransaction(line.ta_id, line.transaction_id);
              },
            },
          });
          break;
        }
        default: {
          if (Object.prototype.hasOwnProperty.call(line, field)) {
            row.cells.push({
              value: Object.values(line)[keys.indexOf(field)],
              display: (
                <ContainerText title={Object.values(line)[keys.indexOf(field)]}>
                  {Object.values(line)[keys.indexOf(field)]}
                </ContainerText>
              ),
              copyVal: Object.values(line)[keys.indexOf(field)],
              methods: {
                postingJournalCallback: () => {
                  OverlayHandler.showOverlay(Overlays.postingJournal, postingJournalParams);
                },
                transactioNDetailsCallback: () => {
                  OverlayHandler.showOverlay(
                    Overlays.transactionDetail,
                    transactionDetailsParams,
                  );
                },
                revertTransactionCallback: () => {
                  that.cancelTransaction(line.ta_id, line.transaction_id);
                },
              },
            });
          } else {
            row.cells.push({ value: '' });
          }
          break;
        }
      }
    }

    return row;
  };
};
