import { ITranslation } from './ITranslation';

export const en: ITranslation = {
  accountSelector: {
    lastTransaction: 'Last transaction',
    accounts: 'Accounts',
  },
  dashboard: {
    last25: {
      last25customers: 'Last 25 registrations',
      last25: 'Last 25',
    },
  },
  administration: {
    teams: {
      nomembers: 'No members',
      teammembers: 'Team members',
    },
    systemstate: {
      userstats: 'Client statistic',
      transactionstats: 'Transaction Statistics',
      dateFrom: 'Date from',
      dateTo: 'Date to',
      name: 'Name',
      date_create: 'Date',
      unbooked_txn: 'Unbooked Transactions',
      number_of_txn: 'Total Number of Transactions',
      min_timeused: 'Min Time',
      avg_timeused: 'Avg Time',
      med_timeused: 'Med Time',
      p99_timeused: '99% Time',
      max_timeused: 'Max Time',
    },
    third_party_monitoring: {
      header: 'Thirdparty monitoring',
    },
    clearing_files: {
      header: 'BPC-Clearing Files',
      selectFile: 'Select Clearing file',
      table: {
        original_id: 'Original ID',
        inst_id: 'Inst ID',
        file_type: 'File type',
        filename: 'File name',
        datetime_booked: 'Booking date',
        start_date: 'Start date',
        end_date: 'End date',
      },
    },
    card_status: {
      header: 'BPC Card status',
      selectFile: 'Select status file',
      table: {
        datetime_imported: 'Datetime imported',
      },
    },
    configuration: {
      header: 'Configurations',
      memo: 'Fee-Package',
      valid_from: 'Valid from',
      invalid_from: 'Valid to',
      amount: 'Amount',
      enterAmount: 'Enter amount',
      event: 'Event',
      delete: 'Deactivate',
      events: {
        A: 'Iban created',
        I: 'Identified',
        Y: 'Signup a year ago',
        V: 'X Referrals made',
        J: 'Junior account created',
      },
    },
  },
  auth: {
    complete: {
      title: 'Process Complete',
      subtitle:
        'Please sign in to your personal account if you want to change your password again.',
    },
    confirmation: {
      title: "You've got mail",
      subtitle: 'Please click the activation link in the email you received.',
    },
    password: {
      title: 'Set a password',
      subtitle: 'Set a password for your account',
      link: 'Password',
      notMatchingError: 'Passwords dont match',
      inValidPasswordError: 'Please enter a valid Password',
      input: {
        password: {
          label: 'Set your password',
          placeholder: 'Enter password',
        },
        password2: {
          label: 'Enter your password again',
          placeholder: 'Enter your password again',
        },
        current: {
          label: 'Current Password',
          placeholder: 'Enter Current Password',
        },
      },
    },
    resetPassword: {
      title: 'Reset password',
      subtitle: 'Please sign in to your personal account.',
      link: 'Reset password',
      input: {
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
      },
    },
    signin: {
      title: 'Sign in',
      subtitle: 'Please sign in to your personal account.',
      link: 'Click here to sign in',
      pwUserNeeded: 'Password/Username needed',
      userNeeded: 'Username needed',
      input: {
        email: {
          label: 'Email',
          placeholder: 'Enter your email',
        },
        password: {
          label: 'Password',
          placeholder: 'Enter your password',
        },
      },
    },
    signup: {
      signup: 'SIGN UP',
      title: 'Sign up',
      subtitle: 'Please sign up.',
      link: 'Click here to sign up',
      input: {
        email: {
          label: 'E-Mail',
          error: 'Please enter your email address',
        },
        firstName: {
          label: 'First Name',
          error: 'Please enter your first name',
        },
        lastName: {
          label: 'Last Name',
          error: 'Please enter your last name',
        },
        conditions: {
          label: 'I agree with terms & conditions',
          error: 'You have to accept our terms and conditions',
        },
      },
    },
    faceLogin: {
      title: 'Face Recognition',
      subtitle:
        'Please start face Recognition by clicking the button and look into the Camera. Please place your face inside the frame.',
      link: 'Click here for face recognition',
    },
    contactAdmin: {
      title: 'Contact Admin',
      subtitle: 'Please sign in to your personal account.',
      link: 'Contact Admin',
    },
    processComplete: {
      title: 'Process Complete',
      subtitle:
        'Please sign in to your personal account if you want to change your password again.',
      link: '',
    },
    contactComponent: {
      title: 'Contact us',
      subtitle: 'Please ...',
      message: 'Message',
      requestInquiries: 'Request for inquiries',
    },
    verify: {
      title: 'Enter your code',
      subtitle: 'We sent a link to your email-address. Please enter the received code.',
      link: 'Verify Code',
      input: {
        email: {
          label: 'Your Email',
          placeholder: 'Enter your email',
          error: 'Email required',
        },
        code: {
          label: 'Code',
          placeholder: 'Enter your code',
          error: 'Token required',
        },
        error: 'Email/ Token required',
      },
    },
    footer: {
      powered: 'powered by',
    },
  },
  backend: {
    '999999': 'The error text has to be set.',
    '1000001': 'The account id is too long.',
    '1000101': 'The entity is not set.',
    '1000102': 'The source account is not set.',
    '1000103': 'The target account is not set.',
    '1000104': 'The text is not set.',
    '1000105': 'The entity does not exist in the database.',
    '1000106': 'The source account does not exist.',
    '1000107': 'The source account is in an incorrect state.',
    '1000108': 'An error occurred in the context of the target account id.',
    '1000109': 'The target account is in an incorrect state.',
    '1000110': 'The amount is not set.',
    '1000111': 'The amount is zero.',
    '1000112': 'The amount is negative.',
    '1000113': 'The amount is too large.',
    '1000114': 'The amount has too many decimal digits.',
    '1000115': 'The currency is not set.',
    '1000116': 'The currency does not exist in the database.',
    '1000117': 'The currency is not provided for the source account.',
    '1000118': 'The currency is not provided for the target account.',
    '1000119': 'Unable to get the source id.',
    '1000120': 'The purpose is invalid.',
    '1000121': 'The local datetime is not set.',
    '1000122': 'The location is not set.',
    '1000123': 'The transaction is a duplicate.',
    '1000201': 'The provided account is unknown.',
    '1000202': 'The provided table is unknown.',
    '1000203': 'The generator is not configured.',
    '1000301': 'The transaction does not balance.',
    '1000302': 'The transaction has no statements.',
    '1000303': 'The account is not open.',
    '1000304': 'account not open',
    '1000305': 'number of statements < 2',
    '1000401': 'The transaction is not booked.',
    '1000501': 'The transaction does not exist.',
    '1000601': 'The provided instance is unknown.',
    '1000602': 'The provided table is unknown.',
    '1000603': 'The generator is not configured.',
    '1000701': 'The provided nationality is unknown.',
    '1000702': 'The login already exists.',
    '1000703': 'The email already exists.',
    '1000704': 'The person has to be at least 18 years old.',
    '1000705': 'Persons without password are only allowed when created by buisness operator.',
    '1000706': 'The external person id is not set.',
    '1000707': 'The dataprovider is unknown. Please use ndIdent only.',
    '1000708': 'The provided partner is unknown.',
    '1000801': 'No username or email provided.',
    '1000802': 'The user does not exist.',
    '1000902': 'There are too many decimal places.',
    '1001000': 'The account id is not set.',
    '1001001': 'The balance type is unknown.',
    '1001002': 'The date is not set.',
    '1001003': 'The commodity is not set.',
    '1001004': 'The subbalance is not set.',
    '1001100': 'The entity is unknown.',
    '1001101': 'The account is unknown.',
    '1001102': 'The balance type is unknown.',
    '1001103': 'The starting date is not set.',
    '1001104': 'The end date is not set.',
    '1001200': 'The entity is not set.',
    '1001201': 'The account number is not set.',
    '1001202': 'The entity is unknown.',
    '1001203': 'The account number is unknown.',
    '1001300': 'The entitiy is unknown.',
    '1001301': 'The account number is unknown.',
    '1001302': 'The balance type is unknown.',
    '1001303': 'The starting date is not set.',
    '1001304': 'The end date is not set.',
    '1001400': 'The entity is not set.',
    '1001401': 'The account is not set.',
    '1001402': 'The entity does not exist.',
    '1001403': 'The account does not exist.',
    '1001500': 'The entity is not set.',
    '1001501': 'The language is not set.',
    '1001502': 'The timezone is not set.',
    '1001503': 'The entity is unknown.',
    '1001504': 'The person is unknown.',
    '1001505': 'The language is unknown.',
    '1001506': 'The timezone is unknown.',
    '1001600': 'The initial state is invalid.',
    '1001601': 'The email address is blacklisted. Please contact support.',
    '1001602': 'The mail has been already sent or transaction does not exist.',
    '1001603': 'The mail has been already sent or notification does not exist.',
    '1001700': 'The message id is unknown.',
    '1001800': 'The nationality is unknown.',
    '1001801': 'The city is not set.',
    '1001900': 'The nationality is unknown.',
    '1001901': 'The city is not set.',
    '1001902': 'No record found.',
    '1002000': 'No record found.',
    '1002100': 'The nationality is unknown.',
    '1002101': 'No record found.',
    '1002102': 'Person has to be at least 18 years old.',
    '1002300': 'The email address has been already assigned.',
    '1002400': 'No record found.',
    '1002500': 'No record found.',
    '1002501': 'Cannot delete primary email address.',
    '1002600': 'The phone number has been already assigned',
    '1002700': 'No record found.',
    '1002701': 'No primary phone number left.',
    '1002800': 'No record found.',
    '1002801': 'The primary phone number can be only deleted if it is the last one.',
    '1002900': 'The email address is not set.',
    '1002901': 'The email address is not known.',
    '1003000': 'The token does not exist.',
    '1003100': 'The email address has been blacklisted. Please contact support.',
    '1003101': 'The email address has been blacklisted. Please contact support.',
    '1003200': 'Unable to create memo text. Increase the length of the memo field.',
    '1003300': 'The payout is not authorized.',
    '1003301': 'The payout was denied.',
    '1003302': 'No login record found.',
    '1003303': 'The person does not exist in the database.',
    '1003304': 'The account does not exist.',
    '1003305': 'The currency does not exist in the database.',
    '1003306': 'The subbalance does not exist.',
    '1003307': 'The state is unknown.',
    '1003308': 'The atm account is unknown.',
    '1003309': 'The amount is zero.',
    '1003310': 'The amount is negative.',
    '1003311': 'The amount is too large.',
    '1003312': 'Too many decimal digits.',
    '1004000': 'The uid is locked.',
    '1004001': 'The uid is unknown.',
    '1004002': 'The token is already used.',
    '1004003': 'The uid format is invalid.',
    '1004100': 'The card is locked.',
    '1004101': 'The card is unknown.',
    '1004102': 'The token has been already used.',
    '1004200': 'The funds are insufficient.',
    '1004201': 'The token is invalid.',
    '1004202': 'The token is invalid.',
    '1004203': 'The currency is invalid.',
    '1004204': 'There are too many decimal digits.',
    '1004205': 'No app session found.',
    '1004206': 'No email address specified.',
    '1004207': 'No person specified.',
    '1004300': 'The funds are insufficient.',
    '1004301': 'The token is invalid.',
    '1004302': 'The pin is invalid.',
    '1004303': 'The currency is invalid.',
    '1004304': 'Too many decimal digits.',
    '1004305': 'Cannot find atm payout or the atm payout is already used.',
    '1004306': 'The amount value does not match.',
    '1004307': 'The currency does not match.',
    '1004308': 'The currency exponent does not match.',
    '1004309': 'The pin is already set.',
    '1004310': 'The atm payout authentification time was already set.',
    '1004311': 'The atm payout is already carried out.',
    '1004312': 'The token is already used.',
    '1004400': 'The token is invalid.',
    '1004401': 'Cannot find the atm payout or the atm payout is already confirmed.',
    '1004500': 'The token is already used.',
    '1004600': 'Cannot find the device id.',
    '1004700': 'Cannot find the account.',
    '1004701': 'Cannot find card.',
    '1004702': 'The card is assigned to another account.',
    '1004800': 'Cannot find the account.',
    '1004801': 'Cannot find the card.',
    '1004900': 'Cannot find atm payout or the atm payout is already used.',
    '1005000': 'Cannot find account.',
    '1005001': 'Cannot find nfc.',
    '1005002': 'The nfc is assigned to another account.',
    '1005100': 'Cannot find account.',
    '1005101': 'Cannot find nfc.',
    '1005200': 'Cannot find voucher.',
    '1005300': 'The person id is not set.',
    '1005301': 'The account number is not set.',
    '1005302': 'The amount is not set.',
    '1005303': 'The amount is zero.',
    '1005304': 'The amount is too small.',
    '1005305': 'The amount is too large.',
    '1005306': 'The amount has too many decimal places.',
    '1005307': 'The currency is not set.',
    '1005308': 'The voucher number is not set.',
    '1005309': 'The currency is unknown.',
    '1005310': 'The account number is unknown.',
    '1005311': 'The voucher number is unknown.',
    '1005312': 'The voucher number does not correspond to a voucher.',
    '1005313': 'The account currency does not match the given currency.',
    '1005314': 'The voucher currency does not match the given currency.',
    '1005400': 'The merchant does not exist.',
    '1005500': 'Same id with different data already saved.',
    '1005600': 'Same id with different data already saved.',
    '1005700': 'The update does not exist.',
    '1005800': 'The account does not exist.',
    '1005900': 'The partner name is unknown.',
    '1006000':
      'Already received an unique identifier for the same business account with different data.',
    '1006001': 'Cannot find the transaction id.',
    '1006002': 'Cannot find the account.',
    '1006003': 'The account is not active.',
    '1006004': 'Cannot find the currency.',
    '1006005': 'Cannot find the subbalance.',
    '1006006': 'Cannot find the atm account.',
    '1006007': 'The amount is zero.',
    '1006008': 'The amount is too small.',
    '1006009': 'The amount is too large.',
    '1006010': 'Too many decimal digits.',
    '1006100': 'Cannot find the country.',
    '1006200': 'The passport is a duplicate.',
    '1006300': 'The image is a duplicate.',
    '1006400': 'The person waits already for approval.',
    '1006401': 'Cannot find the person.',
    '1006402': 'No selfi image found.',
    '1006403': 'No stored image found.',
    '1006404': 'Cannot find the issuing country.',
    '1006405': 'Cannot find the nationality.',
    '1006406': 'The phone number is already assigned.',
    '1006407': 'The email address is already assigned.',
    '1006500': 'Cannot find person.',
    '1006501': 'The person waits already for approval.',
    '1006502': 'The image is too small.',
    '1006600': 'Cannot find the person.',
    '1006601': 'The person waits already for approval.',
    '1006602': 'The nationality is unknown.',
    '1006700': 'Already received unique identifier for same account with different data.',
    '1006701': 'Cannot find the transaction id.',
    '1006702': 'Cannot find the account.',
    '1006703': 'The account is not active.',
    '1006704': 'Cannot find the currency.',
    '1006705': 'Cannot find the subbalance.',
    '1006706': 'Cannot find the atm account.',
    '1006707': 'The amount is zero.',
    '1006708': 'The amount is too small.',
    '1006709': 'The amount is too large.',
    '1006710': 'Too many decimal digits.',
    '1006711': 'The crypto currency is unknown.',
    '1006800': 'Already received unique identifier for same account with different data.',
    '1006801': 'Cannot find the transaction id.',
    '1006802': 'Cannot find the account.',
    '1006803': 'The account is not active.',
    '1006804': 'Cannot find the currency.',
    '1006805': 'Cannot find the subbalance.',
    '1006806': 'Cannot find atm account.',
    '1006807': 'The amount is zero.',
    '1006808': 'The amount is too small.',
    '1006809': 'The amount is too large.',
    '1006810': 'Too many decimal digits.',
    '1006811': 'The crypto currency is unknown.',
    '1006900': 'Too many retries done.',
    '1006901': 'The email address is already registered. Do you need a password reset?',
    '1006902': 'Cannot find the referral code.',
    '1006903': 'No matching oidc client (product) found for the user class.',
    '1006904': 'only superadmin may create bo-user',
    '1007000': 'Cannot find token.',
    '1007001': 'The email addresses do not match.',
    '1007002': 'The token is not active.',
    '1007003': 'An unknown error occurred.',
    '1007004': 'The token is already used.',
    '1007100': 'The mime type for the stored image is unknown.',
    '1007101': 'The mime type for the passport front image is unknown.',
    '1007102': 'Cannot find the person.',
    '1007103': 'The passport is already assigned to another person.',
    '1007104': 'Reading the passports for the issuing country is not allowed.',
    '1007200': 'The image is too small.',
    '1007201': 'The image type is unknown.',
    '1007202': 'Cannot find the person.',
    '1007300': 'Cannot find the person.',
    '1007400': 'Cannot find the person.',
    '1007500': 'Cannot find the transaction.',
    '1007501': 'The transaction type is not configured.',
    '1007600': 'The email address is unknown.',
    '1007700': 'Cannot find the token.',
    '1007701': 'The email addresses do not match.',
    '1007702': 'The token is not active.',
    '1007703': 'An unknown error occurred.',
    '1007704': 'Cannot find the person.',
    '1007800': 'Cannot find the person.',
    '1007900': 'The image is too small.',
    '1007901': 'The image type is unknown.',
    '1007902': 'Cannot find the person.',
    '1007903': 'The nist position is unknown.',
    '1007904': 'The nist impression is unknown.',
    '1007905': 'Duplicate fingerprint for the given nist position.',
    '1008000': 'Same id with different data already saved.',
    '1008100': "Initial upload only allowed for person state 'V'.",
    '1008101': 'Already have passport.',
    '1008102': 'Already have address.',
    '1008103': 'Already have phone.',
    '1008104': 'Already have fingerprint id.',
    '1008105': 'Already have face id.',
    '1008200': 'Cannot find the client.',
    '1008300': 'Client already exists.',
    '1008400': 'Cannot find the verification code.',
    '1008401': 'The receiver does not match.',
    '1008402': 'The verification code is not active.',
    '1008403': 'An unknown error occurred.',
    '1008404': 'The verification code is already used.',
    '1008500': 'No verification code found.',
    '1008600': 'The language is unknown.',
    '1008700': 'Cannot find the account.',
    '1008800': 'Cannot find the account.',
    '1008900': 'No search parameters given.',
    '1009000': 'Cannot find the account.',
    '1009001': 'The account is in a wrong state.',
    '1009002': 'The account balance is not zero.',
    '1009100': 'Cannot find the person.',
    '1009113': 'Cannot find the creditor account.',
    '1009200': 'The instructed amount has too many digits.',
    '1009201': 'The currency is unknown.',
    '1009202': 'Cannot determine creditor account type.',
    '1009300': 'The debtor account is not set.',
    '1009301': 'The debtor account type is unknown.',
    '1009302': 'The instructed currency is not set.',
    '1009303': 'The creditor account is not set.',
    '1009304': 'The creditor account type is unknown.',
    '1009305': 'The creditor name is not set.',
    '1009306': 'The transaction was not specified as either income or outcome.',
    '1009307': 'The debtor account type is not implemented.',
    '1009308': 'The creditor account type is not implemented',
    '1009309': 'Cannot find the debtor account.',
    '1009310': 'The debtor is an external account.',
    '1009311': 'The debtor has no iban and cannot send sepa payments.',
    '1009312': 'The creditor has no iban.',
    '1009314': 'Incoming payments for external accounts are not implemented.',
    '1009315': 'Cannot find intermediate account.',
    '1009316': 'The instructed amount is not set.',
    '1009317': 'The instructed amount is at or below zero.',
    '1009318': 'The transaction id is set but the request money id is not set.',
    '1009319': 'The request money id is set but the transaction id is not set.',
    '1009320': 'The sender is not allowed to accept money requests.',
    '1009321': 'The money request does not match the money flow.',
    '1009322': 'Only the receiver may accept a money request.',
    '1009323': 'The receiver account changed.',
    '1009324': 'Change of currency is not allowed.',
    '1009325': 'Change of creditor is not allowed.',
    '1009326': 'Change of creditor is not allowed.',
    '1009327': 'Not exactly one referenced transaction found.',
    '1009400': 'Handling of pending transactions for the given source is not implemented.',
    '1009500': 'The id of the person creation process is not set.',
    '1009501': 'The current account is not set.',
    '1009502': 'The debtor account type is unknown.',
    '1009503': 'The instructed currency is not set.',
    '1009504': 'The creditor account is not set.',
    '1009505': 'The amount is not set.',
    '1009506': 'The amount is at or below zero.',
    '1009507': 'Cannot find the current account.',
    '1009508': 'Cannot find the creditor account.',
    '1009509': 'At maximum one (customer-) cash account allowed.',
    '1009600': 'Cannot find the address.',
    '1009700': 'Cannot find the address.',
    '1009701': 'The address type already exists.',
    '1009702': 'address approval only possible with identified person',
    '1009800': 'Cannot find the account.',
    '1009900': 'Cannot find the account.',
    '1010000': 'There are no search parameters given.',
    '1010100': 'The monthly amount of incomes is exceeded.',
    '1010101': 'The monthly amount of outgoings is exceeded.',
    '1010102': 'The balance is below the minimum allowed value.',
    '1010103': 'The balance is above the maximum allowed value.',
    '1010104': 'The amount is not set.',
    '1010105': 'There are too many decimal digits given.',
    '1010106': 'amount day in exceeded',
    '1010107': 'amount day out exceeded',
    '1010108': 'amount week in exceeded',
    '1010109': 'amount week out exceeded',
    '1010110': 'amount year in exceeded',
    '1010111': 'amount year out exceeded',
    '1010112': 'amount day withdraw exceeded',
    '1010113': 'amount week withdraw exceeded',
    '1010114': 'amount month withdraw exceeded',
    '1010115': 'amount year withdraw exceeded',
    '1010116': 'amount day upload exceeded',
    '1010117': 'amount week upload exceeded',
    '1010118': 'amount month upload exceeded',
    '1010119': 'amount year upload exceeded',
    '1010120': 'unknown validity',
    '1010200': 'The date range is invalid.',
    '1010300': 'Cannot find the own account.',
    '1010301': 'Cannot find the own person.',
    '1010302': 'Cannot find the own email address.',
    '1010303': 'Cannot find the counterparty account.',
    '1010304': 'The counterparty account number is not set.',
    '1010305': 'Cannot find the counterparty person.',
    '1010306': 'Cannot find the counterparty email address.',
    '1010307': 'The amount is not set.',
    '1010308': 'The amount is negative.',
    '1010309': 'The amount is zero.',
    '1010310': 'Requests to the same account are permitted.',
    '1010311': 'Requests to same person are permitted.',
    '1010312': 'Cannot find the creditor account.',
    '1010313': 'Cannot find the creditor iban.',
    '1010400': 'Cannot find the money request.',
    '1010401': 'Cannot find the money request of the counterparty.',
    '1010402': 'The money request is not in pending state.',
    '1010403': 'The money request of the counterparty is not in pending state.',
    '1010404': 'The receiver tries to update the senders request?',
    '1010405': 'The sender tries to update the receivers request?',
    '1010500': 'The money request is not in a correct state.',
    '1010600': 'Cannot find the notification process.',
    '1010700': 'The combination of database table and process is unknown.',
    '1010701': 'The verification code is not active anymore.',
    '1010702': 'The verification code is not active anymore.',
    '1010800': 'The password change is not allowed.',
    '1010900': 'Cannot find the person.',
    '1010901': 'Cannot find the email address.',
    '1011000': 'The new balance is below the lower limit.',
    '1011100': 'Could not create unique code.',
    '1011200': 'Cannot find the transaction.',
    '1011201': 'The amount to split is no debit.',
    '1011202': 'The counterparty accounts do not match the corresponding account types.',
    '1011203': 'The counterparty accounts do not match the corresponding shares.',
    '1011204': 'The counterparty amount is negative.',
    '1011205': 'The counterparty amount is zero.',
    '1011206': 'The sum of splitted amount exceeds the original amount.',
    '1011300': 'The card brand is unknown.',
    '1011301': 'The card number is invalid.',
    '1011302': 'The card number is already assigned to a person.',
    '1011400': 'Cannot find the card.',
    '1011500': 'The person id is not set.',
    '1011501': 'The account is not set.',
    '1011502': 'Cannot find the account.',
    '1011503': 'The amount is not set.',
    '1011504': 'The amount is at or below zero.',
    '1011505': 'The currency is not set.',
    '1011506': 'Cannot find the currency.',
    '1011508': 'The external account is not set.',
    '1011509': 'Cannot find the external account.',
    '1011510': 'Cannot find the transfer account.',
    '1011511': 'fee account not found',
    '1011512': 'net amount below zero',
    '1011513': 'fee amount below zero',
    '1011514': 'not payed',
    '1011515': 'amount instructed does not match amount payed',
    '1011600': 'Cannot find the account.',
    '1011601': 'Cannot find the card provider.',
    '1011602': 'Cannot find the card type.',
    '1011603': 'The card type is for the provider not valid.',
    '1011604': 'There is already an agreement with the provider.',
    '1011605': 'unknown error saving card agreement',
    '1011700': 'Cannot find the account.',
    '1011701': 'Cannot find the card agreement.',
    '1011702': 'The card agreement is not active.',
    '1011800': 'Cannot find the account.',
    '1011801': 'Cannot find the card agreement.',
    '1011802': 'The card agreement is not active.',
    '1011803': 'The feature is not implemented.',
    '1011804': 'customer not registered at bpc',
    '1011900': 'Cannot find the account.',
    '1011901': 'Cannot find the card agreement.',
    '1011902': 'The card agreement is not active.',
    '1011903': 'The card agreement is not active.',
    '1012000': 'Cannot find the image.',
    '1012100': 'Cannot find the image.',
    '1012200': 'The phone number is already in use.',
    '1012201': 'The phone number is waiting for verification. Please retry later.',
    '1012300': 'Cannot find the account.',
    '1012301': 'The process is unknown.',
    '1012302': 'The channel is unknown.',
    '1012400': 'The phone number is already in use.',
    '1012401': 'The phone number is waiting for verification. Please retry later.',
    '1012402': 'Cannot find the creation process of the person. (gone or finished)',
    '1012500': 'Cannot find the email.',
    '1012600': 'The attachment is not associated with the request.',
    '1012601': 'Cannot find the attachment.',
    '1012602': 'The attachment is not associated with the request.',
    '1012603': 'The attachment is not associated with the request.',
    '1012604': 'The source is not configured.',
    '1012700': 'The date is missing.',
    '1012800': 'The access key is already known.',
    '1012900': 'Cannot find the access key.',
    '1012901': 'The access key is not active.',
    '1013000': 'The access key is already active.',
    '1013001': 'Cannot find the access key or active it is not active.',
    '1013100': 'Cannot find the callback.',
    '1013200': 'Cannot find the callback.',
    '1013300': 'Found same transaction with different callback url.',
    '1013400': 'Cannot find the person.',
    '1013500': 'Cannot find the record.',
    '1013501': 'Cancel is only possible for own checkouts.',
    '1013600': 'Cannot find any document.',
    '1013700': 'Cannot find the person.',
    '1013800': 'The address for this address type is already stored.',
    '1013900': 'The email is already in use.',
    '1013901': 'The email is waiting for verification. Please retry later.',
    '1013902': 'Cannot find the person creation process. (gone or finished)',
    '1014000': 'There is no usable data provided.',
    '1014001': 'Cannot find the companion code or it is active.',
    '1014100': 'Cannot find the person creation process. (gone or finished)',
    '1014200': 'Cannot find the person creation process. (gone or finished)',
    '1014300': 'Cannot find the person creation process. (gone or finished)',
    '1014400': 'Cannot find the person creation process. (gone or finished)',
    '1014500': 'Cannot find the person creation process. (gone or finished)',
    '1014600': 'Cannot find the person creation process. (gone or finished)',
    '1014700': 'Cannot find the person creation process. (gone or finished)',
    '1014800': 'Cannot proceed!',
    '1014801': 'There is no partner for the oidc client configured.',
    '1014900': 'The account is unknown.',
    '1015000': 'The account is unknown.',
    '1015100': 'The account is unknown.',
    '1015200': 'The mobile tag is unknown.',
    '1015201': 'You already checked in.',
    '1015202': 'Cannot find the customer account.',
    '1015203': 'The mobile tag is unknown.',
    '1015300': 'The checkin at the location is unknown.',
    '1015301': 'test_id assigned, checkout not possible, please assign result',
    '1015400': 'The mobile tag is unknown.',
    '1015401': 'The uuid is unparsable.',
    '1015402': 'Your already checked in.',
    '1015403': 'The mobile tag is unknown.',
    '1015500': 'Cannot find the merchant account.',
    '1015501': 'Cannot find the customer.',
    '1015502': 'You already checked in.',
    '1015503': 'The mobile tag is unknown.',
    '1015600': 'The account is unknown.',
    '1015601': 'Cannot find the mobile tag or it is not active.',
    '1015700': 'Cannot find the merchant account.',
    '1015701': 'The mobile tag is unknown.',
    '1015702': 'You already checked in.',
    '1015800': 'Cannot find the parameter.',
    '1015900': 'Cannot find the parameter.',
    '1016000': 'Cannot find the country and the classification is not possible.',
    '1016001': 'market not served, classification not possible',
    '1016100': 'Could not create an unique code.',
    '1016200': 'The companion code is invalid or expired.',
    '1016201': 'The current persons profile is not set.',
    '1016203': 'The companion persons profile is not set.',
    '1016204': 'The companion codes person is not primary.',
    '1016205': 'The companion codes person is not companion.',
    '1016206': 'primary person is not identified',
    '1016300':
      'The person is not related to the companion request or the request is not active.',
    '1016301': 'The primary already reached maximum number of companions.',
    '1016302': 'primary person is not identified',
    '1016400': 'The uuid is not parsable.',
    '1016500': 'The client is not set.',
    '1016501': 'The client is not in invrs, core, ident, ndpaynet or ndident.',
    '1016502': 'The person id of the request is not set.',
    '1016503': 'The request id is not set.',
    '1016504': 'The release type is not set.',
    '1016505': 'The release type is not a transaction.',
    '1016606': 'The person id of the request is not set.',
    '1016707': 'The person id is not set.',
    '1016708': 'The release request id is not set.',
    '1016709': 'The release client is not set.',
    '1016710': 'The release client is not in invrs, core, ident, ndpaynet or ndident.',
    '1016711': 'The release action is not set.',
    '1016712': 'The release action is not in RELEASE, NO_RELEASE, BLOCK or UNBLOCK.',
    '1016713': 'Cannot find the release request by id.',
    '1016714': 'The requesting person is not allowed to edit request.',
    '1016715': 'The release request is blocked and needs to be unblocked first.',
    '1016716': 'The release request is already released.',
    '1016717': 'release/no release can only be given once per request',
    '1016800': 'Cannot find the session.',
    '1016900': 'account not known',
    '1016901': 'account closed',
    '1016902': 'limit_value has too many digits',
    '1016903': 'limit_value is too large',
    '1016904': 'limit_type not found',
    '1016905': 'limit_type not configurable',
    '1016906': 'no system limit configured',
    '1016907': 'limit_value below system limit',
    '1016908': 'limit_value exceeds system limit',
    '1017000': 'account not known',
    '1017001': 'account closed',
    '1017100': 'account not found',
    '1017101': 'person  not found',
    '1017102': 'account already has an iban',
    '1017200': 'unkown transaction task action',
    '1017201': 'unkown depends transaction task action',
    '1017202': 'depends transaction task action does not exist',
    '1017300': 'unkown transaction task action',
    '1017301': 'unknown transaction task',
    '1017302': 'transaction task already done',
    '1017400': 'unknown field',
    '1017500': 'value does not match pattern for iban',
    '1017501': 'wrong iban checksum',
    '1017502': 'impossible iban checksum',
    '1017503': 'bban part of iban violates national bban pattern',
    '1017600': 'card not found',
    '1017601': 'unsuccessful response from bpc',
    '1017700': 'fee package not found',
    '1017701': 'transaction type already assigned to account',
    '1017702': 'no packages found',
    '1017703': 'Package may not be assigned without valid iban',
    '1017800': '2fa required',
    '1017900': 'release not found or already done',
    '1017901': 'no 2fa release',
    '1017902': '2fa only for own releases',
    '1017903': 'max number of tans exceeded',
    '1017904': 'channel not usable',
    '1018000': 'tan not found or expired',
    '1018001': 'too many retries',
    '1018002': 'operation not successfull',
    '1018100': 'account not found or inactive',
    '1018101': 'product not found or inactive',
    '1018200': 'merchant account not found',
    '1018201': 'customer account not found',
    '1018202': 'at least one item is not configured',
    '1018203': 'cannot calculate amount',
    '1018204': 'amount negative',
    '1018205': 'customer name not set, no order possible',
    '1018206': 'customer given_name not set, no order possible',
    '1018207': 'customer sex not setno order possible',
    '1018208': 'customer birth_date not set, no order possible',
    '1018209': 'nationality not set, no order possible',
    '1018210': 'ordering of multiple products, currently not possible. please do test first',
    '1018211': 'voucher not found (wrong code,expired or already used)',
    '1018212': 'voucher not valid for merchant',
    '1018213': 'voucher has different currency',
    '1018214': 'number of items not allowed',
    '1018300': 'no product found for transaction',
    '1018301': 'transaction and product given, use only one!',
    '1018302': 'product does not exist',
    '1018303': 'location does not exist or not active',
    '1018304': 'test sent to ecocare, no manual testresult allowed',
    '1018400': 'previous location not found',
    '1018500': 'unknown action',
    '1018501': 'test_id already assigned to different product order',
    '1018502': 'product order already has a test_id',
    '1018600': 'location not found',
    '1018700': 'incomplete personal data, transfer to ecolog not possible',
    '1018800': 'logitude not convertable to double',
    '1018801': 'latitude not convertable to double',
    '1018900': 'not authorized',
    '1019000': 'non semantic version number',
    '1019001': 'current app version not supported on plattform, please use website',
    '1019100': 'error in calendar_items end_time > start_time',
    '1019101': 'error in calendar_items overlapping times',
    '1019200': 'no pharmacy_id assigned',
    '1019300': 'no pharmacy_id assigned',
    '1019400': 'voucher code not found, invalid or expired',
    '1019500': 'merchant account not found',
    '1019501': 'customer account not found',
    '1019502': 'account zstripe not found',
    '1019503': 'account zecocarekunde not found',
    '1019504': 'account ecocare not found',
    '1019505': 'amount_fee_stripe negative',
    '1019506': 'amount_net negative',
    '1019507': 'amount_merchant negative',
    '1019508': 'amount_ecocare negative',
    '1019510': 'amount_payment negative',
    '1019511': 'no successfull payment found',
    '1019512': 'amount payed does not match amount ordered',
    '1019513': 'product voucher does not exist',
    '1019600': 'person_id not set',
    '1019601': 'account not set',
    '1019602': 'account not found',
    '1019603': 'amount not set',
    '1019604': 'amount at or below zero',
    '1019605': 'currency not set',
    '1019606': 'currency not found',
    '1019607': 'external account not found',
    '1019700': 'account not found',
    '1019701': 'account not active',
    '1019702': 'amount not set',
    '1019703': 'amount below min stripe amount',
    '1019704': 'currency not supported',
    '1019705': 'email address not found',
    '1019706': 'customer person not found',
    '1019800': 'unknown payment method',
    '1019900': 'too many decimal places',
    '1020000': 'no release found for given id',
    '1020100': 'must not be null',
    '1020101': 'no active person record',
    '2000000': 'The email address is invalid.',
    '2000100': 'The email address is invalid.',
    '2000200': 'The email address is invalid.',
    '2000300': 'The email address is invalid.',
    '2000301': 'The link format is unknown.',
    '2000302': 'An error occurred while fetching the pdf.',
    '2000400': 'An error occurred while logging in.',
    '2000500': 'The timeout was reached.',
    '2000501': 'Received a pin authorization.',
    '2000502': 'The token is not active.',
    '2000503': 'The token is not active.',
    '2000504': 'The authorization was denied.',
    '2000505': 'The token is not active.',
    '2000506': 'There is no response from the app process returned. Canceling the process!',
    '2000600': 'The pin is not numeric.',
    '2000605': 'The Jsbm is unreachable.',
    '2000606': 'An unexpected error occurred.',
    '2000700': 'no verification request with given id exists',
    '2000701': 'data for this id already stored',
    '2000800': 'no table id for given table name found',
    '2000801': 'no status change for given table triggered',
    '2000900': 'no verification request with given id exists',
    '2001000': 'data for this notification_id already stored',
    '2001100': 'data for this video_id already stored',
    '2001101': 'no person found for video id',
    '2001200': 'no bpc product for persons resident country',
    '2001201': 'already registered for a different product',
    '2001202': 'account not found',
    '2001203': 'unknown error saving register customer request',
    '2001300': 'person not registered at bpc',
    '2001301': 'account not found',
    '2001302': 'unknown error saving create virtual card request',
    '2001400': 'original transaction not found',
    '2001401': 'original transaction failed',
    '2001402': 'feature not implemented',
    '2001403': 'original transaction already reversed',
    '2001500': 'original transaction not found',
    '2001501': 'reversal transaction not found',
    '2001502': 'reversal and original transaction are not balanced',
    '2001600': 'unkown account task action',
    '2001601': 'unkown depends account task action',
    '2001602': 'depends account task action does not exist',
    '2001700': 'unkown account task action',
    '2001701': 'unknown account task',
    '2001702': 'account task already done',
    '2001800': 'feature not implemented',
    '2001900': 'feature not implemented',
    '2002000': 'card not found',
    '2002001': 'card agreement not found',
    '2002100': 'unsuccessful response from BPC',
    '2002200': 'card not found',
    '2002201': 'card agreement not found',
    '2002300': 'unsuccessful response from BPC',
    '2002301': 'invalid state',
    '2002400': 'card not found',
    '2002401': 'card agreement not found',
    '2002402': 'state not found',
    '2002500': 'unsuccessful response from BPC',
    '2002501': 'unsuccessful response from BPC',
    '2002600': 'unknown error saving register customer response',
    '2002601': 'unsuccessful response from BPC',
    '2002700': 'unknown error saving create virtual card response',
    '2002701': 'unsuccessful response from BPC',
    '2002800': 'primary with companions cannot be downgraded to companion',
    '2002900': 'card not found',
    '2003000': 'unsuccessful response from BPC',
    '2003100': 'operation type not supported',
    '2003200': 'product order not found',
    '2003201': 'test result already entered',
    '2003300': 'person_id create not set',
    '2003301': 'debitor account not set',
    '2003302': 'creditor account not set',
    '2003303': 'currency not set',
    '2003304': 'amount not set',
    '2003305': 'amount at or below zero',
    '2003306': 'debitor account not found',
    '2003307': 'creditor account not found',
    '2003308': 'maximal one customer account allowed',
    '2003309': 'one account must be customer account',
    '2003400': 'card not found',
    '2003500': 'unsuccessful response from BPC',
    '2003600': 'state not found',
    '2003601': 'account not found',
    '2003700': 'unsuccessful response from BPC',
    '2003800': 'account not found',
    '2003801': 'card agreement not found',
    '2003802': 'limit not found',
    '2003900': 'unsuccessful response from BPC',
    '2004000': 'duplicate transaction',
    '2004001': 'duplicate transaction',
    '2004100': 'account not found',
    '2004101': 'card agreement not found',
    '2004102': 'card color not found',
    '2004103': 'only apply once per customer',
    '2004104': 'secret answer and card color required for physical card',
    '2004200': 'account not found',
    '2004201': 'card agreement not found',
    '2004202': 'card not found',
    '2004300': 'as of date lies in the future.',
    '2004400': 'invalid or unknown email address',
    '2004401': 'invalid or unknown account',
    '2004500': 'invalid or unknown voucher code',
    '2004600': 'invalid or unknown account',
    '2004700': 'invalid voucher (wrong code, expired, or already used)',
    '2004800': 'wrong kyc screening status',
    '3000001': 'The feature is not implemented.',
    '3000002': 'The account is not active or connected to the acting person.',
    '3000100': 'The response is unknown.',
    '3000101': 'An exception occurred while calling the wallet server.',
    '3000102': 'The response is unknown.',
    '3000103': 'An exception occurred while calling the wallet server.',
    '3000200': 'An error occurred while validating the idp state: ',
    '3000201': 'The user or the password is invalid.',
    '3000202': 'An error occurred while validating the request: ',
    '3000203': 'An error occurred while fetching the email with reason: ',
    '3000204': 'An error occurred while validating the request: ',
    '3000205': 'The phone number is not valid.',
    '3000206': 'The mobile number is not valid.',
    '3000207': 'The token is not valid or expired.',
    '3000208': 'The token is not valid or expired.',
    '3000209': 'The phone number is not valid.',
    '3000210': 'The mobile number is not valid.',
    '3000300': 'An error occurred while validating the verification code.',
    '3000301': 'You are not authorized.',
    '3000302': 'The person is not connected to the partner and the authorization failed.',
    '3000303': 'An error occurred while fetching the user data.',
    '3000304': 'The person is not connected to the partner and the authorization failed.',
    '3000305': 'An error occurred while fetching the user data.',
    '3000306': 'current app version not supported on plattform, please use website',
    '3000400': 'Could not save the access key as it took too many rounds.',
    '3000401': 'wrong password',
    '3000500': 'The account is not active or connected to the acting person.',
    '3000600': 'An error occurred while calling the link.',
    '3000601': 'An error occurred while calling the link.',
    '3000700': 'The number format is not valid.',
    '3000701': 'The number format is not valid.',
    '3000800': 'The timestamp format is not valid.',
    '3000900': 'The electronic id of the resulting structure id is unknown.',
    '3000901': 'The result is not a valid json object.',
    '3000902': 'An error occurred while calling the electronic id.',
    '3000903': 'The electronic id is not configured.',
    '3001001': 'account not found',
    '3001002': 'error calling bpc',
    '3001003': 'unknown request structure',
    '3001004': 'unknown error',
    '3001005': 'bpc not configured',
    '3001100': 'not a mobile number',
    '3001101': 'error validating phone number',
    '3001102': 'connection_error validating phone number',
    '3001200': 'Hubspot Connection refused',
    '3001201': 'access token not found',
    '3001202': 'base url was not found',
    '3001203': 'base url was not found',
    '3001204': 'api key was not found',
    '3001205': 'no person_id and person_create_id found',
    '3001300': 'error_code not numeric',
    '3001301': 'error_code not numeric',
    '3001302': 'undefined function',
    '3001303': 'sql error',
    '3001304': 'connection closed',
    '3001400': 'unknown xml structure',
    '4000000': 'Cannot connect to the core server.',
    '4000001': 'An unknown error occurred.',
    '4000002': 'Cannot connect to the core server.',
    '4000003': 'An unknown error occurred.',
    '4000100': 'Cannot connect to the core server.',
    '4000101': 'Cannot connect to the core server.',
    '4000102': 'An unknown error occurred.',
    '4000103': 'The response is unknown.',
    '4000104': 'Cannot connect to the core server.',
    '4000105': 'The response is unknown.',
    '4000106': 'Cannot connect to the core server.',
    '4000107': 'The response is unknown.',
    '9999999': 'The error text has to be set.',
  },
  countrys: {
    ABW: 'ABW',
    AFG: 'AFG',
    AGO: 'AGO',
    AIA: 'AIA',
    ALA: 'ALA',
    ALB: 'ALB',
    AND: 'AND',
    ANT: 'ANT',
    ARE: 'ARE',
    ARG: 'ARG',
    ARM: 'ARM',
    ASM: 'ASM',
    ATA: 'ATA',
    ATF: 'ATF',
    ATG: 'ATG',
    AUS: 'AUS',
    AUT: 'AUT',
    AZE: 'AZE',
    BDI: 'BDI',
    BEL: 'BEL',
    BEN: 'BEN',
    BES: 'BES',
    BFA: 'BFA',
    BGD: 'BGD',
    BGR: 'BGR',
    BHR: 'BHR',
    BHS: 'BHS',
    BIH: 'BIH',
    BLM: 'BLM',
    BLR: 'BLR',
    BLZ: 'BLZ',
    BMU: 'BMU',
    BOL: 'BOL',
    BRA: 'BRA',
    BRB: 'BRB',
    BRN: 'BRN',
    BTN: 'BTN',
    BVT: 'BVT',
    BWA: 'BWA',
    CAF: 'CAF',
    CAN: 'CAN',
    CCK: 'CCK',
    CHE: 'CHE',
    CHL: 'CHL',
    CHN: 'CHN',
    CIV: 'CIV',
    CMR: 'CMR',
    COD: 'COD',
    COG: 'COG',
    COK: 'COK',
    COL: 'COL',
    COM: 'COM',
    CPV: 'CPV',
    CRI: 'CRI',
    CUB: 'CUB',
    CUW: 'CUW',
    CXR: 'CXR',
    CYM: 'CYM',
    CYP: 'CYP',
    CZE: 'CZE',
    DEU: 'DEU',
    DJI: 'DJI',
    DMA: 'DMA',
    DNK: 'DNK',
    DOM: 'DOM',
    DZA: 'DZA',
    ECU: 'ECU',
    EGY: 'EGY',
    ERI: 'ERI',
    ESH: 'ESH',
    ESP: 'ESP',
    EST: 'EST',
    ETH: 'ETH',
    EUE: 'EUE',
    FIN: 'FIN',
    FJI: 'FJI',
    FLK: 'FLK',
    FRA: 'FRA',
    FRO: 'FRO',
    FSM: 'FSM',
    GAB: 'GAB',
    GBD: 'GBD',
    GBN: 'GBN',
    GBO: 'GBO',
    GBP: 'GBP',
    GBR: 'GBR',
    GBS: 'GBS',
    GEO: 'GEO',
    GGY: 'GGY',
    GHA: 'GHA',
    GIB: 'GIB',
    GIN: 'GIN',
    GLP: 'GLP',
    GMB: 'GMB',
    GNB: 'GNB',
    GNQ: 'GNQ',
    GRC: 'GRC',
    GRD: 'GRD',
    GRL: 'GRL',
    GTM: 'GTM',
    GUF: 'GUF',
    GUM: 'GUM',
    GUY: 'GUY',
    HKG: 'HKG',
    HMD: 'HMD',
    HND: 'HND',
    HRV: 'HRV',
    HTI: 'HTI',
    HUN: 'HUN',
    IDN: 'IDN',
    IMN: 'IMN',
    IND: 'IND',
    IOT: 'IOT',
    IRL: 'IRL',
    IRN: 'IRN',
    IRQ: 'IRQ',
    ISL: 'ISL',
    ISR: 'ISR',
    ITA: 'ITA',
    JAM: 'JAM',
    JEY: 'JEY',
    JOR: 'JOR',
    JPN: 'JPN',
    KAZ: 'KAZ',
    KEN: 'KEN',
    KGZ: 'KGZ',
    KHM: 'KHM',
    KIR: 'KIR',
    KNA: 'KNA',
    KOR: 'KOR',
    KWT: 'KWT',
    LAO: 'LAO',
    LBN: 'LBN',
    LBR: 'LBR',
    LBY: 'LBY',
    LCA: 'LCA',
    LIE: 'LIE',
    LKA: 'LKA',
    LSO: 'LSO',
    LTU: 'LTU',
    LUX: 'LUX',
    LVA: 'LVA',
    MAC: 'MAC',
    MAF: 'MAF',
    MAR: 'MAR',
    MCO: 'MCO',
    MDA: 'MDA',
    MDG: 'MDG',
    MDV: 'MDV',
    MEX: 'MEX',
    MHL: 'MHL',
    MKD: 'MKD',
    MLI: 'MLI',
    MLT: 'MLT',
    MMR: 'MMR',
    MNE: 'MNE',
    MNG: 'MNG',
    MNP: 'MNP',
    MOZ: 'MOZ',
    MRT: 'MRT',
    MSR: 'MSR',
    MTQ: 'MTQ',
    MUS: 'MUS',
    MWI: 'MWI',
    MYS: 'MYS',
    MYT: 'MYT',
    NAM: 'NAM',
    NCL: 'NCL',
    NER: 'NER',
    NFK: 'NFK',
    NGA: 'NGA',
    NIC: 'NIC',
    NIU: 'NIU',
    NLD: 'NLD',
    NOR: 'NOR',
    NPL: 'NPL',
    NRU: 'NRU',
    NTZ: 'NTZ',
    NZL: 'NZL',
    OMN: 'OMN',
    PAK: 'PAK',
    PAN: 'PAN',
    PCN: 'PCN',
    PER: 'PER',
    PHL: 'PHL',
    PLW: 'PLW',
    PNG: 'PNG',
    POL: 'POL',
    PRI: 'PRI',
    PRK: 'PRK',
    PRT: 'PRT',
    PRY: 'PRY',
    PSE: 'PSE',
    PYF: 'PYF',
    QAT: 'QAT',
    REU: 'REU',
    RKS: 'RKS',
    ROU: 'ROU',
    RUS: 'RUS',
    RWA: 'RWA',
    SAU: 'SAU',
    SDN: 'SDN',
    SEN: 'SEN',
    SGP: 'SGP',
    SGS: 'SGS',
    SHN: 'SHN',
    SJM: 'SJM',
    SLB: 'SLB',
    SLE: 'SLE',
    SLV: 'SLV',
    SMR: 'SMR',
    SOM: 'SOM',
    SPM: 'SPM',
    SRB: 'SRB',
    SSD: 'SSD',
    STP: 'STP',
    SUR: 'SUR',
    SVK: 'SVK',
    SVN: 'SVN',
    SWE: 'SWE',
    SWZ: 'SWZ',
    SXM: 'SXM',
    SYC: 'SYC',
    SYR: 'SYR',
    TCA: 'TCA',
    TCD: 'TCD',
    TGO: 'TGO',
    THA: 'THA',
    TJK: 'TJK',
    TKL: 'TKL',
    TKM: 'TKM',
    TLS: 'TLS',
    TON: 'TON',
    TTO: 'TTO',
    TUN: 'TUN',
    TUR: 'TUR',
    TUV: 'TUV',
    TWN: 'TWN',
    TZA: 'TZA',
    UGA: 'UGA',
    UKR: 'UKR',
    UMI: 'UMI',
    UNA: 'UNA',
    UNK: 'UNK',
    UNO: 'UNO',
    URY: 'URY',
    USA: 'USA',
    UZB: 'UZB',
    VAT: 'VAT',
    VCT: 'VCT',
    VEN: 'VEN',
    VGB: 'VGB',
    VIR: 'VIR',
    VNM: 'VNM',
    VUT: 'VUT',
    WLF: 'WLF',
    WSM: 'WSM',
    XBA: 'XBA',
    XCC: 'XCC',
    XCE: 'XCE',
    XCO: 'XCO',
    XDC: 'XDC',
    XEC: 'XEC',
    XKX: 'XKX',
    XIM: 'XIM',
    XOM: 'XOM',
    XPO: 'XPO',
    XXA: 'XXA',
    XXB: 'XXB',
    XXC: 'XXC',
    XXX: 'XXX',
    YEM: 'YEM',
    ZAF: 'ZAF',
    ZMB: 'ZMB',
    ZWE: 'ZWE',
  },
  backoffice: {
    accountType: 'Account Type',
    accountTypeAll: 'All',
    searchHelp: {
      title: 'Search help',
      a: 'Default: Enter text to search for memo',
      b:
        'Search specific fields: Enter field name and search value seperated with a colon, if you want to search more fields (AND claused), add a comma between every key/value pair. E.g: "account_number: 1234, memo: abc"',
      c: 'Valid search keys: account_number, iban, memo, name, custom_name',
    },
    results: 'Results',
    approvals: {
      title: 'Approvals',
      approve: 'Approve',
      cancel: 'Cancel',
      reject: 'Reject',
      status: {
        N: 'New',
        W: 'Waiting',
        C: 'Canceled',
        R: 'Approved',
      },
      header: {
        approval: 'Approve',
        created: 'Created',
        requestesBy: 'Requested By',
        requestedByMail: 'Requersters E-Mail',
        requieredReleases: 'Requiered Approvals',
        releaseCount: 'Approvals',
        name: 'Requesting User',
        status: 'State',
        type: 'Type',
        lastModified: 'Last Modified',
        client: 'Client',
        block_reason: 'Block reason',
        blocked: 'Blockstatus',
      },
      details: {
        name: 'Name',
        city: 'City',
        postcode: 'Post code',
        street: 'Street',
        country: 'Country',
        email: 'Email',
        employer_country: 'Employer country',
        employer_city: 'Employer city',
        employer_postcode: 'Employer Postcode',
        employer_street: 'Employer street',
        employer_name: 'Employer',
        source_of_income: 'Sources of income',
        incomme: 'Monthly income',
        document: 'Dokument',
        address_id: 'Address ID',
        AddressApproved: {
          approved: 'approved',
        },
        account_numbers: 'Related accounts',
        additional: 'Additional Data',
        openPerson: 'Open customer',
        approver: 'Approved by',
        error: 'Approval reason',
        titleTrans: 'Transaction Information',
        CustomerCreditTransfer: {
          creditorAccount: 'Creditor account',
          creditorName: 'Creditor name',
          debtorAccount: 'Debtor Account',
          instructedAmount: {
            amount: 'Amount',
            currency: 'Currency',
          },
          remittanceInformationUnstructured: 'Remittance information',
          save_new_favorite: 'Save new Favorite',
        },
        ta_id: 'Ta ID',
        transaction_id: 'Transaction ID',
        SepaCreditTransfer: {
          creditorAccount: {
            accountNumber: 'Creditor account',
            iban: 'Creditor iban',
          },
          debtorAccount: {
            accountNumber: 'Debtor account',
            iban: 'Empfänger iban',
          },
          instructedAmount: {
            amount: 'Amount',
            currency: 'Currency',
          },
          remittanceInformationUnstructured: 'Remittance information',
          creditorName: 'Creditor Name',
        },
        NewEmoneyPersonWithUsertype: {
          address: {
            address_type: 'Address type',
            building_identifier: 'Building identifier',
            city: 'City',
            country: 'Country',
            country_subentity: 'Country Subentity',
            district_name: 'District',
            floor_identifier: 'Floor Identifier',
            post_box: 'Post box',
            post_code: 'Post code',
            street: 'Street',
            suite_identifier: 'Suite identifier',
          },
          birth_city: 'City of birth',
          birth_date: 'Date of birth',
          email_address: 'E-Mail address',
          given_name: 'Given name',
          language: 'Language',
          name: 'Name',
          product: 'Product',
          sex: 'Sex',
          user_class: 'User class',
          entity_id: 'Entity ID',
        },
        person_id: 'Person ID',
        partner_id: 'Partner ID',
        reason: 'Reason',
        comment: 'Comment',
        PersonUpdateRequest: {
          birth_city: 'City of birth',
          birth_date: 'Date of birth',
          email: 'E-Mail address',
          given_name: 'Given name',
          language: 'Language',
          name: 'Name',
          product: 'Product',
          sex: 'Sex',
          user_class: 'User class',
        },
      },
    },
    search: {
      title: 'Search Account',
      searchTrans: 'Search transaction',
      input: {
        account_number: {
          label: 'Account number',
          placeholder: 'Enter account number',
        },
        accountType: 'Account typ',
        accountTypeAll: 'All Account types',
        iban: {
          label: 'IBAN',
          placeholder: 'Enter IBAN',
        },
        memo: {
          label: 'Memo',
          placeholder: 'Enter Memo',
        },
        name: {
          label: 'Account name',
          placeholder: 'Enter account name',
        },
        custom_name: {
          label: 'Custom account name',
          placeholder: 'Enter custom account name',
        },
        primanota: {
          label: 'Transaction-ID',
          placeholder: 'Enter Transaction-ID',
        },
      },
    },
  },
  button: {
    addNewCustomer: 'Add new customer',
    addNewMember: 'Add new member',
    cancel: 'Cancel',
    changePassword: 'Change password',
    close: 'Close',
    create: 'Create',
    gotoDashboard: 'Go to dashboard',
    next: 'Next',
    ok: 'Ok',
    results: 'Results',
    save: 'Save',
    search: 'Search',
    send: 'Send',
    sendInformation: 'Send information',
    signin: 'Sign in',
    today: 'Today',
    button: 'Start recognition',
    valid: 'Valid',
    notValid: 'Not valid',
    void: 'Void',
    positive: 'Positive',
    negative: 'Negative',
  },
  confirmation: {
    transactionSuccessful: {
      title: 'Transaction successful',
      message: 'The money was successfully transferred to the recipient.',
    },
  },
  contextMenu: {
    copy: 'Copy',
    paste: 'Paste',
    manageAddress: 'Manage address',
    edit: 'Edit',
    postingJournal: 'Posting journal',
    transactionDetails: 'Transaction details',
    cancel: 'Cancel',
    revert: 'Storno',
    openCustomer: 'Open customer',
    moneyStatement: 'Statement of Account',
    manageEmail: 'Manage Email addresses',
    managePhone: 'Manage Phone numbers',
    openAccount: 'Open in Backoffice',
    startQueue: 'Start validation',
    openLimits: 'Limit checks',
    refund: 'Refund',
    invoice: 'Request invoice',
    storno: 'Storno Document',
  },
  customers: {
    openapprovals: {
      title: 'Open address approvals',
      name: 'Name',
      given_name: 'Given name',
      datetime_due: 'Due since',
      email_address: 'Email address',
    },
    juniorAccount: {
      title: 'Junioraccount',
      turns18: 'Turns 18 at',
    },
    addressRequirement: {
      setup: 'Request document again',
      requirement: 'Document not valid because:',
    },
    opendocuments: 'Open document approvals',
    limits: {
      types: {
        5: 'Daily (In)',
        6: 'Weekly (In)',
        1: 'Monthly (In)',
        2: 'Monthly (Out)',
        4: 'Daily (Out)',
        7: 'Weekly (Out)',
        9: 'Yearly (Out)',
        8: 'Yearly (In)',
        10: 'Daily',
        11: 'Weekly',
        12: 'Monthly',
        13: 'Yearly',
        14: 'Daily',
        15: 'Weekly',
        16: 'Monthly',
        17: 'Yearly',
        18: '',
        19: 'Daily(Checkout Topup)',
        20: 'Monthly(Checkout Topup)',
        21: 'Yearly(Checkout Topup)',
        22: 'Daily Transactions(Checkout Topup)',
      },
      transactionLimit: 'Transaction limit incoming',
      transactionLimitOut: 'Transaction limit outgoing',
      widthdrawLimit: 'Withdrawal limit',
      uploadLimit: 'Upload limit',
      widthdrawDescription: 'The maximum amount up until any user can make uploads',
      uploadDesciption: 'The maximum amount up until any user can make widthdrawals',
      transDescription: 'The maximum amount up until any user can make transactions',
      usage: 'Usage',
      swap: 'Swap between relative and absolute value',
      applied: 'Checks(C/W/B)*',
      check: 'Limits checked',
      write: 'Limits written',
      balance: 'Balance checked',
    },
    products: {
      title: 'Product details',
      productid: 'Product-ID',
      types: 'Product Producer',
      name: 'Name',
      producer: 'Prpducer',
      signature: 'Signature',
      id: 'Product type id',
      stamp: 'Stamp',
      signatureStamp: 'Signature/Stamp',
      paymentMethods: 'Payment methods',
      paypal: 'Paypal',
      stripe: 'Stripe',
      at_merchant: 'At merchant',
      description: 'Description',
      claim: 'Claim',
      default_merchant_provision: 'Merchant provision',
      price: 'Price',
      reveive_test: 'Forward test result to',
      noweda: 'Noweda standard',
      typeOfTest: 'Type of test',
      fast_track: 'Fast Track',
      image: 'Image',
      imageSrc: 'Image-URL',
      vat: 'Value added tax',
      package: 'Produkt package',
      group: 'Unique group',
      voucherAmount: 'Voucher amount',
      endTime: 'End time',
      time: {
        hour: 'Hour',
        minute: 'Minute',
        second: 'Second',
      },
    },
    title: {
      title: 'Salutation',
      mr: 'Mr',
      mrs: 'Mrs',
      undefined: 'Unspecified',
      notSet: 'Select Salutation',
    },
    sex: {
      mr: 'Male',
      mrs: 'Female',
      undefined: 'Unspecified',
      notSet: '',
    },
    emails: {
      primary: 'Primary Email address',
      secondary: 'Secondary Email addresses',
      primaryMails: 'Primary address',
      secondaryMails: 'Secondary addresses',
    },
    phoneNumbers: {
      title: 'Phone Numbers',
      primary: 'Primary phone number',
      secondary: 'Secondary phone numbers',
    },
    feesTab: {
      name: 'Transaction type',
      price: 'Price',
      used: 'Usage',
      empty: 'No booked packages found',
    },
    cardTab: {
      type: 'Card type',
      types: {
        credit_card: 'Credit card',
        debit_card: 'Debit card',
      },
      state: 'State',
      states: {
        A: 'Active',
        P: 'Pending',
        D: 'Deleted',
        O: 'Ordered',
        B: 'Blocked',
      },
      id: 'Agreement ID',
      provider: 'Provider',
      custNumBpc: 'BPC customer number',
      accNumBpc: 'BPC account number',
      bpsState: 'BPC-State',
      cardId: 'Card ID',
      color: 'Color',
      expiry: 'Expiry',
      name: 'Name',
      card: 'Card',
      customerCard: 'Cardagreement -',
      barCode: 'Bar Code',
      bpcCardId: 'BPC card ID',
      virtOrPhys: 'Virtual/Physical',
      virtOrPhyType: {
        P: 'Physical',
        V: 'Virtual',
      },
    },
    product: 'Product',
    newImage: 'Upload new Image: ',
    selectImage: 'Select product image',
    KYC: 'KYC-Data',
    resetKyc: 'Request KYC',
    manageAddress: 'Manage addresses',
    accountNumber: 'Account number',
    clientProfile: 'Client profile',
    accountType: 'Account type',
    accountOpeningDate: 'Account opening date',
    accountclosingDate: 'Date of closure',
    preferences: 'Preferences',
    expiryDate: 'Date of expiration',
    currentBalance: 'Current balance',
    availableBalance: 'Available balance',
    newAddress: 'New address',
    phone: 'Phone number',
    fees: 'Fees',
    user_id: 'User ID',
    junior: 'Junior Account',
    is_staff: 'Staff',
    parent_person_id: 'Parent customer id',
    parent_name: 'Parent name',
    parent_given_name: 'Parent given name',
    user_class: 'Family relation',
    customerNumber: 'Customers number',
    barcode: 'Test Barcode',
    validPassport: 'Valid Document',
    customersIdent: 'External IDs',
    doc_back: 'Dokument back',
    portrait: 'Portrait',
    doc_front: 'Dokument front',
    date_of_ident: 'Date of identification',
    personal_number: 'Personal number',
    issuing_state_code: 'Issuing state code',
    document_class_code: 'Document type',
    enteredNationality: 'Received nationality',
    correctedNationality: 'Actual nationality',
    vouchers: 'Vouchers',
    voucherTypes: {
      onceOnly: 'Once only',
      unlimited: 'Unlimited',
    },
    advancedKyc: {
      advancedKyc: 'Advanced KYC',
      advancedKycHeader: 'Advanced KYC is beein overwritten',
      advancedKycWarning:
        'Sending of this data will remove the advanced KYC-state of this person. Continue?',
      create: 'Create',
      income: {
        EarnedIncome: 'Earned income',
        BusinessIncome: 'Business income',
        InterestIncome: 'Intertest income',
        DividendIncome: 'Divident income',
        RentalIncome: 'Rental income',
        CapitalGains: 'Capital gains',
        Royalties: 'Royalties',
      },
      avgIncome: {
        below_1000: 'Below 1000',
        between_1000_2000: '1000 - 2000',
        between_2000_5000: '2000 - 5000',
        between_5000_10000: '5000 - 10000',
        above_10000: 'Above 10000',
        below_500: 'Below 500',
        between_500_1000: '500 - 1000',
        above_5000: 'Above 5000',
      },
      states: {
        D: 'Deposited',
        A: 'Approved',
        C: 'Rejected',
        P: 'Approved',
        O: 'Overwritten',
      },
      input: {
        document: 'Document',
        state: 'State',
        datetime_create: 'Datetime create',
        datetime_invalidate: 'Datetime invalidate',
        employer: {
          label: 'Employer',
          placeholder: 'Enter Employer',
        },
        employer_street: {
          label: 'Employer street',
          placeholder: 'Enter employer street',
        },
        employer_country: {
          label: 'Employer country',
          placeholder: 'Enter employer country',
        },
        employer_post_code: {
          label: 'Employer post code',
          placeholder: 'Enter employer post code',
        },
        employer_city: {
          label: 'Employer city',
          placeholder: 'Enter employer city',
        },
        source_of_income: {
          label: 'Sources of income',
          placeholder: 'Enter sources of income',
        },
        monthly_income: {
          label: 'Monthly income',
          placeholder: 'Enter monthly income',
        },
        employer_building_identifier: {
          label: 'Employer building identifier',
          placeholder: 'Enter employer building identifier',
        },
      },
    },
    noweda: 'Pharmacy-ID',
    pharmadirekt: 'Pharmadirekt-ID',
    customersAddress: 'Secondary Addresses',
    languageSettings: 'General settings',
    differing_comission: 'Differing Commission',
    notificationSettings: 'Notification settings',
    activateAll: 'Set all',
    deactiveAll: 'Deactivate All',
    balance: 'Balance',
    birthdate: 'Birthdate',
    identification: 'Personal Data',
    blocked: 'Block Account',
    blockedSince: 'Blocked since',
    cards: 'Cards',
    buildingIdentifier: 'Building Identifier',
    city: 'City',
    cityOfBirth: 'City of birth',
    closeAccount: 'Close account',
    reset: 'Set password',
    closeAccountNo: 'Close account no.',
    closed: 'Closed',
    inClosure: 'In Closure',
    isBlocked: 'Account blocked',
    closedSince: 'Closed since',
    currency: 'Currency',
    downloadQR: 'Download QR-Code',
    customerDetails: 'Contact',
    documentType: 'Document Type',
    noDocuments: 'No Documents found',
    documentNumber: 'Document Number',
    personalNumber: 'Personal Number',
    validationDate: 'Validation Date',
    issuingCountry: 'Issuing country',
    issuingAuth: 'Issuing authority',
    dateOfIdentification: 'Date of the identification process',
    date: 'Date',
    email: 'Email',
    givenName: 'Given name',
    iban: 'IBAN',
    localIban: 'Local IBAN',
    interestRate: 'Interest Rate',
    lastTransaction: 'Last transaction',
    name: 'Name',
    results: 'Results',
    street: 'Street',
    sure: 'Are you sure?',
    validUntil: 'Valid until',
    zip: 'ZIP Code',
    district_name: 'District',
    floor_identifier: 'Floor Identifier',
    post_box: 'Post Box',
    suite_identifier: 'Suite Identifier',
    country_subentity: 'Country subentity',
    accountActivity: 'Mail Notifications',
    language: 'Language',
    sendMoney: 'send Money',
    receiveMoney: 'receive Money',
    messageFriends: 'Push Notifications',
    requestMoney: 'request Money',
    takeOver: 'take over',
    splitMoney: 'split Money',
    close: {
      reason: 'Reason',
      ClientRequest: 'Client request',
      AMLTechnicalError: 'AML/Technical error',
      Other3rdPartyRequest: 'Other 3rd party request',
      ImmediateClosure: 'Immediate closure',
      additional: {
        label: 'Additional reason',
        placeholder: 'Enter additional reason',
      },
      block: {
        label: 'Block reason',
        placeholder: 'Enter block reason',
      },
    },
    details: {
      approval: 'Approve',
      release: 'Releases',
      connections: 'Linked connections',
      externalAccounts: {
        externalAccounts: 'external Accounts',
        expiredDate: 'Card expired',
        invalidCardNumber: 'Invalid card number',
        invalidCode: 'Invalid security code',
        invalidExpiryDate: 'Invalid expiry date (MM/YY)',
        missingCardNumber: 'Missing card number',
        missingExpiryDate: 'Missing expiry date',
        missingName: 'Missing card holder',
        unsupportedBrand: 'Unsupported card provider',
        fetchError: 'Error while fetching external Accounts',
        input: {
          cardNumber: {
            label: 'Debit card number',
            placeholder: 'Enter debit card number',
          },
          receiver: {
            label: 'Receiver',
            placeholder: 'Enter receiver',
          },
          amount: {
            label: 'Amount',
            placeholder: 'Enter amount',
          },
          quantitiy: {
            label: 'Quantity',
            placeholder: 'Enter quantity',
          },
          validTo: {
            label: 'Valid to',
            placeholder: 'Select Date',
          },
          testCenter: {
            label: 'At testcenter',
            placeholder: 'Select testcenter',
          },
          type: {
            label: 'Voucher type',
            placeholder: 'Select voucher type',
          },
          expiry: {
            label: 'Valid until',
            placeholder: 'MM/YY',
          },
          name: {
            label: 'Card holder',
            placeholder: "Enter card holder's name",
          },
          securityCode: {
            label: 'Security code',
            placeholder: 'Enter security code',
          },
          bank: {
            label: 'Bank',
            placeholder: "Enter Bank's name",
          },
          acceptance: {
            label: 'Acceptance',
            placeholder: 'enter state of acceptance',
          },
        },
      },
      companions: {
        C: 'Companion',
        P: 'Primary',
        name: 'Name',
        email: 'E-Mail',
        phone: 'Phone',
        type: 'Companiontype',
        linkedPersons: 'Linked persons',
        familty_relation: 'Family relation',
        relation: 'Relation',
        first_degree_relative: 'First degree relative',
        second_degree_relative: 'Second degree relative',
        third_degree_relative: 'Third degree relative',
        fourth_degree_relative: 'Fourth degree relative',
        dependant_relationship: 'Dependant relationship',
        other_official_relationship: 'Other official relationship',
        other_person: {
          label: 'Email Primary',
          placeholder: 'Enter primary email',
        },
        documents: 'Documents',
      },
      merchant: {
        merchant: 'Business account',
        merchantStatus: 'Business account Status',
        calendarData: 'Calendar',
        openCalendarData: 'Opening times',
        uploadDocument: 'Upload Document',
        product_types: 'Select Producttype',
        upload: 'Confirm',
        generateAccessKey: 'Generate Accesskey',
        accessKeyId: 'accesskeyID',
        status: 'state',
        created: 'created',
        makeInactiv: 'make inactive',
        deactivated: 'deactivated',
        select: 'Select file..',
        current: 'Current Documents: ',
        timezone: 'Timezone',
        noneTimezone: 'No timezone selected',
        profile: 'Logo',
        products: 'Products',
        description: 'Description',
        claim: 'Claim',
        image: 'Image',
        calendar: 'Calendar ID',
        add: 'Add calendar item',
        segment: 'Segments',
        webaddress: 'Web address',
        fiskaly: {
          vat: {
            label: 'VAT-ID',
            placeHolder: 'Enter VAT-ID',
          },
          econ: {
            label: 'EconomyID',
            placeHolder: 'Enter EconomyID',
          },
          title: 'Fiskaly Merchant Data',
          orgaId: 'Organization-ID',
          tseID: 'TSS-ID',
          apiKey: 'Api-Key',
          taxNr: {
            label: 'TaxID',
            placeHolder: 'Enter  TaxID',
          },
        },
        segments: {
          Restaurant: 'Restaurant',
          Military: 'Military camp',
          Canteen: 'Canteen',
          Testcenter: 'Testcenter',
          Club: 'Club',
          none: 'No Segment selected',
        },
        calendarItems: {
          0: 'Sunday',
          1: 'Monday',
          2: 'Tuesday',
          3: 'Wednesday',
          4: 'Thursday',
          5: 'Friday',
          6: 'Saturday',
          timeslotLength: 'Timeslot',
          timeslotCapacity: 'Capacity',
          exclude: 'Excluded',
          start: 'Start time',
          dateRangeDays: 'Range(Days)',
          end: 'End time',
          excluded: 'Excluded',
          exceptions: 'Exceptional Openings',
        },
      },
      Voucher: {
        missing: 'Mandatory fields are empty: ',
        sendAt: 'Sent to',
        amount: 'Amount',
        createdAt: 'Created at',
        validTo: 'Valid to',
        createdBy: 'Created by',
        email: 'Receiver E-Mail',
        product: 'Product',
        quantity: 'Quantity',
        testcenter: 'Limited to',
        download: 'Download',
        code: 'Code',
        createVoucher: 'Create Voucher',
        boundProduct: 'For product',
        openVouchers: 'Open Vouchers',
        status: 'Status',
        statusTypes: {
          A: 'Active',
          U: 'Used',
          D: 'Deleted',
        },
      },
      KYC: {
        state: {
          C: 'Closed',
          B: 'Blocked',
          V: 'Verified',
          R: 'Pending',
          I: 'Identified',
        },
        requestKYC: {
          header: 'Request KYC',
          message: 'New KYC will be requested. Are you sure',
        },
        password: {
          header: 'Reset password',
          message: 'Password will be reseted. Are you sure?',
        },
        systemStates: {
          eid_session_initiated: 'eid session initiated',
          eid_session_completed: 'eid session completed',
          eid_data_delivered: 'eid data delivered',
          eid_response_received: 'eid response received',
          assec_data_delivered: 'asseco data delivered',
          waiting_for_address_check: 'waiting for address check',
          address_confirmed: 'address approved',
          eid_response_received_with_status_ACCEPTED:
            'eid response received with status accepted',
          eid_response_received_with_status_REJECTED:
            'eid response received with status rejected',
          asseco_response_received: 'asseco response received',
          kyc_completed: 'kyc completed',
          identified: 'identified',
          password_reset_triggered: 'Password reset - Mail sent',
          password_reset: 'Password reset',
          core_data_changed: 'Base data  changed',
          core_data_added: 'Base data  added',
          A: 'KYC approved',
          W: 'KYC in progress',
          N: 'KYC requiered',
          I: 'KYC in progress',
          R: 'KYC rejected',
        },
        profile: {
          primary: 'Primary',
          companion: 'Companion',
        },
        status: 'State',
        datetimeCreate: 'Date of Creation',
        pep: 'PEP',
        fatca: 'FATCA',
        us_person: 'US-Person',
        own_behalf: 'Acts on own behalf',
        resident: 'Resident',
        systemState: 'System State',
        customerState: 'Customers State',
        age_check: 'Age Checked',
        last_updated: 'Last updated',
        client: 'Client-Status',
        eu_member: 'EU-Member',
        eid: {
          requests: 'EID-Requests',
          faileds: 'Failed VideoIDs',
          webhooks: 'Eid Notifications',
          request: {
            url: 'URL',
            datetime_create: 'Datetime create',
            session_id: 'Session ID',
            datetime_video_id: 'Datetime video ID',
            video_id: 'VideoID',
          },
          failed: {
            datetime_create: 'Datetime create',
            session_id: 'Session ID',
            failure_reason: 'Failure reason',
            failure_category: 'Failure category',
          },
          webhook: {
            datetime_create: 'Datetime create',
            notification_id: 'Notification ID',
            verification_id: 'Verification ID',
            video_id: 'Video ID',
            verification_request_id: 'Verification request ID',
          },
        },
        personHistory: {
          title: 'History',
          personCore: 'Person history',
          address: 'Address history',
          email: 'Email history',
          phone: 'Phone history',
          person: {
            name: 'Name',
            given_name: 'Given name',
            sex: 'Sex',
            birth_date: 'Birth date',
            birth_city: 'Birth city',
            company_name: 'Company name',
            nationality: 'Nationality',
            fatca: 'Fatca',
            is_merchant: 'Merchant',
            us_person: 'US person',
            state: 'State',
            resident: 'resident',
            own_behalf: 'On own behalf',
            pep: 'Pep',
            valid_from: 'Valid from',
            invalid_from: 'Invalid from',
            person_update: 'Updated by',
          },
          addresses: {
            street: 'Street',
            building_identifier: 'Building identifier',
            city: 'City',
            country: 'Country',
            suite_identifier: 'Suite identifier',
            floor_identifier: 'Floor identifier',
            district_name: 'District',
            post_box: 'Post box',
            post_code: 'Post code',
          },
          emails: {
            email_address: 'Email address',
            datetime_confirmed: 'Confirmed',
          },
          phones: {
            phone_number: 'Phone number',
          },
        },
      },

      settings: {
        fetchError: 'Error while fetching settings',
      },

      phone: {
        list: {
          error: 'Error while fetching phone numbers',
          success: 'Successfully fetched phone numbers',
        },
        delete: {
          error: 'Error deleting phone number',
          success: 'Successfully deleted phone number',
        },
      },
    },

    error: {
      link: 'For editing existing client please click here',
      subtitle: 'Customer already exists',
      title: 'Error',
    },
    newCustomer: {
      input: {
        address: {
          label: 'Street',
          placeholder: 'Enter street',
        },
        district_name: {
          label: 'District Name',
          placeholder: 'Enter district name',
        },
        floor_identifier: {
          label: 'Floor identifier',
          placeholder: 'Enter floor identifier',
        },
        suite_identifier: {
          label: 'Suite identifier',
          placeholder: 'Enter suite identifier',
        },
        country_subentity: {
          label: 'Country subentity',
          placeholder: 'Enter country subentity',
        },
        post_box: {
          label: 'Postbox',
          placeholder: 'Enter Postbox',
        },
        number: {
          label: 'Building ID',
          placeholder: 'Enter Building ID',
        },
        birthdate: {
          label: 'Birthdate',
          placeholder: 'Select birthdate',
        },
        birthplace: {
          label: 'Place of birth',
          placeholder: 'Enter place of birth',
        },
        city: {
          label: 'City',
          placeholder: 'Enter city',
        },
        country: {
          label: 'Country',
          placeholder: 'Enter country',
        },
        type: {
          label: 'Address type',
          placeholder: 'Enter address type',
        },
        companyname: {
          label: 'Company name',
          placeholder: 'Your Companyname',
        },
        merchantDescription: {
          label: 'Short description',
          placeholder: 'Enter short description',
        },
        email: {
          label: 'Email',
          placeholder: 'Enter email',
        },
        firstname: {
          label: 'First name',
          placeholder: 'Enter first name',
        },
        gender: {
          label: 'Gender',
          placeholder: 'Enter gender',
        },
        lastname: {
          label: 'Last name',
          placeholder: 'Enter last name',
        },
        language: {
          label: 'Language',
          placeholder: 'Enter Language',
        },
        nationality: {
          label: 'Nationality',
          placeholder: 'Enter nationality',
        },
        phone: {
          label: 'Phone',
          placeholder: 'Enter phone number',
        },
        postCode: {
          label: 'Post code',
          placeholder: 'Enter post code',
        },
        state: {
          label: 'State',
          placeholder: 'Enter state',
        },
        latitude: {
          label: 'Latitude',
          placeholder: 'Enter latitude',
        },
        longitude: {
          label: 'Longitude',
          placeholder: 'Enter Longitude',
        },
      },
      title: 'New customer',
    },
    search: {
      input: {
        accountNumber: {
          label: 'Account number',
          placeholder: 'Enter account no.',
        },
        address: {
          label: 'Address',
          placeholder: 'Enter Address',
        },
        external: {
          label: 'External ID',
          placeholder: 'Enter external ID',
        },
        birthdate: {
          label: 'Birthdate',
          placeholder: 'Select birthdate',
        },
        cardNumber: {
          label: 'Card number',
          placeholder: 'Enter Card Number',
        },
        customerNumber: {
          label: 'Customer number',
          placeholder: 'Enter number',
        },
        email: {
          label: 'Email',
          placeholder: 'Enter email',
        },
        street: {
          label: 'Street',
          placeholder: 'Enter Street',
        },
        buildId: {
          label: 'Identifier',
          placeholder: 'Enter Identifier',
        },
        city: {
          label: 'City',
          placeholder: 'Enter City',
        },
        zip: {
          label: 'Zip code',
          placeholder: 'Enter zip code',
        },
        country: {
          label: 'Country',
          placeholder: 'Enter Country',
        },
        firstname: {
          label: 'First name',
          placeholder: 'Enter first name',
        },
        lastname: {
          label: 'Last name',
          placeholder: 'Enter last name',
        },
        searchID: {
          label: 'Search ID',
          placeholder: 'Enter search ID',
        },
        phone: {
          label: 'Phone number',
          placeholder: 'Enter phone number',
        },
        personIdType: {
          label: 'ID type',
          placeholder: 'Select ID type',
        },
        idTypes: {
          person_id: 'Person ID',
          eid_verification_id: 'EID verification ID',
          pharmacy_id: 'Pharmacy ID',
          pharmadirekt: 'Pharma-Direkt ID',
          noweda: 'Noweda ID',
          user_id: 'User ID',
          onfido_check_id: 'Onfido Check ID',
        },
        showHistory: {
          label: 'Search in history',
          placeholder: 'Search in history',
        },
      },
      title: 'Search customer',
    },
  },
  date: {
    jan: {
      short: 'Jan',
      long: 'January',
    },
    feb: {
      short: 'Feb',
      long: 'February',
    },
    mar: {
      short: 'Mar',
      long: 'March',
    },
    apr: {
      short: 'Apr',
      long: 'April',
    },
    may: {
      short: 'May',
      long: 'May',
    },
    jun: {
      short: 'Jun',
      long: 'June',
    },
    jul: {
      short: 'Jul',
      long: 'July',
    },
    aug: {
      short: 'Aug',
      long: 'August',
    },
    sep: {
      short: 'Sep',
      long: 'September',
    },
    oct: {
      short: 'Oct',
      long: 'October',
    },
    nov: {
      short: 'Nov',
      long: 'November',
    },
    dec: {
      short: 'Dec',
      long: 'December',
    },
    mon: {
      short: 'Mon',
      long: 'Monday',
    },
    thu: {
      short: 'Thu',
      long: 'Thursday',
    },
    wed: {
      short: 'Wed',
      long: 'Wednesday',
    },
    tue: {
      short: 'Tue',
      long: 'Tuesday',
    },
    fri: {
      short: 'Fri',
      long: 'Friday',
    },
    sat: {
      short: 'Sat',
      long: 'Saturday',
    },
    sun: {
      short: 'Sun',
      long: 'Sunday',
    },
  },
  documents: {
    selectDocuments: 'Select Documents',
    selectDocument: 'Select Document',
    uploadDocuments: 'Upload Document',
  },
  error: {
    noUserSelected: 'No user selected',
  },
  generic: {
    chooseReport: 'Choose report',
    createPosting: 'Create posting',
    enter: 'Enter {{title}}',
    isRequired: 'is required',
    report: 'Report',
    downloadReport: 'Download Report',
    pleaseEnterValid: 'Please enter a valid ',
    postingTemplate: 'Posting Template',
    selectPostingTemplate: 'Select Posting Template',
    selectReportTemplate: 'Select Report Template',
    hideOptionalFields: 'Hide optional fields',
    showOptionalFields: 'Show optional fields',
  },
  logOut: {
    heading: 'Your are being logged out now',
    message: 'Inactivity timer has exceeded',
    refresh: 'The Page needs to be refreshed now',
  },
  table: {
    noEntriesFound: 'No entries found',
  },
  paging: {
    rowsPerPage: 'Rows per page',
    of: 'of',
    showing: 'Showing',
  },
  input: {
    amountErrorInvalid: 'Please enter a valid amount',
    emailErrorRequired: 'Email is required',
    emailErrorInvalid: 'Please enter a valid email',
    passwordErrorRequired: 'Password is required',
    passwordErrorInvalid: 'Please enter a valid password',
    email: {
      label: 'Email',
    },
    password: {
      label: 'Enter password',
    },
    phone: {
      label: 'Phone',
    },
    firstname: {
      label: 'First name',
    },
    lastname: {
      label: 'Last name',
    },
    address: {
      label: 'Address',
    },
    city: {
      label: 'City',
    },
    postCode: {
      label: 'Post code',
    },
    state: {
      label: 'State',
    },
    gender: {
      label: 'Gender',
    },
    nationality: {
      label: 'Nationality',
    },
    customerNumber: {
      label: 'Customer number',
    },
    accountNumber: {
      label: 'Account number',
    },
    cardNumber: {
      label: 'Card number',
    },
    pin: {
      label: 'Your PIN',
    },
    nr: {
      label: 'House-Nr',
    },
    search: {
      label: 'Search',
    },
    passportId: {
      label: 'Passport ID',
    },
  },
  nav: {
    dashboard: 'Dashboard',
    customers: 'Customer',
    transactions: 'Transactions',
    postingTemplates: 'Posting templates',
    reports: 'Reports',
    backoffice: 'Backoffice',
    reporting: 'Reporting',
    administration: 'Administration',
    permissions: 'Permissions',
    fees: 'Fees',
    teams: 'Teams',
    settings: 'Settings',
    connections: 'Linked Connections',
  },
  overlay: {
    passwordTitle: 'If you want to change your password, please enter your current one first.',
    noElements: 'No Element given',
    confirmation: 'Confirmation required',
  },
  team: {
    newTeamMember: 'New Team-Member',
    roleAssignment: 'Role Assignment',
    required: {
      firstName: 'Please enter your first name',
      lastName: 'Please enter your last name',
      email: 'Please enter your email address',
    },
    header: {
      personId: 'Person Id',
      lastname: 'Last name',
      firstname: 'First name',
      email: 'Email',
    },
    input: {
      entity: {
        label: 'Entity',
      },
      email: {
        label: 'Email',
        placeholder: 'Enter email',
      },
      firstname: {
        label: 'First name',
        placeholder: 'Enter first name',
      },
      fullname: {
        label: 'Name',
      },
      lastname: {
        label: 'Last name',
        placeholder: 'Enter last name',
      },
      role: {
        label: 'Role',
        placeholder: 'Role',
      },
    },
  },
  tooltip: {
    copied: 'Copied!',
  },
  transactions: {
    dateFrom: 'Date from',
    dateTo: 'Date to',
    dateRangeEnd: 'End of daterange',
    dateRangeStart: 'Start of daterange',
    dateRange: 'Date range',
    downloadStatement: 'Download statement',
    endBalance: 'End balance',
    noPendingTransactions: 'No pending transactions',
    noTransactions: 'No transactions',
    startBalance: 'Start balance',
    transactions: 'Transactions',
    pending: 'Pending transactions',
    requests: 'Requested transactions',
    startBalHelper: 'Balance at startdate',
    endBalHelper: 'Balance at enddate',
    pendingHeader: 'Pending',
    hideReversal: 'Hide reverals',
    hideBlocked: 'Show closed',
    expandableTitle: 'Transaction Filters',
    header: {
      attachment: 'Attachement',
      is_blocked: 'Is Closed',
      information: 'Remittance Info',
      currency: 'Currency',
      date: 'Date',
      name: 'Name',
      transactionId: 'Transaction-ID',
      description: 'Booking Statement',
      bookingDate: 'Booking Date',
      valueDate: 'Valuta Date',
      amount: 'Amount',
      requestMoneyId: 'Request Money ID',
      statement: 'Key',
    },
    search: {
      placeholder: 'Search transactions',
    },
  },
  usermenu: {
    appInformation: 'App Information',
    customerCare: 'Customer Care',
    entity: 'Entity',
    helpCenter: 'Help Center',
    languages: 'Languages',
    logout: 'Logout',
    logoutTimer: 'Logout in {{minutes}} min',
    membersince: 'Member',
    notifications: 'Notifications',
    password: 'Password',
    security: 'Security',
    setLanguage: 'Language',
    settings: 'Settings',
    update: 'Update',
    version: 'Version',
    language: {
      sq: 'shqiptar',
      de: 'Deutsch',
      en: 'English',
      nl: 'Dutch',
      es: 'Spanish',
      it: 'Italian',
      ja: 'Japanese',
      sv: 'Swedish',
      da: 'Danish',
      zh: 'Chinese',
      albanian: 'Albanian',
      english: 'English',
      german: 'German',
    },
    touchID: 'Touch ID',
    selectImage: 'Upload new profile picture',
    showProfile: 'show profile',
    upgradeAccount: 'upgrade account',
    manageAccount: 'Manage Account',
    closeAccount: {
      title: 'close account',
      heading: 'Close your account',
      message:
        'Are you sure that you want to close your account? All saved data will be lost and cannot be restored.',
      button: 'Close account',
    },
  },
  messageMenu: {
    header: 'Messages',
    fetchError: 'Error while fetching notifications',
  },
  clientStats: {
    registered: 'Registered clients',
    identified: 'Identified clients',
    transacting: 'Transacting clients',
  },
  messages: {
    noConfig: {
      error: 'No Config found',
      success: '',
    },
    noBackend: {
      error: 'Backend not reachable (Service unavailable)?',
      success: '',
    },
    loginNoData: {
      error: 'Username/Password required',
      success: '',
    },
    transactionGet: {
      error: 'Unknown Accoutnumber',
      success: '',
    },
    languageSet: {
      error: 'Failure on Setting Language',
      success: 'Successfully set Language',
    },
    addCustomer: {
      error: 'Failed to add customer',
      success: 'The customer was successfully added to the backend system.',
    },
    addCustomerExists: {
      error: 'Customer already exists',
      success: 'The customer was successfully added to the backend system.',
    },
    preferencesSave: {
      error: 'Error while saving settings',
      success: 'Settings successfully saved',
    },
    deleteAccount: {
      error: 'You can not leave us',
    },
    addTeamMember: {
      error: 'Unable to create a team member',
    },
    addTeamMemberRole: {
      error: 'Unable to add a role',
      success: 'Successfully added a team member and role',
    },
    updateTeamMemberRole: {
      error: 'Unable to update the role',
      success: 'Successfully updated the role',
    },
    downloadStatements: {
      error: 'Error while downloading Statements',
      success: 'Successfully downloaded Statements',
    },
    genericRequest: {
      success: 'Successfully performed generic request',
    },
    getTransactionsParams: {
      error: 'Missing parameters to fetch accountAccountNumberActivityDetailGet',
    },
    languageError: {
      error: 'unable to set Language',
    },
    setPersonEntity: {
      error: 'unable to set entity',
    },
    login: {
      error: 'Error while logging in',
      success: 'Successfully logged in',
    },
    partnerNotFound: {
      error: 'Partner not found',
    },
    passwordWrong: {
      error: 'Origin password wrong',
      success: 'Password changed',
    },
    postRequestError: {
      error: 'error appeared while post request',
    },
    putRequestError: {
      error: 'error appeared while performing put request',
    },
    general: {
      error: 'An unknown error occured',
      success: 'Success',
    },
  },
  transfer: {
    balance: 'Balance',
    selectAccount: 'Select Account',
    details: {
      accountFrom: 'Account from',
      attachments: 'Attachments',
      datetimeCreate: 'Datetime create',
      documents: 'Documents',
      documentsEid: 'Eid-Documents',
      documentsUpload: 'Upload Documents',
      emailAddressIdFrom: 'Email address id_from',
      instructedAmount: 'Amount',
      instructedAmountCurreny: 'Currency',
      emailAddressIdTo: 'Email To# ID',
      inOut: 'In/Out',
      localDatetime: 'Local datetime',
      location: 'Location',
      personIdFrom: 'Person Id from',
      purpose: 'Purpose',
      refMoneyTransferId: 'Reference Money Transfer ID',
      refTaId: 'Reference Transaction ID',
      referenceId: 'Reference ID',
      taId: 'Ta ID',
      text: 'text',
      title: 'Transaction Details',
      transactionSource: 'Transaction source',
      cancelTransaction: 'Are you sure?',
      cancelTransactionHeader: 'You are about to cancel the Transaction',
      tradeDate: 'Trade date',
      transactionId: 'Transaction-ID',
    },
    postingJournal: {
      bookingDate: 'Booking Date',
      leftTitle: 'send money',
      memo: 'Memo',
      source: 'Source',
      posting: 'Transaction-ID',
      printPdf: 'Print to pdf',
      rightTitle: 'Posting journal',
      valueDate: 'Creation Date',
      header: {
        account: 'Account',
        accountName: 'Account name',
        dc: 'D/C',
        subbalance: 'Sub Balance',
        statementId: 'Statement ID',
        key: 'Key',
        keyDescription: 'Description',
        amount: 'Amount',
        statementText: 'Statement',
      },
    },
  },
  service_center: {
    checkboxes: {
      open_testkit_during_video: 'Opened testkit during video',
      person_performs_test: 'Person performs test',
      testkit_always_in_video: 'Testkit always visible',
      video_not_cut: 'Video not cut',
      result_readable: 'Resultat readable',
      performing_person_and_testkit_match: 'Performing person and testkit match',
      barcode_readable: 'Barcode readable',
    },
    start_date: 'Start date',
    end_date: 'End date',
    duration: 'Duration',
    testkit: 'Testkit',
    testTime: 'Datetime',
    testtype: 'Test type',
    comment: {
      label: 'Comment(for internal usage only)',
      placeholder: 'Enter comment',
    },
    overlay: {
      title: 'Confirm Testresult',
      text:
        'This action confirms the testresult for this person. Its not possible to undo this setup',
    },
    overlayNores: {
      title: 'Confirm Validation',
      text:
        'This action confirms the Validation for this person. Its not possible to undo this setup',
    },
    types: {
      regula_approve_document: 'Document approval',
      eyeson: 'Video approval',
    },
    createVoucher: 'Create voucher',
    setVoucher: 'Enter voucher data',
  },
  productMaintenance: {
    title: 'Product maintenance',
    typeOfTest: 'Type of test',
    category: 'Category',
    longDescription: 'Long description',
    parent: 'Parent category',
    image: 'Image',
    mapedTypes: 'Types',
    categoryCode: 'Category Code',
    productConfig: 'Product configuration',
    productDelivery: {
      delivery_at_merchant: 'Delivery at merchant',
      delivery_at_home: 'Delivery at home',
      digital_product: 'Digital product',
    },
    productAppointment: {
      product_appointment: 'Product appointment',
      merchant_appointment: 'Merchant appointment',
      walk_in: 'Walk in',
    },
    deliveryType: 'Delivery type',
    appointmentType: 'Appointment type',
  },
  onfido: {
    read: 'Read',
    checkResult: 'Check result',
    name: 'Name',
    lastName: 'Last name',
    datetimeCreate: 'Date of creation',
    person_toggle: 'Processed by',
    showTicked: ' Show processed cases',
  },
};
