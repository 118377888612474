import React from 'react';
import styled from 'styled-components';
import {
  IRelativeProps,
  IRelativeState,
  RelativeOverlay,
} from '../../../logic/handler/overlayhandler/globaloverlays/aRelativeOverlay';

interface IProps extends IRelativeProps {
  message: string;
}

interface IState extends IRelativeState {}

export class ToolTipOverlay extends RelativeOverlay<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  reviveState() {}

  defineContent() {
    return (
      <MainView top={this.props.posY} left={this.props.posX}>
        <p>{this.props.message}</p>
      </MainView>
    );
  }
}

const MainView = styled.div<{ top: string; left: string }>`
  z-index: 100;
  position: absolute;
  box-sizing: border-box;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  box-shadow: ${props => props.theme.Box.boxShadow};
  font-size: 10px;
  background-color: #ffffca;
  min-width: 150px;
`;
