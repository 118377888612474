// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, throwIfRequired } from '../runtime';
import {
    CompanionConnection,
    NewEmoneyPersonWithUsertype,
    PersonId,
} from '../models/index';

export interface PersonConnectPostRequest {
    CompanionConnection: CompanionConnection;
}

export interface PersonCreateThirdPersonPostRequest {
    NewEmoneyPersonWithUsertype: NewEmoneyPersonWithUsertype;
}

/**
 * no description
 */
export class RegistrationApi extends BaseAPI implements IOperationIdMap {

    /**
     * connect the two given people using a companion_request
     * connect two people
     */
    personConnectPost = (requestParameters: PersonConnectPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'CompanionConnection', 'personConnectPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/connect',
            method: 'POST',
            headers,
            body: requestParameters.CompanionConnection,
        });
    };

    /**
     * create a person using name, given name in the body and email address/password in basic auth header. this email address will receive a mail to activate the account.
     * create a person
     */
    personCreateThirdPersonPost = (requestParameters: PersonCreateThirdPersonPostRequest): Observable<PersonId> => {
        throwIfRequired(requestParameters, 'NewEmoneyPersonWithUsertype', 'personCreateThirdPersonPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<PersonId>({
            path: '/person/signup_third_person',
            method: 'POST',
            headers,
            body: requestParameters.NewEmoneyPersonWithUsertype,
        });
    };


    operationToOperationId = {
	    "personConnectPost": "person_connect_post" ,
	    "personCreateThirdPersonPost": "person_create_third_person_post"
    }
}
