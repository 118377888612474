// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, throwIfRequired } from '../runtime';
import { Template } from '../models/index';

export interface PersonLoginRequest {
  body: string;
}

/**
 * no description
 */
export class LoginApi extends BaseAPI implements IOperationIdMap {
  /**
   * the dermalog android sdk only supports 1047byte templates, these are incompatible with the ones the abis or the linux/win sdks use. until then we have to do the encoding on the server side
   * interim solution until dermalog updates the android sdk to support 147byte templates
   */
  personLogin = (requestParameters: PersonLoginRequest): Observable<Template> => {
    throwIfRequired(requestParameters, 'body', 'personLogin');

    const headers: HttpHeaders = {
      'Content-Type': 'image/png',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<Template>({
      path: '/ident/person/login',
      method: 'POST',
      headers,
      body: requestParameters.body as any,
    });
  };

  operationToOperationId = {
    personLogin: 'person_login',
  };
}
