//@ts-nocheck
import React from 'react';
import styled from 'styled-components';
import { FlexBox } from '../../content/auth/auth.css';
import TouchableOpacity from '../atomiccompoents/buttons/touchableOpacity';

interface IProps {
  todayButton?: boolean;
  yesterdayButton?: boolean;
  setToday?: () => void;
  reset?: () => void;
  setYesterday?: () => void;
}

interface IState {
  todayButton: boolean;
  yesterdayButton: boolean;
  reset: boolean;
}

export class UtilityMenu extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      todayButton: props.todayButton != null ? props.todayButton : false,
      yesterdayButton: props.yesterdayButton != null ? props.yesterdayButton : false,
      reset: props.reset != null ? true : false,
    };
  }

  componentWillReceiveProps() {}

  render() {
    return (
      <Main>
        {this.state.todayButton ? (
          <ButtonWrapper>
            <SelectButton onClick={this.props.setToday}>
              <ButtonText>Today</ButtonText>
            </SelectButton>
          </ButtonWrapper>
        ) : null}
        {this.state.yesterdayButton ? (
          <ButtonWrapper>
            <SelectButton onClick={this.props.setYesterday}>
              <ButtonText>Yesterday</ButtonText>
            </SelectButton>
          </ButtonWrapper>
        ) : null}
        {this.state.reset ? (
          <ButtonWrapper>
            <SelectButton onClick={this.props.reset}>
              <ButtonText>Reset</ButtonText>
            </SelectButton>
          </ButtonWrapper>
        ) : null}
      </Main>
    );
  }
}

const Main = styled(FlexBox)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const SelectButton = styled(TouchableOpacity)``;

const ButtonWrapper = styled(FlexBox)`
  width: 60px;
  background-color: ${props => props.theme.Button.backgroundColor};
  box-shadow: ${props => props.theme.Box.boxShadow};
  padding: 2px;
  border-radius: 4px;
  margin-left: 15px;
  text-align: center;
`;
const ButtonText = styled(FlexBox)`
  font-size: 10px;
  padding: 0px;
  color: #ffffff;
`;
