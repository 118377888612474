import { ReactElement } from 'react';
import { OperationId } from '../../logic/api/ident';
import { PermissionStore, UserDataStore } from '../../logic/flux';
import { IPermissions } from '../../logic/types';

export interface IPage {
  display: boolean;
  active: boolean;
  activeCondition?: () => boolean;
  icon: string | null;
  text: string;
  showText: boolean;
  showIcon: boolean;
  showSubmenue?: boolean;
  url: string;
  category?: string;
  addButton?: boolean;
  addComponent?: ReactElement | null;
  subPages?: Array<IPage>;
  subPageActive?: boolean;
  permissions: IPermissions;
}

export abstract class APages {
  private pages: Array<IPage>;
  private PATH = '/';

  constructor(pages: Array<IPage>, rootPath: string = '/') {
    this.pages = pages;
    this.PATH = rootPath;
  }

  abstract getActiveComponent(currentUrl: string): ReactElement;

  getActivePagename(currentUrl = window.location.pathname): string {
    const pages = this.pages;

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];

      if (page.subPages !== undefined) {
        for (let i = 0; i < page.subPages.length; i++) {
          const subPage = page.subPages[i];

          if (currentUrl.includes(this.PATH + subPage.url)) {
            return subPage.text;
          }
        }
      }

      if (
        currentUrl.includes(this.PATH + page.url) ||
        (page.url === '/dashboard' && currentUrl === '/')
      ) {
        return page.text;
      }
    }

    return '';
  }

  getPages(currentUrl: string = '/'): Array<IPage> {
    const pages = this.pages;

    this.setPageActive(pages, currentUrl);

    return pages;
  }

  resetActivePages(pages: Array<IPage>): Array<IPage> {
    if (pages.length === 0) {
      return pages;
    }

    return pages.map((page: IPage) => {
      page.active = false;
      page.subPageActive = false;
      page.showSubmenue = false;

      if (page.subPages != null) {
        page.subPages = this.resetActivePages(page.subPages);
      }

      return page;
    });
  }

  setPageActive(pages: Array<IPage>, currentUrl: string): Array<IPage> {
    const defaultPageUrl = '/customers';

    const urlParts = currentUrl
      .split('?')[0]
      .replace(this.PATH + '/', '')
      .split('/')
      .filter(el => el.trim() !== '')
      .map(urlPart => (urlPart = '/' + urlPart.toLowerCase()));

    //default Page
    if (urlParts[0] == undefined || urlParts[0] == '/') {
      urlParts[0] = defaultPageUrl;
    }
    //no administration Page
    if (urlParts[1] == undefined && urlParts[0] == '/administration') {
      urlParts[1] = '/reports';
    }

    pages = this.resetActivePages(pages);

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];

      if (urlParts[0] === page.url.toLocaleLowerCase()) {
        // page.showSubmenue = true;
        page.active = urlParts.length == 1 || urlParts[1] == undefined || !page.showSubmenue;
      }

      page.subPageActive = false;
      if (urlParts[1] != null && page.subPages != null) {
        for (let i = 0; i < page.subPages.length; i++) {
          const subPage = page.subPages[i];
          if (urlParts[1] != null && urlParts[0] + urlParts[1] == subPage.url.toLowerCase()) {
            if (subPage.activeCondition == null || subPage.activeCondition() === true) {
              page.subPageActive = true;
              page.showSubmenue = true;
              subPage.active = true;
            }
          } else {
            subPage.active = false;
          }
        }
      }
    }

    return pages;
  }

  navPageClicked(pageUrl: string) {
    const pages = this.pages;

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];

      if (page.url == pageUrl) {
        if (page.active) {
          if (page.subPageActive) {
            page.subPageActive = false;
          } else {
            page.showSubmenue = !page.showSubmenue;
            page.subPageActive = false;
          }
        } else {
          page.showSubmenue = true;
          page.subPageActive = false;
        }
      }
    }
  }
}
