//@ts-nocheck
import React from 'react';
import { Ident } from '../../logic/api';
import { IItfAccount } from '../../logic/types';
import { CustomerDataStore, INotifyAbleCustomerData } from '../../logic/flux';

export interface IWithPersonAndAccountProps {}

export function withPersonAndAccount<P extends IWithPersonAndAccountProps, C>(
  // this type allows us to infer P, but grab the type of WrappedComponent separately without it interfering with the inference of P
  WrappedComponent: React.JSXElementConstructor<P> & C,
  // selectData is a functor for T
  // props is Readonly because it's readonly inside of the class
) {
  // the magic is here: JSX.LibraryManagedAttributes will take the type of WrapedComponent and resolve its default props
  // against the props of WithData, which is just the original P type with 'data' removed from its requirements
  type Props = JSX.LibraryManagedAttributes<C, Omit<P, 'person'>>;
  type State = {
    person?: Ident.Person;
    currentAccount?: IItfAccount;
  };
  return class WithData extends React.Component<Props, State>
    implements INotifyAbleCustomerData {
    constructor(props: Props) {
      super(props);
      this.state = {
        person: CustomerDataStore.getUser(),
        currentAccount: CustomerDataStore.getCurrentAccount(),
      };
      this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
    }

    componentDidMount = () => {
      CustomerDataStore.addChangeListener(this._onChangeCustomerData);
      this._onChangeCustomerData();
    };
    componentWillUnmount = () => {
      CustomerDataStore.removeChangeListener(this._onChangeCustomerData);
    };

    _onChangeCustomerData = () => {
      const person = CustomerDataStore.getUser();
      const account = CustomerDataStore.getCurrentAccount();
      this.setState({
        person: person,
        currentAccount: account,
      });
    };

    render() {
      // the typing for spreading this.props is... very complex. best way right now is to just type it as any
      // data will still be typechecked
      return (
        <WrappedComponent
          person={this.state.person}
          currentAccount={this.state.currentAccount}
          {...(this.props as any)}
        />
      );
    }
  };
  // return WithData;
}
