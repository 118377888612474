// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import {
  BaseAPI,
  HttpHeaders,
  HttpQuery,
  throwIfRequired,
  encodeURI,
  COLLECTION_FORMATS,
} from '../runtime';
import {
  Attachment,
  CWALink,
  CardTakeoverRequest,
  CardTakeoverResponse,
  ClassifyRequestList,
  ClassifyResponse,
  CustomerCreditTransfer,
  DebitExternalAccountRequest,
  ExternalTransactionIdentifier,
  NewScheduledTransaction,
  OrderTransactionIdentifier,
  PlainTransactionIdentifier,
  PreviousCWAData,
  ProductOrder,
  ReleaseNotice,
  ReleaseRequired,
  RequestMoneyIdentifier,
  RequestMoneyRequest,
  RequestMoneyResponse,
  RequestMoneyState,
  ScheduledTransactionIdentifier,
  SplitTransactionIdentifier,
  SplitTransactionRequest,
  TakeoverRequest,
  Tan,
  TanRequest,
  TanResponse,
  TransactionIdentifier,
} from '../models/index';

export interface PaymentProductOrderReceivedConfirmRequest {
  ta_id: number;
  transaction_id: number;
  ask_for_confirmation: PaymentProductOrderReceivedConfirmAskForConfirmationEnum;
}

export interface PaymentsCardTakeoverDeleteRequest {
  ta_id: number;
  request_money_id: number;
}

export interface PaymentsCardTakeoverGetRequest {
  show_history?: boolean;
  filter_by_person_id?: number;
}

export interface PaymentsCardTakeoverPostRequest {
  CardTakeoverRequest?: CardTakeoverRequest;
}

export interface PaymentsCardTakeoverPutRequest {
  ta_id: number;
  request_money_id: number;
}

export interface PaymentsClassifyPostRequest {
  ClassifyRequestList?: ClassifyRequestList;
}

export interface PaymentsCustomerCreditTransferPostRequest {
  CustomerCreditTransfer?: CustomerCreditTransfer;
}

export interface PaymentsCwaGetRequest {
  ta_id: number;
  transaction_id: number;
}

export interface PaymentsCwaLinkPutRequest {
  include_personal_data: boolean;
  ta_id: number;
  transaction_id: number;
}

export interface PaymentsCwaQrPutRequest {
  include_personal_data: boolean;
  ta_id: number;
  transaction_id: number;
}

export interface PaymentsDebitExternalAccountPostRequest {
  DebitExternalAccountRequest?: DebitExternalAccountRequest;
}

export interface PaymentsOrderProductRequest {
  ProductOrder?: ProductOrder;
}

export interface PaymentsOrderProductFlightPutRequest {
  ta_id: number;
  transaction_id: number;
  flight_number: string;
}

export interface PaymentsOrderProductStornoRequest {
  PlainTransactionIdentifier?: PlainTransactionIdentifier;
}

export interface PaymentsPaypalResponseRequest {
  response_type: PaymentsPaypalResponseResponseTypeEnum;
  token: string;
  ta_id: number;
  transaction_id: number;
  PayerID?: string;
}

export interface PaymentsRequestInvoicePostRequest {
  PlainTransactionIdentifier?: PlainTransactionIdentifier;
}

export interface PaymentsRequestMoneyBoGetRequest {
  person_id?: number;
}

export interface PaymentsRequestMoneyDeleteRequest {
  ta_id: number;
  request_money_id: number;
  cancellation_reason?: string;
}

export interface PaymentsRequestMoneyGetRequest {
  ta_id?: number;
  request_money_id?: number;
  counterparty_filter?: Array<string>;
  state_filter?: Array<RequestMoneyState>;
}

export interface PaymentsRequestMoneyPostRequest {
  RequestMoneyRequest?: RequestMoneyRequest;
}

export interface PaymentsScheduledTransactionPostRequest {
  NewScheduledTransaction?: NewScheduledTransaction;
}

export interface PaymentsSplitTransactionRequest {
  SplitTransactionRequest?: SplitTransactionRequest;
}

export interface PaymentsTakeOverDebitPostRequest {
  takeover_request: TakeoverRequest;
  attachment?: Blob;
}

export interface PaymentsTakeoverPostRequest {
  TakeoverRequest?: TakeoverRequest;
}

export interface PaymentsTanPostRequest {
  TanRequest?: TanRequest;
}

export interface PaymentsTanPutRequest {
  Tan?: Tan;
}

/**
 * no description
 */
export class CustomerTransactionApi extends BaseAPI implements IOperationIdMap {
  /**
   * update value to confirm test result is received
   * confirm test result is received
   */
  paymentProductOrderReceivedConfirm = (
    requestParameters: PaymentProductOrderReceivedConfirmRequest,
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'ta_id', 'paymentProductOrderReceivedConfirm');
    throwIfRequired(requestParameters, 'transaction_id', 'paymentProductOrderReceivedConfirm');
    throwIfRequired(
      requestParameters,
      'ask_for_confirmation',
      'paymentProductOrderReceivedConfirm',
    );

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.ta_id && { ta_id: requestParameters.ta_id }),
      ...(requestParameters.transaction_id && {
        transaction_id: requestParameters.transaction_id,
      }),
      ...(requestParameters.ask_for_confirmation && {
        ask_for_confirmation: requestParameters.ask_for_confirmation,
      }),
    };

    return this.request<void>({
      path: '/payments/order_product/received_confirm',
      method: 'PUT',
      headers,
      query,
    });
  };

  /**
   * cancels a card-takeover request, this can be done by either sender or receiver
   * deny/withdraw/cancel card-takeover request
   */
  paymentsCardTakeoverDelete = (
    requestParameters: PaymentsCardTakeoverDeleteRequest,
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'ta_id', 'paymentsCardTakeoverDelete');
    throwIfRequired(requestParameters, 'request_money_id', 'paymentsCardTakeoverDelete');

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.ta_id && { ta_id: requestParameters.ta_id }),
      ...(requestParameters.request_money_id && {
        request_money_id: requestParameters.request_money_id,
      }),
    };

    return this.request<void>({
      path: '/payments/card_takeover',
      method: 'DELETE',
      headers,
      query,
    });
  };

  /**
   * open card-takeover request is returned
   * get waiting card-takeover requests
   */
  paymentsCardTakeoverGet = (
    requestParameters: PaymentsCardTakeoverGetRequest,
  ): Observable<Array<CardTakeoverResponse>> => {
    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.show_history && { show_history: requestParameters.show_history }),
      ...(requestParameters.filter_by_person_id && {
        filter_by_person_id: requestParameters.filter_by_person_id,
      }),
    };

    return this.request<Array<CardTakeoverResponse>>({
      path: '/payments/card_takeover',
      method: 'GET',
      headers,
      query,
    });
  };

  /**
   * request a card-takeover from the counterparty
   * issue an card-takeover requests
   */
  paymentsCardTakeoverPost = (
    requestParameters: PaymentsCardTakeoverPostRequest,
  ): Observable<RequestMoneyIdentifier> => {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<RequestMoneyIdentifier>({
      path: '/payments/card_takeover',
      method: 'POST',
      headers,
      body: requestParameters.CardTakeoverRequest,
    });
  };

  /**
   * accept a card-takeover request, this can be done by the receiver onyl
   * accept card-takeover request
   */
  paymentsCardTakeoverPut = (
    requestParameters: PaymentsCardTakeoverPutRequest,
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'ta_id', 'paymentsCardTakeoverPut');
    throwIfRequired(requestParameters, 'request_money_id', 'paymentsCardTakeoverPut');

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.ta_id && { ta_id: requestParameters.ta_id }),
      ...(requestParameters.request_money_id && {
        request_money_id: requestParameters.request_money_id,
      }),
    };

    return this.request<void>({
      path: '/payments/card_takeover',
      method: 'PUT',
      headers,
      query,
    });
  };

  /**
   * as frontend design decided to present different dialogs for internal/external payments, it needs to distinguish between them. please note this might create a privacy risk
   * classify counterparties as internal or external
   */
  paymentsClassifyPost = (
    requestParameters: PaymentsClassifyPostRequest,
  ): Observable<Array<ClassifyResponse>> => {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<Array<ClassifyResponse>>({
      path: '/payments/classify',
      method: 'POST',
      headers,
      body: requestParameters.ClassifyRequestList,
    });
  };

  /**
   * * the email might be known or new to the system, new emails receive an invitation * if account number or an internal iban is used, it must exist * for external ibans a sepa transfer is initiated
   * transfer money from an own account to an account, an email or an iban
   */
  paymentsCustomerCreditTransferPost = (
    requestParameters: PaymentsCustomerCreditTransferPostRequest,
  ): Observable<TransactionIdentifier> => {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<TransactionIdentifier>({
      path: '/payments/customer-credit-transfers',
      method: 'POST',
      headers,
      body: requestParameters.CustomerCreditTransfer,
    });
  };

  /**
   * to show user if cwa link was created previousy
   * shows user if cwa link was created previously
   */
  paymentsCwaGet = (requestParameters: PaymentsCwaGetRequest): Observable<PreviousCWAData> => {
    throwIfRequired(requestParameters, 'ta_id', 'paymentsCwaGet');
    throwIfRequired(requestParameters, 'transaction_id', 'paymentsCwaGet');

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<PreviousCWAData>({
      path: '/payments/cwa/{ta_id}/{transaction_id}/cwa'
        .replace('{ta_id}', encodeURI(requestParameters.ta_id))
        .replace('{transaction_id}', encodeURI(requestParameters.transaction_id)),
      method: 'GET',
      headers,
    });
  };

  /**
   * create an cwa link for an order, if it has been created previously, that will be returned
   * create link to be sent to cwa
   */
  paymentsCwaLinkPut = (requestParameters: PaymentsCwaLinkPutRequest): Observable<CWALink> => {
    throwIfRequired(requestParameters, 'include_personal_data', 'paymentsCwaLinkPut');
    throwIfRequired(requestParameters, 'ta_id', 'paymentsCwaLinkPut');
    throwIfRequired(requestParameters, 'transaction_id', 'paymentsCwaLinkPut');

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.include_personal_data && {
        include_personal_data: requestParameters.include_personal_data,
      }),
    };

    return this.request<CWALink>({
      path: '/payments/cwa/{ta_id}/{transaction_id}/cwa.json'
        .replace('{ta_id}', encodeURI(requestParameters.ta_id))
        .replace('{transaction_id}', encodeURI(requestParameters.transaction_id)),
      method: 'PUT',
      headers,
      query,
    });
  };

  /**
   * create an cwa link for an order, if it has been created previously, that will be returned
   * create link to be sent to cwa
   */
  paymentsCwaQrPut = (requestParameters: PaymentsCwaQrPutRequest): Observable<Blob> => {
    throwIfRequired(requestParameters, 'include_personal_data', 'paymentsCwaQrPut');
    throwIfRequired(requestParameters, 'ta_id', 'paymentsCwaQrPut');
    throwIfRequired(requestParameters, 'transaction_id', 'paymentsCwaQrPut');

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.include_personal_data && {
        include_personal_data: requestParameters.include_personal_data,
      }),
    };

    return this.request<Blob>({
      path: '/payments/cwa/{ta_id}/{transaction_id}/cwa.png'
        .replace('{ta_id}', encodeURI(requestParameters.ta_id))
        .replace('{transaction_id}', encodeURI(requestParameters.transaction_id)),
      method: 'PUT',
      headers,
      query,
      responseType: 'blob',
    });
  };

  /**
   * interim:   * after creating an external account via POST /person/external_account it   can be used to charge the current account future:   * if an external account id is given, use that, otherwise just go to stripe   * if client wants to reuse payment information, we try to store it at sripte. might or might not be successfull
   * debit an previousy or new created external account
   */
  paymentsDebitExternalAccountPost = (
    requestParameters: PaymentsDebitExternalAccountPostRequest,
  ): Observable<ExternalTransactionIdentifier> => {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<ExternalTransactionIdentifier>({
      path: '/payments/debit_external_account',
      method: 'POST',
      headers,
      body: requestParameters.DebitExternalAccountRequest,
    });
  };

  /**
   * buy a product at a location
   * buy a product at a location
   */
  paymentsOrderProduct = (
    requestParameters: PaymentsOrderProductRequest,
  ): Observable<OrderTransactionIdentifier> => {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<OrderTransactionIdentifier>({
      path: '/payments/order_product',
      method: 'POST',
      headers,
      body: requestParameters.ProductOrder,
    });
  };

  /**
   * add or change flight number for ordererd product
   * flight number for ordererd product
   */
  paymentsOrderProductFlightPut = (
    requestParameters: PaymentsOrderProductFlightPutRequest,
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'ta_id', 'paymentsOrderProductFlightPut');
    throwIfRequired(requestParameters, 'transaction_id', 'paymentsOrderProductFlightPut');
    throwIfRequired(requestParameters, 'flight_number', 'paymentsOrderProductFlightPut');

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.ta_id && { ta_id: requestParameters.ta_id }),
      ...(requestParameters.transaction_id && {
        transaction_id: requestParameters.transaction_id,
      }),
      ...(requestParameters.flight_number && {
        flight_number: requestParameters.flight_number,
      }),
    };

    return this.request<void>({
      path: '/payments/order_product/flight',
      method: 'PUT',
      headers,
      query,
    });
  };

  /**
   * storno of a product at a location
   * storno of a product at a location
   */
  paymentsOrderProductStorno = (
    requestParameters: PaymentsOrderProductStornoRequest,
  ): Observable<PlainTransactionIdentifier> => {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<PlainTransactionIdentifier>({
      path: '/payments/order_product_storno',
      method: 'POST',
      headers,
      body: requestParameters.PlainTransactionIdentifier,
    });
  };

  /**
   */
  paymentsPaypalResponse = (
    requestParameters: PaymentsPaypalResponseRequest,
  ): Observable<PlainTransactionIdentifier> => {
    throwIfRequired(requestParameters, 'response_type', 'paymentsPaypalResponse');
    throwIfRequired(requestParameters, 'token', 'paymentsPaypalResponse');
    throwIfRequired(requestParameters, 'ta_id', 'paymentsPaypalResponse');
    throwIfRequired(requestParameters, 'transaction_id', 'paymentsPaypalResponse');

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.response_type && {
        response_type: requestParameters.response_type,
      }),
      ...(requestParameters.token && { token: requestParameters.token }),
      ...(requestParameters.ta_id && { ta_id: requestParameters.ta_id }),
      ...(requestParameters.transaction_id && {
        transaction_id: requestParameters.transaction_id,
      }),
      ...(requestParameters.PayerID && { PayerID: requestParameters.PayerID }),
    };

    return this.request<PlainTransactionIdentifier>({
      path: '/payments/paypal',
      method: 'POST',
      headers,
      query,
    });
  };

  /**
   * request an invoice for a already ordered product , on success it will be also visible in account activity and locations
   * request an invoice
   */
  paymentsRequestInvoicePost = (
    requestParameters: PaymentsRequestInvoicePostRequest,
  ): Observable<Attachment> => {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<Attachment>({
      path: '/payments/request_invoice',
      method: 'POST',
      headers,
      body: requestParameters.PlainTransactionIdentifier,
    });
  };

  /**
   * returns all open request moneys for the given person
   * get money requests for a customer
   */
  paymentsRequestMoneyBoGet = (
    requestParameters: PaymentsRequestMoneyBoGetRequest,
  ): Observable<Array<RequestMoneyResponse>> => {
    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.person_id && { person_id: requestParameters.person_id }),
    };

    return this.request<Array<RequestMoneyResponse>>({
      path: '/payments/request_money_bo',
      method: 'GET',
      headers,
      query,
    });
  };

  /**
   * cancels a request money, this can do either sender or receiver (to not use the burned names debtor or creditor)
   * deny/cancel/reject money requests
   */
  paymentsRequestMoneyDelete = (
    requestParameters: PaymentsRequestMoneyDeleteRequest,
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'ta_id', 'paymentsRequestMoneyDelete');
    throwIfRequired(requestParameters, 'request_money_id', 'paymentsRequestMoneyDelete');

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.ta_id && { ta_id: requestParameters.ta_id }),
      ...(requestParameters.request_money_id && {
        request_money_id: requestParameters.request_money_id,
      }),
      ...(requestParameters.cancellation_reason && {
        cancellation_reason: requestParameters.cancellation_reason,
      }),
    };

    return this.request<void>({
      path: '/payments/request_money',
      method: 'DELETE',
      headers,
      query,
    });
  };

  /**
   * if ta_id and request_money_id are set, we search for that sspecific request-money, otherwise all open request-moneys\' are returned
   * get waiting money requests
   */
  paymentsRequestMoneyGet = (
    requestParameters: PaymentsRequestMoneyGetRequest,
  ): Observable<Array<RequestMoneyResponse>> => {
    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.ta_id && { ta_id: requestParameters.ta_id }),
      ...(requestParameters.request_money_id && {
        request_money_id: requestParameters.request_money_id,
      }),
      ...(requestParameters.counterparty_filter && {
        counterparty_filter: requestParameters.counterparty_filter.join(
          COLLECTION_FORMATS['csv'],
        ),
      }),
      ...(requestParameters.state_filter && {
        state_filter: requestParameters.state_filter.join(COLLECTION_FORMATS['csv']),
      }),
    };

    return this.request<Array<RequestMoneyResponse>>({
      path: '/payments/request_money',
      method: 'GET',
      headers,
      query,
    });
  };

  /**
   * request money from the counterparty
   * issue an money requests
   */
  paymentsRequestMoneyPost = (
    requestParameters: PaymentsRequestMoneyPostRequest,
  ): Observable<RequestMoneyIdentifier> => {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<RequestMoneyIdentifier>({
      path: '/payments/request_money',
      method: 'POST',
      headers,
      body: requestParameters.RequestMoneyRequest,
    });
  };

  /**
   * * the email might be known or new to the system, new emails receive an invitation * if account number or an internal iban is used, it must exist * for external ibans a sepa transfer is initiated
   * schedule transfer money from an own account to an account, an email or an iban
   */
  paymentsScheduledTransactionPost = (
    requestParameters: PaymentsScheduledTransactionPostRequest,
  ): Observable<ScheduledTransactionIdentifier> => {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<ScheduledTransactionIdentifier>({
      path: '/payments/scheduled_transaction',
      method: 'POST',
      headers,
      body: requestParameters.NewScheduledTransaction,
    });
  };

  /**
   * split a debit transaction into multiple request money\'s to collect the amount from other participants
   * split moneytransaction
   */
  paymentsSplitTransaction = (
    requestParameters: PaymentsSplitTransactionRequest,
  ): Observable<SplitTransactionIdentifier> => {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<SplitTransactionIdentifier>({
      path: '/payments/split_transaction',
      method: 'POST',
      headers,
      body: requestParameters.SplitTransactionRequest,
    });
  };

  /**
   * ask a third person(debtor) to transfer money to the creditor swagger specification allows multiple content-types for a requestBody(eg. application/json and multipart/form-data ). unfortunatelly the swagger codegen up to version 5 beta, is unable to handle more than one request content-type. this is not a limitation of the specific language templates but the swagger codegen engine itsself.   so even if the feature is specified, it is basically unusable, for everyone that uses the official swagger codegen.  it is still unclear if we will have both interfaces or if the client will switch to this one, therefore we make the attachment optional
   * forward a cct (with a document) instead of paying
   */
  paymentsTakeOverDebitPost = (
    requestParameters: PaymentsTakeOverDebitPostRequest,
  ): Observable<RequestMoneyIdentifier> => {
    throwIfRequired(requestParameters, 'takeover_request', 'paymentsTakeOverDebitPost');

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const formData = new FormData();
    if (requestParameters.takeover_request !== undefined) {
      formData.append('takeover_request', requestParameters.takeover_request as any);
    }

    if (requestParameters.attachment !== undefined) {
      formData.append('attachment', requestParameters.attachment as any);
    }

    return this.request<RequestMoneyIdentifier>({
      path: '/payments/take_over_debit',
      method: 'POST',
      headers,
      body: formData,
    });
  };

  /**
   * ask a third person(debtor) to transfer money to the creditor
   * forward a cct instead of paying
   */
  paymentsTakeoverPost = (
    requestParameters: PaymentsTakeoverPostRequest,
  ): Observable<RequestMoneyIdentifier> => {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<RequestMoneyIdentifier>({
      path: '/payments/takeover',
      method: 'POST',
      headers,
      body: requestParameters.TakeoverRequest,
    });
  };

  /**
   * * after beeing requested to enter a tan, it can be requested using this interface * conforming to sepa specifications, there can be only one active tan * submit it using payments_tan_put
   * request a tan
   */
  paymentsTanPost = (requestParameters: PaymentsTanPostRequest): Observable<TanResponse> => {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<TanResponse>({
      path: '/payments/tan',
      method: 'POST',
      headers,
      body: requestParameters.TanRequest,
    });
  };

  /**
   * after beeing requested to enter a tan, it needs to be submitted using this interface
   * request a tan
   */
  paymentsTanPut = (
    requestParameters: PaymentsTanPutRequest,
  ): Observable<TransactionIdentifier> => {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<TransactionIdentifier>({
      path: '/payments/tan',
      method: 'PUT',
      headers,
      body: requestParameters.Tan,
    });
  };

  operationToOperationId = {
    paymentProductOrderReceivedConfirm: 'payment_product_order_received_confirm',
    paymentsCardTakeoverDelete: 'payments_card_takeover_delete',
    paymentsCardTakeoverGet: 'payments_card_takeover_get',
    paymentsCardTakeoverPost: 'payments_card_takeover_post',
    paymentsCardTakeoverPut: 'payments_card_takeover_put',
    paymentsClassifyPost: 'payments_classify_post',
    paymentsCustomerCreditTransferPost: 'payments_customer_credit_transfer_post',
    paymentsCwaGet: 'payments_cwa_get',
    paymentsCwaLinkPut: 'payments_cwa_link_put',
    paymentsCwaQrPut: 'payments_cwa_qr_put',
    paymentsDebitExternalAccountPost: 'payments_debit_external_account_post',
    paymentsOrderProduct: 'payments_order_product',
    paymentsOrderProductFlightPut: 'payments_order_product_flight_put',
    paymentsOrderProductStorno: 'payments_order_product_storno',
    paymentsPaypalResponse: 'payments_paypal_response',
    paymentsRequestInvoicePost: 'payments_request_invoice_post',
    paymentsRequestMoneyBoGet: 'payments_request_money_bo_get',
    paymentsRequestMoneyDelete: 'payments_request_money_delete',
    paymentsRequestMoneyGet: 'payments_request_money_get',
    paymentsRequestMoneyPost: 'payments_request_money_post',
    paymentsScheduledTransactionPost: 'payments_scheduled_transaction_post',
    paymentsSplitTransaction: 'payments_split_transaction',
    paymentsTakeOverDebitPost: 'payments_take_over_debit_post',
    paymentsTakeoverPost: 'payments_takeover_post',
    paymentsTanPost: 'payments_tan_post',
    paymentsTanPut: 'payments_tan_put',
  };
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentProductOrderReceivedConfirmAskForConfirmationEnum {
  C = 'C',
  D = 'D',
}
/**
 * @export
 * @enum {string}
 */
export enum PaymentsPaypalResponseResponseTypeEnum {
  Success = 'success',
  Cancel = 'cancel',
}
