//@ts-nocheck
import React, { SyntheticEvent, ReactNode } from 'react';
import { withRouter } from 'react-router';
import AuthScreen, { IAuthScreenProps, IAuthScreenState } from '../authScreen';
import { Editor } from '../../../components/editor';
import { StyledAuthComponent } from './auth.css';
import { translate } from '../../../common/language/translate';
import { BigInput } from '../../../components/atomiccompoents/form/inputs.css';
import ButtonOk from '../../../components/atomiccompoents/buttons/buttonOk';

interface IProps extends IAuthScreenProps {}

interface IState extends IAuthScreenState {
  inquiries: string;
  buttonDisabled: boolean;
  message: string;
  errorEmail: string | null;
}

class ContactComponentClass extends AuthScreen<IProps, IState> {
  email: string | undefined = undefined;

  constructor(props: IProps) {
    super(props);

    this.state = {
      message: '',
      inquiries: '',
      buttonDisabled: true,
      errorEmail: null,
    };
  }

  onSubmit(event: SyntheticEvent | React.KeyboardEvent<HTMLFormElement>) {
    event.preventDefault();
  }

  validateEmail(): boolean {
    const pattern = /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z]{3,25}[.][a-z]{2,5}/g;

    if (this.email == null || !pattern.test(this.email)) {
      if (this.email == null || this.email.trim().length === 0) {
        this.setState({ errorEmail: 'Email is required' });
      } else {
        this.setState({ errorEmail: 'Please enter a valid email' });
      }
      return true;
    }

    this.setState({ errorEmail: null });
    return false;
  }

  updateEmail(event: string) {
    this.email = event;
    this.setState({ buttonDisabled: this.validateEmail() });
  }

  updateInquiries(event: string) {
    this.setState({ inquiries: event });
  }

  updateMessage(event: string) {
    this.setState({ message: event });
  }

  notification(n: { email: string | null }) {
    if (n != null && n.email != null) {
      this.updateEmail(n.email);
    }
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.onSubmit(event);
    }
  };

  defineContent(): ReactNode {
    return null;
  }

  reviveState(): void {}

  render() {
    return (
      <StyledAuthComponent>
        <div>{translate(this.getTitle())}</div>
        <div>{translate(this.getSubtitle() as string)}</div>

        <form onSubmit={event => this.onSubmit(event)} onKeyDown={this.onKeyDown}>
          <BigInput
            type="email"
            id="email"
            notification={n => this.notification(n)}
            showError={true}
            disabled={this.state.buttonDisabled === true}
            onSubmit={this.onSubmit}
          />

          <Editor
            label={translate('contactComponent.message')}
            onChange={event => this.updateMessage(event)}
          />
          <Editor
            label={translate('contactComponent.requestInquiries')}
            onChange={event => this.updateInquiries(event)}
          />

          <ButtonOk
            disableSpinner={true}
            id="btnSignIn"
            style={{ width: '100%' }}
            disabled={this.state.buttonDisabled}
          >
            {translate('button.sendInformation')}
          </ButtonOk>
        </form>
      </StyledAuthComponent>
    );
  }
}

export const ContactComponent = withRouter(ContactComponentClass);
