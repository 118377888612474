// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Attachment, Counterparty, NotificationChannel, NotificationProcess } from './index';

/**
 * a notification line uniquly identified by using the tuple (channel,id)  the properties counterparty_person_id, counterparty_given,counterparty_given_name and  counterparty_email_address are deprecated and will be removed soon. please use the element counterparty_list instead. until then the first counterparty will be shown in the old properties  as this structure is also used in push notifications (that do not have a deduplicated list of faces see NotificationList), this structure also contains a face_url, if it is set, use this one otherwise the one from face_image_links
 * @export
 * @interface NotificationLine
 */
export interface NotificationLine {
  /**
   * @type {NotificationChannel}
   * @memberof NotificationLine
   */
  channel: NotificationChannel;
  /**
   * the id within each channel
   * @type {number}
   * @memberof NotificationLine
   */
  id: number;
  /**
   * * I - incomming * O - outgoing
   * @type {string}
   * @memberof NotificationLine
   */
  in_out: NotificationLineInOutEnum;
  /**
   * message has been read
   * @type {boolean}
   * @memberof NotificationLine
   */
  read: boolean;
  /**
   * date and time of notification creation
   * @type {Date}
   * @memberof NotificationLine
   */
  datetime_create: Date;
  /**
   * if known the person_id of the counterparty
   * @type {number}
   * @memberof NotificationLine
   */
  counterparty_person_id?: number;
  /**
   * the counterparties name
   * @type {string}
   * @memberof NotificationLine
   */
  counterparty_name?: string;
  /**
   * the counterparties given
   * @type {string}
   * @memberof NotificationLine
   */
  counterparty_given_name?: string;
  /**
   * the counterparties account number
   * @type {string}
   * @memberof NotificationLine
   */
  counterparty_email_address?: string;
  /**
   * @type {Array<Counterparty>}
   * @memberof NotificationLine
   */
  counterparty_list?: Array<Counterparty>;
  /**
   * if applicable the subject of the notification
   * @type {string}
   * @memberof NotificationLine
   */
  subject?: string;
  /**
   * a short preview of the notification
   * @type {string}
   * @memberof NotificationLine
   */
  preview: string;
  /**
   * user given text, if any
   * @type {string}
   * @memberof NotificationLine
   */
  message?: string;
  /**
   * the original message
   * @type {string}
   * @memberof NotificationLine
   */
  detail: string;
  /**
   * @type {NotificationProcess}
   * @memberof NotificationLine
   */
  process?: NotificationProcess;
  /**
   * @type {Array<Attachment>}
   * @memberof NotificationLine
   */
  attachments?: Array<Attachment>;
  /**
   * if set the face image link for this specific counterparty_person_id, otherwise use the links in face_image_links
   * @type {string}
   * @memberof NotificationLine
   */
  face_url?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum NotificationLineInOutEnum {
  I = 'I',
  O = 'O',
}
