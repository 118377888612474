import React from 'react';
import { api, Ident, Account, apis, ApiError } from '../../../../logic/api';
import {
  IStatusState,
  Reporter,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import {
  IInitProps,
  IInitState,
  InitialDataComponent,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import {
  INotifyAbleCustomerData,
  CustomerDataStore,
  PermissionStore,
} from '../../../../logic/flux';
import { Log, Logs } from '../../../../logic/log';
import ExternalAcocuntListBox from './externalAccountListBox';
import { HeaderCell, Table } from './basicStyledComponents/customerDetails.css';
import { FlexBox } from '../../../auth/auth.css';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import styled from 'styled-components';
import { evaluateErrorMessage } from '../../../../logic/helper/Common';
import { withPerson } from '../../../../components/hocs/withPerson';
// import ExternalAccountSepaBox from './externalAccountSepaBox';

const Header = styled.div`
  display: flex;
  width: 80%;
  justify-content: flex-start;
  padding: 16px;
`;

const SplitView = styled.div`
  width: 50%;
`;
const Main = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px;
`;
interface IProps {
  person?: Ident.Person;
}

interface IState {
  externalAccounts?: Array<Account.ExternalAccount>;
  keyForLoadingSpinner?: number;
}

class CustomerDetailsExternalAccountsBox extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    };

    this.fetchExternalAccounts();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.person?.person_id !== this.props.person?.person_id) {
      this.fetchExternalAccounts();
    }
  }

  fetchExternalAccounts(): void {
    if (this.props.person == null) {
      return undefined;
    }

    api
      .asyncRequest<Array<Account.ExternalAccount>>(
        { person_id: this.props.person.person_id },
        apis.DefaultApi,
        'personExternalAccountGet',
      )
      .then(response => {
        this.setState({
          externalAccounts: response,
        });
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
        MessageHandler.onError(
          Reporter['customer.details.external.accoounts.fetch'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
      });
  }

  render() {
    const permissionget = Account.OperationId.PersonExternalAccountGet;
    if (!PermissionStore.hasPermission(permissionget)) {
      return null;
    }

    return (
      <Main>
        <SplitView>
          <FlexBox
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Header>
              <HeaderCell> Bank accounts</HeaderCell>
            </Header>
            <div>nothing to show yet</div>
            <Table>
              <tbody>
                {/*
									<ExternalAccountSepaBox 
										selectedUser={this.props.person}
										merchantSepa={
											{
												iban: "DE7213123123123123213",
												bank: "Sparkasse Rosenheim",
												name: "Hannelore Haarentraut (Merchant)",
												acceptance: 'accepted'
											}
										}
									/>
								: (
									<React.Fragment>
										<div>no Bank Accounts to show</div>
									</React.Fragment>) 
								*/}
              </tbody>
            </Table>
          </FlexBox>
        </SplitView>
        <SplitView>
          <FlexBox
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Header>
              <HeaderCell> Card accounts</HeaderCell>
            </Header>
            {this.state.externalAccounts != null && this.state.externalAccounts.length > 0 ? (
              <Table>
                <tbody>
                  {this.state.externalAccounts.map((externalAccount, index) => {
                    return (
                      <ExternalAcocuntListBox
                        key={'externalAccount_' + index}
                        selectedUser={this.props.person}
                        externalAccount={externalAccount}
                      />
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <div>nothing to show yet</div>
            )}
          </FlexBox>
        </SplitView>
      </Main>
    );
  }
}

export default withPerson(CustomerDetailsExternalAccountsBox);
