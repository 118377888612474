import React from 'react';
import GenericTemplateComponent, {
  GenericTemplateType,
  TemplateCategory,
} from '../../../genericUI/genericTemplateComponent';
import AccountSelector from '../../../../components/accountSelector';
import styled from 'styled-components';
import {
  IStatusState,
  IMessageConfig,
  Reporter,
  getFormComponents,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { IItfAccount } from '../../../../logic/types';
import { Accounts } from '../../../../logic/accounts';
import { Ident, ApiError, Account, api } from '../../../../logic/api';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { INotifyAbleUserData, UserDataStore } from '../../../../logic/flux';
import {
  OverlayHandler,
  Overlays,
} from '../../../../logic/handler/overlayhandler/overlayHandler';
import { FlexBox } from '../../../auth/auth.css';
import { translate } from '../../../../common/language/translate';
import { FileType } from '../../../../components/compositcomponents/popup/fileTypeChooserOverlay';
import { Log, Logs } from '../../../../logic/log';
import { downloadFile, evaluateErrorMessage } from '../../../../logic/helper/Common';
import { format } from '../../../../logic/helper/format';

interface IProps {}

interface IState extends IStatusState {
  accounts?: Array<IItfAccount>;
  selectedAccount?: number;
  showReport: boolean;
  responseData?: any;
  selectedPostingTemplate?: Account.TransactionTemplate;
}
//TODO Make this accessible in a seperate file for all components that need reports and use only one reportcomponent
export type ReportAcceptTypes =
  | 'application/pdf'
  | 'text/comma-separated-values'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export default class ReportsComponent extends React.Component<IProps, IState>
  implements INotifyAbleUserData {
  private ref = React.createRef<GenericTemplateComponent>();
  constructor(props: IProps) {
    super(props);

    this.state = {
      showReport: false,
    };

    this.showReportPage = this.showReportPage.bind(this);
    this._onChangeUserData = this._onChangeUserData.bind(this);
    this.reset = this.reset.bind(this);
  }

  private getAcceptHeader(report: string, filetype: FileType): ReportAcceptTypes {
    if (filetype === FileType.csv) return 'text/comma-separated-values';
    else if (filetype === FileType.pdf) return 'application/pdf';
    else if (filetype === FileType.docx)
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    else return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }

  componentDidMount(): void {
    UserDataStore.addChangeListener(this._onChangeUserData);
    this._onChangeUserData();
  }

  componentWillUnmount(): void {
    UserDataStore.removeChangeListener(this._onChangeUserData);
  }

  _onChangeUserData(): void {
    const person: Ident.Person | undefined = UserDataStore.getUser();
    if (person != null) {
      Accounts.getAccountsByPersonId(person.person_id)
        .then(response => {
          if (response.length > 0) {
            this.setState({
              accounts: response,
              selectedAccount:
                this.state.selectedAccount == null ||
                this.state.selectedAccount >= response.length
                  ? 0
                  : this.state.selectedAccount,
            });
          }
        })
        .catch((error: ApiError) => {
          const config: IMessageConfig = MessageHandler.onError(
            Reporter['reports.account.get.request'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
          this.setState({
            showInlineError: config.errorMethods.inline,
            errorMessage: config.errorMessage != null ? config.errorMessage : error.statusText,
          });
        });
    }
  }

  showReportPage(
    showReport: boolean,
    response: any,
    selectedPostingTemplate: Account.TransactionTemplate,
  ) {
    if (showReport === true) {
      this.setState({
        showReport: showReport,
        responseData: response,
        selectedPostingTemplate: selectedPostingTemplate,
      });
    }
  }

  reset() {
    this.setState({
      showReport: false,
    });
  }

  render() {
    return (
      <FlexBox>
        <Wrapper>{this.state.accounts != null ? <AccountSelector /> : null}</Wrapper>

        <Wrapper onClick={() => OverlayHandler.closeOverlaysOnClick()}>
          <GenericTemplateComponent
            templateType={GenericTemplateType.report_template}
            templateCategory={TemplateCategory.customer}
            heading={translate('generic.report')}
            subHeading={translate('generic.chooseReport')}
            notification={this.showReportPage}
            isReport={true}
            resetCallback={this.reset}
            ref={this.ref}
          />
          {getFormComponents(
            this.state.showInlineError,
            this.state.showInlineSuccess,
            this.state.errorMessage,
            this.state.successMessage,
            this.state.key,
          )}
        </Wrapper>
      </FlexBox>
    );
  }
}

const Wrapper = styled(FlexBox)`
  margin-bottom: 24px;
`;
