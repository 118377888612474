// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
  NewProductCategory,
  ProductAppointmentProduct,
  ProductCategoryWTypes,
  ProductConfigTypes,
  ProductDeliveryProduct,
  ProductTypeOfTest,
} from '../models/index';

export interface ProductMaintenanceProductAppointmentDeleteRequest {
  product_id: number;
  product_appointment_type_id: number;
}

export interface ProductMaintenanceProductAppointmentPutRequest {
  ProductAppointmentProduct?: ProductAppointmentProduct;
}

export interface ProductMaintenanceProductCategoriesDeleteRequest {
  product_category_id: number;
}

export interface ProductMaintenanceProductCategoriesPostRequest {
  new_product_category: NewProductCategory;
  product_category_image?: Blob;
}

export interface ProductMaintenanceProductCategoryImageRequest {
  product_category_image_id: number;
}

export interface ProductMaintenanceProductDeliveryDeleteRequest {
  product_id: number;
  product_delivery_type_id: number;
}

export interface ProductMaintenanceProductDeliveryPutRequest {
  ProductDeliveryProduct?: ProductDeliveryProduct;
}

/**
 * no description
 */
export class ProductMaintenanceApi extends BaseAPI implements IOperationIdMap {
  /**
   */
  productMaintenanceProductAppointmentDelete = (
    requestParameters: ProductMaintenanceProductAppointmentDeleteRequest,
  ): Observable<void> => {
    throwIfRequired(
      requestParameters,
      'product_id',
      'productMaintenanceProductAppointmentDelete',
    );
    throwIfRequired(
      requestParameters,
      'product_appointment_type_id',
      'productMaintenanceProductAppointmentDelete',
    );

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.product_id && { product_id: requestParameters.product_id }),
      ...(requestParameters.product_appointment_type_id && {
        product_appointment_type_id: requestParameters.product_appointment_type_id,
      }),
    };

    return this.request<void>({
      path: '/product_maintenance/product_appointment',
      method: 'DELETE',
      headers,
      query,
    });
  };

  /**
   */
  productMaintenanceProductAppointmentPut = (
    requestParameters: ProductMaintenanceProductAppointmentPutRequest,
  ): Observable<void> => {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<void>({
      path: '/product_maintenance/product_appointment',
      method: 'PUT',
      headers,
      body: requestParameters.ProductAppointmentProduct,
    });
  };

  /**
   */
  productMaintenanceProductCategories = (): Observable<Array<ProductCategoryWTypes>> => {
    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<Array<ProductCategoryWTypes>>({
      path: '/product_maintenance/product_categories',
      method: 'GET',
      headers,
    });
  };

  /**
   */
  productMaintenanceProductCategoriesDelete = (
    requestParameters: ProductMaintenanceProductCategoriesDeleteRequest,
  ): Observable<void> => {
    throwIfRequired(
      requestParameters,
      'product_category_id',
      'productMaintenanceProductCategoriesDelete',
    );

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.product_category_id && {
        product_category_id: requestParameters.product_category_id,
      }),
    };

    return this.request<void>({
      path: '/product_maintenance/product_categories',
      method: 'DELETE',
      headers,
      query,
    });
  };

  /**
   * add or edit a product-category. If product_category_id is set, the categroy will be edited, else added
   */
  productMaintenanceProductCategoriesPost = (
    requestParameters: ProductMaintenanceProductCategoriesPostRequest,
  ): Observable<void> => {
    throwIfRequired(
      requestParameters,
      'new_product_category',
      'productMaintenanceProductCategoriesPost',
    );

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const formData = new FormData();
    if (requestParameters.new_product_category !== undefined) {
      formData.append('new_product_category', requestParameters.new_product_category as any);
    }

    if (requestParameters.product_category_image !== undefined) {
      formData.append(
        'product_category_image',
        requestParameters.product_category_image as any,
      );
    }

    return this.request<void>({
      path: '/product_maintenance/product_categories',
      method: 'POST',
      headers,
      body: formData,
    });
  };

  /**
   */
  productMaintenanceProductCategoryImage = (
    requestParameters: ProductMaintenanceProductCategoryImageRequest,
  ): Observable<Blob> => {
    throwIfRequired(
      requestParameters,
      'product_category_image_id',
      'productMaintenanceProductCategoryImage',
    );

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<Blob>({
      path: '/product_maintenance/product_categories_image/{product_category_image_id}'.replace(
        '{product_category_image_id}',
        encodeURI(requestParameters.product_category_image_id),
      ),
      method: 'GET',
      headers,
      responseType: 'blob',
    });
  };

  /**
   */
  productMaintenanceProductConfigTypes = (): Observable<ProductConfigTypes> => {
    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<ProductConfigTypes>({
      path: '/product_maintenance/product_config_types',
      method: 'GET',
      headers,
    });
  };

  /**
   */
  productMaintenanceProductDeliveryDelete = (
    requestParameters: ProductMaintenanceProductDeliveryDeleteRequest,
  ): Observable<void> => {
    throwIfRequired(
      requestParameters,
      'product_id',
      'productMaintenanceProductDeliveryDelete',
    );
    throwIfRequired(
      requestParameters,
      'product_delivery_type_id',
      'productMaintenanceProductDeliveryDelete',
    );

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.product_id && { product_id: requestParameters.product_id }),
      ...(requestParameters.product_delivery_type_id && {
        product_delivery_type_id: requestParameters.product_delivery_type_id,
      }),
    };

    return this.request<void>({
      path: '/product_maintenance/product_delivery',
      method: 'DELETE',
      headers,
      query,
    });
  };

  /**
   */
  productMaintenanceProductDeliveryPut = (
    requestParameters: ProductMaintenanceProductDeliveryPutRequest,
  ): Observable<void> => {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<void>({
      path: '/product_maintenance/product_delivery',
      method: 'PUT',
      headers,
      body: requestParameters.ProductDeliveryProduct,
    });
  };

  /**
   */
  productMaintenanceProductTypeOfTestList = (): Observable<Array<ProductTypeOfTest>> => {
    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<Array<ProductTypeOfTest>>({
      path: '/product_maintenance/product_type_of_test_list',
      method: 'GET',
      headers,
    });
  };

  operationToOperationId = {
    productMaintenanceProductAppointmentDelete:
      'product_maintenance_product_appointment_delete',
    productMaintenanceProductAppointmentPut: 'product_maintenance_product_appointment_put',
    productMaintenanceProductCategories: 'product_maintenance_product_categories',
    productMaintenanceProductCategoriesDelete: 'product_maintenance_product_categories_delete',
    productMaintenanceProductCategoriesPost: 'product_maintenance_product_categories_post',
    productMaintenanceProductCategoryImage: 'product_maintenance_product_category_image',
    productMaintenanceProductConfigTypes: 'product_maintenance_product_config_types',
    productMaintenanceProductDeliveryDelete: 'product_maintenance_product_delivery_delete',
    productMaintenanceProductDeliveryPut: 'product_maintenance_product_delivery_put',
    productMaintenanceProductTypeOfTestList: 'product_maintenance_product_type_of_test_list',
  };
}
