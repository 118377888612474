import styled from 'styled-components';
import { FlexBox } from '../content/auth/auth.css';
import { Icons } from '../images';
import { ReactElement, SyntheticEvent } from 'react';
import React from 'react';
import Title from './compositcomponents/title';

const StyledView = styled(FlexBox)`
  display: flex;
  width: 320px;
  padding: 16px;
  background-color: ${props => props.theme.Box.background};
  border: ${props => props.theme.Box.border};
  box-shadow: ${props => props.theme.Box.boxShadow};
  border-radius: ${props => props.theme.Box.borderRadius};
  flex-direction: column;
  align-items: stretch;

  max-height: calc(100vh - 146px);
  overflow-y: auto;
  box-sizing: border-box;
  svg {
    fill: none;
  }
`;

export interface IResultProps {
  title: string;
}
export interface IDataType {
  title: string;
  subTitle: string;
  image: ReactElement;
  onClick: (event: SyntheticEvent) => void;
}

export abstract class SearchResultBox<P extends IResultProps> extends React.Component<P> {
  abstract createData(): Array<ReactElement>;

  render() {
    return (
      <StyledView>
        <Title title={this.props.title} icon={Icons.customerResultList()} />
        {this.createData()}
      </StyledView>
    );
  }
}
