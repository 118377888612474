import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { translate } from '../../common/language/translate';
import {
  KeyField,
  NoMarginSelect,
  ValueField,
} from '../../content/dashboard/content/customers/basicStyledComponents/customerDetails.css';
import { coalesce, valueFromNullableObject } from '../../logic/helper/Common';
import { DisplayMenuComponent } from '../compositcomponents/displayMenuComponent';
import { CustomDatePicker } from '../datepicker';
import { IOption } from './form';

interface IProps {
  translationkey: string;
  value?: string;
  wrapperStyle?: React.CSSProperties;
}

export default class KeyTextComponent extends PureComponent<IProps> {
  render() {
    return (
      <React.Fragment>
        <LargeKeyValueBlock style={this.props.wrapperStyle}>
          <KeyField>
            {translate(this.props.translationkey, this.props.translationkey)}:
          </KeyField>
          <ValueField>{this.props.value}</ValueField>
        </LargeKeyValueBlock>
      </React.Fragment>
    );
  }
}

const LargeKeyValueBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 450px;
  height: 62px;
  border-bottom: 1px solid #dedede;
  padding: ${props => props.theme.Table.TBody.Td.padding};
  margin: 8px;
  padding: 8px;
`;
