// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
  NotificationChannel,
  NotificationIdList,
  NotificationList,
  NotificationProcess,
} from '../models/index';

export interface NotificationsDeleteRequest {
  channel: NotificationChannel;
  id: number;
}

export interface NotificationsGetRequest {
  date_from?: Date;
  date_to?: Date;
  limit?: number;
  offset?: number;
  counterparty_person_id?: number;
  process?: NotificationProcess;
  only_unread?: boolean;
}

export interface NotificationsPutRequest {
  NotificationIdList: NotificationIdList;
}

/**
 * no description
 */
export class NotificationApi extends BaseAPI implements IOperationIdMap {
  /**
   * permanently remove a notification from the users inbox
   * delete a notificaion
   */
  notificationsDelete = (requestParameters: NotificationsDeleteRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'channel', 'notificationsDelete');
    throwIfRequired(requestParameters, 'id', 'notificationsDelete');

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.channel && { channel: requestParameters.channel }),
      ...(requestParameters.id && { id: requestParameters.id }),
    };

    return this.request<void>({
      path: '/notifications/',
      method: 'DELETE',
      headers,
      query,
    });
  };

  /**
   * show notifications, sorted by date-time of notification descending
   * show notifications
   */
  notificationsGet = (
    requestParameters: NotificationsGetRequest,
  ): Observable<NotificationList> => {
    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.date_from && {
        date_from: (requestParameters.date_from as any).toISOString().substring(0, 10),
      }),
      ...(requestParameters.date_to && {
        date_to: (requestParameters.date_to as any).toISOString().substring(0, 10),
      }),
      ...(requestParameters.limit && { limit: requestParameters.limit }),
      ...(requestParameters.offset && { offset: requestParameters.offset }),
      ...(requestParameters.counterparty_person_id && {
        counterparty_person_id: requestParameters.counterparty_person_id,
      }),
      ...(requestParameters.process && { process: requestParameters.process }),
      ...(requestParameters.only_unread && { only_unread: requestParameters.only_unread }),
    };

    return this.request<NotificationList>({
      path: '/notifications/',
      method: 'GET',
      headers,
      query,
    });
  };

  /**
   * marks all given notifications as read
   * mark notifications as read
   */
  notificationsPut = (requestParameters: NotificationsPutRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'NotificationIdList', 'notificationsPut');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<void>({
      path: '/notifications/',
      method: 'PUT',
      headers,
      body: requestParameters.NotificationIdList,
    });
  };

  operationToOperationId = {
    notificationsDelete: 'notifications_delete',
    notificationsGet: 'notifications_get',
    notificationsPut: 'notifications_put',
  };
}
