// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, COLLECTION_FORMATS } from '../runtime';
import {
    AuthForgotPasswordRequest,
    AuthParams,
    AuthRequest,
    AuthRequestPhoneNumber,
    AuthRequestPhoneNumberWithVerificationCode,
    AuthRequestToken,
    AuthRequestUsernamePassword,
    AuthRequestWithPassword,
    AuthRequestWithPersonId,
    AuthRequestWithVerificationCode,
    AuthSignupAddressRequest,
    AuthSignupEmailRequest,
    AuthSignupPhoneNumberRequest,
    AuthSignupRequest,
    AuthSignupUserdataRequest,
    CompanionCodeDetailExt,
    CountryCodeAlpha3PlusKosovo,
    DocumentsItem,
    OidcError,
    PermissionResponse,
    StdAuthRequest,
} from '../models/index';

export interface OauthChoosePersonPostRequest {
    AuthRequestWithPersonId?: AuthRequestWithPersonId;
}

export interface OauthCompanionCodeDetailRequest {
    companion_code: string;
}

export interface OauthDocumentsListGetRequest {
    entity_id?: number;
    country_code?: CountryCodeAlpha3PlusKosovo;
    language?: OauthDocumentsListGetLanguageEnum;
}

export interface OauthLoginGetRequest {
    response_type: OauthLoginGetResponseTypeEnum;
    scope: Array<OauthLoginGetScopeEnum>;
    client_id: string;
    redirect_uri: string;
    state: string;
}

export interface OauthLoginPhonePostRequest {
    AuthRequestPhoneNumber?: AuthRequestPhoneNumber;
}

export interface OauthLoginPostRequest {
    AuthRequest?: AuthRequest;
}

export interface OauthLoginTokenPostRequest {
    AuthRequestToken?: AuthRequestToken;
}

export interface OauthResetpasswordRequest {
    AuthForgotPasswordRequest: AuthForgotPasswordRequest;
}

export interface OauthSetPasswordPostRequest {
    AuthRequestWithPassword?: AuthRequestWithPassword;
}

export interface OauthSignupAddressPostRequest {
    AuthSignupAddressRequest: AuthSignupAddressRequest;
}

export interface OauthSignupCancelPhonePostRequest {
    StdAuthRequest?: StdAuthRequest;
}

export interface OauthSignupEmailPostRequest {
    AuthSignupEmailRequest: AuthSignupEmailRequest;
}

export interface OauthSignupPhonePostRequest {
    AuthSignupPhoneNumberRequest: AuthSignupPhoneNumberRequest;
}

export interface OauthSignupPostRequest {
    AuthSignupRequest: AuthSignupRequest;
}

export interface OauthSignupUserdataPostRequest {
    AuthSignupUserdataRequest: AuthSignupUserdataRequest;
}

export interface OauthVerifyPhonePostRequest {
    AuthRequestPhoneNumberWithVerificationCode?: AuthRequestPhoneNumberWithVerificationCode;
}

export interface OauthVerifyPostRequest {
    AuthRequestWithVerificationCode?: AuthRequestWithVerificationCode;
}

/**
 * no description
 */
export class OpenIDConnectApi extends BaseAPI implements IOperationIdMap {

    /**
     * this endpoint is the start and the end of the users login process. initially it will be redirected by the load balancer to the actual login interface and finaly it presents a token to the application that can be used for a remember me function
     * authorize user
     */
    authAuthorizeGet = (): Observable<PermissionResponse> => {
        return this.request<PermissionResponse>({
            path: '/oauth/authorize',
            method: 'GET',
        });
    };

    /**
     * joint useres login using credentials of primary persion, which then can choose who he wants to become
     * choose user from joint_persons
     */
    oauthChoosePersonPost = (requestParameters: OauthChoosePersonPostRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/oauth/choose_person',
            method: 'POST',
            headers,
            body: requestParameters.AuthRequestWithPersonId,
        });
    };

    /**
     * validate a companion code and show details for signup form prefill
     * get companion code details
     */
    oauthCompanionCodeDetail = (requestParameters: OauthCompanionCodeDetailRequest): Observable<CompanionCodeDetailExt> => {
        throwIfRequired(requestParameters, 'companion_code', 'oauthCompanionCodeDetail');

        const query: HttpQuery = {
            ...(requestParameters.companion_code && { 'companion_code': requestParameters.companion_code }),
        };

        return this.request<CompanionCodeDetailExt>({
            path: '/oauth/companion_code_detail',
            method: 'GET',
            query,
        });
    };

    /**
     * Get documents list from country code or entity_id
     * Get documents list from country code or entity_id
     */
    oauthDocumentsListGet = (requestParameters: OauthDocumentsListGetRequest): Observable<Array<DocumentsItem>> => {

        const query: HttpQuery = {
            ...(requestParameters.entity_id && { 'entity_id': requestParameters.entity_id }),
            ...(requestParameters.country_code && { 'country_code': requestParameters.country_code }),
            ...(requestParameters.language && { 'language': requestParameters.language }),
        };

        return this.request<Array<DocumentsItem>>({
            path: '/oauth/documents_list',
            method: 'GET',
            query,
        });
    };

    /**
     * inform the client application about possible ways to login
     * deliver login data to the client application
     */
    oauthLoginGet = (requestParameters: OauthLoginGetRequest): Observable<AuthParams> => {
        throwIfRequired(requestParameters, 'response_type', 'oauthLoginGet');
        throwIfRequired(requestParameters, 'scope', 'oauthLoginGet');
        throwIfRequired(requestParameters, 'client_id', 'oauthLoginGet');
        throwIfRequired(requestParameters, 'redirect_uri', 'oauthLoginGet');
        throwIfRequired(requestParameters, 'state', 'oauthLoginGet');

        const query: HttpQuery = {
            ...(requestParameters.response_type && { 'response_type': requestParameters.response_type }),
            ...(requestParameters.scope && { 'scope': requestParameters.scope.join(COLLECTION_FORMATS['csv']) }),
            ...(requestParameters.client_id && { 'client_id': requestParameters.client_id }),
            ...(requestParameters.redirect_uri && { 'redirect_uri': requestParameters.redirect_uri }),
            ...(requestParameters.state && { 'state': requestParameters.state }),
        };

        return this.request<AuthParams>({
            path: '/oauth/login',
            method: 'GET',
            query,
        });
    };

    /**
     * shall be used to submit the users login data to the idp.  note, that we need to invest time in some kind of secure remote password protocol(SRP) in a way, that the client is able to sign the request, instead of submitting it\'s password. then we \"only\" need to verify the signature, but as we store passwords salted, peppered and cryptographically hashed we can\'t simply rehash the clients request. but as of know even auth0 submits the password in the request body... 
     * upload users login data
     */
    oauthLoginPhonePost = (requestParameters: OauthLoginPhonePostRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/oauth/login_phone',
            method: 'POST',
            headers,
            body: requestParameters.AuthRequestPhoneNumber,
        });
    };

    /**
     * shall be used to submit the users login data to the idp.  note, that we need to invest time in some kind of secure remote password protocol(SRP) in a way, that the client is able to sign the request, instead of submitting it\'s password. then we \"only\" need to verify the signature, but as we store passwords salted, peppered and cryptographically hashed we can\'t simply rehash the clients request. but as of know even auth0 submits the password in the request body... 
     * upload users login data
     */
    oauthLoginPost = (requestParameters: OauthLoginPostRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/oauth/login',
            method: 'POST',
            headers,
            body: requestParameters.AuthRequest,
        });
    };

    /**
     * shall be used to submit the users login data to the idp.  
     * upload users login data
     */
    oauthLoginTokenPost = (requestParameters: OauthLoginTokenPostRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/oauth/login_token',
            method: 'POST',
            headers,
            body: requestParameters.AuthRequestToken,
        });
    };

    /**
     * logout the user currently logged in
     */
    oauthLogoutGet = (): Observable<void> => {
        return this.request<void>({
            path: '/oauth/logout',
            method: 'GET',
        });
    };

    /**
     * logout the user currently logged in
     */
    oauthLogoutPost = (): Observable<void> => {
        return this.request<void>({
            path: '/oauth/logout',
            method: 'POST',
        });
    };

    /**
     * the aws alb has the option to deny requests if they have no authorization cookie. but unfortunatelly if there is a cookie and just the refresh does not work (e.g. expired session) then the alb will forward the request via 302 to the configured login endpoint. to get arround this, instead of denying these requests at the alb (--> 401) we forward them to our idp and deny them there.
     * endpoint to terminate oidc redirect on expired or no tokens
     */
    oauthNologinGet = (): Observable<void> => {
        return this.request<void>({
            path: '/oauth/no_login',
            method: 'GET',
        });
    };

    /**
     * send a password reset token to the given email address, this email address will receive a link. using this link one can set a new password
     * request a password reset token
     */
    oauthResetpassword = (requestParameters: OauthResetpasswordRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'AuthForgotPasswordRequest', 'oauthResetpassword');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/oauth/reset_password',
            method: 'POST',
            headers,
            body: requestParameters.AuthForgotPasswordRequest,
        });
    };

    /**
     * the application is expected to ask the user for his password, double check it and submit it via this interface
     * set new password after beeing requested by the idp
     */
    oauthSetPasswordPost = (requestParameters: OauthSetPasswordPostRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/oauth/set_password',
            method: 'POST',
            headers,
            body: requestParameters.AuthRequestWithPassword,
        });
    };

    /**
     * after submitting initial signup the user may be asked to    * to submit his personal data (connection:= signup_address)
     * add userdata during signup
     */
    oauthSignupAddressPost = (requestParameters: OauthSignupAddressPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'AuthSignupAddressRequest', 'oauthSignupAddressPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/oauth/signup_address',
            method: 'POST',
            headers,
            body: requestParameters.AuthSignupAddressRequest,
        });
    };

    /**
     * if users adds a phone number he either needs to verify it, add another one or remove it 
     * cancel verification of phone number and removes it from person creation
     */
    oauthSignupCancelPhonePost = (requestParameters: OauthSignupCancelPhonePostRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/oauth/signup_cancel_phone',
            method: 'POST',
            headers,
            body: requestParameters.StdAuthRequest,
        });
    };

    /**
     * after submitting initial signup the user may be asked to    * to submit an emailaddress (connection:= signup_email)
     * add email number during signup
     */
    oauthSignupEmailPost = (requestParameters: OauthSignupEmailPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'AuthSignupEmailRequest', 'oauthSignupEmailPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/oauth/signup_email',
            method: 'POST',
            headers,
            body: requestParameters.AuthSignupEmailRequest,
        });
    };

    /**
     * after submitting initial signup the user may be asked to    * either verify its emailadress (connection:= verify) or    * to submit a phone number (connection:= signup_phone) this number will receive a verification code via sms. the application is supposed to submit this verification code to /oauth/verify_phone
     * add phone number during signup
     */
    oauthSignupPhonePost = (requestParameters: OauthSignupPhonePostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'AuthSignupPhoneNumberRequest', 'oauthSignupPhonePost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/oauth/signup_phone',
            method: 'POST',
            headers,
            body: requestParameters.AuthSignupPhoneNumberRequest,
        });
    };

    /**
     * create a person_creation request, at least    * email_address   * the phone   * or userdata: given_name name must be present   * or the address
     * create a person
     */
    oauthSignupPost = (requestParameters: OauthSignupPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'AuthSignupRequest', 'oauthSignupPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/oauth/signup',
            method: 'POST',
            headers,
            body: requestParameters.AuthSignupRequest,
        });
    };

    /**
     * after submitting initial signup the user may be asked to    * to submit his personal data (connection:= signup_userdata)
     * add userdata  during signup
     */
    oauthSignupUserdataPost = (requestParameters: OauthSignupUserdataPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'AuthSignupUserdataRequest', 'oauthSignupUserdataPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/oauth/signup_userdata',
            method: 'POST',
            headers,
            body: requestParameters.AuthSignupUserdataRequest,
        });
    };

    /**
     * after a user added a phone_number up via /oauth/signup_phone, this interface is used to upload the verification code and continue the verification. this interface is just needed to have partially created users validate their phone number. after regular user validation. the usual self management interfaces can be used 
     * upload users verification code
     */
    oauthVerifyPhonePost = (requestParameters: OauthVerifyPhonePostRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/oauth/verify_phone',
            method: 'POST',
            headers,
            body: requestParameters.AuthRequestPhoneNumberWithVerificationCode,
        });
    };

    /**
     * after a user signed up via /oauth/signup, this interface is used to upload the verification code and set the initial password 
     * upload users verification code
     */
    oauthVerifyPost = (requestParameters: OauthVerifyPostRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/oauth/verify',
            method: 'POST',
            headers,
            body: requestParameters.AuthRequestWithVerificationCode,
        });
    };


    operationToOperationId = {
	    "authAuthorizeGet": "auth_authorize_get" ,
	    "oauthChoosePersonPost": "oauth_choose_person_post" ,
	    "oauthCompanionCodeDetail": "oauth_companion_code_detail" ,
	    "oauthDocumentsListGet": "oauth_documents_list_get" ,
	    "oauthLoginGet": "oauth_login_get" ,
	    "oauthLoginPhonePost": "oauth_login_phone_post" ,
	    "oauthLoginPost": "oauth_login_post" ,
	    "oauthLoginTokenPost": "oauth_login_token_post" ,
	    "oauthLogoutGet": "oauth_logout_get" ,
	    "oauthLogoutPost": "oauth_logout_post" ,
	    "oauthNologinGet": "oauth_nologin_get" ,
	    "oauthResetpassword": "oauth_resetpassword" ,
	    "oauthSetPasswordPost": "oauth_set_password_post" ,
	    "oauthSignupAddressPost": "oauth_signup_address_post" ,
	    "oauthSignupCancelPhonePost": "oauth_signup_cancel_phone_post" ,
	    "oauthSignupEmailPost": "oauth_signup_email_post" ,
	    "oauthSignupPhonePost": "oauth_signup_phone_post" ,
	    "oauthSignupPost": "oauth_signup_post" ,
	    "oauthSignupUserdataPost": "oauth_signup_userdata_post" ,
	    "oauthVerifyPhonePost": "oauth_verify_phone_post" ,
	    "oauthVerifyPost": "oauth_verify_post"
    }
}

/**
 * @export
 * @enum {string}
 */
export enum OauthDocumentsListGetLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
/**
 * @export
 * @enum {string}
 */
export enum OauthLoginGetResponseTypeEnum {
    Code = 'code',
    IdToken = 'id_token',
    Token = 'token'
}
/**
 * @export
 * @enum {string}
 */
export enum OauthLoginGetScopeEnum {
    Openid = 'openid',
    Profile = 'profile'
}
