import React, { ReactNode } from 'react';
import styled from 'styled-components';
import {
  IPropsCenter,
  IStateCenter,
  ACenteredOverlay,
} from '../../../logic/handler/overlayhandler/globaloverlays/aCenteredOverlay';

interface IProps extends IPropsCenter {
  image?: Blob;
  alt?: string;
}

interface IState extends IStateCenter {}

export default class ImagePreview extends ACenteredOverlay<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  reviveState(): void {}

  defineContent(): ReactNode {
    const urlCreator = window.URL;

    return (
      <Container>
        <BigImage
          src={
            this.props.image != null
              ? urlCreator.createObjectURL(this.props.image)
              : window.location.origin + this.props.alt
          }
          alt={this.props.alt == null ? 'no image' : this.props.alt}
          onClick={this.props.selfClose}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
`;

const BigImage = styled.img`
  max-width: 80vw;
  max-height: 80vh;
  object-fit: cover;
`;
