import { Ident } from '../../logic/api';

export default class PathMap {
  static getPath(key: string): Ident.AuthConnection {
    switch (key) {
      default:
      case 'LoginUserPassword':
      case 'login_user_password':
        return Ident.AuthConnection.LoginUserPassword;
      case 'Signup':
      case 'signup':
        return Ident.AuthConnection.Signup;
      case 'ResetPassword':
      case 'reset_password':
        return Ident.AuthConnection.ResetPassword;
      case 'Verify':
      case 'verify':
        return Ident.AuthConnection.Verify;
      case 'SetPassword':
      case 'set_password':
        return Ident.AuthConnection.SetPassword;
    }
  }
}
