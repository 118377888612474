// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, throwIfRequired, encodeURI } from '../runtime';
import {
    OnfidoWebhookNotification,
} from '../models/index';

export interface OnfidoBackofficeNotificationTogglePutRequest {
    onfido_backoffice_notification_id: number;
}

export interface OnfidoBackofficeNotificationsGetRequest {
    limit: number;
}

export interface OnfidoWebhookRequest {
    apikey: string;
    OnfidoWebhookNotification: OnfidoWebhookNotification;
}

/**
 * no description
 */
export class OnfidoApi extends BaseAPI implements IOperationIdMap {

    /**
     * onfido toggel notification
     */
    onfidoBackofficeNotificationTogglePut = (requestParameters: OnfidoBackofficeNotificationTogglePutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'onfido_backoffice_notification_id', 'onfidoBackofficeNotificationTogglePut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/onfido/backoffice/notification/{onfido_backoffice_notification_id}'.replace('{onfido_backoffice_notification_id}', encodeURI(requestParameters.onfido_backoffice_notification_id)),
            method: 'PUT',
            headers,
        });
    };

    /**
     * onfido get notifications
     */
    onfidoBackofficeNotificationsGet = (requestParameters: OnfidoBackofficeNotificationsGetRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'limit', 'onfidoBackofficeNotificationsGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/onfido/backoffice/notifications/{limit}'.replace('{limit}', encodeURI(requestParameters.limit)),
            method: 'GET',
            headers,
        });
    };

    /**
     * onfido webhook
     */
    onfidoWebhook = (requestParameters: OnfidoWebhookRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'apikey', 'onfidoWebhook');
        throwIfRequired(requestParameters, 'OnfidoWebhookNotification', 'onfidoWebhook');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/onfido/{apikey}'.replace('{apikey}', encodeURI(requestParameters.apikey)),
            method: 'POST',
            headers,
            body: requestParameters.OnfidoWebhookNotification,
        });
    };


    operationToOperationId = {
	    "onfidoBackofficeNotificationTogglePut": "onfido_backoffice_notification_toggle_put" ,
	    "onfidoBackofficeNotificationsGet": "onfido_backoffice_notifications_get" ,
	    "onfidoWebhook": "onfido_webhook"
    }
}
