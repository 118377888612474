// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * valid card providers: * visa - Visa * master_card - Mastercard * current_institution - the current institution
 * @export
 * @enum {string}
 */
export enum CardProvider {
  Visa = 'visa',
  MasterCard = 'master_card',
  CurrentInstitution = 'current_institution',
}
