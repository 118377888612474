import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';
import { IAPIData } from '../actions';

class IAPidataStore extends EventEmitter {
  apiData?: IAPIData;
  apiResponse?: any;
  private static instance: IAPidataStore | null;

  constructor() {
    super();
    IDispatcher.register(this.registerActions.bind(this));
    this.setApiData = this.setApiData.bind(this);
  }
  static getInstance(): IAPidataStore {
    if (IAPidataStore.instance == null) {
      IAPidataStore.instance = new IAPidataStore();
    }

    return IAPidataStore.instance;
  }

  registerActions(action: any) {
    switch (action.actionType) {
      default:
        break;
      case Constants.CLEAR_ALL:
        this.apiData = undefined;
        this.apiResponse = undefined;
        this.emit(CHANGE_EVENT);
        break;
      case Constants.API_WORKFLOW.DATA_GET:
        this.setApiData(action.apidata);
        this.emit(CHANGE_EVENT);
        break;
      case Constants.API_WORKFLOW.DATA_CHANGED:
        this.setApiResponse(action.data);
        this.emit(CHANGE_EVENT);
        break;
    }
    return true;
  }

  setApiData(apidata: IAPIData) {
    this.apiData = apidata;
  }

  getApiData() {
    return this.apiData;
  }
  setApiResponse(response: any) {
    this.apiResponse = response;
  }

  getApiResponse() {
    return this.apiResponse;
  }

  addChangeListener(callback: any) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback: any) {
    this.removeListener(CHANGE_EVENT, callback);
  }
}

export const ApiDataStore = IAPidataStore.getInstance();
