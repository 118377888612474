import React from 'react';
import styled from 'styled-components';
import {
  IStatusState,
  IMessageConfig,
  Reporter,
  getFormComponents,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { IItfAccount } from '../../../../logic/types';
import { Account, api, ApiError, apis } from '../../../../logic/api';
import { format } from '../../../../logic/helper/format';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { Log, Logs } from '../../../../logic/log';
import { Icons } from '../../../../images';
import { translate } from '../../../../common/language/translate';
import {
  IPropsCenter,
  IStateCenter,
  ACenteredOverlay,
} from '../../../../logic/handler/overlayhandler/globaloverlays/aCenteredOverlay';
import { Table } from '../../../../components/compositcomponents/table/table';
import { KeyValueDisplayComponent } from '../../../../components/compositcomponents/display/keyValueDisplayComponent';
import { FlexBox, Container } from '../../../auth/auth.css';
import { RowType } from '../../../../components/compositcomponents/table/tableTypes';
import { ContainerText, FileChooserStyle } from './transfersComponent.css';
import moment from 'moment';
import TouchableOpacity from '../../../../components/atomiccompoents/buttons/touchableOpacity';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import {
  OverlayHandler,
  Overlays,
} from '../../../../logic/handler/overlayhandler/overlayHandler';
import { FileType } from '../../../../components/compositcomponents/popup/fileTypeChooserOverlay';
import {
  downloadFile,
  getPersonForAccount,
  revertTransaction,
  updateAccount,
  updatePerson,
} from '../../../../logic/helper/Common';
import { ReportAcceptTypes } from '../reports/reportsComponent';
import ActivityIndicator from '../../../../components/atomiccompoents/activityIndicator';
import { ClientConfig } from '../../../../common/config';
import { ThemeStore } from '../../../../logic/flux/stores/themeStore';
import { StyledSelect } from '../basicStyledComponents/customersComponent.css';
import { IOption } from '../../../../components/atomiccompoents/form/select';

const StyledOverlay = styled.div<{ pending?: boolean }>`
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  border-radius: 5px;
  width: 80%;
  height: ${props => (props.pending !== true ? '50%' : '70%')};
  min-width: 900px;
  min-height: 500px;

  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  cursor: default;
`;

const StyledViewLeft = styled(FlexBox)`
  width: 100%;
  padding: 10px 30px;
  display: flex;
  flex-direction: row;
`;

const StyledHeaderLeft = styled(FlexBox)`
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: #98a9bc;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
  border-bottom: 1px solid #dedede;
`;

const IconBox = styled(StyledHeaderLeft)`
  flex-direction: row;
`;

const StyledHeaderRight = styled(FlexBox)`
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.Global.lightFontColor};
  margin-bottom: 12px;
  font-weight: 500;
`;

const StyledEmptyLine = styled(FlexBox)`
  width: 100%;
  height: 50px;
`;

const StyledSpanLeftPrint = styled(FlexBox)`
  margin-left: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  div-transform: uppercase;
  color: ${props => props.theme.Global.lightFontColor};
`;

const StyledViewRight = styled(FlexBox)`
  background-color: #ffffff;
  width: 100%;
  padding: 30px;
  padding-top: 5px;
  & > div > div > table > tbody > tr > td2,
  & > div > div > table > tbody > tr > td > div > div {
    max-width: 400px;
  }
  git & > div > div > table {
    table-layout: auto;
  }
`;

const StyledOpacityIcon = styled(FlexBox)`
  position: absolute;
  right: 8px;
  top: 8px;
  width: 16px;
  height: 16px;
  z-index: 350;
`;

const StyledLine = styled.hr`
  border: 1px solid #f2f4f6;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const StyledMetaBox = styled.div`
  box-shadow: ${props => props.theme.Box.boxShadow};
  margin: auto;
  padding: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-radius: 5px;
`;
const SelectBox = styled.div`
  min-width: 150px;
`;
interface IProps extends IPropsCenter {
  taId: number;
  transactionId: number;
  account?: IItfAccount;
  openedFromSearch?: boolean;
  callback: (path: string) => void;
  boCallback: (path: string) => void;
}

interface ITransaction {
  posting: string;
  bookingDate: string;
  valueDate: string;
  moneyStatements?: Array<any>;
  dispoStatements?: Array<any>;
  src: string;
}

interface IState extends IStatusState, IStateCenter {
  errorCode: string;
  timeUsed: string;
  systemDate: Date | null;
  postingData: Array<ITransaction>;
  subtotalCustomer: number;
  subtotalFees: number;
  currency: string;
  rawResponse?: Account.PostingJournal;
  downloadLoading?: boolean;
  pendingSet?: boolean;
  selectedTransaction?: any;
}

class PostingJournalOverlay extends ACenteredOverlay<IProps, IState> {
  private converter = (
    line: Account.PostingJournalTMoneyStatements,
    index: number,
    fields: Array<string>,
  ): RowType => {
    const row: RowType = { cells: [], ref: line };
    if (row.cells == null) {
      return row;
    }
    const params = {
      taId: this.props.taId,
      transactionId: this.props.transactionId,
      //@ts-ignore
      accountNumber:
        line.account_number != null
          ? line.account_number
          : this.props.account != null
          ? this.props.account.account_number
          : null,
    };
    const keys: Array<string> = Object.keys(line);
    const LimitOverlayCallback = () => {
      OverlayHandler.showOverlay(Overlays.limitInfoOverlay, {
        check_limit: line.check_tx_limit,
        write_limit: line.write_tx_limit,
        check_balance: line.check_balance_limit,
        //@ts-ignore
        acc_nr: line.account_number,
      });
    };
    for (let i = 0; i < fields.length; i++) {
      const field: string = fields[i];
      switch (field) {
        case 'account_number': {
          row.cells.push({
            value: line.account_number,
            display: (
              <Container>
                <ContainerText title={line.account_number}>
                  {line.account_number}
                </ContainerText>
              </Container>
            ),
            methods: {
              customersCallback:
                this.props.openedFromSearch === true
                  ? () => {
                      //@ts-ignore
                      this.openPerson(
                        line.account_number != null
                          ? line.account_number
                          : this.props.account != null
                          ? this.props.account.account_number
                          : '',
                      );
                    }
                  : undefined,
              accountCallback: () => {
                //@ts-ignore
                this.openAccount(
                  line.account_number != null
                    ? line.account_number
                    : this.props.account != null
                    ? this.props.account.account_number
                    : '',
                );
              },
              transactioNDetailsCallback: () => {
                OverlayHandler.showOverlay(Overlays.transactionDetail, params);
              },
              revertTransactionCallback: () => {
                revertTransaction(this.props.taId, this.props.transactionId);
              },
            },
            copyVal: line.account_number,
          });
          break;
        }
        case 'checks': {
          row.cells.push({
            value:
              line.check_tx_limit + ',' + line.write_tx_limit + ',' + line.check_balance_limit,
            display: (
              <ContainerText
                style={{ display: 'flex', flexDirection: 'row' }}
                title={
                  line.check_tx_limit +
                  ',' +
                  line.write_tx_limit +
                  ',' +
                  line.check_balance_limit
                }
              >
                {line.check_tx_limit === false ? (
                  <div style={{ marginRight: '4px' }}>{Icons.cancelCross()}</div>
                ) : (
                  <div style={{ marginRight: '4px' }}>{Icons.checkBoxIcon()} </div>
                )}
                {line.write_tx_limit === false ? (
                  <div style={{ marginRight: '4px' }}>{Icons.cancelCross()}</div>
                ) : (
                  <div style={{ marginRight: '4px' }}>{Icons.checkBoxIcon()} </div>
                )}
                {line.check_balance_limit === false ? (
                  <div style={{ marginRight: '4px' }}>{Icons.cancelCross()}</div>
                ) : (
                  <div style={{ marginRight: '4px' }}>{Icons.checkBoxIcon()} </div>
                )}
              </ContainerText>
            ),
            methods: {
              customersCallback:
                this.props.openedFromSearch === true
                  ? () => {
                      //@ts-ignore
                      this.openPerson(
                        line.account_number != null
                          ? line.account_number
                          : this.props.account != null
                          ? this.props.account.account_number
                          : '',
                      );
                    }
                  : undefined,
              accountCallback: () => {
                //@ts-ignore
                this.openAccount(
                  line.account_number != null
                    ? line.account_number
                    : this.props.account != null
                    ? this.props.account.account_number
                    : '',
                );
              },
              transactioNDetailsCallback: () => {
                OverlayHandler.showOverlay(Overlays.transactionDetail, params);
              },
              revertTransactionCallback: () => {
                revertTransaction(this.props.taId, this.props.transactionId);
              },
            },
            copyVal:
              line.check_tx_limit + ',' + line.write_tx_limit + ',' + line.check_balance_limit,
          });
          break;
        }
        default: {
          if (Object.prototype.hasOwnProperty.call(line, field)) {
            row.cells.push({
              value: Object.values(line)[keys.indexOf(field)],
              display: (
                <ContainerText title={Object.values(line)[keys.indexOf(field)]}>
                  {Object.values(line)[keys.indexOf(field)]}
                </ContainerText>
              ),
              methods: {
                customersCallback:
                  this.props.openedFromSearch === true
                    ? () => {
                        //@ts-ignore
                        this.openPerson(
                          line.account_number != null
                            ? line.account_number
                            : this.props.account != null
                            ? this.props.account.account_number
                            : '',
                        );
                      }
                    : undefined,
                accountCallback: () => {
                  //@ts-ignore
                  this.openAccount(
                    line.account_number != null
                      ? line.account_number
                      : this.props.account != null
                      ? this.props.account.account_number
                      : '',
                  );
                },
                transactioNDetailsCallback: () => {
                  OverlayHandler.showOverlay(Overlays.transactionDetail, params);
                },
                cancelCallback: () => {
                  revertTransaction(this.props.taId, this.props.transactionId);
                },
              },
              copyVal: Object.values(line)[keys.indexOf(field)],
            });
          } else {
            row.cells.push({ value: '' });
          }
          break;
        }
      }
    }

    return row;
  };
  constructor(props: IProps) {
    super(props);

    const n: IState = {
      errorCode: '',
      timeUsed: '',
      systemDate: null,
      postingData: [],
      subtotalCustomer: 0,
      subtotalFees: 0,
      currency: '',
    };
    this.state = n;

    this.fetchPostingJournal();
    this.onCancel = this.onCancel.bind(this);
    this.downloadStatement = this.downloadStatement.bind(this);
    this.setCurrentTransaction = this.setCurrentTransaction.bind(this);
  }

  onCancel(): void {
    this.props.selfClose();
  }

  openPerson(accountNumber: string) {
    //@ts-ignore
    getPersonForAccount(accountNumber)
      .then((result: Array<number>) => {
        if (result.length === 1) {
          updatePerson(
            result[0],
            '/customers/details/transactions/customertransactions',
            this.props.callback,
          );
          this.props.selfClose();
        } else {
          MessageHandler.onError(Reporter['account.person.get.multipleResults']);
        }
      })
      .catch((error: ApiError) => {
        MessageHandler.onError(Reporter['account.person.get']);
      });
  }

  openAccount(accountNumber: string) {
    updateAccount(accountNumber, '/backoffice/transactions', this.props.boCallback);
    this.props.selfClose();
  }

  fetchPostingJournal(taId?: number, transactionId?: number) {
    if (
      (this.props.taId == null || this.props.transactionId == null) &&
      (taId == null || transactionId == null)
    ) {
      Log.debug(Logs.API, 'Missing parameters to fetch transactionGet');
      return;
    }
    const transactionRequest: Account.TransactionGetRequest = {
      ta_id: taId == null ? this.props.taId : taId,
      transaction_id: transactionId == null ? this.props.transactionId : transactionId,
    };

    api
      .asyncRequest<any>(transactionRequest, apis.DefaultApi, 'transactionGet')
      .then((response: any) => {
        const transactions: Array<ITransaction> = [];
        const moneyStatements: Array<Object> = [];
        const dispoStatemens: Array<Object> = [];
        const bookingDate = format.date(moment(response.t_transaction.booked).toDate());
        const createDate = format.date(
          moment(response.t_transaction.datetime_create).toDate(),
        );
        const transaction: ITransaction = {
          posting: response.t_transaction
            ? response.t_transaction.ta_id + '/' + response.t_transaction.transaction_id
            : '',
          bookingDate: bookingDate != null ? bookingDate : response.t_transaction.booked,
          valueDate: createDate != null ? createDate : response.t_transaction.datetime_create,
          moneyStatements: moneyStatements,
          dispoStatements: dispoStatemens,
          src: response.t_transaction.source_id,
        };

        const subtotalCustomer: number = 0;
        const subtotalFees: number = 0;
        const currency: string = '';
        if (response.t_money_statements != null) {
          response.t_money_statements.forEach(
            (elem: Account.PostingJournalTMoneyStatements) => {
              const sign = elem.dc === 'D' ? -1 : 1;
              const newEntry = {
                accountNumber: elem.account_number,
                accountName: elem.description,
                statementText: elem.statement_text,
                amount: format.number(sign * elem.amount, 2) + ' ' + elem.currency,
                key: elem.txkey_id,
                descr: elem.statement_text,
                statement_id: elem.statement_id,
                subbalance: elem.subbalance,
                txkey_description: elem.txkey_description,
                checks: '',
                check_tx_limit: elem.check_tx_limit,
                memo: elem.memo != null ? elem.memo : '',
                write_tx_limit: elem.write_tx_limit,
                check_balance_limit: elem.check_balance_limit,
              };

              if (transaction.moneyStatements != null) {
                transaction.moneyStatements.push(newEntry);
              }
            },
          );
        }
        let pendingSet = false;
        if (response.dispo_statements != null) {
          pendingSet = true;
          response.dispo_statements.forEach((elem: Account.PostingJournalTMoneyStatements) => {
            const sign = elem.dc === 'D' ? '-' : '';
            const newEntry = {
              accountNumber: elem.account_number,
              accountName: elem.description,
              statementText: elem.statement_text,
              amount: sign + format.number(elem.amount, 2) + ' ' + elem.currency,
              key: elem.txkey_id,
              descr: elem.statement_text,
              statement_id: elem.statement_id,
              subbalance: elem.subbalance,
              txkey_description: elem.txkey_description,
              checks: '',
              check_tx_limit: elem.check_tx_limit,
              memo: elem.memo != null ? elem.memo : '',
              write_tx_limit: elem.write_tx_limit,
              check_balance_limit: elem.check_balance_limit,
            };
            if (transaction.dispoStatements != null) {
              transaction.dispoStatements.push(newEntry);
            }
          });
        }

        transactions.push(transaction);

        const n: IState = {
          systemDate: response.t_transaction.datetimeCreate,
          errorCode: response.t_transaction.errorCode,
          timeUsed: response.t_transaction.timeused,
          postingData: transactions,
          subtotalCustomer: subtotalCustomer,
          subtotalFees: subtotalFees,
          currency: currency,
          rawResponse: response,
          pendingSet: pendingSet,
        };
        this.setState(n);
      })
      .catch((error: ApiError) => {
        const config: IMessageConfig = MessageHandler.onError(
          Reporter['transaction.posting.journal.get.request'],
        );
        this.setState({
          showInlineError: config.errorMethods.inline,
          errorMessage: config.errorMessage != null ? config.errorMessage : error.statusText,
          key: config.translationKey != null ? config.translationKey + '.error' : '',
        });
        Log.debug(Logs.API, 'fetchPostingJournal error: transactionGet failed.');
      });
  }

  reviveState(): void {}

  private getAcceptHeader(report: string, filetype: FileType): ReportAcceptTypes {
    if (filetype === FileType.csv) return 'text/comma-separated-values';
    else if (filetype === FileType.pdf) return 'application/pdf';
    else if (filetype === FileType.docx)
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    else return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }

  private downloadStatement(filetype: FileType): void {
    this.setState({ downloadLoading: true });
    Log.debug(Logs.COMPONENT, 'Clicked downloadStatement');

    const tableArray = this.state.rawResponse;
    if (filetype === FileType.inApp) {
      OverlayHandler.showOverlay(Overlays.reportOverlay, { data: tableArray });
      this.setState({ downloadLoading: false });
      return;
    }
    const fileEnding: string =
      filetype === FileType.csv ? 'csv' : filetype === FileType.pdf ? 'pdf' : 'xlsx';
    const report = 'posting_journal';
    const params = {
      accept: this.getAcceptHeader(report, filetype),
      body: {
        data: tableArray,
      },
    }; //TODO REMOVE TABLE

    api
      .reportRequest(params, report + '.xlsx')
      .then(file => {
        this.setState({ downloadLoading: false });
        downloadFile(file, format.getDateString() + '_report.' + fileEnding);
      })
      .catch(error => Log.error(Logs.REMOVABLE, error));
  }

  generateRelatedIds(): Array<IOption> {
    const out: Array<IOption> = [];
    out.push({
      name: 'Origin',
      key: this.props.taId + '/' + this.props.transactionId,
      value: this.props.taId + '/' + this.props.transactionId,
    });
    if (
      this.state.rawResponse != null &&
      this.state.rawResponse.related_transactions != null
    ) {
      for (const o of this.state.rawResponse.related_transactions) {
        out.push({
          name: o.ta_id + '/' + o.transaction_id,
          key: o.ta_id + '/' + o.transaction_id,
          value: o.ta_id + '/' + o.transaction_id,
        });
      }
    }
    return out;
  }

  setCurrentTransaction(message: any) {
    const data = message.transaction;
    this.setState(
      {
        selectedTransaction: data,
      },
      () => {
        const ta_id = data.split('/')[0];
        const transaction_id = data.split('/')[1];
        this.fetchPostingJournal(ta_id, transaction_id);
      },
    );
  }

  defineContent() {
    return (
      <StyledOverlay
        onClick={event => {
          event.stopPropagation();
          OverlayHandler.closeSpecific(Overlays.contextMenu);
          OverlayHandler.closeSpecific(Overlays.FileTypeChooser);
        }}
        pending={this.state.pendingSet}
      >
        <StyledOpacityIcon onClick={this.onCancel}>{Icons.closingCross()}</StyledOpacityIcon>
        <StyledViewLeft>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <StyledHeaderRight>
              {translate('transfer.postingJournal.rightTitle')}
            </StyledHeaderRight>
            <StyledMetaBox>
              {this.state.postingData.map(transaction => [
                <KeyValueDisplayComponent
                  key={'posting' + transaction.posting}
                  keyLabel={translate('transfer.postingJournal.posting')}
                  valueLabel={transaction.posting}
                  skipUnderLine={true}
                />,
                <KeyValueDisplayComponent
                  key={'bokingdate' + transaction.src}
                  keyLabel={translate('transfer.postingJournal.source')}
                  valueLabel={transaction.src}
                  skipUnderLine={true}
                />,
                <KeyValueDisplayComponent
                  key={'valuedate' + transaction.posting}
                  keyLabel={translate('transfer.postingJournal.valueDate')}
                  valueLabel={transaction.valueDate}
                  skipUnderLine={true}
                />,
                <KeyValueDisplayComponent
                  key={'bokingdate' + transaction.posting}
                  keyLabel={translate('transfer.postingJournal.bookingDate')}
                  valueLabel={transaction.bookingDate}
                  skipUnderLine={true}
                />,
                <KeyValueDisplayComponent
                  key={'memo' + transaction.posting}
                  keyLabel={translate('transfer.postingJournal.memo')}
                  valueLabel={
                    this.props.account != null && this.props.account.memo != null
                      ? this.props.account.memo
                      : null
                  }
                  skipUnderLine={true}
                />,
              ])}
              {this.state.rawResponse != null &&
              this.state.rawResponse.related_transactions != null ? (
                <SelectBox>
                  <StyledSelect
                    id="transaction"
                    current={this.state.selectedTransaction}
                    options={this.generateRelatedIds()}
                    notification={this.setCurrentTransaction}
                  />
                </SelectBox>
              ) : null}
              <IconBox>
                <StyledSpanLeftPrint>
                  <ButtonOk
                    disableSpinner={true}
                    style={{
                      width: '100px',
                      height: '40px',
                      padding: '0px',
                    }}
                    onClick={(event: any) => {
                      event.preventDefault();
                    }}
                  >
                    <TouchableOpacity
                      onClick={(event, domRect) =>
                        OverlayHandler.showOverlay(Overlays.FileTypeChooser, {
                          callback: this.downloadStatement,
                          posX: domRect == null ? 0 : domRect.x - 2,
                          posY: domRect == null ? 0 : domRect.y - 142,
                          width: '100px',
                        })
                      }
                      containerStyle={FileChooserStyle}
                    >
                      {this.state.downloadLoading === true ? (
                        <div style={{ marginBottom: '15px' }}>
                          <ActivityIndicator animating={true} size="small" />
                        </div>
                      ) : (
                        <div style={{ marginBottom: '15px' }}>{Icons.downloadIcon()} </div>
                      )}
                    </TouchableOpacity>
                  </ButtonOk>
                </StyledSpanLeftPrint>
              </IconBox>
            </StyledMetaBox>
          </div>
        </StyledViewLeft>

        <StyledViewRight>
          <StyledLine />
          {getFormComponents(
            this.state.showInlineError,
            this.state.showInlineSuccess,
            this.state.errorMessage,
            this.state.successMessage,
            this.state.key,
          )}
          {this.state.postingData.map(transaction => [
            transaction.moneyStatements != null && transaction.moneyStatements.length > 0 ? (
              <Table
                dataConverter={this.converter}
                hidePaging={true}
                key={'txtable' + transaction.posting}
                fields={
                  ClientConfig.showLimits
                    ? [
                        'accountNumber',
                        'accountName',
                        'statementText',
                        'key',
                        'txkey_description',
                        'memo',
                        'amount',
                        'checks',
                      ]
                    : [
                        'accountNumber',
                        'accountName',
                        'statementText',
                        'key',
                        'txkey_description',
                        'memo',
                        'amount',
                      ]
                }
                header={
                  ClientConfig.showLimits
                    ? [
                        translate('transfer.postingJournal.header.account'),
                        translate('transfer.postingJournal.header.accountName'),
                        translate('transfer.postingJournal.header.statementText'),
                        translate('transfer.postingJournal.header.key'),
                        translate('transfer.postingJournal.header.keyDescription'),
                        'Memo',
                        translate('transfer.postingJournal.header.amount'),
                        translate('customers.limits.applied'),
                      ]
                    : [
                        translate('transfer.postingJournal.header.account'),
                        translate('transfer.postingJournal.header.accountName'),
                        translate('transfer.postingJournal.header.statementText'),
                        translate('transfer.postingJournal.header.key'),
                        translate('transfer.postingJournal.header.keyDescription'),
                        'Memo',
                        translate('transfer.postingJournal.header.amount'),
                      ]
                }
                sourceData={
                  transaction.moneyStatements != null ? transaction.moneyStatements : []
                }
              />
            ) : null,
            <StyledEmptyLine key={'emptlinerigth' + transaction.posting} />,
            transaction.dispoStatements != null && transaction.dispoStatements.length > 0 ? (
              <StyledHeaderRight>{translate('transactions.pending')}</StyledHeaderRight>
            ) : null,
            transaction.dispoStatements != null && transaction.dispoStatements.length > 0 ? (
              <Table
                dataConverter={this.converter}
                hidePaging={true}
                key={'txtableDispo' + transaction.posting}
                fields={
                  ClientConfig.showLimits
                    ? [
                        'accountNumber',
                        'accountName',
                        'statementText',
                        'key',
                        'txkey_description',
                        'memo',
                        'amount',
                        'checks',
                      ]
                    : [
                        'accountNumber',
                        'accountName',
                        'statementText',
                        'key',
                        'txkey_description',
                        'memo',
                        'amount',
                      ]
                }
                header={
                  ClientConfig.showLimits
                    ? [
                        translate('transfer.postingJournal.header.account'),
                        translate('transfer.postingJournal.header.accountName'),
                        translate('transfer.postingJournal.header.statementText'),
                        translate('transfer.postingJournal.header.key'),
                        translate('transfer.postingJournal.header.keyDescription'),
                        'Memo',
                        translate('transfer.postingJournal.header.amount'),
                        translate('customers.limits.applied'),
                      ]
                    : [
                        translate('transfer.postingJournal.header.account'),
                        translate('transfer.postingJournal.header.accountName'),
                        translate('transfer.postingJournal.header.statementText'),
                        translate('transfer.postingJournal.header.key'),
                        translate('transfer.postingJournal.header.keyDescription'),
                        'Memo',
                        translate('transfer.postingJournal.header.amount'),
                      ]
                }
                sourceData={
                  transaction.dispoStatements != null ? transaction.dispoStatements : []
                }
              />
            ) : null,
            transaction.dispoStatements != null && transaction.dispoStatements.length > 0 ? (
              <StyledEmptyLine key={'emptlinerigthend' + transaction.posting} />
            ) : null,
          ])}
        </StyledViewRight>
        {ClientConfig.showLimits ? (
          <div style={{ marginLeft: '32px', fontSize: '12px' }}>
            {'* C - ' +
              translate('customers.limits.check') +
              ', W - ' +
              translate('customers.limits.write') +
              ', B - ' +
              translate('customers.limits.balance')}
          </div>
        ) : null}
      </StyledOverlay>
    );
  }
}

export default PostingJournalOverlay;
