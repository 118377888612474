import React from 'react';
import GenericTemplateComponent, {
  GenericTemplateType,
  TemplateCategory,
} from '../../../genericUI/genericTemplateComponent';
import { translate } from '../../../../common/language/translate';
import { OverlayHandler } from '../../../../logic/handler/overlayhandler/overlayHandler';

interface IProps {}

interface IState {
  responseData?: any;
}

export default class BackofficeTemplateComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div onClick={() => OverlayHandler.closeOverlaysOnClick()}>
          <GenericTemplateComponent
            templateType={GenericTemplateType.posting_template}
            templateCategory={TemplateCategory.backoffice}
            heading={translate('generic.createPosting')}
            subHeading={translate('generic.postingTemplate')}
          />
        </div>
      </React.Fragment>
    );
  }
}
