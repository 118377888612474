import React, { Suspense, SyntheticEvent } from 'react';
import { theme, themeMap } from './common/theme';
import { Router } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import Auth from './content/auth/auth';
import DashboardComponent from './content/dashboard/dashboard';
import 'typeface-roboto';
import { OverlayHandler } from './logic/handler/overlayhandler/overlayHandler';
import history from './common/history';
import { Ident, api, apis, ApiError } from './logic/api';
import { MessageHandler } from './logic/handler/messagehandler/messageHandler';
import { Reporter } from './logic/handler/messagehandler/messageHandlerConfig';
import { Log, Logs } from './logic/log';
import { UserDataStore, Actions, INotifyAbleUserData } from './logic/flux';
import { INotifyAbleTheme } from './logic/flux/notifyAbles/notifyAbleTheme';
import { ThemeStore, themeType } from './logic/flux/stores/themeStore';
import { performLogin } from './logic/helper/Common';
import { options } from './logic/handler/messagehandler/overlays';

const GlobalStyle = createGlobalStyle`
    html,
    body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
          -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${theme.Global.darkFontColor};
		background-color: ${theme.Global.backgroundColor};
    }
`;

interface IState {
  response?: Ident.AuthParams | Ident.PermissionResponse;
  overlayCount: number;
  user?: Ident.Person;
  theme: themeType;
}

export class App extends React.Component<{}, IState>
  implements INotifyAbleUserData, INotifyAbleTheme {
  initialized: boolean = false;
  loginState: boolean = false;
  private loginRequestRunning: boolean = false;

  constructor(props = {}) {
    super(props);

    this.state = {
      response: undefined,
      overlayCount: 0,
      theme: 'default',
    };

    this.forceRerender = this.forceRerender.bind(this);
    this.updateOverlayState = this.updateOverlayState.bind(this);
    this._onChangeUserData = this._onChangeUserData.bind(this);
    this._onChangeTheme = this._onChangeTheme.bind(this);
    OverlayHandler.setOptions(options, this.updateOverlayState);
    OverlayHandler.setUpdateApp(this.forceRerender);
  }

  _onChangeTheme(): void {
    Log.info(Logs.SYSTEM, ThemeStore.getTheme());
    this.setState({
      theme: ThemeStore.getTheme(),
    });
  }

  _onChangeUserData(): void {
    this.setState({
      user: UserDataStore.getUser(),
      response: undefined,
    });
  }

  private isAuthParams(arg: any): arg is Ident.AuthParams {
    if (arg == null) {
      return false;
    }
    return (
      arg.connection !== undefined && arg.idp_state !== undefined && arg.exp !== undefined
    );
  }

  private isPermissionResponse(arg: any): arg is Ident.PermissionResponse {
    if (arg == null) {
      return false;
    }
    return arg.person !== undefined && arg.operation_ids !== undefined;
  }

  componentDidMount() {
    ThemeStore.addChangeListener(this._onChangeTheme);
    this._onChangeTheme();
    UserDataStore.addChangeListener(this._onChangeUserData);
    this._onChangeUserData();

    this.doLoginRequest();
  }

  componentWillUnmount(): void {
    UserDataStore.removeChangeListener(this._onChangeUserData);
    ThemeStore.removeChangeListener(this._onChangeTheme);
  }

  closeOverlays(event: SyntheticEvent) {
    if (event.isPropagationStopped()) {
      return;
    }
    if (this.state.overlayCount > 0) {
      OverlayHandler.closeOverlaysOnClick();
    }
  }

  private doLoginRequest(): void {
    if (this.loginRequestRunning === true) {
      return;
    }
    this.loginRequestRunning = true;

    api.request(
      [],
      apis.OpenIDConnectApi,
      'authAuthorizeGet',
      (error: ApiError, response: Ident.AuthParams | Ident.PermissionResponse) => {
        if (this.isAuthParams(response)) {
          Actions.updateLastActivity();
          //@ts-ignore
          UserDataStore.setRoles(response.amzn_oidc_data?.user_roles || []);
          Actions.setLogoutAbsTime(response.exp);
          this.setState({
            response: response,
          });
        } else if (this.isPermissionResponse(response)) {
          performLogin(response).catch((error: ApiError) => {
            MessageHandler.onError(
              Reporter['perform.login.permission.response'],
              error.statusText,
            );
            Log.error(Logs.API, error);
          });
        } else {
          MessageHandler.onError(Reporter['perform.login.unknown.error']);
          Log.error(Logs.API, error);
        }
        this.loginRequestRunning = false;
      },
    );
  }

  updateOverlayState(currentOverlays: number) {
    this.setState({ overlayCount: currentOverlays });
  }

  forceRerender() {
    this.forceUpdate();
  }

  render() {
    if (this.state.user != null && this.state.user.state !== Ident.PersonStateEnum.C) {
      return (
        <div>
          <Suspense fallback="loading">
            <ThemeProvider theme={themeMap.get(this.state.theme) || theme}>
              <Router history={history}>
                <GlobalStyle />

                <DashboardComponent
                  onClick={(event: SyntheticEvent) => {
                    this.closeOverlays(event);
                  }}
                />
              </Router>
            </ThemeProvider>
          </Suspense>
        </div>
      );
    } else if (this.state.response != null) {
      Log.debug(Logs.SYSTEM, window.location.pathname);
      return (
        <div>
          <Suspense fallback="loading">
            <ThemeProvider theme={theme}>
              <Router history={history}>
                <GlobalStyle />
                <Auth response={this.state.response as Ident.AuthParams} />
              </Router>
            </ThemeProvider>
          </Suspense>
        </div>
      );
    } else {
      this.doLoginRequest();

      return null;
    }
  }
}
