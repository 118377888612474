import React, { SyntheticEvent } from 'react';
import { StyledLine, StyledDivHeader } from '../basicStyledComponents/customersComponent.css';
import { Container as CheckBoxContainer } from '../transfer/transfersComponent.css';
import styled, { withTheme } from 'styled-components';
import { IdType, PersonFound } from '../../../../logic/api/ident';
import i18next from 'i18next';
import { Ident, api, apis, ApiError } from '../../../../logic/api';
import {
  IStatusState,
  IMessageConfig,
  Reporter,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { OverlayHandler } from '../../../../logic/handler/overlayhandler/overlayHandler';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { Log, Logs } from '../../../../logic/log';
import CustomerResultsBox from '../customers/customerResultsBox';
import { translate } from '../../../../common/language/translate';
import history from '../../../../common/history';
import {
  IInitProps,
  IInitState,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import { CustomerDataStore } from '../../../../logic/flux';
import { FlexBox } from '../../../auth/auth.css';
import { evaluateErrorMessage } from '../../../../logic/helper/Common';
import {
  StyledViewLeft,
  StyledBox,
} from '../customers/basicStyledComponents/customerDetails.css';
import Title from '../../../../components/compositcomponents/title';
import { Redirect } from 'react-router';

const queryString = require('query-string');

const StyledViewMain = styled(FlexBox)`
  display: flex;
  flex-direction: row;
  min-width: 700px;

  input[disabled] {
    background: #f2f4f6;
  }
`;

const StyledViewRight = styled(FlexBox)`
  flex-grow: 0;
  width: 50%;
  margin-left: 24px;
  div {
    width: 100%;
    max-height: calc(100vh - 226px);
  }
`;

interface IProps extends IInitProps {
  notification?: (person: PersonFound) => void;
}

interface IState extends IStatusState, IInitState {
  preset: string;
  limit: number;
  offset: number;
  data: Array<PersonFound>;
  redirect: boolean;
}

export class Last25 extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const n: IState = {
      preset: '',
      limit: 25,
      offset: 0,
      data: [],
      redirect: false,
    };

    this.state = n;

    this.onSubmit = this.onSubmit.bind(this);
    this.notification = this.notification.bind(this);
    this._onLanguageChanged = this._onLanguageChanged.bind(this);
    this.findPersonOnCreate = this.findPersonOnCreate.bind(this);
    this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
  }

  _onChangeCustomerData() {
    this.setState({
      redirect: true,
    });
  }

  _onLanguageChanged(/*lng: string*/): void {
    this.setState(this.state);
  }

  onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    this.fetchPersons();
  }

  findPersonOnCreate(id: number) {
    this.fetchPersons(true, id);
  }

  componentDidMount() {
    CustomerDataStore.addChangeListener(this._onChangeCustomerData);
  }

  fetchPersons(autoSelectOnSingleResult?: boolean, personId: number | null = null) {
    const personSearchRequest: any = {
      preset: this.state.preset,
      limit: this.state.limit,
      offset: this.state.offset,
    };
    this.doSearchRequest(personSearchRequest, autoSelectOnSingleResult);
  }

  doSearchRequest(
    req: any,
    autoSelectOnSingleResult?: boolean,
    compareResult?: Array<number>,
  ) {
    let data: Array<Ident.PersonFound>;
    api.request(
      req,
      apis.MaintenanceApi,
      'personSearch',
      (error: ApiError, response: Array<Ident.Person>) => {
        if (response != null) {
          data = response;
        } else {
          const config: IMessageConfig = MessageHandler.onError(
            Reporter['customer.search.post.request.noparameter.error'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
          this.setState({
            showInlineError: config.errorMethods.inline,
            errorMessage: config.errorMessage != null ? config.errorMessage : error.statusText,
            key: config.translationKey != null ? config.translationKey + '.error' : '',
          });
          Log.error(Logs.API, '\tfetchPersons: unknown error');
        }
        const newState: IState = {
          data: data,
          offset: this.state.offset,
          preset: '',
          limit: this.state.limit,
          redirect: this.state.redirect,
        };
        this.setState(newState);
      },
    );
  }

  private notification(person: Ident.PersonFound): void {
    if (this.props.notification != null) {
      this.props.notification(person);
    }
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.onSubmit(event);
    }
  };

  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/" />;
    }
    return (
      <StyledViewMain>
        <StyledViewLeft
          style={{
            maxHeight: 'calc(100vh - 220px)',
            overflow: 'auto',
            minWidth: '530px',
          }}
        >
          <StyledBox onClick={() => OverlayHandler.closeOverlaysOnClick()}>
            <FlexBox>
              <Title title={'Presets'} />
              <SelectBox
                onClick={() => {
                  this.setState(
                    {
                      preset: 'last25',
                    },
                    () => this.fetchPersons(),
                  );
                }}
              >
                {translate('dashboard.last25.last25customers')}
              </SelectBox>
            </FlexBox>
          </StyledBox>
        </StyledViewLeft>
        <StyledViewRight>
          <CustomerResultsBox
            notification={this.notification}
            data={this.state.data != null ? this.state.data : []}
            title={translate('customers.results')}
          />
        </StyledViewRight>
      </StyledViewMain>
    );
  }
}

const SelectBox = styled.div`
  margin: 32px;
  :hover {
    cursor: pointer;
  }
`;
