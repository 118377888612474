import React, { ReactElement } from 'react';
import styled from 'styled-components';
import {
  IPropsOver,
  IStateOver,
  AOverlay,
} from '../logic/handler/overlayhandler/globaloverlays/aOverlay';
import { BubbleStore, INotifyAbleBubbleData } from '../logic/flux';
import { HideComponent } from './hideComponent';

const DivMain = styled.div`
  position: fixed;
  display: block;
  float: right;
  overflow-y: visible;
  width: 200px;
  bottom: 30px;
  right: 30px;
  background-color: rgba(0, 0, 0, 0);
  align-items: center;
  z-index: 250;
`;

const Wrapper = styled.div`
  position: relative;
  width: 182px;
  margin-top: 15px;
  background-color: rgba(0, 0, 0, 0);
  align-items: center;
  display: block;
  float: right;
`;

interface IProps extends IPropsOver {
  bubbles: ReactElement;
}

interface IState extends IStateOver {
  bubbles: Array<ReactElement>;
}

export class BubbleContainer extends AOverlay<IProps, IState>
  implements INotifyAbleBubbleData {
  constructor(props: IProps) {
    super(props);
    this.state = { bubbles: [] };
    this.popBubble = this.popBubble.bind(this);
    this.pushBubble = this.pushBubble.bind(this);
    this._onChangeBubble = this._onChangeBubble.bind(this);
  }

  componentDidMount(): void {
    BubbleStore.addChangeListener(this._onChangeBubble);
    const bubble: ReactElement = this.createBubble(this.props.bubbles);
    this.setState({ bubbles: [bubble] });
  }

  componentWillUnmount(): void {
    BubbleStore.removeChangeListener(this._onChangeBubble);
  }

  _onChangeBubble() {
    const bubble = BubbleStore.getBubble();
    if (bubble != null) {
      this.pushBubble(bubble);
    }
  }

  createBubble(bubble: ReactElement): ReactElement {
    return (
      <Wrapper>
        {bubble}
        <HideComponent delay={5} children={bubble} onHide={this.popBubble} />
      </Wrapper>
    );
  }

  pushBubble(bubble: ReactElement) {
    const newBubbles = this.state.bubbles != null ? this.state.bubbles : [];

    newBubbles.push(this.createBubble(bubble));
    this.setState({ bubbles: newBubbles });
  }

  popBubble() {
    const newBubbles = this.state.bubbles.splice(1);
    this.setState({ bubbles: newBubbles });
  }

  reviveState(): void {}

  defineContent() {
    return <DivMain>{this.state.bubbles}</DivMain>;
  }
}
