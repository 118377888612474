import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { StyledLine, StyledSelect } from '../../basicStyledComponents/customersComponent.css';
import { translate } from '../../../../../common/language/translate';
import { FlexBox } from '../../../../auth/auth.css';
import Title from '../../../../../components/compositcomponents/title';
import { Account } from '../../../../../logic/api';
import { IOption } from '../../../../../components/atomiccompoents/form';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import EditableComponent from '../../../../../components/atomiccompoents/editableComponent/editableComponent';
import {
  AOverlay,
  IPropsOver,
  IStateOver,
} from '../../../../../logic/handler/overlayhandler/globaloverlays/aOverlay';
import { CustomDatePicker } from '../../../../../components/datepicker/tfdatepicker';
import { Icons } from '../../../../../images/icons/icons';

const StyledDivMain = styled(FlexBox)`
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 50px;
  padding-top: 20;
  display: flex;
  flex-direction: column;
  width: 480px;
  height: 240px;
  align-items: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  overflow-y: auto;
`;

interface IProps extends IPropsOver {
  callback: (item: { opening_date: Date; start_time: string; end_time: string }) => void;
}
interface IState extends IStateOver {
  item: { opening_date: Date; start_time: string; end_time: string };
  keyForLoadingSpinner: number;
  errors: { [key: string]: string };
}

export default class addCalendarItemExceptionOverlay extends AOverlay<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      item: {
        end_time: '18:00:00',
        start_time: '10:00:00',
        opening_date: new Date(),
      },
      errors: {},
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    };
  }

  reviveState() {}

  defineContent() {
    return (
      <StyledDivMain onClick={(event: SyntheticEvent) => event.stopPropagation()}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <div onClick={() => this.props.selfClose()}>{Icons.closingCross()}</div>
          </div>
          <Title title={translate('customers.details.merchant.add')} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: '52px',
              marginBottom: '16px',
            }}
          >
            <CustomDatePicker
              selectedValue={this.state.item.opening_date}
              onChange={(date: Date) => {
                const item = this.state.item;
                item.opening_date = date;
                this.setState({
                  item: item,
                });
              }}
              displayDate={true}
              resetCallback={() => {}}
              iconCallback={Icons.calendarExpand}
              boxStyle={{
                boxShadow: 'none',
                borderRadius: '0px',
                height: '24px',
                border: 'none',
                margin: 'auto',
                justifyContent: 'flex-start',
              }}
              textStyle={{
                color: '#4a4a4a',
                textAlign: 'left',
                margin: '0px',
                marginRight: '8px',
                fontWeight: 500,
                fontSize: '18px',
              }}
              smallText={true}
            />
            <EditableComponent
              id="itemtStartmtime"
              initText={this.state.item.start_time}
              changeCallback={(value: string) => {
                const item = this.state.item;
                item.start_time = value;
                this.setState({
                  item: item,
                });
              }}
              leaveCallback={() => {
                const value = this.state.item.start_time;
                const regexp: RegExp = /^(2[0-3]|[0-1]?[\d]):[0-5][\d]:[0-5][\d]$/;
                if (!regexp.test(value)) {
                  //MessageHandler.onError(Reporter['customer.merchants.time.invalid']);
                  const errors = this.state.errors;
                  errors['s'] = 'Invalid Time format';
                  this.setState({
                    errors: errors,
                  });
                } else {
                  if (this.state.errors['s'] != null) {
                    const errors = this.state.errors;
                    delete errors['s'];
                    this.setState({
                      errors: errors,
                    });
                  }
                }
              }}
              error={this.state.errors['s']}
              wrapperStyle={{
                border: 'none',
                width: '40%',
                alignItems: 'center',
              }}
              enterCallback={() => {}}
              viewStyle={{ fontSize: '18px' }}
              inputStyle={{ fontSize: '18px', width: '100%', minWidth: '100px' }}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>{' - '}</div>
            <EditableComponent
              id="itemEndTime"
              initText={this.state.item.end_time}
              changeCallback={(value: string) => {
                const item = this.state.item;
                item.end_time = value;
                this.setState({
                  item: item,
                });
              }}
              leaveCallback={() => {
                const value = this.state.item.end_time;
                const regexp: RegExp = /^(2[0-3]|[0-1]?[\d]):[0-5][\d]:[0-5][\d]$/;
                if (!regexp.test(value)) {
                  //MessageHandler.onError(Reporter['customer.merchants.time.invalid']);
                  const errors = this.state.errors;
                  errors['e'] = 'Invalid Time format';
                  this.setState({
                    errors: errors,
                  });
                } else {
                  if (this.state.errors['e'] != null) {
                    const errors = this.state.errors;
                    delete errors['e'];
                    this.setState({
                      errors: errors,
                    });
                  }
                }
              }}
              error={this.state.errors['e']}
              wrapperStyle={{
                border: 'none',
                width: '40%',
                alignItems: 'center',
              }}
              enterCallback={() => {}}
              viewStyle={{ fontSize: '18px' }}
              inputStyle={{ fontSize: '18px', width: '100%', minWidth: '100px' }}
            />
          </div>
        </div>
        <ButtonOk
          key={this.state.keyForLoadingSpinner}
          id="btnAddItem"
          onClick={() => {
            this.props.callback(this.state.item);
            this.setState({
              keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            });
          }}
          disabled={Object.keys(this.state.errors).length > 0}
        >
          {translate('button.create')}
        </ButtonOk>
      </StyledDivMain>
    );
  }
}
