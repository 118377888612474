import React from 'react';
import styled from 'styled-components';

import {
  IStatusState,
  Reporter,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { Table } from '../../../../components/compositcomponents/table/table';
import { RowType } from '../../../../components/compositcomponents/table/tableTypes';

import { translate } from '../../../../common/language/translate';
import { FlexBox } from '../../../auth/auth.css';
import { Box } from '../../../../components/atomiccompoents/boxes/box';
import Title from '../../../../components/compositcomponents/title';

import { api, apis, ApiError, Account } from '../../../../logic/api/index';
import { Log, Logs } from '../../../../logic/log';
import { Icons } from '../../../../images';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { evaluateErrorMessage } from '../../../../logic/helper/Common';
import { Config } from '../../../../config';
import Parser, { IParsedContent } from '../../../genericUI/parser';
import {
  KeyField,
  KeyValueBlock,
  ValueField,
} from '../customers/basicStyledComponents/customerDetails.css';
import { DisplayMenuComponent } from '../../../../components/compositcomponents/displayMenuComponent';
import { CustomDatePicker } from '../../../../components/datepicker';
import { IDynamicEntry } from '../../../../components/compositcomponents/popup/contextMenuOverlay';
import EditableTableComponent from '../../../../components/atomiccompoents/editableComponent/editableTableComponent';
import { StyledHr, StyledInput } from '../transfer/transfersComponent.css';

interface IProps {}

interface IState extends IStatusState {
  limit: number;
  offset: number;
  startDate?: Date;
  endDate?: Date;
  dataSets?: { [key: string]: any };
  actionGroups?: Array<Account.ActionGroup>;
  parsedContent?: { [key: string]: any };
  searchKeys: { [key: string]: string };
}

export default class ThirdPartyMonitoring extends React.Component<IProps, IState> {
  private firstPage: number | undefined = undefined;
  private parser: Parser = new Parser();
  constructor(props: IProps) {
    super(props);
    const startdate = new Date();
    const enddate = new Date(startdate);
    startdate.setMonth(startdate.getMonth() - 3);
    this.state = {
      limit: 1000,
      offset: 0,
      startDate: startdate,
      endDate: enddate,
      searchKeys: {},
    };

    this.createConverterForCase = this.createConverterForCase.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.performAction = this.performAction.bind(this);

    this.getThirdPartyData();
  }

  getThirdPartyData() {
    const req: Account.MonitoringThirdPartyListRequest = {
      date_from: this.state.startDate ?? new Date(),
      date_to: this.state.endDate ?? new Date(),
      qlimit: this.state.limit,
      qoffset: this.state.offset,
    };

    api
      .asyncRequest<Account.ThirdPartyData>(
        req,
        apis.MonitoringApi,
        'monitoringThirdPartyList',
      )
      .then((response: Account.ThirdPartyData) => {
        const dataSets = {};
        const parsedData = {};
        const keys = Object.keys(response);
        for (const key of keys) {
          if (key === 'action_groups') {
            continue;
          }
          const memo = response[key].action_group_memo;
          dataSets[memo] = response[key];
          dataSets[memo].key = key;
          const operations = this.getOperationIDs(memo, response.action_groups);
          const parsedOperations = {};
          for (const o of operations) {
            const path = {};
            const rb = this.parser.getRequestBodyObject(o.path, 'put');
            path[o.path] = {
              put: { operationId: o.operation_id, requestBody: rb },
            };
            parsedOperations[o.operation_id] = this.parser.getParsedContent(
              {
                label: o.operation_id,
                path: path,
                operationid: o.operation_id,
                template_group: 'mwd',
                short_info: o.descr ?? '',
              },
              false,
            );
          }
          parsedData[memo] = parsedOperations;
        }
        this.setState({
          actionGroups: response.action_groups,
          dataSets: dataSets,
          parsedContent: parsedData,
        });
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
      });
  }

  getOperationIDs(
    memo: string,
    actionGroups: Array<Account.ActionGroup>,
  ): Array<{ operation_id: string; path: string; descr?: string; idTypes?: Array<string> }> {
    const out = [];
    for (const o of actionGroups) {
      if (o.action_group_memo === memo) {
        for (const ac of o.action_group_actions) {
          out.push({
            operation_id: ac.operation_id,
            path: ac.path,
            descr: o.action_group_description,
            idTypes: ac.id_types,
          });
        }
        return out;
      }
    }
    return out;
  }

  updateThirdPartyData() {}

  getEditableFields(memo: string): Array<string> {
    const parsedData = this.state.parsedContent;
    const out = [];
    if (parsedData == null) {
      return out;
    }
    const typeData = parsedData[memo];
    for (const o of Object.keys(typeData)) {
      for (const gen of typeData[o].elements) {
        out.push(gen.key);
      }
    }
    return out;
  }

  getActions(memo: string): Array<Account.Action> {
    let out = [];
    if (this.state.actionGroups == null) {
      return out;
    }
    for (const o of this.state.actionGroups) {
      if (o.action_group_memo === memo) {
        out = o.action_group_actions;
      }
    }
    return out;
  }

  snakeCaseToTitle(text: string): string {
    text = text.replaceAll('_', ' ');
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  performAction(memo: string, operation_id: string, index: number) {
    console.log(memo, operation_id, index, this.state.parsedContent);
    if (
      this.state.parsedContent == null ||
      this.state.parsedContent[memo] == null ||
      this.state.dataSets == null ||
      this.state.dataSets[memo] == null
    ) {
      return;
    }
    const genData = this.state.parsedContent[memo];
    const actionData = this.getOperationIDs(memo, this.state.actionGroups);
    let path = '';

    const fields = genData[operation_id];
    const data = this.state.dataSets[memo].data[index];

    for (const o of actionData) {
      if (o.operation_id === operation_id) {
        const replaceVals = {};
        for (const id of o.idTypes) {
          replaceVals[id] = data[id];
        }
        path = this.replaceUrlRefs(o.path, replaceVals);
        break;
      }
    }
    const req = {};
    for (const o of fields.elements) {
      req[o.key] = data[o.key] ?? '';
    }
    const method = fields.method;
    api
      .genericRequest<any>(
        path,
        method,
        { 'Content-Type': 'application/json' },
        JSON.stringify(req),
        false,
      )
      .then((response: any) => {
        if (response.status < 200 || response.status > 300) {
          MessageHandler.onError(
            Reporter['template.posting.request.unknown.error'],
            response.statusText ?? null,
          );
        } else {
          this.getThirdPartyData();
          MessageHandler.onSuccess(Reporter['template.posting.request.error']);
        }
      })
      .catch((error: ApiError) => {
        MessageHandler.onError(
          Reporter['template.posting.request.unknown.error'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
      });
  }

  //since every table has its own contextmenu and its own editable properties, we need to create a converter per table individually according to the operationid provieded.
  createConverterForCase(
    memo: string,
  ): (line: any, index: number, fields: Array<string>) => RowType {
    const editableFields = this.getEditableFields(memo);
    const actions = this.getActions(memo);

    return (line: any, index: number, fields: Array<string>): RowType => {
      const row: RowType = { cells: [], ref: line };
      if (row.cells == null) {
        return row;
      }
      const methods: Array<IDynamicEntry> = [];
      for (const o of actions) {
        methods.push({
          icon: Icons.approve(),
          text: o.action_name,
          callback: () => {
            this.performAction(memo, o.operation_id, index);
          },
        });
      }
      //@ts-ignore
      const keys: Array<string> = Object.keys(line);
      for (let i = 0; i < fields.length; i++) {
        const field: string = fields[i];
        switch (field) {
          default: {
            if (Object.prototype.hasOwnProperty.call(line, field)) {
              row.cells.push({
                value: String(Object.values(line)[keys.indexOf(field)]),
                display: (
                  <EditableTableComponent
                    id={keys[i]}
                    changeCallback={(value: string, key?: string) => {
                      const data = this.state.dataSets;
                      if (data == null) {
                        return;
                      }
                      data[memo].data[index][key] = value;
                      this.setState({
                        dataSets: data,
                      });
                    }}
                    initText={String(Object.values(line)[keys.indexOf(field)]) ?? ' - '}
                    enterCallback={() => {}}
                    disabled={editableFields.indexOf(keys[i]) === -1}
                  />
                ),
                methods: { dynEntrys: methods },
                copyVal: String(Object.values(line)[keys.indexOf(field)]),
              });
            } else {
              row.cells.push({ value: '' });
            }
            break;
          }
        }
      }
      return row;
    };
  }

  replaceUrlRefs(url: string, replaceVals: { [key: string]: string }) {
    const keys = Object.keys(replaceVals);
    for (const o of keys) {
      url = url.replace('{' + o + '}', replaceVals[o]);
    }
    return url;
  }

  onChangeStartDate(date: Date) {
    this.setState(
      {
        startDate: new Date(date),
      },
      () => {
        this.getThirdPartyData();
      },
    );
  }

  onChangeEndDate(date: Date) {
    this.setState(
      {
        endDate: new Date(date),
      },
      () => {
        this.getThirdPartyData();
      },
    );
  }

  private resetStartDate() {
    this.setState({
      startDate: undefined,
    });
  }

  private resetEndDate() {
    this.setState({
      endDate: undefined,
    });
  }

  render() {
    return (
      <FlexBox>
        <Box>
          <div>
            <Title title={translate('administration.third_party_monitoring.header')} />
          </div>
          <StyledHr />
          <UploadBox>
            <Dates>
              <KeyValueBlock
                style={{
                  width: '30%',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <KeyField>{translate('administration.systemstate.dateFrom')}:</KeyField>
                <ValueField>
                  <DisplayMenuComponent
                    elementKey="startDate"
                    copyValue={
                      this.state.startDate != null ? this.state.startDate.toString() : ''
                    }
                    methods={{}}
                  >
                    <CustomDatePicker
                      selectedValue={this.state.startDate}
                      onChange={this.onChangeStartDate}
                      displayDate={true}
                      resetCallback={this.resetStartDate}
                      boxStyle={{
                        boxShadow: 'none',
                        borderRadius: '0px',
                        height: '24px',
                        border: 'none',
                        margin: '0px',
                        justifyContent: 'flex-start',
                      }}
                      textStyle={{
                        color: '#4a4a4a',
                        textAlign: 'left',
                        margin: '0px',
                        marginLeft: '8px',
                        marginRight: '8px',
                        fontWeight: 400,
                        fontSize: '16px',
                      }}
                      maxDate={this.state.endDate}
                      number={0}
                      toggleHeightOffset={16}
                      smallText={true}
                    />
                  </DisplayMenuComponent>
                </ValueField>
              </KeyValueBlock>
              <KeyValueBlock
                style={{
                  width: '30%',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <KeyField>{translate('administration.systemstate.dateTo')}:</KeyField>
                <ValueField>
                  <DisplayMenuComponent
                    elementKey="endDate"
                    copyValue={this.state.endDate != null ? this.state.endDate.toString() : ''}
                    methods={{}}
                  >
                    <CustomDatePicker
                      selectedValue={this.state.endDate}
                      onChange={this.onChangeEndDate}
                      displayDate={true}
                      resetCallback={this.resetEndDate}
                      boxStyle={{
                        boxShadow: 'none',
                        borderRadius: '0px',
                        height: '24px',
                        border: 'none',
                        margin: '0px',
                        justifyContent: 'flex-start',
                      }}
                      textStyle={{
                        marginRight: '0px',
                        color: '#4a4a4a',
                        textAlign: 'left',
                        margin: '0px',
                        fontWeight: 400,
                        fontSize: '16px',
                      }}
                      minDate={this.state.startDate}
                      number={1}
                      toggleHeightOffset={16}
                      smallText={true}
                    />
                  </DisplayMenuComponent>
                </ValueField>
              </KeyValueBlock>
            </Dates>
          </UploadBox>
          {this.state.dataSets == null
            ? null
            : Object.keys(this.state.dataSets).map((key: string) => {
                return (
                  <TableBox style={{ width: '100%', overflowX: 'scroll' }} key={key}>
                    <Title title={this.snakeCaseToTitle(this.state.dataSets[key].key)} />
                    <SearchBox>
                      <StyledInput
                        value={this.state.searchKeys[key] ?? ''}
                        notification={(searchObj: any) => {
                          const value = searchObj[key + 'search'].value;
                          const keys = this.state.searchKeys;
                          this.state.searchKeys[key] = value;
                          this.setState({
                            searchKeys: keys,
                          });
                        }}
                        id={key + 'search'}
                        placeHolder={translate('transactions.search.placeholder')}
                        iconRight={Icons.search()}
                        containerStyle={{
                          height: '32px',
                          marginTop: '8px',
                          width: '100%',
                          boxShadow: 'none',
                          marginRight: '16pxt',
                        }}
                        input={{ maxHeight: '32px' }}
                        inline={true}
                      />
                    </SearchBox>
                    <Table<any>
                      header={Object.keys(this.state.dataSets[key].data[0]).map(
                        (key: string) => {
                          return this.snakeCaseToTitle(key);
                        },
                      )}
                      overflow={true}
                      sourceData={this.state.dataSets[key].data}
                      fields={Object.keys(this.state.dataSets[key].data[0])}
                      externalPaging={false}
                      hidePaging={false}
                      dynamicPaging={false}
                      resize={true}
                      searchValue={this.state.searchKeys[key] ?? ''}
                      highlightCellOnSearch={true}
                      id={key}
                      stickyHeader={true}
                      rowsPerPageParam={Config.table.rowserPerPageDefault}
                      highlightTextOnSearch={true}
                      dataConverter={this.createConverterForCase(
                        this.state.dataSets[key].action_group_memo,
                      )}
                    />
                  </TableBox>
                );
              })}
        </Box>
      </FlexBox>
    );
  }
}

const TableBox = styled.div``;
const UploadBox = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 90%;
`;

const SearchBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
`;

const PagingWrapper = styled.div`
  justify-self: flex-end;
  margin: auto;
  margin-right: 12px;
  max-height: 32px;
`;

const Part = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const Dates = styled(Part)`
  width: 100%;
`;
