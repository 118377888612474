import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { OverlayHandler } from '../../logic/handler/overlayhandler/overlayHandler';

interface IState {}

interface IProps {}
export class FooterComponent extends React.Component<IProps, IState> {
  closeOverlays(event: SyntheticEvent) {
    if (event.isPropagationStopped()) {
      return;
    }

    OverlayHandler.closeOverlaysOnClick();
  }

  render() {
    return (
      <Container
        onClick={(event: SyntheticEvent) => {
          this.closeOverlays(event);
        }}
      >
        <span>@ ndBit all rights reserved</span>
        {/* <Link href="/imprint">Impressum</Link>
                <Link href="/dataProtection">Datenschutz</Link>
                <Link href="agb">AGB</Link> */}
        <StyledLink
          href="https://ndbusinessit.atlassian.net/servicedesk/customer/portal/3"
          target="_blank"
        >
          Feedback
        </StyledLink>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 46px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > * {
    margin-right: 15px;
    color: ${props => props.theme.Global.lightFontColor};
  }
`;

const StyledLink = styled.a`
  color: #000;
  font-weight: 400;
`;
