//@ts-nocheck
import React, { SyntheticEvent } from 'react';
import CustomerResultBox from './customerResultsBox';
import { StyledLine, StyledDivHeader } from '../basicStyledComponents/customersComponent.css';
import { Container as CheckBoxContainer } from '../transfer/transfersComponent.css';
import styled, { withTheme } from 'styled-components';
import { IdType, PersonFound } from '../../../../logic/api/ident';
import i18next from 'i18next';
import { Ident, api, apis, ApiError } from '../../../../logic/api';
import {
  IStatusState,
  IMessageConfig,
  Reporter,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { OverlayHandler } from '../../../../logic/handler/overlayhandler/overlayHandler';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { Log, Logs } from '../../../../logic/log';
import { Icons } from '../../../../images';
import { translate } from '../../../../common/language/translate';
import history from '../../../../common/history';
import {
  IInitProps,
  IInitState,
  InitialDataComponent,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import { InitComponentHandler } from '../../../../logic/handler/initialdatahandler/InitComponentHandler';
import { CustomerDataStore, Actions } from '../../../../logic/flux';
import { CustomDatePicker } from '../../../../components/datepicker';
import { BigInput } from '../../../../components/atomiccompoents/form/inputs.css';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import { Accounts } from '../../../../logic/accounts';
import { IItfAccount } from '../../../../logic/types';
import { DeveloperStore } from '../../../../logic/flux/stores/DeveloperStore';
import { FlexBox } from '../../../auth/auth.css';
import Title from '../../../../components/compositcomponents/title';
import { CheckBox, IOption } from '../../../../components/atomiccompoents/form';
import {
  FloatingCircle,
  Helpertext,
  NoMaxWidthSelect,
  ResetWrapper,
  StyledBox,
  StyledViewLeft,
} from './basicStyledComponents/customerDetails.css';
import {
  createDynamicLink,
  evaluateErrorMessage,
  getFullPerson,
  getPersonForAccount,
} from '../../../../logic/helper/Common';

const queryString = require('query-string');

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  & > label {
    min-width: 240px;
    width: 100%;
    margin-right: 12px;
    flex-grow: 1;
  }
`;
const InputContainer = {
  width: '100%',
};

const StyledViewMain = styled(FlexBox)`
  display: flex;
  flex-direction: row;
  min-width: 700px;

  input[disabled] {
    background: #f2f4f6;
  }
`;

const StyledViewRight = styled(FlexBox)`
  flex-grow: 0;
  width: 50%;
  margin-left: 24px;
  div {
    width: 100%;
    max-height: calc(100vh - 226px);
  }
`;

const StreetWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledSmallInput = styled(BigInput)``;

interface IProps extends IInitProps {
  notification?: (
    person: PersonFound,
    data: {
      firstname?: string;
      lastname?: string;
      email?: string;
      birthdate?: Date;
      data?: Array<Ident.PersonFound>;
    },
  ) => void;

  firstname?: string;
  lastname?: string;
  email?: string;
  birthdate?: Date;
  data?: Array<Ident.PersonFound>;
  theme?: any;
  instantID?: number;
  addCallback: () => void;
}

interface IState extends IStatusState, IInitState {
  firstname?: string;
  lastname?: string;
  email?: string;
  customernumber?: number;
  birthdate?: Date;
  address?: string;
  street?: string;
  buildingIdent?: string;
  city?: string;
  zip?: string;
  country?: string;
  accountnumber?: string;
  cardnumber?: string;
  data?: Array<Ident.PersonFound>;
  keyForLoadingSpinner?: number;
  personId?: number;
  idType?: Ident.IdType;
  clicked?: boolean;
  iban?: string;
  phone?: string;
  external?: string;
  showAddCustomer?: boolean;
  idTypes?: Array<Ident.IdType>;
  show_history?: boolean;
  limit: number;
  offset: number;
}

class CustomerSearchClass extends InitialDataComponent<IProps, IState> {
  private OkButtonStyle = {
    display: 'block',
    margin: 'auto',
    marginTop: '25px',
    width: this.props.theme.Button.width,
  };

  private parsedUri: any;

  constructor(props: IProps) {
    super(props);

    this.parsedUri = queryString.parse(window.location.search, {
      parseNumbers: true,
      parseBooleans: true,
    });

    const n: IState = {
      firstname: props.firstname == null ? this.parsedUri.firstname : props.firstname,
      lastname: props.lastname == null ? this.parsedUri.lastname : props.lastname,
      email: props.email == null ? this.parsedUri.email : props.email,
      birthdate: props.birthdate == null ? this.parsedUri.birthdate : props.birthdate,
      customernumber: this.parsedUri.customer_number,
      address: this.parsedUri.address,
      accountnumber: this.parsedUri.account_number,
      cardnumber: this.parsedUri.card_number,
      personId: this.parsedUri.person_id || DeveloperStore.getDefaultCustomer(),
      data: props.data == null ? undefined : props.data,
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
      clicked: false,
      showAddCustomer: false,
      idType: Ident.IdType.PersonId,
      show_history: false,
      limit: 20,
      offset: 0,
    };

    this.state = n;

    if (
      n.firstname != null ||
      n.lastname != null ||
      n.email != null ||
      n.birthdate != null ||
      n.customernumber != null ||
      n.address != null ||
      n.accountnumber != null ||
      n.cardnumber != null ||
      n.personId != null
    ) {
      this.fetchPersons(true);
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.addCustomer = this.addCustomer.bind(this);
    this.notification = this.notification.bind(this);
    this._onLanguageChanged = this._onLanguageChanged.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.findPersonOnCreate = this.findPersonOnCreate.bind(this);
    this.getIdtypes = this.getIdtypes.bind(this);
    this.getIdTypeOptions = this.getIdTypeOptions.bind(this);
    this.handleTypeSelect = this.handleTypeSelect.bind(this);
    InitComponentHandler.register(this, this.constructor.name);
    if (props.instantID != null) {
      this.fetchPersons(true, props.instantID);
    }
  }

  reviveState(newState: { [key: string]: any }): void {
    this.setState({
      ...newState,
      birthdate:
        'birthdate' in newState ? new Date(newState['birthdate'] as string) : undefined,
    });
  }

  _onLanguageChanged(/*lng: string*/): void {
    this.setState(this.state);
  }

  componentDidMount() {
    this.getIdtypes();
    this.fill(this.constructor.name);
    i18next.on('languageChanged', this._onLanguageChanged);
  }

  componentWillUnmount() {
    InitComponentHandler.cleanUp(this.constructor.name);
    i18next.off('languageChanged', this._onLanguageChanged);
  }

  addCustomer(event: SyntheticEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      showAddCustomer: !this.state.showAddCustomer,
    });
    //OverlayHandler.showOverlay(Overlays.addCustomer, {
    //		createCallback: this.findPersonOnCreate,
    //	});
  }

  unsetAutofillForChrome(event: any) {
    if (event.target.autocomplete) {
      event.target.autocomplete = 'whatever';
    }
  }
  onInputChange(n: any) {
    if (Object.keys(n).length === 1) {
      const key = Object.keys(n)[0] as keyof IState;
      const value = n[key].value;

      const newState: IState = {
        [key]: value,
        limit: this.state.limit,
        offset: this.state.offset,
      };
      this.setState(newState);
    }
  }

  componentWillReceiveProps(props: IProps) {
    if (props.instantID != null) {
      this.fetchPersons(true, props.instantID);
    }
  }

  onDateChange(date: Date) {
    // const netDate = new Date();
    // netDate.setFullYear(date.getFullYear());
    // netDate.setMonth(date.getMonth());
    // netDate.setDate(date.getDate());
    this.setState({
      birthdate: new Date(date),
    });
  }

  onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    this.setState({
      clicked: true,
    });
    this.fetchPersons();
  }

  findPersonOnCreate(id: number) {
    this.fetchPersons(true, id);
  }

  fetchPersons(autoSelectOnSingleResult?: boolean, personId: number | null = null) {
    let personSearchRequest: any = {
      given_name: this.state.firstname,
      name: this.state.lastname,
      email_address: this.state.email,
      search_id: this.state.personId != null ? this.state.personId.toString() : undefined,
      birth_date: this.state.birthdate,
      street: this.state.street,
      building_identifier: this.state.buildingIdent,
      zip: this.state.zip,
      city: this.state.city,
      id_type: this.state.idType,
      phone_number: this.state.phone,
      show_history: this.state.show_history,
      limit: this.state.limit,
      offset: this.state.offset,
    };
    if (personId != null) {
      personSearchRequest = {
        search_id: this.state.personId != null ? this.state.personId.toString() : undefined,
      };
    }

    if (this.state.accountnumber != null && this.state.accountnumber !== '') {
      getPersonForAccount(this.state.accountnumber).then((result: Array<number>) => {
        if (result.length !== 0) {
          if (
            personSearchRequest.search_id == null ||
            (result.indexOf(parseInt(personSearchRequest.search_id)) > -1 &&
              (personSearchRequest.id_type === IdType.PersonId ||
                personSearchRequest.id_type == null))
          ) {
            personSearchRequest.search_id = result[0].toString();
          } else {
            this.doSearchRequest(personSearchRequest, false, result);
            return;
          }
        } else {
          personSearchRequest.search_id = (-1).toString();
        }
        this.doSearchRequest(personSearchRequest, autoSelectOnSingleResult);
        return;
      });
    } else {
      this.doSearchRequest(personSearchRequest, autoSelectOnSingleResult);
    }
  }

  doSearchRequest(
    req: any,
    autoSelectOnSingleResult?: boolean,
    compareResult?: Array<number>,
  ) {
    let data: Array<Ident.PersonFound>;
    api.request(
      req,
      apis.MaintenanceApi,
      'personSearch',
      (error: ApiError, response: Array<Ident.Person>) => {
        if (response != null) {
          if (compareResult != null) {
            for (const i in response) {
              if (compareResult.indexOf(response[i].person_id) > -1) {
                data.push(response[i]);
              }
            }
          }
          if (autoSelectOnSingleResult === true && response.length === 1) {
            // Auto submit when option is set and the result contains only one entry

            OverlayHandler.updateApp();
            getFullPerson(response[0].person_id).then((person: Ident.Person) => {
              Accounts.getAccountsByPersonId(person.person_id).then(accounts => {
                let currentAccount: IItfAccount | undefined = undefined;
                if (accounts.length > 0) {
                  currentAccount = accounts[0];
                }
                Actions.setCustomerData({
                  accounts: accounts,
                  currentAccount: currentAccount,
                  selectedUser: person as Ident.Person,
                });
              });
              return;
            });
          }
          data = compareResult != null ? data : response;
        } else {
          const config: IMessageConfig = MessageHandler.onError(
            Reporter['customer.search.post.request.noparameter.error'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
          this.setState({
            showInlineError: config.errorMethods.inline,
            errorMessage: config.errorMessage != null ? config.errorMessage : error.statusText,
            key: config.translationKey != null ? config.translationKey + '.error' : '',
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          });
          Log.error(Logs.API, '\tfetchPersons: unknown error');
        }
        const newState: IState = {
          data: data,
          keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          clicked: this.state.clicked,
          offset: this.state.offset,
          limit: this.state.limit,
        };
        this.setState(newState);
      },
    );
  }

  resetDate() {
    this.setState({
      birthdate: undefined,
    });
  }
  private notification(person: Ident.Person): void {
    if (this.props.notification != null) {
      this.props.notification(person, {
        data: this.state.data,
        email: this.state.email,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        birthdate: this.state.birthdate,
      });
    }
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.onSubmit(event);
    }
  };

  resetState() {
    this.setState({
      firstname: '',
      lastname: '',
      birthdate: undefined,
      email: '',
      personId: undefined,
      street: undefined,
      buildingIdent: undefined,
      iban: undefined,
      accountnumber: undefined,
      city: undefined,
      zip: undefined,
    });
  }
  getIdtypes() {
    api
      .asyncRequest<Array<Ident.IdType>>({}, apis.MaintenanceApi, 'lookupExternalIdTypes')
      .then(response => {
        if (response.length !== 0) {
          this.setState({
            idTypes: response,
          });
        }
      })
      .catch(() => {
        this.setState({ idTypes: [Ident.IdType.PersonId] });
      });
  }

  getIdTypeOptions(): Array<IOption> {
    const options: Array<IOption> = [];
    if (this.state.idTypes != null) {
      for (const i in this.state.idTypes) {
        options.push({
          key: 'defaultOptionKey' + this.state.idTypes[i],
          name: translate(
            'customers.search.input.idTypes.' + this.state.idTypes[i].toString(),
            this.state.idTypes[i].toString(),
          ),
          value: this.state.idTypes[i],
        });
      }
    }
    return options;
  }

  handleTypeSelect(message: any) {
    this.setState({
      idType: message.id_type,
    });
  }

  render() {
    if (CustomerDataStore.getUser() != null) {
      history.push('/customers/details');
      return null;
    }
    return (
      <StyledViewMain>
        <StyledViewLeft
          style={{
            maxHeight: 'calc(100vh - 220px)',
            overflow: 'auto',
            minWidth: '530px',
          }}
        >
          <StyledBox onClick={() => OverlayHandler.closeOverlaysOnClick()}>
            <FlexBox onKeyPress={this.onKeyDown}>
              <StyledDivHeader>
                {Icons.searchCustomer()}
                <div style={{ minWidth: '200px' }}>
                  <Title title={translate('customers.search.title')} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <CheckBoxContainer
                    style={{
                      marginTop: '10px',
                      justifyContent: 'flex-end',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      minWidth: '170px',
                    }}
                  >
                    <CheckBox
                      id="histroy"
                      value={this.state.show_history}
                      onChange={(value: boolean) =>
                        this.setState({
                          show_history: !this.state.show_history,
                        })
                      }
                    />
                    <Helpertext style={{ width: '90%' }}>
                      {translate('customers.search.input.showHistory.label')}{' '}
                    </Helpertext>
                  </CheckBoxContainer>
                </div>
                <ResetWrapper onClick={() => this.resetState()} title="reset searchvalues">
                  {Icons.resetSearch()}
                </ResetWrapper>
              </StyledDivHeader>
              <StyledLine />
              <Container>
                <BigInput
                  type="firstname"
                  label={translate('customers.search.input.firstname.label')}
                  placeHolder={translate('customers.search.input.firstname.placeholder')}
                  id="firstname"
                  value={this.state.firstname}
                  notification={n => this.onInputChange(n)}
                  defaultValue={this.props.firstname}
                  valueFromState={true}
                  onSubmit={this.onSubmit}
                  containerStyle={InputContainer}
                  autoComplete={false}
                  transitionInput={true}
                  onEnter={this.unsetAutofillForChrome}
                />
                <BigInput
                  label={translate('customers.search.input.lastname.label')}
                  placeHolder={translate('customers.search.input.lastname.placeholder')}
                  type="lastname"
                  id="lastname"
                  value={this.state.lastname}
                  notification={(n: any) => this.onInputChange(n)}
                  defaultValue={this.props.lastname}
                  valueFromState={true}
                  onSubmit={this.onSubmit}
                  containerStyle={InputContainer}
                  autoComplete={false}
                  transitionInput={true}
                  onEnter={this.unsetAutofillForChrome}
                />

                <StyledSmallInput
                  type="email"
                  label={translate('customers.search.input.email.label')}
                  placeHolder={translate('customers.search.input.email.placeholder')}
                  id="email"
                  showError={false}
                  value={this.state.email}
                  notification={(n: any) => this.onInputChange(n)}
                  defaultValue={this.props.email}
                  valueFromState={true}
                  onSubmit={this.onSubmit}
                  containerStyle={InputContainer}
                  autoComplete={false}
                  transitionInput={true}
                  onEnter={this.unsetAutofillForChrome}
                />
                <NoMaxWidthSelect
                  helptext={translate('customers.search.input.personIdType.placeholder')}
                  id="id_type"
                  options={this.getIdTypeOptions()}
                  notification={this.handleTypeSelect}
                />
                <BigInput
                  label={translate('customers.search.input.searchID.label')}
                  placeHolder={translate('customers.search.input.searchID.placeholder')}
                  id="personId"
                  valueFromState={true}
                  value={
                    this.state.personId == null ? undefined : this.state.personId.toString()
                  }
                  notification={(n: any) => this.onInputChange(n)}
                  onSubmit={this.onSubmit}
                  containerStyle={InputContainer}
                  autoComplete={false}
                  transitionInput={true}
                  onEnter={this.unsetAutofillForChrome}
                />
                <BigInput
                  label={translate('customers.search.input.phone.label')}
                  placeHolder={translate('customers.search.input.phone.placeholder')}
                  id="phone"
                  valueFromState={true}
                  value={this.state.phone == null ? undefined : this.state.phone}
                  notification={(n: any) => this.onInputChange(n)}
                  onSubmit={this.onSubmit}
                  containerStyle={InputContainer}
                  autoComplete={false}
                  onEnter={this.unsetAutofillForChrome}
                  transitionInput={true}
                />
                <BigInput
                  label={translate('backoffice.search.input.account_number.label')}
                  placeHolder={translate('backoffice.search.input.account_number.placeholder')}
                  id="accountnumber"
                  valueFromState={true}
                  value={
                    this.state.accountnumber == null ? undefined : this.state.accountnumber
                  }
                  notification={(n: any) => this.onInputChange(n)}
                  onSubmit={this.onSubmit}
                  containerStyle={InputContainer}
                  autoComplete={false}
                  onEnter={this.unsetAutofillForChrome}
                  transitionInput={true}
                />
                <BigInput
                  label={translate('backoffice.search.input.iban.label')}
                  placeHolder={translate('backoffice.search.input.iban.placeholder')}
                  id="iban"
                  valueFromState={true}
                  value={this.state.iban == null ? undefined : this.state.iban}
                  notification={(n: any) => this.onInputChange(n)}
                  onSubmit={this.onSubmit}
                  containerStyle={InputContainer}
                  autoComplete={false}
                  transitionInput={true}
                  onEnter={this.unsetAutofillForChrome}
                />
                <CustomDatePicker
                  title={translate('customers.search.input.birthdate.placeholder')}
                  selectedValue={this.state.birthdate}
                  onChange={this.onDateChange}
                  iconCallback={Icons.calendarExpand}
                  displayDate={this.state.birthdate != null}
                  resetCallback={this.resetDate}
                  isInput={true}
                  helperText={translate('customers.search.input.birthdate.label')}
                />
                <StreetWrapper>
                  <div style={{ width: '70%' }}>
                    <BigInput
                      label={translate('customers.search.input.street.label')}
                      placeHolder={translate('customers.search.input.street.placeholder')}
                      id="street"
                      valueFromState={true}
                      value={this.state.street == null ? undefined : this.state.street}
                      notification={(n: any) => this.onInputChange(n)}
                      onSubmit={this.onSubmit}
                      containerStyle={InputContainer}
                      autoComplete={false}
                      transitionInput={true}
                      input={{ minWidth: '1px' }}
                      onEnter={this.unsetAutofillForChrome}
                    />
                  </div>
                  <div style={{ width: '16px' }} />
                  <div style={{ width: '30%' }}>
                    <BigInput
                      label={translate('customers.newCustomer.input.number.label')}
                      placeHolder={translate('customers.newCustomer.input.number.placeholder')}
                      id="buildingIdent"
                      valueFromState={true}
                      value={
                        this.state.buildingIdent == null
                          ? undefined
                          : this.state.buildingIdent.toString()
                      }
                      notification={(n: any) => this.onInputChange(n)}
                      onSubmit={this.onSubmit}
                      containerStyle={InputContainer}
                      autoComplete={false}
                      transitionInput={true}
                      input={{ minWidth: '1px' }}
                      onEnter={this.unsetAutofillForChrome}
                    />
                  </div>
                </StreetWrapper>
                <StreetWrapper>
                  <div style={{ width: '70%' }}>
                    <BigInput
                      label={translate('customers.search.input.city.label')}
                      placeHolder={translate('customers.search.input.city.placeholder')}
                      id="city"
                      valueFromState={true}
                      value={this.state.city == null ? undefined : this.state.city}
                      notification={(n: any) => this.onInputChange(n)}
                      onSubmit={this.onSubmit}
                      containerStyle={InputContainer}
                      autoComplete={false}
                      transitionInput={true}
                      input={{ minWidth: '1px' }}
                      onEnter={this.unsetAutofillForChrome}
                    />
                  </div>
                  <div style={{ width: '16px' }} />
                  <div style={{ width: '30%' }}>
                    <BigInput
                      label={translate('customers.search.input.zip.label')}
                      placeHolder={translate('customers.search.input.zip.placeholder')}
                      id="zip"
                      valueFromState={true}
                      value={this.state.zip == null ? undefined : this.state.zip}
                      notification={(n: any) => this.onInputChange(n)}
                      onSubmit={this.onSubmit}
                      containerStyle={InputContainer}
                      autoComplete={false}
                      transitionInput={true}
                      input={{ minWidth: '1px' }}
                      onEnter={this.unsetAutofillForChrome}
                    />
                  </div>
                </StreetWrapper>
              </Container>
              <div style={{ height: '52px' }} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'inline-block',
                    position: 'fixed',
                    bottom: '90px',
                  }}
                >
                  <ButtonOk
                    key={this.state.keyForLoadingSpinner}
                    onClick={this.onSubmit}
                    id="btnSearchCustomer"
                    style={this.OkButtonStyle}
                    clicked={this.state.clicked}
                  >
                    {translate('button.search')}
                  </ButtonOk>
                </div>
              </div>
            </FlexBox>
          </StyledBox>
          <div
            style={{
              display: 'inline-block',
              position: 'fixed',
              bottom: '90px',
              right: '64px',
            }}
          >
            <FloatingCircle
              onClick={() => {
                this.props.addCallback();
              }}
              id="btnAddCustomer"
            >
              +
            </FloatingCircle>
          </div>
        </StyledViewLeft>
        <StyledViewRight>
          <CustomerResultBox
            notification={this.notification}
            data={this.state.data != null ? this.state.data : []}
            title={translate('customers.results')}
            nextCallback={
              this.state.data != null && this.state.data.length >= this.state.limit
                ? () => {
                    this.setState(
                      {
                        offset:
                          this.state.data != null && this.state.data.length >= this.state.limit
                            ? this.state.offset + this.state.limit
                            : this.state.offset,
                      },
                      () => {
                        this.fetchPersons();
                      },
                    );
                  }
                : undefined
            }
            prevCallback={
              this.state.data != null && this.state.offset > 0
                ? () => {
                    this.setState(
                      {
                        offset:
                          this.state.offset > 0 ? this.state.offset - this.state.limit : 0,
                      },
                      () => {
                        this.fetchPersons();
                      },
                    );
                  }
                : undefined
            }
          />
        </StyledViewRight>
      </StyledViewMain>
    );
  }
}

export const CustomerSearch = withTheme(CustomerSearchClass);
