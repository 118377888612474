import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';
import { Ident } from '../../api/index';
import { IItfAccount } from '../../types';
import { UndoStore } from './undoStore';

interface IPickerDates {
  startDate: Date;
  endDate: Date;
}
class ICustomerDataStore extends EventEmitter {
  user?: Ident.Person = undefined;
  currentAccount?: IItfAccount = undefined;
  accounts: Array<IItfAccount> = [];
  dates: IPickerDates | null = null;
  private static instance: ICustomerDataStore | null;
  constructor() {
    super();
    IDispatcher.register(this.registerActions.bind(this));
  }

  static getInstance(): ICustomerDataStore {
    if (ICustomerDataStore.instance == null) {
      ICustomerDataStore.instance = new ICustomerDataStore();
    }
    return ICustomerDataStore.instance;
  }

  registerActions(action: any) {
    switch (action.actionType) {
      default:
        break;
      case Constants.CLEAR_ALL:
        this.accounts = [];
        this.user = undefined;
        this.currentAccount = undefined;
        this.emit(CHANGE_EVENT);
        break;
      case Constants.CUSTOMER_CHANGED:
        this.setUser(action.user);
        this.emit(CHANGE_EVENT);
        break;
      case Constants.CURRENT_ACCOUNT_CHANGED:
        this.setCurrentAccount(action.account);
        this.emit(CHANGE_EVENT);
        break;
      case Constants.CUSTOMER_ACCOUNTS_CHANGED:
        this.setAccounts(action.accounts);
        this.emit(CHANGE_EVENT);
        break;
      case Constants.RESET_CUSTOMER_DATA:
        this.resetAll();
        this.emit(CHANGE_EVENT);
        break;
      case Constants.CUSTOMER_DATA_CHANGED:
        const data: {
          accounts: Array<IItfAccount>;
          currentAccount?: IItfAccount;
          selectedUser?: Ident.Person;
        } = action.data;

        this.setAccounts(data.accounts);
        this.setCurrentAccount(data.currentAccount);
        this.setUser(data.selectedUser);
        this.emit(CHANGE_EVENT);
        break;
    }
    return true;
  }

  setUser(user: Ident.Person | undefined) {
    this.user = user;
    UndoStore.setLastUser(user);
  }

  setAccounts(accounts: Array<IItfAccount>): void {
    this.accounts = accounts;
    UndoStore.setLastAccounts(accounts);
  }

  getAccounts(): Array<IItfAccount> {
    return this.accounts;
  }

  setCurrentAccount(account?: IItfAccount) {
    this.currentAccount = account;
    UndoStore.setLastCurrentAccount(account);
  }

  getCurrentAccount() {
    return this.currentAccount;
  }

  resetUser() {
    this.user = undefined;
  }

  getUser() {
    return this.user;
  }

  resetAll() {
    this.user = undefined;
    this.currentAccount = undefined;
    this.accounts = [];
    this.dates = null;
  }
  addChangeListener(callback: any) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback: any) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  setDates(dates: IPickerDates) {
    this.dates = dates;
  }
  getDates() {
    return this.dates;
  }
}

export const CustomerDataStore = ICustomerDataStore.getInstance();
