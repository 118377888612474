import React, { ReactElement, SyntheticEvent } from 'react';
import { Cell } from './cell';
import styled from 'styled-components';
import { RowType, ICellType, IContextMenuType } from './tableTypes';
import { CheckBox } from '../../atomiccompoents/form';
import { IPagingStore } from '../../../logic/flux/stores/pagingStore';
import { IContextmenuMethods } from '../displayMenuComponent';

const NoWrapContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface IProps {
  data?: RowType;
  id?: string;
  highlightCellOnSearch?: boolean;
  highlightTextOnSearch?: boolean;
  onSort?: (columnIndex: number, sort: 'ASC' | 'DESC' | 'none') => void;
  windowWidth?: number;
  windowHeight?: number;
  minWidthForColumns?: Array<number>;
  searchValue?: string;
  isHeader?: boolean;
  rowIndex: number;
  multiSelect?: boolean;
  onChangeCheckbox?: (value: boolean, ref: any | undefined) => void;
  isSelected?: boolean;
  headers?: Array<string>;
  resize?: boolean;
}

interface IState {
  searchValue?: string;
  multiSelect?: boolean;
  selected?: boolean;
  render: boolean;
}

export class Row extends React.Component<IProps, IState> {
  private RowView = styled.tr`
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    background: rgba(0, 0, 0, 0);
  `;
  private rowRef = React.createRef<HTMLTableRowElement>();
  private blocked = IPagingStore.getBlocked();
  constructor(props: IProps) {
    super(props);

    if (props.isHeader === true) {
      this.RowView = styled(this.RowView).attrs({
        className: 'row' + props.rowIndex + ' col',
      })`
        box-shadow: ${props => props.theme.Table.THead.boxShadow};
        border-radius: ${props => props.theme.Table.THead.borderRadius};
        box-sizing: ${props => props.theme.Table.THead.boxSizing};
        height: ${props => props.theme.Table.THead.height};
        align-items: ${props => props.theme.Table.THead.alignItems};
        margin: ${props => props.theme.Table.THead.margin};
        text-transform: ${props => props.theme.Table.THead.textTransform};
        border-bottom: 1px solid ${props => props.theme.ButtonDisabled.backgroundColor};
        &:hover {
          background: rgba(220, 220, 220, 0.2);
        }
      `;
    } else {
      this.RowView = styled(this.RowView).attrs({
        className: 'row' + props.rowIndex + ' col',
      })`
        box-shadow: ${props => props.theme.Table.TBody.boxShadow};
        border-radius: ${props => props.theme.Table.TBody.borderRadius};
        box-sizing: ${props => props.theme.Table.TBody.boxSizing};
        height: ${props => props.theme.Table.TBody.height};
        align-items: ${props => props.theme.Table.TBody.alignItems};
        text-transform: ${props => props.theme.Table.TBody.textTransform};
        &:hover {
          background: rgba(220, 220, 220, 0.2);
        }
      `;
    }

    this.state = {
      searchValue: undefined,
      multiSelect: props.multiSelect,
      selected: props.isSelected === true,
      render: !this.blocked,
    };

    this.onSort = this.onSort.bind(this);
  }

  static getDerivedStateFromProps(props: IProps, state: IState): IState | null {
    if (props.searchValue !== state.searchValue || props.multiSelect !== state.multiSelect) {
      return {
        searchValue: props.searchValue,
        multiSelect: props.multiSelect,
        render: state.render,
      };
    }

    return null;
  }

  checkRender(maxHeight?: number): number {
    if (this.rowRef != null && this.rowRef.current != null) {
      const bBox = this.rowRef.current.getBoundingClientRect();
      const top = bBox.top + bBox.height;
      const heightLeft = maxHeight != null ? maxHeight : window.innerHeight - 40 - top;
      const maxElements = Math.floor(heightLeft / bBox.height);
      return maxElements > 0 ? maxElements : 1;
    }
    return 0;
  }

  private onCheckBoxChanged(value: boolean, ref: any | undefined) {
    this.setState(
      {
        selected: value,
      },
      () => {
        if (this.props.onChangeCheckbox != null) {
          this.props.onChangeCheckbox(this.state.selected === true, ref);
        }
      },
    );
  }

  private onSort(columnIndex: number, sort: 'ASC' | 'DESC' | 'none'): void {
    if (this.state.multiSelect === true && columnIndex === 0) {
      return;
    }
    if (this.props.onSort != null) {
      this.props.onSort(columnIndex, sort);
    }
  }

  get selected(): boolean {
    return this.state.selected || false;
  }

  set selected(selected: boolean) {
    this.setState({
      selected: selected,
    });
  }

  toggleSelected() {
    this.setState({
      selected: this.state.selected !== true,
    });
  }

  private renderRow(data?: RowType): Array<ReactElement | null> | null {
    if (data == null || data.cells == null) {
      return null;
    }
    const arr: Array<ReactElement | string | number | IContextMenuType> = [];
    for (let i = -1; i < data.cells.length; i++) {
      if (i === -1) {
        if (this.state.multiSelect === true) {
          arr.push(
            <NoWrapContainer onClick={(event: SyntheticEvent) => event.stopPropagation()}>
              <CheckBox
                id={'row_checkbox_' + i}
                value={this.state.selected === true}
                onChange={(value: boolean) => this.onCheckBoxChanged(value, data.ref)}
              />
            </NoWrapContainer>,
          );
        }
        continue;
      }
      const d: ICellType = data.cells[i];
      if (d == null) {
        arr.push('');
      } else if (d.methods != null) {
        arr.push({
          element: d.display != null ? d.display : d.value,
          methods: d.methods,
          copyVal: d.copyVal,
        });
      } else if (d.display != null) {
        arr.push(d.display);
      } else {
        arr.push(d.value);
      }
    }
    return arr.map(
      (cell: ReactElement | string | number | IContextMenuType, index: number) => {
        if (
          this.props.minWidthForColumns != null &&
          this.props.windowWidth != null &&
          this.props.minWidthForColumns.length > index &&
          this.props.windowWidth < this.props.minWidthForColumns[index]
        ) {
          return null;
        } else {
          let val: IContextMenuType | undefined = undefined;
          if (typeof cell === 'object' && !React.isValidElement(cell)) {
            val = cell as IContextMenuType;
          }
          //val = undefined;
          return (
            <Cell
              columnIndex={index}
              key={'cell_' + index}
              data={val != undefined ? val.element : cell}
              highlightCellOnSearch={this.props.highlightCellOnSearch}
              highlightTextOnSearch={this.props.highlightTextOnSearch}
              searchValue={this.state.searchValue}
              onSort={this.props.onSort == null ? undefined : this.onSort}
              isHeader={this.props.isHeader}
              rowIndex={this.props.rowIndex}
              colIndex={index}
              id={this.props.id}
              resize={this.props.resize}
              methods={
                //@ts-ignore
                val != null ? val.methods : undefined
              }
              copyValue={
                //@ts-ignore
                val != null ? val.copyVal : undefined
              }
            />
          );
        }
      },
    );
  }

  render() {
    if (
      this.props.data == null ||
      this.props.data.cells == null ||
      this.props.data.cells.length === 0 ||
      this.state.render === false
    ) {
      return null;
    }

    return <this.RowView ref={this.rowRef}>{this.renderRow(this.props.data)}</this.RowView>;
  }
}
