import styled from 'styled-components';
import { Box } from '../../../../../components/atomiccompoents/boxes/box';
import { FlexBox } from '../../../../auth/auth.css';

export const StyledViewWrapper = styled(FlexBox)`
  display: flex;
  flex-direction: row;

  input[disabled] {
    background: #f2f4f6;
  }
`;

export const StyledViewSearchBox = styled(FlexBox)`
  display: block;
  flex-grow: 1;
  max-width: 50%;
`;

export const StyledViewResultBox = styled(FlexBox)`
  flex-grow: 0;
  width: 50%;
  div {
    width: 100%;
    max-height: calc(100vh - 226px);
  }
`;

export const StyledBox = styled(Box)`
  margin-bottom: 4px;
  margin-right: 24px;
  box-shadow: 4px 0px 4px -4px rgba(0, 0, 0, 0.25);
`;

export const StyledViewHeader = styled(FlexBox)`
  display: flex;
  height: 29px;
`;

export const StyledHeaderText = styled.div`
  margin: auto;
  margin-left: 36px;
  margin-top: -30px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${props => props.theme.Global.lightFontColor};
`;
export const StyledView = styled(FlexBox)`
  width: 320px;
  padding: 16px;
  background-color: ${props => props.theme.Box.background};
  border: ${props => props.theme.Box.border};
  box-shadow: ${props => props.theme.Box.boxShadow};
  border-radius: ${props => props.theme.Box.borderRadius};
  max-height: calc(100vh - 146px);
  overflow-y: auto;
`;

export const StyledTextHeading = styled(FlexBox)`
  font-weight: 500;
  text-transform: uppercase;
  color: ${props => props.theme.Global.lightFontColor};
  margin-left: 8px;
`;

export const StyledLine = styled(FlexBox)`
  border-bottom: 1px solid #f2f4f6;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

export const StyledOpacityEntry = styled(FlexBox)`
  display: flex;
  flex-direction: row;

  & > div > svg,
  img {
    border-radius: 50%;
  }
`;

export const StyledViewEntryText = styled(FlexBox)`
  padding-left: 12px;
  width: 75%;

  & > div:nth-child(1) {
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: ${props => props.theme.Global.darkFontColor};
    overflow-wrap: no-wrap;
  }

  & > div:nth-child(2) {
    font-weight: normal;
    line-height: 21px;
    color: #98a9bc;
    overflow-wrap: no-wrap;
  }
`;

export const StyledUserIcon = styled.div`
  && {
    width: 64px;
  }
`;
