import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';
import { Ident, apis } from '../../api/index';
import { IItfAccount } from '../../types';
import { Actions } from '../actions';
import { CustomerDataStore } from '..';
import { Ref, ReactElement } from 'react';
import { deepCopy } from '../../helper/Common';

interface IHistory {
  data: Array<any>;
  index: number;
}

enum UndoEvents {
  UrlChangEvent,
  AccountChangeEvent,
  CustomerChangEvent,
  ApiCallEvent,
  FillFormEvent,
  TabChangEvent,
}

interface IUndoStackEntry {
  event: UndoEvents;
  state?: any;
  component?: React.Component;
}
interface IURLCHangeStackEntry extends IUndoStackEntry {
  lastUrl: string;
}

interface IAPICallStackEntry extends IUndoStackEntry {
  data: any;
  api: apis;
  personId?: number;
  request: string;
}

class IUndoStore extends EventEmitter {
  header: string = '';
  lastUser?: Ident.Person = undefined;
  lastAccount?: IItfAccount;
  lastAccounts?: IItfAccount[];

  private undoStack: Array<IUndoStackEntry> = [];
  private stackindex: number = -1;

  handleUndo() {
    if (this.stackindex > 0) {
      const currentEvent = this.undoStack[this.stackindex - 1];
      switch (currentEvent.event) {
        case UndoEvents.FillFormEvent: {
          this.handleResetForm(currentEvent);
          break;
        }
        case UndoEvents.UrlChangEvent: {
          const ev = currentEvent as IURLCHangeStackEntry;
          window.location.pathname = ev.lastUrl;
          this.stackindex--;
          this.undoStack.pop();
          break;
        }
        default:
          return;
      }
    }
  }
  handleResetForm(currentEvent: IUndoStackEntry) {
    if (currentEvent.component != null) {
      currentEvent.component.setState(currentEvent.state);
    }
    this.undoStack.pop();
    this.stackindex--;
  }

  addToUndoStack(event: IUndoStackEntry) {
    const pushEvent: IUndoStackEntry = { event: event.event, component: event.component };
    if (event.state != null) {
      pushEvent.state = deepCopy(event.state);
    }
    this.undoStack.push(pushEvent);
    this.stackindex++;
  }

  lastBackofficeAccount?: IItfAccount = undefined;
  personDetailsHistory: IHistory = {
    data: [],
    index: -1,
  };
  personPreferencesHistory: IHistory = {
    data: [],
    index: -1,
  };
  private static instance: IUndoStore | null;

  constructor() {
    super();

    IDispatcher.register(this.registerActions.bind(this));
  }

  static getInstance(): IUndoStore {
    if (IUndoStore.instance == null) {
      IUndoStore.instance = new IUndoStore();
    }
    return IUndoStore.instance;
  }

  registerActions(action: any) {
    switch (action.actionType) {
      default:
        break;
      case Constants.UNDO_CUSTOMER:
        this.emit(CHANGE_EVENT);
        break;
    }
  }

  setLastUser(user: Ident.Person | undefined) {
    if (user != null && this.lastUser != null && user.person_id !== this.lastUser.person_id) {
      this.personDetailsHistory = { data: [], index: -1 };
    }
    this.lastUser = user;
  }

  setLastAccounts(accounts: IItfAccount[]) {
    this.lastAccounts = accounts;
  }

  setLastCurrentAccount(account: IItfAccount | undefined) {
    this.lastAccount = account;
  }

  getUser(): Ident.Person | undefined {
    if (this.lastUser == null) {
      const userString: string | null = window.sessionStorage.getItem('user');
      if (userString != null && userString !== '') {
        this.lastUser = JSON.parse(userString);
      }
    }
    return this.lastUser;
  }

  getAccount(): IItfAccount | undefined {
    return this.lastAccount;
  }
  getAccounts(): Array<IItfAccount> | undefined {
    return this.lastAccounts;
  }

  undo(): void {
    if (this.lastUser != null && this.lastAccounts != null) {
      Actions.setCustomerData({
        accounts: this.lastAccounts,
        currentAccount: this.lastAccount,
        selectedUser: this.lastUser,
      });
    } else {
      if (this.lastUser != null) {
        Actions.customerChanged(this.lastUser);
      }
      CustomerDataStore.setCurrentAccount(this.lastAccount);
      if (this.lastAccount != null) {
        Actions.currentAccountChanged(this.lastAccount);
      }
      if (this.lastAccounts != null) {
        CustomerDataStore.setAccounts(this.lastAccounts);
        Actions.setCustomerAccounts(this.lastAccounts);
      }
    }
  }

  addDetailsState(state: any) {
    this.personDetailsHistory.data.push(state);
    this.personDetailsHistory.index++;
  }

  addpreferencesState(state: any) {
    this.personPreferencesHistory.data.push(JSON.parse(JSON.stringify(state)));
    this.personPreferencesHistory.index++;
  }

  getLastPreferencesState() {
    if (this.personPreferencesHistory.data.length > 0) {
      const returnvalue = this.personPreferencesHistory.data[
        this.personPreferencesHistory.data.length - 1
      ];
      this.personPreferencesHistory.index =
        this.personPreferencesHistory.index === -1
          ? -1
          : this.personPreferencesHistory.index - 1;
      return returnvalue;
    }
  }

  getLastDetailsState() {
    if (this.personDetailsHistory.data.length > 0) {
      const returnvalue = this.personDetailsHistory.data[
        this.personDetailsHistory.data.length - 1
      ];
      this.personDetailsHistory.index =
        this.personDetailsHistory.index === -1 ? -1 : this.personDetailsHistory.index - 1;
      return returnvalue;
    }
  }

  removeChangeListener(callback: any) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  setLastBackofficeAccount(account: IItfAccount) {
    this.lastBackofficeAccount = account;
  }

  undoBackofficeAccount() {
    Actions.backofficeChanged(this.lastBackofficeAccount);
  }
}

export const UndoStore = IUndoStore.getInstance();
