//@ts-nocheck
import React from 'react';
import styled from 'styled-components';
import {
  IRelativeProps,
  IRelativeState,
  RelativeOverlay,
} from '../../../logic/handler/overlayhandler/globaloverlays/aRelativeOverlay';
import { translate } from '../../../common/language/translate';
import { Icons } from '../../../images';

export interface IDynamicEntry {
  icon?: JSX.Element;
  text: string;
  callback: () => void;
}

interface IProps extends IRelativeProps {
  copyCallback: () => void;
  pasteCallback?: () => void;
  undoCallback?: () => void;
  redoCallback?: () => void;
  editCallback?: () => void;
  selfClose: () => void;
  postingJournalCallback?: () => void;
  transactionDetailsCallback: () => void;
  revertTransactionCallback: () => void;
  statementCallback?: () => void;
  editAddressCallback?: () => void;
  approvalCallback?: () => void;
  cancelCallback?: () => void;
  emailCallback?: () => void;
  phoneCallback?: () => void;
  customersCallback?: () => void;
  accountCallback?: () => void;
  limitCallback?: () => void;
  stornoProductCallback?: () => void;
  invoiceCallback?: () => void;
  startQueuecallback: () => void;
  stornoCallback: () => void;
  dynEntrys?: Array<IDynamicEntry>;
}

interface IState extends IRelativeState {}

export class ContextMenuOverlay extends RelativeOverlay<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  reviveState() {}

  defineContent() {
    return (
      <MainView top={this.props.posY} left={this.props.posX}>
        <MenuEntry
          onClick={(event: any) => {
            event.preventDefault();
            event.stopPropagation();
            this.props.copyCallback();
            this.props.selfClose();
          }}
        >
          <IconWrapper style={{ marginRight: '4px' }}>{Icons.copy()} </IconWrapper>
          {translate('contextMenu.copy')}
        </MenuEntry>
        {this.props.pasteCallback != null ? (
          <MenuEntry
            onClick={() => {
              if (this.props.pasteCallback != null) {
                this.props.pasteCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>{Icons.paste()} </IconWrapper>
            {translate('contextMenu.paste')}
          </MenuEntry>
        ) : null}
        {this.props.editCallback != null ? (
          <MenuEntry
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              event.preventDefault();
              if (this.props.editCallback != null) {
                this.props.editCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>{Icons.editEntry()} </IconWrapper>
            {translate('contextMenu.edit')}
          </MenuEntry>
        ) : null}
        {this.props.undoCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.undoCallback != null) {
                this.props.undoCallback();
              }
              this.props.selfClose();
            }}
          >
            Undo
          </MenuEntry>
        ) : null}
        {this.props.redoCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.redoCallback != null) {
                this.props.redoCallback();
              }
              this.props.selfClose();
            }}
          >
            Redo
          </MenuEntry>
        ) : null}
        {this.props.postingJournalCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.postingJournalCallback != null) {
                this.props.postingJournalCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>{Icons.detailOverlay()} </IconWrapper>
            {translate('contextMenu.postingJournal')}
          </MenuEntry>
        ) : null}
        {this.props.transactionDetailsCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.transactionDetailsCallback != null) {
                this.props.transactionDetailsCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>{Icons.detailOverlay()} </IconWrapper>
            {translate('contextMenu.transactionDetails')}
          </MenuEntry>
        ) : null}
        {this.props.revertTransactionCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.revertTransactionCallback != null) {
                this.props.revertTransactionCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>{Icons.reverse()} </IconWrapper>
            {this.props.postingJournalCallback != null
              ? translate('contextMenu.revert')
              : translate('contextMenu.cancel')}
          </MenuEntry>
        ) : null}
        {this.props.statementCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.statementCallback != null) {
                this.props.statementCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>{Icons.detailOverlay()} </IconWrapper>
            {translate('contextMenu.moneyStatement')}
          </MenuEntry>
        ) : null}
        {this.props.editAddressCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.editAddressCallback != null) {
                this.props.editAddressCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>{Icons.addressEdit()} </IconWrapper>
            {translate('contextMenu.manageAddress')}
          </MenuEntry>
        ) : null}
        {this.props.approvalCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.approvalCallback != null) {
                this.props.approvalCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>{Icons.approve()} </IconWrapper>
            {translate('backoffice.approvals.approve')}
          </MenuEntry>
        ) : null}
        {this.props.cancelCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.cancelCallback != null) {
                this.props.cancelCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>{Icons.cancel()} </IconWrapper>
            {translate('backoffice.approvals.cancel')}
          </MenuEntry>
        ) : null}
        {this.props.emailCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.emailCallback != null) {
                this.props.emailCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>{Icons.message()} </IconWrapper>
            {translate('contextMenu.manageEmail')}
          </MenuEntry>
        ) : null}
        {this.props.phoneCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.phoneCallback != null) {
                this.props.phoneCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>{Icons.fixedPhone()} </IconWrapper>
            {translate('contextMenu.managePhone')}
          </MenuEntry>
        ) : null}
        {this.props.customersCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.customersCallback != null) {
                this.props.customersCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>{Icons.searchCustomer()} </IconWrapper>
            {translate('contextMenu.openCustomer')}
          </MenuEntry>
        ) : null}
        {this.props.accountCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.accountCallback != null) {
                this.props.accountCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>
              {Icons.searchBackoffice()}{' '}
            </IconWrapper>
            {translate('contextMenu.openAccount')}
          </MenuEntry>
        ) : null}
        {this.props.limitCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.limitCallback != null) {
                this.props.limitCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>{Icons.profile()} </IconWrapper>
            {translate('contextMenu.openLimits')}
          </MenuEntry>
        ) : null}
        {this.props.stornoProductCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.stornoProductCallback != null) {
                this.props.stornoProductCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>{Icons.reverse()} </IconWrapper>
            {translate('contextMenu.refund')}
          </MenuEntry>
        ) : null}
        {this.props.invoiceCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.invoiceCallback != null) {
                this.props.invoiceCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>{Icons.attachment()} </IconWrapper>
            {translate('contextMenu.invoice')}
          </MenuEntry>
        ) : null}
        {this.props.stornoCallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.stornoCallback != null) {
                this.props.stornoCallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>{Icons.attachment()} </IconWrapper>
            {translate('contextMenu.storno')}
          </MenuEntry>
        ) : null}
        {this.props.startQueuecallback != null ? (
          <MenuEntry
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (this.props.startQueuecallback != null) {
                this.props.startQueuecallback();
              }
              this.props.selfClose();
            }}
          >
            <IconWrapper style={{ marginRight: '4px' }}>
              {Icons.arrowInCircleRight()}{' '}
            </IconWrapper>
            {translate('contextMenu.startQueue')}
          </MenuEntry>
        ) : null}
        {this.props.dynEntrys != null
          ? this.props.dynEntrys.map((value: IDynamicEntry) => {
              return (
                <MenuEntry
                  onClick={(event: any) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (value.callback != null) {
                      value.callback();
                    }
                    this.props.selfClose();
                  }}
                >
                  <IconWrapper style={{ marginRight: '4px' }}>{value.icon} </IconWrapper>
                  {value.text}
                </MenuEntry>
              );
            })
          : null}
      </MainView>
    );
  }
}

const MainView = styled.div<{ top: string; left: string }>`
  z-index: 10000;
  position: absolute;
  box-sizing: border-box;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0);
  padding: 2px;
  font-size: 10px;
  min-width: 160px;
`;

const MenuEntry = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 30px;
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  background-color: #ffffff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid ${props => props.theme.Button.backgroundColor};
  height: 30px;
  margin: 2px;
  svg {
    fill: ${props => props.theme.Global.lightFontColor};
    width: 16px;
    height: 16px;
  }
  :hover {
    background-color: rgb(189, 190, 192);
    cursor: pointer;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
