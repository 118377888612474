//@ts-nocheck
import React from 'react';
import styled from 'styled-components';
import {
  ACenteredOverlay,
  IPropsCenter,
  IStateCenter,
} from '../../../logic/handler/overlayhandler/globaloverlays/aCenteredOverlay';
import { Icons } from '../../../images';
import TouchableOpacity from '../../atomiccompoents/buttons/touchableOpacity';
import { FlexBox } from '../../../content/auth/auth.css';
import { translate } from '../../../common/language/translate';

const StyledViewMain = styled(FlexBox)`
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: white;

  font-family: Roboto, 'Helvetica Neue', sans-serif;
  padding: 16px;
  width: 450px;
  height: 250px;
  box-sizing: border-box;
  border-radius: 6px;
`;

const StyledLine = styled.hr`
  border: 1px solid #f2f4f6;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const StyledTextError = styled.span`
  border-radius: 4px;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  text-align: center;
  box-shadow: ${props => props.theme.Box.boxShadow};
  color: #d0021b;
  margin: 5px 40px;
  font-size: 22px;
  padding: 16px 0;
`;

const StyledTextFirstLine = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.Global.darkFontColor};
  text-align: center;
  padding: 2px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledOpacityIcon: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  padding: '8px',
};

interface IProps extends IPropsCenter {
  error?: string;
}

interface IState extends IStateCenter {}

export class ErrorMessageOverlay extends ACenteredOverlay<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.close = this.close.bind(this);
  }

  close() {
    this.props.selfClose();
  }

  reviveState(): void {}

  defineContent() {
    return (
      <StyledViewMain>
        <TouchableOpacity
          onClick={() => {
            this.close();
          }}
          containerStyle={StyledOpacityIcon}
        >
          {Icons.closingCross()}
        </TouchableOpacity>
        <StyledTextError>{translate('customers.error.title', 'Error')}</StyledTextError>
        <StyledLine />
        <StyledTextFirstLine title={this.props.error}>{this.props.error}</StyledTextFirstLine>
        <StyledLine />
      </StyledViewMain>
    );
  }
}
