import React from 'react';
import styled from 'styled-components';
import { IItfAccount } from '../logic/types';
import { Ident } from '../logic/api';
import { format } from '../logic/helper/format';

import {
  INotifyAbleCustomerData,
  INotifyAbleBackofficeData,
  BackofficeStore,
  CustomerDataStore,
  Actions,
} from '../logic/flux';
import { IOption } from './atomiccompoents/form';
import { SmallStyledSelect } from '../content/dashboard/content/customers/basicStyledComponents/customerDetails.css';
import { translate } from '../common/language/translate';

interface IProps {}

interface IState {
  currentAccount?: IItfAccount;
  accounts?: Array<IItfAccount>;
  selectedUser?: Ident.Person;
}

export default class AccountSelector extends React.Component<IProps, IState>
  implements INotifyAbleCustomerData, INotifyAbleBackofficeData {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
    this._onChangeBackofficeData = this._onChangeBackofficeData.bind(this);
    this.setAccount = this.setAccount.bind(this);
  }

  _onChangeBackofficeData() {
    if (this.isBackofficeScreen()) {
      const d = BackofficeStore.getAccount();
      this.setState({
        currentAccount: d,
        accounts: [],
        selectedUser: undefined,
      });
    } else {
    }
  }

  _onChangeCustomerData() {
    if (this.isCustomerScreen()) {
      this.setState({
        currentAccount: CustomerDataStore.getCurrentAccount(),
        accounts: CustomerDataStore.getAccounts(),
        selectedUser: CustomerDataStore.getUser(),
      });
    } else {
    }
  }

  componentDidMount() {
    CustomerDataStore.addChangeListener(this._onChangeCustomerData);
    this._onChangeCustomerData();
    BackofficeStore.addChangeListener(this._onChangeBackofficeData);
    this._onChangeBackofficeData();
  }

  componentWillUnmount() {
    CustomerDataStore.removeChangeListener(this._onChangeCustomerData);
    BackofficeStore.removeChangeListener(this._onChangeBackofficeData);
  }

  private isCustomerScreen(): boolean {
    return window.location.pathname.includes('customers');
  }

  private isBackofficeScreen(): boolean {
    return window.location.pathname.includes('backoffice');
  }

  setAccount(message: any) {
    if (this.state.accounts != null) {
      for (const o of this.state.accounts) {
        if (o.account_number === message.accountSelect) {
          this.setState(
            {
              currentAccount: o,
            },
            () => {
              Actions.setCustomerCurrentAccount(o);
            },
          );
          break;
        }
      }
    }
  }

  render() {
    const accountOptions: Array<IOption> = [];
    if (this.state.accounts != null && this.state.accounts.length > 0) {
      for (const i in this.state.accounts) {
        const accText =
          this.state.accounts[i].account_number +
          '\t (' +
          translate('customers.currentBalance') +
          ': ' +
          //@ts-ignore
          format.number(
            this.state.accounts[i].balance != null ? this.state.accounts[i].balance : 0,
            2,
          ) +
          ' ' +
          this.state.accounts[i].currency +
          '\t , ' +
          translate('customers.lastTransaction') +
          ': ' +
          format.date(this.state.accounts[i].last_transaction) +
          '\t , ' +
          translate('customers.availableBalance') +
          ': ' +
          format.number(this.state.accounts[i].total_balance, 2) +
          ' ' +
          this.state.accounts[i].currency +
          ' ) ';
        accountOptions.push({
          name: accText,
          value: this.state.accounts[i].account_number,
          key: this.state.accounts[i].account_number,
        });
      }
    } else if (this.state.currentAccount != null) {
      const accText =
        this.state.currentAccount.account_number +
        '\t (current Balance: ' +
        this.state.currentAccount.balance +
        ' ' +
        this.state.currentAccount.currency +
        '\t , last Transaction: ' +
        format.date(this.state.currentAccount.last_transaction) +
        ')';
      accountOptions.push({
        name: accText,
        value: this.state.currentAccount.account_number,
        key: this.state.currentAccount.account_number,
      });
    }
    return (
      <AccountSelect
        options={accountOptions}
        id="accountSelect"
        helptext="select your account"
        current={
          this.state.currentAccount != null ? this.state.currentAccount.account_number : ''
        }
        notification={this.setAccount}
      />
    );
  }
}

const AccountSelect = styled(SmallStyledSelect)`
  max-width: 100%;
  select {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.25);
    height: 42px;
  }
`;
