import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { translate } from '../../../common/language/translate';
import Tabs, { ITabvalues } from '../../../components/atomiccompoents/tabs/tabs';
import ProductConfigComponent from './customers/productConfigComponent';
import ProductMaintenanceComponent from './customers/productMaintenanceComponent';

interface IProps extends RouteComponentProps {}

export default class ProductManagementComponent extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
    this.redirect = this.redirect.bind(this);
  }

  redirect(path: string) {
    this.props.history.push(path);
  }

  render() {
    const tabValues: Array<ITabvalues> = [
      {
        name: 'categorys',
        title: translate('productMaintenance.title'),
        path: '/productmanagement/categorys',
        callback: props => {
          return <ProductMaintenanceComponent />;
        },
      },
      {
        name: 'config',
        title: translate('productMaintenance.productConfig'),
        path: '/productmanagement/config',
        callback: props => {
          return <ProductConfigComponent />;
        },
      },
    ];

    return (
      <Tabs
        activeTab="Categorys"
        tabList={tabValues}
        redicrect="/productmanagement/categorys"
        basePath="/productmanagement/"
        minwidthNeeded={false}
      />
    );
  }
}
