import React from 'react';
import GenericTemplateComponent, {
  GenericTemplateType,
  TemplateCategory,
} from '../../../genericUI/genericTemplateComponent';
import AccountSelector from '../../../../components/accountSelector';
import styled from 'styled-components';
import { IItfAccount } from '../../../../logic/types';
import { Log, Logs } from '../../../../logic/log';
import { BackofficeStore } from '../../../../logic/flux';
import {
  OverlayHandler,
  Overlays,
} from '../../../../logic/handler/overlayhandler/overlayHandler';
import { FlexBox } from '../../../auth/auth.css';
import { Account, api } from '../../../../logic/api';
import { translate } from '../../../../common/language/translate';
import { FileType } from '../../../../components/compositcomponents/popup/fileTypeChooserOverlay';
import { downloadFile } from '../../../../logic/helper/Common';
import { format } from '../../../../logic/helper/format';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../logic/handler/messagehandler/messageHandlerConfig';

interface IProps {}

interface IState {
  showReport: boolean;
  responseData?: any;
  selectedAccount?: IItfAccount;
  selectedPostingTemplate?: Account.TransactionTemplate;
}
type ReportAcceptTypes =
  | 'application/pdf'
  | 'text/comma-separated-values'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export default class BackofficeReportsComponent extends React.Component<IProps, IState> {
  private ref = React.createRef<GenericTemplateComponent>();
  constructor(props: IProps) {
    super(props);

    this.state = {
      showReport: false,
      selectedAccount: BackofficeStore.getAccount(),
    };

    this.showReportPage = this.showReportPage.bind(this);
    this._onChangeBackofficeData = this._onChangeBackofficeData.bind(this);
    this.reset = this.reset.bind(this);
  }

  private getAcceptHeader(report: string, filetype: FileType): ReportAcceptTypes {
    if (filetype === FileType.csv) return 'text/comma-separated-values';
    else if (filetype === FileType.pdf) return 'application/pdf';
    else if (filetype === FileType.docx)
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    else return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }
  componentDidMount(): void {
    BackofficeStore.addChangeListener(this._onChangeBackofficeData);
    this._onChangeBackofficeData();
  }

  componentWillUnmount(): void {
    BackofficeStore.removeChangeListener(this._onChangeBackofficeData);
  }

  _onChangeBackofficeData(): void {
    this.setState(
      {
        selectedAccount: BackofficeStore.getAccount(),
      },
      () => {
        Log.debug(Logs.COMPONENT, 'BackofficeComponent -> state changed');
        Log.debug(Logs.COMPONENT, this.state.selectedAccount);
      },
    );
  }

  showReportPage(
    showReport: boolean,
    response: any,
    selectedPostingTemplate: Account.TransactionTemplate,
  ) {
    if (showReport === true) {
      this.setState({
        showReport: showReport,
        responseData: response,
        selectedPostingTemplate: selectedPostingTemplate,
      });
    }
  }

  reset() {
    this.setState({
      showReport: false,
    });
  }

  render() {
    return (
      <FlexBox>
        <Wrapper>{this.state.selectedAccount != null ? <AccountSelector /> : null}</Wrapper>

        <Wrapper onClick={() => OverlayHandler.closeOverlaysOnClick()}>
          <GenericTemplateComponent
            templateType={GenericTemplateType.report_template}
            templateCategory={TemplateCategory.backoffice}
            heading={translate('generic.report')}
            subHeading={translate('generic.chooseReport')}
            notification={this.showReportPage}
            isReport={true}
            isBackOffice={true}
            resetCallback={this.reset}
            ref={this.ref}
          />
        </Wrapper>
      </FlexBox>
    );
  }
}

const Wrapper = styled(FlexBox)`
  margin-bottom: 24px;
`;
