import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';
import { Account } from '../../api';
import { compareDatetime } from '../../helper/Common';

class INotificationStore extends EventEmitter {
  private static instance: INotificationStore | null;

  private notifications: Array<Account.NotificationLine> = [];

  constructor() {
    super();
    IDispatcher.register(this.registerActions.bind(this));
  }
  static getInstance(): INotificationStore {
    if (INotificationStore.instance == null) {
      INotificationStore.instance = new INotificationStore();
    }

    return INotificationStore.instance;
  }

  registerActions(action: any) {
    switch (action.actionType) {
      default:
        break;
      case Constants.CLEAR_ALL:
        this.notifications = [];
        this.emit(CHANGE_EVENT);
        break;
      case Constants.ADD_NOTIFICATION:
        this.addNotification(action.notification);
        this.emit(CHANGE_EVENT);
        break;
      case Constants.DELETE_NOTIFICATION:
        this.deleteNotification(action.notification);
        this.emit(CHANGE_EVENT);
        break;
      case Constants.READ_NOTIFICATION:
        this.markRead(action.notification);
        this.emit(CHANGE_EVENT);
        break;
      case Constants.UNREAD_NOTIFICATION:
        this.markUnread(action.notification);
        this.emit(CHANGE_EVENT);
        break;
      case Constants.SET_NOTIFICATION:
        this.setNotifications(action.notificationList);
        this.emit(CHANGE_EVENT);
        break;
    }
    return true;
  }

  addNotification(notification?: Account.NotificationLine): void {
    if (notification == null) {
      return;
    }

    if (this.notifications.find(n => n.id === notification.id)) {
      return;
    }

    const notifications = this.notifications;
    notifications.push(notification);
    this.notifications = notifications.sort((a, b) =>
      compareDatetime(a.datetime_create, b.datetime_create),
    );
  }

  deleteNotification(notification?: Account.NotificationLine): void {
    if (notification == null) {
      return;
    }

    const index = this.notifications.findIndex(n => n.id === notification.id);
    if (index < 0) {
      return;
    }

    this.notifications.splice(index, 1);
  }

  markRead(notification?: Account.NotificationLine): void {
    if (notification == null) {
      return;
    }

    const index = this.notifications.findIndex(n => n.id === notification.id);
    if (index < 0) {
      return;
    }

    this.notifications[index].read = true;
  }

  markUnread(notification?: Account.NotificationLine): void {
    if (notification == null) {
      return;
    }

    const index = this.notifications.findIndex(n => n.id === notification.id);
    if (index < 0) {
      return;
    }

    this.notifications[index].read = false;
  }

  getNotifications(): Array<Account.NotificationLine> {
    return this.notifications;
  }

  setNotifications(notificationList?: Account.NotificationList): void {
    if (notificationList == null) {
      return;
    }

    this.notifications = notificationList.notification_lines;
  }

  addChangeListener(callback: any) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback: any) {
    this.removeListener(CHANGE_EVENT, callback);
  }
}

export const NotificationStore = INotificationStore.getInstance();
