import { Ident } from '../api';
import i18next from 'i18next';
import { Log, Logs } from '../log';

//TODO: Use intl object to receive language from
export class Format {
  private static instance: Format | null = null;

  static getInstance() {
    if (Format.instance == null) {
      Format.instance = new Format();
    }

    return Format.instance;
  }

  currency(amount?: number, currency: string = 'EUR'): string {
    if (amount == null) {
      return '';
    }
    return new Intl.NumberFormat(i18next.language, {
      style: 'currency',
      currency: currency,
    }).format(amount);
  }

  /**
   * Formats a date based on language settings made by setLanguage()
   * @param date The date object to parse
   * @param long Enable long format (default false), e.g. November 5th 1999
   */
  date(date?: Date, long: boolean = false): string | undefined {
    if (date == null) {
      return undefined;
    }

    if (Object.prototype.toString.call(date) !== '[object Date]') {
      date = new Date(date);
    }

    // Detecting invalid dates
    if (isNaN(date.getTime())) {
      const e: Error = new Error('Invalid date detected');
      Log.error(Logs.SYSTEM, e);
      return undefined;
    }
    const options: Intl.DateTimeFormatOptions = {};
    if (long === true) {
      options.day = 'numeric';
      options.month = 'long';
      options.year = 'numeric';
    }

    return new Intl.DateTimeFormat(i18next.language, options).format(date);
  }

  /**
   * Formats a date based on language settings made by setLanguage()
   * @param date The date object to parse
   * @param long Enable long format (default false), e.g. November 5th 1999
   */
  // Do not removed parameter long, maybe useful in future
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  datetime(date?: Date, long: boolean = false): string | undefined {
    if (date == null) {
      return undefined;
    }

    if (Object.prototype.toString.call(date) !== '[object Date]') {
      date = new Date(date);
    }

    // Detecting invalid dates
    if (isNaN(date.getTime())) {
      const e: Error = new Error('Invalid date detected');
      Log.error(Logs.SYSTEM, e);
      return undefined;
    }

    return date.toLocaleString(i18next.language);
  }

  nameByParts(
    given_name: string | undefined,
    name: string | undefined,
    email: string | undefined,
    format: string = '%l, %g',
  ): string {
    if (given_name != null && given_name.trim() !== '' && name != null && name.trim() !== '') {
      return format.replace('%g', given_name).replace('%l', name);
    } else if (email != null) {
      return email;
    } else {
      return '';
    }
  }

  name(person: Ident.Person | undefined, format: string = '%l, %g'): string {
    if (person == null) {
      return '';
    }
    return this.nameByParts(
      person.given_name,
      person.name,
      person.primary_email_address,
      format,
    );
  }

  number(amount: number, digits: number = 2) {
    return new Intl.NumberFormat(i18next.language, {
      style: 'decimal',
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    }).format(amount);
  }
  numberInt(amount: number) {
    return new Intl.NumberFormat(i18next.language, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  }

  /**
   * Formats a date based on language settings made by setLanguage()
   * @param date The date object to parse
   * @param long Enable long format (default false), e.g. November 5th 1999
   */
  // Do not removed parameter long, maybe useful in future
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  time(date: Date, long: boolean = false): string {
    return date.toLocaleTimeString(i18next.language);
  }

  /**
   * used for the file names when downloading transactions and reports
   */
  getDateString(): string {
    const date = new Date();
    let dateString = '';
    dateString += date.getFullYear();
    if (date.getMonth() + 1 < 10) {
      dateString += '0';
    }
    dateString += date.getMonth() + 1;
    if (date.getDate() < 10) {
      dateString += '0';
    }
    dateString += date.getDate();
    if (date.getHours() < 10) {
      dateString += '0';
    }
    dateString += date.getHours();
    if (date.getMinutes() < 10) {
      dateString += '0';
    }
    dateString += date.getMinutes();

    return dateString;
  }
}

export const format = Format.getInstance();
