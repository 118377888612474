// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import { IbanCreateUpdate, ThirdPartyData } from '../models/index';

export interface MonitoringThirdPartyListRequest {
  date_from: Date;
  date_to: Date;
  qlimit: number;
  qoffset: number;
}

export interface MoorwandIbanInboundMarkAsDoneRequest {
  moorwand_iban_inbound_id: number;
}

export interface MoorwandIbanMarkAsDoneRequest {
  moorwand_create_iban_requests_id: number;
}

export interface MoorwandOutboundMarkAsDoneRequest {
  moorwand_account_to_iban_transfer_data_id: number;
}

export interface MoorwandResendIbanRequestRequest {
  moorwand_create_iban_requests_id: number;
  IbanCreateUpdate?: IbanCreateUpdate;
}

export interface MoorwandRetryBookingRequest {
  moorwand_iban_inbound_id: number;
}

export interface MoorwandRetryTransferRequest {
  ta_id: number;
  transaction_id: number;
}

export interface MoorwandReturnTransferRequest {
  moorwand_iban_inbound_id: number;
}

/**
 * no description
 */
export class MonitoringApi extends BaseAPI implements IOperationIdMap {
  /**
   * list errors in thrid-party-dservices
   */
  monitoringThirdPartyList = (
    requestParameters: MonitoringThirdPartyListRequest,
  ): Observable<ThirdPartyData> => {
    throwIfRequired(requestParameters, 'date_from', 'monitoringThirdPartyList');
    throwIfRequired(requestParameters, 'date_to', 'monitoringThirdPartyList');
    throwIfRequired(requestParameters, 'qlimit', 'monitoringThirdPartyList');
    throwIfRequired(requestParameters, 'qoffset', 'monitoringThirdPartyList');

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.date_from && {
        date_from: (requestParameters.date_from as any).toISOString().substring(0, 10),
      }),
      ...(requestParameters.date_to && {
        date_to: (requestParameters.date_to as any).toISOString().substring(0, 10),
      }),
      ...(requestParameters.qlimit && { qlimit: requestParameters.qlimit }),
      ...(requestParameters.qoffset && { qoffset: requestParameters.qoffset }),
    };

    return this.request<ThirdPartyData>({
      path: '/monitoring/third_party_list',
      method: 'GET',
      headers,
      query,
    });
  };

  /**
   * set case to done
   */
  moorwandIbanInboundMarkAsDone = (
    requestParameters: MoorwandIbanInboundMarkAsDoneRequest,
  ): Observable<void> => {
    throwIfRequired(
      requestParameters,
      'moorwand_iban_inbound_id',
      'moorwandIbanInboundMarkAsDone',
    );

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<void>({
      path: '/monitoring/moorwand/iban_inbound_mark_as_done/{moorwand_iban_inbound_id}'.replace(
        '{moorwand_iban_inbound_id}',
        encodeURI(requestParameters.moorwand_iban_inbound_id),
      ),
      method: 'PUT',
      headers,
    });
  };

  /**
   * set case to done
   */
  moorwandIbanMarkAsDone = (
    requestParameters: MoorwandIbanMarkAsDoneRequest,
  ): Observable<void> => {
    throwIfRequired(
      requestParameters,
      'moorwand_create_iban_requests_id',
      'moorwandIbanMarkAsDone',
    );

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<void>({
      path: '/monitoring/moorwand/iban_mark_as_done/{moorwand_create_iban_requests_id}'.replace(
        '{moorwand_create_iban_requests_id}',
        encodeURI(requestParameters.moorwand_create_iban_requests_id),
      ),
      method: 'PUT',
      headers,
    });
  };

  /**
   * set case to done
   */
  moorwandOutboundMarkAsDone = (
    requestParameters: MoorwandOutboundMarkAsDoneRequest,
  ): Observable<void> => {
    throwIfRequired(
      requestParameters,
      'moorwand_account_to_iban_transfer_data_id',
      'moorwandOutboundMarkAsDone',
    );

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<void>({
      path: '/monitoring/moorwand/outbound_mark_as_done/{moorwand_account_to_iban_transfer_data_id}'.replace(
        '{moorwand_account_to_iban_transfer_data_id}',
        encodeURI(requestParameters.moorwand_account_to_iban_transfer_data_id),
      ),
      method: 'PUT',
      headers,
    });
  };

  /**
   * resend iban creation request to mwd
   */
  moorwandResendIbanRequest = (
    requestParameters: MoorwandResendIbanRequestRequest,
  ): Observable<void> => {
    throwIfRequired(
      requestParameters,
      'moorwand_create_iban_requests_id',
      'moorwandResendIbanRequest',
    );

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<void>({
      path: '/monitoring/moorwand/resend_iban/{moorwand_create_iban_requests_id}'.replace(
        '{moorwand_create_iban_requests_id}',
        encodeURI(requestParameters.moorwand_create_iban_requests_id),
      ),
      method: 'PUT',
      headers,
      body: requestParameters.IbanCreateUpdate,
    });
  };

  /**
   * retry booking of incomming sepa to customer
   */
  moorwandRetryBooking = (
    requestParameters: MoorwandRetryBookingRequest,
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'moorwand_iban_inbound_id', 'moorwandRetryBooking');

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<void>({
      path: '/monitoring/moorwand/retry_booking/{moorwand_iban_inbound_id}'.replace(
        '{moorwand_iban_inbound_id}',
        encodeURI(requestParameters.moorwand_iban_inbound_id),
      ),
      method: 'PUT',
      headers,
    });
  };

  /**
   * retry outbound transfer to mwd
   */
  moorwandRetryTransfer = (
    requestParameters: MoorwandRetryTransferRequest,
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'ta_id', 'moorwandRetryTransfer');
    throwIfRequired(requestParameters, 'transaction_id', 'moorwandRetryTransfer');

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<void>({
      path: '/monitoring/moorwand/retry_transfer/{ta_id}/{transaction_id}'
        .replace('{ta_id}', encodeURI(requestParameters.ta_id))
        .replace('{transaction_id}', encodeURI(requestParameters.transaction_id)),
      method: 'PUT',
      headers,
    });
  };

  /**
   * return inbound transfer to mwd
   */
  moorwandReturnTransfer = (
    requestParameters: MoorwandReturnTransferRequest,
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'moorwand_iban_inbound_id', 'moorwandReturnTransfer');

    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<void>({
      path: '/monitoring/moorwand/return_transfer/{moorwand_iban_inbound_id}'.replace(
        '{moorwand_iban_inbound_id}',
        encodeURI(requestParameters.moorwand_iban_inbound_id),
      ),
      method: 'PUT',
      headers,
    });
  };

  operationToOperationId = {
    monitoringThirdPartyList: 'monitoring_third_party_list',
    moorwandIbanInboundMarkAsDone: 'moorwand_iban_inbound_mark_as_done',
    moorwandIbanMarkAsDone: 'moorwand_iban_mark_as_done',
    moorwandOutboundMarkAsDone: 'moorwand_outbound_mark_as_done',
    moorwandResendIbanRequest: 'moorwand_resend_iban_request',
    moorwandRetryBooking: 'moorwand_retry_booking',
    moorwandRetryTransfer: 'moorwand_retry_transfer',
    moorwandReturnTransfer: 'moorwand_return_transfer',
  };
}
