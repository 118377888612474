import { request } from 'http';
import React, {
  FunctionComponent,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import KeyTextComponent from '../../../../../components/atomiccompoents/keyTextComponent';
import {
  DataTypeEnum,
  IRequestData,
} from '../../../../../components/compositcomponents/popup/requestDataOverlay';
import { api, apis, ApiError, Ident, Account } from '../../../../../logic/api';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import {
  OverlayHandler,
  Overlays,
} from '../../../../../logic/handler/overlayhandler/overlayHandler';
import { evaluateErrorMessage } from '../../../../../logic/helper/Common';
import { Log, Logs } from '../../../../../logic/log';

interface IProps {
  person: Ident.Person;
}

export const FiskalyComponent: FunctionComponent<IProps> = (props: IProps) => {
  const [data, setData] = useState<Account.FiskalyOrgaData>();
  const [keyForLoadingSpinner, setkeyForLoadingSpinner] = useState<number>(
    Math.floor(Math.random() * 10000000),
  );

  function createFiskaly(data: { [key: string]: IRequestData }) {
    const req: Account.MerchantPersonIdCreateFiskalyPostRequest = {
      person_id: props.person.person_id,
      FiskalyData: {
        economy_id: data.econ.value,
        tax_number: data.taxNr.value,
        vat_id: data.vat.value,
      },
    };
    api
      .asyncRequest<Array<Account.PaymentMethod>>(
        req,
        apis.MerchantApi,
        'merchantPersonIdCreateFiskalyPost',
      )
      .then(response => {
        MessageHandler.onSuccess(Reporter['fiskaly.create.post']);
        getFiskaly();
        setkeyForLoadingSpinner(Math.floor(Math.random() * 10000000));
      })
      .catch((error: ApiError) => {
        MessageHandler.onError(
          Reporter['fiskaly.create.post'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
        Log.debug(Logs.API, error);
        setkeyForLoadingSpinner(Math.floor(Math.random() * 10000000));
      });
  }

  function getFiskaly() {
    const req: Account.MerchantPersonIdFiskalyDataGetRequest = {
      person_id: props.person.person_id,
    };
    api
      .asyncRequest<Account.FiskalyOrgaData>(
        req,
        apis.MerchantApi,
        'merchantPersonIdFiskalyDataGet',
      )
      .then(response => {
        if (response.orga_id != null) {
          setData(response);
        }
      })
      .catch((error: ApiError) => {
        Log.debug(Logs.API, error);
      });
  }

  function requestFiskalyData() {
    const data: { [key: string]: IRequestData } = {};
    data.vat = {
      translationkeyLA: 'customers.details.merchant.fiskaly.vat.label',
      translationkeyPL: 'customers.details.merchant.fiskaly.vat.placeHolder',
      value: '',
      type: DataTypeEnum.Input,
    };
    data.econ = {
      translationkeyLA: 'customers.details.merchant.fiskaly.econ.label',
      translationkeyPL: 'customers.details.merchant.fiskaly.econ.placeHolder',
      value: '',
      type: DataTypeEnum.Input,
    };
    data.taxNr = {
      translationkeyLA: 'customers.details.merchant.fiskaly.taxNr.label',
      translationkeyPL: 'customers.details.merchant.fiskaly.taxNr.placeHolder',
      value: '',
      type: DataTypeEnum.Input,
    };

    OverlayHandler.showOverlay(Overlays.requestDataOverlay, {
      data: data,
      title: 'customers.details.merchant.fiskaly.title',
      callback: createFiskaly,
      buttonKey: 'button.ok',
      height: '500px',
    });
  }

  useEffect(() => {
    getFiskaly();
  }, []);

  return (
    <React.Fragment>
      <Wrapper>
        {data == null ? (
          <ButtonOk
            style={{ margin: 'auto' }}
            key={keyForLoadingSpinner}
            onClick={(event: SyntheticEvent) => {
              event.stopPropagation();
              event.preventDefault();
              requestFiskalyData();
            }}
            disabled={props.person.is_merchant !== true}
          >
            {' '}
            {'Start Fiskaly'}
          </ButtonOk>
        ) : (
          <React.Fragment>
            <KeyTextComponent
              translationkey="customers.details.merchant.fiskaly.orgaId"
              value={data.orga_id}
            />
            <KeyTextComponent
              translationkey="customers.details.merchant.fiskaly.tseID"
              value={data.tss_id}
            />
            <KeyTextComponent
              translationkey="customers.details.merchant.fiskaly.apiKey"
              value={data.api_key}
            />
          </React.Fragment>
        )}
      </Wrapper>
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  justify-content: center;
`;
