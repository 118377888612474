import React, { ReactNode } from 'react';

export interface IDateState {
  value?: Date;
}

export interface IDateProps {
  onChange: (value: Date) => void;
  selectedValue?: Date;
  dateFormat?: string;
}

export abstract class ADatePicker<
  P extends IDateProps,
  S extends IDateState
> extends React.Component<P, S> {
  constructor(props: P) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(param: Date): void {
    this.setState({
      value: param,
    });
    this.props.onChange(param);
  }

  abstract render(): ReactNode;
}
