//@ts-nocheck
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Row } from './row';
import { translate } from '../../../common/language/translate';
import { format } from '../../../logic/helper/format';
import { PagingComponent } from '../../paging';
import { RowType, ICellType } from './tableTypes';
import { Actions } from '../../../logic/flux';
import { Config } from '../../../config';
import { mergeArrayUnique } from '../../../logic/helper/Common';
import { toUnicode } from 'punycode';

interface IProps<T> {
  dataConverter?: (line: T, index: number, fields: Array<string>) => RowType;
  sourceData: Array<T>;
  id?: string;
  header?: Array<string>;
  fields?: Array<string>;
  highlightCellOnSearch?: boolean;
  highlightTextOnSearch?: boolean;
  searchFields?: Array<string>;
  minimumColumnWidth?: Array<number>;
  searchValue?: string;
  stickyHeader?: boolean;
  minWidthForColumns?: Array<number>;
  onSort?: () => void;
  onFilter?: () => void;
  hidePaging?: boolean;
  multiSelect?: boolean; /// Enables multiselect checkboxes in header and per line, default false
  toggleOnSelectAll?: boolean; /// If set to true the current selection is inverted when clicking the header checkbox, default false
  maxHeight?: number;
  externalPaging?: boolean;
  sortDirection?: 'ASC' | 'DESC' | 'none';
  sortColumn?: number;
  dynamicPaging?: boolean;
  stableHeader?: boolean;
  updateTransactionCallback?: (data: any) => void;
  resize?: boolean;
  firstOfPage?: number;
  rowsPerPageParam?: number;
  overflow?: boolean;
}

interface IState<T> {
  combinedData?: Array<RowType>;
  header?: Array<string>;
  minWidthForColumns?: Array<number>;
  searchFields?: Array<string>;
  searchValue?: string;
  sortColumn?: number;
  sortDirection?: 'ASC' | 'DESC' | 'none';
  sourceData?: Array<T>;
  windowHeight?: number;
  windowWidth?: number;
  indexFirstDisplayedElement: number;
  indexLastDisplayedElement?: number;
  multiSelect?: boolean; /// Enables multiselect checkboxes in header and per line, default false
  toggleOnSelectAll?: boolean; /// If set to true the current selection is inverted when clicking the header checkbox, default false
  selectedRows?: Map<number, T>;
  offset?: number;
}

export class Table<T> extends React.Component<IProps<T>, IState<T>> {
  //private checkBoxRefs: Array<CheckBox | null> = [];
  private rowRefs: Array<Row | null> = [];
  private rowsPerPage: number;
  private scrollViewRef: HTMLDivElement | null = null;
  private showPaging: boolean;
  private firstRowRef = React.createRef<Row>();
  private rowRefSet = false;
  constructor(props: IProps<T>) {
    super(props);

    let columnCount: number = -1;
    if (props.fields != null || props.header != null) {
      columnCount = Math.max(
        columnCount,
        Math.max(
          props.fields == null ? 0 : props.fields.length,
          props.header == null ? 0 : props.header.length,
        ),
      );
    } else if (props.sourceData != null && props.sourceData.length > 0) {
      columnCount = Math.max(columnCount, Object.keys(props.sourceData[0]).length);
    }

    if (this.props.rowsPerPageParam === null) {
      this.rowsPerPage = Config.table.rowserPerPageDefault;
    } else {
      this.rowsPerPage = this.props.rowsPerPageParam;
    }
    this.state = {
      header: props.header,
      minWidthForColumns: props.minWidthForColumns == null ? [] : props.minWidthForColumns,
      combinedData:
        props.sourceData != null && props.sourceData.map != null
          ? props.sourceData.map((el: T, index: number) =>
              Table.converter(props, index, el, props.fields, columnCount),
            )
          : [],
      indexFirstDisplayedElement: 0,
      indexLastDisplayedElement: this.rowsPerPage,
      multiSelect: props.multiSelect,
      toggleOnSelectAll: props.toggleOnSelectAll,
      selectedRows: new Map(),
      sortColumn: this.props.sortColumn != null ? this.props.sortColumn : undefined,
      sortDirection: this.props.sortDirection != null ? this.props.sortDirection : undefined,
    };

    this.resize = this.resize.bind(this);
    this.updatePaging = this.updatePaging.bind(this);

    if (this.props.hidePaging === true) {
      this.showPaging = false;
    } else {
      this.showPaging = true;
    }
    if (this.props.externalPaging === true) {
      Actions.setNotification(this.updatePaging);
    }
  }

  componentDidMount() {
    this.resize();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentDidUpdate() {
    if (
      this.firstRowRef.current != null &&
      this.rowRefSet === false &&
      this.props.hidePaging !== true
    ) {
      this.rowRefSet = true;
      this.setState(this.state);
    }
  }

  static converter(
    props: IProps<any>,
    index: number,
    line?: any,
    fields?: Array<string>,
    columnCount: number = 0,
  ): RowType {
    if (line == null) {
      return {};
    }
    let result: RowType = { cells: [] };
    let idx = 0;
    if (props.dataConverter == null) {
      Object.entries(line).forEach(([key, value]: [string, any]) => {
        const index: number = fields == null ? -2 : fields.indexOf(key);
        if (index === -1) {
          return null;
        }

        const cell: ICellType = {
          value: '',
        };
        if (Array.isArray(value)) {
          //TODO:
        } else if (typeof value === 'object') {
          if (value instanceof Date) {
            const formatted: string | undefined = format.date(value);
            cell.value = formatted == null ? '' : formatted;
          } else {
            if (result.cells != null) {
              const converted = Table.converter(props, index, value, fields, columnCount);
              result.cells.concat(converted.cells == null ? [] : converted.cells);
            }
          }
        } else {
          while (fields != null && fields[idx] != key && result.cells != null) {
            result.cells.push({ value: '' });
            ++idx;
            if (idx >= fields.length) {
              break;
            }
          }
          cell.value = value;
          cell.key = key;
        }

        if (index < 0) {
          if (result.cells != null) {
            result.cells.push(cell);
          }
        } else {
          if (result.cells != null) {
            result.cells[index] = cell;
          }
        }
      });
    } else {
      const fields: Array<string> = props.fields == null ? Object.keys(line) : props.fields;
      result = props.dataConverter(line, index, fields);
    }

    if (result.cells != null) {
      while (result.cells.length < columnCount) {
        result.cells.push({ value: '' });
      }
    }

    result.ref = line;

    return result;
  }

  extractHeader(data?: Array<RowType>, external: boolean = false): RowType | undefined {
    let columnCount: number = -1;
    if (this.props.fields != null) {
      columnCount = Math.max(columnCount, this.props.fields.length);
    } else if (this.props.sourceData != null && this.props.sourceData.length > 0) {
      columnCount = Math.max(columnCount, Object.keys(this.props.sourceData[0]).length);
    }

    if (this.state.header != null && external === false) {
      const header: Array<ICellType> = this.state.header.map((el: string) => {
        return {
          value: el,
        };
      });

      while (header.length < columnCount) {
        header.push({ value: '' });
      }
      return { cells: header };
    }
    if (data == null || data.length === 0) {
      return undefined;
    }

    const d: RowType = data[0];

    const keys: Array<string> = Object.keys(d.ref);
    if (this.props.fields != null) {
      const filteredKeys: Array<ICellType> = keys
        .filter((key: string) => {
          return this.props.fields == null
            ? true || this.props.stableHeader === true
            : this.props.fields.indexOf(key) >= 0;
        })
        .map((el: string) => {
          return {
            value: el,
          };
        });

      while (filteredKeys.length < columnCount) {
        filteredKeys.push({ value: '' });
      }
      return { cells: filteredKeys };
    }

    const header: Array<ICellType> = keys.map((el: string) => {
      return {
        value: el,
      };
    });
    while (header.length < columnCount) {
      header.push({ value: '' });
    }
    return {
      cells: header,
    };
  }

  private filterData(data: Array<RowType>): Array<RowType> {
    if (data.length === 0) {
      return data;
    }

    const filtered = data.filter((entry: RowType) => {
      if (entry.ref == null) {
        return false;
      }
      if (this.state.searchValue == null || this.state.searchValue === '') {
        return true;
      }

      if (entry.cells == null) {
        return false;
      }

      const found =
        Object.values(entry.cells)
          .map((value: ICellType) => {
            const found: boolean =
              value.value
                .toString()
                .toLowerCase()
                .indexOf(
                  this.state.searchValue == null ? '' : this.state.searchValue.toLowerCase(),
                ) >= 0;

            return found;
          })
          .indexOf(true) >= 0;

      if (found === true) {
        return true;
      }

      return (
        Object.entries(entry.ref)
          .map(([key, value]: [string, any]): boolean => {
            const searchFields: Array<string> = mergeArrayUnique(
              this.props.fields,
              this.state.searchFields,
            );

            if (searchFields.indexOf(key) < 0) {
              return false;
            }
            if (value == null) {
              return false;
            }
            const found: boolean =
              value
                .toString()
                .toLowerCase()
                .indexOf(this.state.searchValue == null ? '' : this.state.searchValue) >= 0;

            return found;
          })
          .indexOf(true) >= 0
      );
    });
    Actions.setPaging({
      rowsPP: this.rowsPerPage,
      first: 0,
      last: this.rowsPerPage,
      amount: filtered.length,
    });
    return filtered;
  }

  public getData(): Array<T> {
    if (this.state.combinedData == null || this.state.combinedData.length === 0) {
      return [];
    }

    let data: Array<RowType> = this.filterData(this.state.combinedData);
    data = this.sort(data);
    return data.filter((el: RowType): boolean => el.ref != null).map((el: RowType) => el.ref);
  }

  static getDerivedStateFromProps(props: IProps<any>, state: IState<any>): IState<any> | null {
    if (
      props.sourceData !== state.sourceData ||
      props.header !== state.header ||
      props.searchValue !== state.searchValue ||
      props.multiSelect !== state.multiSelect ||
      props.toggleOnSelectAll !== state.toggleOnSelectAll
    ) {
      let columnCount: number = -1;
      if (props.fields != null) {
        columnCount = Math.max(
          columnCount,
          Math.max(
            props.fields == null ? 0 : props.fields.length,
            props.header == null ? 0 : props.header.length,
          ),
        );
      } else if (props.sourceData != null && props.sourceData.length > 0) {
        columnCount = Math.max(columnCount, Object.keys(props.sourceData[0]).length);
      }

      return {
        header: props.header,
        searchValue: props.searchValue,
        combinedData:
          props.sourceData != null && props.sourceData.map != null
            ? props.sourceData.map((el: RowType, index: number) =>
                Table.converter(props, index, el, props.fields, columnCount, index > 0),
              )
            : [],
        sourceData: props.sourceData,
        multiSelect: props.multiSelect,
        toggleOnSelectAll: props.toggleOnSelectAll,
        indexFirstDisplayedElement:
          props.firstOfPage != null ? props.firstOfPage - 1 : state.indexFirstDisplayedElement,
        indexLastDisplayedElement:
          props.firstOfPage != null
            ? props.firstOfPage - 1 + Config.table.rowserPerPageDefault
            : state.indexLastDisplayedElement,
      };
    }
    return null;
  }

  private onCheckBoxChanged(rowIndex: number, selected: boolean, ref: T | undefined): void {
    if (this.state.combinedData == null || this.state.combinedData.length === 0) {
      return;
    }

    if (rowIndex < 0) {
      if (this.state.toggleOnSelectAll === true) {
        this.rowRefs.forEach((row: Row | null) => {
          if (row != null) {
            row.toggleSelected();
          }
        });
      } else {
        this.rowRefs.forEach((row: Row | null) => {
          if (row != null) {
            row.selected = selected;
          }
        });
      }
      return;
    }

    if (ref == null) {
      return;
    }
    if (selected === true) {
      if (this.state.selectedRows != null) {
        this.setState({
          selectedRows: this.state.selectedRows.set(rowIndex, ref),
        });
      }
    } else {
      if (this.state.selectedRows != null && this.state.selectedRows.has(rowIndex)) {
        this.state.selectedRows.delete(rowIndex);
      }
    }
  }

  private data: Array<RowType> = [];
  private sourceData: Array<T> | undefined = undefined;

  private prepareData(): Array<RowType> | undefined {
    const data: Array<RowType> | undefined = this.state.combinedData;

    if (data == null || data.length === 0) {
      return [];
    }

    let action: 'update' | 'filter' | 'order' | 'none' = 'none';

    if (
      this.sortDirection !== this.state.sortDirection ||
      this.sortColumn !== this.state.sortColumn
    ) {
      action = 'order';
    }
    if (this.searchValue !== this.state.searchValue) {
      action = 'filter';
    }
    if (this.sourceData !== this.state.sourceData) {
      action = 'update';
    }
    // logic here requires the abscense of breaks for each case, cause every case needs to be applied
    switch (action) {
      case 'update':
        this.sourceData = this.state.sourceData;
      // eslint-disable-next-line no-fallthrough
      case 'filter':
        this.searchValue = this.state.searchValue;
        this.data = this.filterData(data);
        if (this.props.onFilter != null) {
          this.props.onFilter();
        }
      // eslint-disable-next-line no-fallthrough
      case 'order':
        this.sortDirection = this.state.sortDirection;
        this.sortColumn = this.state.sortColumn;
        this.data = this.sort(this.data);
        if (this.props.onSort != null) {
          this.props.onSort();
        }
      // eslint-disable-next-line no-fallthrough
      default:
        return this.data;
    }
  }

  private resize() {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  }

  private sort(data?: Array<RowType>): Array<RowType> {
    if (data == null || data.length === 0) {
      return [];
    }

    const column: number = this.state.sortColumn == null ? -1 : this.state.sortColumn;
    if (column < 0 || this.state.sortDirection === 'none') {
      return data;
    }

    const sorted = data.sort((a: RowType, b: RowType): number => {
      const valuesA: Array<any> = Object.values(a);
      const valuesB: Array<any> = Object.values(b);

      if (valuesA[0][column].value > valuesB[0][column].value) {
        if (this.state.sortDirection === 'ASC') {
          return 1;
        } else {
          return -1;
        }
      } else if (valuesA[0][column].value < valuesB[0][column].value) {
        if (this.state.sortDirection === 'DESC') {
          return 1;
        } else {
          return -1;
        }
      } else {
        return 0;
      }
    });

    return sorted;
  }

  private sortData(columnIndex: number, sort: 'ASC' | 'DESC' | 'none'): void {
    if (this.state.combinedData == null || this.state.combinedData.length < 2) {
      return;
    }

    this.setState({
      sortColumn: columnIndex,
      sortDirection: sort,
    });
  }

  resetPaging(value?: number) {
    Actions.setPaging({
      rowsPP: this.rowsPerPage,
      first: 0,
      last: 1 + this.rowsPerPage,
    });
    this.setState(
      {
        indexFirstDisplayedElement: value != null ? value : 0,
        indexLastDisplayedElement: 1 + this.rowsPerPage,
      },
      () => {
        this.setState(this.state);
      },
    );
  }

  calcHeightsAndRender(preparedTableData: Array<RowType>) {
    const res: JSX.Element[] = [];
    if (this.firstRowRef.current != null) {
      this.rowRefSet = true;
      let elements = this.firstRowRef.current.checkRender(this.props.maxHeight);
      this.rowsPerPage = elements;
      if (this.props.dynamicPaging === false) {
        if (this.props.rowsPerPageParam === null) {
          this.rowsPerPage = Config.table.rowserPerPageDefault;
          elements = Config.table.rowserPerPageDefault;
        } else {
          this.rowsPerPage = this.props.rowsPerPageParam;
          elements = this.props.rowsPerPageParam;
        }
      }
      preparedTableData = preparedTableData.slice(
        this.state.indexFirstDisplayedElement != null
          ? this.state.indexFirstDisplayedElement + 1
          : 1,
        this.state.indexFirstDisplayedElement != null
          ? this.state.indexFirstDisplayedElement + elements
          : elements,
      );
      preparedTableData.forEach((rowData: RowType, index: number) => {
        res.push(
          <Row
            key={
              rowData.cells == null
                ? '_empty'
                : rowData.cells
                    .map((el: ICellType) => el.value.toString() + '_' + index)
                    .join('_')
            }
            resize={this.props.resize}
            data={rowData}
            id={this.props.id}
            windowWidth={this.state.windowWidth}
            windowHeight={this.state.windowHeight}
            minWidthForColumns={this.state.minWidthForColumns}
            searchValue={this.state.searchValue}
            highlightCellOnSearch={this.props.highlightCellOnSearch === true}
            highlightTextOnSearch={this.props.highlightTextOnSearch === true}
            rowIndex={index}
            multiSelect={this.state.multiSelect}
            onChangeCheckbox={(value: boolean, ref: T | undefined) =>
              this.onCheckBoxChanged(index, value, ref)
            }
            isSelected={this.state.selectedRows != null && this.state.selectedRows.has(index)}
            ref={element => this.rowRefs.push(element)}
          />,
        );
      });
      if (!this.props.hidePaging) {
        Actions.setPaging(
          {
            rowsPP: elements,
            first: this.state.indexFirstDisplayedElement + 1,
            last: this.state.indexFirstDisplayedElement + elements,
          },
          this.props.id,
        );
      }
    }
    return res;
  }
  renderHeader() {
    return (
      <Row
        data={this.extractHeader(this.state.combinedData)}
        windowWidth={this.state.windowWidth}
        windowHeight={this.state.windowHeight}
        minWidthForColumns={this.state.minWidthForColumns}
        highlightCellOnSearch={false}
        highlightTextOnSearch={false}
        onSort={(columnIndex: number, sort: 'ASC' | 'DESC' | 'none') =>
          this.sortData(columnIndex, sort)
        }
        isHeader={true}
        rowIndex={-1}
        multiSelect={this.state.multiSelect}
        onChangeCheckbox={(value: boolean, ref: T | undefined) =>
          this.onCheckBoxChanged(-1, value, ref)
        }
      />
    );
  }

  updatePaging(indexes: any) {
    if (indexes != null && indexes.firstOfPage != null && indexes.lastOfPage != null) {
      this.setState({
        indexFirstDisplayedElement: indexes.firstOfPage - 1,
        indexLastDisplayedElement: indexes.lastOfPage - 1,
        offset: indexes.offset != null ? indexes.offset : 0,
      });
    }
  }

  private searchValue: string | undefined = undefined;
  private sortDirection: 'ASC' | 'DESC' | 'none' | undefined = undefined;
  private sortColumn: number | undefined =
    this.props.sortDirection != null ? this.props.sortColumn : undefined;

  render() {
    const res = this.props.resize;
    if (this.state.combinedData == null) {
      return (
        <TableScrollView>
          <div> {translate('table.noEntriesFound')} </div>
        </TableScrollView>
      );
    } else {
      const preparedTableData = this.prepareData();
      return (
        <div>
          {this.showPaging === true && this.props.externalPaging !== true ? (
            <PagingComponent
              numberOfEntries={this.props.sourceData.length}
              rowsPerPage={this.rowsPerPage}
              notification={this.updatePaging}
              resetTransactionscallback={this.props.updateTransactionCallback}
              offset={this.state.offset}
              id={this.props.id}
            />
          ) : null}

          <TableScrollView
            ref={element => (this.scrollViewRef = element)}
            /*stickyHeaderIndices={
                            this.props.stickyHeader === true ? [0] : undefined*/
          >
            <StyledTable overflowBox={this.props.overflow}>
              <thead>{this.renderHeader()}</thead>
              <tbody style={{ border: 'none' }}>
                {preparedTableData !== undefined
                  ? this.props.hidePaging !== true &&
                    preparedTableData[this.state.indexFirstDisplayedElement] != null
                    ? (
                        <Row
                          resize={this.props.resize}
                          key={
                            preparedTableData[this.state.indexFirstDisplayedElement].cells ==
                            null
                              ? '_empty'
                              : preparedTableData[this.state.indexFirstDisplayedElement]
                                  .cells!!.map(
                                    (el: ICellType) => el.value.toString() + '_' + 0,
                                  )
                                  .join('_')
                          }
                          id={this.props.id}
                          data={preparedTableData[this.state.indexFirstDisplayedElement]}
                          windowWidth={this.state.windowWidth}
                          windowHeight={this.state.windowHeight}
                          minWidthForColumns={this.state.minWidthForColumns}
                          searchValue={this.state.searchValue}
                          highlightCellOnSearch={this.props.highlightCellOnSearch === true}
                          highlightTextOnSearch={this.props.highlightTextOnSearch === true}
                          rowIndex={this.state.indexFirstDisplayedElement}
                          multiSelect={this.state.multiSelect}
                          onChangeCheckbox={(value: boolean, ref: T | undefined) =>
                            this.onCheckBoxChanged(0, value, ref)
                          }
                          isSelected={
                            this.state.selectedRows != null &&
                            this.state.selectedRows.has(this.state.indexFirstDisplayedElement)
                          }
                          headers={this.props.header}
                          ref={this.firstRowRef}
                        />
                      ) || translate('table.noEntriesFound')
                    : preparedTableData.map(
                        (rowData: RowType, index: number): ReactElement | null => {
                          return (
                            <Row
                              resize={res}
                              key={
                                rowData.cells == null
                                  ? '_empty'
                                  : rowData.cells
                                      .map(
                                        (el: ICellType) => el.value.toString() + '_' + index,
                                      )
                                      .join('_')
                              }
                              id={this.props.id}
                              data={rowData}
                              windowWidth={this.state.windowWidth}
                              windowHeight={this.state.windowHeight}
                              minWidthForColumns={this.state.minWidthForColumns}
                              searchValue={this.state.searchValue}
                              highlightCellOnSearch={this.props.highlightCellOnSearch === true}
                              highlightTextOnSearch={this.props.highlightTextOnSearch === true}
                              rowIndex={index}
                              multiSelect={this.state.multiSelect}
                              onChangeCheckbox={(value: boolean, ref: T | undefined) =>
                                this.onCheckBoxChanged(index, value, ref)
                              }
                              isSelected={
                                this.state.selectedRows != null &&
                                this.state.selectedRows.has(index)
                              }
                              ref={element => this.rowRefs.push(element)}
                              headers={this.props.header}
                            />
                          );
                        },
                      ) || translate('table.noEntriesFound')
                  : null}
                {this.firstRowRef != null && this.props.hidePaging !== true
                  ? this.calcHeightsAndRender(preparedTableData!!)
                  : null}
              </tbody>
            </StyledTable>
          </TableScrollView>
        </div>
      );
    }
  }
}

const StyledTable = styled.table<{ overflowBox?: boolean }>`
  border-spacing: 0;
  table-layout: fixed;
  width: ${props => (props.overflowBox === true ? 'auto' : '100%')};
  white-space: nowrap;
`;

const TableScrollView = styled.div`
  width: 100%;
  text-transform: ${props => props.theme.Table.textTransform};
  white-space: nowrap;
  text-overflow: ellipsis;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
`;
