import React from 'react';
import { OverlayHandler, Overlays } from '../../logic/handler/overlayhandler/overlayHandler';
import { IDynamicEntry } from './popup/contextMenuOverlay';

export interface IContextmenuMethods {
  openCallback?: () => void;
  pasteCallback?: (text: string) => void;
  undoCallback?: () => void;
  redoCallback?: () => void;
  editCallback?: () => void;
  transactioNDetailsCallback?: () => void;
  postingJournalCallback?: () => void;
  revertTransactionCallback?: () => void;
  approvalCallback?: () => void;
  cancelCallback?: () => void;
  statementCallback?: () => void;
  editAddressCallback?: () => void;
  emailCallback?: () => void;
  phoneCallback?: () => void;
  customersCallback?: () => void;
  accountCallback?: () => void;
  limitCallback?: () => void;
  stornoProductCallback?: () => void;
  invoiceCallback?: () => void;
  stornoCallback?: () => void;
  startQueuecallback?: () => void;
  dynEntrys?: Array<IDynamicEntry>;
}
interface IProps {
  methods: IContextmenuMethods;
  elementKey: string;
  copyValue: string;
  style?: React.CSSProperties;
}

interface IState {}

export class DisplayMenuComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
    this.openContextMenu = this.openContextMenu.bind(this);
    this.copy = this.copy.bind(this);
    this.paste = this.paste.bind(this);
  }

  openContextMenu(ev: React.MouseEvent<HTMLDivElement>) {
    ev.preventDefault();
    ev.stopPropagation();
    console.log(this.props.methods.stornoCallback);
    const el = document.getElementById('contextMenu' + this.props.elementKey);
    if (el != null) {
      const box = el.getBoundingClientRect();
      OverlayHandler.showOverlay(Overlays.contextMenu, {
        posX: box.x,
        posY: box.y + box.height + 10,
        copyCallback: this.copy,
        pasteCallback: this.props.methods.pasteCallback != null ? this.paste : null,
        undoCallback: this.props.methods.undoCallback,
        redoCallback: this.props.methods.redoCallback,
        editCallback: this.props.methods.editCallback,
        selfclose: () => {
          OverlayHandler.closeSpecific(Overlays.contextMenu);
        },
        postingJournalCallback: this.props.methods.postingJournalCallback,
        transactionDetailsCallback: this.props.methods.transactioNDetailsCallback,
        revertTransactionCallback: this.props.methods.revertTransactionCallback,
        statementCallback: this.props.methods.statementCallback,
        editAddressCallback: this.props.methods.editAddressCallback,
        approvalCallback: this.props.methods.approvalCallback,
        cancelCallback: this.props.methods.cancelCallback,
        emailCallback: this.props.methods.emailCallback,
        phoneCallback: this.props.methods.phoneCallback,
        customersCallback: this.props.methods.customersCallback,
        accountCallback: this.props.methods.accountCallback,
        limitCallback: this.props.methods.limitCallback,
        stornoProductCallback: this.props.methods.stornoProductCallback,
        invoiceCallback: this.props.methods.invoiceCallback,
        stornoCallback: this.props.methods.stornoCallback,
        startQueuecallback: this.props.methods.startQueuecallback,
        dynEntrys: this.props.methods.dynEntrys,
      });
    }
    if (this.props.methods.openCallback != null) {
      this.props.methods.openCallback();
    }
  }

  copy() {
    navigator.clipboard.writeText(this.props.copyValue);
  }
  paste() {
    if (this.props.methods.pasteCallback != null) {
      navigator.permissions
        .query({
          //@ts-ignore
          name: 'clipboard-read',
        })
        .then((permissionStatus: any) => {
          // Will be 'granted', 'denied' or 'prompt':
          navigator.clipboard.readText().then((text: string) => {
            if (this.props.methods.pasteCallback != null) {
              this.props.methods.pasteCallback(text);
            }
          });
          // Listen for changes to the permission state
          permissionStatus.onchange = () => {};
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={this.props.style}
          id={'contextMenu' + this.props.elementKey}
          onContextMenu={this.openContextMenu}
        >
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}
