import React, { ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Icons } from '../../../images';
import {
  IRelativeProps,
  IRelativeState,
  RelativeOverlay,
} from '../../../logic/handler/overlayhandler/globaloverlays/aRelativeOverlay';

interface IProps extends IRelativeProps {
  callback: (imageId: number, type: string) => void;
  width?: string;
  images: Array<any>;
}

interface IState extends IRelativeState {}

export default class ImageSelectOverlay extends RelativeOverlay<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  reviveState() {}

  getImages() {
    const out = [];
    for (const i in this.props.images) {
      if (i === '0') {
        continue;
      }
      out.push(
        <ImageSelect
          onClick={(event: SyntheticEvent) => {
            event.stopPropagation();
            event.preventDefault();
            const fileTypeSplit = this.props.images[i].name.split('.');
            this.props.callback(
              this.props.images[i].id,
              fileTypeSplit[fileTypeSplit.length - 1],
            );
          }}
        >
          <div style={{ marginRight: '8px', marginTop: '6px' }}> {Icons.collection()}</div>
          <div
            title={this.props.images[i].name}
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {' '}
            {this.props.images[i].name}
          </div>
        </ImageSelect>,
      );
    }
    return out;
  }
  defineContent(): ReactNode {
    return (
      <Container x={this.props.posX} y={this.props.posY}>
        {this.getImages()}
      </Container>
    );
  }
}

const Container = styled.div<{ x: string; y: string }>`
  position: absolute;
  top: ${props => props.y + 'px'};
  left: ${props => props.x + 'px'};
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  z-index: 50;
  flex-direction: column;
`;

const ImageSelect = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 24px;
  padding: 8px;
  width: ${props => (props.width != null ? props.width : '400px')};
  text-align: center;
  line-height: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.Button.backgroundColor};
  height: 40px;
  margin: 2px;
  svg {
    fill: ${props => props.theme.Global.lightFontColor};
  }
  :hover {
    background-color: rgb(189, 190, 192);
    cursor: pointer;
  }
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 400px;
  @media (max-width: 1830px) {
    max-width: 250px;
  }
`;
