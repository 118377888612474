import styled from 'styled-components';
import { FlexBox } from '../auth.css';

export const StyledAuthComponent = styled(FlexBox)<{ centered?: boolean }>`
    margin-left: ${props => (props.centered === true ? 'calc(50vw - 268px)' : 'inherit')}
    margin-top: ${props => (props.centered === true ? 'calc(50vh - 296px)' : 'inherit')}

    // First header line (old h2)
    & > div:nth-child(1) {
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 35px;
        letter-spacing: 0.2px;

        color: #1b1e24;
        margin-top: 30px;
        text-align: left;
    }

    // Second header line (old h3)
    & > div:nth-child(2) {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;

        color: ${props => props.theme.Global.darkFontColor};
        text-align: left;
        margin-top: 1vh;
        margin-bottom: 40px;
    }

    label {
        margin-top: 18px;
    }

    svg,
    img {
        margin: auto;
    }

    & > * > * > button,
    & > * > button,
    & > button {
        background-color: ${props => props.theme.Button.backgroundColor};
        color: ${props => props.theme.Button.color};
        width: 100%;
        margin-top: 60px;
    }

    a#cancel {
        margin-top: 10vh;

        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;

        color: ${props => props.theme.Button.backgroundColor};
        border: none;
        background-color: #fbfbfb;

        :hover {
            text-decoration: underline;
        }
    }

    a#resetPassword {
        font-weight: bold;
        color: ${props => props.theme.Global.darkFontColor};
        text-align: right;
        margin-top: 1.2vh;
        text-decoration: none;

        :hover {
            text-decoration: underline;
        }
    }

    a#faceLogin {
        width: 100%;
        text-align: center;
        margin-top: 2.8vh;
        font-family: Roboto;
        color: #bdbdbd;
        text-decoration: none;

        :hover {
            text-decoration: underline;
        }
    }

    a#verify {
        display: none;
    }

    a#signin {
        font-size: 14px;
        line-height: 16px;
        color: #bdbdbd;
        text-align: center;
        margin-top: 14px;
    }
`;

export const LinkBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
