import React from 'react';
import styled from 'styled-components';

interface IProps {
  ratio: number;
  color: string;
  absVal: string;
  displayPercent: boolean;
}

interface IState {
  perCent: string;
  displayValue: string;
}

export class Progressbar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      perCent: Math.round(props.ratio * 100) + '%',
      displayValue: props.displayPercent ? Math.round(props.ratio * 100) + '%' : props.absVal,
    };
  }

  componentWillReceiveProps(props: IProps) {
    this.setState({
      displayValue: props.displayPercent ? Math.round(props.ratio * 100) + '%' : props.absVal,
      perCent: Math.round(props.ratio * 100) + '%',
    });
  }

  render() {
    return (
      <OuterBox>
        <InnerBox width={this.state.perCent} color={this.props.color}></InnerBox>
        <InnerText>{this.state.displayValue}</InnerText>
      </OuterBox>
    );
  }
}

const OuterBox = styled.div`
    width: 100%;
    padding: 2px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid ${props => props.theme.Global.lightFontColor}
    display: flex;
    position: relative;
`;

const InnerBox = styled.div<{ width: string; color: string }>`
  display: flex;
  justify-content: center;
  width: ${props => props.width};
  background-color: ${props =>
    props.color != null ? props.color : props.theme.Global.primaryColor};
`;

const InnerText = styled.span`
  width: 50%;
  font-size: 10px;
  line-height: 12px;
  position: absolute;
  margin-left: 16px;
  font-weight: 500;
`;
