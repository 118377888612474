//@ts-nocheck
import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { StyledLine } from '../../basicStyledComponents/customersComponent.css';
import {
  PermissionsPersonsRolesPutRequest,
  PermissionsPersonsRolesGetRequest,
  PermissionsPersonsRolesDeleteRequest,
} from '../../../../../logic/api/ident';
import {
  IStatusState,
  IMessageConfig,
  Reporter,
  getFormComponents,
} from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import { api, apis, ApiError, Ident } from '../../../../../logic/api';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { Log, Logs } from '../../../../../logic/log';
import { translate } from '../../../../../common/language/translate';
import { Icons } from '../../../../../images';
import {
  IPropsCenter,
  IStateCenter,
  ACenteredOverlay,
} from '../../../../../logic/handler/overlayhandler/globaloverlays/aCenteredOverlay';
import { theme } from '../../../../../common/theme';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import ButtonCancel from '../../../../../components/atomiccompoents/buttons/buttonCancel';
import { IOption } from '../../../../../components/atomiccompoents/form/select';
import { BigInput } from '../../../../../components/atomiccompoents/form/inputs.css';
import { FlexBox } from '../../../../auth/auth.css';
import { CheckBox } from '../../../../../components/atomiccompoents/form/checkbox';
import { CheckboxWrapper } from '../../customers/basicStyledComponents/customerDetails.css';
import { StyledSelect } from '../../basicStyledComponents/customersComponent.css';
import { PermissionStore } from '../../../../../logic/flux';
import { evaluateErrorMessage } from '../../../../../logic/helper/Common';
import { ClientConfig } from '../../../../../common/config';

const StyledOverlay = styled(FlexBox)`
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 50px;
  padding-top: 20;
  z-index: 300;
  width: 670px;
  height: 520px;

  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  overflow-y: auto;
`;

const StyledViewHeader = styled(FlexBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledTextHeader = styled.div`
  margin-left: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${props => props.theme.Global.lightFontColor};
`;

const StyledButtonView = styled(FlexBox)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 100px;

  & > button {
    margin-left: 10px;
  }
`;

const StyledViewTwoFields = styled(FlexBox)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > div {
    width: 48%;
    padding-right: 20px;
  }
`;

const StyledTextLabel = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.Global.darkFontColor};
  margin-bottom: 12px;
`;

const StyledMarginSelect = styled(StyledSelect)`
  max-width: 450px;

  select {
    min-width: 200px;
    max-width: 350px;
    margin-bottom: 8px;
    margin-top: 9px;
    margin-right: 32px;
  }
`;

const BigInputWrapper = styled.div`
  max-width: 350px;
  margin-right: 16px;
  margin-left: 16px;
`;

interface IProps extends IPropsCenter {
  personId: number;
  personName: string;
  personEmail: string;
  personGivenName: string;
  personEntityId?: number;
  createCallback?: () => void;
  initPerson?: boolean;
}

interface IRoleOption extends IOption {
  checked: boolean;
}

interface IState extends IStatusState, IStateCenter {
  availableRoles: IRoleMap;
  currentRoles: { [role_id: string]: IRole };
  selectOptions: { [role_id: number]: IRoleOption };
  keyForLoadingSpinner?: number;
  deleteRoles: IRoleMap;
  addRole: IRoleMap;
  email: string;
  initialEntityId?: number;
  entityId?: number;
  name: string;
  givenName: string;
  entities?: Array<Ident.Entity>;
}
interface IRole {
  role_id: number;
  name: string;
}
interface IRoleMap {
  [key: string]: IRole;
}

export default class RoleEditOverlay extends ACenteredOverlay<IProps, IState> {
  private ButtonStyle = {
    width: theme.Button.width,
  };

  constructor(props: IProps) {
    super(props);
    this.onSave = this.onSave.bind(this);
    this.getEntityOptions = this.getEntityOptions.bind(this);
    this.cancel = this.cancel.bind(this);
    this.state = {
      email: props.personEmail,
      currentRoles: {},
      selectOptions: {},
      deleteRoles: {},
      addRole: {},
      availableRoles: {},
      initialEntityId: props.personEntityId,
      entityId: props.personEntityId,
      name: props.personName,
      givenName: props.personGivenName,
    };
    this.fetchEntities();
    if (!this.state.entityId) {
      this.fetchPersonEntityId();
    }
  }

  componentDidMount() {
    const requestParams = {};
    const availableRoles: IRoleMap = {};
    const selectOptions: { [key: string]: IRoleOption } = {};
    api
      .asyncRequest(
        requestParams,
        apis.PermissionsApi,
        // "permissionsPersonsRolesGet"
        'permissionsRolesGet',
      )
      .then((roles: any) => {
        let key = 0;
        if (roles != null) {
          for (const role of roles) {
            availableRoles[role.role_id] = role;
            selectOptions[role.role_id] = {
              key: 'Roles' + key++,
              name: role.name,
              value: role.role_id,
              checked: false,
            };
          }

          this.setState({
            availableRoles: availableRoles,
            selectOptions: selectOptions,
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            deleteRoles: {},
            addRole: {},
          });
          // get person-roles
          const params: PermissionsPersonsRolesGetRequest = {
            person_id: this.props.personId,
          };
          api
            .asyncRequest(params, apis.PermissionsApi, 'permissionsPersonsRolesGet')
            .then((currentRoles: any) => {
              const currentRolesJson: IRoleMap = {};
              if (
                currentRoles != null &&
                currentRoles[0] != null &&
                currentRoles[0].name != null
              ) {
                const currentOptions = selectOptions;
                for (const role of currentRoles) {
                  currentOptions[role.role_id].checked = true;
                  const role_id: number = role.role_id;
                  currentRolesJson[role_id] = role;
                }
                this.setState({
                  selectOptions: currentOptions,
                  currentRoles: currentRoles,
                });
              }
            })
            .catch((error: ApiError) => {
              const config: IMessageConfig = MessageHandler.onError(
                Reporter['person.roles.get.request'],
                evaluateErrorMessage(error, true),
                evaluateErrorMessage(error, false),
              );
              this.setState({
                showInlineError: config.errorMethods.inline,
                errorMessage:
                  config.errorMessage != null ? config.errorMessage : error.statusText,
                key:
                  config.translationKey != null
                    ? 'messages.' + config.translationKey + '.error'
                    : '',
              });
              Log.debug(Logs.API, error);
            });
        }
      })

      .catch((error: ApiError) => {
        const config: IMessageConfig = MessageHandler.onError(
          Reporter['person.roles.get.request'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
        this.setState({
          showInlineError: config.errorMethods.inline,
          errorMessage: config.errorMessage != null ? config.errorMessage : error.statusText,
          key:
            config.translationKey != null
              ? 'messages.' + config.translationKey + '.error'
              : '',
        });
        Log.debug(Logs.API, error);
      });
  }

  cancel(event?: SyntheticEvent) {
    if (event != null) {
      event.preventDefault();
    }
    this.props.selfClose();
  }

  onSave(event: SyntheticEvent) {
    event.preventDefault();
    const addKeys = Object.keys(this.state.addRole);
    const delKeys = Object.keys(this.state.deleteRoles);
    for (let i = 0; i < addKeys.length; i++) {
      // for (let index = 0; index < this.state.currentRole.length; index++) {
      const role_id = this.state.addRole[addKeys[i]].role_id;
      const requestParams: PermissionsPersonsRolesPutRequest = {
        PersonRoleRequest: { person_id: this.props.personId, role_id: role_id },
      };
      api
        .asyncRequest(requestParams, apis.PermissionsApi, 'permissionsPersonsRolesPut')
        .then((response: any) => {
          // if (response != null && index === this.state.currentRole.length - 1) {
          this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          });
          if (response != null) {
            MessageHandler.onSuccess(Reporter['administrator.teams.update.role']);
            if (this.props.createCallback != null) {
              this.props.createCallback();
            }
          }
          this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          });
        })
        .catch((error: ApiError) => {
          const config: IMessageConfig = MessageHandler.onError(
            Reporter['person.roles.get.request'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
          this.setState({
            showInlineError: config.errorMethods.inline,
            errorMessage: config.errorMessage != null ? config.errorMessage : error.statusText,
            key:
              config.translationKey != null
                ? 'messages.' + config.translationKey + '.error'
                : '',
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          });
          Log.debug(Logs.API, error);
        });
      // }
    }
    for (let i = 0; i < delKeys.length; i++) {
      const role_id = this.state.deleteRoles[delKeys[i]].role_id;
      const requestParams: PermissionsPersonsRolesDeleteRequest = {
        person_id: this.props.personId,
        role_id: role_id,
      };

      api
        .asyncRequest(requestParams, apis.PermissionsApi, 'permissionsPersonsRolesDelete')
        .then((response: any) => {
          // if (response != null && index === this.state.currentRole.length - 1) {
          this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          });
          if (response != null) {
            MessageHandler.onSuccess(Reporter['administrator.teams.update.role']);
          }
          this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          });
        })
        .catch((error: ApiError) => {
          const config: IMessageConfig = MessageHandler.onError(
            Reporter['person.roles.get.request'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
          this.setState({
            showInlineError: config.errorMethods.inline,
            errorMessage: config.errorMessage != null ? config.errorMessage : error.statusText,
            key:
              config.translationKey != null
                ? 'messages.' + config.translationKey + '.error'
                : '',
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          });
          Log.debug(Logs.API, error);
        });
      // }
    }
    if (this.props.initPerson !== true && this.props.personEmail !== this.state.email) {
      const params: Ident.PersonEmailAddressPostRequest = {
        NewEmailAddress: {
          email_address: this.state.email,
        },
        person_id: this.props.personId,
      };
      api
        .asyncRequest(params, apis.MaintenanceApi, 'personEmailAddressPost')
        .then((response: any) => {
          // if (response != null && index === this.state.currentRole.length - 1) {
          this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          });
          if (response != null) {
            MessageHandler.onSuccess(Reporter['administrator.teams.update.data']);
          }
          this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          });
        })
        .catch((error: ApiError) => {
          MessageHandler.onError(
            Reporter['administrator.teams.update.data'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
        });
    }
    if (
      this.props.initPerson !== true &&
      (this.props.personGivenName !== this.state.givenName ||
        this.props.personName !== this.state.name)
    ) {
      const params: Ident.PersonPutRequest = {
        person_id: this.props.personId,
        PersonUpdateRequest: {
          name: this.state.name,
          given_name: this.state.givenName,
        },
      };
      api
        .asyncRequest(params, apis.MaintenanceApi, 'personPut')
        .then((response: any) => {
          // if (response != null && index === this.state.currentRole.length - 1) {
          this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          });
          if (response != null) {
            MessageHandler.onSuccess(Reporter['administrator.teams.update.data']);
          }
          this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          });
        })
        .catch((error: ApiError) => {
          MessageHandler.onError(
            Reporter['administrator.teams.update.data'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
        });
    }
    if (this.props.initPerson !== true && this.state.initialEntityId !== this.state.entityId) {
      const params: Ident.PersonPersonIdEntityPostRequest = {
        person_id: this.props.personId,
        entity_id: this.state.entityId === undefined ? null : this.state.entityId,
      };
      api
        .asyncRequest(params, apis.MaintenanceApi, 'personPersonIdEntityPost')
        .then((response: any) => {
          // if (response != null && index === this.state.currentRole.length - 1) {
          this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          });
          if (response != null) {
            MessageHandler.onSuccess(Reporter['administrator.teams.update.data']);
          }
          this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          });
        })
        .catch((error: ApiError) => {
          MessageHandler.onError(
            Reporter['person.entity.post.request'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
        });
    }
    this.props.selfClose();
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.onSave(event);
    }
  };

  onInputChange(key: any, value: boolean) {
    const changeData = this.state.selectOptions;
    changeData[key].checked = value;
    const deleteData = this.state.deleteRoles;
    const addData = this.state.addRole;
    if (value) {
      delete deleteData[key];
      addData[key] = this.state.availableRoles[key];
    } else {
      delete addData[key];
      deleteData[key] = this.state.availableRoles[key];
    }
    this.setState({
      selectOptions: changeData,
    });
  }

  getCheckBoxes() {
    const out: JSX.Element[] = [];
    for (const key in this.state.selectOptions) {
      out.push(
        <CheckboxWrapper>
          <CheckBox
            textStyle={{
              display: 'inline',
              verticalAlign: 'top',
            }}
            containerStyle={{
              maxWidth: '180px',
            }}
            id={this.state.selectOptions[key].name}
            value={this.state.selectOptions[key].checked}
            //@ts-ignore
            label={
              ClientConfig.roles[this.state.selectOptions[key].name] != null
                ? ClientConfig.roles[this.state.selectOptions[key].name]
                : this.state.selectOptions[key].name
            }
            onChange={(value: boolean) => this.onInputChange(key, value)}
            disabled={!PermissionStore.hasPermission('permissions_persons_roles_put')}
          />
        </CheckboxWrapper>,
      );
    }
    return out;
  }

  fetchEntities() {
    api
      .asyncRequest<Array<Ident.Entity>>({}, apis.MaintenanceApi, 'lookupEntityGet')
      .then((response: Array<Ident.Entity>) => {
        this.setState({
          entities: response,
        });
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
      });
  }

  fetchPersonEntityId() {
    const params: Ident.PersonPersonIdGetRequest = {
      person_id: this.props.personId,
    };
    api
      .asyncRequest<Ident.PersonPersonIdGetRequest>(
        params,
        apis.MaintenanceApi,
        'personPersonIdGet',
      )
      .then((response: Ident.PersonPersonIdGetRequest) => {
        this.setState({
          initialEntityId: response.entity_id,
          entityId: response.entity_id,
        });
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
      });
  }

  getEntityOptions(): Array<IOption> {
    const out: Array<IOption> = (this.state.entities || []).map(e => ({
      name: e.entity_name != null ? e.entity_name : '',
      value: e.entity_id,
      key: e.entity_name,
    }));

    if (out.length !== 1) {
      // Assume that the current user is in 'all' if they can see multiple entities
      out.unshift({
        name: 'all',
        value: 'all',
        key: 'all',
      });
    }

    return out;
  }

  getFormContent() {
    if (this.state) {
      return (
        <form onSubmit={this.onSave} onKeyDown={this.onKeyDown}>
          <StyledViewTwoFields style={{ flexWrap: 'nowrap' }}>
            <FlexBox>
              <BigInput
                label={translate('team.input.fullname.label')}
                type="name"
                id="fullName"
                disabled={this.props.initPerson === true}
                defaultValue={this.state.name}
                onSubmit={this.onSave}
                onChange={(val: string) => {
                  this.setState({
                    name: val,
                  });
                }}
              />
              {this.props.initPerson !== true ? (
                <React.Fragment>
                  <BigInput
                    label={translate('team.input.firstname.label')}
                    type="name"
                    id="givenName"
                    disabled={false}
                    defaultValue={this.state.givenName}
                    onSubmit={this.onSave}
                    onChange={(val: string) => {
                      this.setState({
                        givenName: val,
                      });
                    }}
                  />
                  <BigInput
                    label={translate('team.input.email.label')}
                    type="name"
                    id="email"
                    disabled={false}
                    defaultValue={this.state.email}
                    onSubmit={this.onSave}
                    onChange={(val: string) => {
                      this.setState({
                        email: val,
                      });
                    }}
                  />
                </React.Fragment>
              ) : null}
              <BigInputWrapper>
                <StyledMarginSelect
                  helptext={translate('team.input.entity.label')}
                  id="entity"
                  options={this.getEntityOptions()}
                  notification={(message: any) => {
                    this.setState({
                      entityId: message.entity === 'all' ? undefined : Number(message.entity),
                    });
                  }}
                  current={this.state.entityId}
                  wrapperStyle={{ margin: 'initial' }}
                />
              </BigInputWrapper>
            </FlexBox>
            <FlexBox style={{ alignItems: 'flex-start', marginLeft: '16px' }}>
              <StyledTextLabel>{translate('team.input.role.label')}</StyledTextLabel>
              {this.getCheckBoxes()}
            </FlexBox>
          </StyledViewTwoFields>

          <StyledButtonView>
            <ButtonCancel id="btnAddRoleCancel" onClick={this.cancel} style={this.ButtonStyle}>
              {translate('button.cancel')}
            </ButtonCancel>
            <ButtonOk id="btnAddRoleOk" onClick={this.onSave} style={this.ButtonStyle}>
              {translate('button.save')}
            </ButtonOk>
          </StyledButtonView>
        </form>
      );
    }
  }

  reviveState(): void {}

  defineContent() {
    return (
      <StyledOverlay onClick={event => event.stopPropagation()}>
        <FlexBox>
          <StyledViewHeader>
            {Icons.createCustomer()}
            <StyledTextHeader>{translate('team.roleAssignment')}</StyledTextHeader>
          </StyledViewHeader>
          <StyledLine />
          {this.getFormContent()}
          {this.state
            ? getFormComponents(
                this.state.showInlineError,
                this.state.showInlineSuccess,
                this.state.errorMessage,
                this.state.successMessage,
                this.state.key,
              )
            : null}
        </FlexBox>
      </StyledOverlay>
    );
  }
}
