import * as Ident from './ident';
import * as Account from './account';
import { Observable, of } from 'rxjs';
import { ajax, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { map, concatMap } from 'rxjs/operators';
import { IOperationIdMap } from './IOperationMap';
import { translate } from '../../common/language/translate';
import { OverlayHandler } from '../handler/overlayhandler/overlayHandler';
import { Log, Logs } from '../log';
import { Actions, UserDataStore, PermissionStore } from '../flux';
import { InitComponentHandler } from '../handler/initialdatahandler/InitComponentHandler';
import { FileType } from '../../components/compositcomponents/popup/fileTypeChooserOverlay';

const accountBasePath = window.location.protocol + '//' + window.location.host + '/core';
const identBasePath = window.location.protocol + '//' + window.location.host + '/ident';
//TODO: Set path accordingly if report-generator is secured
const reportBasePath =
  window.location.protocol + '//' + window.location.host + '/report-generator'; //'https://api.dit.corebaas.com/report-generator';

interface IApiError {
  status: number;
  statusText: string;
  response?: any;
}

export enum apis {
  RegistrationApi,
  MaintenanceApi,
  PartnerApi,
  OpenIDConnectApi,
  ServiceApi,
  SelfServiceApi,
  DefaultApi,
  BusinessaccountApi,
  MerchantApi,
  CryptoApi,
  LoginApi,
  CustomerTransactionApi,
  TemplatesApi,
  TemplatesApiDient,
  ReportCustomerApiIdent,
  TransactionBoApi,
  TransactionCustomerApi,
  OpenIDConnectAccount,
  PermissionsApi,
  NotificationApi,
  ReleaseApi,
  ReleaseApiIdent,
  ProductApi,
  ReplicationApi,
  BookingApi,
  BPCApi,
  ConfigurationApi,
  ClientstatsApi,
  RegForensicsApi,
  EyesonApi,
  MonitoringApi,
  ProductMaintentanceApi,
  OnfidoApi,
}

const apiNames: Array<string> = [
  'RegistrationApi',
  'MaintenanceApi',
  'PartnerApi',
  'OpenIDConnectApi',
  'SelfServiceApi',
  'ServiceApi',
  'DefaultApi',
  'BusinessaccountApi',
  'MerchantApi',
  'CryptoApi',
  'LoginApi',
  'CustomerTransactionApi',
  'TemplatesApi',
  'TemplatesApiIent',
  'ReportCustomerApiIdent',
  'TransactionBoApi',
  'TransactionCustomerApi',
  'OpenIDConnectAccount',
  'PermissionsApi',
  'NotificationApi',
  'ReleaseApi',
  'ReleaseApiIdent',
  'ProductsApi',
  'ReplicationApi',
  'BookingApi',
  'BPCApi,',
  'ConfigurationApi',
  'ClientstatsApi',
  'RegForensicsApi',
  'EyesonApi',
  'MonitoringApi',
  'ProductMaintentanceApi',
  'OnfidoApi',
];

const noDoubeClickPrevention: Array<string> = [
  'personFaceGet',
  'personMerchantDocumentGet',
  'accountNotificationPut',
  'permissionsPersonsRolesPut',
  'permissionsPersonsRolesDelete',
  'releasesPut',
  'personAccountsPersonIdGet',
  'personEmailaddressGet',
  'accountAccountNumberCardAgreementCardCardIdStateGet',
  'servicePersonDocumentGet',
  'personPersonIdGet',
  'oauthCompanionCodeDetail',
  'personJuniorDocumentList',
];

export class VoidResponse {
  void: any;
}

export type OperationIds = Account.OperationId | Ident.OperationId;

export type ApiError = IApiError;

type IdentApis =
  | Ident.MaintenanceApi
  | Ident.RegistrationApi
  | Ident.PartnerApi
  | Ident.OpenIDConnectApi
  | Ident.PermissionsApi
  | Ident.TemplatesApi
  | Ident.ReportCustomerApi
  | Ident.SelfServiceApi
  | Ident.ReleasesApi
  | Ident.ServiceApi
  | Ident.RegulaforensicsApi
  | Ident.OnfidoApi;
type AccountApis =
  | Account.DefaultApi
  | Account.BusinessaccountApi
  | Account.MerchantApi
  | Account.CryptoApi
  | Account.LoginApi
  | Account.CustomerTransactionApi
  | Account.TemplatesApi
  | Account.TransactionBoApi
  | Account.TransactionCustomerApi
  | Account.OpenIDConnectApi
  | Account.NotificationApi
  | Account.ReleasesApi
  | Account.ProductsApi
  | Account.ReplicationApi
  | Account.BookingApi
  | Account.BpcApi
  | Account.ConfigurationApi
  | Account.ClientstatsApi
  | Account.EyesonApi
  | Account.MonitoringApi
  | Account.ProductMaintenanceApi;

interface IInvokeTimer {
  [api: string]: {
    [request: string]: number;
  };
}

export type ReportAcceptHeader =
  | 'application/pdf'
  | 'text/comma-separated-values'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

interface IApiInstanceHolder {
  maintenanceApi?: Ident.MaintenanceApi;
  registrationApi?: Ident.RegistrationApi;
  partnerApi?: Ident.PartnerApi;
  openIDConnectApi?: Ident.OpenIDConnectApi;
  permissionsApi?: Ident.PermissionsApi;
  selfServiceApi?: Ident.SelfServiceApi;
  serviceApi?: Ident.ServiceApi;
  templatesApiIdent?: Ident.TemplatesApi;
  releaseApiIdent?: Ident.ReleasesApi;
  regForensicsApi?: Ident.RegulaforensicsApi;
  onfidoApi?: Ident.OnfidoApi;

  defaultApi?: Account.DefaultApi;
  businessaccountApi?: Account.BusinessaccountApi;
  merchantApi?: Account.MerchantApi;
  cryptoApi?: Account.CryptoApi;
  loginApi?: Account.LoginApi;
  customerTransactionApi?: Account.CustomerTransactionApi;
  templatesApi?: Account.TemplatesApi;
  transactionBoApi?: Account.TransactionBoApi;
  transactionCustomerApi?: Account.TransactionCustomerApi;
  openIdConnectAccount?: Account.OpenIDConnectApi;
  notificationApi?: Account.NotificationApi;
  releaseApi?: Account.ReleasesApi;
  productsApi?: Account.ProductsApi;
  replicationApi?: Account.ReplicationApi;
  bookingApi?: Account.BookingApi;
  bpcApi?: Account.BpcApi;
  configurationApi?: Account.ConfigurationApi;
  clientstatsApi?: Account.ClientstatsApi;
  eyesonApi?: Account.EyesonApi;
  monitoringApi?: Account.MonitoringApi;
  productMaintentanceApi: Account.ProductMaintenanceApi;
}

// alias for easier importing
export interface IRequestArgs extends AjaxRequest {}
export interface IResponseArgs extends AjaxResponse {}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'HEAD';
export type HttpHeaders = {} | { [key: string]: string };
export type HttpQuery =
  | {}
  | {
      [key: string]:
        | string
        | number
        | null
        | boolean
        | Array<string | number | null | boolean>;
    };
export type HttpBody = any | FormData;

export interface IRequestOpts {
  path: string;
  method: HttpMethod;
  headers?: HttpHeaders;
  query?: HttpQuery;
  body?: HttpBody;
  responseType?: 'json' | 'blob' | 'arraybuffer' | 'text';
}

class Api {
  private static instance: Api | null = null;

  private static apiInstanceHolder: IApiInstanceHolder = {
    maintenanceApi: undefined,
    registrationApi: undefined,
    partnerApi: undefined,
    openIDConnectApi: undefined,
    selfServiceApi: undefined,
    serviceApi: undefined,
    defaultApi: undefined,
    businessaccountApi: undefined,
    merchantApi: undefined,
    cryptoApi: undefined,
    loginApi: undefined,
    customerTransactionApi: undefined,
    templatesApi: undefined,
    templatesApiIdent: undefined,
    transactionBoApi: undefined,
    transactionCustomerApi: undefined,
    openIdConnectAccount: undefined,
    permissionsApi: undefined,
    notificationApi: undefined,
    releaseApi: undefined,
    releaseApiIdent: undefined,
    productsApi: undefined,
    replicationApi: undefined,
    bookingApi: undefined,
    bpcApi: undefined,
    configurationApi: undefined,
    clientstatsApi: undefined,
    regForensicsApi: undefined,
    eyesonApi: undefined,
    monitoringApi: undefined,
    productMaintentanceApi: undefined,
    onfidoApi: undefined,
  };

  private requestLastInvoked: IInvokeTimer = {};

  accountConfiguration: Account.Configuration = new Account.Configuration({
    basePath: accountBasePath,
    defaultHeaders: {
      app_version: process.env.REACT_APP_BUILD_VERSION,
    },
  });
  identConfiguration: Ident.Configuration = new Ident.Configuration({
    basePath: identBasePath,
    defaultHeaders: {
      app_version: process.env.REACT_APP_BUILD_VERSION,
    },
  });

  static getInstance(): Api {
    if (Api.instance == null) {
      Api.instance = new Api();
      return Api.instance;
    } else {
      return Api.instance;
    }
  }

  logout(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.asyncRequest<VoidResponse>([], apis.OpenIDConnectApi, 'oauthLogoutGet')
        .catch((error: ApiError) => {
          //TODO: Determine what is returned here?!? No Promise returning, Log.error response is void
          return Log.error(Logs.API, error);
        })
        .finally(() => {
          OverlayHandler.terminate();
          Actions.userChanged(undefined);
          return resolve(true);
        });
    });
  }

  getAccountBasePath() {
    return accountBasePath;
  }

  getIdentBasePath() {
    return identBasePath;
  }

  getReportBasePath() {
    return reportBasePath;
  }

  private isOidcError(arg: any): arg is Ident.OidcError {
    if (arg == null) {
      return false;
    }

    return 'authParams' in arg;
  }

  async asyncRequest<T>(params: any, api: apis, fn: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.request(params, api, fn, (error: ApiError, response: T) => {
        if (response != null) {
          resolve(response);
        } else {
          reject(error);
        }
      });
    });
  }

  async reportRequest(
    params: {
      accept: ReportAcceptHeader;
      body: any;
    },
    fn: string,
  ): Promise<Blob> {
    const headers: {} | { [key: string]: string } = {
      'Content-Type': 'application/json',
      Accept: params.accept,
    };

    return this.ajaxRequest<Blob>({
      path: '/' + fn,
      method: 'POST',
      headers,
      body: params.body as any,
      responseType: 'blob',
    }).toPromise();
  }

  ajaxRequest = <T,>(requestOpts: IRequestOpts): Observable<T> =>
    this.rxjsRequest(this.createRequestArgs(requestOpts)).pipe(
      map(res => {
        if (res.status >= 200 && res.status < 300) {
          return res.response as T;
        }
        throw res;
      }),
    );

  rxjsRequest = (params: IRequestArgs): Observable<AjaxResponse> =>
    of(params).pipe(
      map(request => {
        return request;
      }),
      concatMap(args =>
        ajax(args).pipe(
          map(response => {
            return response;
          }),
        ),
      ),
    );

  queryString = (params: HttpQuery): string =>
    Object.keys(params)
      .map(key => {
        // @ts-ignore
        const value = params[key];
        return value instanceof Array
          ? value.map(val => `${encodeURI(key)}=${encodeURI(val)}`).join('&')
          : `${encodeURI(key)}=${encodeURI(value)}`;
      })
      .join('&');

  private createRequestArgs(requestOpts: IRequestOpts): IRequestArgs {
    let url = reportBasePath + requestOpts.path;
    if (requestOpts.query !== undefined && Object.keys(requestOpts.query).length !== 0) {
      // only add the queryString to the URL if there are query parameters.
      // this is done to avoid urls ending with a '?' character which buggy webservers
      // do not handle correctly sometimes.
      url += '?' + this.queryString(requestOpts.query);
    }

    return {
      url,
      method: requestOpts.method,
      headers: {
        ...requestOpts.headers,
        app_version: process.env.REACT_APP_BUILD_VERSION,
      },
      body:
        requestOpts.body instanceof FormData
          ? requestOpts.body
          : JSON.stringify(requestOpts.body),
      responseType: requestOpts.responseType || 'json',
    };
  }

  private setSessionStorage() {
    const lastPath = window.location.pathname.replace(
      process.env.REACT_APP_ROOT_PATH + '/',
      '',
    );
    let openedOverlays = OverlayHandler.getOverlayJson();
    const temp: [
      (
        | {
            key: string;
            data: {};
          }
        | undefined
      )?,
    ] = [];
    let userMenuSet = true;
    for (const i in openedOverlays) {
      if (openedOverlays[i]!!.key !== 'userMenu') {
        temp.push(openedOverlays[i]);
      } else {
        userMenuSet = false;
      }
    }

    openedOverlays = temp;
    if (userMenuSet) {
      InitComponentHandler.cleanUp('HeaderComponent');
    }
    window.sessionStorage.setItem('lastPath', lastPath);
    window.sessionStorage.setItem(
      'lastUser',
      UserDataStore.getUser() != null ? UserDataStore.getUser()!!.person_id.toString() : '',
    );
    window.sessionStorage.setItem('overlays', JSON.stringify(openedOverlays));
    InitComponentHandler.trigger();
    Actions.userChanged(undefined);
    OverlayHandler.terminate();
  }

  // FuncName may be used in future, do not remove for documentation purposes
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private isSecured(api: apis, funcName: string): boolean {
    // TODO: Remove ReportApi, when secured
    return api !== apis.OpenIDConnectApi && api !== apis.OpenIDConnectAccount;
  }

  private isNotDoubeClickPrevented(apiInterface: string): boolean {
    return noDoubeClickPrevention.indexOf(apiInterface) >= 0;
  }

  async request(
    params: any,
    api: apis,
    fn: string,
    callback: (error: ApiError, response: any) => void,
  ) {
    if (callback == null) {
      Log.error(Logs.API, 'No callback given for api request');
      return;
    }
    if (api == null) {
      const e: ApiError = {
        status: 999,
        statusText: 'No api given, aborting request',
      };
      Log.error(Logs.API, e.statusText);
      callback(e, null);
    }
    if (fn == null) {
      const e: ApiError = {
        status: 999,
        statusText: 'No api function given, aborting request',
      };
      Log.error(Logs.API, e.statusText);
      callback(e, null);
    }

    // When this particular method was invoked in the last n milliseconds, abort request
    const apiName: string = api.toString();
    if (
      this.requestLastInvoked[apiName] != null &&
      this.requestLastInvoked[apiName][fn] != null
    ) {
      if (
        this.requestLastInvoked[apiName][fn] >= Date.now() - 500 &&
        !this.isNotDoubeClickPrevented(fn)
      ) {
        callback(
          {
            status: 999,
            statusText: 'Method ' + fn + ' invoked too fast, aborting',
          },
          undefined,
        );
        return;
      }
    }
    this.requestLastInvoked[apiName] = {
      [fn]: Date.now(),
    };

    const funcName: string = fn;
    Log.debug(Logs.API, 'Preparing request to ' + apiNames[api.valueOf()] + '.' + funcName);

    const call = (error: ApiError | undefined, response: any) => {
      if (error != null) {
        if (error.status === 401) {
          Log.debug(Logs.API, 'Api returned 401 -> Needs to be authenticated');
          this.setSessionStorage();
          return;
        }
      } else if (response == null) {
        error = {
          status: 999,
          statusText: 'An unexpected error occured',
        };
      } else {
        error = {
          status: -1,
          statusText: '',
        };
      }
      if (callback != null) {
        callback(error, response);
      }
    };

    const apiInstance: IdentApis | AccountApis | null = this.makeInstance(api);

    if (
      this.isSecured(api, funcName) &&
      Object.keys(apiInstance).indexOf('operationToOperationId') >= 0 &&
      funcName !== 'personSearch' &&
      funcName !== 'personAccountsPersonIdGet' &&
      funcName !== 'accountAccountNumberDetailGet' &&
      funcName !== 'accountAccountNumberBalanceGet' &&
      funcName !== 'servicePersonDocumentGet'
    ) {
      const instance: IOperationIdMap = apiInstance as IOperationIdMap;
      const opIds = instance.operationToOperationId;
      const permission: [string, string] | undefined = Object.entries(opIds).find(
        ([key]: [string, string]) => {
          return key === funcName;
        },
      );

      if (permission != null && !PermissionStore.hasPermission(permission[1])) {
        const e: ApiError = {
          status: 999,
          statusText: 'Missing permission ' + permission + ' for this request',
        };
        Log.error(Logs.API, e);
        call(e, undefined);
        return;
      }
    }

    this.performRequest(apiInstance, funcName, params, call);
  }

  genericRequest<T>(
    urlPath: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    headers: { [key: string]: string },
    body?: Object,
    query?: Account.HttpQuery,
    ident?: boolean,
    fileType?: FileType | null,
  ): Promise<T> {
    return new Promise<T>(resolve => {
      let queryParams: string = '';
      if (query != null) {
        queryParams =
          '?' +
          Object.keys(query)
            .map(key => {
              // @ts-ignore
              const value = query[key];
              return value instanceof Array
                ? value.map(val => `${encodeURI(key)}=${encodeURI(val)}`).join('&')
                : `${encodeURI(key)}=${encodeURI(value)}`;
            })
            .join('&');
      }
      if (fileType != null) {
        fetch(
          ident === true
            ? this.identConfiguration.basePath + urlPath + queryParams
            : this.accountConfiguration.basePath + urlPath + queryParams,
          {
            method: method,
            headers: headers,
          },
        )
          .then(res => {
            if (res.status < 200 || res.status > 300) {
              return resolve(res.json() as any);
            } else {
              res.blob().then(blob => {
                const file = window.URL.createObjectURL(blob);
                const anchor = document.body.appendChild(document.createElement('a'));
                anchor.href = file;
                anchor.download = 'report.' + fileType;
                anchor.click();
                return resolve({ success: true } as any);
              });
            }
          })
          .catch((error: any) => {
            return resolve(error);
          });
      } else {
        const requestParams: AjaxRequest = {
          url:
            ident === true
              ? this.identConfiguration.basePath + urlPath + queryParams
              : this.accountConfiguration.basePath + urlPath + queryParams,
          method: method,
          headers: headers,
          body: body,
          crossDomain: true,
          responseType: headers.response_type != null ? headers.response_type : 'json',
        };
        ajax(requestParams)
          .toPromise()
          .then(response => {
            Actions.updateLastActivity();
            return resolve(response.response as T);
          })
          .catch(error => {
            return resolve(error);
          });
      }
    });
  }

  private makeInstance(api: apis): IdentApis | AccountApis {
    switch (api) {
      case apis.RegistrationApi:
        if (Api.apiInstanceHolder.registrationApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Ident.RegistrationApi');
          Log.debug(Logs.API, 'Using configuration:', this.identConfiguration);
          Api.apiInstanceHolder.registrationApi = new Ident.RegistrationApi(
            this.identConfiguration,
          );
        }
        return Api.apiInstanceHolder.registrationApi;
      default:
      case apis.MaintenanceApi:
        if (Api.apiInstanceHolder.maintenanceApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Ident.MaintenanceApi');
          Log.debug(Logs.API, 'Using configuration:', this.identConfiguration);
          Api.apiInstanceHolder.maintenanceApi = new Ident.MaintenanceApi(
            this.identConfiguration,
          );
        }
        return Api.apiInstanceHolder.maintenanceApi;
      case apis.PartnerApi:
        if (Api.apiInstanceHolder.partnerApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Ident.PartnerApi');
          Log.debug(Logs.API, 'Using configuration:', this.identConfiguration);
          Api.apiInstanceHolder.partnerApi = new Ident.PartnerApi(this.identConfiguration);
        }
        return Api.apiInstanceHolder.partnerApi;
      case apis.OpenIDConnectApi:
        if (Api.apiInstanceHolder.openIDConnectApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Ident.OpenIDConnectApi');
          Log.debug(Logs.API, 'Using configuration:', this.identConfiguration);
          Api.apiInstanceHolder.openIDConnectApi = new Ident.OpenIDConnectApi(
            this.identConfiguration,
          );
        }
        return Api.apiInstanceHolder.openIDConnectApi;
      case apis.PermissionsApi:
        if (Api.apiInstanceHolder.permissionsApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Ident.PermissionsApi');
          Log.debug(Logs.API, 'Using configuration:', this.identConfiguration);
          Api.apiInstanceHolder.permissionsApi = new Ident.PermissionsApi(
            this.identConfiguration,
          );
        }
        return Api.apiInstanceHolder.permissionsApi;
      case apis.SelfServiceApi:
        if (Api.apiInstanceHolder.selfServiceApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Ident.SelfServiceApi');
          Log.debug(Logs.API, 'Using configuration:', this.identConfiguration);
          Api.apiInstanceHolder.selfServiceApi = new Ident.SelfServiceApi(
            this.identConfiguration,
          );
        }
        return Api.apiInstanceHolder.selfServiceApi;
      case apis.ServiceApi:
        if (Api.apiInstanceHolder.serviceApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Ident.ServiceApi');
          Log.debug(Logs.API, 'Using configuration:', this.identConfiguration);
          Api.apiInstanceHolder.serviceApi = new Ident.ServiceApi(this.identConfiguration);
        }
        return Api.apiInstanceHolder.serviceApi;
      case apis.DefaultApi:
        if (Api.apiInstanceHolder.defaultApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.DefaultApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.defaultApi = new Account.DefaultApi(this.accountConfiguration);
        }
        return Api.apiInstanceHolder.defaultApi;
      case apis.BusinessaccountApi:
        if (Api.apiInstanceHolder.businessaccountApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.BusinessaccountApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.businessaccountApi = new Account.BusinessaccountApi(
            this.accountConfiguration,
          );
        }
        return Api.apiInstanceHolder.businessaccountApi;
      case apis.MerchantApi:
        if (Api.apiInstanceHolder.merchantApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.MerchantApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.merchantApi = new Account.MerchantApi(
            this.accountConfiguration,
          );
        }
        return Api.apiInstanceHolder.merchantApi;
      case apis.CryptoApi:
        if (Api.apiInstanceHolder.cryptoApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.CryptoApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.cryptoApi = new Account.CryptoApi(this.accountConfiguration);
        }
        return Api.apiInstanceHolder.cryptoApi;
      case apis.OnfidoApi:
        if (Api.apiInstanceHolder.cryptoApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Ident.OnfidoApi');
          Log.debug(Logs.API, 'Using configuration:', this.identConfiguration);
          Api.apiInstanceHolder.onfidoApi = new Ident.OnfidoApi(this.identConfiguration);
        }
        return Api.apiInstanceHolder.onfidoApi;
      case apis.LoginApi:
        if (Api.apiInstanceHolder.loginApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.LoginApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.loginApi = new Account.LoginApi(this.accountConfiguration);
        }
        return Api.apiInstanceHolder.loginApi;
      case apis.CustomerTransactionApi:
        if (Api.apiInstanceHolder.customerTransactionApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.CustomerTransactionApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.customerTransactionApi = new Account.CustomerTransactionApi(
            this.accountConfiguration,
          );
        }
        return Api.apiInstanceHolder.customerTransactionApi;
      case apis.TemplatesApi:
        if (Api.apiInstanceHolder.templatesApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.TemplatesApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.templatesApi = new Account.TemplatesApi(
            this.accountConfiguration,
          );
        }
        return Api.apiInstanceHolder.templatesApi;
      case apis.TemplatesApiDient:
        if (Api.apiInstanceHolder.templatesApiIdent == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.TemplatesApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.templatesApiIdent = new Ident.TemplatesApi(
            this.identConfiguration,
          );
        }
        return Api.apiInstanceHolder.templatesApiIdent;
      case apis.TransactionBoApi:
        if (Api.apiInstanceHolder.transactionBoApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Ident.TransactionApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.transactionBoApi = new Account.TransactionBoApi(
            this.accountConfiguration,
          );
        }
        return Api.apiInstanceHolder.transactionBoApi;
      case apis.TransactionCustomerApi:
        if (Api.apiInstanceHolder.transactionCustomerApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.TransactionApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.transactionCustomerApi = new Account.TransactionCustomerApi(
            this.accountConfiguration,
          );
        }
        return Api.apiInstanceHolder.transactionCustomerApi;
      case apis.OpenIDConnectAccount:
        if (Api.apiInstanceHolder.openIdConnectAccount == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.OpenIDConnectApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.openIdConnectAccount = new Account.OpenIDConnectApi(
            this.accountConfiguration,
          );
        }
        return Api.apiInstanceHolder.openIdConnectAccount;
      case apis.NotificationApi:
        if (Api.apiInstanceHolder.notificationApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.NotificationApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.notificationApi = new Account.NotificationApi(
            this.accountConfiguration,
          );
        }
        return Api.apiInstanceHolder.notificationApi;
      case apis.ReleaseApi:
        if (Api.apiInstanceHolder.releaseApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.ReleaseApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.releaseApi = new Account.ReleasesApi(
            this.accountConfiguration,
          );
        }
        return Api.apiInstanceHolder.releaseApi;
      case apis.ReleaseApiIdent:
        if (Api.apiInstanceHolder.releaseApiIdent == null) {
          Log.debug(Logs.API, 'Creating new instance of Ident.ReleaseApi');
          Log.debug(Logs.API, 'Using configuration:', this.identConfiguration);
          Api.apiInstanceHolder.releaseApiIdent = new Ident.ReleasesApi(
            this.identConfiguration,
          );
        }
        return Api.apiInstanceHolder.releaseApiIdent;
      case apis.ProductApi:
        if (Api.apiInstanceHolder.productsApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Ident.productsApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.productsApi = new Account.ProductsApi(
            this.accountConfiguration,
          );
        }
        return Api.apiInstanceHolder.productsApi;
      case apis.ReplicationApi:
        if (Api.apiInstanceHolder.replicationApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Ident.ReplicationApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.replicationApi = new Account.ReplicationApi(
            this.accountConfiguration,
          );
        }
        return Api.apiInstanceHolder.replicationApi;
      case apis.BookingApi:
        if (Api.apiInstanceHolder.bookingApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.BookingApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.bookingApi = new Account.BookingApi(this.accountConfiguration);
        }
        return Api.apiInstanceHolder.bookingApi;
      case apis.BPCApi:
        if (Api.apiInstanceHolder.bpcApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.bpcApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.bpcApi = new Account.BpcApi(this.accountConfiguration);
        }
        return Api.apiInstanceHolder.bpcApi;
      case apis.ConfigurationApi:
        if (Api.apiInstanceHolder.configurationApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.configurationApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.configurationApi = new Account.ConfigurationApi(
            this.accountConfiguration,
          );
        }
        return Api.apiInstanceHolder.configurationApi;
      case apis.ClientstatsApi:
        if (Api.apiInstanceHolder.clientstatsApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.clientstatsApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.clientstatsApi = new Account.ClientstatsApi(
            this.accountConfiguration,
          );
        }
        return Api.apiInstanceHolder.clientstatsApi;
      case apis.RegForensicsApi:
        if (Api.apiInstanceHolder.regForensicsApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Ident.ApiRegulaforensicsComApi');
          Log.debug(Logs.API, 'Using configuration:', this.identConfiguration);
          Api.apiInstanceHolder.regForensicsApi = new Ident.RegulaforensicsApi(
            this.identConfiguration,
          );
        }
        return Api.apiInstanceHolder.regForensicsApi;
      case apis.EyesonApi:
        if (Api.apiInstanceHolder.eyesonApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Ident.Eyesonapi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.eyesonApi = new Account.EyesonApi(this.accountConfiguration);
        }
        return Api.apiInstanceHolder.eyesonApi;
      case apis.MonitoringApi:
        if (Api.apiInstanceHolder.monitoringApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.MonitoringApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.monitoringApi = new Account.MonitoringApi(
            this.accountConfiguration,
          );
        }
        return Api.apiInstanceHolder.monitoringApi;
      case apis.ProductMaintentanceApi:
        if (Api.apiInstanceHolder.productMaintentanceApi == null) {
          Log.debug(Logs.API, 'Creating new instance of Account.ProductMaintentanceApi');
          Log.debug(Logs.API, 'Using configuration:', this.accountConfiguration);
          Api.apiInstanceHolder.productMaintentanceApi = new Account.ProductMaintenanceApi(
            this.accountConfiguration,
          );
        }
        return Api.apiInstanceHolder.productMaintentanceApi;
    }
  }

  private isIdentApi(apiInstance: IdentApis | AccountApis): apiInstance is IdentApis {
    return (
      apiInstance instanceof Ident.MaintenanceApi ||
      apiInstance instanceof Ident.RegistrationApi ||
      apiInstance instanceof Ident.PartnerApi ||
      apiInstance instanceof Ident.OpenIDConnectApi
    );
  }

  private performRequest(
    apiInstance: IdentApis | AccountApis,
    funcName: any,
    params: any,
    callback: (error: ApiError | undefined, response: any) => void,
  ) {
    // @ts-ignore
    const fn: any = apiInstance[funcName];
    if (typeof fn !== 'function') {
      const error: ApiError = {
        status: 500,
        statusText: funcName + ' is not a valid function name in ' + apiInstance.toString(),
      };
      Log.error(Logs.API, error.statusText);
      callback(error, null);
      return;
    }

    Log.debug(Logs.API, 'Calling Api');
    let result: any = fn.call(apiInstance, params);

    if (result instanceof Observable) {
      result = result.toPromise();
    }

    result
      .then((response: any, data: any) => {
        Log.debug(Logs.API, 'Entered success of call promise', { response, data });
        Actions.updateLastActivity();
        callback(undefined, response == null ? VoidResponse : response);
      })
      .catch((error: any) => {
        Actions.updateLastActivity();
        // If not authenticated the api always returns 401
        if (error.response != null && error.response.connection != null) {
          callback(undefined, error.response);
        } else {
          Log.debug(Logs.API, 'Api request ended in error', error);
          const e: ApiError = {
            status: error == null || error.status == null ? 0 : error.status,
            statusText:
              error == null || error.response == null
                ? 'An error occured calling ' +
                  (this.isIdentApi(apiInstance) ? 'Ident' : 'Account') +
                  '.' +
                  funcName
                : translate('backend.' + error.response.error_code, error.response.error_text),
            response: error,
          };
          callback(e, null);
        }
      });
  }
}

export { Ident, Account };
export const api = Api.getInstance();
