import React, { ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';
import ActivityIndicator from '../activityIndicator';
import { ITheme } from '../../../common/theme';
import Button from '@material-ui/core/Button';

interface IButtonProps {
  loading?: boolean;
  disableSpinner?: boolean;
  onClick?: (event: SyntheticEvent) => void;
  children?: ReactNode;
  theme?: ITheme;
  disabled?: boolean;
  id?: string;
  style?: React.CSSProperties;
  clicked?: boolean;
}
interface IButtonState {
  loading?: boolean;
  style?: React.CSSProperties;
  clicked?: boolean;
}

export default class ButtonOk extends React.Component<IButtonProps, IButtonState> {
  constructor(props: IButtonProps) {
    super(props);
    this.state = {
      loading: false,
      style: props.style,
      clicked: props.clicked != null ? props.clicked : false,
    };
    this.onClick = this.onClick.bind(this);
  }

  static getDerivedStateFromProps(
    props: IButtonProps,
    state: IButtonState,
  ): IButtonState | null {
    if (props.style !== state.style) {
      return {
        style: props.style,
      };
    }

    return null;
  }

  onClick(event: SyntheticEvent): void {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();

    if (this.props.disabled !== true) {
      this.setState({
        loading: true,
        clicked: true,
      });
      if (this.props.onClick != null) {
        this.props.onClick(event);
      }
    }
  }

  render() {
    return (
      <StyledButton
        onClick={this.onClick}
        style={this.state.style}
        disabled={this.props.disabled}
        id={this.props.id}
      >
        {this.state.loading === true && this.props.disableSpinner !== true ? (
          <ActivityIndicator
            animating={this.state.loading}
            size="small"
            color={this.props.theme == null ? undefined : this.props.theme.Button.color}
          />
        ) : (
          this.props.children
        )}
      </StyledButton>
    );
  }
}

const StyledButton = styled(Button)<{ disabled?: boolean }>`
  && {
    height: 46px;
    width: ${props => props.theme.Button.width};

    border: none;
    border-radius: 4px;
    box-shadow: ${props => props.theme.Box.boxShadow};
    background-color: ${props =>
      props.disabled
        ? props.theme.ButtonDisabled.backgroundColor
        : props.theme.Button.backgroundColor};

    color: ${props =>
      props.disabled ? props.theme.ButtonDisabled.color : props.theme.Button.color};
    text-transform: uppercase;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.3px;
    &:hover {
      background-color: ${props => props.theme.Button.hover};
    }
  }
`;
