import React, { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

export interface IIconPickerItemProps {
  children?: ReactNode;
  index?: number;
  onPress?: (itemValue: string | number, index: number) => void;
  pickerStyle?: CSSProperties;
  value: string | number;
}

export interface IIconPickerItemState {}

export default class IconPickerItem extends React.Component<
  IIconPickerItemProps,
  IIconPickerItemState
> {
  constructor(props: IIconPickerItemProps) {
    super(props);

    this.state = {};

    this.selectItem = this.selectItem.bind(this);
  }

  private selectItem(): void {
    if (this.props.onPress != null) {
      this.props.onPress(this.props.value, this.props.index == null ? -1 : this.props.index);
    }
  }

  render() {
    return (
      <StyledContainer style={this.props.pickerStyle as any}>
        {this.props.children}
      </StyledContainer>
    );
  }
}

const StyledContainer = styled.div``;
