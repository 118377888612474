import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';
import { Ident } from '../../api/index';
import { IItfAccount } from '../../types';

class IUserDataStore extends EventEmitter {
  header: string = '';
  user?: Ident.Person = undefined;
  roles?: Array<string>;
  menuComponent: number = 0;
  accounts?: {
    accounts: IItfAccount[];
    currentAccount?: IItfAccount;
  } = undefined;

  private static instance: IUserDataStore | null;

  constructor() {
    super();

    IDispatcher.register(this.registerActions.bind(this));

    this.setHeader = this.setHeader.bind(this);
  }

  static getInstance(): IUserDataStore {
    if (IUserDataStore.instance == null) {
      IUserDataStore.instance = new IUserDataStore();
    }
    return IUserDataStore.instance;
  }

  clearWithoutNofiction() {
    this.accounts = undefined;
    this.header = '';
    this.menuComponent = 0;
    this.user = undefined;
    window.sessionStorage.clear();
  }

  registerActions(action: any) {
    switch (action.actionType) {
      default:
        break;
      case Constants.CLEAR_ALL:
        this.accounts = undefined;
        this.header = '';
        this.menuComponent = 0;
        this.user = undefined;
        window.sessionStorage.clear();
        this.emit(CHANGE_EVENT);
        break;
      case Constants.HEADER_CHANGED:
        this.setHeader(action.header);
        this.emit(CHANGE_EVENT);
        break;
      case Constants.USER_CHANGED:
        this.setUser(action.user);
        this.emit(CHANGE_EVENT);
        break;
      case Constants.MENU_CHANGED:
        this.setMenuComponent(action.component);
        this.emit(CHANGE_EVENT);
        break;
      case Constants.SET_ROLES:
        this.setRoles(action.roles);
        this.emit(CHANGE_EVENT);
        break;
    }
  }

  setAccounts(accounts: { accounts: IItfAccount[]; currentAccount?: IItfAccount }) {
    this.accounts = accounts;
  }

  getAccounts(): { accounts: IItfAccount[]; currentAccount?: IItfAccount } | undefined {
    return this.accounts;
  }
  setHeader(header: string) {
    this.header = header;
  }

  getHeader() {
    return this.header;
  }

  setUser(user: Ident.Person | undefined) {
    this.user = user;
    if (user == null) {
      window.sessionStorage.removeItem('user');
    } else {
      window.sessionStorage.setItem('user', JSON.stringify(user));
    }
  }

  setRoles(roles: Array<string>) {
    this.roles = roles;
  }

  getRoles(): Array<string> {
    return this.roles != null ? this.roles : [];
  }

  getUser(): Ident.Person | undefined {
    if (this.user == null) {
      const userString: string | null = window.sessionStorage.getItem('user');
      if (userString != null && userString !== '') {
        this.user = JSON.parse(userString);
      }
    }
    return this.user;
  }

  getMenuComponent(): number {
    return this.menuComponent;
  }
  setMenuComponent(comp: number) {
    this.menuComponent = comp;
  }
  addChangeListener(callback: any) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback: any) {
    this.removeListener(CHANGE_EVENT, callback);
  }
}

export const UserDataStore = IUserDataStore.getInstance();
