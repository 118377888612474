import React, { ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { IPagingStore } from '../../logic/flux/stores/pagingStore';
import { Config } from '../../config';
import { Icons } from '../../images';
import { PagingManager } from '../../content/dashboard/content/transfer/pagingManager';
import { identity } from 'rxjs';

interface IProps {
  numberOfEntries: number;
  rowsPerPage?: number;
  index?: number;
  indexReset?: boolean;
  notification?: (message: any) => void;
  resetTransactionscallback?: (
    data: any,
    updateParams?: { updateCallback: () => void; firstOfPage: number },
  ) => void;
  offset?: number;
  id?: string;
  resetIndex?: boolean;
}

interface IState {
  numberOfEntries: number;
  rowsPerPage: number;
  index: number;
  firstOfPage: number;
  lastOfPage: number;
  offset?: number;
  blocked?: boolean;
}

const NUMBERS_BEFORE_DOT = Config.paging.numbersBeforeDot;
const NUMBERS_BETWEEN_DOT = Config.paging.numbersBetweenDot;
const NUMBERS_AFTER_DOT = Config.paging.numbersAfterDot;

export class PagingComponent extends React.Component<IProps, IState> {
  private defaultRowsPerPage: number = Config.table.rowserPerPageDefault;
  private defaultIndex: number = Config.paging.defaultIndex;
  private maxIndex: number;
  private externalNofication: any = null;

  private manager?: PagingManager =
    this.props.resetTransactionscallback != null
      ? new PagingManager(this.props.resetTransactionscallback)
      : undefined;

  constructor(props: IProps) {
    super(props);
    const rowsPerPage =
      this.props.rowsPerPage != null ? this.props.rowsPerPage : this.defaultRowsPerPage;
    const index = this.props.index != null ? this.props.index : this.defaultIndex;
    let lastOfPage = index * rowsPerPage;
    if (lastOfPage > this.props.numberOfEntries) {
      lastOfPage = this.props.numberOfEntries;
    }

    this.state = {
      rowsPerPage: rowsPerPage,
      index: index,
      firstOfPage: (index - 1) * rowsPerPage + 1,
      lastOfPage: lastOfPage,
      numberOfEntries: props.numberOfEntries,
      offset: props.offset != null ? props.offset : 0,
      blocked: false,
    };

    this.maxIndex = this.state.numberOfEntries / rowsPerPage;
    this.getPageNumbers = this.getPageNumbers.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.onChangePaging = this.onChangePaging.bind(this);
  }

  componentWillReceiveProps(props: IProps) {
    const blocked =
      props.numberOfEntries < Config.table.transactionLimit && this.manager != null;
    if (this.manager != null && props.indexReset === true) {
      this.manager.reset();
      this.setState({
        numberOfEntries: props.numberOfEntries,
        blocked: blocked,
        index: 1,
        offset: 0,
      });
      return;
    }
    this.setState({
      numberOfEntries: props.numberOfEntries,
      blocked: blocked,
    });
  }
  // kinda messy one. Since we have developed several types of paging over time, this needs a cleanup as soon as the backend is consistently structured with paging
  // what we do here is check if we need to do another request to the backend to get the next set of data
  // if values manager/offset are not set, we do not have backend-paging
  // if they are set the pagingmanager calcualates the necessary values and we trigger the callback to get the next set of data with new offset and notify the table about the change of the page to display
  // this needs a rework!!
  // this is all so messy because we need an external-paging component, that is not bound to the table
  // as soon as the backend has paging in every relevant interface, we can bind this fixed to the table and dont need to communicate via the stores anymore, which will clean this up a lot
  next() {
    if (this.state.index < this.maxIndex) {
      let newIndex = this.state.index + 1;
      const firstOfPage = (newIndex - 1) * this.state.rowsPerPage + 1;
      let lastOfPage = newIndex * this.state.rowsPerPage;
      if (lastOfPage > this.state.numberOfEntries) {
        lastOfPage = this.state.numberOfEntries;
      }
      let offset = this.state.offset;
      let vals = { offset: 0, changeValue: 0 };
      if (this.manager != null) {
        vals = this.manager.setCurrent(newIndex);
        offset = vals.offset;
        newIndex = newIndex - vals.changeValue;
      }
      const offsetChanged = offset != this.state.offset;
      const deltaOffset =
        offset != null && this.state.offset != null ? Math.abs(this.state.offset - offset) : 0;

      this.setState(
        {
          index: newIndex,
          firstOfPage: firstOfPage,
          lastOfPage: lastOfPage,
          offset: offset,
        },
        () => {
          if (
            offset != null &&
            offsetChanged &&
            this.props.resetTransactionscallback != null &&
            this.state.blocked !== true
          ) {
            this.props.resetTransactionscallback({
              amount: Config.table.transactionLimit,
              offset: offset * Config.table.rowserPerPageDefault,
            });
          }
        },
      );
      if (this.props.notification != null) {
        this.props.notification({
          firstOfPage: firstOfPage,
          lastOfPage: lastOfPage,
          offset: offset,
        });
      } else if (this.externalNofication != null) {
        this.externalNofication({
          firstOfPage:
            firstOfPage -
            (offsetChanged && offset != null
              ? deltaOffset * Config.table.rowserPerPageDefault
              : 0),
          lastOfPage:
            lastOfPage -
            (offsetChanged && offset != null
              ? deltaOffset * Config.table.rowserPerPageDefault
              : 0),
          offset: offset,
        });
      }
    }
  }

  componentDidMount(): void {
    IPagingStore.addChangeListener(this.onChangePaging);
  }

  componentWillUnmount(): void {
    IPagingStore.removeChangeListener(this.onChangePaging);
  }

  private getPageNumbers(currentPage: number): Array<ReactElement> {
    const pages: number = Math.ceil(this.state.numberOfEntries / this.state.rowsPerPage);
    const normalizedCurrentPage = currentPage - 1;

    const pageButtons: Array<ReactElement> = [];
    if (NUMBERS_BEFORE_DOT + NUMBERS_BETWEEN_DOT + NUMBERS_AFTER_DOT >= pages) {
      for (let i = 0; i < pages; i++) {
        pageButtons.push(
          <span
            key={'paginator_' + i}
            className={normalizedCurrentPage === i ? 'active' : ''}
            onClick={(event: SyntheticEvent) => {
              event.stopPropagation();
              this.selectPage(i);
            }}
          >
            {i + 1 + (this.state.offset != null ? this.state.offset : 0)}
          </span>,
        );
      }
    } else {
      let start = 0;
      let end = start + NUMBERS_BEFORE_DOT;
      for (let i = start; i < end; i++) {
        pageButtons.push(
          <span
            key={'paginator_' + i}
            className={normalizedCurrentPage === i ? 'active' : ''}
            onClick={(event: SyntheticEvent) => {
              event.stopPropagation();
              this.selectPage(i);
            }}
          >
            {i + 1 + (this.state.offset != null ? this.state.offset : 0)}
          </span>,
        );
      }

      pageButtons.push(<span key={'paginator_dots_before'}>...</span>);

      start = Math.max(
        NUMBERS_BEFORE_DOT,
        Math.min(
          normalizedCurrentPage - Math.floor(NUMBERS_BETWEEN_DOT * 0.5),
          pages - NUMBERS_AFTER_DOT - NUMBERS_BETWEEN_DOT,
        ),
      );
      end = Math.min(start + NUMBERS_BETWEEN_DOT, pages - NUMBERS_AFTER_DOT);
      for (let i = start; i < end; i++) {
        pageButtons.push(
          <span
            key={'paginator_' + i}
            className={normalizedCurrentPage === i ? 'active' : ''}
            onClick={(event: SyntheticEvent) => {
              event.stopPropagation();
              this.selectPage(i);
            }}
          >
            {i + 1 + (this.state.offset != null ? this.state.offset : 0)}
          </span>,
        );
      }

      pageButtons.push(<span key={'paginator_dots_after'}>...</span>);

      start = pages - NUMBERS_AFTER_DOT;
      end = pages;
      for (let i = start; i < end; i++) {
        pageButtons.push(
          <span
            key={'paginator_' + i}
            className={normalizedCurrentPage === i ? 'active' : ''}
            onClick={(event: SyntheticEvent) => {
              event.stopPropagation();
              this.selectPage(i);
            }}
          >
            {i + 1 + (this.state.offset != null ? this.state.offset : 0)}
          </span>,
        );
      }
    }
    if (
      this.state.blocked === false &&
      this.state.numberOfEntries === Config.table.transactionLimit
    ) {
      pageButtons.push(
        <span
          onClick={(event: SyntheticEvent) => {
            event.stopPropagation();
            this.selectPage(this.state.lastOfPage - 1);
          }}
          key={'paginator_dots_next'}
        >
          ...
        </span>,
      );
    }
    return pageButtons;
  }

  onChangePaging() {
    const pagination = IPagingStore.getPages(this.props.id);
    if (pagination == null) {
      return;
    }
    const entries = pagination.amount != null ? pagination.amount : this.state.numberOfEntries;
    this.setState({
      rowsPerPage: pagination.rowsPP,
      firstOfPage: pagination.first,
      lastOfPage: pagination.last > entries ? entries : pagination.last,
      numberOfEntries: entries,
      index:
        this.manager != null
          ? this.state.index
          : Math.ceil(pagination.first / pagination.rowsPP),
    });
    this.maxIndex = entries / pagination.rowsPP;

    if (this.props.notification == null) {
      this.externalNofication = IPagingStore.getNofitication();
    }
  }

  previous() {
    if (this.state.index >= 1) {
      let newIndex = this.state.index - 1;
      let firstOfPage = (newIndex - 1) * this.state.rowsPerPage + 1;
      if (firstOfPage < 1) {
        firstOfPage = 1;
      }
      const lastOfPage = newIndex * this.state.rowsPerPage;
      let offset = this.state.offset;
      let vals = { offset: 0, changeValue: 0 };
      if (this.manager != null) {
        vals = this.manager.setCurrent(newIndex);
        offset = vals.offset;
        newIndex = newIndex + vals.changeValue;
      }
      const offsetChanged = offset != this.state.offset;
      const deltaOffset =
        offset != null && this.state.offset != null ? Math.abs(this.state.offset - offset) : 0;
      this.setState(
        {
          index: newIndex,
          firstOfPage: firstOfPage,
          lastOfPage: lastOfPage,
          offset: offset,
        },
        () => {
          if (
            offset != null &&
            offsetChanged &&
            this.props.resetTransactionscallback != null
          ) {
            this.props.resetTransactionscallback({
              amount: Config.table.transactionLimit,
              offset: offset * Config.table.rowserPerPageDefault,
            });
          }
        },
      );
      if (this.props.notification != null) {
        this.props.notification({
          firstOfPage: firstOfPage,
          lastOfPage: lastOfPage,
          offset: offset,
        });
      } else if (this.externalNofication != null) {
        this.externalNofication({
          firstOfPage:
            firstOfPage +
            (offsetChanged && offset != null
              ? deltaOffset * Config.table.rowserPerPageDefault
              : 0),
          lastOfPage:
            lastOfPage +
            (offsetChanged && offset != null
              ? deltaOffset * Config.table.rowserPerPageDefault
              : 0),
          offset: offset,
        });
      }
    }
  }

  private selectPage(index: number): void {
    const pages: number = Math.ceil(this.state.numberOfEntries / this.state.rowsPerPage);
    if (index < 0 || index > pages) {
      return;
    }
    let offset = this.state.offset;
    let vals = { offset: 0, changeValue: 0 };
    const firstOfPage: number = index * this.state.rowsPerPage + 1;
    const lastOfPage: number = index * this.state.rowsPerPage + this.state.rowsPerPage;
    if (this.manager != null) {
      vals = this.manager.setCurrent(index, this.state.blocked);
      offset = vals.offset;
    }
    const offsetChanged = offset != this.state.offset;
    index =
      index +
      (this.state.offset != null && offset != null && offset < this.state.offset
        ? +vals.changeValue
        : -vals.changeValue) +
      1;
    const right = this.state.offset != null && offset != null && this.state.offset < offset;
    const deltaOffset =
      offset != null && this.state.offset != null ? this.state.offset - offset : 0;
    this.setState(
      {
        index: index,
        firstOfPage: firstOfPage,
        lastOfPage: lastOfPage,
        offset: offset,
      },
      () => {
        if (
          offset != null &&
          offsetChanged &&
          this.props.resetTransactionscallback != null &&
          (!right || this.state.blocked !== true)
        ) {
          this.props.resetTransactionscallback({
            amount: Config.table.transactionLimit,
            offset: offset * Config.table.rowserPerPageDefault,
          });
        }
      },
    );
    if (this.props.notification != null) {
      this.props.notification({
        index: index,
        firstOfPage: firstOfPage,
        lastOfPage: lastOfPage,
        offset: offset,
      });
    } else if (this.externalNofication != null) {
      this.externalNofication({
        index: index,
        firstOfPage:
          firstOfPage +
          (offsetChanged && offset != null
            ? deltaOffset * Config.table.rowserPerPageDefault
            : 0),
        lastOfPage:
          lastOfPage +
          (offsetChanged && offset != null
            ? deltaOffset * Config.table.rowserPerPageDefault
            : 0),
        offset: offset,
      });
    }
  }

  render() {
    return (
      <StyledView>
        <span
          onClick={(event: SyntheticEvent) => {
            event.stopPropagation();
            this.previous();
          }}
        >
          {Icons.pagingArrowLeft()}
        </span>

        {this.getPageNumbers(this.state.index)}

        <span
          onClick={(event: SyntheticEvent) => {
            event.stopPropagation();
            this.next();
          }}
        >
          {Icons.pagingArrowRight()}
        </span>
      </StyledView>
    );
  }
}

const StyledView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  & > span {
    cursor: pointer;
    width: 25px;
    height: 25px;
    background-color: transparent;
    line-height: 25px;
    text-align: center;
    font-size: 14px;
    border-radius: 4px;

    :hover {
      background-color: #eaeaea;
    }

    :active {
      background-color: ${props => props.theme.Global.primaryColor};
      color: white;
      font-weight: 500;
    }
  }

  & > span.active {
    background-color: ${props => props.theme.Global.primaryColor};
    color: white;
    font-weight: 500;

    :hover {
      background-color: ${props => props.theme.Global.primaryColor};
    }
  }

  div {
    margin-right: 10px;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    font-family: Roboto;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: ${props => props.theme.Global.lightFontColor};
  }

  svg {
    margin-right: 10px;
  }
`;
