import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { api, apis, ApiError, Account, VoidResponse } from '../../../../../logic/api';
import { Log, Logs } from '../../../../../logic/log';
import { evaluateErrorMessage } from '../../../../../logic/helper/Common';
import { translate } from '../../../../../common/language/translate';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import {
  ListEntry,
  MainField,
  NameField,
  SimpleHover,
  StyledSelect,
} from '../basicStyledComponents/customerDetails.css';
import { CheckBox } from '../../../../../components/atomiccompoents/form/checkbox';
import { IOption } from '../../../../../components/atomiccompoents/form';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import { Icons } from '../../../../../images';

const ProductTypeSelect = styled(StyledSelect)`
  width: 300px;
  font-size: 18px;
  font-weight: 500;
`;

interface IProps {
  currentAccount: string;
}

export const ProductsTypeComponent: FunctionComponent<IProps> = (props: IProps) => {
  const [types, setTypes] = useState<Array<Account.ProductType>>([]);
  const [selectedType, setSelectedType] = useState<Account.ProductType | undefined>();
  const [availableProductTypes, setAvailableProductTypes] = useState<
    Array<Account.ProductType>
  >();
  const [keyForLoadingSpinner, setKey] = useState(Math.floor(Math.random() * 10000000));

  function setProductType() {
    const req = {
      product_type_id: selectedType,
      merchant_account_number: props.currentAccount,
    };
    api
      .asyncRequest<VoidResponse>(req, apis.MerchantApi, 'merchantProductTypePut')
      .then(() => {
        MessageHandler.onSuccess(Reporter['person.merchant.productType.put']);
        getProductTypeData();
        setSelectedType(undefined);
        setKey(Math.floor(Math.random() * 10000000));
      })
      .catch((error: ApiError) => {
        MessageHandler.onError(
          Reporter['person.merchant.productType.put'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
      });
  }

  function DeleteProductType(product_type_id: number) {
    const req = {
      product_type_id: product_type_id,
      merchant_account_number: props.currentAccount,
    };
    api
      .asyncRequest<VoidResponse>(req, apis.MerchantApi, 'merchantProductTypeDelete')
      .then(() => {
        MessageHandler.onSuccess(Reporter['person.merchant.productType.delete']);
        getProductTypeData();
      })
      .catch((error: ApiError) => {
        MessageHandler.onError(
          Reporter['person.merchant.productType.delete'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
      });
  }

  function getProductTypeData() {
    const req: Account.MerchantProductTypeGetRequest = {
      merchant_account_number: props.currentAccount,
    };
    api
      .asyncRequest<Array<Account.ProductType>>(
        req,
        apis.MerchantApi,
        'merchantProductTypeGet',
      )
      .then((response: Array<Account.ProductType>) => {
        console.log(response);
        setTypes(response.length === 0 ? [] : response);
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
      });
  }

  function getAvailableProductTypes() {
    const req: Account.MerchantAvailableProductTypeGetRequest = {
      merchant_account_number: props.currentAccount,
    };
    api
      .asyncRequest<Array<Account.ProductType>>(
        req,
        apis.MerchantApi,
        'merchantAvailableProductTypeGet',
      )
      .then((response: Array<Account.ProductType>) => {
        setAvailableProductTypes(response instanceof VoidResponse ? [] : response);
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error.statusText);
      });
    setKey(Math.floor(Math.random() * 10000000));
  }

  function getProductTypeOptions(): Array<IOption> {
    const options: Array<IOption> = [];
    options.push({
      key: 'defaultOptionKey none',
      name: translate('customers.details.merchant.product_types'),
      value: undefined,
    });
    if (availableProductTypes == null) {
      return options;
    }
    for (const i of availableProductTypes) {
      options.push({
        key: 'defaultOptionKey ' + i.product_type_id,
        name: i.manufacturer_name ?? '',
        value: i.product_type_id,
      });
    }
    return options;
  }

  function generateList(): Array<ReactElement> {
    const out: Array<ReactElement> = [];
    if (types == null || types.length == null || types.length === 0) {
      out.push(<ListEntry>Nothing found</ListEntry>);
    } else {
      for (const o of types) {
        out.push(
          <MainField>
            <NameField>{o.product_type_id}</NameField>
            <NameField>{o.manufacturer_name}</NameField>
            <NameField>{o.commercial_name}</NameField>
            <SimpleHover
              style={{ width: '10%', display: 'flex' }}
              onClick={() => {
                DeleteProductType(o.product_type_id);
              }}
            >
              {Icons.del()}
            </SimpleHover>
          </MainField>,
        );
      }
    }
    out.push(
      <NameField style={{ display: 'flex', width: '95%', marginTop: '8px' }}>
        <div style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
          <ProductTypeSelect
            id="productType"
            options={getProductTypeOptions()}
            current={selectedType?.manufacturer_name ?? ''}
            notification={setProductTypeId}
          />
        </div>
        <div
          style={{
            width: '50%',
            display: 'flex',
            justifyContent: 'center',
            marginLeft: '16px',
          }}
        >
          <ButtonOk
            onClick={() => {
              setProductType();
            }}
            disabled={selectedType == null}
            key={keyForLoadingSpinner}
            id="btnUploadTyper"
          >
            {translate('customers.details.merchant.upload')}
          </ButtonOk>
        </div>
      </NameField>,
    );
    return out;
  }

  function setProductTypeId(message: any) {
    const val = message.productType;
    setSelectedType(val);
  }

  useEffect(() => {
    getProductTypeData();
    getAvailableProductTypes();
  }, []);

  return <React.Fragment>{generateList()}</React.Fragment>;
};
