import React from 'react';
import TeamMembersComponent from './administration/teamMembers/teamMembersComponent';
import AdminReportsComponent from './administration/administrationReportComponent';
import Tabs, { ITabvalues } from '../../../components/atomiccompoents/tabs/tabs';
import { ClientConfig } from '../../../common/config';
import ClearingFiles from './administration/clearing_files';
import ConfigurationSetup from './administration/configuration';
import CardStatus from './administration/card_status';
import { translate } from '../../../common/language/translate';
import ThirdPartyMonitoring from './administration/thirdpartyMonitoring';

export default class AdministrationComponent extends React.Component {
  render() {
    const tabValues: Array<ITabvalues> = [
      {
        name: 'reports',
        title: 'Reports',
        path: '/administration/reports',
        callback: props => {
          return <AdminReportsComponent />;
        },
      },
      {
        name: 'teams',
        title: 'Teams',
        path: '/administration/teams',
        callback: props => {
          return <TeamMembersComponent />;
        },
      },
    ];
    if (ClientConfig.clearingFIles === true) {
      tabValues.push({
        name: 'clearing',
        title: translate('administration.clearing_files.header'),
        path: '/administration/clearing',
        callback: props => {
          return <ClearingFiles />;
        },
      });
    }

    if (ClientConfig.showCardStatus === true) {
      tabValues.push({
        name: 'cardstatus',
        title: translate('administration.card_status.header'),
        path: '/administration/cardstatus',
        callback: props => {
          return <CardStatus />;
        },
      });
    }

    if (ClientConfig.configuration === true) {
      tabValues.push({
        name: 'configuration',
        title: 'Configuration',
        path: '/administration/configuration',
        callback: props => {
          return <ConfigurationSetup />;
        },
      });
    }

    if (ClientConfig.monitoring === true) {
      tabValues.push({
        name: 'monitoring',
        title: 'Monitoring',
        path: '/administration/monitoring',
        callback: props => {
          return <ThirdPartyMonitoring />;
        },
      });
    }

    return (
      <Tabs
        activeTab="reports"
        tabList={tabValues}
        redicrect="/administration/reports"
        basePath="/administration/"
      />
    );
  }
}
