import React, { ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';
import {
  IStatusState,
  Reporter,
} from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import { api, apis, ApiError, Ident } from '../../../../../logic/api';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { translate } from '../../../../../common/language/translate';
import { Icons } from '../../../../../images';
import {
  IStateOver,
  IPropsOver,
  AOverlay,
} from '../../../../../logic/handler/overlayhandler/globaloverlays/aOverlay';
import {
  IInitProps,
  IInitState,
} from '../../../../../logic/handler/initialdatahandler/initialDataComponent';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import ButtonCancel from '../../../../../components/atomiccompoents/buttons/buttonCancel';
import Title from '../../../../../components/compositcomponents/title';
import {
  OverlayHandler,
  Overlays,
} from '../../../../../logic/handler/overlayhandler/overlayHandler';
import { StyledViewButtons, ValueField } from '../basicStyledComponents/customerDetails.css';
import { evaluateErrorMessage, updatePerson } from '../../../../../logic/helper/Common';
import { Log, Logs } from '../../../../../logic/log';

const StyledOverlay = styled.div`
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 50px;
  padding-top: 20;
  display: flex;
  flex-direction: column;
  width: 490px;
  height: 550px;
  align-items: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  overflow-y: auto;
`;

const IconWrapper = styled.div<{ title?: string }>`
  flex-grow: 0;
  margin-right: 1ex;
  cursor: ${props => (props.title ? 'help' : 'auto')};
`;

interface IProps extends IInitProps, IPropsOver {
  personId: number;
  state?: Ident.PersonStateEnum;
}
interface IState extends IStatusState, IInitState, IStateOver {
  phoneNumbers: Array<Ident.Phone>;
  phoneNumberIdsToDelete: Array<number>;
  keyForLoadingSpinner: number;
  containsUnverifiedPhone?: boolean;
}

export default class PhoneEditOverlay extends AOverlay<IProps, IState> {
  private containsUnverifiedPhone: boolean = false;
  constructor(props: IProps) {
    super(props);
    this.state = {
      phoneNumbers: [],
      phoneNumberIdsToDelete: [],
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    };
    this.getPhoneList = this.getPhoneList.bind(this);
    this.getPhoneList();
  }
  reviveState() {}
  stopPropagation(event: SyntheticEvent) {
    event.stopPropagation();
  }
  onCancel() {
    OverlayHandler.closeSpecific(Overlays.phoneOverlay);
  }

  async onSave() {
    if (this.state.phoneNumberIdsToDelete.length > 0) {
      await this.processPendingDeletions();
    }
    console.warn('onSave calling updatePerson...');
    await updatePerson(this.props.personId);
    console.warn('onSave calling onCancel to close overlay...');
    this.onCancel();
  }

  onDeletePhoneNumber(phoneNumberIndex: number) {
    const phoneNumberId = this.state.phoneNumbers[phoneNumberIndex].phone_number_id;
    const toDelete = this.state.phoneNumberIdsToDelete;
    toDelete.push(phoneNumberId);

    const numbers = this.state.phoneNumbers;
    numbers.splice(phoneNumberIndex, 1);

    this.setState({
      ...this.state,
      phoneNumberIdsToDelete: toDelete,
    });
  }

  getPhoneList() {
    const req: Ident.PersonPhoneListRequest = {
      person_id: this.props.personId,
    };
    api.request(
      req,
      apis.MaintenanceApi,
      'personPhoneList',
      (error: ApiError, response: Array<Ident.Phone>) => {
        if (response != null) {
          const phoneNumbers = response == null || response.length === 0 ? [] : response;
          this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            phoneNumbers,
          });
          MessageHandler.onSuccess(Reporter['customer.details.phone.list.request.error']);
        } else {
          MessageHandler.onError(
            Reporter['customer.details.phone.list.request.error'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
        }
      },
    );
  }

  async processPendingDeletions() {
    if (this.state.phoneNumberIdsToDelete.length === 0) {
      return;
    }

    this.setState({
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    });

    for (const i in this.state.phoneNumberIdsToDelete) {
      const phoneNumberId: number = this.state.phoneNumberIdsToDelete[i];
      const params: Ident.PersonPhoneDeleteRequest = {
        person_id: this.props.personId,
        phone_number_id: phoneNumberId,
      };

      try {
        await api.request(
          params,
          apis.MaintenanceApi,
          'personPhoneDelete',
          (error: ApiError, response: any) => {
            console.log('result callback for delete', { phoneNumberId }, { error, response });
            if (response != null) {
              MessageHandler.onSuccess(
                Reporter['customer.details.phone.delete.request.error'],
              );
            } else {
              MessageHandler.onError(
                Reporter['customer.details.phone.delete.request.error'],
                evaluateErrorMessage(error, true),
                evaluateErrorMessage(error, false),
              );
              throw error;
            }
          },
        );
      } catch (err) {
        console.warn('catch for delete', { phoneNumberId }, { err });
        MessageHandler.onError(Reporter['customers.phone.delete']);
      }
    }
  }

  onCreateKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  getPrimaryPhoneNumber() {
    const phones = this.state.phoneNumbers;
    for (let i = 0; i < this.state.phoneNumbers.length; i++) {
      if (phones[i].is_primary) {
        return phones[i];
      }
    }
  }

  getPhoneFields(primary: boolean) {
    const out: Array<ReactElement> = [];
    const phones = this.state.phoneNumbers;
    for (const i in phones) {
      const phone = phones[i];
      if (!phone.is_validated) {
        this.containsUnverifiedPhone = true;
      }
      if ((!primary && !phone.is_primary) || (primary && phone.is_primary)) {
        out.push(
          <ValueField
            style={{
              marginBottom: '16px',
              minWidth: '330px',
              maxWidth: '330px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            key={'phoneNumber-' + i}
          >
            <IconWrapper title={phone.is_validated ? 'Validated' : 'Not validated'}>
              {phone.is_validated ? Icons.checkInCircle() : Icons.emptyCircle()}
            </IconWrapper>
            <IconWrapper
              title={phone.is_primary ? 'Primary phone number' : 'Secondary phone number'}
            >
              {phone.is_primary ? Icons.filledStar() : Icons.emptyStar()}
            </IconWrapper>
            <IconWrapper
              title={
                phone.phone_number_type === Ident.PhonePhoneNumberTypeEnum.M
                  ? 'Mobile'
                  : 'Fixed'
              }
            >
              {Icons.phoneType(phone.phone_number_type)}
            </IconWrapper>
            <div style={{ width: '100%', maxWidth: '450px' }}>{phone.phone_number}</div>
            {!phone.is_primary && phone.phone_number_id > -1 ? (
              <IconWrapper
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  this.onDeletePhoneNumber(parseInt(i));
                }}
              >
                {Icons.del()}
              </IconWrapper>
            ) : null}
          </ValueField>,
        );
      }
    }
    return out;
  }

  defineContent() {
    return (
      <StyledOverlay onClick={event => event.stopPropagation()}>
        <HeaderBlock>
          <Title title={translate('customers.phoneNumbers.title')} />
        </HeaderBlock>
        <SubHeader>{translate('customers.phoneNumbers.primary')}</SubHeader>
        <PhoneNumbersBlock>{this.getPhoneFields(true)}</PhoneNumbersBlock>
        <SubHeader>{translate('customers.phoneNumbers.secondary')}</SubHeader>
        <PhoneNumbersBlock>{this.getPhoneFields(false)}</PhoneNumbersBlock>
        <StyledViewButtons>
          <ButtonCancel id="btnEditCustomerPhoneCancel" onClick={this.onCancel}>
            {translate('button.cancel')}
          </ButtonCancel>
          <ButtonOk
            key={this.state.keyForLoadingSpinner}
            disabled={
              this.props.state === Ident.PersonStateEnum.C ||
              this.state.phoneNumberIdsToDelete.length === 0
            }
            onClick={(event: SyntheticEvent) => {
              event.stopPropagation();
              event.preventDefault();
              this.onSave();
            }}
          >
            {' '}
            {translate('button.ok')}
          </ButtonOk>
        </StyledViewButtons>
        {this.containsUnverifiedPhone === true ? (
          <StyledBottomText>* unverified</StyledBottomText>
        ) : null}
      </StyledOverlay>
    );
  }
}
const StyledBottomText = styled.div`
  font-size: 14px;
  width: 90%;
`;
const SubHeader = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: ${props => props.theme.Global.keyFontColor};
`;
const PhoneNumbersBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${props => props.theme.Global.lightFontColor};
  width: 80%;
`;
const HeaderBlock = styled.div`
  display: flex;
  flex-direction: row;
`;
