import React from 'react';
import styled from 'styled-components';
import { Input, IOption } from '../atomiccompoents/form';
import {
  Helpertext,
  TinySelect,
} from '../../content/dashboard/content/customers/basicStyledComponents/customerDetails.css';
import { FlexBox } from '../../content/auth/auth.css';
import { translate } from '../../common/language/translate';

interface IProps {
  date?: Date;
  dateString?: string;
  callback: (date: Date | string | undefined) => void;
  intervall?: number;
  showSeconds?: boolean;
  timeAsString?: boolean;
}

interface IState {
  date?: Date;
  dateString?: string;
  hour?: string | number;
  minute?: string | number;
  second?: string | number;
}

export class TimePicker extends React.Component<IProps, IState> {
  private hours = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
  ];
  private minutes: Array<number> = [];

  constructor(props: IProps) {
    super(props);
    let hour: string | number = '-1';
    let minute: string | number = '-1';
    let second: string | number = '-1';
    if (props.date != null) {
      hour = props.date.getHours();
      minute = props.date.getUTCMinutes();
      second = props.date.getSeconds();
    } else if (props.dateString != null) {
      const arr = props.dateString.split(':');
      hour = arr[0];
      minute = arr[1];
      second = arr[2];
    }
    this.state = {
      date: props.date,
      dateString: props.dateString,
      hour: hour,
      minute: minute,
      second: second,
    };
    this.createMinutes();
    this.handleMinuteSelect = this.handleMinuteSelect.bind(this);
    this.handleOurSelect = this.handleOurSelect.bind(this);
    this.generateHourOptions = this.generateHourOptions.bind(this);
    this.generateMinuteOptions = this.generateMinuteOptions.bind(this);
    this.generateSecondOptions = this.generateSecondOptions.bind(this);
    this.handleSecondSelect = this.handleSecondSelect.bind(this);
  }

  createMinutes() {
    const intervall = this.props.intervall != null ? this.props.intervall : 5;
    for (let i = 0; i <= 60; i = i + intervall) {
      this.minutes.push(i);
    }
  }

  generateHourOptions() {
    const enumValues: Array<IOption> = [];
    enumValues.push({
      key: 'defaultOptionKey minute null',
      name: '-',
      value: '-1',
    });

    for (const value of this.hours) {
      enumValues.push({
        key: 'defaultOptionKey hour' + value,
        name: value < 10 ? '0' + value.toString() : value.toString(),
        value: value.toString(),
      });
    }
    return enumValues;
  }

  handleOurSelect(message: any) {
    if (this.props.date != null || this.props.timeAsString !== true) {
      let date: Date | undefined = this.state.date;
      if (message.hourSelect === '-1') {
        date = undefined;
      } else if (date == null && message.hourSelect !== '-1') {
        date = new Date();
        date.setHours(0);
      }
      if (date != null) {
        date.setHours(parseInt(message.hourSelect));
      }
      this.setState({
        hour: message.hourSelect,
        date: date,
        minute: date != null ? (this.state.minute !== '-1' ? this.state.minute : '0') : '-1',
      });
      this.props.callback(date);
    } else {
      let date: string | undefined = this.state.dateString;
      const hourString =
        message.hourSelect < 10 ? '0' + message.hourSelect : message.hourSelect;
      if (message.hourSelect === '-1') {
        date = undefined;
      } else if (date == null && message.minuteSelect !== '-1') {
        date = hourString + ':00:00';
      }
      if (date != null) {
        const arr = date.split(':');
        date = hourString + ':' + arr[1] + ':00';
      }
      this.setState({
        hour: message.hourSelect,
        minute:
          date != null
            ? this.state.minute !== '-1'
              ? this.state.minute
              : date.split(':')[1]
            : '-1',
        dateString: date,
      });
      this.props.callback(date);
    }
  }

  generateMinuteOptions() {
    const enumValues: Array<IOption> = [];
    enumValues.push({
      key: 'defaultOptionKey minute null',
      name: '-',
      value: '-1',
    });
    for (const value of this.minutes) {
      enumValues.push({
        key: 'defaultOptionKey minute' + value,
        name: value < 10 ? '0' + value.toString() : value.toString(),
        value: value.toString(),
      });
    }
    return enumValues;
  }

  generateSecondOptions() {
    const enumValues: Array<IOption> = [];
    enumValues.push({
      key: 'defaultOptionKey minute null',
      name: '-',
      value: '-1',
    });
    for (let i = 0; i < 60; i++) {
      enumValues.push({
        key: 'defaultOptionKey minute' + i,
        name: i < 10 ? '0' + i.toString() : i.toString(),
        value: i.toString(),
      });
    }
    return enumValues;
  }

  handleMinuteSelect(message: any) {
    if (this.props.date != null || this.props.timeAsString !== true) {
      let date: Date | undefined = this.state.date;
      if (message.minuteSelect === '-1') {
        date = undefined;
      } else if (date == null && message.minuteSelect !== '-1') {
        date = new Date();
      }
      if (date != null) {
        date.setMinutes(parseInt(message.minuteSelect));
      }
      this.setState({
        minute: message.minuteSelect,
        hour:
          date != null
            ? this.state.hour !== '-1'
              ? this.state.hour
              : date.getHours().toString()
            : '-1',
        date: date,
      });
      this.props.callback(date);
    } else {
      let date: string | undefined = this.state.dateString;
      const minuteString =
        message.minuteSelect < 10 ? '0' + message.minuteSelect : message.minuteSelect;
      if (message.minuteSelect === '-1') {
        date = undefined;
      } else if (date == null && message.minuteSelect !== '-1') {
        date = '00:' + minuteString + '00';
      }
      if (date != null) {
        const arr = date.split(':');
        date = arr[0] + ':' + minuteString + ':00';
      }
      this.setState({
        minute: message.minuteSelect,
        hour:
          date != null
            ? this.state.hour !== '-1'
              ? this.state.hour
              : date.split(':')[0]
            : '-1',
        dateString: date,
      });
      this.props.callback(date);
    }
  }

  handleSecondSelect(message: any) {
    if (this.props.date != null || this.props.timeAsString !== true) {
      let date: Date | undefined = this.state.date;
      if (message.secondSelect === '-1') {
        date = undefined;
      } else if (date == null && message.secondSelect !== '-1') {
        date = new Date();
      }
      if (date != null) {
        date.setSeconds(parseInt(message.secondSelect));
      }
      this.setState({
        second: message.secondSelect,
        date: date,
      });
      this.props.callback(date);
    } else {
      let date: string | undefined = this.state.dateString;
      const secondString =
        parseInt(message.secondSelect) < 10
          ? '0' + message.secondSelect
          : message.secondSelect;
      if (message.secondSelect === '-1') {
        date = undefined;
      } else if (date == null && message.secondSelect !== '-1') {
        date = '00:00:' + secondString;
      }
      if (date != null) {
        const arr = date.split(':');
        date = arr[0] + ':' + arr[1] + ':' + secondString;
      }
      this.setState({
        second: message.secondSelect,
        dateString: date,
      });
      this.props.callback(date);
    }
  }

  render() {
    return (
      <Main>
        <HourSelect>
          <TinySelect
            id="hourSelect"
            current={this.state.hour}
            options={this.generateHourOptions()}
            notification={this.handleOurSelect}
          />
          <Helpertext>{translate('customers.products.time.hour')}</Helpertext>
        </HourSelect>
        <Divider style={{ marginTop: '16px', marginLeft: '4px', marginRight: '4px' }}>
          {' :: '}
        </Divider>
        <MinuteSelect>
          <TinySelect
            id="minuteSelect"
            current={this.state.minute}
            options={this.generateMinuteOptions()}
            notification={this.handleMinuteSelect}
          />
          <Helpertext>{translate('customers.products.time.minute')}</Helpertext>
        </MinuteSelect>
        {this.props.showSeconds ? (
          <React.Fragment>
            <Divider
              style={{
                marginTop: '16px',
                marginLeft: '4px',
                marginRight: '4px',
              }}
            >
              {' :: '}
            </Divider>
            <SecondSelect>
              <TinySelect
                id="secondSelect"
                current={this.state.second}
                options={this.generateSecondOptions()}
                notification={this.handleSecondSelect}
              />
            </SecondSelect>
            <Helpertext>{translate('customers.products.time.second')}</Helpertext>
          </React.Fragment>
        ) : null}
      </Main>
    );
  }
}

const Main = styled(FlexBox)`
  display: flex;
  flex-direction: row;
`;

const HourSelect = styled.div`
  display: flex;
  flex-direction: column;
`;

const MinuteSelect = styled.div`
  display: flex;
  flex-direction: column;
`;

const SecondSelect = styled.div`
  display: flex;
  flex-direction: column;
`;
const Divider = styled.div`
  color: ${props => props.theme.Button.backgroundColor};
`;
