//@ts-nocheck
import React, { SyntheticEvent, ReactNode } from 'react';
import AuthScreen, { IAuthScreenState, IAuthScreenProps } from '../authScreen';
import { withTheme } from 'styled-components';
import { MessageHandler } from '../../../logic/handler/messagehandler/messageHandler';
import {
  Reporter,
  IMessageConfig,
  IStatusState,
  getFormComponents,
} from '../../../logic/handler/messagehandler/messageHandlerConfig';
import { withRouter } from 'react-router-dom';
import { StyledAuthComponent, LinkBox } from './auth.css';
import { Actions } from '../../../logic/flux';
import { Ident, ApiError, api, apis } from '../../../logic/api';
import { IAuthConfig } from './IAuthConfig';
import history from '../../../common/history';
import { translate } from '../../../common/language/translate';
import { BigInput } from '../../../components/atomiccompoents/form/inputs.css';
import ButtonOk from '../../../components/atomiccompoents/buttons/buttonOk';
import { DeveloperStore, DefaultLoginType } from '../../../logic/flux/stores/DeveloperStore';
import { FlexBox } from '../auth.css';
import {
  evaluateErrorMessage,
  performErrorCodeUpdate,
  performLogin,
} from '../../../logic/helper/Common';
import { Config } from '../../../config';
import {
  OverlayHandler,
  Overlays,
} from '../../../logic/handler/overlayhandler/overlayHandler';
import { LogoutTimer } from '../../../components/compositcomponents/logoutTimer';

interface IState extends IAuthScreenState, IStatusState {
  redirect?: boolean;
  email?: string;
  password?: string;
  checked: boolean;
  keyForLoadingSpinner?: number;
  timerExceeded?: boolean;
  buttonDisabled?: boolean;
}

interface IProps extends IAuthScreenProps {
  theme?: any;
}

class LoginComponentClass extends AuthScreen<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      redirect: false,
      checked: false,
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
      email: '',
      password: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  unsetAutofillForChrome(event: any) {
    if (event.target.autocomplete) {
      event.target.autocomplete = 'whatever';
    }
  }

  componentDidMount(): void {
    const emailInput = document.getElementById('email');
    const passwordInput = document.getElementById('email');
    if (emailInput != null && passwordInput != null) {
      setTimeout(() => {
        passwordInput.focus();
        emailInput.focus();
      }, 500);
    }

    if (DeveloperStore.isDeveloperModeEnabled()) {
      const defaultLogin: DefaultLoginType = DeveloperStore.getDefaultLogin();
      if (defaultLogin != null) {
        this.setState(
          {
            email: defaultLogin.username,
            password: defaultLogin.password,
          },
          () => {
            this.onSubmit();
          },
        );
      }
    }
  }

  private handleAuthenticationError(response: Ident.OidcError | ApiError): void {
    const errorText: string = this.isOidcError(response)
      ? response.error_text
      : response.statusText;

    const conf: IMessageConfig = MessageHandler.onError(
      Reporter['web.auth.login.error'],
      errorText,
    );
    this.setState({
      showInlineError: conf.errorMethods.inline === true,
      errorMessage: conf.errorMessage != null ? conf.errorMessage : errorText,
      key: conf.translationKey != null ? conf.translationKey + '.error' : '',
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    });

    const authParams: Ident.AuthParams | undefined = this.isOidcError(response)
      ? response.authParams
      : response.response == null
      ? undefined
      : response.response.authParams;
    if (authParams != null) {
      this.idpState = authParams.idp_state;
      const authConfig: IAuthConfig | undefined = this.getAuthConfigForConnection(
        authParams.connection[0],
      );
      history.push(
        authConfig == null
          ? this.authConfig[0].uri // TODO: !!!
          : authConfig.uri,
      );
    }
  }

  handleEmailChange(email: string) {
    this.setState({
      email: email,
    });
  }

  private isOidcError(arg: any): arg is Ident.OidcError {
    if (arg == null) {
      return false;
    }

    return 'authParams' in arg;
  }

  onSubmit(event?: SyntheticEvent | React.KeyboardEvent<HTMLFormElement>) {
    if (event != null) {
      event.preventDefault();
    }
    if (
      (this.state.email == null || this.state.email.trim() === '') &&
      (this.state.password == null || this.state.password.trim() === '')
    ) {
      const conf: IMessageConfig = MessageHandler.onError(
        Reporter['web.auth.login.error.nodata'],
      );
      this.setState({
        errorMessage:
          conf.errorMessage != null
            ? conf.errorMessage
            : translate('auth.signin.pwUserNeeded'),
        showInlineError: conf.errorMethods.inline,
        key: conf.translationKey != null ? conf.translationKey + '.error' : '',
        keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
      });
    } else if (this.state.email == null || this.state.email.trim() === '') {
      const conf: IMessageConfig = MessageHandler.onError(
        Reporter['web.auth.login.error.nouser'],
      );
      this.setState({
        errorMessage:
          conf.errorMessage != null ? conf.errorMessage : translate('auth.signin.userNeeded'),
        showInlineError: conf.errorMethods.inline,
        key: conf.translationKey != null ? conf.translationKey + '.error' : '',
        keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
      });
    } else if (this.state.password == null || this.state.password.trim() === '') {
      const conf: IMessageConfig = MessageHandler.onError(
        Reporter['web.auth.login.error.nopassword'],
      );
      this.setState({
        errorMessage: conf.errorMessage != null ? conf.errorMessage : 'Password needed',
        showInlineError: conf.errorMethods.inline,
        key: conf.translationKey != null ? conf.translationKey + '.error' : '',
        keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
      });
    } else if (this.state.timerExceeded === true) {
      const conf: IMessageConfig = MessageHandler.onError(
        Reporter['web.auth.login.error.timerExceeded'],
      );
      this.setState({
        errorMessage: conf.errorMessage != null ? conf.errorMessage : 'Session expired',
        showInlineError: conf.errorMethods.inline,
        key: conf.translationKey != null ? conf.translationKey + '.error' : '',
        keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
        buttonDisabled: true,
      });
    } else {
      const oauthLoginPostRequest: Ident.OauthLoginPostRequest = {
        AuthRequest: {
          username: this.state.email != null ? this.state.email : '',
          password: this.state.password != null ? this.state.password : '',
          idp_state: this.idpState,
        },
      };
      api
        .asyncRequest<Ident.PermissionResponse | Ident.OidcError>(
          oauthLoginPostRequest,
          apis.OpenIDConnectApi,
          'oauthLoginPost',
        )
        .then(response => {
          this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          });
          if (this.isOidcError(response)) {
            Actions.setLogoutAbsTime(
              response.authParams == null ? undefined : response.authParams.exp,
            );
            this.handleAuthenticationError(response);
          } else {
            performLogin(response).catch((error: ApiError) => {
              const conf: IMessageConfig = MessageHandler.onError(
                Reporter['web.auth.login.permission.error'],
                error.statusText,
              );
              this.setState({
                showInlineError: conf.errorMethods.inline === true,
                errorMessage: conf.errorMessage != null ? conf.errorMessage : error.statusText,
                key: conf.translationKey != null ? conf.translationKey + '.error' : '',
                keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
              });
            });
          }
        })
        .catch((error: ApiError) => {
          this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          });
          if (error.response != null) {
            this.handleAuthenticationError(error);
          } else {
            const conf: IMessageConfig = MessageHandler.onError(
              Reporter['general.error.message'],
              evaluateErrorMessage(error, true),
              evaluateErrorMessage(error, false),
            );
            this.setState({
              errorMessage: conf.errorMessage != null ? conf.errorMessage : '',
              showInlineError: conf.errorMethods.inline === true,
              key: conf.translationKey != null ? conf.translationKey + '.error' : '',
            });
          }
        });
    }
  }

  apiRequest(callback: () => void) {
    callback();
  }

  private handlePasswordChange(value: string): void {
    this.setState({ password: value });
  }

  setChecked() {
    this.setState({
      checked: !this.state.checked,
      redirect: this.state.redirect,
    });
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.onSubmit(event);
    }
  };

  defineContent(): ReactNode {
    return null;
  }

  reviveState(): void {}

  render() {
    return (
      <StyledAuthComponent>
        <div>{translate(this.getTitle())}</div>
        <div>{translate(this.getSubtitle() as string)}</div>

        <form
          onSubmit={(event: SyntheticEvent) => this.onSubmit(event)}
          onKeyDown={this.onKeyDown}
        >
          <BigInput
            label={translate('auth.signin.input.email.label')}
            placeHolder={translate('auth.signin.input.email.placeholder')}
            id="email"
            type="email"
            onChange={(value: string) => this.handleEmailChange(value)}
            showError={false}
            onSubmit={this.onSubmit}
            value={this.state.email}
            valueFromState={true}
            onEnter={this.unsetAutofillForChrome}
          />

          <BigInput
            label={translate('auth.signin.input.password.label')}
            placeHolder={translate('auth.signin.input.password.placeholder')}
            id="password"
            type="password"
            onChange={(value: string) => this.handlePasswordChange(value)}
            showError={false}
            onSubmit={this.onSubmit}
            value={this.state.password}
            valueFromState={true}
            onEnter={this.unsetAutofillForChrome}
          />

          <FlexBox>
            <LinkBox>
              {this.getLinkForConnection(Ident.AuthConnection.ResetPassword)}
              {this.getLinkForConnection(Ident.AuthConnection.FaceLogin)}
            </LinkBox>
            <ButtonOk
              onClick={this.onSubmit}
              style={this.props.theme.AuthComponentButton}
              id="btnSignIn"
              key={this.state.keyForLoadingSpinner}
              disabled={this.state.buttonDisabled}
            >
              {translate('button.signin')}
              <LogoutTimer
                showTimer={false}
                onExpire={() => {
                  if (Config.logOutConfirm === true) {
                    OverlayHandler.showOverlay(Overlays.warningOverlay, {
                      confirm: () => {
                        window.location.reload();
                      },
                      heading: translate('logOut.refresh'),
                      message: translate('logOut.message'),
                    });
                  }
                }}
              />
            </ButtonOk>

            {getFormComponents(
              this.state.showInlineError,
              this.state.showInlineSuccess,
              this.state.errorMessage,
              this.state.successMessage,
              this.state.key,
            )}
          </FlexBox>
        </form>
        {this.getLinkForConnection(Ident.AuthConnection.Signup)}
        {this.getLinkForConnection(Ident.AuthConnection.Verify)}
        {OverlayHandler.getOverlays()}
      </StyledAuthComponent>
    );
  }
}

export const LoginComponent = withRouter(withTheme(LoginComponentClass));
