import React from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import { Icons } from '../../../../images';
import { translate } from '../../../../common/language/translate';

interface IProps {
  notification?: () => {};
}

interface IState {}

const StyledViewHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 28px;
  margin-bottom: 50px;
  margin-left: 20px;
  margin-right: 20px;

  & > div {
    font-size: 18px;
    line-height: 24px;
    color: #4f5f8e;
    font-style: normal;
    font-weight: normal;
  }

  svg {
    margin-right: 25px;
    cursor: pointer;
  }
`;

const StyledView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: 30px;

  margin-left: 20px;
  margin-right: 20px;
  margin-top: 8px;
  margin-bottom: 7px;
`;

const StyledTextEntry = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: #4f5f8e;
  font-style: normal;
  font-weight: normal;
`;

const StyledTextKey = styled(StyledTextEntry)`
  color: #979797;
`;

const StyledTextValue = styled(StyledTextEntry)`
  color: #4f5f8e;
`;

const StyledHr = styled.hr`
  border: 1px solid #ecebeb;
  margin-left: 20px;
  margin-right: 20px;
`;

export class AppInformationComponent extends React.Component<IProps, IState> {
  render() {
    return (
      <React.Fragment>
        <StyledViewHeading>
          {Icons.userMenuArrowLeft(this.props.notification)}
          <span>{translate('usermenu.appInformation')}</span>
        </StyledViewHeading>

        <StyledView>
          <StyledTextKey>{translate('usermenu.update')}</StyledTextKey>
          <StyledTextValue>{process.env.REACT_APP_BUILD_DATE}</StyledTextValue>
        </StyledView>
        <StyledHr />

        <StyledView>
          <StyledTextKey>{translate('usermenu.version')}</StyledTextKey>
          <StyledTextValue>{process.env.REACT_APP_BUILD_VERSION}</StyledTextValue>
        </StyledView>
        <StyledHr />

        <StyledView>
          <StyledTextKey>{translate('usermenu.setLanguage')}</StyledTextKey>
          <StyledTextValue>
            {translate('usermenu.language.' + i18next.language)}
          </StyledTextValue>
        </StyledView>
        <StyledHr />
      </React.Fragment>
    );
  }
}
