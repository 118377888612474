//@ts-nocheck
import React, { ReactNode } from 'react';
import { withRouter } from 'react-router-dom';
import AuthScreen, { IAuthScreenProps, IAuthScreenState } from '../authScreen';
import { translate } from '../../../common/language/translate';
import { StyledAuthComponent } from './auth.css';
import { Images } from '../../../images';
import ButtonOk from '../../../components/atomiccompoents/buttons/buttonOk';

interface IState extends IAuthScreenState {
  redirect?: boolean;
  email?: string;
  password?: string;
  checked: boolean;
}

interface IProps extends IAuthScreenProps {}

class ConfirmationComponentClass extends AuthScreen<IProps, IState> {
  onSubmit(): void {}

  constructor(props: IProps) {
    super(props);

    this.state = {
      ...props,
      redirect: false,
      checked: false,
    };
  }

  defineContent(): ReactNode {
    return null;
  }

  reviveState(): void {}

  render() {
    return (
      <StyledAuthComponent>
        <div>{translate(this.getTitle())}</div>
        <div>{translate(this.getSubtitle() as string)}</div>

        {Images.readEmail()}

        <ButtonOk
          disableSpinner={true}
          id="openEmail"
          style={{ width: '100%' }}
          onClick={() => {
            //TODO: Set UserDataStore.user, maybe performLogin()
            window.location.pathname = process.env.REACT_APP_ROOT_PATH + '/';
          }}
        >
          {translate('button.ok')}
        </ButtonOk>
      </StyledAuthComponent>
    );
  }
}

export const ConfirmationComponent = withRouter(ConfirmationComponentClass);
