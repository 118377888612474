import React, { ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { LanguageSelectorComponent } from './MenuComponents/languageSelector';
import { AppInformationComponent } from './MenuComponents/appInformation';
import { withRouter } from 'react-router-dom';
import { Location } from 'history';
import { authConfig } from '../../auth/authConfig';
import { Ident, api, VoidResponse, apis, ApiError, Account } from '../../../logic/api';
import { IItfAccount } from '../../../logic/types';
import {
  OverlayHandler,
  Overlays,
} from '../../../logic/handler/overlayhandler/overlayHandler';
import { format } from '../../../logic/helper/format';
import { translate } from '../../../common/language/translate';
import { Icons } from '../../../images';
import {
  IInitState,
  IInitProps,
  InitialDataComponent,
} from '../../../logic/handler/initialdatahandler/initialDataComponent';
import { APages } from '../../../components/nav';
import {
  INotifyAbleCustomerData,
  INotifyAbleUserImage,
  INotifyAbleUserData,
  INotifyAbleBackofficeData,
  UserImageStore,
  CustomerDataStore,
  UserDataStore,
  BackofficeStore,
  Actions,
  UndoStore,
} from '../../../logic/flux';
import { IMenuEntry, UserMenu, MessageMenu } from '../../../components/menu';
import { InitComponentHandler } from '../../../logic/handler/initialdatahandler/InitComponentHandler';
import ButtonOk from '../../../components/atomiccompoents/buttons/buttonOk';
import { LogoutTimer } from '../../../components/compositcomponents/logoutTimer';
import { Log, Logs } from '../../../logic/log';
import { INotifyAbleNotification } from '../../../logic/flux/notifyAbles/notifyAbleNotification';
import { NotificationStore } from '../../../logic/flux/stores/notificationStore';
import { INotifyAbleDeveloperData } from '../../../logic/flux/notifyAbles/notifyAbleDeveloperData';
import { DeveloperStore } from '../../../logic/flux/stores/DeveloperStore';
import TouchableOpacity from '../../../components/atomiccompoents/buttons/touchableOpacity';
import { MessageHandler } from '../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../logic/handler/messagehandler/messageHandlerConfig';
import { getUserImageOrPlaceholder, updatePerson } from '../../../logic/helper/Common';

interface IProps extends IInitState {
  location?: Location;
  pages: APages;
  smallNav: boolean;
  toggleNav: () => void;
}

interface IState extends IInitProps {
  showUserMenu?: boolean;
  showMessageMenu?: boolean;
  navHidden?: boolean;
  activePageName?: string;
  changePasswordOverlay?: boolean;
  user?: Ident.Person;
  selectedUser?: Ident.Person;
  userImage?: Blob;
  selectedBackofficeAccount?: IItfAccount;
  smallNav?: boolean;
  notifications?: Array<Account.NotificationLine>;
  developerMode?: boolean;
  defaultBackofficeAccount?: string;
  defaultCustomer?: number;
}

@(withRouter as any)
class HeaderComponent extends InitialDataComponent<IProps, IState>
  implements
    INotifyAbleCustomerData,
    INotifyAbleUserImage,
    INotifyAbleUserData,
    INotifyAbleBackofficeData,
    INotifyAbleNotification,
    INotifyAbleDeveloperData {
  private pages: APages;
  private menuEntries: Array<IMenuEntry> = [
    {
      title: 'usermenu.password',
      action: this.passwordAction,
    },
    {
      title: 'usermenu.languages',
      gotoSubComponent: true,
      subComponent: <LanguageSelectorComponent />,
    },
    {
      title: 'usermenu.appInformation',
      gotoSubComponent: true,
      subComponent: <AppInformationComponent />,
    },
    { title: 'usermenu.security' },
    { title: 'usermenu.notifications' },
    { title: 'usermenu.helpCenter' },
    { title: 'usermenu.customerCare' },
  ];

  constructor(props: IProps) {
    super(props);

    this.pages = props.pages;

    this.state = {
      showUserMenu: false,
      showMessageMenu: false,
      navHidden: false,
      activePageName: this.pages.getActivePagename(window.location.pathname),
      changePasswordOverlay: false,
      smallNav: props.smallNav,
    };

    this.toggleUserMenu = this.toggleUserMenu.bind(this);
    this.toggleMessageMenu = this.toggleMessageMenu.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
    this.hideUserMenu = this.hideUserMenu.bind(this);
    this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
    this._onChangeUserImage = this._onChangeUserImage.bind(this);
    this._onChangeUserData = this._onChangeUserData.bind(this);
    this._onChangeBackofficeData = this._onChangeBackofficeData.bind(this);
    this._onChangeNotifications = this._onChangeNotifications.bind(this);
    this._onChangeDeveloperData = this._onChangeDeveloperData.bind(this);
  }

  reviveState(newState: { [key: string]: any }): void {
    this.setState(newState);
    this.toggleUserMenu();
  }

  passwordAction() {
    OverlayHandler.showOverlay(Overlays.changePassword, {
      button: ButtonOk,
      authConfig: authConfig,
      centered: true,
    });
  }

  componentDidMount() {
    this.setState({
      activePageName: this.pages.getActivePagename(window.location.pathname),
    });
    UserImageStore.addChangeListener(this._onChangeUserImage);
    this._onChangeUserImage();
    CustomerDataStore.addChangeListener(this._onChangeCustomerData);
    this._onChangeCustomerData();
    UserDataStore.addChangeListener(this._onChangeUserData);
    this._onChangeUserData();
    BackofficeStore.addChangeListener(this._onChangeBackofficeData);
    this._onChangeBackofficeData();
    NotificationStore.addChangeListener(this._onChangeNotifications);
    this._onChangeNotifications();
    DeveloperStore.addChangeListener(this._onChangeDeveloperData);
    this._onChangeDeveloperData();
    this.fill(this.constructor.name);
    // this._initPush();
    //this.clickButton("manualPushButton");
    this.fetchStoredNotifications();
    this.getUserImage();
  }

  componentWillUnmount() {
    CustomerDataStore.removeChangeListener(this._onChangeCustomerData);
    UserImageStore.removeChangeListener(this._onChangeUserImage);
    UserDataStore.removeChangeListener(this._onChangeUserData);
    BackofficeStore.removeChangeListener(this._onChangeBackofficeData);
    NotificationStore.removeChangeListener(this._onChangeNotifications);
    DeveloperStore.removeChangeListener(this._onChangeDeveloperData);
  }

  componentDidUpdate(props: IProps) {
    if (this.props.location != null && this.props.location !== props.location) {
      this.setState({
        activePageName: this.pages.getActivePagename(window.location.pathname),
      });
    }
  }

  private fetchStoredNotifications(): void {
    const params: Account.NotificationsGetRequest = {};
    api
      .asyncRequest<Account.NotificationList>(params, apis.NotificationApi, 'notificationsGet')
      .then(response => {
        Actions.setNotifications(response);
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, 'An error occured fetching notifications', error.statusText);
        MessageHandler.onError(Reporter['header.notifications.fetch.error']);
      });
  }

  private isNotificationLine(arg: any): arg is Account.NotificationLine {
    if (arg == null) {
      return false;
    }

    return (
      'channel' in arg &&
      'id' in arg &&
      'in_out' in arg &&
      'read' in arg &&
      'datetime_create' in arg &&
      'preview' in arg &&
      'detail' in arg
    );
  }

  private receiveMessage(payload: Account.NotificationLine): void {
    Log.info(Logs.FCM, 'Message received. ', payload);
    Actions.addNotification(payload);
  }

  _onChangeDeveloperData(): void {
    this.setState({
      developerMode: DeveloperStore.isDeveloperModeEnabled(),
      defaultCustomer: DeveloperStore.getDefaultCustomer(),
      defaultBackofficeAccount: DeveloperStore.getDefaultBackofficeAccount(),
    });
  }

  _onChangeNotifications(): void {
    this.setState({
      notifications: NotificationStore.getNotifications(),
    });
  }

  _onChangeBackofficeData() {
    this.setState({
      selectedBackofficeAccount: BackofficeStore.getAccount(),
    });
  }

  _onChangeCustomerData() {
    const user = CustomerDataStore.getUser();
    this.setState({ selectedUser: user });
  }

  _onChangeUserImage(): void {
    this.setState({
      userImage: UserImageStore.getUserImage(),
    });
  }

  _onChangeUserData(): void {
    this.setState({
      user: UserDataStore.getUser(),
    });
  }

  static getDerivedStateFromProps(props: IProps, state: IState): IState | null {
    if (props.smallNav !== state.smallNav) {
      return {
        smallNav: props.smallNav,
      };
    }

    return null;
  }

  private alerts(): void {
    OverlayHandler.showOverlay(Overlays.alerts);
  }

  private notifications(): void {
    OverlayHandler.showOverlay(Overlays.notifications);
  }

  private toggleNav() {
    if (this.props.toggleNav != null) {
      this.props.toggleNav();
    }
  }

  resetBackoffice() {
    Actions.resetBackoffice();
    MessageHandler.onWarning(Reporter['header.customer.close.confirmation'], () =>
      UndoStore.undoBackofficeAccount(),
    );
  }

  resetUser() {
    Actions.resetCustomerData();
    MessageHandler.onWarning(Reporter['header.customer.close.confirmation'], () =>
      UndoStore.undo(),
    );
  }

  private toggleMessageMenu() {
    // if (event != null) {
    //     event.stopPropagation();
    // }

    if (OverlayHandler.findDuplicate('messageMenu')) {
      OverlayHandler.closeSpecific(Overlays.messageMenu);
    } else {
      OverlayHandler.showOverlay(Overlays.messageMenu, {});
    }
  }

  private toggleUserMenu(event?: SyntheticEvent) {
    InitComponentHandler.register(this, this.constructor.name);
    if (event != null) {
      event.stopPropagation();
    }

    if (OverlayHandler.findDuplicate('userMenu')) {
      OverlayHandler.closeSpecific(Overlays.userMenu);
    } else {
      OverlayHandler.showOverlay(Overlays.userMenu, {
        menuEntries: this.menuEntries,
      });
    }
  }

  private hideUserMenu() {
    this.setState({ showUserMenu: false });
  }

  private setDefaultBackofficeAccount(accountNumber?: string) {
    if (this.state.defaultBackofficeAccount == null) {
      Actions.setDefaultBackofficeAccount(accountNumber);
    } else {
      Actions.setDefaultBackofficeAccount(undefined);
    }
  }

  private setDefaultCustomer(personId?: number) {
    if (this.state.defaultCustomer == null) {
      Actions.setDefaultCustomer(personId);
    } else {
      Actions.setDefaultCustomer(undefined);
    }
  }
  private getUserImage() {
    api
      .asyncRequest<any>({}, apis.SelfServiceApi, 'selfFaceGetCurrent')
      .then((response: Blob) => {
        if (response != null) {
          Actions.userImageChanged(response);
        }
      })
      .catch(() => {});
  }
  private clickButton(id: string): void {
    if ('safari' in window) {
      const btn = document.getElementById(id);
      if (btn != null) {
        Log.debug(Logs.APNS, 'Executing permission method');
        btn.click();
      }
    }
  }

  render() {
    const username =
      this.state.user == null
        ? ''
        : format.nameByParts(
            this.state.user.given_name,
            this.state.user.name,
            this.state.user.primary_email_address,
          );

    let titleBox: ReactNode = (
      <PageTitle>{translate(this.state.activePageName, this.state.activePageName)}</PageTitle>
    );
    let closingCross = null;
    if (window.location.pathname.includes('customers') && this.state.selectedUser != null) {
      const selectedUser =
        this.state.selectedUser == null
          ? ''
          : format.nameByParts(
              this.state.selectedUser.given_name,
              this.state.selectedUser.name,
              this.state.selectedUser.primary_email_address,
            );
      titleBox = (
        <UserNameBox>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {selectedUser}
            <div style={{ fontSize: '12px' }}>{this.state.selectedUser.entity}</div>
          </div>
          {this.state.developerMode !== true ? null : (
            <ButtonOk
              disableSpinner={true}
              loading={false}
              style={
                this.state.defaultCustomer == null
                  ? { height: '23px', marginLeft: '12px', width: 'initial' }
                  : {
                      backgroundColor: 'green',
                      height: '23px',
                      marginLeft: '12px',
                      width: 'initial',
                    }
              }
              onClick={() =>
                this.setDefaultCustomer(
                  this.state.selectedUser == null
                    ? undefined
                    : this.state.selectedUser.person_id,
                )
              }
            >
              Set as default
            </ButtonOk>
          )}
        </UserNameBox>
      );
      closingCross =
        this.state.selectedUser != null ? (
          <React.Fragment>
            <IconHolderRefresh>
              <TouchableOpacity
                onClick={() => {
                  if (this.state.selectedUser != null) {
                    updatePerson(this.state.selectedUser.person_id);
                  }
                }}
              >
                {Icons.refresh()}
              </TouchableOpacity>
            </IconHolderRefresh>
            <IconHolderCross>
              <TouchableOpacity onClick={this.resetUser}>
                {Icons.closingCross()}
              </TouchableOpacity>
            </IconHolderCross>
          </React.Fragment>
        ) : null;
    } else if (
      window.location.pathname.includes('backoffice') &&
      this.state.selectedBackofficeAccount != null
    ) {
      closingCross =
        this.state.selectedBackofficeAccount != null ? (
          <IconHolderCross>
            <TouchableOpacity onClick={this.resetBackoffice}>
              {Icons.closingCross()}
            </TouchableOpacity>
          </IconHolderCross>
        ) : null;

      titleBox = (
        <UserNameBox>
          {this.state.selectedBackofficeAccount.name}
          {this.state.developerMode !== true ? null : (
            <ButtonOk
              clicked={false}
              disableSpinner={true}
              loading={false}
              style={
                this.state.defaultBackofficeAccount == null
                  ? { height: '23px', marginLeft: '12px', width: 'initial' }
                  : {
                      backgroundColor: 'green',
                      height: '23px',
                      marginLeft: '12px',
                      width: 'initial',
                    }
              }
              onClick={() =>
                this.setDefaultBackofficeAccount(
                  this.state.selectedBackofficeAccount == null
                    ? undefined
                    : this.state.selectedBackofficeAccount.account_number,
                )
              }
            >
              Set as default
            </ButtonOk>
          )}
        </UserNameBox>
      );
    }

    return (
      <StyledHeader id="mainHeader">
        <HamburgerTitleWrapper>
          <TouchableOpacity containerStyle={HamburgerButton} onClick={this.toggleNav}>
            {Icons.hamburger()}
          </TouchableOpacity>
          {titleBox}
          {closingCross}
        </HamburgerTitleWrapper>

        {/*isSafariBrowser() === true ? (
                    <button
                        id="manualPushButton"
                        onClick={event => {
                            event.stopPropagation();
                            event.preventDefault();

                            Log.debug(Logs.APNS, "Button onClick()");

                            if (
                                checkRemotePermission != null &&
                                typeof checkRemotePermission === 'function'
                            ) {
                                Log.debug(Logs.APNS, "Executing checkRemotePermission()");
                                checkRemotePermission(
                                    //@ts-ignore
                                    window.safari.pushNotification.permission(apnsDomain)
                                );
                            }
                        }}
                    />
                    ) : null*/}

        <IconHolder onClick={this.toggleMessageMenu}>
          <NotificationWrapper>
            {this.state.notifications != null &&
            this.state.notifications.length > 0 &&
            this.state.notifications.find(n => n.read !== true) != null
              ? Icons.notificationGreen()
              : Icons.notification()}
          </NotificationWrapper>
        </IconHolder>

        <UserArea onClick={this.toggleUserMenu}>
          <UserImageBox>{getUserImageOrPlaceholder(this.state.userImage)}</UserImageBox>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Username>{username}</Username>
            <LogoutTimer />
          </div>
        </UserArea>

        {this.state.showUserMenu ? (
          <UserMenu
            menuEntries={this.menuEntries}
            showMetaBox={false}
            selfClose={() => OverlayHandler.closeSpecific(Overlays.userMenu)}
          />
        ) : null}
        {this.state.showMessageMenu ? (
          <MessageMenu
            menuEntries={this.menuEntries}
            selfClose={() => OverlayHandler.closeSpecific(Overlays.messageMenu)}
          />
        ) : null}
      </StyledHeader>
    );
  }
}

const HamburgerTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
`;

const HamburgerButton: React.CSSProperties = {
  width: '64px',
  display: 'flex',
  justifyContent: 'center',
  marginRight: '24px',
};

const StyledHeader = styled.header`
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 64px;
  background-color: #ffffff;
  min-width: 718px;
  border: solid #e8ecef;
  border-width: 0px 0px 1px 0px;
  box-sizing: border-box;
  padding-left: 18px;
  padding-right: 18px;
  box-shadow: ${props => props.theme.Box.boxShadow};
  margin-bottom: 4px;
  z-index: 10;
  cursor: default;
`;

const PageTitle = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.Global.darkFontColor};
`;

const NotificationWrapper = styled.div`
  flex-direction: column;
  margin-top: 19px;
`;

const UserNameBox = styled.span`
  display: inline-block;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.Global.darkFontColor};
  white-space: nowrap;
`;

const UserArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
`;

const IconHolder = styled.div`
  height: 64px;
  flex-direction: row;
  align-items: center;
  margin-right: 25px;
  justify-content: center;
  svg {
    width: 26px;
    height: 26px;
  }
`;

const IconHolderCross = styled.div`
  flex-direction: row;
  align-items: center;
  margin-left: 25px;
  justify-content: center;
  svg {
    width: 12px;
    height: 12px;
  }
`;
//@ts-ignore
const IconHolderRefresh = styled(IconHolderCross)`
  padding-top: 4px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const UserImageBox = styled.span`
  display: block;
  width: 44px;
  min-width: 44px;
  height: 44px;

  margin-top: 10px;
  margin-left: 8px;
  margin-right: 2px;
  box-sizing: border-box;

  border: 2px solid #98a9bc;
  border-radius: 44px;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const Username = styled.span`
  display: block;
  padding: 0 15px 0 15px;
  max-width: 155px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.Global.darkFontColor};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
`;

export default HeaderComponent;
