//@ts-nocheck
import React, { SyntheticEvent } from 'react';
import {
  ACenteredOverlay,
  IPropsCenter,
  IStateCenter,
} from '../../../logic/handler/overlayhandler/globaloverlays/aCenteredOverlay';
import styled from 'styled-components';
import {
  OverlayHandler,
  Overlays,
} from '../../../logic/handler/overlayhandler/overlayHandler';
import { FlexBox } from '../../../content/auth/auth.css';
import Title from '../title';
import { BigInput } from '../../atomiccompoents/form/inputs.css';
import { MessageHandler } from '../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../logic/handler/messagehandler/messageHandlerConfig';
import { translate } from '../../../common/language/translate';
import { Icons } from '../../../images';

const StyledViewMain = styled(FlexBox)`
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  border: 1px solid ${props => props.theme.Button.backgroundColor};
  font-family: Roboto, 'Helvetica Neue', sans-serif;

  padding: 16px;
  width: 400px;
  height: 180px;

  box-sizing: border-box;
`;
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  margin-top: 12px;
  justify-content: flex-start;
  box-sizing: border-box;
  & > div {
    justify-self: flex-start;
  }
  & > div > div > input {
    width: 291px;
    height: 37px;
    position: absolute;
    z-index: 10000;
    left: 0;
    top: 0;
    cursor: pointer;
    opacity: 0;
  }
`;
const SelectBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -250px;
`;
const SelectButton = styled.button`
  content: 'Browse';
  height: 37px;
  width: 37px;
  margin-left: 2px;
  margin-top: 12px;
  padding: 1px;
  line-height: 16px;
  color: #555;
  background-color: ${props => props.theme.Global.primaryColor};
  border: 0.075rem solid #ddd;
  border-radius: 4px;
  box-shadow: ${props => props.theme.Box.boxShadow};
`;

const Upload = styled.form`
  border: none;
`;

interface IProps extends IPropsCenter {
  callback: (file: any) => void;
  title?: string;
  fileType?: string;
}

interface IState extends IStateCenter {}

export class FileChooserOverlay extends ACenteredOverlay<IProps, IState> {
  private ButtonStyle = {
    width: '100%',
  };

  constructor(props: IProps) {
    super(props);

    this.state = {};
    this.close = this.close.bind(this);
    this.fileChange = this.fileChange.bind(this);
  }

  handleClick(event: SyntheticEvent): void {
    event.stopPropagation();
  }
  reviveState(): void {}

  fileChange(path: string) {
    //@ts-ignore
    const elem = document!!.getElementById('filechooser-input-overlay')!!;
    //@ts-ignore
    let file = path;
    //@ts-ignore
    if (elem.files != null && elem.files[0] != null) {
      //@ts-ignore
      file = elem.files[0];
      //@ts-ignore
      if (!file.type.match('image.*') && this.props.fileType !== 'all') {
        MessageHandler.onError(Reporter['person.merchant.document.wrongtype']);
        return;
      }
    }
    this.props.callback(file);
    this.close();
  }
  defineContent() {
    return (
      <StyledViewMain>
        <Title title={this.props.title != null ? this.props.title : 'Select file'} />
        <Upload enc-type="multipart/form-data">
          <Wrapper>
            <BigInput onChange={this.fileChange} id="filechooser-input-overlay" type="file" />
            <SelectBox>
              <BigInput
                valueFromState={true}
                id="replacer"
                type="text"
                value={''}
                label="Select file..."
                placeHolder={translate('customers.details.merchant.select')}
              />
              <SelectButton onClick={event => event.preventDefault}>
                {Icons.browse()}
              </SelectButton>
            </SelectBox>
          </Wrapper>
        </Upload>
      </StyledViewMain>
    );
  }

  close() {
    OverlayHandler.closeSpecific(Overlays.fileChooserOverlay);
  }
}
