//@ts-nocheck
import React from 'react';
import styled from 'styled-components';
import { api, apis, ApiError, Ident, VoidResponse } from '../../../../logic/api';
import {
  IInitProps,
  IInitState,
  InitialDataComponent,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import {
  IStatusState,
  Reporter,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import {
  INotifyAbleCustomerData,
  CustomerDataStore,
  PermissionStore,
} from '../../../../logic/flux';
import { translate } from '../../../../common/language/translate';
import { FlexBox } from '../../../auth/auth.css';
import {
  CustomCell,
  TitleCell,
  Helpertext,
  Table,
  HelpTextWrapper,
  CustomRow,
  CheckboxWrapper,
  KeyField,
  ValueField,
} from './basicStyledComponents/customerDetails.css';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import { CheckBox } from '../../../../components/atomiccompoents/form';
import { format } from '../../../../logic/helper/format';
import { KYCStatus } from '../../../../logic/api/ident';
import { Icons } from '../../../../images';
import Expandable from '../../../../components/compositcomponents/expandable/expandable';
import { compareDatetime, evaluateErrorMessage } from '../../../../logic/helper/Common';
import { ContainerText, TableBox } from '../transfer/transfersComponent.css';
import Title from '../../../../components/compositcomponents/title';
import { Table as CoreTable } from '../../../../components/compositcomponents/table/table';
import { RowType } from '../../../../components/compositcomponents/table/tableTypes';
import {
  OverlayHandler,
  Overlays,
} from '../../../../logic/handler/overlayhandler/overlayHandler';
import { DisplayMenuComponent } from '../../../../components/compositcomponents/displayMenuComponent';
import { CustomDatePicker } from '../../../../components/datepicker';
import { StyledLine } from '../backoffice/basicStyledComponents/styled';
import { ConfigStore } from '../../../../logic/flux/stores/configStore';
import { ClientConfig } from '../../../../common/config';

interface IProps extends IInitProps {
  personId?: number;
}
interface IState extends IStatusState, IInitState {
  selectedUser?: Ident.Person;
  pep?: boolean;
  fatca?: boolean;
  state?: string;
  systemState?: string;
  own_behalf?: boolean;
  resident?: boolean;
  age_check?: boolean;
  eu_member?: boolean;
  dataChanged?: boolean;
  editable?: boolean;
  risk_score?: string;
  keyForLoadingSpinner?: number;
  stateUpdate?: Date;
  primary?: string;
  kycHistory?: Array<KYCStatus>;
  historyOpen?: boolean;
  kycOPen?: boolean;
  systemOpen?: boolean;
  personHistory?: IHistoryDiff;
  date_from?: Date;
  date_to?: Date;
}

interface IPersonDiff extends Ident.PersonCoreHistory {
  diffs?: Array<string>;
}
interface IAddressDiff extends Ident.AdressHistory {
  diffs?: Array<string>;
}
interface IEmailDiff extends Ident.EmailHistory {
  diffs?: Array<string>;
}
interface IPhoneDiff extends Ident.PhoneHistory {
  diffs?: Array<string>;
}
interface IHistoryDiff {
  person_history?: Array<IPersonDiff>;
  address_history?: Array<IAddressDiff>;
  email_history?: Array<IEmailDiff>;
  phone_history?: Array<IPhoneDiff>;
}
const StyledContentBox = styled(FlexBox)`
  flex-direction: column;
  padding: 0px 32px;
  overflow-x: auto;
`;

const ResposnsiveTableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1400px) {
    max-width: 800px;
    align-items: flex-start;
  }
  @media (max-width: 1100px) {
    max-width: 500px;
    align-items: flex-start;
  }
`;
export default class CustomerDetailsKYC extends InitialDataComponent<IProps, IState>
  implements INotifyAbleCustomerData {
  constructor(props: IProps) {
    super(props);
    const date = new Date();
    const endDate = new Date();
    endDate.setFullYear(endDate.getFullYear() - 1);
    this.state = {
      dataChanged: false,
      editable: PermissionStore.hasPermission(Ident.OperationId.PersonPut.toString()),
      historyOpen: false,
      date_from: endDate,
      date_to: date,
    };

    this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
    this.save = this.save.bind(this);
  }
  private converter = function(line: any, index: number, fields: Array<string>): RowType {
    const row: RowType = { cells: [], ref: line };
    if (row.cells == null) {
      return row;
    }
    const keys: Array<string> = Object.keys(line);
    if (keys.indexOf('invalid_from') === -1) {
      keys.push('invalid_from');
      line['invalid_from'] = 'still valid';
    }
    for (let i = 0; i < fields.length; i++) {
      const field: string = fields[i];
      const diffs: Array<string> | undefined = line.diffs;
      switch (field) {
        case 'pep':
        case 'fatca':
        case 'is_merchant':
        case 'own_behalf':
        case 'us_person':
        case 'resident': {
          row.cells.push({
            value: String(Object.values(line)[keys.indexOf(field)]),
            display: (
              <ContainerText
                style={{
                  color: diffs != null && diffs.indexOf(field) !== -1 ? '#fa8072' : 'black',
                }}
                title={String(Object.values(line)[keys.indexOf(field)])}
              >
                <IconBox
                  color={diffs != null && diffs.indexOf(field) !== -1 ? '#fa8072' : 'black'}
                >
                  {Object.values(line)[keys.indexOf(field)] === true
                    ? Icons.checkBoxIcon()
                    : Icons.closingCross()}
                </IconBox>
              </ContainerText>
            ),
            methods: {},
            copyVal: String(Object.values(line)[keys.indexOf(field)]),
          });
          break;
        }
        default: {
          if (Object.prototype.hasOwnProperty.call(line, field)) {
            row.cells.push({
              value: String(Object.values(line)[keys.indexOf(field)]),
              display: (
                <ContainerText
                  style={{
                    color: diffs != null && diffs.indexOf(field) !== -1 ? '#fa8072' : 'black',
                  }}
                  title={String(Object.values(line)[keys.indexOf(field)])}
                >
                  {(field.includes('valid') || field.includes('datetime')) &&
                  Object.values(line)[keys.indexOf(field)] !== 'still valid'
                    ? format.datetime(Object.values(line)[keys.indexOf(field)] as Date)
                    : field.includes('date') && !field.includes('update')
                    ? format.date(Object.values(line)[keys.indexOf(field)] as Date)
                    : String(Object.values(line)[keys.indexOf(field)])}
                </ContainerText>
              ),
              methods: {},
              copyVal: String(Object.values(line)[keys.indexOf(field)]),
            });
          } else {
            row.cells.push({ value: '' });
          }
          break;
        }
      }
    }
    return row;
  };

  _onChangeCustomerData() {
    const tempUser = CustomerDataStore.getUser();
    if (tempUser != null) {
      this.getFullPerson(tempUser.person_id);
      this.getKYCStatus(tempUser.person_id);
      this.getKYCHistory(tempUser.person_id);
      this.getPersonHistory(tempUser.person_id);
    }
  }

  getFullPerson(personId: number) {
    const params: Ident.PersonPersonIdGetRequest = {
      person_id: personId,
    };
    api.request(
      params,
      apis.MaintenanceApi,
      'personPersonIdGet',
      (error: ApiError, response: Ident.Person) => {
        if (response != null) {
          const selectedUser = response;
          this.setState({
            selectedUser: selectedUser,
            pep: selectedUser.pep,
            fatca: selectedUser.fatca,
            state: selectedUser.state,
            resident: selectedUser.resident,
            own_behalf: selectedUser.own_behalf,
            age_check: selectedUser.age_check,
            risk_score: selectedUser.risk_score,
            eu_member: selectedUser.is_eu_member,
            primary: selectedUser.client_profile,
            editable:
              PermissionStore.hasPermission(Ident.OperationId.PersonPut.toString()) &&
              selectedUser.state !== Ident.PersonStateEnum.C,
          });
        } else {
          this.setState({
            selectedUser: undefined,
          });
        }
      },
    );
  }

  getKYCStatus(personId: number) {
    const params: Ident.PersonKycStatusGetRequest = {
      person_id: personId,
    };
    api.request(
      params,
      apis.MaintenanceApi,
      'personKycStatusGet',
      (error: ApiError, response: Ident.KYCStatus) => {
        if (response != null) {
          this.setState({
            systemState: response.kyc_status,
            stateUpdate: response.datetime_create,
          });
        }
      },
    );
  }

  getPersonHistory(personId: number) {
    const params: Ident.PersonHistoryGetRequest = {
      person_id: personId,
      date_from: this.state.date_from,
      date_to: this.state.date_to,
    };
    api.request(
      params,
      apis.MaintenanceApi,
      'personHistoryGet',
      (error: ApiError, response: Ident.PersonHistory) => {
        if (response != null) {
          this.setState({
            personHistory: this.setUpDiffs(response),
          });
        }
      },
    );
  }

  setUpDiffs(data: Ident.PersonHistory): IHistoryDiff {
    const result: IHistoryDiff = {
      person_history: [],
      address_history: [],
      email_history: [],
      phone_history: [],
    };
    if (data.person_history != null && data.person_history.length > 0) {
      const personHistory = [];
      for (let i = 0; i < data.person_history.length; i++) {
        if (i === data.person_history.length - 1) {
          personHistory.push(data.person_history[i]);
        } else {
          const keys = Object.keys(data.person_history[i]);
          const keysBefore = Object.keys(data.person_history[i + 1]);

          const diffs = [];
          for (const o of keys) {
            if (
              keysBefore.indexOf(o) === -1 &&
              o !== 'valid_from' &&
              o !== 'invalid_from' &&
              o !== 'person_update'
            ) {
              diffs.push(o);
            } else {
              //@ts-ignore
              if (
                data.person_history[i][o] !== data.person_history[i + 1][o] &&
                o !== 'valid_from' &&
                o !== 'invalid_from' &&
                o !== 'person_update'
              ) {
                diffs.push(o);
              }
            }
          }
          const dat: IPersonDiff = data.person_history[i];
          dat.diffs = diffs;
          personHistory.push(dat);
        }
      }
      result.person_history = personHistory;
    }
    if (data.address_history != null && data.address_history.length > 0) {
      const address_history = [];

      for (let i = 0; i < data.address_history.length; i++) {
        if (i === data.address_history.length - 1) {
          address_history.push(data.address_history[i]);
        } else {
          const keys = Object.keys(data.address_history[i]);
          const keysBefore = Object.keys(data.address_history[i + 1]);

          const diffs = [];
          for (const o of keys) {
            if (
              keysBefore.indexOf(o) === -1 &&
              o !== 'valid_from' &&
              o !== 'invalid_from' &&
              o !== 'person_update'
            ) {
              diffs.push(o);
            } else {
              //@ts-ignore
              if (
                data.address_history[i][o] !== data.address_history[i + 1][o] &&
                o !== 'valid_from' &&
                o !== 'invalid_from' &&
                o !== 'person_update'
              ) {
                diffs.push(o);
              }
            }
          }
          const dat: IAddressDiff = data.address_history[i];
          dat.diffs = diffs;
          address_history.push(dat);
        }
      }
      result.address_history = address_history;
    }
    if (data.email_history != null && data.email_history.length > 0) {
      const email_history = [];
      for (let i = 0; i < data.email_history.length; i++) {
        if (i === data.email_history.length - 1) {
          email_history.push(data.email_history[i]);
        } else {
          const keys = Object.keys(data.email_history[i]);
          const keysBefore = Object.keys(data.email_history[i + 1]);

          const diffs = [];
          for (const o of keys) {
            if (
              keysBefore.indexOf(o) === -1 &&
              o !== 'valid_from' &&
              o !== 'invalid_from' &&
              o !== 'person_update'
            ) {
              diffs.push(o);
            } else {
              //@ts-ignore
              if (
                data.email_history[i][o] !== data.email_history[i + 1][o] &&
                o !== 'valid_from' &&
                o !== 'invalid_from' &&
                o !== 'person_update'
              ) {
                diffs.push(o);
              }
            }
          }
          const dat: IEmailDiff = data.email_history[i];
          dat.diffs = diffs;
          email_history.push(dat);
        }
      }
      result.email_history = email_history;
    }
    if (data.phone_history != null && data.phone_history.length > 0) {
      const phone_history = [];
      for (let i = 0; i < data.phone_history.length; i++) {
        if (i === data.phone_history.length - 1) {
          phone_history.push(data.phone_history[i]);
        } else {
          const keys = Object.keys(data.phone_history[i]);
          const keysBefore = Object.keys(data.phone_history[i + 1]);
          const diffs = [];
          for (const o of keys) {
            if (
              keysBefore.indexOf(o) === -1 &&
              o !== 'valid_from' &&
              o !== 'invalid_from' &&
              o !== 'person_update'
            ) {
              diffs.push(o);
            } else {
              //@ts-ignore
              if (
                data.phone_history[i][o] !== data.phone_history[i + 1][o] &&
                o !== 'valid_from' &&
                o !== 'invalid_from' &&
                o !== 'person_update'
              ) {
                diffs.push(o);
              }
            }
          }
          const dat: IPhoneDiff = data.phone_history[i];
          dat.diffs = diffs;
          phone_history.push(dat);
        }
      }
      result.phone_history = phone_history;
    }
    return result;
  }

  getKYCHistory(personId: number) {
    const params: Ident.PersonKycHistoryGetRequest = {
      person_id: personId,
    };
    api.request(
      params,
      apis.MaintenanceApi,
      'personKycHistoryGet',
      (error: ApiError, response: Array<KYCStatus>) => {
        if (response != null) {
          this.setState({
            kycHistory: response,
          });
        }
      },
    );
  }

  reviveState(newState: { [key: string]: any }): void {}

  componentDidMount(): void {
    CustomerDataStore.addChangeListener(this._onChangeCustomerData);
    this._onChangeCustomerData();
  }

  componentWillUnmount() {
    CustomerDataStore.removeChangeListener(this._onChangeCustomerData);
  }

  onChangeCheckBox(key: string, value: boolean) {
    const state = this.state;
    //@ts-ignore
    state[key as keyof IState] = value;
    this.setState({
      ...state,
      dataChanged: true,
    });
  }

  save() {
    if (this.state.selectedUser == null) {
      return;
    }
    const req: Ident.PersonUpdateRequest = {
      pep: this.state.pep,
      fatca: this.state.fatca,
      own_behalf: this.state.own_behalf,
      resident: this.state.resident,
      age_check: this.state.age_check,
    };
    api
      .asyncRequest<VoidResponse>(
        {
          person_id: this.state.selectedUser.person_id,
          PersonUpdateRequest: req,
        },
        apis.MaintenanceApi,
        'personPut',
      )
      .then(() => {
        this.setState({
          keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          dataChanged: false,
        });
        MessageHandler.onSuccess(Reporter['customer.details.data.put.request']);
      })
      .catch((error: ApiError) => {
        this.setState({
          keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
        });
        MessageHandler.onError(
          Reporter['customer.details.data.put.request'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
      });
  }

  sortKyc(a: KYCStatus, b: KYCStatus): 1 | 0 | -1 {
    return compareDatetime(b.datetime_create, a.datetime_create);
  }

  createKYCHistory() {
    if (this.state.kycHistory == null) {
      return;
    }
    const out = [];
    out.push(
      <KeyValueBlock key="kycHeader">
        <KeyBlock style={{ width: '50%' }}>
          {translate('customers.details.KYC.status')}
        </KeyBlock>
        <KeyBlock style={{ width: '50%' }}>
          {translate('customers.details.KYC.datetimeCreate')}
        </KeyBlock>
      </KeyValueBlock>,
    );
    let kycData = this.state.kycHistory;
    if (kycData != null && kycData.length > 0) {
      kycData = kycData.sort(this.sortKyc);
    }
    for (const i in kycData) {
      out.push(
        <KeyValueBlock key={'kycData' + i}>
          <ValueBlock style={{ width: '50%' }}>
            {translate(
              'customers.details.KYC.systemStates.' + kycData[i].kyc_status,
              kycData[i].kyc_status,
            )}
          </ValueBlock>
          <ValueBlock style={{ width: '50%' }}>
            {format.datetime(kycData[i].datetime_create)}
          </ValueBlock>
        </KeyValueBlock>,
      );
    }
    return out;
  }

  render() {
    return (
      <StyledContentBox>
        <ResposnsiveTableWrapper>
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: '32px',
              justifyContent: 'flex-start',
            }}
          >
            <Expandable
              open={true}
              title={translate('customers.details.KYC.systemValues', 'System')}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Table
                  style={{
                    maxWidth: '1000px',
                    paddingRight: '32px',
                    borderSpacing: '0',
                    marginLeft: '16px',
                  }}
                >
                  <tbody>
                    <CustomRow>
                      <NoBorderSubTitleCell>
                        <TitleCell>
                          {translate('customers.details.KYC.customerState', 'State')}
                        </TitleCell>
                      </NoBorderSubTitleCell>
                      <NoBorderSubTitleCell
                        style={{
                          textAlign: 'right',
                          paddingRight: '16px',
                        }}
                      >
                        {this.state.state != null
                          ? translate('customers.details.KYC.state.' + this.state.state, ' - ')
                          : ' - '}
                      </NoBorderSubTitleCell>
                    </CustomRow>
                    <CustomRow>
                      <NoBorderSubTitleCell>
                        <TitleCell
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <div
                            style={{ marginRight: '8px' }}
                            onClick={() => {
                              this.setState({
                                historyOpen: !this.state.historyOpen,
                              });
                            }}
                          >
                            {this.state.historyOpen === true
                              ? Icons.arrowFacingDown()
                              : Icons.arrowFacingRight()}
                          </div>
                          {translate('customers.details.KYC.systemState', 'System-State')}
                        </TitleCell>
                      </NoBorderSubTitleCell>
                      <NoBorderSubTitleCell
                        style={{
                          textAlign: 'right',
                          paddingRight: '16px',
                        }}
                      >
                        {this.state.systemState != null
                          ? translate(
                              'customers.details.KYC.systemStates.' + this.state.systemState,
                              ' - ',
                            )
                          : ' - '}
                      </NoBorderSubTitleCell>
                    </CustomRow>
                    {this.state.historyOpen === true ? (
                      <CustomRow>
                        <NoBorderSubTitleCell colSpan={2}>
                          <DataBlock>{this.createKYCHistory()}</DataBlock>
                        </NoBorderSubTitleCell>
                      </CustomRow>
                    ) : null}
                    <CustomRow>
                      <CustomCell>
                        <TitleCell>
                          {translate('customers.details.KYC.last_updated')}
                        </TitleCell>
                      </CustomCell>
                      <CustomCell
                        style={{
                          textAlign: 'right',
                          paddingRight: '16px',
                        }}
                      >
                        {this.state.stateUpdate != null
                          ? format.date(this.state.stateUpdate)
                          : ' - '}
                      </CustomCell>
                    </CustomRow>
                    <CustomRow>
                      <CustomCell>
                        <TitleCell>{'Risk score'}</TitleCell>
                      </CustomCell>
                      <CustomCell
                        style={{
                          textAlign: 'right',
                          paddingRight: '16px',
                        }}
                      >
                        {this.state.risk_score}
                      </CustomCell>
                    </CustomRow>
                  </tbody>
                </Table>
              </div>
            </Expandable>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: '32px',
              justifyContent: 'flex-start',
            }}
          >
            <Expandable title={translate('customers.details.KYC.kycValues', 'KYC')}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Table
                  style={{
                    maxWidth: '1000px',
                    paddingRight: '32px',
                    borderSpacing: '0',
                    marginLeft: '16px',
                  }}
                >
                  <tbody>
                    <CustomRow>
                      <NoBorderSubTitleCell>
                        <TitleCell>{translate('customers.details.KYC.client')}</TitleCell>
                      </NoBorderSubTitleCell>
                      <NoBorderSubTitleCell
                        style={{
                          textAlign: 'right',
                          paddingRight: '16px',
                        }}
                      >
                        {translate(
                          'customers.details.KYC.profile.' + this.state.primary,
                          ' - ',
                        )}
                      </NoBorderSubTitleCell>
                    </CustomRow>
                    <CustomRow>
                      <NoBorderSubTitleCell>
                        <TitleCell>{translate('customers.details.KYC.pep', 'Pep')}</TitleCell>
                      </NoBorderSubTitleCell>
                      <NoBorderCell>
                        <HelpTextWrapper>
                          <CheckboxWrapper style={{ justifyContent: 'flex-end' }}>
                            <CheckBox
                              id="kyc-pep"
                              disabled={!this.state.editable || !ClientConfig.kycEdit}
                              value={this.state.pep === true}
                              onChange={(value: boolean) =>
                                this.onChangeCheckBox('pep', value)
                              }
                            />
                          </CheckboxWrapper>
                          <HelperCheckBox></HelperCheckBox>
                        </HelpTextWrapper>
                      </NoBorderCell>
                    </CustomRow>
                    <CustomRow>
                      <NoBorderSubTitleCell>
                        <TitleCell>{translate('customers.details.KYC.fatca')}</TitleCell>
                      </NoBorderSubTitleCell>
                      <NoBorderCell>
                        <HelpTextWrapper>
                          <CheckboxWrapper style={{ justifyContent: 'flex-end' }}>
                            <CheckBox
                              id="kyc-fatca"
                              disabled={!this.state.editable || !ClientConfig.kycEdit}
                              value={this.state.fatca === true}
                              onChange={(value: boolean) =>
                                this.onChangeCheckBox('fatca', value)
                              }
                            />
                          </CheckboxWrapper>
                          <HelperCheckBox></HelperCheckBox>
                        </HelpTextWrapper>
                      </NoBorderCell>
                    </CustomRow>
                    <CustomRow>
                      <NoBorderSubTitleCell>
                        <TitleCell>{translate('customers.details.KYC.age_check')}</TitleCell>
                      </NoBorderSubTitleCell>
                      <NoBorderCell>
                        <HelpTextWrapper>
                          <CheckboxWrapper style={{ justifyContent: 'flex-end' }}>
                            <CheckBox
                              id="kyc-us_person"
                              disabled={!this.state.editable}
                              value={this.state.age_check === true}
                              onChange={(value: boolean) =>
                                this.onChangeCheckBox('age_check', value)
                              }
                            />
                          </CheckboxWrapper>
                          <HelperCheckBox></HelperCheckBox>
                        </HelpTextWrapper>
                      </NoBorderCell>
                    </CustomRow>
                    <CustomRow>
                      <NoBorderSubTitleCell>
                        <TitleCell>{translate('customers.details.KYC.resident')}</TitleCell>
                      </NoBorderSubTitleCell>
                      <NoBorderCell>
                        <HelpTextWrapper>
                          <CheckboxWrapper style={{ justifyContent: 'flex-end' }}>
                            <CheckBox
                              id="kyc-us_person"
                              disabled={!this.state.editable || !ClientConfig.kycEdit}
                              value={this.state.resident === true}
                              onChange={(value: boolean) =>
                                this.onChangeCheckBox('resident', value)
                              }
                            />
                          </CheckboxWrapper>
                          <HelperCheckBox></HelperCheckBox>
                        </HelpTextWrapper>
                      </NoBorderCell>
                    </CustomRow>
                    <CustomRow>
                      <NoBorderSubTitleCell>
                        <TitleCell>{translate('customers.details.KYC.own_behalf')}</TitleCell>
                      </NoBorderSubTitleCell>
                      <NoBorderCell>
                        <HelpTextWrapper>
                          <CheckboxWrapper style={{ justifyContent: 'flex-end' }}>
                            <CheckBox
                              id="kyc-us_person"
                              disabled={!this.state.editable || !ClientConfig.kycEdit}
                              value={this.state.own_behalf === true}
                              onChange={(value: boolean) =>
                                this.onChangeCheckBox('own_behalf', value)
                              }
                            />
                          </CheckboxWrapper>
                          <HelperCheckBox></HelperCheckBox>
                        </HelpTextWrapper>
                      </NoBorderCell>
                    </CustomRow>
                    <CustomRow>
                      <NoBorderSubTitleCell>
                        <TitleCell>{translate('customers.details.KYC.eu_member')}</TitleCell>
                      </NoBorderSubTitleCell>
                      <NoBorderCell>
                        <HelpTextWrapper>
                          <CheckboxWrapper style={{ justifyContent: 'flex-end' }}>
                            <CheckBox
                              id="kyc-us_person"
                              disabled={true}
                              value={this.state.eu_member === true}
                              onChange={(value: boolean) =>
                                this.onChangeCheckBox('eu_member', value)
                              }
                            />
                          </CheckboxWrapper>
                          <HelperCheckBox></HelperCheckBox>
                        </HelpTextWrapper>
                      </NoBorderCell>
                    </CustomRow>
                  </tbody>
                </Table>
              </div>
            </Expandable>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: '32px',
              justifyContent: 'flex-start',
            }}
          >
            <Expandable
              open={false}
              title={translate('customers.details.KYC.personHistory.title', 'KYC')}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '48px',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Dates>
                  <KeyValueBlock
                    style={{
                      width: '30%',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                  >
                    <KeyField>{translate('administration.systemstate.dateFrom')}:</KeyField>
                    <ValueField>
                      <DisplayMenuComponent
                        elementKey="startDate"
                        copyValue={
                          this.state.date_from != null ? this.state.date_from.toString() : ''
                        }
                        methods={{}}
                      >
                        <CustomDatePicker
                          selectedValue={this.state.date_from}
                          onChange={(date: Date) => {
                            this.setState(
                              {
                                date_from: date,
                              },
                              () => {
                                if (this.state.selectedUser != null) {
                                  this.getPersonHistory(this.state.selectedUser?.person_id);
                                }
                              },
                            );
                          }}
                          displayDate={true}
                          resetCallback={() => {
                            this.setState(
                              {
                                date_from: undefined,
                              },
                              () => {
                                if (this.state.selectedUser != null) {
                                  this.getPersonHistory(this.state.selectedUser?.person_id);
                                }
                              },
                            );
                          }}
                          boxStyle={{
                            boxShadow: 'none',
                            borderRadius: '0px',
                            height: '24px',
                            border: 'none',
                            margin: '0px',
                            justifyContent: 'flex-start',
                          }}
                          textStyle={{
                            color: '#4a4a4a',
                            textAlign: 'left',
                            margin: '0px',
                            marginLeft: '8px',
                            marginRight: '8px',
                            fontWeight: 400,
                            fontSize: '16px',
                          }}
                          maxDate={this.state.date_to}
                          number={0}
                          toggleHeightOffset={16}
                          smallText={true}
                          showTimePicker={true}
                          showMenu={false}
                        />
                      </DisplayMenuComponent>
                    </ValueField>
                  </KeyValueBlock>
                  <KeyValueBlock
                    style={{
                      width: '30%',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                  >
                    <KeyField>{translate('administration.systemstate.dateTo')}:</KeyField>
                    <ValueField>
                      <DisplayMenuComponent
                        elementKey="endDate"
                        copyValue={
                          this.state.date_to != null ? this.state.date_to.toString() : ''
                        }
                        methods={{}}
                      >
                        <CustomDatePicker
                          selectedValue={this.state.date_to}
                          onChange={(date: Date) => {
                            this.setState(
                              {
                                date_to: date,
                              },
                              () => {
                                if (this.state.selectedUser != null) {
                                  this.getPersonHistory(this.state.selectedUser?.person_id);
                                }
                              },
                            );
                          }}
                          displayDate={true}
                          resetCallback={() => {
                            this.setState(
                              {
                                date_to: undefined,
                              },
                              () => {
                                if (this.state.selectedUser != null) {
                                  this.getPersonHistory(this.state.selectedUser?.person_id);
                                }
                              },
                            );
                          }}
                          boxStyle={{
                            boxShadow: 'none',
                            borderRadius: '0px',
                            height: '24px',
                            border: 'none',
                            margin: '0px',
                            justifyContent: 'flex-start',
                          }}
                          textStyle={{
                            marginRight: '0px',
                            color: '#4a4a4a',
                            textAlign: 'left',
                            margin: '0px',
                            fontWeight: 400,
                            fontSize: '16px',
                          }}
                          minDate={this.state.date_from}
                          number={1}
                          toggleHeightOffset={16}
                          smallText={true}
                          showTimePicker={true}
                          showMenu={false}
                        />
                      </DisplayMenuComponent>
                    </ValueField>
                  </KeyValueBlock>
                </Dates>
                <StyledLine />
                <TableBoxCoreData>
                  <Title
                    title={translate('customers.details.KYC.personHistory.personCore', 'KYC')}
                  />
                  <CoreTable
                    sourceData={
                      this.state.personHistory != null &&
                      this.state.personHistory.person_history != null
                        ? this.state.personHistory.person_history
                        : []
                    }
                    fields={[
                      'name',
                      'given_name',
                      'sex',
                      'birth_date',
                      'birth_city',
                      'company_name',
                      'nationality',
                      'fatca',
                      'is_merchant',
                      'own_behalf',
                      'us_person',
                      'resident',
                      'pep',
                      'state',
                      'valid_from',
                      'invalid_from',
                      'person_update',
                    ]}
                    dataConverter={this.converter}
                    hidePaging={true}
                    header={[
                      translate('customers.details.KYC.personHistory.person.name'),
                      translate('customers.details.KYC.personHistory.person.given_name'),
                      translate('customers.details.KYC.personHistory.person.sex'),
                      translate('customers.details.KYC.personHistory.person.birth_date'),
                      translate('customers.details.KYC.personHistory.person.birth_city'),
                      translate('customers.details.KYC.personHistory.person.company_name'),
                      translate('customers.details.KYC.personHistory.person.nationality'),
                      translate('customers.details.KYC.personHistory.person.fatca'),
                      translate('customers.details.KYC.personHistory.person.is_merchant'),
                      translate('customers.details.KYC.personHistory.person.own_behalf'),
                      translate('customers.details.KYC.personHistory.person.us_person'),
                      translate('customers.details.KYC.personHistory.person.resident'),
                      translate('customers.details.KYC.personHistory.person.pep'),
                      translate('customers.details.KYC.personHistory.person.state'),
                      translate('customers.details.KYC.personHistory.person.valid_from'),
                      translate('customers.details.KYC.personHistory.person.invalid_from'),
                      translate('customers.details.KYC.personHistory.person.person_update'),
                    ]}
                  />
                </TableBoxCoreData>
                <TableBoxCoreData>
                  <Title
                    title={translate('customers.details.KYC.personHistory.address', 'KYC')}
                  />
                  <CoreTable
                    sourceData={
                      this.state.personHistory != null &&
                      this.state.personHistory.address_history != null
                        ? this.state.personHistory.address_history
                        : []
                    }
                    fields={[
                      'street',
                      'building_identifier',
                      'city',
                      'country',
                      'suite_identifier',
                      'floor_identifier',
                      'district_name',
                      'post_box',
                      'post_code',
                      'valid_from',
                      'invalid_from',
                      'person_update',
                    ]}
                    dataConverter={this.converter}
                    hidePaging={true}
                    header={[
                      translate('customers.details.KYC.personHistory.addresses.street'),
                      translate(
                        'customers.details.KYC.personHistory.addresses.building_identifier',
                      ),
                      translate('customers.details.KYC.personHistory.addresses.city'),
                      translate('customers.details.KYC.personHistory.addresses.country'),
                      translate(
                        'customers.details.KYC.personHistory.addresses.suite_identifier',
                      ),
                      translate(
                        'customers.details.KYC.personHistory.addresses.floor_identifier',
                      ),
                      translate('customers.details.KYC.personHistory.addresses.district_name'),
                      translate('customers.details.KYC.personHistory.addresses.post_box'),
                      translate('customers.details.KYC.personHistory.addresses.post_code'),
                      translate('customers.details.KYC.personHistory.person.valid_from'),
                      translate('customers.details.KYC.personHistory.person.invalid_from'),
                      translate('customers.details.KYC.personHistory.person.person_update'),
                    ]}
                  />
                </TableBoxCoreData>
                <TableBoxCoreData>
                  <DoubleBox>
                    <SmallTable>
                      <Title
                        title={translate('customers.details.KYC.personHistory.email', 'KYC')}
                      />
                      <CoreTable
                        sourceData={
                          this.state.personHistory != null &&
                          this.state.personHistory.email_history != null
                            ? this.state.personHistory.email_history
                            : []
                        }
                        fields={[
                          'email_address',
                          'datetime_confirmed',
                          'valid_from',
                          'invalid_from',
                          'person_update',
                        ]}
                        dataConverter={this.converter}
                        hidePaging={true}
                        header={[
                          translate(
                            'customers.details.KYC.personHistory.emails.email_address',
                          ),
                          translate(
                            'customers.details.KYC.personHistory.emails.datetime_confirmed',
                          ),
                          translate('customers.details.KYC.personHistory.person.valid_from'),
                          translate('customers.details.KYC.personHistory.person.invalid_from'),
                          translate(
                            'customers.details.KYC.personHistory.person.person_update',
                          ),
                        ]}
                      />
                    </SmallTable>
                    <SmallTable>
                      <Title
                        title={translate('customers.details.KYC.personHistory.phone', 'KYC')}
                      />
                      <CoreTable
                        sourceData={
                          this.state.personHistory != null &&
                          this.state.personHistory.phone_history != null
                            ? this.state.personHistory.phone_history
                            : []
                        }
                        fields={[
                          'phone_number',
                          'valid_from',
                          'invalid_from',
                          'person_update',
                        ]}
                        dataConverter={this.converter}
                        hidePaging={true}
                        header={[
                          translate('customers.details.KYC.personHistory.phones.phone_number'),
                          translate('customers.details.KYC.personHistory.person.valid_from'),
                          translate('customers.details.KYC.personHistory.person.invalid_from'),
                          translate(
                            'customers.details.KYC.personHistory.person.person_update',
                          ),
                        ]}
                      />
                    </SmallTable>
                  </DoubleBox>
                </TableBoxCoreData>
              </div>
            </Expandable>
          </div>
          <div
            style={{
              marginTop: '52px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ButtonOk disableSpinner={true} style={{ width: '130px', marginRight: '65px' }}>
              {translate('button.cancel')}
            </ButtonOk>
            <ButtonOk
              disabled={this.state.dataChanged === false}
              key={this.state.keyForLoadingSpinner}
              style={{ width: '130px' }}
              onClick={() => this.save()}
            >
              {translate('button.save')}
            </ButtonOk>
          </div>
        </ResposnsiveTableWrapper>
      </StyledContentBox>
    );
  }
}

const SmallCell = styled(CustomCell)`
  width: 25%;
`;
const CheckboxCell = styled(SmallCell)`
  text-align: center !important;
`;
const NoBorderCell = styled(CheckboxCell)`
  border: none;
  align-items: center;
  width: 50%;
  min-width: 0px;
`;
const NoBorderSubTitleCell = styled(SmallCell)`
  border: none;
  width: 50%;
`;

const HelperCheckBox = styled(Helpertext)`
  height: 12px;
  margin-top: -4px;
  width: 170px;
`;
const KeyValueBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  margin-top: 4px;
  width: 100%;
`;

const KeyBlock = styled.div`
  width: 50%;
  color: ${props => props.theme.Global.keyFontColor};
  font-size: 1em;
  font-weight: 500;
  line-height: 1.5;
`;

const ValueBlock = styled.div<{ isMax?: boolean }>`
  width: 50%;
  color: ${props => (props.isMax === true ? 'red' : props.theme.Global.darkFontColor)};
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
`;

const DataBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 85%;
`;
const TableBoxCoreData = styled.div`
  overflow-y: auto;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const DoubleBox = styled.div`
  display: flex;
  flex-direction: row;
`;
const SmallTable = styled.div`
  width: 50%;
`;
const Part = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const Dates = styled(Part)`
  width: 100%;
`;

const IconBox = styled.div<{ color: string }>`
  svg {
    fill: ${props => props.color};
    stroke: ${props => props.color};
    path {
      stroke: ${props => props.color};
    }
  }
`;
