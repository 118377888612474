// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, throwIfRequired, encodeURI } from '../runtime';
import { CryptoFiatTransaction, TransactionIdentifier } from '../models/index';

export interface CryptoTransactionUniqueIdentifierPutRequest {
  uniqueIdentifier: string;
  CryptoFiatTransaction: CryptoFiatTransaction;
}

/**
 * no description
 */
export class CryptoApi extends BaseAPI implements IOperationIdMap {
  /**
   * request the core to credit fiat to the account as a result of an already happend debit on the crypto side. detection of duplicates is done using the id. If an already processed event is received again and the new event is identical to the previous one, no error will be raised. if these two events differ, error 409 conflict will be raised
   * notify the core about occoured conversions from crypto tokes to fiat
   */
  cryptoTransactionUniqueIdentifierPut = (
    requestParameters: CryptoTransactionUniqueIdentifierPutRequest,
  ): Observable<TransactionIdentifier> => {
    throwIfRequired(
      requestParameters,
      'uniqueIdentifier',
      'cryptoTransactionUniqueIdentifierPut',
    );
    throwIfRequired(
      requestParameters,
      'CryptoFiatTransaction',
      'cryptoTransactionUniqueIdentifierPut',
    );

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<TransactionIdentifier>({
      path: '/crypto/transaction/{uniqueIdentifier}'.replace(
        '{uniqueIdentifier}',
        encodeURI(requestParameters.uniqueIdentifier),
      ),
      method: 'PUT',
      headers,
      body: requestParameters.CryptoFiatTransaction,
    });
  };

  operationToOperationId = {
    cryptoTransactionUniqueIdentifierPut: 'crypto_transaction_unique_identifier_put',
  };
}
