import React, { ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';
import {
  IStatusState,
  Reporter,
} from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import { api, apis, ApiError, Ident } from '../../../../../logic/api';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { translate } from '../../../../../common/language/translate';
import { Icons } from '../../../../../images';
import {
  IStateOver,
  IPropsOver,
  AOverlay,
} from '../../../../../logic/handler/overlayhandler/globaloverlays/aOverlay';
import {
  IInitProps,
  IInitState,
} from '../../../../../logic/handler/initialdatahandler/initialDataComponent';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import ButtonCancel from '../../../../../components/atomiccompoents/buttons/buttonCancel';
import Title from '../../../../../components/compositcomponents/title';
import {
  OverlayHandler,
  Overlays,
} from '../../../../../logic/handler/overlayhandler/overlayHandler';
import { StyledViewButtons, ValueField } from '../basicStyledComponents/customerDetails.css';
import EditableComponent from '../../../../../components/atomiccompoents/editableComponent/editableComponent';
import { updatePerson } from '../../../../../logic/helper/Common';

const StyledOverlay = styled.div`
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 50px;
  padding-top: 20;
  display: flex;
  flex-direction: column;
  width: 490px;
  height: 550px;
  align-items: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  overflow-y: auto;
`;

const IconWrapper = styled.div`
  margin-top: 16px;
  :hover {
    cursor: pointer;
  }
`;

interface IProps extends IInitProps, IPropsOver {
  personId: number;
  state?: Ident.PersonStateEnum;
}
interface IState extends IStatusState, IInitState, IStateOver {
  emailAddresses: Array<Ident.EmailAddress>;
  newMailIndex: number;
  keyForLoadingSpinner: number;
  containsUnverifiedMail?: boolean;
}

export default class MailEditOverlay extends AOverlay<IProps, IState> {
  private changeTracker: Array<string> = [];
  private containsUnverifiedMail: boolean = false;
  constructor(props: IProps) {
    super(props);
    this.state = {
      emailAddresses: [],
      newMailIndex: -1,
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    };
    this.getMailList = this.getMailList.bind(this);
    this.getMailList();
  }
  reviveState() {}
  stopPropagation(event: SyntheticEvent) {
    event.stopPropagation();
  }
  onCancel() {
    OverlayHandler.closeSpecific(Overlays.emailOverlay);
  }

  onSave() {
    if (this.state.newMailIndex >= 0) {
      this.emailPost();
    }
    if (this.changeTracker.length != null) {
      this.emailPut();
    }
    updatePerson(this.props.personId);
    this.onCancel();
  }

  deleteMail(mail: Ident.EmailAddress) {
    const params: Ident.PersonEmailaddressDeleteRequest = {
      person_id: this.props.personId,
      email_address_id: mail.email_address_id,
    };
    api
      .request(
        params,
        apis.MaintenanceApi,
        'personEmailaddressDelete',
        (error: ApiError, response: any) => {
          if (response != null) {
            MessageHandler.onSuccess(Reporter['customers.email.put']);
          } else {
            MessageHandler.onError(Reporter['customers.email.put']);
          }
        },
      )
      .catch(() => {
        MessageHandler.onError(Reporter['customers.email.put']);
      });
    this.setState(
      {
        keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
      },
      () => this.getMailList(),
    );
  }

  getMailList() {
    const req: Ident.PersonEmailaddressGetRequest = {
      person_id: this.props.personId,
      get_not_verified: true,
    };
    api.request(
      req,
      apis.MaintenanceApi,
      'personEmailaddressGet',
      (error: ApiError, response: Array<Ident.EmailAddress>) => {
        if (response != null) {
          this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            emailAddresses: response == null || response.length === 0 ? [] : response,
          });
        } else {
          //Todo Errorhandling
        }
      },
    );
  }

  emailPut() {
    const mails = this.state.emailAddresses;
    if (mails != null) {
      for (const i in this.changeTracker) {
        const key: number = parseInt(this.changeTracker[i]);
        if (mails[key].email_address_id < 0) {
          continue;
        }
        const params: Ident.PersonEmailAddressPutRequest = {
          person_id: this.props.personId,
          NewEmailAddress: {
            email_address: mails[key].email_address,
          },
        };
        api
          .request(
            params,
            apis.MaintenanceApi,
            'personEmailAddressPut',
            (error: ApiError, response: any) => {
              if (response != null) {
                MessageHandler.onSuccess(Reporter['customers.email.put']);
              } else {
                MessageHandler.onError(Reporter['customers.email.put']);
              }
            },
          )
          .catch(() => {
            MessageHandler.onError(Reporter['customers.email.put']);
          });
      }
    }
    this.setState({
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    });
  }

  emailPost() {
    const startIdx = this.state.newMailIndex;
    const mails = this.state.emailAddresses;
    if (startIdx !== -1) {
      for (let i = startIdx; i < this.state.emailAddresses.length; i++) {
        const params: Ident.PersonEmailAddressPostRequest = {
          person_id: this.props.personId,
          NewEmailAddress: {
            email_address: mails[i].email_address,
          },
        };
        api
          .request(
            params,
            apis.MaintenanceApi,
            'personEmailAddressPost',
            (error: ApiError, response: any) => {
              if (response != null) {
                MessageHandler.onSuccess(Reporter['customers.email.post']);
              } else {
                MessageHandler.onError(Reporter['customers.email.post']);
              }
            },
          )
          .catch(() => {
            MessageHandler.onError(Reporter['customers.email.put']);
          });
      }
    }
    this.setState({
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    });
  }
  onCreateKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  getMailFields(primary: boolean) {
    const out: Array<ReactElement> = [];
    const mails = this.state.emailAddresses;
    for (const i in mails) {
      if (mails[i].datetime_confirmed == null) {
        this.containsUnverifiedMail = true;
      }
      if ((!primary && !mails[i].is_primary) || (primary && mails[i].is_primary)) {
        out.push(
          <ValueField
            style={{
              marginBottom: '16px',
              minWidth: '280px',
              maxWidth: '280px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            key={'emailAddress-' + i}
          >
            {mails[i].datetime_confirmed == null ? '*' : ''}
            <EditableComponent
              id={mails[i].email_address_id.toString()}
              initText={mails[i].email_address}
              changeCallback={() => {}}
              inputStyle={{
                textAlign: 'left',
                marginBottom: '0px',
                marginTop: '2px',
              }}
              leaveCallback={() => {}}
              enterCallback={(value: string, key?: string) => {
                const mails = this.state.emailAddresses;
                mails[i].email_address = value;
                this.setState({
                  emailAddresses: mails,
                });
                if (this.changeTracker.indexOf(i) === -1) {
                  this.changeTracker.push(i);
                }
              }}
              viewStyle={mails[i].datetime_confirmed == null ? { color: 'grey' } : undefined}
              permission={Ident.OperationId.PersonEmailAddressPost}
              index={1}
            />
            {!mails[i].is_primary && mails[i].email_address_id > -1 ? (
              <IconWrapper
                onClick={() => {
                  this.deleteMail(mails[i]);
                }}
              >
                {' '}
                {Icons.del()}{' '}
              </IconWrapper>
            ) : null}
          </ValueField>,
        );
      }
    }
    return out;
  }

  defineContent() {
    return (
      <StyledOverlay onClick={event => event.stopPropagation()}>
        <HeaderBlock>
          <Title title={translate('customers.customersEmail')} />
        </HeaderBlock>
        <SubHeader>{translate('customers.emails.primary')}</SubHeader>
        <AddressBlock>{this.getMailFields(true)}</AddressBlock>
        <SubHeader>
          {translate('customers.emails.secondary')}
          <IconWrapper
            onClick={() => {
              const mails = this.state.emailAddresses;
              mails.push({
                email_address: '...',
                email_address_id: -1,
                is_primary: false,
                datetime_update: new Date(),
              });
              this.setState({
                emailAddresses: mails,
                newMailIndex: mails.length - 1,
              });
            }}
            style={{ marginLeft: '16px' }}
          >
            {Icons.addMail()}
          </IconWrapper>
        </SubHeader>
        <AddressBlock>{this.getMailFields(false)}</AddressBlock>
        <StyledViewButtons>
          <ButtonCancel id="btnEditCustomerEmailCancel" onClick={this.onCancel}>
            {translate('button.cancel')}
          </ButtonCancel>
          <ButtonOk
            key={this.state.keyForLoadingSpinner}
            disabled={
              this.props.state === Ident.PersonStateEnum.C ||
              (this.changeTracker.length === 0 && this.state.newMailIndex === -1)
            }
            onClick={(event: SyntheticEvent) => {
              event.stopPropagation();
              event.preventDefault();
              this.onSave();
            }}
          >
            {' '}
            {translate('button.ok')}
          </ButtonOk>
        </StyledViewButtons>
        {this.containsUnverifiedMail === true ? (
          <StyledBottomText>* unverified</StyledBottomText>
        ) : null}
      </StyledOverlay>
    );
  }
}
const StyledBottomText = styled.div`
  font-size: 14px;
  width: 90%;
`;
const SubHeader = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: ${props => props.theme.Global.keyFontColor};
`;
const AddressBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${props => props.theme.Global.lightFontColor};
  width: 80%;
`;
const HeaderBlock = styled.div`
  display: flex;
  flex-direction: row;
`;
