import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';
import { Config } from '../../../config';

class ILogoutTimerStore extends EventEmitter {
  // Idle time until logout in seconds
  private logoutTime: number | undefined = undefined;
  private lastActivity: Date = new Date();

  private static instance: ILogoutTimerStore | null;

  constructor() {
    super();
    IDispatcher.register(this.registerActions.bind(this));
  }
  static getInstance(): ILogoutTimerStore {
    if (ILogoutTimerStore.instance == null) {
      ILogoutTimerStore.instance = new ILogoutTimerStore();
    }

    return ILogoutTimerStore.instance;
  }

  registerActions(action: any) {
    switch (action.actionType) {
      default:
        break;
      case Constants.CLEAR_ALL:
        this.logoutTime = Config.logOutTime;
        this.emit(CHANGE_EVENT);
        break;
      case Constants.UPDATE_LAST_ACTIVITY:
        this.updateLastActivity();
        this.emit(CHANGE_EVENT);
        break;
      case Constants.LOGOUT_TIME:
        this.setLogoutTime(action.logoutTime);
        this.emit(CHANGE_EVENT);
        break;
      case Constants.ABS_LOGOUT_TIME:
        this.setAbsLogoutTime(action.absLogoutTime);
        this.emit(CHANGE_EVENT);
        break;
    }
    return true;
  }

  updateLastActivity(): void {
    this.lastActivity = new Date();
  }

  getSecondsUntilLogout(): number | undefined {
    if (this.logoutTime == null) {
      return undefined;
    }

    const logoutTime =
      Math.floor((this.lastActivity.getTime() - new Date().getTime()) / 1000) +
      this.logoutTime;
    return logoutTime;
  }

  addChangeListener(callback: any) {
    this.on(CHANGE_EVENT, callback);
  }

  setLogoutTime(logoutTime?: number) {
    if (logoutTime == null) {
      return;
    }

    this.logoutTime = logoutTime;
  }

  setAbsLogoutTime(absLogoutTime?: number) {
    if (absLogoutTime == null) {
      return;
    }

    this.logoutTime = absLogoutTime - new Date().getTime() / 1000;
  }

  removeChangeListener(callback: any) {
    this.removeListener(CHANGE_EVENT, callback);
  }
}

export const LogoutTimerStore = ILogoutTimerStore.getInstance();
