import React, { ReactElement, ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';
import {
  OverlayComponent,
  IOverlayComponentProps,
  IOverlayComponentState,
} from '../../../../components/compositcomponents/popup/overlayComponent';

const GreyBackground = styled.div<{ centered: boolean }>`
  width: 100vw;
  height: 100vh;
  background-color: rgba(50, 50, 50, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 287;

  display: ${props => (props.centered === true ? 'flex' : 'block')};
  align-items: ${props => (props.centered === true ? 'center' : 'initial')};
  justify-content: ${props => (props.centered === true ? 'center' : 'initial')};
`;

interface IProps {
  centered: boolean;
  children?:
    | Array<ReactElement>
    | ReactElement
    | OverlayComponent<IOverlayComponentProps, IOverlayComponentState>;
  callback: (event: SyntheticEvent) => void;
  onClose?: () => void;
}

interface IState {}

export class AGreyOverlay extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }
  render() {
    const children: ReactNode | undefined =
      this.props.children != null && this.props.children instanceof OverlayComponent
        ? this.props.children.render()
        : this.props.children;
    return (
      <GreyBackground onClick={this.props.callback} centered={this.props.centered}>
        {children}
      </GreyBackground>
    );
  }
}
