import React from 'react';
import {
  KeyField,
  ColumnBox,
  ValueField,
  EditableWrapper,
} from '../basicStyledComponents/customerDetails.css';
import { PermissionStore } from '../../../../../logic/flux';
import { Ident, api, apis, ApiError, VoidResponse } from '../../../../../logic/api';
import { Passport, Face } from '../../../../../logic/api/ident';
import { Log, Logs } from '../../../../../logic/log';
import { translate } from '../../../../../common/language/translate';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import styled from 'styled-components';
import {
  OverlayHandler,
  Overlays,
} from '../../../../../logic/handler/overlayhandler/overlayHandler';
import { FlexBox } from '../../../../auth/auth.css';
import TouchableOpacity from '../../../../../components/atomiccompoents/buttons/touchableOpacity';
import {
  coalesce,
  compareDatetime,
  evaluateErrorMessage,
  getCountryOptionsAlph3,
} from '../../../../../logic/helper/Common';
import EditableComponent from '../../../../../components/atomiccompoents/editableComponent/editableComponent';
import { IOption } from '../../../../../components/atomiccompoents/form';
import ButtonCancel from '../../../../../components/atomiccompoents/buttons/buttonCancel';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import moment from 'moment';
import { ClientConfig } from '../../../../../common/config';
import { OperationId } from '../../../../../logic/api/ident';
import KeyDateComponent from '../../../../../components/atomiccompoents/keyDateComponent';
import KeySelectComponent from '../../../../../components/atomiccompoents/keySelectComponent';
import ImageBox, {
  IImageBoxElement,
} from '../../../../../components/compositcomponents/imageBox';

interface IProps {
  person: Ident.Person;
  eidData?: Ident.PersonVerification;
  changeCallback: () => void;
  is18?: boolean;
}

interface IState {
  passportFront?: Blob;
  passportStored?: Blob;
  passportBack?: Blob;
  faces?: Array<Blob>;
  ids?: Array<number>;
  keyForLoadingSpinner?: number;
  selectedUser: Ident.Person;
  passportData?: Passport;
  expandPassport?: boolean;
  storedImageToUplad?: any;
  passportFrontToUpload?: any;
  passportBacktoUpload?: any;
  dataChanged?: boolean;
}

const ENTITY_ID_XK = 2;

const StyledViewMain = styled(FlexBox)`
  flex-direction: column;
  align-items: center;
`;

const StyledIconView = styled(FlexBox)`
  flex-direction: row;
  justify-content: flex-end;
`;

export default class CustomerDetailsPassport extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
      selectedUser: props.person,
      expandPassport: true,
    };
    this.changePassportData = this.changePassportData.bind(this);
    this.validatePassportNumber = this.validatePassportNumber.bind(this);
    this.setSex = this.setSex.bind(this);
    this.setBirthDate = this.setBirthDate.bind(this);
    this.setValidationDate = this.setValidationDate.bind(this);
    this.setStoredImage = this.setStoredImage.bind(this);
    this.setFrontImage = this.setFrontImage.bind(this);
    this.setBackImage = this.setBackImage.bind(this);
    this.setExpiryDate = this.setExpiryDate.bind(this);
  }

  static getDerivedStateFromProps(props: IProps, state: IState) {
    return {
      selectedUser: props.person,
    };
  }

  componentDidMount(): void {
    this.fetchIdentification();
  }

  fetchIdentification(): void {
    if (this.state.selectedUser == null) {
      return undefined;
    }

    let passportId;
    api
      .asyncRequest<Array<Ident.Passport>>(
        { person_id: this.props.person.person_id },
        apis.MaintenanceApi,
        'personPassportGet',
      )
      .then(response => {
        if (response != null && response.length > 0) {
          response = response.sort(
            (a: Ident.Passport, b: Ident.Passport) =>
              compareDatetime(a.datetime_update, b.datetime_update) * -1,
          );

          passportId = response[0].passport_id;
          const pass = response[0];
          this.setState({
            passportData: pass,
          });
          api
            .asyncRequest<Blob>(
              {
                person_id: this.props.person.person_id,
                passport_id: passportId,
              },
              apis.MaintenanceApi,
              'personPassportPassportFrontGet',
            )
            .then(response => {
              if (response != null && response.size > 0) {
                this.setState({
                  passportFront: response,
                  passportData: pass,
                });
              }
            })
            .catch((error: ApiError) => {
              Log.warn(Logs.API, error);
            });
          api
            .asyncRequest<Blob>(
              {
                person_id: this.props.person.person_id,
                passport_id: passportId,
              },
              apis.MaintenanceApi,
              'personPassportStoredImageGet',
            )
            .then(response => {
              if (response != null && response.size > 0) {
                this.setState({
                  passportStored: response,
                  passportData: pass,
                });
              }
            })
            .catch((error: ApiError) => {
              Log.warn(Logs.API, error);
            });
          api
            .asyncRequest<Blob>(
              {
                person_id: this.props.person.person_id,
                passport_id: passportId,
              },
              apis.MaintenanceApi,
              'personPassportPassportBackGet',
            )
            .then(response => {
              if (response != null && response.size > 0) {
                this.setState({
                  passportBack: response,
                  passportData: pass,
                });
              }
            })
            .catch((error: ApiError) => {
              Log.warn(Logs.API, error);
            });
        } else {
          this.setState({
            passportData: {},
          });
        }
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
        MessageHandler.onError(
          Reporter['customer.details.identification'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
      });

    api
      .asyncRequest<Array<Face>>(
        { person_id: this.props.person.person_id },
        apis.MaintenanceApi,
        'personFaceList',
      )
      .then(response => {
        if (response != null && response.length > 0) {
          response = response.sort(
            (a: Ident.Face, b: Ident.Face) =>
              compareDatetime(a.datetime_update, b.datetime_update) * -1,
          );
          const faces: Array<Blob> = [];
          const ids: Array<number> = [];
          for (let i = 0; i < response.length; i++) {
            const faceId = response[i].face_id;
            ids.push(faceId);
            if (this.state.selectedUser != null) {
              api
                .asyncRequest<Blob>(
                  {
                    person_id: this.props.person.person_id,
                    face_id: faceId,
                  },
                  apis.MaintenanceApi,
                  'personFaceGet',
                )
                .then(newResponse => {
                  faces.push(newResponse);
                  this.setState({
                    faces: faces,
                    ids: ids,
                  });
                });
            }
          }
        }
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
        if (error != null) {
          MessageHandler.onError(
            Reporter['customer.details.identification'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
        }
      });
  }

  generateImages(editable?: boolean): Array<IImageBoxElement> {
    const out: Array<IImageBoxElement> = [];
    out.push({
      image: this.state.passportFront ?? this.state.passportFrontToUpload,
      title: 'FaceID: Front',
      selectcallback: editable === true ? (file: any) => this.setFrontImage(file) : null,
    });
    out.push({
      image: this.state.passportStored ?? this.state.storedImageToUplad,
      title: 'FaceID: Stored',
      selectcallback: editable === true ? (file: any) => this.setStoredImage(file) : null,
    });
    out.push({
      image: this.state.passportBack ?? this.state.passportBacktoUpload,
      title: 'FaceID: Back',
      selectcallback: editable === true ? (file: any) => this.setBackImage(file) : null,
    });
    if (this.state.faces == null || this.state.ids == null) {
      return out;
    }
    for (let i = 0; i < this.state.faces.length; i++) {
      out.push({
        image: this.state.faces[i],
        title: this.state.ids != null ? 'FaceID: ' + this.state.ids[i] : '',
      });
    }
    return out;
  }

  savePassportData() {
    if (ClientConfig.editPassport) {
      const req = {
        birth_date:
          this.state.selectedUser.birth_date != null
            ? moment(this.state.selectedUser.birth_date).format('YYYY-MM-DD')
            : undefined,
        birth_city: this.state.selectedUser.birth_city,
      };
      api
        .asyncRequest<VoidResponse>(
          {
            person_id: this.props.person.person_id,
            PersonUpdateRequest: req,
          },
          apis.MaintenanceApi,
          'personPut',
        )
        .then(() => {
          this.fetchIdentification();
          this.props.changeCallback();
          this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            dataChanged: false,
          });

          MessageHandler.onSuccess(Reporter['customer.details.data.put.request']);
        })
        .catch((error: ApiError) => {
          MessageHandler.onError(
            Reporter['customer.details.email.post.request.error'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
        });
    } else {
      if (this.props.eidData != null || this.state.selectedUser.family_relation === 'junior') {
        const req: Ident.PersonElectronicidPassportPutRequest = {
          person_id: this.props.person.person_id,
          ApprovedPersonVerification: {
            primary_name: this.state.passportData?.given_name,
            secondary_name: this.state.passportData?.name,
            issuer: this.state.passportData?.issuer,
            nationality: this.state.passportData?.nationality,
            document_number: this.state.passportData?.passport_number,
            //@ts-ignore
            birth_date: moment(this.state.passportData.birth_date).format('YYYY-MM-DD'),
            //@ts-ignore
            expiry_date: moment(this.state.passportData.expiration_date).format('YYYY-MM-DD'),
            sex: this.state.passportData?.sex,
            birth_city: this.state.passportData.birth_city,
          },
        };
        api
          .asyncRequest<any>(req, apis.MaintenanceApi, 'personElectronicidPassportPut')
          .then(response => {
            if (response != null) {
              if (
                this.state.passportBacktoUpload != null ||
                this.state.passportFrontToUpload != null ||
                this.state.storedImageToUplad != null
              ) {
                const req = {
                  person_id: this.props.person.person_id,
                  front: this.state.passportFrontToUpload,
                  back: this.state.passportBacktoUpload,
                  stored: this.state.storedImageToUplad,
                };
                api
                  .asyncRequest<VoidResponse>(
                    req,
                    apis.MaintenanceApi,
                    'personPassportDocumentPut',
                  )
                  .then(() => {
                    this.fetchIdentification();
                    this.props.changeCallback();
                    MessageHandler.onSuccess(Reporter['person.passport.document.put']);
                  })
                  .catch((error: ApiError) => {
                    MessageHandler.onError(
                      Reporter['person.passport.document.put'],
                      evaluateErrorMessage(error, true),
                      evaluateErrorMessage(error, false),
                    );
                  });
              } else {
                this.fetchIdentification();
                this.props.changeCallback();
              }
              MessageHandler.onSuccess(Reporter['passport.post.request']);
              this.setState({
                keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
              });
            }
          })
          .catch((error: ApiError) => {
            if (error.response != null) {
              MessageHandler.onError(
                Reporter['passport.post.request'],
                error.response.response.error_code,
                error.response.response.error_text,
              );
            } else {
              MessageHandler.onError(Reporter['passport.post.request']);
            }
            this.setState({
              keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            });
          });
      }
    }
  }

  requestKyc() {
    OverlayHandler.showOverlay(Overlays.ConfirmationRequestOverlay, {
      confirm: (callback: (success: boolean, close?: boolean) => void) => {
        api
          .asyncRequest<any>(
            { person_id: this.props.person.person_id },
            apis.MaintenanceApi,
            'personRequestKycPut',
          )
          .then((response: any) => {
            this.setState({
              keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            });
            if (response.release_request_id != null) {
              MessageHandler.onSuccess(Reporter['customer.details.kyc.put.request.approval']);
            } else {
              MessageHandler.onSuccess(Reporter['customer.details.kyc.put.request']);
            }
          })
          .catch((error: ApiError) => {
            MessageHandler.onError(
              Reporter['customer.details.kyc.put.request'],
              evaluateErrorMessage(error, true),
              evaluateErrorMessage(error, false),
            );
          });
        callback(false, true);
        return;
      },
      heading: translate('customers.details.KYC.requestKYC.header'),
      message: translate('customers.details.KYC.requestKYC.message'),
    });
  }

  changePassportData(value: string, key?: string) {
    const persData = ClientConfig.editPassport
      ? this.state.selectedUser
      : this.state.passportData;
    if (persData != null) {
      //@ts-ignore
      persData[key] = value;
      this.setState({
        selectedUser: ClientConfig.editPassport
          ? (persData as Ident.Person)
          : this.state.selectedUser,
        passportData: ClientConfig.editPassport
          ? this.state.passportData
          : (persData as Ident.Passport),
        dataChanged: true,
      });
    }
  }

  validatePassportNumber(value: string, key?: string) {
    return value.length <= 16;
  }

  setBirthDate(date: Date) {
    if (ClientConfig.editPassport) {
      const persData = this.state.selectedUser;
      if (persData != null) {
        persData.birth_date = date;
        this.setState({
          selectedUser: persData,
          dataChanged: true,
        });
      }
    } else {
      const pass = this.state.passportData;
      if (pass != null) {
        pass.birth_date = date;
        this.setState({
          passportData: pass,
        });
      }
    }
  }

  setValidationDate(date: Date) {
    const passportData = this.state.passportData;
    if (passportData != null) {
      passportData.datetime_update = date;
      this.setState({
        passportData: passportData,
      });
    }
  }

  setExpiryDate(date: Date) {
    const passportData = this.state.passportData;
    if (passportData != null) {
      passportData.expiration_date = date;
      this.setState({
        passportData: passportData,
      });
    }
  }

  getSexOptions() {
    const options: Array<IOption> = [];
    if (this.state.passportData == null || this.state.passportData.sex == null) {
      options.push({
        key: 'defaultOptionKey_not_set',
        name: translate('customers.sex.notSet'),
        value: '',
      });
    }
    options.push({
      key: 'defaultOptionKey mr',
      name: translate('customers.sex.mr'),
      value: Ident.Sex.M,
    });
    options.push({
      key: 'defaultOptionKey mrs',
      name: translate('customers.sex.mrs'),
      value: Ident.Sex.F,
    });
    options.push({
      key: 'defaultOptionKey undefined',
      name: translate('customers.sex.undefined'),
      value: Ident.Sex.X,
    });
    return options;
  }

  setStoredImage(file: any) {
    this.setState({
      storedImageToUplad: file,
      dataChanged: true,
    });
  }
  setFrontImage(file: any) {
    this.setState({
      passportFrontToUpload: file,
      dataChanged: true,
    });
  }

  setBackImage(file: any) {
    this.setState({
      passportBacktoUpload: file,
      dataChanged: true,
    });
  }

  setSex(message: any) {
    const passportData = this.state.passportData;
    if (passportData != null) {
      passportData.sex = message.sex;
      this.setState({
        passportData: passportData,
      });
    }
  }
  render() {
    const disabled =
      (ClientConfig.editPassport && this.state.selectedUser.family_relation !== 'junior') ||
      !PermissionStore.hasPermission(OperationId.PersonElectronicidPassportPut);
    return (
      <FlexBox>
        <StyledIconView>
          <TouchableOpacity>
            <FlexBox style={{ height: '29px', width: '30px' }} />
          </TouchableOpacity>
        </StyledIconView>
        <StyledViewMain>
          <WrapperInner>
            <ColumnBox style={{ maxHeight: 'calc(100vh - 300px', minWidth: '950px' }}>
              {this.state.passportData == null ? (
                <KeyField style={{ margin: 'auto' }}> No Passport to edit found</KeyField>
              ) : null}
              <EditableWrapper>
                <EditableComponent
                  translationkey="customers.name"
                  id="name"
                  initText={coalesce('', this.state.passportData?.name)}
                  inputStyle={{
                    textAlign: 'left',
                    marginBottom: '0px',
                    marginTop: '2px',
                  }}
                  changeCallback={this.changePassportData}
                  enterCallback={this.changePassportData}
                  disabled={disabled}
                />
              </EditableWrapper>
              <EditableWrapper>
                <EditableComponent
                  translationkey="customers.givenName"
                  id="given_name"
                  initText={coalesce('', this.state.passportData?.given_name)}
                  inputStyle={{
                    textAlign: 'left',
                    marginBottom: '0px',
                    marginTop: '2px',
                  }}
                  changeCallback={this.changePassportData}
                  enterCallback={this.changePassportData}
                  disabled={disabled}
                />
              </EditableWrapper>
              <EditableWrapper>
                <KeySelectComponent
                  translationkey="input.nationality.label"
                  id="nationality"
                  selected={this.state.passportData?.nationality}
                  options={getCountryOptionsAlph3(true)}
                  disabled={disabled}
                  onChange={(value: string, key: string) =>
                    this.changePassportData(value, key)
                  }
                />
              </EditableWrapper>
              <EditableWrapper>
                <EditableComponent
                  translationkey="customers.cityOfBirth"
                  id="birth_city"
                  initText={coalesce(
                    '',
                    ClientConfig.editPassport
                      ? this.state.selectedUser.birth_city
                      : this.state.passportData?.birth_city,
                  )}
                  inputStyle={{
                    textAlign: 'left',
                    marginBottom: '0px',
                    marginTop: '2px',
                  }}
                  changeCallback={this.changePassportData}
                  enterCallback={this.changePassportData}
                  disabled={!ClientConfig.editPassport && disabled}
                />
              </EditableWrapper>
              <EditableWrapper>
                <KeyDateComponent
                  translationkey="customers.validationDate"
                  value={
                    this.state.passportData != null &&
                    this.state.passportData.datetime_update != null
                      ? new Date(this.state.passportData.datetime_update)
                      : undefined
                  }
                  id="datetime_update"
                  onChange={() => {}}
                  disabled={true}
                  number={0}
                />
              </EditableWrapper>
              <EditableWrapper>
                <KeyDateComponent
                  translationkey="customers.birthdate"
                  value={
                    ClientConfig.editPassport
                      ? this.state.selectedUser.birth_date != null
                        ? new Date(this.state.selectedUser.birth_date)
                        : undefined
                      : this.state.passportData?.birth_date != null
                      ? new Date(this.state.passportData.birth_date)
                      : undefined
                  }
                  id="birth_date"
                  onChange={(key: string, value: Date) => {
                    this.setBirthDate(value);
                  }}
                  disabled={!ClientConfig.editPassport && disabled}
                  number={0}
                />
              </EditableWrapper>
              <EditableWrapper>
                <KeySelectComponent
                  translationkey="customers.newCustomer.input.gender.label"
                  id="sex"
                  selected={coalesce('', this.state.passportData?.sex)}
                  options={this.getSexOptions()}
                  disabled={disabled}
                  onChange={this.changePassportData}
                />
              </EditableWrapper>
              <EditableWrapper>
                <EditableComponent
                  translationkey={
                    this.state.selectedUser.entity_id === ENTITY_ID_XK
                      ? 'customers.personalNumber'
                      : 'customers.documentNumber'
                  }
                  id="passport_number"
                  initText={coalesce('', this.state.passportData?.passport_number)}
                  inputStyle={{
                    textAlign: 'left',
                    marginBottom: '0px',
                    marginTop: '2px',
                  }}
                  changeCallback={this.changePassportData}
                  enterCallback={this.changePassportData}
                  validateCallback={
                    this.state.selectedUser.entity_id === ENTITY_ID_XK
                      ? this.validatePassportNumber
                      : undefined
                  }
                  disabled={disabled}
                />
              </EditableWrapper>
              <EditableWrapper>
                <KeyDateComponent
                  translationkey="customers.expiryDate"
                  value={
                    this.state.passportData != null &&
                    this.state.passportData.expiration_date != null
                      ? new Date(this.state.passportData.expiration_date)
                      : undefined
                  }
                  id="expiration_date"
                  onChange={(key: string, value: Date) => {
                    this.setExpiryDate(value);
                  }}
                  disabled={disabled}
                  number={2}
                />
              </EditableWrapper>
              <EditableWrapper>
                <EditableComponent
                  translationkey="customers.user_class"
                  id="client_profile"
                  initText={coalesce('', this.state.passportData?.family_relation)}
                  inputStyle={{
                    textAlign: 'left',
                    marginBottom: '0px',
                    marginTop: '2px',
                  }}
                  changeCallback={this.changePassportData}
                  enterCallback={this.changePassportData}
                  disabled={true}
                />
              </EditableWrapper>
              <EditableWrapper>
                <EditableComponent
                  translationkey="customers.issuingAuth"
                  id="issuer"
                  initText={coalesce('', this.state.passportData?.issuer)}
                  inputStyle={{
                    textAlign: 'left',
                    marginBottom: '0px',
                    marginTop: '2px',
                  }}
                  changeCallback={this.changePassportData}
                  enterCallback={this.changePassportData}
                  disabled={disabled}
                />
              </EditableWrapper>
              {this.state.passportData?.family_relation === 'junior' ? (
                <React.Fragment>
                  <EditableWrapper>
                    <EditableComponent
                      translationkey="customers.parent_person_id"
                      id="parent_person_id"
                      initText={coalesce(
                        '',
                        this.state.passportData?.parent_person_id.toString(),
                      )}
                      inputStyle={{
                        textAlign: 'left',
                        marginBottom: '0px',
                        marginTop: '2px',
                      }}
                      changeCallback={() => {}}
                      enterCallback={this.changePassportData}
                      disabled={true}
                    />
                  </EditableWrapper>
                  <EditableWrapper>
                    <EditableComponent
                      translationkey="customers.parent_name"
                      id="parent_name"
                      initText={coalesce('', this.state.passportData?.parent_name)}
                      inputStyle={{
                        textAlign: 'left',
                        marginBottom: '0px',
                        marginTop: '2px',
                      }}
                      changeCallback={() => {}}
                      enterCallback={this.changePassportData}
                      disabled={true}
                    />
                  </EditableWrapper>
                  <EditableWrapper>
                    <EditableComponent
                      translationkey="customers.parent_given_name"
                      id="parent_given_name"
                      initText={coalesce('', this.state.passportData?.parent_given_name)}
                      inputStyle={{
                        textAlign: 'left',
                        marginBottom: '0px',
                        marginTop: '2px',
                      }}
                      changeCallback={() => {}}
                      enterCallback={this.changePassportData}
                      disabled={true}
                    />
                  </EditableWrapper>
                </React.Fragment>
              ) : null}
              {!ClientConfig.editPassport ? (
                <React.Fragment>
                  <EditableWrapper>
                    <LargeKeyValueBlock>
                      <KeyField>Eid-ID</KeyField>
                      <ValueField>
                        {this.props.eidData != null
                          ? this.props.eidData.electronicid_verification_id
                          : ''}
                      </ValueField>
                    </LargeKeyValueBlock>
                  </EditableWrapper>
                  <EditableWrapper>
                    <LargeKeyValueBlock>
                      <KeyField>{translate('customers.dateOfIdentification')}</KeyField>
                      <ValueField>
                        {this.props.eidData != null
                          ? this.props.eidData.verification_date
                          : ''}
                      </ValueField>
                    </LargeKeyValueBlock>
                  </EditableWrapper>
                </React.Fragment>
              ) : null}
              <EditableWrapper style={{ height: '140px', marginLeft: '16px' }}>
                <KeyField>{translate('transfer.details.documents')}</KeyField>
                <ValueField>
                  <FlexBox
                    style={{
                      alignItems: 'center',
                      flexDirection: 'row',
                      overflowX: 'auto',
                    }}
                  >
                    <ImageBox images={this.generateImages(!disabled)} />
                  </FlexBox>
                </ValueField>
              </EditableWrapper>
              <EditableWrapper style={{ marginLeft: '16px' }}>
                <KeyField></KeyField>
                <ValueField>
                  {!ClientConfig.editPassport ? (
                    <ButtonOk
                      key={this.state.keyForLoadingSpinner}
                      disabled={
                        this.props.person.state === Ident.PersonStateEnum.C ||
                        this.props.is18 === false
                      }
                      onClick={() => this.requestKyc()}
                      id="btnRequestKyc"
                    >
                      {translate('customers.resetKyc')}
                    </ButtonOk>
                  ) : null}
                </ValueField>
              </EditableWrapper>
            </ColumnBox>
          </WrapperInner>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '40%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '65px',
                justifyContent: 'flex-start',
              }}
            >
              <ButtonCancel id="btnEditCustomerDetailsCancel" onClick={() => {}}>
                {translate('button.cancel')}
              </ButtonCancel>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '65px',
                justifyContent: 'flex-start',
              }}
            >
              <ButtonOk
                key={this.state.keyForLoadingSpinner}
                disabled={this.state.dataChanged !== true}
                onClick={() => this.savePassportData()}
                id="btnEditCustomerDetailsOk"
              >
                {translate('button.save')}
              </ButtonOk>
            </div>
          </div>
        </StyledViewMain>
      </FlexBox>
    );
  }
}

const WrapperInner = styled(FlexBox)`
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const LargeKeyValueBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 450px;
  height: 62px;
  border-bottom: 1px solid #dedede;
  padding: ${props => props.theme.Table.TBody.Td.padding};
  margin: 8px;
  padding: 8px;
`;
