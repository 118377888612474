import React, { ReactNode } from 'react';

interface IProps {
  timeout?: number;
  delay?: number;
  children?: ReactNode;
  trigger?: boolean;
  onHide?: () => void;
}

interface IState {
  show?: boolean;
}

export class HideComponent extends React.Component<IProps, IState> {
  private _timer: number | undefined = undefined;
  private trigger: boolean | undefined = undefined;

  constructor(props: IProps) {
    super(props);

    this.trigger = props.trigger;

    this.state = {
      show:
        (props.timeout == null || props.timeout === 0) &&
        (props.trigger == null || props.trigger === true),
    };

    this.timerFn = this.timerFn.bind(this);
  }

  componentDidMount() {
    this.setTimer();
  }

  componentWillReceiveProps(props: IProps) {
    if (props.trigger !== this.trigger && props.trigger === true) {
      this.setState(
        {
          show: props.timeout == null || props.timeout === 0,
        },
        () => this.setTimer(),
      );
    }
  }

  setTimer() {
    if (this._timer != null) {
      return;
    }

    if (this.props.timeout != null && this.state.show !== true) {
      this._timer = window.setTimeout(
        () => this.timerFn(true, true),
        this.props.timeout * 1000,
      );
    } else {
      this._timer = window.setTimeout(
        () => this.timerFn(false),
        this.props.delay == null ? 500 : this.props.delay * 1000,
      );
    }
  }

  private timerFn(show: boolean, setTimerAgain: boolean = false) {
    this.setState({ show: show });
    this._timer = undefined;

    if (show !== true && this.props.onHide != null) {
      this.props.onHide();
    }

    if (setTimerAgain) {
      this.setTimer();
    }
  }

  render() {
    return null;
    /*if (this.state.show !== true) {
           return null;
         }
        return <StyledDiv>{this.props.children}</StyledDiv>;
    }*/
  }
}
