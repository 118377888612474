import { IGenericElement, GenericElementType, RestMethods } from './IGenericElement';
import ObjectParser from './subParser/objectParser';
import RequestBodyParser from './subParser/requestBodyParser';
import JsonFilter from './subParser/jsonFilter';
import { Account, Ident } from '../../logic/api';
import { Log, Logs } from '../../logic/log';

export interface IParsedContent {
  uri?: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  contentType?: string;
  elements: Array<IGenericElement>;
}

const AnyMap = ['Sortcode', 'Account', 'Iban', 'Email', 'Account Number', 'Phone'];

export const AnyMapPar = ['Sortcode', 'Iban', 'Email', 'Account Number', 'Phone'];

export default class Parser {
  // responsePath: string
  // constructor(responsePath: string) {
  //     this.responsePath = responsePath;
  // }

  jsonFilter: JsonFilter;
  objectParser: ObjectParser;
  requestBodyParser: RequestBodyParser;

  errors: Array<string>;
  warnings: Array<string>;

  constructor() {
    this.jsonFilter = new JsonFilter();
    this.objectParser = new ObjectParser(this.addError, this.addWarning);
    this.requestBodyParser = new RequestBodyParser();
    this.errors = [];
    this.warnings = [];
  }

  getRequestBodyObject(path: string, method: string): object {
    return this.jsonFilter.getRequestBody(path, method);
  }

  getParsedContent(
    postingTemplatePath: Account.TransactionTemplate | Ident.TransactionTemplate,
    ident?: boolean,
  ): IParsedContent {
    const filledTemplate = this.jsonFilter.replaceRefs(postingTemplatePath, ident);
    const parsedContent: IParsedContent = {
      elements: [],
    };
    let path = this.getPath(filledTemplate);

    if (path == null) {
      const error = 'no path found';
      this.addError(error);
      return parsedContent;
    }
    let pathKeys = Object.keys(path);

    if (pathKeys.length > 1) {
      const warning = 'more than one children of path, using first one';
      this.addWarning(warning);
    }
    parsedContent.uri = pathKeys[0];
    path = path[pathKeys[0]];
    pathKeys = Object.keys(path);
    const method = this.findMethod(pathKeys);
    //@ts-ignore
    if (method == null) {
      const error = 'no requestmethod found';
      this.addError(error);
      return parsedContent;
    }
    parsedContent.method =
      method !== 'GET' && method !== 'PUT' && method !== 'POST' && method !== 'DELETE'
        ? 'POST'
        : method;
    path = path[method.toLowerCase()];

    switch (parsedContent.method) {
      case 'POST':
        return this.parseForPostPath(path, parsedContent);
        break;

      case 'GET':
        return this.parseForGetPath(path, parsedContent);
        break;
      case 'PUT':
        return this.parseForPutPath(path, parsedContent);
        break;

      default:
        return parsedContent;
    }
  }

  getPath(content: {}): any | null {
    const keys = Object.keys(content);
    if (keys.length > 0 && keys.includes('path')) {
      //@ts-ignore
      return content['path'];
    } else {
      return null;
    }
  }

  findMethod(keys: any[]): string | null {
    for (const i in keys) {
      if (Object.values(RestMethods).includes(keys[i].toUpperCase())) {
        return keys[i].toUpperCase();
      }
    }
    return null;
  }

  parseForGetPath(content: {}, parsedContent: IParsedContent) {
    let keys = Object.keys(content);
    if (!(keys.indexOf('parameters') >= 0)) {
      const error = 'no parameters found';
      this.addError(error);
      return parsedContent;
    }
    //@ts-ignore
    const path = content.parameters;
    keys = Object.keys(path);

    Object.entries(path).forEach(([key]) => {
      const content = path[key];
      const field: IGenericElement = {
        type: GenericElementType.text,
        title: '',
        uuid: this.objectParser.uuidv4(),
        validator: [],
        regexMap: [],
        parentKeys: [],
        required: content.required != null ? content.required : false,
      };
      if (content.schema != null) {
        if (content.schema.type === 'string') {
          parsedContent.elements.push(
            this.objectParser.parseStringObject(
              content.schema,
              content.description,
              content.name,
              field,
            ),
          );
        } else if (content.schema.type === 'number' || content.schema.type === 'integer') {
          parsedContent.elements.push(
            this.objectParser.parseNumberObject(
              content.schema,
              content.description,
              content.name,
              field,
            ),
          );
        } else if (content.schema.type === 'boolean') {
          parsedContent.elements.push(
            this.objectParser.parseBooleanObject(content.name, content.description, field),
          );
        } else if (content.schema.type === 'object') {
          parsedContent.elements = parsedContent.elements.concat(
            this.parseObject(content.schema),
          );
        }
      }
    });
    return parsedContent;
  }

  parseForPostPath(content: {}, parsedContent: IParsedContent) {
    this.requestBodyParser.addJsonBegin();
    let keys = Object.keys(content);
    if (!(keys.indexOf('requestBody') >= 0)) {
      const error = 'no requestbody found';
      this.addError(error);
      return parsedContent;
    }
    //@ts-ignore
    let path = content.requestBody;

    keys = Object.keys(path);
    if (!(keys.indexOf('content') >= 0)) {
      const error = 'No Content found';
      this.addError(error);
      return parsedContent;
    }
    path = path.content;
    keys = Object.keys(path);
    if (keys.length > 1) {
      const warning = 'More than one content key found, using first one';
      this.addWarning(warning);
    }
    parsedContent.contentType = keys[0];
    if (parsedContent.contentType !== 'application/json') {
      const error = 'contentType not application/json';
      this.addError(error);
    }
    path = path[keys[0]];
    keys = Object.keys(path);
    path = path[keys[0]];
    keys = Object.keys(path);
    if (keys.indexOf('type') >= 0) {
      if (path.type === 'object') {
        parsedContent.elements = this.parseObject(path);
      } else if (path.type === 'array') {
        // ToDo
      }
    }
    this.requestBodyParser.finish();
    return parsedContent;
  }

  parseForPutPath(content: {}, parsedContent: IParsedContent) {
    return this.parseForPostPath(content, parsedContent);
  }

  getErrors(): Array<string> {
    return this.errors;
  }

  getWarnings(): Array<string> {
    return this.warnings;
  }

  /**
   * This method takes an object in form of:
   * {
   *      "type": "object",
   *      "required"?: [],
   *      "properties": {
   *          ...
   *      }
   * }
   * @param content objects content json
   * @param min defines minimum entrys beeing used in this object
   * @param max defines maximum entrys beeing used in this object
   * @param requiredChild defines whether this object is a requiered childObject of some parent
   * @returns A valid array of IGenericElement 's -> in _every_ case
   */
  parseObject(
    content: any,
    min: number = -1,
    max: number = -1,
    requiredChild: boolean = false,
    parentKeys: Array<string> = [],
    belongsTo?: number,
  ): Array<IGenericElement> {
    let fields: Array<IGenericElement> = [];

    if (content.type !== 'object') {
      return fields;
    }
    const keys: Array<string> = Object.keys(content);
    let required: Array<string> = [];
    if (keys.indexOf('required') >= 0) {
      // Keep required keys
      required = content.required;
    }
    if (content.properties != null) {
      // Object has properties, parse them
      let alternateField: IGenericElement = {
        type: GenericElementType.combined,
        title: '',
        uuid: '',
        validator: [],
        regexMap: [],
        parentKeys: [],
      };
      Object.entries(content.properties).forEach(([key]) => {
        let currentField: IGenericElement = {
          type: GenericElementType.text,
          key: key,
          title: '',
          uuid: '',
          validator: [],
          regexMap: [],
          parentKeys: [],
          belongsTo: belongsTo,
        };
        if (parentKeys != null && parentKeys.length !== 0 && currentField.parentKeys != null) {
          currentField.parentKeys = [...parentKeys];
        }
        if (
          content.properties[key].type != null &&
          content.properties[key].type === 'object'
        ) {
          this.requestBodyParser.openNewObject(key);

          const min =
            content.properties[key].minProperties != null
              ? content.properties[key].minProperties
              : -1;
          const max =
            content.properties[key].maxProperties != null
              ? content.properties[key].maxProperties
              : -1;
          const newKeys = parentKeys.concat([key]);
          fields = fields.concat(
            this.parseObject(
              content.properties[key],
              min,
              max,
              required.indexOf(key) >= 0,
              newKeys,
            ),
          );
          this.requestBodyParser.closeObject();
        } else if (content.properties[key].anyOf != null) {
          // for the moment we only use the first field of anyOf, since the adjustment will take some time. This will be adjusted as soon as more important tasks are done
          if (content.properties[key].anyOf[1].anyOf != null) {
            this.requestBodyParser.openNewObject(key);
            currentField.type = GenericElementType.anyOf;
            currentField.title = key;
            currentField.anyOf = [];
            currentField.required = required.indexOf(key) >= 0;
            currentField.data = [];
            const min =
              content.properties[key].anyOf[1].minProperties != null
                ? content.properties[key].anyOf[1].minProperties
                : -1;
            const max =
              content.properties[key].anyOf[1].maxProperties != null
                ? content.properties[key].anyOf[1].maxProperties
                : -1;
            const newKeys = parentKeys.concat([key]);
            let idx: number = 0;
            for (const o of content.properties[key].anyOf[1].anyOf) {
              currentField.data.push(AnyMapPar[idx]);
              currentField.anyOf = currentField.anyOf?.concat(
                this.parseObject(o, min, max, true, [AnyMap[idx]], idx),
              );
              ++idx;
            }

            fields = fields.concat(currentField);
            this.requestBodyParser.closeObject();
          }
        } else if (content.properties[key].type === 'array') {
          fields = fields.concat(this.parseObject(content.properties[key].items[0]));
          // TODO:
        } else {
          const isRequired: boolean = required.indexOf(key) >= 0 || requiredChild;
          const uuid = this.objectParser.uuidv4();

          if (content.properties[key].type === 'string') {
            currentField = this.objectParser.parseStringObject(
              content.properties[key],
              content.properties[key].description,
              key,
              currentField,
            );
          } else if (
            content.properties[key].type === 'number' ||
            content.properties[key].type === 'integer'
          ) {
            currentField = this.objectParser.parseNumberObject(
              content.properties[key],
              content.properties[key].description,
              key,
              currentField,
            );
          } else if (content.properties[key].type === 'boolean') {
            currentField = this.objectParser.parseBooleanObject(
              key,
              content.properties[key].description,
              currentField,
            );
          } else {
            const warning = 'No known type found';
            this.addWarning(warning);
          }

          this.requestBodyParser.addElement(key, uuid);

          currentField.uuid = uuid;
          currentField.required = isRequired;
          currentField.parentRequired = requiredChild;
          if (min === max && min === 1) {
            alternateField = this.objectParser.concatFields(
              alternateField,
              isRequired,
              currentField,
              uuid,
              requiredChild,
            );
          } else {
            fields.push(currentField);
          }
        }
      });

      if (min === max && min === 1) {
        fields.push(alternateField);
      }
    }
    return fields;
  }

  /**
   * @returns A valid json tree!
   */
  getRequestBody(): string {
    return this.requestBodyParser.getRequestBody();
  }

  addError(error: string) {
    Log.error(Logs.PARSER, error);
    this.errors.push(error);
  }

  addWarning(warning: string) {
    Log.warn(Logs.PARSER, warning);
    this.warnings.push(warning);
  }
}
