import { IGenericElement, GenericElementType } from '../IGenericElement';
import i18next from 'i18next';

export default class ObjectParser {
  errorCallback: (error: string) => void;
  warningCallback: (warning: string) => void;

  constructor(
    errorCallback: (error: string) => void,
    warningCallback: (warning: string) => void,
  ) {
    this.errorCallback = errorCallback;
    this.warningCallback = warningCallback;
    this.validateNumber = this.validateNumber.bind(this);
  }

  parseNumberObject(
    content: any,
    description: string,
    key: string,
    currentField: IGenericElement,
  ): IGenericElement {
    currentField.type = GenericElementType.number;
    currentField.title = key;
    currentField.displayTitle = description;
    if (content['x-client-context'] != null) {
      currentField.autofill = content['x-client-context'];
    }
    currentField.numberValidatorCallback = (value: number | string | undefined) => {
      return this.validateNumber(content, value);
    };
    return currentField;
  }

  parseBooleanObject(
    key: string,
    description: string,
    currentField: IGenericElement,
  ): IGenericElement {
    currentField.type = GenericElementType.boolean;
    currentField.title = key;
    currentField.displayTitle = description;
    return currentField;
  }

  validateNumber(content: any, value: number | string | undefined) {
    const constraints = content;
    if (value == null) {
      return false;
    }
    if (typeof value === 'string') {
      value = parseInt(value);
      if (isNaN(value)) {
        return false;
      }
    }
    if (constraints.type === 'integer') {
      if (!Number.isInteger(value)) {
        return false;
      }
      if (constraints.format != null) {
        const format: string = constraints.format;
        const bits = parseInt(format.substring(3, format.length));
        if (value > Math.pow(2, bits) - 1) {
          return false;
        }
      }
    }
    if (typeof value === 'number') {
      if (constraints.multipleOf != null) {
        if (value / constraints.multipleOf !== Math.round(value / constraints.multipleOf)) {
          return false;
        }
      }
      if (constraints.minimum != null) {
        if (constraints.minimum > value) {
          return false;
        }
      }
      if (constraints.maximum != null) {
        if (constraints.maximum < value) {
          return false;
        }
      }
    } else {
      return false;
    }
    return true;
  }

  parseStringObject(
    content: any,
    description: string,
    key: string,
    currentField: IGenericElement,
  ): IGenericElement {
    currentField.title = key;
    currentField.type = GenericElementType.text;
    currentField.displayTitle = description;
    if (content.enum != null) {
      currentField.data = content.enum;
      currentField.type = GenericElementType.enum;
    }
    let validator: RegExp | undefined;

    if (content['x-client-context'] != null) {
      currentField.autofill = content['x-client-context'];
    }
    if (content.format != null) {
      if (content.format === 'date') {
        currentField.type = GenericElementType.date;
        if (currentField.validator != null && content.pattern == null) {
          currentField.validator.push(this.buildDateRegExp());
        }
      } else if (content.format === 'date-time') {
        currentField.type = GenericElementType.datetime;
        if (currentField.validator != null && content.pattern == null) {
          currentField.validator.push(this.buildDateTimeRegExp());
        }
      }
    }

    if (content.pattern != null && content['x-format'] == null) {
      let regexString = content.pattern;
      const nameCaptureGroup = new RegExp('\\?<[^>]*>');
      while (regexString.search(nameCaptureGroup) >= 0) {
        regexString = regexString.replace(nameCaptureGroup, '');
      }
      const beginEndMarkers = new RegExp('^\\^.*\\$$');
      if (regexString.search(beginEndMarkers) >= 0) {
        validator = new RegExp(regexString);
      } else {
        validator = new RegExp('^' + regexString + '$');
      }
    } else {
      validator = this.buildRegexFromProperties(content);
    }
    if (validator != null && currentField.validator != null) {
      currentField.validator.push(validator);
    }
    return currentField;
  }

  buildRegexFromProperties(content: {}): RegExp | undefined {
    //@ts-ignore
    if (content['x-format'] != null) {
      const lang = i18next.language;
      const separator = lang === 'en' ? '.' : ',';
      //@ts-ignore
      const format: string = content['x-format'];
      if (format.includes('numeric')) {
        const values = format.substring(8, format.length - 1);
        const p = parseInt(values.substring(0, values.indexOf(',')));
        const s = parseInt(values.substring(values.indexOf(',') + 1, values.length));
        const beforeSeparator = p - s;
        const min =
          //@ts-ignore
          content['x-minimum'] != null ? parseInt(content['x-minimum']) : null;
        // TODO: @Robert: Why is this variable unused??
        //const max =
        //	content['x-maximum'] != null ? parseInt(content['x-maximum']) : null;
        let regexString = '^';
        regexString +=
          (min != null && min < 0 ? '-?' : '') +
          '[0-9]{1,' +
          beforeSeparator +
          '}(\\' +
          separator +
          '[0-9]{0,' +
          s +
          '})?$';
        return new RegExp(regexString);
      }
    }
    //@ts-ignore
    if (content.minLength == null && content.maxLength == null) {
      return undefined;
    }
    let regexString = '';
    regexString =
      '^.{' +
      //@ts-ignore
      (content.minLength == null ? 0 : content.minLength) +
      ',' +
      //@ts-ignore
      (content.maxLength == null ? Number.MAX_SAFE_INTEGER : content.maxLength) +
      '}$';
    return regexString === '' ? undefined : new RegExp(regexString);
  }

  uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  concatFields(
    alternateField: IGenericElement,
    isRequired: boolean,
    currentField: IGenericElement,
    uuid: string,
    parentRequired: boolean = false,
  ): IGenericElement {
    alternateField.title =
      alternateField.title === ''
        ? currentField.title
        : alternateField.title + ',' + currentField.title;
    alternateField.required = isRequired;
    alternateField.parentKeys = currentField.parentKeys;
    alternateField.parentRequired = parentRequired;
    if (
      currentField.validator != null &&
      alternateField.validator != null &&
      alternateField.regexMap != null
    ) {
      for (const i in currentField.validator) {
        alternateField.validator.push(currentField.validator[i]);
        alternateField.regexMap.push({ regex: currentField.validator[i], uuid: uuid });
      }
    } else {
      const error = 'no Regex given for combined field';
      this.errorCallback(error);
    }
    alternateField.uuid = alternateField.uuid === '' ? this.uuidv4() : alternateField.uuid;
    alternateField.autofill =
      alternateField.autofill === null ? currentField.autofill : undefined;
    return alternateField;
  }

  buildDateRegExp() {
    return new RegExp('^s*((?:19|20)d{2})-(1[012]|0?[1-9])-(3[01]|[12][0-9]|0?[1-9])s*$');
  }
  buildDateTimeRegExp() {
    return new RegExp(
      '^s*((?:19|20)d{2})-(1[012]|0?[1-9])-(3[01]|[12][0-9]|0?[1-9])s(2[0-4]|1[1-9]|0?[1-9]):([12345][0-9]):([12345][0-9])s*$',
    );
  }
}
