//@ts-nocheck
import React, { SyntheticEvent, ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  IStatusState,
  IMessageConfig,
  Reporter,
  getFormComponents,
} from '../../../logic/handler/messagehandler/messageHandlerConfig';
import { MessageHandler } from '../../../logic/handler/messagehandler/messageHandler';
import { translate } from '../../../common/language/translate';
import { Ident, api, apis, ApiError, VoidResponse } from '../../../logic/api';
import { Log, Logs } from '../../../logic/log';
import {
  IPropsCenter,
  IStateCenter,
  ACenteredOverlay,
} from '../../../logic/handler/overlayhandler/globaloverlays/aCenteredOverlay';
import { IOverlayMessage } from '../../../components/compositcomponents/popup/overlay';
import { BigInput } from '../../../components/atomiccompoents/form/inputs.css';
import ButtonOk from '../../../components/atomiccompoents/buttons/buttonOk';
import { Box } from '../../../components/atomiccompoents/boxes/box';
import { evaluateErrorMessage } from '../../../logic/helper/Common';

const StyledTouchable = styled.div`
  flex-grow: 1;
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  a {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;

    color: ${props => props.theme.Button.backgroundColor};
    border: none;

    :hover {
      text-decoration: underline;
    }
  }
`;
const StyledWrapperView = styled(Box)`
  width: 555px;
  height: 655px;
  flex-direction: column;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: fixed;
`;

const StyledTextHeading = styled.p`
  margin-top: 46px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.2px;
  text-align: center;

  margin-bottom: 9px;
`;

const StyledTextSubHeading = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  text-align: center;

  margin-bottom: 61px;
`;

const StyledInputView = styled.div`
  margin-top: 26px;
  margin-bottom: 26px;
`;

const ButtonBox = styled.div`
  width: 100%;
  text-align: center;
`;

interface IProps extends IPropsCenter {
  currentPassword?: string;
  notifyParentObject?: (message: IOverlayMessage) => void;
}

interface IState extends IStateCenter, IStatusState {
  currentPassword?: string;
  password?: string;
  password2?: string;
  showComponent?: ReactElement;
  keyForLoadingSpinner: number;
}

export default class NewPasswordComponent extends ACenteredOverlay<IProps, IState> {
  password: string = '';

  constructor(props: IProps) {
    super(props);

    this.state = {
      ...props,
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    };

    this.cancel = this.cancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.close = this.close.bind(this);
  }

  onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.password == null && this.state.password2 == null) {
      const conf: IMessageConfig = MessageHandler.onError(
        Reporter['web.auth.login.error.nopassword'],
      );
      this.setState({
        errorMessage:
          conf.errorMessage != null
            ? conf.errorMessage
            : translate('auth.password.inValidPasswordError'),
        showInlineError: conf.errorMethods.inline,
        key: conf.translationKey != null ? conf.translationKey + '.error' : '',
        keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
      });
    } else if (
      this.state.password == null ||
      this.state.password2 == null ||
      this.state.password !== this.state.password2
    ) {
      const conf: IMessageConfig = MessageHandler.onError(
        Reporter['web.auth.resetpassword.error.nomatch'],
      );
      this.setState({
        errorMessage:
          conf.errorMessage != null
            ? conf.errorMessage
            : translate('auth.password.notMatchingError'),
        showInlineError: conf.errorMethods.inline,
        key: conf.translationKey != null ? conf.translationKey + '.error' : '',
        keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
      });
    } else {
      if (this.state.password == null || this.state.password.trim() === '') {
        const errorconf: IMessageConfig = MessageHandler.onError(
          Reporter['web.auth.password.invalid'],
        );
        this.setState({
          errorMessage: errorconf.errorMessage,
          showInlineError: errorconf.errorMethods.inline === true,
          keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          key: errorconf.translationKey != null ? errorconf.translationKey + '.error' : '',
        });
        return;
      }
      const request: Ident.SelfPasswordPutRequest = {
        Password: {
          current_password:
            this.state.currentPassword != null ? this.state.currentPassword : '',
          password: this.state.password,
        },
      };

      api
        .asyncRequest<VoidResponse>(request, apis.SelfServiceApi, 'selfPasswordPut')
        .then(response => {
          if (response != null) {
            MessageHandler.onSuccess(Reporter['web.auth.password.put']);
            this.props.selfClose();
            Log.info(Logs.API, 'password changed');
            this.cancel();
          }
        })
        .catch((error: ApiError) => {
          Log.error(Logs.API, error.statusText);
          const errorconf: IMessageConfig = MessageHandler.onError(
            Reporter['web.auth.password.put'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
          this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            errorMessage:
              errorconf.errorMessage != null ? errorconf.errorMessage : error.statusText,
            showInlineError: errorconf.errorMethods.inline === true,
            key:
              error.response.response.error_code != null
                ? 'backend.' + error.response.response.error_code
                : errorconf.translationKey != null
                ? errorconf.translationKey + '.error'
                : '',
          });
        });
    }
  }

  private handleInputChange(notification: any): void {
    if (notification != null) {
      if (notification.password != null) {
        this.setState({ password: notification.password.value });
      }
      if (notification.passwordRepeat != null) {
        this.setState({ password2: notification.passwordRepeat.value });
      }
      if (notification.passwordOld != null) {
        this.setState({ currentPassword: notification.passwordOld.value });
      }
    }
  }

  cancel(event?: SyntheticEvent) {
    if (event != null) {
      event.preventDefault();
    }

    if (this.props.notifyParentObject != null) {
      this.props.notifyParentObject({
        changePopUpComponent: null,
        newPopUpComponent: null,
        isShowing: false,
        messageForParentObject: false,
      });
    }
  }

  consume(event: SyntheticEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  close(event: SyntheticEvent) {
    event.stopPropagation();
    this.props.selfClose();
  }

  stopPropagation(event: SyntheticEvent) {
    event.stopPropagation();
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.onSubmit(event);
    }
  };

  reviveState(): void {}

  defineContent(): ReactNode {
    if (this.state.showComponent != null) {
      return this.state.showComponent;
    }

    return (
      <StyledWrapperView onClick={this.stopPropagation}>
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <StyledTextHeading>{translate('auth.password.title')}</StyledTextHeading>
          <StyledTextSubHeading>{translate('auth.password.subtitle')}</StyledTextSubHeading>
          <form onKeyDown={this.onKeyDown} onClick={this.consume}>
            <BigInput
              label={translate('auth.password.input.current.label')}
              placeHolder={translate('auth.password.input.current.placeholder')}
              type="password"
              id="passwordOld"
              value={this.state.currentPassword}
              showError={true}
              showStrength={false}
              notification={(notification: any) => this.handleInputChange(notification)}
              onSubmit={this.onSubmit}
            />
            <BigInput
              label={translate('auth.password.input.password.label')}
              placeHolder={translate('auth.password.input.password.placeholder')}
              type="password"
              id="password"
              showError={true}
              showStrength={true}
              notification={(notification: any) => this.handleInputChange(notification)}
              onSubmit={this.onSubmit}
            />
            <StyledInputView>
              <BigInput
                label={translate('auth.password.input.password2.label')}
                placeHolder={translate('auth.password.input.password2.placeholder')}
                type="password"
                id="passwordRepeat"
                showError={true}
                notification={(notification: any) => this.handleInputChange(notification)}
                onSubmit={this.onSubmit}
              />
            </StyledInputView>

            {getFormComponents(
              this.state.showInlineError,
              this.state.showInlineSuccess,
              this.state.errorMessage,
              this.state.successMessage,
              this.state.key,
            )}
            <ButtonBox>
              <ButtonOk
                onClick={event => this.onSubmit(event)}
                key={this.state.keyForLoadingSpinner}
                id="complete"
                style={{ width: '80%', margin: 'auto' }}
              >
                {translate('button.changePassword')}
              </ButtonOk>
            </ButtonBox>
            <StyledTouchable onClick={event => this.close(event)}>
              {
                <Link to="#" onClick={this.cancel} id="cancel">
                  {translate('button.cancel')}
                </Link>
              }
            </StyledTouchable>
          </form>
        </div>
      </StyledWrapperView>
    );
  }
}
