export enum GenericElementType {
  text = 'text',
  password = 'password',
  number = 'number',
  date = 'date',
  datetime = 'datetime',
  object = 'object',
  enum = 'enum',
  combined = 'combined',
  boolean = 'boolean',
  oneOf = 'oneOf',
  anyOf = 'anyOf',
}

export enum RestMethods {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
}

export interface IGenericElement {
  displayTitle?: string;
  key?: string;
  title: string;
  type: GenericElementType;
  validator?: Array<RegExp>;
  required?: boolean;
  uuid: string;
  parentKeys: Array<string>;
  parentRequired?: boolean;
  autofill?: string;
  data?: Array<any>;
  belongsTo?: number | null;
  oneOf?: Array<IGenericElement>;
  anyOf?: Array<IGenericElement>;
  numberValidatorCallback?: (val: string | number | undefined) => boolean;
  regexMap?: Array<{
    regex: RegExp;
    uuid: string;
  }>;
}
