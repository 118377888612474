import React from 'react';
import styled from 'styled-components';

import { IStatusState } from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { translate } from '../../../../common/language/translate';
import { FlexBox } from '../../../auth/auth.css';
import { Box } from '../../../../components/atomiccompoents/boxes/box';

import { format } from '../../../../logic/helper/format';
import Title from '../../../../components/compositcomponents/title';

import { api, apis, ApiError, Account } from '../../../../logic/api/index';
import { Log, Logs } from '../../../../logic/log';
import { Table } from '../../../../components/compositcomponents/table/table';

interface IProps {}

interface IState extends IStatusState {
  clientStats: ITotalStats;
}

interface ITotalStats extends Account.ClientAmounts {
  clients_total?: number;
}

export default class UserStatsComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      clientStats: {},
    };
    this.getClientStats = this.getClientStats.bind(this);
    this.getClientStats();
  }

  private getClientStats() {
    api
      .asyncRequest<Account.ClientAmounts>(
        {},
        apis.ClientstatsApi,
        'clientstatsRegistrationsGet',
      )
      .then((response: Account.ClientAmounts) => {
        const total =
          (response.clients_identified != null ? response.clients_identified : 0) +
          (response.clients_registered != null ? response.clients_registered : 0) +
          (response.clients_transact != null ? response.clients_transact : 0);
        const stats = response as ITotalStats;
        stats.clients_total = total;
        this.setState({
          clientStats: stats,
        });
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
      });
  }

  render() {
    return (
      <FlexBox>
        <div>
          <Title title={translate('administration.systemstate.userstats')} />
        </div>
        <StatsBox>
          <Table
            sourceData={[this.state.clientStats]}
            fields={[
              'clients_registered',
              'clients_identified',
              'clients_transact',
              'clients_total',
            ]}
            header={[
              translate('clientStats.registered'),
              translate('clientStats.identified'),
              translate('clientStats.transacting'),
              translate('clientStats.total', 'Total'),
            ]}
            hidePaging={true}
          />
        </StatsBox>
      </FlexBox>
    );
  }
}

const StatsBox = styled.div`
	display: flex;
	flex-direction: column;
	margin: 16px;
	height: 100%;
	justify-content: center;
    align-items: center
	margin-bottom: 32px;
    width: 100%;
  
`;
