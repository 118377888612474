// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders } from '../runtime';
import { SepaCreditTransfer, TransactionIdentifier } from '../models/index';

export interface PaymentsSepaCreditTransferPostRequest {
  SepaCreditTransfer?: SepaCreditTransfer;
}

/**
 * no description
 */
export class TransactionCustomerApi extends BaseAPI implements IOperationIdMap {
  /**
   * create a sepa payment
   */
  paymentsSepaCreditTransferPost = (
    requestParameters: PaymentsSepaCreditTransferPostRequest,
  ): Observable<TransactionIdentifier> => {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<TransactionIdentifier>({
      path: '/payments/sepa-credit-transfers',
      method: 'POST',
      headers,
      body: requestParameters.SepaCreditTransfer,
    });
  };

  operationToOperationId = {
    paymentsSepaCreditTransferPost: 'payments_sepa_credit_transfer_post',
  };
}
