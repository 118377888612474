import React, { ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { IStatusState } from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import { translate } from '../../../../../common/language/translate';
import {
  IStateOver,
  IPropsOver,
  AOverlay,
} from '../../../../../logic/handler/overlayhandler/globaloverlays/aOverlay';
import {
  IInitProps,
  IInitState,
} from '../../../../../logic/handler/initialdatahandler/initialDataComponent';
import Title from '../../../../../components/compositcomponents/title';
import {
  OverlayHandler,
  Overlays,
} from '../../../../../logic/handler/overlayhandler/overlayHandler';
import {
  KeyField,
  KeyValueBlock as KeyValueBlockBig,
  ValueField,
} from '../basicStyledComponents/customerDetails.css';
import { Table } from '../../../../../components/compositcomponents/table/table';
import { format } from '../../../../../logic/helper/format';
import { RowType } from '../../../../../components/compositcomponents/table/tableTypes';

const StyledOverlay = styled.div`
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 50px;
  padding-top: 20;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 70%;
  align-items: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  overflow-y: auto;
`;

interface IProps extends IInitProps, IPropsOver {
  data: any;
}
interface IState extends IStatusState, IInitState, IStateOver {
  hasMultipleReports?: boolean;
  selectedReport?: string;
}

export default class ReportsOverlay extends AOverlay<IProps, IState> {
  private converter = (line: any, index: number, fields: Array<string>): RowType => {
    const row: RowType = { cells: [], ref: line };
    if (row.cells == null) {
      return row;
    }
    for (let i = 0; i < fields.length; i++) {
      const field: string = fields[i];
      let output;
      let title: string | undefined;
      const keys: Array<string> = Object.keys(line);
      if (typeof Object.values(line)[keys.indexOf(field)] === 'object') {
        const val: any = Object.values(line)[keys.indexOf(field)];
        output =
          val.basic != null && val.total != null && val.closed != null
            ? val.basic + '/' + val.total + '/' + val.closed
            : val;
        title = 'basic/total/closed';
      } else {
        output = Object.values(line)[keys.indexOf(field)];
        title = output as string;
      }
      switch (field) {
        default: {
          if (Object.prototype.hasOwnProperty.call(line, field)) {
            row.cells.push({
              value: output,
              display: <div title={title}>{output}</div>,
              methods: {},
              copyVal: output,
            });
          } else {
            row.cells.push({ value: '' });
          }
          break;
        }
      }
    }

    return row;
  };
  private changeTracker: Array<string> = [];
  constructor(props: IProps) {
    super(props);
    let multipleReps: boolean = false;
    let initKey: string | undefined = undefined;
    const keys = Object.keys(props.data);
    for (const key of keys) {
      if (key.includes('rpt0')) {
        multipleReps = true;
        initKey = key;
        break;
      }
    }
    this.state = {
      hasMultipleReports: multipleReps,
      selectedReport: initKey,
    };
  }
  reviveState() {}
  stopPropagation(event: SyntheticEvent) {
    event.stopPropagation();
  }
  onCancel() {
    OverlayHandler.closeSpecific(Overlays.productOverlay);
  }

  generateSourceData(data: any) {
    if (this.state.selectedReport != null && data.report == null) {
      return (
        <React.Fragment>
          {this.generateSourceData(data[this.state.selectedReport])}
        </React.Fragment>
      );
    }
    if (data.report != null && data.report instanceof Array) {
      return (
        <TableBox>
          <Table
            sourceData={data.report}
            stableHeader={true}
            fields={this.findAccurateHeaders(data.report)}
            header={this.findAccurateHeaders(data.report)}
            dataConverter={this.state.hasMultipleReports === true ? this.converter : undefined}
          />
        </TableBox>
      );
    } else if (data.report != null && typeof data.report === 'object') {
      return <FlexRowBox>{this.buildObjectFields(data.report)} </FlexRowBox>;
    } else if (data != null && data instanceof Array) {
      return (
        <MarginField>
          <Table
            sourceData={data}
            header={this.findAccurateHeaders(data)}
            fields={this.findAccurateHeaders(data)}
            stableHeader={true}
          />
        </MarginField>
      );
    } else {
      return <FlexRowBox>{this.buildObjectFields(data)} </FlexRowBox>;
    }
  }

  buildObjectFields(data: any) {
    const out = [];
    for (const key in data) {
      if (data[key] instanceof Array) {
        out.push(
          <KeyValueBlock style={{ maxWidth: '100%' }}>
            <KeyField>{key}</KeyField>
            <ValueField>
              <MarginField>
                <Table
                  sourceData={data[key]}
                  header={this.findAccurateHeaders(data[key])}
                  fields={this.findAccurateHeaders(data[key])}
                  stableHeader={true}
                />
              </MarginField>
              )
            </ValueField>
          </KeyValueBlock>,
        );
      }
      if (typeof data[key] === 'object' && !(data[key] instanceof Date)) {
        out.push(
          <KeyValueBlock style={{ maxWidth: '100%' }}>
            <KeyField>{key}</KeyField>
            <ValueField>
              <MarginField>{this.buildObjectFields(data[key])}</MarginField>)
            </ValueField>
          </KeyValueBlock>,
        );
      } else {
        out.push(
          <KeyValueBlock>
            <KeyField>{key}</KeyField>
            <ValueField>{data[key].toString()}</ValueField>
          </KeyValueBlock>,
        );
      }
    }
    return out;
  }

  findAccurateHeaders(data: Array<any>) {
    let out: Array<string> = [];
    for (const o of data) {
      if (Object.keys(o).length > out.length) {
        out = Object.keys(o);
      }
    }
    return out;
  }

  generateMetaData(data: any): Array<ReactElement> {
    const out = [];
    if (data.params != null) {
      for (const key in data.params) {
        out.push(
          <KeyValueBlock style={{ maxWidth: '300px' }}>
            <KeyField>{key}</KeyField>
            <ValueField
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {data.params[key] instanceof Date
                ? format.date(data.params[key])
                : data.params[key]}
            </ValueField>
          </KeyValueBlock>,
        );
      }
    } else {
      if (this.state.selectedReport != null && data.params == null) {
        return this.generateMetaData(data[this.state.selectedReport]);
      }
    }
    return out;
  }

  onCreateKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  generateReportSelect() {
    const out = [];
    for (const key in this.props.data) {
      out.push(
        <ValueFieldReports
          onClick={() => {
            this.setState({
              selectedReport: key,
            });
          }}
        >
          {key}
        </ValueFieldReports>,
      );
    }
    return out;
  }

  defineContent() {
    return (
      <StyledOverlay onClick={event => event.stopPropagation()}>
        <Title title={translate('generic.report')} />
        {this.state.hasMultipleReports === true ? (
          <MetaDataBox>{this.generateReportSelect()}</MetaDataBox>
        ) : null}
        <MainBox>
          <KeyField style={{ color: 'black' }}>MetaData</KeyField>
          <MetaDataBox>{this.generateMetaData(this.props.data)}</MetaDataBox>
          <KeyField style={{ color: 'black' }}>Data</KeyField>

          {this.generateSourceData(this.props.data)}
        </MainBox>
      </StyledOverlay>
    );
  }
}
const MainBox = styled.div`
  width: 95%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
const MetaDataBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 32px;
`;
const FlexRowBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding: 32px;
`;
const MarginField = styled.div`
  margin-left: 32px;
  & > div > div > table {
    max-width: 20000px;
    overflow-x: auto;
    table-layout: auto;
    width: auto;
    margin: auto;
  }
`;
const TableBox = styled.div`
  & > div > div > table {
    max-width: 20000px;
    overflow-x: auto;
    table-layout: auto;
    width: auto;
    margin: auto;
    min-width: 70%;
  }
`;
const ValueFieldReports = styled(ValueField)`
  margin: 16px;
  :hover {
    cursor: pointer;
  }
`;
const KeyValueBlock = styled(KeyValueBlockBig)`
  max-height: 62px;
  flex-direction: row;
  justify-content: space-between;
`;
