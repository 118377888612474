import { ITranslation } from './ITranslation';

export const sq: ITranslation = {
  accountSelector: {
    lastTransaction: 'transaksion i fundit',
    accounts: 'llogaritë',
  },
  dashboard: {
    last25: {
      last25customers: 'ToDo',
      last25: 'ToDo',
    },
  },
  administration: {
    teams: {
      nomembers: 'Nuk ka anëtarë',
      teammembers: 'anëtarët e ekipit',
    },
    systemstate: {
      userstats: 'ToDO',
      transactionstats: 'Transaction Statistics',
      dateFrom: 'Date from',
      dateTo: 'Date to',
      name: 'Name',
      date_create: 'Date',
      unbooked_txn: 'Unbooked Transactions',
      number_of_txn: 'Total Number of Transactions',
      min_timeused: 'Min Time',
      avg_timeused: 'Avg Time',
      med_timeused: 'Med Time',
      p99_timeused: '99% Time',
      max_timeused: 'Max Time',
    },
    third_party_monitoring: {
      header: 'ToDo',
    },
    clearing_files: {
      header: 'BPC-Clearing Files',
      selectFile: 'ToDo',
      table: {
        original_id: 'ToDo',
        inst_id: 'ToDo ',
        file_type: 'ToDo ',
        filename: 'ToDo ',
        datetime_booked: 'ToDo ',
        start_date: 'ToDo ',
        end_date: 'ToDo ',
      },
    },
    card_status: {
      header: 'ToDo',
      selectFile: 'ToDo',
      table: {
        datetime_imported: 'ToDo',
      },
    },
    configuration: {
      header: 'ToDo',
      memo: 'ToDo',
      valid_from: 'ToDo ',
      invalid_from: 'ToDo ',
      amount: 'ToDo',
      enterAmount: 'ToDo',
      event: 'ToDo',
      delete: 'ToDo',
      events: {
        A: 'ToDo ',
        I: 'ToDo',
        Y: 'ToDo',
        V: 'ToDO',
        J: 'ToDo',
      },
    },
  },
  auth: {
    complete: {
      title: 'procesi i plotë',
      subtitle:
        'Ju lutemi nënshkruani në llogarinë tuaj personale në qoftë se ju doni për të ndryshuar fjalëkalimin tuaj përsëri.',
    },
    confirmation: {
      title: 'Ju keni marrë postë',
      subtitle: 'Ju lutem klikoni në linkun e aktivizimit në E-Mail që keni marrë.',
    },
    password: {
      title: 'Vendosur një fjalëkalim',
      subtitle: 'Vendosur një fjalëkalim për llogarinë tuaj',
      link: 'fjalëkalim',
      notMatchingError: 'Fjalëkalimet Jo ndeshje',
      inValidPasswordError: 'Ju lutem shkruani një fjalëkalim i vlefshëm',
      input: {
        password: {
          label: 'Set fjalëkalimin tuaj',
          placeholder: 'Shkruani fjalëkalimin',
        },
        password2: {
          label: 'Fusni fjalëkalimin tuaj përsëri',
          placeholder: 'Fusni fjalëkalimin tuaj përsëri',
        },
        current: {
          label: 'Fjalëkalimi Aktual',
          placeholder: 'Vendosni Fjalëkalimin Aktual',
        },
      },
    },
    resetPassword: {
      title: 'Rivendosni fjalëkalimin',
      subtitle: 'Ju lutemi nënshkruani në llogarinë tuaj personale.',
      link: 'Rivendosni fjalëkalimin',
      input: {
        email: {
          label: 'E-Mail',
          placeholder: 'E-Mail',
        },
      },
    },
    signin: {
      title: 'Hyni',
      subtitle: 'Ju lutemi nënshkruani në llogarinë tuaj personale.',
      link: 'Kliko këtu për të nënshkruar në',
      pwUserNeeded: 'Fjalëkalim/Emri i përdoruesit nevojshme',
      userNeeded: 'Emri i përdoruesit nevojshme',
      input: {
        email: {
          label: 'E-Mail',
          placeholder: 'Shkruani E-Mail tuaj',
        },
        password: {
          label: 'fjalëkalim',
          placeholder: 'Shkruani fjalëkalimin tuaj',
        },
      },
    },
    signup: {
      signup: 'SHENJTOHEN',
      title: 'Regjistrohu',
      subtitle: 'Ju lutem regjistroheni.',
      link: 'Kliko këtu për të nënshkruar',
      input: {
        email: {
          label: 'E-Mail',
          error: 'Ju lutemi shkruani adresën tuaj e-mail',
        },
        firstName: {
          label: 'Emri',
          error: 'Ju lutemi shkruani emrin tuaj të parë',
        },
        lastName: {
          label: 'Mbiemri',
          error: 'Ju lutemi shkruani emrin tuaj të fundit',
        },
        conditions: {
          label: 'Unë pajtohem me termat dhe kushtet',
          error: 'Ju duhet të pranojnë termat dhe kushtet tona',
        },
      },
    },
    faceLogin: {
      title: 'Njohja e Fytyrës',
      subtitle:
        'Ju lutemi filloni Face Recognition duke klikuar butonin dhe të kërkoni në kamera. Ju lutemi vendosni fytyrën tuaj brenda kornizës.',
      link: 'Kliko këtu për njohjen e fytyrës',
    },
    contactAdmin: {
      title: 'Kontaktoni me Administratorin',
      subtitle: 'Ju lutemi nënshkruani në llogarinë tuaj personale.',
      link: 'Kontaktoni me Administratorin',
    },
    processComplete: {
      title: 'procesi i plotë',
      subtitle:
        'Ju lutemi nënshkruani në llogarinë tuaj personale në qoftë se ju doni për të ndryshuar fjalëkalimin tuaj përsëri.',
      link: '',
    },
    contactComponent: {
      title: 'Na kontaktoni',
      subtitle: 'Ju lutem ...',
      message: 'mesazh',
      requestInquiries: 'Kërkesa për hetimet',
    },
    verify: {
      title: 'Shkruani kodin',
      subtitle:
        'Ne kemi dërguar një lidhje për të E-Mail-adresën tuaj. Ju lutemi shkruani kodin e marra.',
      link: 'Verifiko kodin',
      input: {
        email: {
          label: 'E-Mail juaj',
          placeholder: 'Shkruani E-Mail tuaj',
          error: 'E-Mail nevojshme',
        },
        code: {
          label: 'kod',
          placeholder: 'Shkruani kodin',
          error: 'Token e nevojshme',
        },
        error: 'E-Mail/Shenjë nevojshme',
      },
    },
    footer: {
      powered: 'mundësuar nga',
    },
  },
  backend: {
    '999999': 'Teksti i gabimit duhet të caktohet.',
    '1000001': 'ID-ja e llogarisë është shumë e gjatë.',
    '1000101': 'Subjekti nuk është vendosur.',
    '1000102': 'Llogaria e burimit nuk është caktuar.',
    '1000103': 'Llogaria e synuar nuk është caktuar.',
    '1000104': 'Teksti nuk është vendosur.',
    '1000105': 'Subjekti nuk ekziston në bazën e të dhënave.',
    '1000106': 'Llogaria e burimit nuk ekziston.',
    '1000107': 'Llogaria e burimit është në një gjendje të pasaktë.',
    '1000108': 'Ndodhi një gabim në kontekstin e identifikimit të llogarisë së synuar.',
    '1000109': 'Llogaria e synuar është në një gjendje të pasaktë.',
    '1000110': 'Shuma nuk është caktuar.',
    '1000111': 'Shuma është zero.',
    '1000112': 'Shuma është negative.',
    '1000113': 'Shuma është shumë e madhe.',
    '1000114': 'Shuma ka shumë shifra dhjetore.',
    '1000115': 'Monedha nuk është vendosur.',
    '1000116': 'Monedha nuk ekziston në bazën e të dhënave.',
    '1000117': 'Monedha nuk është dhënë për llogarinë burimore.',
    '1000118': 'Monedha nuk është dhënë për llogarinë e synuar.',
    '1000119': 'Nuk mund të merret ID-ja e burimit.',
    '1000120': 'Qëllimi është i pavlefshëm.',
    '1000121': 'Koha lokale e të dhënave nuk është caktuar.',
    '1000122': 'Vendndodhja nuk është caktuar.',
    '1000123': 'Transaksioni është dublikatë.',
    '1000201': 'Llogaria e dhënë është e panjohur.',
    '1000202': 'Tabela e dhënë është e panjohur.',
    '1000203': 'Gjeneratori nuk është konfiguruar.',
    '1000301': 'Transaksioni nuk ekuilibron.',
    '1000302': 'Transaksioni nuk ka deklarata.',
    '1000303': 'Llogaria nuk është e hapur.',
    '1000304': 'account not open',
    '1000305': 'number of statements < 2',
    '1000401': 'Transaksioni nuk është rezervuar.',
    '1000501': 'Transaksioni nuk ekziston.',
    '1000601': 'Shembulli i dhënë është i panjohur.',
    '1000602': 'Tabela e dhënë është e panjohur.',
    '1000603': 'Gjeneratori nuk është konfiguruar.',
    '1000701': 'Kombësia e dhënë nuk dihet.',
    '1000702': 'Identifikimi ekziston tashmë.',
    '1000703': 'Emaili ekziston tashmë.',
    '1000704': 'Personi duhet të jetë së paku 18 vjeç.',
    '1000705': 'Personat pa fjalëkalim lejohen vetëm kur krijohen nga operatori i biznesit.',
    '1000706': 'Id-ja e personit të jashtëm nuk është vendosur.',
    '1000707': 'Siguruesi i të dhënave është i panjohur. Ju lutemi përdorni vetëm ndIdent.',
    '1000708': 'Partneri i dhënë është i panjohur.',
    '1000801': 'Nuk ofrohet asnjë emër përdoruesi ose email.',
    '1000802': 'Përdoruesi nuk ekziston.',
    '1000902': 'Ka shumë dhjetore.',
    '1001000': 'ID-ja e llogarisë nuk është caktuar.',
    '1001001': 'Lloji i bilancit është i panjohur.',
    '1001002': 'Data nuk është caktuar.',
    '1001003': 'Malli nuk është vendosur.',
    '1001004': 'Nënbalanci nuk është vendosur.',
    '1001100': 'Subjekti është i panjohur.',
    '1001101': 'Llogaria është e panjohur.',
    '1001102': 'Lloji i bilancit është i panjohur.',
    '1001103': 'Data e fillimit nuk është caktuar.',
    '1001104': 'Data e përfundimit nuk është caktuar.',
    '1001200': 'Subjekti nuk është vendosur.',
    '1001201': 'Numri i llogarisë nuk është caktuar.',
    '1001202': 'Subjekti është i panjohur.',
    '1001203': 'Numri i llogarisë është i panjohur.',
    '1001300': 'Subjekti është i panjohur.',
    '1001301': 'Numri i llogarisë është i panjohur.',
    '1001302': 'Lloji i bilancit është i panjohur.',
    '1001303': 'Data e fillimit nuk është caktuar.',
    '1001304': 'Data e përfundimit nuk është caktuar.',
    '1001400': 'Subjekti nuk është vendosur.',
    '1001401': 'Llogaria nuk është caktuar.',
    '1001402': 'Subjekti nuk ekziston.',
    '1001403': 'Llogaria nuk ekziston.',
    '1001500': 'Subjekti nuk është vendosur.',
    '1001501': 'Gjuha nuk është vendosur.',
    '1001502': 'Zona kohore nuk është caktuar.',
    '1001503': 'Subjekti është i panjohur.',
    '1001504': 'Personi është i panjohur.',
    '1001505': 'Gjuha është e panjohur.',
    '1001506': 'Zona kohore është e panjohur.',
    '1001600': 'Gjendja fillestare është e pavlefshme.',
    '1001601': 'Adresa e emailit është në listën e zezë. Ju lutemi kontaktoni mbështetjen.',
    '1001602': 'Posta është dërguar tashmë ose transaksioni nuk ekziston.',
    '1001603': 'Posta është dërguar tashmë ose njoftimi nuk ekziston.',
    '1001700': 'Id-ja e mesazhit është e panjohur.',
    '1001800': 'Kombësia nuk dihet.',
    '1001801': 'Qyteti nuk është vendosur.',
    '1001900': 'Kombësia nuk dihet.',
    '1001901': 'Qyteti nuk është vendosur.',
    '1001902': 'Nuk u gjet asnjë rekord.',
    '1002000': 'Nuk u gjet asnjë rekord.',
    '1002100': 'Kombësia nuk dihet.',
    '1002101': 'Nuk u gjet asnjë rekord.',
    '1002102': 'Personi duhet të jetë së paku 18 vjeç.',
    '1002300': 'Adresa e postës elektronike është caktuar tashmë.',
    '1002400': 'Nuk u gjet asnjë rekord.',
    '1002500': 'Nuk u gjet asnjë rekord.',
    '1002501': 'Nuk mund të fshihet adresa kryesore e postës elektronike.',
    '1002600': 'Numri i telefonit është caktuar tashmë',
    '1002700': 'Nuk u gjet asnjë rekord.',
    '1002701': 'Nuk ka mbetur numri kryesor i telefonit.',
    '1002800': 'Nuk u gjet asnjë rekord.',
    '1002801': 'Numri kryesor i telefonit mund të fshihet vetëm nëse është i fundit.',
    '1002900': 'Adresa e postës elektronike nuk është caktuar.',
    '1002901': 'Adresa e emailit nuk dihet.',
    '1003000': 'Shenja nuk ekziston.',
    '1003100':
      'Adresa e postës elektronike është vendosur në listën e zezë. Ju lutemi kontaktoni mbështetjen.',
    '1003101':
      'Adresa e postës elektronike është vendosur në listën e zezë. Ju lutemi kontaktoni mbështetjen.',
    '1003200': 'I pazoti të krijojë tekst memo. Rritni gjatësinë e fushës së memos.',
    '1003300': 'Pagesa nuk është e autorizuar.',
    '1003301': 'Pagesa u refuzua.',
    '1003302': 'Nuk u gjet asnjë regjistër hyrjeje.',
    '1003303': 'Personi nuk ekziston në bazën e të dhënave.',
    '1003304': 'Llogaria nuk ekziston.',
    '1003305': 'Monedha nuk ekziston në bazën e të dhënave.',
    '1003306': 'Nënbalanci nuk ekziston.',
    '1003307': 'Shteti nuk dihet.',
    '1003308': 'Llogaria e atm është e panjohur.',
    '1003309': 'Shuma është zero.',
    '1003310': 'Shuma është negative.',
    '1003311': 'Shuma është shumë e madhe.',
    '1003312': 'Shumë shifra dhjetore.',
    '1004000': 'Uid-i është i kyçur.',
    '1004001': 'Uid është i panjohur.',
    '1004002': 'Shenja është përdorur tashmë.',
    '1004003': 'Formati uid është i pavlefshëm.',
    '1004100': 'Karta është e kyçur.',
    '1004101': 'Karta është e panjohur.',
    '1004102': 'Shenja është përdorur tashmë.',
    '1004200': 'Fondet janë të pamjaftueshme.',
    '1004201': 'Shenja është e pavlefshme.',
    '1004202': 'Shenja është e pavlefshme.',
    '1004203': 'Monedha është e pavlefshme.',
    '1004204': 'Ka shumë shifra dhjetore.',
    '1004205': 'Nuk u gjet asnjë seancë aplikacioni.',
    '1004206': 'Nuk është specifikuar asnjë adresë e-mail.',
    '1004207': 'Asnjë person i specifikuar.',
    '1004300': 'Fondet janë të pamjaftueshme.',
    '1004301': 'Shenja është e pavlefshme.',
    '1004302': 'PIN-i është i pavlefshëm.',
    '1004303': 'Monedha është e pavlefshme.',
    '1004304': 'Shumë shifra dhjetore.',
    '1004305':
      'Nuk mund të gjendet pagesa e atmosferës ose pagesa e atmosferës është përdorur tashmë.',
    '1004306': 'Vlera e shumës nuk përputhet.',
    '1004307': 'Monedha nuk përputhet.',
    '1004308': 'Eksponenti i monedhës nuk përputhet.',
    '1004309': 'Pin-i është vendosur tashmë.',
    '1004310': 'Koha e vërtetimit të pagesave të atm-it ishte caktuar tashmë.',
    '1004311': 'Pagesa e atmosferës është kryer tashmë.',
    '1004312': 'Shenja është përdorur tashmë.',
    '1004400': 'Shenja është e pavlefshme.',
    '1004401':
      'Nuk mund të gjendet pagesa e atmosferës ose pagesa e atmosferës është konfirmuar tashmë.',
    '1004500': 'Shenja është përdorur tashmë.',
    '1004600': 'ID-ja e pajisjes nuk mund të gjendet.',
    '1004700': 'Llogaria nuk mund të gjendet.',
    '1004701': 'Kartela nuk mund të gjendet.',
    '1004702': 'Karta është caktuar në një llogari tjetër.',
    '1004800': 'Llogaria nuk mund të gjendet.',
    '1004801': 'Kartën nuk mund ta gjej.',
    '1004900':
      'Nuk mund të gjendet pagesa e atmosferës ose pagesa e atmosferës është përdorur tashmë.',
    '1005000': 'Nuk mund të gjendet llogaria.',
    '1005001': 'Nuk mund të gjesh nfc.',
    '1005002': 'NFC është caktuar në një llogari tjetër.',
    '1005100': 'Nuk mund të gjendet llogaria.',
    '1005101': 'Nuk mund të gjesh nfc.',
    '1005200': 'Nuk mund të gjesh kupon.',
    '1005300': 'Id-ja e personit nuk është vendosur.',
    '1005301': 'Numri i llogarisë nuk është caktuar.',
    '1005302': 'Shuma nuk është caktuar.',
    '1005303': 'Shuma është zero.',
    '1005304': 'Shuma është shumë e vogël.',
    '1005305': 'Shuma është shumë e madhe.',
    '1005306': 'Shuma ka shumë dhjetore.',
    '1005307': 'Monedha nuk është vendosur.',
    '1005308': 'Numri i kuponit nuk është caktuar.',
    '1005309': 'Monedha nuk dihet.',
    '1005310': 'Numri i llogarisë është i panjohur.',
    '1005311': 'Numri i kuponit është i panjohur.',
    '1005312': 'Numri i kuponit nuk korrespondon me një kupon.',
    '1005313': 'Monedha e llogarisë nuk përputhet me monedhën e dhënë.',
    '1005314': 'Monedha e kuponit nuk përputhet me monedhën e dhënë.',
    '1005400': 'Tregtari nuk ekziston.',
    '1005500': 'Id i njëjtë me të dhëna të ndryshme të ruajtura tashmë.',
    '1005600': 'Id i njëjtë me të dhëna të ndryshme të ruajtura tashmë.',
    '1005700': 'Përditësimi nuk ekziston.',
    '1005800': 'Llogaria nuk ekziston.',
    '1005900': 'Emri i partnerit është i panjohur.',
    '1006000':
      'Kam marrë tashmë një identifikues unik për të njëjtën llogari biznesi me të dhëna të ndryshme.',
    '1006001': 'ID-ja e transaksionit nuk mund të gjendet.',
    '1006002': 'Llogaria nuk mund të gjendet.',
    '1006003': 'Llogaria nuk është aktive.',
    '1006004': 'Nuk mund të gjeni monedhën.',
    '1006005': 'Nuk mund të gjesh ekuilibrin.',
    '1006006': 'Nuk mund të gjendet llogaria e atm.',
    '1006007': 'Shuma është zero.',
    '1006008': 'Shuma është shumë e vogël.',
    '1006009': 'Shuma është shumë e madhe.',
    '1006010': 'Shumë shifra dhjetore.',
    '1006100': 'Nuk mund ta gjej vendin.',
    '1006200': 'Pasaporta është dublikatë.',
    '1006300': 'Imazhi është dublikatë.',
    '1006400': 'Personi pret tashmë për aprovim.',
    '1006401': 'Nuk mund ta gjej personin.',
    '1006402': 'Nuk u gjet asnjë imazh selfi.',
    '1006403': 'Nuk u gjet asnjë imazh i ruajtur.',
    '1006404': 'Nuk mund të gjendet vendi lëshues.',
    '1006405': 'Nuk mund të gjej kombësinë.',
    '1006406': 'Numri i telefonit është caktuar tashmë.',
    '1006407': 'Adresa e postës elektronike është caktuar tashmë.',
    '1006500': 'Nuk mund ta gjej personin.',
    '1006501': 'Personi pret tashmë për aprovim.',
    '1006502': 'Imazhi është shumë i vogël.',
    '1006600': 'Nuk mund ta gjej personin.',
    '1006601': 'Personi pret tashmë për aprovim.',
    '1006602': 'Kombësia nuk dihet.',
    '1006700':
      'Marrë tashmë identifikues unik për të njëjtën llogari me të dhëna të ndryshme.',
    '1006701': 'ID-ja e transaksionit nuk mund të gjendet.',
    '1006702': 'Llogaria nuk mund të gjendet.',
    '1006703': 'Llogaria nuk është aktive.',
    '1006704': 'Nuk mund të gjeni monedhën.',
    '1006705': 'Nuk mund të gjesh ekuilibrin.',
    '1006706': 'Nuk mund të gjendet llogaria e atm.',
    '1006707': 'Shuma është zero.',
    '1006708': 'Shuma është shumë e vogël.',
    '1006709': 'Shuma është shumë e madhe.',
    '1006710': 'Shumë shifra dhjetore.',
    '1006711': 'Monedha e kripto është e panjohur.',
    '1006800':
      'Marrë tashmë identifikues unik për të njëjtën llogari me të dhëna të ndryshme.',
    '1006801': 'ID-ja e transaksionit nuk mund të gjendet.',
    '1006802': 'Llogaria nuk mund të gjendet.',
    '1006803': 'Llogaria nuk është aktive.',
    '1006804': 'Nuk mund të gjeni monedhën.',
    '1006805': 'Nuk mund të gjesh ekuilibrin.',
    '1006806': 'Nuk mund të gjendet llogaria e atm.',
    '1006807': 'Shuma është zero.',
    '1006808': 'Shuma është shumë e vogël.',
    '1006809': 'Shuma është shumë e madhe.',
    '1006810': 'Shumë shifra dhjetore.',
    '1006811': 'Monedha e kripto është e panjohur.',
    '1006900': 'Shumë prova të mbaruara.',
    '1006901':
      'Adresa e postës elektronike është regjistruar tashmë. Keni nevojë për një rivendosje të fjalëkalimit?',
    '1006902': 'Kodi i referimit nuk mund të gjendet.',
    '1006903': 'Nuk u gjet asnjë klient (produkt) oidc që përputhet për klasën e përdoruesit.',
    '1006904': 'only superadmin may create bo-user',
    '1007000': 'Nuk mund të gjesh shenjën.',
    '1007001': 'Adresat e emailit nuk përputhen.',
    '1007002': 'Shenja nuk është aktive.',
    '1007003': 'Ndodhi një gabim i panjohur.',
    '1007004': 'Shenja është përdorur tashmë.',
    '1007100': 'Lloji i mimikës për imazhin e ruajtur është i panjohur.',
    '1007101': 'Lloji i mimikës për imazhin e përparmë të pasaportës është i panjohur.',
    '1007102': 'Nuk mund ta gjej personin.',
    '1007103': 'Pasaporta i është caktuar tashmë një personi tjetër.',
    '1007104': 'Leximi i pasaportave për vendin lëshues nuk lejohet.',
    '1007200': 'Imazhi është shumë i vogël.',
    '1007201': 'Lloji i imazhit është i panjohur.',
    '1007202': 'Nuk mund ta gjej personin.',
    '1007300': 'Nuk mund ta gjej personin.',
    '1007400': 'Nuk mund ta gjej personin.',
    '1007500': 'Nuk mund të gjendet transaksioni.',
    '1007501': 'Lloji i transaksionit nuk është konfiguruar.',
    '1007600': 'Adresa e emailit është e panjohur.',
    '1007700': 'Nuk mund të gjesh simbolin.',
    '1007701': 'Adresat e emailit nuk përputhen.',
    '1007702': 'Shenja nuk është aktive.',
    '1007703': 'Ndodhi një gabim i panjohur.',
    '1007704': 'Nuk mund ta gjej personin.',
    '1007800': 'Nuk mund ta gjej personin.',
    '1007900': 'Imazhi është shumë i vogël.',
    '1007901': 'Lloji i imazhit është i panjohur.',
    '1007902': 'Nuk mund ta gjej personin.',
    '1007903': 'Pozicioni nist është i panjohur.',
    '1007904': 'Përshtypja nist është e panjohur.',
    '1007905': 'Kopjoni gjurmët e gishtave për pozicionin e dhënë nist.',
    '1008000': 'Id i njëjtë me të dhëna të ndryshme të ruajtura tashmë.',
    '1008100': "Ngarkimi fillestar lejohet vetëm për personin shtet 'V'.",
    '1008101': 'Tashmë keni pasaportë.',
    '1008102': 'Tashmë keni adresën.',
    '1008103': 'Tashmë keni telefonin.',
    '1008104': 'Tashmë keni id të gjurmës së gishtit.',
    '1008105': 'Tashmë keni ID-në e fytyrës.',
    '1008200': 'Nuk mund ta gjej klientin.',
    '1008300': 'Klienti tashmë ekziston.',
    '1008400': 'Kodi i verifikimit nuk mund të gjendet.',
    '1008401': 'Marrësi nuk përputhet.',
    '1008402': 'Kodi i verifikimit nuk është aktiv.',
    '1008403': 'Ndodhi një gabim i panjohur.',
    '1008404': 'Kodi i verifikimit është përdorur tashmë.',
    '1008500': 'Nuk u gjet asnjë kod verifikimi.',
    '1008600': 'Gjuha është e panjohur.',
    '1008700': 'Llogaria nuk mund të gjendet.',
    '1008800': 'Llogaria nuk mund të gjendet.',
    '1008900': 'Nuk janë dhënë parametra kërkimi.',
    '1009000': 'Llogaria nuk mund të gjendet.',
    '1009001': 'Llogaria është në një gjendje të gabuar.',
    '1009002': 'Bilanci i llogarisë nuk është zero.',
    '1009100': 'Nuk mund ta gjej personin.',
    '1009113': 'Nuk mund të gjendet llogaria e kreditorit.',
    '1009200': 'Shuma e udhëzuar ka shumë shifra.',
    '1009201': 'Monedha nuk dihet.',
    '1009202': 'Nuk mund të përcaktohet lloji i llogarisë së kreditorit.',
    '1009300': 'Llogaria e debitorit nuk është caktuar.',
    '1009301': 'Lloji i llogarisë së debitorit është i panjohur.',
    '1009302': 'Monedha e udhëzuar nuk është vendosur.',
    '1009303': 'Llogaria e kreditorit nuk është vendosur.',
    '1009304': 'Lloji i llogarisë së kreditorit është i panjohur.',
    '1009305': 'Emri i kreditorit nuk është vendosur.',
    '1009306': 'Transaksioni nuk ishte specifikuar as si e ardhur ose si rezultat.',
    '1009307': 'Lloji i llogarisë së debitorit nuk është implementuar.',
    '1009308': 'Lloji i llogarisë së kreditorit nuk është implementuar',
    '1009309': 'Nuk mund të gjendet llogaria e debitorit.',
    '1009310': 'Debitori është një llogari e jashtme.',
    '1009311': 'Debitori nuk ka asnjë iban dhe nuk mund të dërgojë pagesa sepa.',
    '1009312': 'Kreditori nuk ka iban.',
    '1009314': 'Pagesat hyrëse për llogaritë e jashtme nuk janë implementuar.',
    '1009315': 'Nuk mund të gjesh llogari të ndërmjetme.',
    '1009316': 'Shuma e udhëzuar nuk është caktuar.',
    '1009317': 'Shuma e udhëzuar është në ose nën zero.',
    '1009318':
      'Id-ja e transaksionit është vendosur por ID-ja e parave të kërkesës nuk është vendosur.',
    '1009319':
      'Identifikimi i parave të kërkesës është vendosur por ID-ja e transaksionit nuk është vendosur.',
    '1009320': 'Dërguesit nuk i lejohet të pranojë kërkesa për para.',
    '1009321': 'Kërkesa për para nuk përputhet me rrjedhën e parave.',
    '1009322': 'Vetëm marrësi mund të pranojë një kërkesë parash.',
    '1009323': 'Llogaria e marrësit ndryshoi.',
    '1009324': 'Ndryshimi i monedhës nuk lejohet.',
    '1009325': 'Ndryshimi i kreditorit nuk lejohet.',
    '1009326': 'Ndryshimi i kreditorit nuk lejohet.',
    '1009327': 'Nuk u gjet saktësisht një transaksion i referuar.',
    '1009400':
      'Trajtimi i transaksioneve në pritje për burimin e dhënë nuk është implementuar.',
    '1009500': 'Id-ja e procesit të krijimit të personit nuk është vendosur.',
    '1009501': 'Llogaria rrjedhëse nuk është caktuar.',
    '1009502': 'Lloji i llogarisë së debitorit është i panjohur.',
    '1009503': 'Monedha e udhëzuar nuk është vendosur.',
    '1009504': 'Llogaria e kreditorit nuk është vendosur.',
    '1009505': 'Shuma nuk është caktuar.',
    '1009506': 'Shuma është në ose nën zero.',
    '1009507': 'Nuk mund të gjendet llogaria rrjedhëse.',
    '1009508': 'Nuk mund të gjendet llogaria e kreditorit.',
    '1009509': 'Në maksimum lejohet një llogari cash (një klient).',
    '1009600': 'Nuk mund ta gjej adresën.',
    '1009700': 'Nuk mund ta gjej adresën.',
    '1009701': 'Lloji i adresës ekziston tashmë.',
    '1009702': 'address approval only possible with identified person',
    '1009800': 'Llogaria nuk mund të gjendet.',
    '1009900': 'Llogaria nuk mund të gjendet.',
    '1010000': 'Nuk janë dhënë parametra kërkimi.',
    '1010100': 'Shuma mujore e të ardhurave tejkalohet.',
    '1010101': 'Shuma mujore e daljeve është tejkaluar.',
    '1010102': 'Bilanci është nën vlerën minimale të lejuar.',
    '1010103': 'Bilanci është mbi vlerën maksimale të lejuar.',
    '1010104': 'Shuma nuk është caktuar.',
    '1010105': 'Janë dhënë shumë shifra dhjetore.',
    '1010106': 'amount day in exceeded',
    '1010107': 'amount day out exceeded',
    '1010108': 'amount week in exceeded',
    '1010109': 'amount week out exceeded',
    '1010110': 'amount year in exceeded',
    '1010111': 'amount year out exceeded',
    '1010112': 'amount day withdraw exceeded',
    '1010113': 'amount week withdraw exceeded',
    '1010114': 'amount month withdraw exceeded',
    '1010115': 'amount year withdraw exceeded',
    '1010116': 'amount day upload exceeded',
    '1010117': 'amount week upload exceeded',
    '1010118': 'amount month upload exceeded',
    '1010119': 'amount year upload exceeded',
    '1010120': 'unknown validity',
    '1010200': 'Diapazoni i datës është i pavlefshëm.',
    '1010300': 'Nuk mund të gjej llogarinë vetjake.',
    '1010301': 'Nuk mund ta gjej personin e vet.',
    '1010302': 'Nuk mund të gjej adresën e emailit.',
    '1010303': 'Nuk mund të gjendet llogaria e palës tjetër.',
    '1010304': 'Numri i llogarisë së palës tjetër nuk është vendosur.',
    '1010305': 'Nuk mund ta gjej personin e palës tjetër.',
    '1010306': 'Nuk mund të gjej adresën e postës elektronike të palës tjetër.',
    '1010307': 'Shuma nuk është caktuar.',
    '1010308': 'Shuma është negative.',
    '1010309': 'Shuma është zero.',
    '1010310': 'Kërkesat për të njëjtën llogari janë të lejuara.',
    '1010311': 'Kërkesat për të njëjtin person janë të lejuara.',
    '1010312': 'Nuk mund të gjendet llogaria e kreditorit.',
    '1010313': 'Nuk mund ta gjej kreditorin iban.',
    '1010400': 'Kërkesa për para nuk mund të gjendet.',
    '1010401': 'Nuk mund të gjendet kërkesa për para e palës tjetër.',
    '1010402': 'Kërkesa për para nuk është në gjendje në pritje.',
    '1010403': 'Kërkesa për para e palës tjetër nuk është në gjendje në pritje.',
    '1010404': 'Marrësi përpiqet të azhurnojë kërkesën e dërguesve?',
    '1010405': 'Dërguesi përpiqet të azhurnojë kërkesën e marrësit?',
    '1010500': 'Kërkesa për para nuk është në një gjendje të saktë.',
    '1010600': 'Nuk mund të gjendet procesi i njoftimit.',
    '1010700': 'Kombinimi i tabelës dhe procesit të bazës së të dhënave është i panjohur.',
    '1010701': 'Kodi i verifikimit nuk është më aktiv.',
    '1010702': 'Kodi i verifikimit nuk është më aktiv.',
    '1010800': 'Ndryshimi i fjalëkalimit nuk lejohet.',
    '1010900': 'Nuk mund ta gjej personin.',
    '1010901': 'Nuk mund të gjendet adresa e emailit.',
    '1011000': 'Bilanci i ri është nën kufirin e poshtëm.',
    '1011100': 'Kodi unik nuk mund të krijohej.',
    '1011200': 'Nuk mund të gjendet transaksioni.',
    '1011201': 'Shuma e ndarë nuk është debituese.',
    '1011202': 'Llogaritë e palës tjetër nuk përputhen me llojet përkatëse të llogarisë.',
    '1011203': 'Llogaritë e palës tjetër nuk përputhen me aksionet përkatëse.',
    '1011204': 'Shuma e palës tjetër është negative.',
    '1011205': 'Shuma e palës tjetër është zero.',
    '1011206': 'Shuma e shumës së ndarë e tejkalon shumën origjinale.',
    '1011300': 'Marka e kartës është e panjohur.',
    '1011301': 'Numri i kartës është i pavlefshëm.',
    '1011302': 'Numri i kartës i është caktuar tashmë një personi.',
    '1011400': 'Kartën nuk mund ta gjej.',
    '1011500': 'Id-ja e personit nuk është vendosur.',
    '1011501': 'Llogaria nuk është caktuar.',
    '1011502': 'Llogaria nuk mund të gjendet.',
    '1011503': 'Shuma nuk është caktuar.',
    '1011504': 'Shuma është në ose nën zero.',
    '1011505': 'Monedha nuk është vendosur.',
    '1011506': 'Nuk mund të gjeni monedhën.',
    '1011508': 'Llogaria e jashtme nuk është caktuar.',
    '1011509': 'Nuk mund të gjendet llogaria e jashtme.',
    '1011510': 'Llogaria e transferimit nuk mund të gjendet.',
    '1011511': 'fee account not found',
    '1011512': 'net amount below zero',
    '1011513': 'fee amount below zero',
    '1011514': 'not payed',
    '1011515': 'amount instructed does not match amount payed',
    '1011600': 'Llogaria nuk mund të gjendet.',
    '1011601': 'Nuk mund të gjendet ofruesi i kartës.',
    '1011602': 'Nuk mund të gjendet lloji i kartës.',
    '1011603': 'Lloji i kartës është për ofruesin që nuk është i vlefshëm.',
    '1011604': 'Ekziston tashmë një marrëveshje me ofruesin.',
    '1011605': 'unknown error saving card agreement',
    '1011700': 'Llogaria nuk mund të gjendet.',
    '1011701': 'Nuk mund të gjesh marrëveshjen e kartës.',
    '1011702': 'Marrëveshja e kartës nuk është aktive.',
    '1011800': 'Llogaria nuk mund të gjendet.',
    '1011801': 'Nuk mund të gjesh marrëveshjen e kartës.',
    '1011802': 'Marrëveshja e kartës nuk është aktive.',
    '1011803': 'Karakteristika nuk është zbatuar.',
    '1011804': 'customer not registered at bpc',
    '1011900': 'Llogaria nuk mund të gjendet.',
    '1011901': 'Nuk mund të gjesh marrëveshjen e kartës.',
    '1011902': 'Marrëveshja e kartës nuk është aktive.',
    '1011903': 'Marrëveshja e kartës nuk është aktive.',
    '1012000': 'Nuk mund të gjendet imazhi.',
    '1012100': 'Nuk mund të gjendet imazhi.',
    '1012200': 'Numri i telefonit është tashmë në përdorim.',
    '1012201':
      'Numri i telefonit është në pritje të verifikimit. Ju lutemi riprovoni më vonë.',
    '1012300': 'Llogaria nuk mund të gjendet.',
    '1012301': 'Procesi është i panjohur.',
    '1012302': 'Kanali është i panjohur.',
    '1012400': 'Numri i telefonit është tashmë në përdorim.',
    '1012401':
      'Numri i telefonit është në pritje të verifikimit. Ju lutemi riprovoni më vonë.',
    '1012402': 'Nuk mund të gjej procesin e krijimit të personit. (zhdukur ose mbaruar)',
    '1012500': 'Nuk mund ta gjej emailin.',
    '1012600': 'Shtojca nuk shoqërohet me kërkesën.',
    '1012601': 'Nuk mund të gjendet shtojca.',
    '1012602': 'Shtojca nuk shoqërohet me kërkesën.',
    '1012603': 'Shtojca nuk shoqërohet me kërkesën.',
    '1012604': 'Burimi nuk është konfiguruar.',
    '1012700': 'Data mungon.',
    '1012800': 'Çelësi i hyrjes dihet tashmë.',
    '1012900': 'Nuk mund të gjendet çelësi i hyrjes.',
    '1012901': 'Çelësi i hyrjes nuk është aktiv.',
    '1013000': 'Çelësi i hyrjes është tashmë aktiv.',
    '1013001': 'Nuk mund ta gjej çelësin e hyrjes ose aktiv nuk është aktiv.',
    '1013100': 'Nuk mund të gjendet përgjigja.',
    '1013200': 'Nuk mund të gjendet përgjigja.',
    '1013300': 'U gjet i njëjti transaksion me url tjetër të thirrjes.',
    '1013400': 'Nuk mund ta gjej personin.',
    '1013500': 'Nuk mund ta gjej rekordin.',
    '1013501': 'Anulimi është i mundur vetëm për arka vetanake.',
    '1013600': 'Nuk mund të gjesh asnjë dokument.',
    '1013700': 'Nuk mund ta gjej personin.',
    '1013800': 'Adresa për këtë lloj adrese është ruajtur tashmë.',
    '1013900': 'Emaili është tashmë në përdorim.',
    '1013901': 'Emaili po pret për verifikim. Ju lutemi riprovoni më vonë.',
    '1013902': 'Nuk mund të gjej procesin e krijimit të personit. (zhdukur ose mbaruar)',
    '1014000': 'Nuk ka asnjë të dhënë të përdorshme.',
    '1014001': 'Kodi shoqërues nuk mund të gjendet ose është aktiv.',
    '1014100': 'Nuk mund të gjej procesin e krijimit të personit. (zhdukur ose mbaruar)',
    '1014200': 'Nuk mund të gjej procesin e krijimit të personit. (zhdukur ose mbaruar)',
    '1014300': 'Nuk mund të gjej procesin e krijimit të personit. (zhdukur ose mbaruar)',
    '1014400': 'Nuk mund të gjej procesin e krijimit të personit. (zhdukur ose mbaruar)',
    '1014500': 'Nuk mund të gjej procesin e krijimit të personit. (zhdukur ose mbaruar)',
    '1014600': 'Nuk mund të gjej procesin e krijimit të personit. (zhdukur ose mbaruar)',
    '1014700': 'Nuk mund të gjej procesin e krijimit të personit. (zhdukur ose mbaruar)',
    '1014800': 'Nuk mund te vazhdohet!',
    '1014801': 'Nuk ka partner për klientin oidc të konfiguruar.',
    '1014900': 'Llogaria është e panjohur.',
    '1015000': 'Llogaria është e panjohur.',
    '1015100': 'Llogaria është e panjohur.',
    '1015200': 'Etiketa e telefonit celular është e panjohur.',
    '1015201': 'Ju tashmë jeni regjistruar.',
    '1015202': 'Nuk mund të gjendet llogaria e klientit.',
    '1015203': 'Etiketa e telefonit celular është e panjohur.',
    '1015300': 'Regjistrimi në vendndodhje është i panjohur.',
    '1015301': 'test_id assigned, checkout not possible, please assign result',
    '1015400': 'Etiketa e telefonit celular është e panjohur.',
    '1015401': 'Uuid është i paparashikueshëm.',
    '1015402': 'Jeni i regjistruar tashmë.',
    '1015403': 'Etiketa e telefonit celular është e panjohur.',
    '1015500': 'Llogaria e tregtarit nuk mund të gjendet.',
    '1015501': 'Nuk mund ta gjej klientin.',
    '1015502': 'Ju tashmë jeni regjistruar.',
    '1015503': 'Etiketa e telefonit celular është e panjohur.',
    '1015600': 'Llogaria është e panjohur.',
    '1015601': 'Nuk mund ta gjej etiketën për celular ose nuk është aktive.',
    '1015700': 'Llogaria e tregtarit nuk mund të gjendet.',
    '1015701': 'Etiketa e telefonit celular është e panjohur.',
    '1015702': 'Ju tashmë jeni regjistruar.',
    '1015800': 'Nuk mund të gjendet parametri.',
    '1015900': 'Nuk mund të gjendet parametri.',
    '1016000': 'Nuk mund të gjendet vendi dhe klasifikimi nuk është i mundur.',
    '1016001': 'market not served, classification not possible',
    '1016100': 'Nuk mund të krijohej një kod unik.',
    '1016200': 'Kodi i shoqëruesit është i pavlefshëm ose i skaduar.',
    '1016201': 'Profili i personave aktual nuk është vendosur.',
    '1016203': 'Profili i personave shoqërues nuk është vendosur.',
    '1016204': 'Personi i kodit shoqërues nuk është primar.',
    '1016205': 'Personi i kodit të shoqëruesit nuk është shoqërues.',
    '1016206': 'primary person is not identified',
    '1016300': 'Personi nuk ka lidhje me kërkesën shoqëruese ose kërkesa nuk është aktive.',
    '1016301': 'Primari tashmë arriti numrin maksimal të shoqëruesve.',
    '1016302': 'primary person is not identified',
    '1016400': 'Uuid nuk është i analizueshëm.',
    '1016500': 'Klienti nuk është vendosur.',
    '1016501': 'Klienti nuk është në regjistrat, bazat, idetë, ndpaynet ose ndident.',
    '1016502': 'Identifikimi i personit të kërkesës nuk është vendosur.',
    '1016503': 'Id-ja e kërkesës nuk është vendosur.',
    '1016504': 'Lloji i lëshimit nuk është caktuar.',
    '1016505': 'Lloji i lëshimit nuk është një transaksion.',
    '1016606': 'Identifikimi i personit të kërkesës nuk është vendosur.',
    '1016707': 'Id-ja e personit nuk është vendosur.',
    '1016708': 'ID-ja e kërkesës për lëshim nuk është caktuar.',
    '1016709': 'Klienti i lëshimit nuk është caktuar.',
    '1016710':
      'Klienti i lëshimit nuk është në regjistrat, bazat, idetë, ndpaynet ose ndident.',
    '1016711': 'Veprimi i lëshimit nuk është caktuar.',
    '1016712': 'Veprimi i lëshimit nuk është në RELEASE, NO_RELEASE, BLOCK ose UNBLOCK.',
    '1016713': 'Kërkesa e lëshimit nuk mund të gjendet nga ID.',
    '1016714': 'Personi kërkues nuk lejohet të ndryshojë kërkesën.',
    '1016715': 'Kërkesa për lëshim është bllokuar dhe duhet të zhbllokohet së pari.',
    '1016716': 'Kërkesa për lëshim është lëshuar tashmë.',
    '1016717': 'release/no release can only be given once per request',
    '1016800': 'Sesioni nuk mund të gjendet.',
    '1016900': 'account not known',
    '1016901': 'account closed',
    '1016902': 'limit_value has too many digits',
    '1016903': 'limit_value is too large',
    '1016904': 'limit_type not found',
    '1016905': 'limit_type not configurable',
    '1016906': 'no system limit configured',
    '1016907': 'limit_value below system limit',
    '1016908': 'limit_value exceeds system limit',
    '1017000': 'account not known',
    '1017001': 'account closed',
    '1017100': 'account not found',
    '1017101': 'person  not found',
    '1017102': 'account already has an iban',
    '1017200': 'unkown transaction task action',
    '1017201': 'unkown depends transaction task action',
    '1017202': 'depends transaction task action does not exist',
    '1017300': 'unkown transaction task action',
    '1017301': 'unknown transaction task',
    '1017302': 'transaction task already done',
    '1017400': 'unknown field',
    '1017500': 'value does not match pattern for iban',
    '1017501': 'wrong iban checksum',
    '1017502': 'impossible iban checksum',
    '1017503': 'bban part of iban violates national bban pattern',
    '1017600': 'card not found',
    '1017601': 'unsuccessful response from bpc',
    '1017700': 'fee package not found',
    '1017701': 'transaction type already assigned to account',
    '1017702': 'no packages found',
    '1017703': 'Package may not be assigned without valid iban',
    '1017800': '2fa required',
    '1017900': 'release not found or already done',
    '1017901': 'no 2fa release',
    '1017902': '2fa only for own releases',
    '1017903': 'max number of tans exceeded',
    '1017904': 'channel not usable',
    '1018000': 'tan not found or expired',
    '1018001': 'too many retries',
    '1018002': 'operation not successfull',
    '1018100': 'account not found or inactive',
    '1018101': 'product not found or inactive',
    '1018200': 'merchant account not found',
    '1018201': 'customer account not found',
    '1018202': 'at least one item is not configured',
    '1018203': 'cannot calculate amount',
    '1018204': 'amount negative',
    '1018205': 'customer name not set, no order possible',
    '1018206': 'customer given_name not set, no order possible',
    '1018207': 'customer sex not setno order possible',
    '1018208': 'customer birth_date not set, no order possible',
    '1018209': 'nationality not set, no order possible',
    '1018210': 'ordering of multiple products, currently not possible. please do test first',
    '1018211': 'voucher not found (wrong code,expired or already used)',
    '1018212': 'voucher not valid for merchant',
    '1018213': 'voucher has different currency',
    '1018214': 'number of items not allowed',
    '1018300': 'no product found for transaction',
    '1018301': 'transaction and product given, use only one!',
    '1018302': 'product does not exist',
    '1018303': 'location does not exist or not active',
    '1018304': 'test sent to ecocare, no manual testresult allowed',
    '1018400': 'previous location not found',
    '1018500': 'unknown action',
    '1018501': 'test_id already assigned to different product order',
    '1018502': 'product order already has a test_id',
    '1018600': 'location not found',
    '1018700': 'incomplete personal data, transfer to ecolog not possible',
    '1018800': 'logitude not convertable to double',
    '1018801': 'latitude not convertable to double',
    '1018900': 'not authorized',
    '1019000': 'non semantic version number',
    '1019001': 'current app version not supported on plattform, please use website',
    '1019100': 'error in calendar_items end_time > start_time',
    '1019101': 'error in calendar_items overlapping times',
    '1019200': 'no pharmacy_id assigned',
    '1019300': 'no pharmacy_id assigned',
    '1019400': 'voucher code not found, invalid or expired',
    '1019500': 'merchant account not found',
    '1019501': 'customer account not found',
    '1019502': 'account zstripe not found',
    '1019503': 'account zecocarekunde not found',
    '1019504': 'account ecocare not found',
    '1019505': 'amount_fee_stripe negative',
    '1019506': 'amount_net negative',
    '1019507': 'amount_merchant negative',
    '1019508': 'amount_ecocare negative',
    '1019510': 'amount_payment negative',
    '1019511': 'no successfull payment found',
    '1019512': 'amount payed does not match amount ordered',
    '1019513': 'product voucher does not exist',
    '1019600': 'person_id not set',
    '1019601': 'account not set',
    '1019602': 'account not found',
    '1019603': 'amount not set',
    '1019604': 'amount at or below zero',
    '1019605': 'currency not set',
    '1019606': 'currency not found',
    '1019607': 'external account not found',
    '1019700': 'account not found',
    '1019701': 'account not active',
    '1019702': 'amount not set',
    '1019703': 'amount below min stripe amount',
    '1019704': 'currency not supported',
    '1019705': 'email address not found',
    '1019706': 'customer person not found',
    '1019800': 'unknown payment method',
    '1019900': 'too many decimal places',
    '1020000': 'no release found for given id',
    '1020100': 'must not be null',
    '1020101': 'no active person record',
    '2000000': 'Adresa e emailit është e pavlefshme.',
    '2000100': 'Adresa e emailit është e pavlefshme.',
    '2000200': 'Adresa e emailit është e pavlefshme.',
    '2000300': 'Adresa e emailit është e pavlefshme.',
    '2000301': 'Formati i lidhjes është i panjohur.',
    '2000302': 'Ndodhi një gabim gjatë marrjes së pdf.',
    '2000400': 'Ndodhi një gabim gjatë hyrjes.',
    '2000500': 'U arrit koha.',
    '2000501': 'Marrë një autorizim pin.',
    '2000502': 'Shenja nuk është aktive.',
    '2000503': 'Shenja nuk është aktive.',
    '2000504': 'Autorizimi nuk u pranua.',
    '2000505': 'Shenja nuk është aktive.',
    '2000506':
      'Nuk ka asnjë përgjigje nga procesi i aplikacionit i kthyer. Anulimi i procesit!',
    '2000600': 'Pin nuk është numerik.',
    '2000605': 'Jsbm është e paarritshme.',
    '2000606': 'Nje gabim i papritur ndodhi.',
    '2000700': 'no verification request with given id exists',
    '2000701': 'data for this id already stored',
    '2000800': 'no table id for given table name found',
    '2000801': 'no status change for given table triggered',
    '2000900': 'no verification request with given id exists',
    '2001000': 'data for this notification_id already stored',
    '2001100': 'data for this video_id already stored',
    '2001101': 'no person found for video id',
    '2001200': 'no bpc product for persons resident country',
    '2001201': 'already registered for a different product',
    '2001202': 'account not found',
    '2001203': 'unknown error saving register customer request',
    '2001300': 'person not registered at bpc',
    '2001301': 'account not found',
    '2001302': 'unknown error saving create virtual card request',
    '2001400': 'original transaction not found',
    '2001401': 'original transaction failed',
    '2001402': 'feature not implemented',
    '2001403': 'original transaction already reversed',
    '2001500': 'original transaction not found',
    '2001501': 'reversal transaction not found',
    '2001502': 'reversal and original transaction are not balanced',
    '2001600': 'unkown account task action',
    '2001601': 'unkown depends account task action',
    '2001602': 'depends account task action does not exist',
    '2001700': 'unkown account task action',
    '2001701': 'unknown account task',
    '2001702': 'account task already done',
    '2001800': 'feature not implemented',
    '2001900': 'feature not implemented',
    '2002000': 'card not found',
    '2002001': 'card agreement not found',
    '2002100': 'unsuccessful response from BPC',
    '2002200': 'card not found',
    '2002201': 'card agreement not found',
    '2002300': 'unsuccessful response from BPC',
    '2002301': 'invalid state',
    '2002400': 'card not found',
    '2002401': 'card agreement not found',
    '2002402': 'state not found',
    '2002500': 'unsuccessful response from BPC',
    '2002501': 'unsuccessful response from BPC',
    '2002600': 'unknown error saving register customer response',
    '2002601': 'unsuccessful response from BPC',
    '2002700': 'unknown error saving create virtual card response',
    '2002701': 'unsuccessful response from BPC',
    '2002800': 'primary with companions cannot be downgraded to companion',
    '2002900': 'card not found',
    '2003000': 'unsuccessful response from BPC',
    '2003100': 'operation type not supported',
    '2003200': 'product order not found',
    '2003201': 'test result already entered',
    '2003300': 'person_id create not set',
    '2003301': 'debitor account not set',
    '2003302': 'creditor account not set',
    '2003303': 'currency not set',
    '2003304': 'amount not set',
    '2003305': 'amount at or below zero',
    '2003306': 'debitor account not found',
    '2003307': 'creditor account not found',
    '2003308': 'maximal one customer account allowed',
    '2003309': 'one account must be customer account',
    '2003400': 'card not found',
    '2003500': 'unsuccessful response from BPC',
    '2003600': 'state not found',
    '2003601': 'account not found',
    '2003700': 'unsuccessful response from BPC',
    '2003800': 'account not found',
    '2003801': 'card agreement not found',
    '2003802': 'limit not found',
    '2003900': 'unsuccessful response from BPC',
    '2004000': 'duplicate transaction',
    '2004001': 'duplicate transaction',
    '2004100': 'account not found',
    '2004101': 'card agreement not found',
    '2004102': 'card color not found',
    '2004103': 'only apply once per customer',
    '2004104': 'secret answer and card color required for physical card',
    '2004200': 'account not found',
    '2004201': 'card agreement not found',
    '2004202': 'card not found',
    '2004300': 'as of date lies in the future.',
    '2004400': 'invalid or unknown email address',
    '2004401': 'invalid or unknown account',
    '2004500': 'invalid or unknown voucher code',
    '2004600': 'invalid or unknown account',
    '2004700': 'invalid voucher (wrong code, expired, or already used)',
    '2004800': 'wrong kyc screening status',
    '3000001': 'Karakteristika nuk është zbatuar.',
    '3000002': 'Llogaria nuk është aktive ose e lidhur me personin që vepron.',
    '3000100': 'Përgjigja është e panjohur.',
    '3000101': 'Një përjashtim ndodhi gjatë thirrjes në serverin e portofolit.',
    '3000102': 'Përgjigja është e panjohur.',
    '3000103': 'Një përjashtim ndodhi gjatë thirrjes në serverin e portofolit.',
    '3000200': 'Ndodhi një gabim gjatë vërtetimit të gjendjes idp:',
    '3000201': 'Përdoruesi ose fjalëkalimi është i pavlefshëm.',
    '3000202': 'Ndodhi një gabim gjatë vërtetimit të kërkesës:',
    '3000203': 'Ndodhi një gabim gjatë marrjes së emailit me arsye:',
    '3000204': 'Ndodhi një gabim gjatë vërtetimit të kërkesës:',
    '3000205': 'Numri i telefonit nuk është i vlefshëm.',
    '3000206': 'Numri i celularit nuk është i vlefshëm.',
    '3000207': 'Shenja nuk është e vlefshme ose e skaduar.',
    '3000208': 'Shenja nuk është e vlefshme ose e skaduar.',
    '3000209': 'Numri i telefonit nuk është i vlefshëm.',
    '3000210': 'Numri i celularit nuk është i vlefshëm.',
    '3000300': 'Ndodhi një gabim gjatë vërtetimit të kodit të verifikimit.',
    '3000301': 'Ju nuk jeni i autorizuar.',
    '3000302': 'Personi nuk është i lidhur me partnerin dhe autorizimi dështoi.',
    '3000303': 'Ndodhi një gabim gjatë marrjes së të dhënave të përdoruesit.',
    '3000304': 'Personi nuk është i lidhur me partnerin dhe autorizimi dështoi.',
    '3000305': 'Ndodhi një gabim gjatë marrjes së të dhënave të përdoruesit.',
    '3000306': 'current app version not supported on plattform, please use website',
    '3000400': 'Nuk mund të ruhej çelësi i hyrjes pasi mori shumë xhiro.',
    '3000401': 'wrong password',
    '3000500': 'Llogaria nuk është aktive ose e lidhur me personin që vepron.',
    '3000600': 'Ndodhi një gabim gjatë thirrjes së lidhjes.',
    '3000601': 'Ndodhi një gabim gjatë thirrjes së lidhjes.',
    '3000700': 'Formati i numrit nuk është i vlefshëm.',
    '3000701': 'Formati i numrit nuk është i vlefshëm.',
    '3000800': 'Formati i vulës kohore nuk është i vlefshëm.',
    '3000900': 'ID-ja elektronike e ID-së së strukturës rezultuese është e panjohur.',
    '3000901': 'Rezultati nuk është një objekt i vlefshëm json.',
    '3000902': 'Ndodhi një gabim gjatë thirrjes në ID elektronike.',
    '3000903': 'Id-ja elektronike nuk është konfiguruar.',
    '3001001': 'account not found',
    '3001002': 'error calling bpc',
    '3001003': 'unknown request structure',
    '3001004': 'unknown error',
    '3001005': 'bpc not configured',
    '3001100': 'not a mobile number',
    '3001101': 'error validating phone number',
    '3001102': 'connection_error validating phone number',
    '3001200': 'Hubspot Connection refused',
    '3001201': 'access token not found',
    '3001202': 'base url was not found',
    '3001203': 'base url was not found',
    '3001204': 'api key was not found',
    '3001205': 'no person_id and person_create_id found',
    '3001300': 'error_code not numeric',
    '3001301': 'error_code not numeric',
    '3001302': 'undefined function',
    '3001303': 'sql error',
    '3001304': 'connection closed',
    '3001400': 'unknown xml structure',
    '4000000': 'Nuk mund të lidhet me serverin kryesor.',
    '4000001': 'Ndodhi një gabim i panjohur.',
    '4000002': 'Nuk mund të lidhet me serverin kryesor.',
    '4000003': 'Ndodhi një gabim i panjohur.',
    '4000100': 'Nuk mund të lidhet me serverin kryesor.',
    '4000101': 'Nuk mund të lidhet me serverin kryesor.',
    '4000102': 'Ndodhi një gabim i panjohur.',
    '4000103': 'Përgjigja është e panjohur.',
    '4000104': 'Nuk mund të lidhet me serverin kryesor.',
    '4000105': 'Përgjigja është e panjohur.',
    '4000106': 'Nuk mund të lidhet me serverin kryesor.',
    '4000107': 'Përgjigja është e panjohur.',
    '9999999': 'Teksti i gabimit duhet të caktohet.',
  },
  countrys: {
    ABW: 'ABW',
    AFG: 'AFG',
    AGO: 'AGO',
    AIA: 'AIA',
    ALA: 'ALA',
    ALB: 'ALB',
    AND: 'AND',
    ANT: 'ANT',
    ARE: 'ARE',
    ARG: 'ARG',
    ARM: 'ARM',
    ASM: 'ASM',
    ATA: 'ATA',
    ATF: 'ATF',
    ATG: 'ATG',
    AUS: 'AUS',
    AUT: 'AUT',
    AZE: 'AZE',
    BDI: 'BDI',
    BEL: 'BEL',
    BEN: 'BEN',
    BES: 'BES',
    BFA: 'BFA',
    BGD: 'BGD',
    BGR: 'BGR',
    BHR: 'BHR',
    BHS: 'BHS',
    BIH: 'BIH',
    BLM: 'BLM',
    BLR: 'BLR',
    BLZ: 'BLZ',
    BMU: 'BMU',
    BOL: 'BOL',
    BRA: 'BRA',
    BRB: 'BRB',
    BRN: 'BRN',
    BTN: 'BTN',
    BVT: 'BVT',
    BWA: 'BWA',
    CAF: 'CAF',
    CAN: 'CAN',
    CCK: 'CCK',
    CHE: 'CHE',
    CHL: 'CHL',
    CHN: 'CHN',
    CIV: 'CIV',
    CMR: 'CMR',
    COD: 'COD',
    COG: 'COG',
    COK: 'COK',
    COL: 'COL',
    COM: 'COM',
    CPV: 'CPV',
    CRI: 'CRI',
    CUB: 'CUB',
    CUW: 'CUW',
    CXR: 'CXR',
    CYM: 'CYM',
    CYP: 'CYP',
    CZE: 'CZE',
    DEU: 'DEU',
    DJI: 'DJI',
    DMA: 'DMA',
    DNK: 'DNK',
    DOM: 'DOM',
    DZA: 'DZA',
    ECU: 'ECU',
    EGY: 'EGY',
    ERI: 'ERI',
    ESH: 'ESH',
    ESP: 'ESP',
    EST: 'EST',
    ETH: 'ETH',
    EUE: 'EUE',
    FIN: 'FIN',
    FJI: 'FJI',
    FLK: 'FLK',
    FRA: 'FRA',
    FRO: 'FRO',
    FSM: 'FSM',
    GAB: 'GAB',
    GBD: 'GBD',
    GBN: 'GBN',
    GBO: 'GBO',
    GBP: 'GBP',
    GBR: 'GBR',
    GBS: 'GBS',
    GEO: 'GEO',
    GGY: 'GGY',
    GHA: 'GHA',
    GIB: 'GIB',
    GIN: 'GIN',
    GLP: 'GLP',
    GMB: 'GMB',
    GNB: 'GNB',
    GNQ: 'GNQ',
    GRC: 'GRC',
    GRD: 'GRD',
    GRL: 'GRL',
    GTM: 'GTM',
    GUF: 'GUF',
    GUM: 'GUM',
    GUY: 'GUY',
    HKG: 'HKG',
    HMD: 'HMD',
    HND: 'HND',
    HRV: 'HRV',
    HTI: 'HTI',
    HUN: 'HUN',
    IDN: 'IDN',
    IMN: 'IMN',
    IND: 'IND',
    IOT: 'IOT',
    IRL: 'IRL',
    IRN: 'IRN',
    IRQ: 'IRQ',
    ISL: 'ISL',
    ISR: 'ISR',
    ITA: 'ITA',
    JAM: 'JAM',
    JEY: 'JEY',
    JOR: 'JOR',
    JPN: 'JPN',
    KAZ: 'KAZ',
    KEN: 'KEN',
    KGZ: 'KGZ',
    KHM: 'KHM',
    KIR: 'KIR',
    KNA: 'KNA',
    KOR: 'KOR',
    KWT: 'KWT',
    LAO: 'LAO',
    LBN: 'LBN',
    LBR: 'LBR',
    LBY: 'LBY',
    LCA: 'LCA',
    LIE: 'LIE',
    LKA: 'LKA',
    LSO: 'LSO',
    LTU: 'LTU',
    LUX: 'LUX',
    LVA: 'LVA',
    MAC: 'MAC',
    MAF: 'MAF',
    MAR: 'MAR',
    MCO: 'MCO',
    MDA: 'MDA',
    MDG: 'MDG',
    MDV: 'MDV',
    MEX: 'MEX',
    MHL: 'MHL',
    MKD: 'MKD',
    MLI: 'MLI',
    MLT: 'MLT',
    MMR: 'MMR',
    MNE: 'MNE',
    MNG: 'MNG',
    MNP: 'MNP',
    MOZ: 'MOZ',
    MRT: 'MRT',
    MSR: 'MSR',
    MTQ: 'MTQ',
    MUS: 'MUS',
    MWI: 'MWI',
    MYS: 'MYS',
    MYT: 'MYT',
    NAM: 'NAM',
    NCL: 'NCL',
    NER: 'NER',
    NFK: 'NFK',
    NGA: 'NGA',
    NIC: 'NIC',
    NIU: 'NIU',
    NLD: 'NLD',
    NOR: 'NOR',
    NPL: 'NPL',
    NRU: 'NRU',
    NTZ: 'NTZ',
    NZL: 'NZL',
    OMN: 'OMN',
    PAK: 'PAK',
    PAN: 'PAN',
    PCN: 'PCN',
    PER: 'PER',
    PHL: 'PHL',
    PLW: 'PLW',
    PNG: 'PNG',
    POL: 'POL',
    PRI: 'PRI',
    PRK: 'PRK',
    PRT: 'PRT',
    PRY: 'PRY',
    PSE: 'PSE',
    PYF: 'PYF',
    QAT: 'QAT',
    REU: 'REU',
    RKS: 'RKS',
    ROU: 'ROU',
    RUS: 'RUS',
    RWA: 'RWA',
    SAU: 'SAU',
    SDN: 'SDN',
    SEN: 'SEN',
    SGP: 'SGP',
    SGS: 'SGS',
    SHN: 'SHN',
    SJM: 'SJM',
    SLB: 'SLB',
    SLE: 'SLE',
    SLV: 'SLV',
    SMR: 'SMR',
    SOM: 'SOM',
    SPM: 'SPM',
    SRB: 'SRB',
    SSD: 'SSD',
    STP: 'STP',
    SUR: 'SUR',
    SVK: 'SVK',
    SVN: 'SVN',
    SWE: 'SWE',
    SWZ: 'SWZ',
    SXM: 'SXM',
    SYC: 'SYC',
    SYR: 'SYR',
    TCA: 'TCA',
    TCD: 'TCD',
    TGO: 'TGO',
    THA: 'THA',
    TJK: 'TJK',
    TKL: 'TKL',
    TKM: 'TKM',
    TLS: 'TLS',
    TON: 'TON',
    TTO: 'TTO',
    TUN: 'TUN',
    TUR: 'TUR',
    TUV: 'TUV',
    TWN: 'TWN',
    TZA: 'TZA',
    UGA: 'UGA',
    UKR: 'UKR',
    UMI: 'UMI',
    UNA: 'UNA',
    UNK: 'UNK',
    UNO: 'UNO',
    URY: 'URY',
    USA: 'USA',
    UZB: 'UZB',
    VAT: 'VAT',
    VCT: 'VCT',
    VEN: 'VEN',
    VGB: 'VGB',
    VIR: 'VIR',
    VNM: 'VNM',
    VUT: 'VUT',
    WLF: 'WLF',
    WSM: 'WSM',
    XBA: 'XBA',
    XCC: 'XCC',
    XCE: 'XCE',
    XCO: 'XCO',
    XDC: 'XDC',
    XEC: 'XEC',
    XKX: 'XKX',
    XIM: 'XIM',
    XOM: 'XOM',
    XPO: 'XPO',
    XXA: 'XXA',
    XXB: 'XXB',
    XXC: 'XXC',
    XXX: 'XXX',
    YEM: 'YEM',
    ZAF: 'ZAF',
    ZMB: 'ZMB',
    ZWE: 'ZWE',
  },
  backoffice: {
    accountType: 'Lloji i Llogarisë',
    accountTypeAll: 'të gjithë',
    searchHelp: {
      title: 'Kërko ndihmë',
      a: 'Default: Futni tekst për të kërkuar për memo',
      b:
        'Kërko fusha të veçanta: Shkruani emrin fushë dhe kërko vlera të ndara me një zorrë e trashë, në qoftë se ju doni të kërkoni më shumë fusha (dhe claused), shtoni një presje në mes të çdo palë kyç/vlerës. E.g: "account_number: 1234, memo: abc "',
      c: 'Çelësat e vlefshme e kërkimit: account_number, IBAN, memorandum, emri, custom_name',
    },
    results: 'rezultatet',
    approvals: {
      title: 'Miratimet',
      approve: 'Miratimi',
      cancel: 'Anulo',
      reject: 'ToDo',
      status: {
        N: 'I Ri',
        W: 'Ne Pritje',
        C: 'Anulohet',
        R: 'Lëshuar',
      },
      header: {
        approval: 'Aprovoj',
        created: 'Krijuar',
        requestesBy: 'Kërkuar Nga',
        requestedByMail: 'Kërkuesit E-Mail',
        requieredReleases: 'Miratimet E Kërkuara',
        releaseCount: 'Numri I Miratimit',
        status: 'Gjendja',
        type: 'Lloji',
        name: 'ToDo',
        lastModified: 'E Modifikuara E Fundit',
        client: 'Klient',
        block_reason: 'ToDo',
        blocked: 'ToDo',
      },
      details: {
        name: 'ToDo',
        city: 'ToDo',
        postcode: 'ToDo',
        country: 'ToDO',
        street: 'ToDo',
        email: 'ToDo',
        employer_country: 'ToDo',
        employer_city: 'ToDo ',
        employer_postcode: 'ToDo ',
        employer_street: 'ToDo ',
        employer_name: 'ToDo',
        source_of_income: 'ToDo  ',
        incomme: 'ToDo ',
        document: 'ToDo',
        address_id: 'ToDo',
        AddressApproved: {
          approved: 'ToDo',
        },
        account_numbers: 'ToDo',
        additional: 'ToDo',
        openPerson: 'ToDo',
        approver: 'ToDo',
        error: 'ToDo',
        titleTrans: 'Informacioni I Transaksionit',
        CustomerCreditTransfer: {
          creditorAccount: 'ToDo',
          creditorName: 'ToDo',
          debtorAccount: 'ToDo',
          instructedAmount: {
            amount: 'ToDo',
            currency: 'ToDo',
          },
          remittanceInformationUnstructured: 'ToDo',
          save_new_favorite: 'ToDo',
        },
        SepaCreditTransfer: {
          creditorAccount: {
            accountNumber: 'ToDo',
            iban: 'ToDo',
          },
          debtorAccount: {
            accountNumber: 'ToDo',
            iban: 'ToDo',
          },
          instructedAmount: {
            amount: 'ToDo',
            currency: 'ToDo',
          },
          remittanceInformationUnstructured: 'ToDo',
          creditorName: 'ToDo',
        },
        ta_id: 'Ta-ID',
        transaction_id: 'ToDo',
        NewEmoneyPersonWithUsertype: {
          address: {
            address_type: 'ToDo',
            building_identifier: 'ToDo',
            city: 'ToDo',
            country: 'ToDo',
            country_subentity: 'ToDo',
            district_name: 'ToDo',
            floor_identifier: 'ToDo',
            post_box: 'ToDo',
            post_code: 'ToDo',
            street: 'ToDo',
            suite_identifier: 'ToDo',
          },
          birth_city: 'ToDo',
          birth_date: 'ToDo',
          email_address: 'ToDo',
          given_name: 'ToDo',
          language: 'ToDo',
          name: 'ToDo',
          product: 'ToDo',
          sex: 'ToDo',
          user_class: 'ToDo',
          entity_id: 'ToDo',
        },
        person_id: 'ToDo',
        partner_id: 'ToDo',
        reason: 'ToDo',
        comment: 'ToDo',
        PersonUpdateRequest: {
          birth_city: 'ToDo',
          birth_date: 'ToDo',
          email: 'ToDo',
          given_name: 'ToDo',
          language: 'ToDo',
          name: 'ToDo',
          product: 'ToDo',
          sex: 'ToDo',
          user_class: 'ToDo',
        },
      },
    },
    search: {
      title: 'Kërko Llogaria',
      searchTrans: 'ToDo',
      input: {
        account_number: {
          label: 'Numri i llogarisë',
          placeholder: 'Futni numrin e llogarisë',
        },
        accountType: 'Lloji i Llogarisë',
        accountTypeAll: 'Të gjitha llojet e llogarisë',
        iban: {
          label: 'IBAN',
          placeholder: 'Shkruani IBAN',
        },
        memo: {
          label: 'memorandum',
          placeholder: 'Shkruani Memo',
        },
        name: {
          label: 'Emri i llogarise',
          placeholder: 'Futni emrin e llogarisë',
        },
        custom_name: {
          label: 'Custom Emri i llogarisë',
          placeholder: 'Futni emrin e llogarisë porosi',
        },
        primanota: {
          label: 'Transaction-ID',
          placeholder: 'Vendosni Transaction-ID',
        },
      },
    },
  },
  button: {
    addNewCustomer: 'Shto klient të ri',
    addNewMember: 'Shto anëtar të ri',
    cancel: 'Anuloj',
    changePassword: 'Ndrysho fjalekalimin',
    close: 'Afër',
    create: 'Krijoj',
    gotoDashboard: 'Shko në pult',
    next: 'Tjetër',
    ok: 'Ne rregull',
    results: 'Rezultatet',
    save: 'Ruaj',
    search: 'Kërko',
    send: 'Dërgoni',
    sendInformation: 'Dërgo informacioni',
    signin: 'Hyni',
    today: 'Sot',
    button: 'njohja fillimi',
    valid: 'ToDo',
    notValid: 'ToDo',
    void: 'Void',
    positive: 'Positiv',
    negative: 'Negativ',
  },
  confirmation: {
    transactionSuccessful: {
      title: 'transaksion të suksesshme',
      message: 'Paratë u transferuar me sukses për të marrësit.',
    },
  },
  contextMenu: {
    copy: 'ToDo',
    paste: 'ToDo',
    edit: 'ToDpo',
    postingJournal: 'ToDo',
    manageAddress: 'ToDo',
    transactionDetails: 'ToDo',
    cancel: 'ToDo',
    revert: 'ToDo',
    startQueue: 'ToDo',
    manageEmail: 'ToDo',
    managePhone: 'ToDo',
    openCustomer: 'ToDo',
    moneyStatement: 'ToDo',
    openAccount: 'ToDo',
    refund: 'ToDo',
    openLimits: 'ToDo',
    invoice: 'ToDo',
    storno: 'ToDo',
  },
  customers: {
    openapprovals: {
      title: 'ToDo',
      name: 'ToDo',
      given_name: 'ToDo',
      datetime_due: 'ToDo',
      email_address: 'ToDo',
    },
    juniorAccount: {
      title: 'ToDo',
      turns18: 'todo',
    },
    addressRequirement: {
      setup: 'ToDo',
      requirement: 'ToDo',
    },
    opendocuments: 'ToDo',
    limits: {
      types: {
        5: 'ToDo',
        6: 'ToDo',
        1: 'ToDo',
        8: 'ToDo',
        2: 'ToDo',
        4: 'ToDo',
        7: 'ToDo',
        9: 'ToDo',
        10: 'ToDo',
        11: 'ToDo',
        12: 'ToDo',
        13: 'ToDo',
        14: 'ToDo',
        15: 'ToDo',
        16: 'ToDo',
        17: 'ToDo',
        18: 'ToDo',
        19: 'ToDo',
        20: 'ToDo',
        21: 'ToDo',
        22: 'ToDo',
      },
      transactionLimit: 'ToDo',
      transactionLimitOut: 'ToDo',
      widthdrawLimit: 'ToDo',
      uploadLimit: 'ToDo',
      widthdrawDescription: 'ToDo',
      uploadDesciption: 'ToDo',
      transDescription: 'ToDo',
      usage: 'ToDo',
      swap: 'ToDo',
      applied: 'ToDo',
      check: 'ToDo',
      write: 'ToDo',
      balance: 'ToDo',
    },
    products: {
      title: 'ToDo',
      productid: 'ToDo',
      types: 'ToDo',
      producer: 'ToDo',
      signature: 'ToDo',
      paymentMethods: 'ToDo',
      paypal: 'Paypal',
      stripe: 'Stripe',
      at_merchant: 'ToDo',
      stamp: 'ToDo',
      id: 'ToDo',
      signatureStamp: 'ToDo',
      voucherAmount: 'ToDo',
      name: 'ToDo',
      description: 'ToDo',
      claim: 'ToDo',
      default_merchant_provision: 'ToDo',
      price: 'ToDo',
      reveive_test: 'ToDo ',
      noweda: 'ToDo',
      typeOfTest: 'ToDo',
      fast_track: 'ToDo',
      image: 'ToDo',
      imageSrc: 'ToDo',
      vat: 'ToDo',
      package: 'ToDo',
      group: 'ToDo',
      endTime: 'ToDo',
      time: {
        hour: 'ToDo',
        minute: 'ToDo',
        second: 'ToDo',
      },
    },
    title: {
      title: 'Titull',
      mr: 'Zoti',
      mrs: 'Zonja',
      undefined: 'Paspecifikuara',
      notSet: 'Zgjidhni Titullin',
    },
    sex: {
      mr: 'Mashkull',
      mrs: 'Femër',
      undefined: 'E Paspecifikuar',
      notSet: '',
    },
    emails: {
      primary: 'ToDo',
      secondary: 'ToDo',
      primaryMails: 'ToDo',
      secondaryMails: 'ToDo ',
    },
    phoneNumbers: {
      title: 'ToDo',
      primary: 'ToDo',
      secondary: 'ToDo',
    },
    feesTab: {
      name: 'ToDo',
      price: 'ToDo',
      used: 'ToDo',
      empty: 'ToDo',
    },
    cardTab: {
      type: 'ToDo',
      types: {
        credit_card: 'ToDo',
        debit_card: 'ToDo',
      },
      state: 'ToDo',
      states: {
        A: 'ToDo',
        P: 'ToDo',
        D: 'ToDo',
        O: 'ToDo',
        B: 'ToDo',
      },
      id: 'ToDo',
      provider: 'ToDo',
      custNumBpc: 'ToDo',
      bpsState: 'ToDo',
      accNumBpc: 'ToDo',
      cardId: 'ToDo',
      color: 'ToDo',
      expiry: 'ToDo',
      name: 'ToDo',
      card: 'ToDo',
      customerCard: 'ToDo',
      barCode: 'ToDo',
      bpcCardId: 'ToDo',
      virtOrPhys: 'ToDo',
      virtOrPhyType: {
        P: 'ToDo',
        V: 'ToDo',
      },
    },
    close: {
      reason: 'Todo',
      ClientRequest: 'ToDo',
      AMLTechnicalError: 'ToDo',
      Other3rdPartyRequest: 'ToDo',
      ImmediateClosure: 'ToDo',
      additional: {
        label: 'ToDo',
        placeholder: 'ToDO',
      },
      block: {
        label: 'ToDo',
        placeholder: 'ToDo',
      },
    },
    product: 'Produkt',
    KYC: 'KYC',
    newImage: 'ToDo',
    resetKyc: 'ToDo',
    selectImage: 'ToDo',
    manageAddress: 'Menaxho Adresat',
    accountNumber: 'Numri i llogarisë',
    clientProfile: 'ToDo',
    accountType: 'ToDo',
    accountOpeningDate: 'Llogaria data e hapjes',
    accountclosingDate: 'ToDo',
    preferences: 'Preferencat',
    expiryDate: 'Data E Skadimit',
    newAddress: 'Adresa E Re',
    phone: 'Numri I Telefonit',
    fees: 'Gebühren',
    barcode: 'Test Barcode',
    customerNumber: 'ToDo',
    user_id: 'ToDo',
    junior: 'ToDo',
    is_staff: 'ToDo',
    validPassport: 'ToDo',
    currentBalance: 'TodO',
    parent_person_id: 'Parent customer id',
    parent_name: 'Parent name',
    parent_given_name: 'Parent given name',
    user_class: 'User profile',
    availableBalance: 'ToDo',
    doc_back: 'ToDo',
    portrait: 'ToDo',
    doc_front: 'ToDo',
    date_of_ident: 'ToDo',
    personal_number: 'ToDo',
    enteredNationality: 'ToDo',
    correctedNationality: 'ToDo',
    issuing_state_code: 'ToDo',
    document_class_code: 'ToDo',
    vouchers: 'ToDo',
    voucherTypes: {
      onceOnly: 'ToDo',
      unlimited: 'ToDo',
    },
    advancedKyc: {
      advancedKyc: 'ToDo',
      advancedKycHeader: 'ToDo',
      advancedKycWarning: 'ToDo',
      create: 'ToDo',
      income: {
        EarnedIncome: 'ToDo ',
        BusinessIncome: 'ToDo ',
        InterestIncome: 'ToDo ',
        DividendIncome: 'ToDo ',
        RentalIncome: 'ToDo ',
        CapitalGains: 'ToDo ',
        Royalties: 'ToDo',
      },
      avgIncome: {
        below_1000: 'ToDo 1000',
        between_1000_2000: '1000 - 2000',
        between_2000_5000: '2000 - 5000',
        between_5000_10000: '5000 - 10000',
        above_10000: 'ToDO 10000',
        below_500: 'ToDo 500',
        between_500_1000: '500 - 1000',
        above_5000: 'ToDo 5000',
      },
      states: {
        D: 'ToDo',
        A: 'ToDo',
        C: 'ToDo',
        P: 'ToDo',
        O: 'ToDo',
      },
      input: {
        document: 'ToDo',
        state: 'ToDo',
        datetime_create: 'ToDo ',
        datetime_invalidate: 'ToDo ',
        employer: {
          label: 'ToDo',
          placeholder: 'ToDo ',
        },
        employer_street: {
          label: 'ToDo ',
          placeholder: 'ToDo  ',
        },
        employer_country: {
          label: 'ToDo ',
          placeholder: 'ToDo  ',
        },
        employer_post_code: {
          label: 'ToDo  ',
          placeholder: 'ToDo   ',
        },
        employer_city: {
          label: 'ToDo ',
          placeholder: 'ToDo  ',
        },
        source_of_income: {
          label: 'ToDo  ',
          placeholder: 'ToDo   ',
        },
        monthly_income: {
          label: 'ToDo ',
          placeholder: 'ToDo ',
        },
        employer_building_identifier: {
          label: 'ToDo',
          placeholder: 'ToDoer',
        },
      },
    },
    customersIdent: 'ToDo',
    noweda: 'Pharmacy-ID',
    pharmadirekt: 'Pharmadirekt-ID',
    customersAddress: 'Adresat Dytësore',
    differing_comission: 'ToDO',
    languageSettings: 'Cilësimet E Gjuhës',
    notificationSettings: 'Cilësimet E Njoftimit',
    activateAll: 'Vendosni të gjitha',
    deactiveAll: 'Actaktivizoni të gjithë',
    balance: 'ekuilibër',
    birthdate: 'Data e lindjes',
    cards: 'ToDo',
    identification: 'Te Dhena Personale',
    blocked: 'I Bllokuar',
    blockedSince: 'Bllokuar që nga viti',
    buildingIdentifier: 'Identifikuesi Building',
    city: 'qytet',
    cityOfBirth: 'Qyteti i lindjes',
    closeAccount: 'Mbylle llogari',
    closeAccountNo: 'Mbylle llogari nr.',
    closed: 'Mbyllur',
    inClosure: 'ToDo',
    closedSince: 'mbyllur që nga',
    isBlocked: 'ToDo',
    currency: 'monedhë',
    downloadQR: 'ToDO',
    reset: 'ToDO',
    customerDetails: 'Kontaktoni',
    documentType: 'Lloji i Dokumentit',
    noDocuments: 'Nuk U Gjet Asnjë Dokument',
    documentNumber: 'Numri i Dokumentit',
    personalNumber: 'Numri Personal',
    validationDate: 'Data e Vlefshmërisë',
    issuingCountry: 'Issuing country ToDo',
    issuingAuth: 'Issuing authority ToDo',
    dateOfIdentification: 'Data e procesit të identifikimit',
    date: 'data',
    email: 'E-Mail',
    givenName: 'emri i dhënë',
    iban: 'IBAN',
    localIban: 'ToDo',
    interestRate: 'Norma e Interesit',
    lastTransaction: 'transaksion i fundit',
    name: 'emër',
    results: 'rezultatet',
    street: 'rrugë',
    sure: 'A je i sigurt?',
    validUntil: 'i vlefshëm deri',
    zip: 'Kodi Postal',
    district_name: 'Rrethi',
    floor_identifier: 'Identifikuesi I Dyshemesë',
    post_box: 'Kuti Postare',
    suite_identifier: 'Identifikoni Suite',
    country_subentity: 'Subentiteti I Vendit',
    accountActivity: 'Njoftimet E Postës',
    language: 'Gjuhe',
    sendMoney: 'dergo Para',
    receiveMoney: 'merrni Para',
    messageFriends: 'Njoftime pushimi',
    requestMoney: 'kërkoni Para',
    takeOver: 'take over',
    splitMoney: 'ndarja e Parave',
    details: {
      approval: 'ToDo',
      connections: 'ToDo',
      release: 'Lirimet',
      externalAccounts: {
        externalAccounts: 'external Accounts',
        expiredDate: 'Skaduar karta',
        invalidCardNumber: 'numri i pavlefshëm i kartës',
        invalidCode: 'Kod i sigurisë së pavlefshme',
        invalidExpiryDate: 'Data e pavlefshme e skadencës (MM/VV)',
        missingCardNumber: 'Numri i kartës që mungon',
        missingExpiryDate: 'Mungon data e skadencës',
        missingName: 'Mbajtësi i kartës që mungon',
        unsupportedBrand: 'Ofruesi i kartelave të pa mbështetura',
        fetchError: 'Gabim gjatë mbledhjes së Llogarive të jashtme',
        input: {
          cardNumber: {
            label: 'Numri i kartës së debitit',
            placeholder: 'Vendosni numrin e kartës së debitit',
          },
          amount: {
            label: 'Todo',
            placeholder: 'Todo',
          },
          receiver: {
            label: 'ToDo',
            placeholder: 'ToDo',
          },
          quantitiy: {
            label: 'ToDo',
            placeholder: 'ToDo',
          },
          validTo: {
            label: 'ToDo',
            placeholder: 'ToDo',
          },
          testCenter: {
            label: 'ToDo',
            placeholder: 'ToDo',
          },
          type: {
            label: 'ToDo',
            placeholder: 'ToDo',
          },
          expiry: {
            label: 'I vlefshëm deri',
            placeholder: 'MM/VV',
          },
          name: {
            label: 'Mbajtësi i kartës',
            placeholder: 'Vendosni emrin e mbajtësit të kartës',
          },
          securityCode: {
            label: 'Kodi i Sigurisë',
            placeholder: 'Vendosni kodin e sigurisë',
          },
          bank: {
            label: 'Banka',
            placeholder: 'Vendosni emrin e Bankës',
          },
          acceptance: {
            label: 'Pranim',
            placeholder: 'hyni në gjendje pranimi',
          },
        },
      },
      companions: {
        C: 'toDo',
        P: 'toDo',
        name: 'toDo',
        email: 'toDo',
        phone: 'toDo',
        type: 'toDo',
        relation: 'ToDo',
        linkedPersons: 'ToDo',
        familty_relation: 'ToDo',
        first_degree_relative: 'ToDo',
        second_degree_relative: 'ToDo',
        third_degree_relative: 'ToDo',
        fourth_degree_relative: 'ToDo',
        dependant_relationship: 'ToDo',
        other_official_relationship: 'ToDo',
        other_person: {
          label: 'ToDo',
          placeholder: 'ToDon',
        },
        documents: 'ToDo',
      },
      merchant: {
        merchant: 'ToDo',
        merchantStatus: 'ToDo',
        calendarData: 'ToDo',
        openCalendarData: 'ToDo',
        uploadDocument: 'Ngarko dokumentin',
        product_types: 'ToDo',
        upload: 'ToDo',
        generateAccessKey: 'Gjeneroni tastin e aksesit',
        accessKeyId: 'ID aksesit',
        status: 'gjendje',
        created: 'krijuar',
        makeInactiv: 'bëj joaktiv',
        noneTimezone: 'ToDo ',
        deactivated: 'çaktivizuar',
        select: 'Zgjidhni skedarin..',
        current: 'Dokumentet aktuale:',
        profile: 'toDo',
        products: 'ToDo',
        description: 'ToDo',
        timezone: 'ToDo',
        claim: 'ToDo',
        image: 'ToDo',
        calendar: 'ToDo',
        add: 'ToDon',
        segment: 'ToDo',
        webaddress: 'ToDo',
        fiskaly: {
          vat: {
            label: 'ToDo',
            placeHolder: 'ToDo',
          },
          econ: {
            label: 'ToDo',
            placeHolder: 'ToDo',
          },
          title: 'ToDo',
          orgaId: 'Organisations-ID',
          tseID: 'TSS-ID',
          apiKey: 'Api-Key',
          taxNr: {
            label: 'ToDo',
            placeHolder: 'ToDo',
          },
        },
        segments: {
          Restaurant: 'ToDo',
          Military: 'ToDo',
          Canteen: 'ToDo',
          Testcenter: 'ToDo',
          Club: 'ToDo',
          none: 'ToDo',
        },
        calendarItems: {
          0: 'ToDo',
          1: 'ToDo',
          2: 'ToDo',
          3: 'ToDo',
          4: 'ToDo',
          5: 'ToDo',
          6: 'ToDo',
          timeslotLength: 'ToDo',
          timeslotCapacity: 'ToDo',
          exclude: 'ToDo',
          start: 'ToDo ',
          end: 'ToDo ',
          dateRangeDays: 'ToDo',
          excluded: 'ToDo',
          exceptions: 'ToDo',
        },
      },
      Voucher: {
        missing: 'ToDo',
        sendAt: 'ToDo',
        amount: 'ToDo',
        createdBy: 'ToDo',
        email: 'ToDo',
        quantity: 'ToDo',
        download: 'ToDo',
        createdAt: 'ToDo',
        testcenter: 'ToDo',
        validTo: 'ToDo',
        code: 'ToDo',
        product: 'Produkt',
        createVoucher: 'ToDo',
        boundProduct: 'ToDO',
        openVouchers: 'ToDo',
        status: 'Status',
        statusTypes: {
          A: 'ToDo',
          U: 'ToDo',
          D: 'ToDo',
        },
      },
      KYC: {
        state: {
          C: 'ToDo',
          B: 'ToDo',
          V: 'Verifikuar',
          R: 'Në Pritje',
          I: 'Identifikuar',
        },
        requestKYC: {
          header: 'ToDo',
          message: 'ToDo',
        },
        password: {
          header: 'ToDo',
          message: 'ToDo',
        },
        systemStates: {
          eid_session_initiated: 'eid seanca e iniciuar',
          eid_session_completed: 'eid seanca e përfunduar',
          eid_data_delivered: 'eid të dhënat e dorëzuara',
          eid_response_received: 'eid përgjigja e marrë',
          assec_data_delivered: 'dorëzohen të dhënat e asseco',
          asseco_response_received: 'përgjigja e asseco e marrë',
          waiting_for_address_check: 'ToDo',
          address_confirmed: 'ToDo',
          eid_response_received_with_status_ACCEPTED: 'Todo',
          eid_response_received_with_status_REJECTED: 'ToDo',
          kyc_completed: 'kyc përfunduar',
          identified: 'identifikuar',
          password_reset_triggered: 'ToDo',
          password_reset: 'ToDo',
          core_data_changed: 'ToDo',
          core_data_added: 'ToDo',
          A: 'ToDo',
          W: 'ToDo ',
          N: 'ToDo ',
          I: 'ToDo ',
          R: 'ToDo ',
        },
        profile: {
          primary: 'Fillore',
          companion: 'Shoqërues',
        },
        status: 'ToDo',
        datetimeCreate: 'ToDo',
        pep: 'PEP',
        fatca: 'FATCA',
        us_person: 'Personi I SHBA',
        own_behalf: 'Vepron Në Emër Të Vet',
        resident: 'Banor',
        systemState: 'Shteti I Sistemit',
        customerState: 'Shteti I Konsumatorëve',
        age_check: 'Mosha E Kontrolluar',
        last_updated: 'Përditësuar Së Fundmi',
        client: 'Klient',
        eu_member: 'Anëtar i BE-së',
        eid: {
          requests: 'ToDo',
          faileds: 'ToDo',
          webhooks: 'ToDO',
          request: {
            url: 'ToDo',
            datetime_create: 'ToDo',
            session_id: 'ToDo',
            datetime_video_id: 'ToDo',
            video_id: 'ToDo',
          },
          failed: {
            datetime_create: 'ToDo',
            session_id: 'ToDo',
            failure_reason: 'ToDo',
            failure_category: 'ToDo',
          },
          webhook: {
            datetime_create: 'ToDo',
            notification_id: 'ToDo',
            verification_id: 'ToDo',
            video_id: 'ToDo',
            verification_request_id: 'ToDo',
          },
        },
        personHistory: {
          title: 'ToDo',
          personCore: 'ToDo',
          address: 'ToDo',
          email: 'ToDo',
          phone: 'ToDo',
          person: {
            name: 'ToDo',
            given_name: 'ToDo',
            sex: 'ToDo',
            birth_date: 'ToDo',
            birth_city: 'ToDo',
            company_name: 'ToDo',
            nationality: 'ToDo',
            fatca: 'ToDo',
            is_merchant: 'ToDo',
            own_behalf: 'ToDo',
            us_person: 'ToDo',
            state: 'ToDo',
            resident: 'ToDo',
            pep: 'ToDo',
            valid_from: 'ToDo ',
            invalid_from: 'ToDo ',
            person_update: 'ToDo',
          },
          addresses: {
            street: 'ToDo',
            building_identifier: 'ToDo',
            city: 'ToDo',
            country: 'ToDo',
            suite_identifier: 'ToDo',
            floor_identifier: 'ToDo',
            district_name: 'ToDo',
            post_box: 'ToDo',
            post_code: 'ToDo',
          },
          emails: {
            email_address: 'ToDo',
            datetime_confirmed: 'ToDo',
          },
          phones: {
            phone_number: 'ToDo',
          },
        },
      },

      settings: {
        fetchError: 'Gabim gjatë tërheqjes së cilësimeve',
      },

      phone: {
        list: {
          error: 'ToDo',
          success: 'ToDo',
        },
        delete: {
          error: 'ToDo',
          success: 'ToDo',
        },
      },
    },

    error: {
      link: 'Për përpunim klient ekzistues, ju lutem klikoni këtu',
      subtitle: 'Konsumatori tashmë ekziston',
      title: 'gabim',
    },
    newCustomer: {
      input: {
        address: {
          label: 'Adresë',
          placeholder: 'Shkruani adresën',
        },
        district_name: {
          label: 'Emri I Rrethit',
          placeholder: 'Enter District Name',
        },
        floor_identifier: {
          label: 'Identifikuesi I Dyshemesë',
          placeholder: 'Vendosni Identifikuesin E Dyshemesë',
        },
        suite_identifier: {
          label: 'Identifikoni Suitën',
          placeholder: 'Vendosni Identifikuesin E Kompletit',
        },
        country_subentity: {
          label: 'Subentiteti I Vendit',
          placeholder: 'Shkruani Subentitetin E Vendit',
        },
        post_box: {
          label: 'Kuti Postare',
          placeholder: 'Hyni Në Kutinë Postare',
        },
        number: {
          label: 'ID e Ndërtimit',
          placeholder: 'Vendosni ID-në e Ndërtimit',
        },
        birthdate: {
          label: 'Data e lindjes',
          placeholder: 'Shkruani ditëlindjen',
        },
        birthplace: {
          label: 'Data e lindjes',
          placeholder: 'Zgjidhni ditëlindjen',
        },
        city: {
          label: 'qytet',
          placeholder: 'Shkruani qytetin',
        },
        country: {
          label: 'Vend',
          placeholder: 'Hyni në vend',
        },
        type: {
          label: 'Lloji i adresës',
          placeholder: 'Vendosni llojin e adresës',
        },
        companyname: {
          label: 'Emri i Kompanise',
          placeholder: 'Emri i kompanisë suaj',
        },
        merchantDescription: {
          label: 'ToDO',
          placeholder: 'ToDO',
        },
        email: {
          label: 'E-Mail',
          placeholder: 'shkruani e-mailin',
        },
        firstname: {
          label: 'Emri',
          placeholder: 'Futni emrin e parë',
        },
        gender: {
          label: 'gjini',
          placeholder: 'Shkruani gjininë',
        },
        lastname: {
          label: 'Mbiemri',
          placeholder: 'Shkruani mbiemrin',
        },
        language: {
          label: 'Gjuhe',
          placeholder: 'Shkruaj gjuhën',
        },
        nationality: {
          label: 'kombësi',
          placeholder: 'Shkruani kombësinë',
        },
        phone: {
          label: 'telefon',
          placeholder: 'Futni numrin e telefonit',
        },
        postCode: {
          label: 'Kodi Postar',
          placeholder: 'Shkruani Kodi Postar',
        },
        state: {
          label: 'shtet',
          placeholder: 'Shkruani shtetin',
        },
        latitude: {
          label: 'ToDo',
          placeholder: 'ToDo',
        },
        longitude: {
          label: 'ToDo',
          placeholder: 'ToDo',
        },
      },
      title: 'klient i ri',
    },
    search: {
      input: {
        accountNumber: {
          label: 'Numri i llogarisë',
          placeholder: 'Vendosni numrin e llogarisë',
        },
        address: {
          label: 'ToDo',
          placeholder: 'Vendosni ToDo',
        },
        external: {
          label: 'ID E Jashtme',
          placeholder: 'Vendosni ID-Në E Jashtme',
        },
        birthdate: {
          label: 'Data e lindjes',
          placeholder: 'Vendosni datën e lindjes',
        },
        street: {
          label: 'Rrugë',
          placeholder: 'Vendosni rrugën',
        },
        buildId: {
          label: 'Identifikuesi',
          placeholder: 'Vendos identifikuesin',
        },
        city: {
          label: 'Qytet',
          placeholder: 'Vendosni qytetin',
        },
        zip: {
          label: 'Kodi Postal',
          placeholder: 'Vendosni kodin zip',
        },
        country: {
          label: 'Vend',
          placeholder: 'Vendosni vendin',
        },
        cardNumber: {
          label: 'Numri i kartes',
          placeholder: 'Vendosni numrin e kartës',
        },
        customerNumber: {
          label: 'Numri i konsumatorëve',
          placeholder: 'Futni numrin',
        },
        email: {
          label: 'Postë elektronike',
          placeholder: 'Vendosni postën elektronike',
        },
        firstname: {
          label: 'Emri',
          placeholder: 'Vendosni emrin e parë',
        },
        lastname: {
          label: 'Mbiemri',
          placeholder: 'Vendosni mbiemrin',
        },
        phone: {
          label: 'ToDo',
          placeholder: 'ToDo',
        },
        searchID: {
          label: 'Kërko ID',
          placeholder: 'Vendosni ID-Në E Kërkimit',
        },
        personIdType: {
          label: 'Lloji I Identitetit',
          placeholder: 'Zgjidhni Llojin E ID-Së',
        },
        idTypes: {
          person_id: 'ID E Personit',
          eid_verification_id: 'EID Verification ID',
          pharmacy_id: 'Pharmacy ID',
          pharmadirekt: 'Pharma-Direkt ID',
          noweda: 'Noweda ID',
          user_id: 'User-ID',
          onfido_check_id: 'Onfido Check ID',
        },
        showHistory: {
          label: 'ToDo',
          placeholder: 'ToDo',
        },
      },
      title: 'Kërko konsumatorëve',
    },
  },
  date: {
    jan: {
      short: 'Jan',
      long: 'Janar',
    },
    feb: {
      short: 'Feg',
      long: 'Fegruary',
    },
    mar: {
      short: 'Mar',
      long: 'Marsh',
    },
    apr: {
      short: 'Pri',
      long: 'Prill',
    },
    may: {
      short: 'Mun',
      long: 'Mund',
    },
    jun: {
      short: 'Qer',
      long: 'Qershor',
    },
    jul: {
      short: 'Kor',
      long: 'Korrik',
    },
    aug: {
      short: 'Gus',
      long: 'Gusht',
    },
    sep: {
      short: 'Sht',
      long: 'Shtator',
    },
    oct: {
      short: 'Tet',
      long: 'Tetor',
    },
    nov: {
      short: 'Nën',
      long: 'Nëntor',
    },
    dec: {
      short: 'Dhj',
      long: 'Dhjetor',
    },
    mon: {
      short: 'E Hënë',
      long: 'E Hënë',
    },
    thu: {
      short: 'E Martë',
      long: 'E Martë',
    },
    wed: {
      short: 'E Mërkurë',
      long: 'E Mërkurë',
    },
    tue: {
      short: 'E Martë',
      long: 'E Martë',
    },
    fri: {
      short: 'E Premte',
      long: 'E Premte',
    },
    sat: {
      short: 'E Shtunë',
      long: 'E Shtunë',
    },
    sun: {
      short: 'E Diel',
      long: 'E Diel',
    },
  },
  documents: {
    selectDocuments: 'Zgjidhni Dokumentet',
    selectDocument: 'Zgjidhni Dokumentin',
    uploadDocuments: 'Ngarko dokumentin',
  },
  error: {
    noUserSelected: 'Asnjë përdorues zgjedhur',
  },
  generic: {
    chooseReport: 'Zgjidh raportin',
    createPosting: 'Krijo postimin',
    enter: 'hyj {{title}}',
    isRequired: 'nevojitet',
    report: 'raport',
    downloadReport: 'Raporti i Shkarkimit',
    pleaseEnterValid: 'Ju lutem shkruani një e vlefshme',
    postingTemplate: 'Modeli i Postimit',
    selectPostingTemplate: 'Zgjidhni Modelin e Postimit',
    selectReportTemplate: 'Zgjidhni Modelin e Raportit',
    hideOptionalFields: 'Fshih fushat opsionale',
    showOptionalFields: 'Trego fushat opsionale',
  },
  logOut: {
    heading: 'Ju jeni duke u loguar tani',
    message: 'Koha e pasivitetit ka tejkaluar',
    refresh: 'Faqja duhet të rifreskohet tani',
  },
  table: {
    noEntriesFound: 'Asnjë entries gjetur',
  },
  paging: {
    rowsPerPage: 'Rreshtave për faqe',
    of: 'të',
    showing: 'duke treguar',
  },
  input: {
    amountErrorInvalid: 'Ju lutem shkruani një sasi të vlefshme',
    emailErrorRequired: 'E-Mail është e nevojshme',
    emailErrorInvalid: 'Ju lutem shkruani një email të vlefshme',
    passwordErrorRequired: 'Fjalëkalimi është e nevojshme',
    passwordErrorInvalid: 'Ju lutem shkruani një fjalëkalim i vlefshëm',
    email: {
      label: 'E-Mail',
    },
    password: {
      label: 'Shkruani fjalëkalimin',
    },
    phone: {
      label: 'Telefon',
    },
    firstname: {
      label: 'Emri',
    },
    lastname: {
      label: 'Mbiemri',
    },
    address: {
      label: 'Adresë',
    },
    city: {
      label: 'Qytet',
    },
    postCode: {
      label: 'Kodi Postar',
    },
    state: {
      label: 'Shtet',
    },
    gender: {
      label: 'Gjini',
    },
    nationality: {
      label: 'Kombësi',
    },
    customerNumber: {
      label: 'Numri i konsumatorëve',
    },
    accountNumber: {
      label: 'Numri i llogarisë',
    },
    cardNumber: {
      label: 'Numri i kartes',
    },
    pin: {
      label: 'Gjilpere tuaj',
    },
    nr: {
      label: 'Numri i shtëpisë',
    },
    search: {
      label: 'Kërko',
    },
    passportId: {
      label: 'ToDo',
    },
  },
  nav: {
    dashboard: 'baltëpritëse',
    customers: 'klient',
    transactions: 'transaksionet',
    postingTemplates: 'Postimi i Modeleve',
    reports: 'raportet',
    backoffice: 'zyra prapa',
    reporting: 'raportimi',
    administration: 'administratë',
    permissions: 'Lejet',
    fees: 'tarifat',
    teams: 'ekipet',
    settings: 'Cilësimet',
    connections: 'ToDo',
  },
  overlay: {
    passwordTitle:
      'Nëse dëshironi të ndryshoni fjalëkalimin tuaj, ju lutem shkruani një tuaj të tanishme të parë.',
    noElements: 'Asnjë element i dhënë',
    confirmation: 'Kërkohet konfirmimi',
  },
  team: {
    newTeamMember: 'anëtar i ri i ekipit',
    roleAssignment: 'roli caktimi',
    required: {
      firstName: 'Ju lutemi shkruani emrin tuaj të parë',
      lastName: 'Ju lutemi shkruani emrin tuaj të fundit',
      email: 'Ju lutemi shkruani adresën tuaj e-mail',
    },
    header: {
      personId: 'Identifikimi i Personit',
      lastname: 'Mbiemri',
      firstname: 'Emri',
      email: 'E-Mail',
    },
    input: {
      entity: {
        label: 'Entity',
      },
      email: {
        label: 'E-Mail',
        placeholder: 'shkruani e-mailin',
      },
      firstname: {
        label: 'Emri',
        placeholder: 'Futni emrin e parë',
      },
      fullname: {
        label: 'emër',
      },
      lastname: {
        label: 'Mbiemri',
        placeholder: 'Shkruani mbiemrin',
      },
      role: {
        label: 'rol',
        placeholder: 'rol',
      },
    },
  },
  tooltip: {
    copied: 'Kopjuar!',
  },
  transactions: {
    dateFrom: 'Data nga',
    dateTo: 'Data për',
    dateRangeEnd: 'Fundi i diapazonit të datës',
    dateRangeStart: 'Fillimi i diapazonit të datës',
    dateRange: 'Diapazoni I Datës',
    downloadStatement: 'Shkarko deklaratë',
    endBalance: 'bilanci i fundit',
    noPendingTransactions: 'Asnjë transaksion në pritje',
    noTransactions: 'Nuk transaksionet',
    startBalance: 'bilanci fillimi',
    transactions: 'transaksionet',
    pending: 'ToDo',
    requests: 'ToDo',
    startBalHelper: 'Bilanci në datën e fillimit',
    endBalHelper: 'Bilanci në datën e mbarimit',
    pendingHeader: 'Pritje',
    hideReversal: 'ToDo',
    hideBlocked: 'ToDo',
    expandableTitle: 'Filtrat e transaksionit',
    header: {
      attachment: '',
      information: 'Informacioni i dërgesave',
      is_blocked: 'ToDo',
      currency: 'Monedhë',
      date: 'Data',
      name: 'Emër',
      transactionId: 'ID transaksion',
      description: 'Deklarata e Rezervimit',
      bookingDate: 'Data e prenotimit',
      valueDate: 'Data Vlera',
      amount: 'Sasi',
      requestMoneyId: 'Kërkoni ID të Parave',
      statement: 'Celës',
    },
    search: {
      placeholder: 'transaksionet Kërko',
    },
  },
  usermenu: {
    appInformation: 'App Informacione',
    customerCare: 'Kujdesit të konsumatorëve',
    entity: 'Entity',
    helpCenter: 'Qendra e ndihmes',
    languages: 'Gjuhë',
    logout: 'Shkyç',
    logoutTimer: 'Shkyç në {{minutes}} min',
    membersince: 'anëtar',
    notifications: 'Njoftime',
    password: 'fjalëkalim',
    security: 'siguri',
    setLanguage: 'Gjuhe',
    settings: 'Cilësimet',
    update: 'Përditësimi',
    version: 'Version',
    language: {
      sq: 'shqiptar',
      de: 'deutsch',
      en: 'english',
      nl: 'ToDo',
      es: 'ToDo',
      it: 'ToDo',
      ja: 'ToDo',
      sv: 'ToDo',
      da: 'ToDo',
      zh: 'Chinese',
      albanian: 'shqiptar',
      english: 'anglisht',
      german: 'gjermanisht',
    },
    touchID: 'Identifikimi i Prekjes',
    selectImage: 'Ngarko Një Fotografi Të Re Të Profilit',
    showProfile: 'Profili i show',
    upgradeAccount: 'llogaria upgrade',
    manageAccount: 'Menaxho llogarine',
    closeAccount: {
      title: 'llogaria afër',
      heading: 'Mbyllim llogarinë tuaj',
      message:
        'Jeni i sigurt se doni të mbyllni llogarinë tuaj? Të gjitha të dhënat e ruajtura do të jetë e humbur dhe nuk mund të restaurohen.',
      button: 'Mbylle llogari',
    },
  },
  messageMenu: {
    header: 'mesazhet',
    fetchError: 'Gabim gjatë tërheqjes së njoftimeve',
  },
  clientStats: {
    registered: 'ToDo',
    identified: 'ToDo',
    transacting: 'ToDo',
  },
  messages: {
    noConfig: {
      error: 'Asnjë konfigurim nuk u gjet',
      success: '',
    },
    noBackend: {
      error: 'Backend nuk mund të arrihet (Shërbimi i padisponueshëm))?',
      success: '',
    },
    loginNoData: {
      error: 'Emri i Përdoruesit/Fjalëkalim nevojshme',
      success: '',
    },
    transactionGet: {
      error: 'Numri i Panjohur i Llogarisë',
      success: '',
    },
    languageSet: {
      error: 'Dështimi në vendosjen e gjuhës',
      success: 'Sukses set Gjuha',
    },
    addCustomer: {
      error: 'Dështoi për të shtuar të konsumatorëve',
      success: 'Konsumatori u shtua me sukses në sistemin backend.',
    },
    addCustomerExists: {
      error: 'Konsumatori Tashmë Ekziston',
      success: 'Konsumatori u shtua me sukses në sistemin e backend.',
    },
    preferencesSave: {
      error: 'Gabim gjatë ruajtjes së cilësimeve',
      success: 'Cilësimet u ruajtën me sukses',
    },
    deleteAccount: {
      error: 'Ju nuk mund të na lërë',
    },
    addTeamMember: {
      error: 'ToDo',
    },
    addTeamMemberRole: {
      error: 'Në pamundësi për të shtuar një rol',
      success: 'Sukses shtuar një anëtar i grupit dhe rolin',
    },
    updateTeamMemberRole: {
      error: 'Në pamundësi për të rinovuar rolin',
      success: 'përditësua me sukses rolin',
    },
    downloadStatements: {
      error: 'Gabim gjatë Pasqyrave shkarkimit',
      success: 'Deklaratat shkarkuar me sukses',
    },
    genericRequest: {
      success: 'Sukses kryer kërkesë të përgjithshme',
    },
    getTransactionsParams: {
      error: 'parametrat e Zhdukur të shkoj të marr accountAccountNumberActivityDetailGet',
    },
    languageError: {
      error: 'në pamundësi për të vendosur Gjuhën',
    },
    setPersonEntity: {
      error: 'ToDo',
    },
    login: {
      error: 'Gabim gjatë logging in',
      success: 'hyrë me sukses në',
    },
    partnerNotFound: {
      error: 'Partner nuk u gjet',
    },
    passwordWrong: {
      error: 'Fjalëkalimi Origjina e gabuar',
      success: 'fjalëkalimi ndryshuar',
    },
    postRequestError: {
      error: 'iluzione shfaq ndërsa kërkesën e postës',
    },
    putRequestError: {
      error: 'Gabim shfaq gjatë kryerjes së kërkesës put',
    },
    general: {
      error: 'Ndodhi një gabim i panjohur',
      success: 'Sukses',
    },
  },
  transfer: {
    balance: 'ekuilibër',
    selectAccount: 'Zgjidh Llogaria',
    details: {
      accountFrom: 'Llogari nga',
      attachments: 'Attachments',
      datetimeCreate: 'Koha e datës krijuar',
      documents: 'Dokumentet',
      documentsEid: 'Dokumentet e Eid',
      documentsUpload: 'Ngarko Dokumente',
      emailAddressIdFrom: 'E-Mail adresa id_from',
      emailAddressIdTo: 'E-Mail adresa id_to',
      inOut: 'Brenda jashte',
      instructedAmount: 'Sasi',
      instructedAmountCurreny: 'Monedhë',
      localDatetime: 'DATETIME lokale',
      location: 'Vend',
      personIdFrom: 'Identifikimi i personit nga',
      purpose: 'Qëllim',
      refMoneyTransferId: 'ref_money_transfer_id',
      refTaId: 'ref_ta_id',
      referenceId: 'REFERENCE_ID',
      taId: 'Ta-ID',
      text: 'tekst',
      title: 'Detajet e Transaksionit',
      transactionSource: 'Burimi i Transaksionit',
      cancelTransaction: 'A je i sigurt?',
      cancelTransactionHeader: 'Ju jeni gati të anulloni Transaksionin',
      tradeDate: 'ToDo',
      transactionId: 'Transaction-ID',
    },
    postingJournal: {
      bookingDate: 'Data e Prenotimit',
      leftTitle: 'Dergo para',
      memo: 'memorandum',
      source: 'Burim',
      posting: 'Transaction-ID',
      printPdf: 'Shtypni PDF',
      rightTitle: 'Postimi i Ditarit',
      valueDate: 'Data Vlera',
      header: {
        account: 'llogari',
        subbalance: 'Nën Bilanci',
        statementId: 'Identifikimi i deklaratës',
        accountName: 'Emri i llogarise',
        dc: 'Debi/Kredi',
        key: 'Celës',
        keyDescription: 'Përshkrim',
        amount: 'sasi',
        statementText: 'Deklarata',
      },
    },
  },
  service_center: {
    checkboxes: {
      open_testkit_during_video: 'Todo',
      person_performs_test: 'Todo',
      testkit_always_in_video: 'Todo',
      video_not_cut: 'Todo',
      result_readable: 'Todo',
      performing_person_and_testkit_match: 'Todo',
      barcode_readable: 'ToDo',
    },
    start_date: 'Todo',
    end_date: 'Todo',
    testkit: 'Todo',
    duration: 'ToDo',
    testTime: 'ToDo',
    testtype: 'ToDo',
    comment: {
      label: 'ToDo',
      placeholder: 'ToDo',
    },
    overlay: {
      title: 'ToDo',
      text: 'ToDo',
    },
    overlayNores: {
      title: 'ToDo',
      text: 'ToDo',
    },
    types: {
      regula_approve_document: 'ToDo',
      eyeson: 'ToDo',
    },
    createVoucher: 'ToDo',
    setVoucher: 'ToDo',
  },
  productMaintenance: {
    title: 'Product maintenance',
    typeOfTest: 'ToDo',
    category: 'ToDo',
    longDescription: 'ToDon',
    parent: 'ToDo',
    image: 'ToDo',
    mapedTypes: 'ToDo',
    categoryCode: 'ToDo',
    productConfig: 'ToDo',
    productDelivery: {
      delivery_at_merchant: 'ToDo',
      delivery_at_home: 'ToDo',
      digital_product: 'ToDo',
    },
    productAppointment: {
      product_appointment: 'ToDo',
      merchant_appointment: 'ToDo',
      walk_in: 'ToDo',
    },
    deliveryType: 'ToDo',
    appointmentType: 'ToDo',
  },
  onfido: {
    read: 'Todo',
    checkResult: 'Todo',
    name: 'Todo',
    lastName: 'Todo',
    datetimeCreate: 'Todo',
    person_toggle: 'ToDo',
    showTicked: 'ToDo',
  },
};
