import React from 'react';
import styled from 'styled-components';
import {
  ACenteredOverlay,
  IPropsCenter,
  IStateCenter,
} from '../../../logic/handler/overlayhandler/globaloverlays/aCenteredOverlay';
import { translate } from '../../../common/language/translate';
import ButtonOk from '../../atomiccompoents/buttons/buttonOk';
import { FlexBox } from '../../../content/auth/auth.css';

const StyledViewMain = styled(FlexBox)`
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  width: 500px;
  height: 270px;
  background-color: #ffffff;
  border-radius: 4px;

  padding: 32px 27px;
  box-sizing: border-box;

  a#cancel {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 10px;

    :hover {
      text-decoration: underline;
    }
  }
`;

const BigParagraph = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  text-align: left;
  margin-bottom: 18px;
`;

const StyledTextParagraph = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.499689);
  text-align: left;
  margin-bottom: 25px;
  width: 100%;
  height: 50%;
`;
const StyledButtonView = styled(FlexBox)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export interface IConfirmationOverlayProps extends IPropsCenter {
  confirm: () => void;
  heading: string;
  message: string;
  buttonText?: string;
  doSelfClose?: boolean;
}
interface IState extends IStateCenter {
  keyForLoadingSpinner?: number;
}

export default class WarningOverlay extends ACenteredOverlay<
  IConfirmationOverlayProps,
  IState
> {
  constructor(props: IConfirmationOverlayProps) {
    super(props);

    this.state = {
      width: props.width,
      height: props.height,
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    };
  }

  reviveState(): void {}

  defineContent() {
    return (
      <StyledViewMain>
        <BigParagraph>{this.props.heading} </BigParagraph>
        <StyledTextParagraph>{this.props.message}</StyledTextParagraph>
        <StyledButtonView>
          <ButtonOk
            id="btnConfirmationRequestOverlayOk"
            key={this.state.keyForLoadingSpinner}
            onClick={() => {
              this.props.confirm();
              if (this.props.doSelfClose !== false) {
                this.props.selfClose();
              }
            }}
          >
            {this.props.buttonText == null ? translate('button.ok') : this.props.buttonText}
          </ButtonOk>
        </StyledButtonView>
      </StyledViewMain>
    );
  }
}
