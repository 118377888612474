import React, { SyntheticEvent } from 'react';
import { GenericElementType, IGenericElement } from './IGenericElement';
import GenericTextInput from './genericTextInput';
import { GenericDateInput } from './genericDateInput';
import GenericSelectInput from './genericSelectInput';
import { CustomerDataStore } from '../../logic/flux';
import { Ident } from '../../logic/api';
import { IItfAccount } from '../../logic/types';
import { Log, Logs } from '../../logic/log';

export default class GenericElementUtils {
  private static instance: GenericElementUtils | null;

  static getInstance(): GenericElementUtils {
    if (GenericElementUtils.instance == null) {
      GenericElementUtils.instance = new GenericElementUtils();
    }
    return GenericElementUtils.instance;
  }

  // TODO: createGenericElement(type: GenericElementType): AGenericElement<P extends GenericElementProps, S extends GenericElementState>{
  createGenericElement(
    elem: IGenericElement,
    id: string,
    index: number,
    notification: (
      requestBodyUuid: string,
      value: any,
      index?: number,
      hasError?: boolean,
    ) => void,
    parameterNames: any,
    value?: any,
    onSubmit?: (event: SyntheticEvent) => void,
    activeIndex?: number,
    indexCallback?: (idx: number) => void,
    dynElemCallback?: (idx: any) => void,
  ): JSX.Element | undefined {
    let title = '';
    if (parameterNames != null && parameterNames.label != null) {
      title = parameterNames.label;
    } else if (elem.parentKeys != null && elem.parentKeys.length > 0) {
      const newTitle = elem.title.replace(',', ' or ');
      title = newTitle + ' of ' + elem.parentKeys[0];
    } else {
      title = elem.title;
    }
    if (elem.parentKeys.indexOf('debtorAccount') > -1) {
      const account: IItfAccount | undefined = CustomerDataStore.getCurrentAccount();

      if (account != null) {
        value = account.account_number;
      }
    }
    if (elem.autofill != null && value == null) {
      const autostring: string = elem.autofill;
      try {
        const values = autostring.split('::')[1];
        const splitData = values.split('/');
        if (splitData != null && splitData.length > 1) {
          if (splitData[0] === 'person') {
            const person: Ident.Person | undefined = CustomerDataStore.getUser();
            const key = splitData[1] as keyof Ident.Person;
            if (person != null) {
              value = person[key];
            }
          } else if (splitData[0] === 'account') {
            const account: IItfAccount | undefined = CustomerDataStore.getCurrentAccount();
            const key = splitData[1] as keyof IItfAccount;

            if (account != null) {
              value = account[key];
            }
          }
        }
      } catch (error) {
        Log.warn(
          Logs.PARSER,
          'something went wrong whiel parsing default value for ' + elem.title,
        );
      }
    }
    switch (elem.type) {
      default:
        return undefined;
      case GenericElementType.text:
      case GenericElementType.password:
      case GenericElementType.combined:
      case GenericElementType.number:
        return (
          <GenericTextInput
            title={
              parameterNames != null && parameterNames.label != null
                ? parameterNames.label
                : title
            }
            placeHolder={
              parameterNames != null && parameterNames.label != null
                ? parameterNames.label
                : title
            }
            type={elem.type}
            helpText={
              parameterNames != null && parameterNames.short_info != null
                ? parameterNames.short_info
                : undefined
            }
            id={id}
            index={index}
            requestBodyUuid={elem.uuid}
            isRequired={elem.required}
            regex={elem.validator}
            notification={notification}
            regexMap={elem.regexMap}
            numberValidatorCallback={elem.numberValidatorCallback}
            value={value}
            onSubmit={onSubmit}
            key={id}
            activeIndex={activeIndex}
            activeIndexCallback={indexCallback}
          />
        );
      case GenericElementType.date:
        return (
          <GenericDateInput
            title={
              parameterNames != null && parameterNames.label != null
                ? parameterNames.label
                : title
            }
            type={elem.type}
            id={id + title}
            helpText={
              parameterNames != null && parameterNames.short_info != null
                ? parameterNames.short_info
                : undefined
            }
            index={index}
            isRequired={elem.required}
            requestBodyUuid={elem.uuid}
            notification={notification}
            key={id}
            activeIndex={activeIndex}
            activeIndexCallback={indexCallback}
          />
        );
      case GenericElementType.datetime:
        return (
          <GenericDateInput
            title={
              parameterNames != null && parameterNames.label != null
                ? parameterNames.label
                : title
            }
            type={elem.type}
            id={id + title}
            helpText={
              parameterNames != null && parameterNames.short_info != null
                ? parameterNames.short_info
                : undefined
            }
            index={index}
            isRequired={elem.required}
            requestBodyUuid={elem.uuid}
            notification={notification}
            key={id}
            activeIndex={activeIndex}
            activeIndexCallback={indexCallback}
          />
        );
      case GenericElementType.enum:
        return (
          <GenericSelectInput
            title={
              parameterNames != null && parameterNames.label != null
                ? parameterNames.label
                : title
            }
            helpText={
              parameterNames != null && parameterNames.short_info != null
                ? parameterNames.short_info
                : undefined
            }
            type={elem.type}
            id={id}
            index={index}
            requestBodyUuid={elem.uuid}
            isRequired={elem.required}
            notification={notification}
            values={elem.data}
            key={id}
          />
        );
      case GenericElementType.anyOf:
        return (
          <GenericSelectInput
            title={
              parameterNames != null && parameterNames.label != null
                ? parameterNames.label
                : title
            }
            helpText={
              parameterNames != null && parameterNames.short_info != null
                ? parameterNames.short_info
                : undefined
            }
            type={elem.type}
            id={id}
            index={index}
            requestBodyUuid={elem.uuid}
            isRequired={elem.required}
            notification={(reqUU: string, value: any) => {
              if (dynElemCallback != null) dynElemCallback(value);
            }}
            values={elem.data}
            key={id}
          />
        );
    }
  }
}

export const genericElementUtils = GenericElementUtils.getInstance();
