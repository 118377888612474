import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export interface IOption {
  name: string;
  value?: string | number;
  key?: string;
}

interface IProps {
  id: string;
  placeholder?: string;
  label?: string | null;
  className?: string | undefined;
  notification?: ((message: any) => void) | null;
  options?: Array<IOption> | null;
  current?: string | number;
  error?: string | null;
  helptext?: string;
  maxWidthForHelper?: number;
  disabled?: boolean;
  wrapperStyle?: CSSProperties;
}

interface IState {
  label?: string | null;
  options?: Array<IOption> | null;
  current?: string | number;
  error?: string | null;
}
const WrapperHelpText = styled.div<{ maxWidth: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  align-items: center;
  position: relative;
  max-width: ${props => props.maxWidth}px;
  select:focus {
    outline: none;
  }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
      url(/invrs/selectArrow.png) no-repeat 96% #fff !important;
    background-size: 24px 24px !important;
  }
`;
//TOD: Make this independent from external urls
const HelpText = styled.div`
  font-size: 10px;
  color: ${props => props.theme.Button.backgroundColor};
  width: 100%;
  font-weight: 400;
  margin-bottom: 15px;
`;
export class Select extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const label = props.label;

    const n: IState = {};
    if (label != null) {
      n.label = label;
    }
    if (this.props.options != null) {
      n.options = this.props.options;
    }
    if (this.props.current != null) {
      n.current = this.props.current;
    }
    if (this.props.error != null) {
      n.error = this.props.error;
    }
    this.state = n;
  }

  UNSAFE_componentWillReceiveProps(props: IProps) {
    const newState: IState = {};
    if (props.error != null && this.state.error !== props.error) {
      newState.error = props.error;
    }
    if (props.options != null && this.state.options !== props.options) {
      newState.options = props.options;
    }
    if (props.current != null && this.state.current !== props.current) {
      newState.current = props.current;
    }
    if (props.label != null && this.state.label !== props.label) {
      newState.label = props.label;
    }
    this.setState(newState);
  }

  onchange(content: SyntheticEvent) {
    const target = content.target as HTMLSelectElement;
    if (target == null || target.value == null) {
      return;
    }

    let value: string | number = target.value;
    if (this.props.options != null && typeof this.props.options[0].value === 'number') {
      value = Number.parseInt(target.value);
    }
    if (this.props.notification != null) {
      this.props.notification({ [this.props.id as string]: value });
    }
    this.setState({ current: value });
  }

  render() {
    const selectElement = (
      <select
        autoComplete="false"
        onChange={content => this.onchange(content)}
        id={this.props.id}
        className={this.props.className}
        placeholder={this.props.placeholder}
        style={{ fontWeight: 400 }}
        name={this.props.id}
        disabled={this.props.disabled === true}
        value={this.state.current}
      >
        {this.state.options != null
          ? this.state.options.map(option => {
              const value: string | number = option.value == null ? option.name : option.value;
              return (
                <option value={value} key={option.key}>
                  {option.name}
                </option>
              );
            })
          : null}
      </select>
    );

    if (this.state.label == null) {
      if (this.props.helptext != null) {
        return (
          <WrapperHelpText
            className={this.props.className}
            style={this.props.wrapperStyle}
            maxWidth={
              this.props.maxWidthForHelper != null ? this.props.maxWidthForHelper : 600
            }
          >
            {selectElement}
            <HelpText id="helperwrapper">{this.props.helptext}</HelpText>
          </WrapperHelpText>
        );
      } else {
        return selectElement;
      }
    } else {
      return (
        <label htmlFor={this.props.id} className={this.props.className}>
          {this.state.label}
          <br />
          {selectElement}
        </label>
      );
    }
  }
}
