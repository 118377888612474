import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { translate } from '../../common/language/translate';
import {
  KeyField,
  ValueField,
} from '../../content/dashboard/content/customers/basicStyledComponents/customerDetails.css';
import { DisplayMenuComponent } from '../compositcomponents/displayMenuComponent';
import { CheckBox } from './form';

interface IProps {
  translationkey: string;
  value?: any;
  id: string;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  wrapperStyle?: React.CSSProperties;
  bigCheckbox?: boolean;
  required?: boolean;
}

interface IState {}

export default class KeyCheckBoxComponent extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <LargeKeyValueBlock style={this.props.wrapperStyle}>
          <KeyField>
            {translate(this.props.translationkey, this.props.translationkey) +
              (this.props.disabled === true || this.props.required === false ? '' : ' *')}
            :
          </KeyField>
          <CenteredValuField>
            <DisplayMenuComponent
              elementKey={this.props.id}
              copyValue={this.props.value != null ? this.props.value.toString() : ''}
              methods={{}}
            >
              <CheckBox
                id={this.props.id}
                disabled={this.props.disabled}
                value={this.props.value === true}
                onChange={this.props.onChange}
                bigCheckbox={this.props.bigCheckbox}
              />
            </DisplayMenuComponent>
          </CenteredValuField>
        </LargeKeyValueBlock>
      </React.Fragment>
    );
  }
}

const LargeKeyValueBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 450px;
  height: 62px;
  border-bottom: 1px solid #dedede;
  align-items: space-between;
  padding: ${props => props.theme.Table.TBody.Td.padding};
  margin: 8px;
  padding: 8px;
`;

const CenteredValuField = styled(ValueField)`
  display: flex;
  & > div {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;
