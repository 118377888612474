// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface FeeAssignment
 */
export interface FeeAssignment {
  /**
   * @type {string}
   * @memberof FeeAssignment
   */
  memo?: string;
  /**
   * @type {string}
   * @memberof FeeAssignment
   */
  event?: FeeAssignmentEventEnum;
  /**
   * @type {Date}
   * @memberof FeeAssignment
   */
  valid_from?: Date;
  /**
   * @type {Date}
   * @memberof FeeAssignment
   */
  invalid_from?: Date;
  /**
   * @type {number}
   * @memberof FeeAssignment
   */
  automatic_fee_assignment_id?: number;
  /**
   * @type {number}
   * @memberof FeeAssignment
   */
  amount?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum FeeAssignmentEventEnum {
  A = 'A',
  I = 'I',
  Y = 'Y',
  V = 'V',
  J = 'J',
}
