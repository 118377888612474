import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { HideComponent } from '../../hideComponent';
import { Icons } from '../../../images';
import { translate } from '../../../common/language/translate';
import Tooltip from '../../tooltip';
import TouchableOpacity from './touchableOpacity';

interface IProps {
  text?: string;
}

interface IState {
  copied?: boolean;
  showTooltip?: boolean;
  x?: number;
  y?: number;
}

export default class CopyButton extends React.Component<IProps, IState> {
  private iconRef: React.RefObject<TouchableOpacity> | null = null;

  constructor(props: IProps) {
    super(props);

    this.state = {
      copied: false,
      showTooltip: false,
      x: 0,
      y: 0,
    };

    this.iconRef = React.createRef<TouchableOpacity>();

    this._onCopy = this._onCopy.bind(this);
  }

  private _onCopy(event: SyntheticEvent, domRect: DOMRect | null): void {
    if (this.iconRef == null) {
      return;
    }
    if (this.state.showTooltip === true || this.props.text == null) {
      return;
    }

    navigator.clipboard.writeText(this.props.text);

    this.setState({
      showTooltip: true,
      x: domRect == null ? 0 : domRect.left - 25,
      y: domRect == null ? 0 : domRect.top - 30,
    });
  }

  render() {
    return (
      <React.Fragment>
        <StyledTouchable
          ref={this.iconRef}
          onClick={this._onCopy}
          containerStyle={{ width: '16px' }}
        >
          {Icons.copy()}
        </StyledTouchable>
        <StyledHideComponent
          delay={1}
          trigger={this.state.showTooltip}
          onHide={() => {
            this.setState({
              showTooltip: false,
            });
          }}
        />
        {this.state.showTooltip !== true ? null : (
          <Tooltip
            text={translate('tooltip.copied')}
            x={this.state.x == null ? 0 : this.state.x}
            y={this.state.y == null ? 0 : this.state.y}
          />
        )}
      </React.Fragment>
    );
  }
}

const StyledTouchable = styled(TouchableOpacity)`
  margin-left: 5px;
  display: inline;
  width: 0px;
`;

const StyledHideComponent = styled(HideComponent)`
  width: 0px;
  height: 0px;
  display: inline;
`;
