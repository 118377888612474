import React from 'react';
import styled from 'styled-components';
import { api, apis, ApiError, Ident, Account } from '../../../../logic/api';
import { Log, Logs } from '../../../../logic/log';
import {
  IInitProps,
  IInitState,
  InitialDataComponent,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import {
  IStatusState,
  Reporter,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { Settings } from '../../../../logic/api/ident';
import { CheckBox } from '../../../../components/atomiccompoents/form/checkbox';
import { Icons } from '../../../../images';
import { IItfAccount } from '../../../../logic/types';
import { translate } from '../../../../common/language/translate';
import { FlexBox } from '../../../auth/auth.css';
import {
  Table,
  CheckboxWrapper,
  CustomRow,
  CustomCell,
  TitleCell,
  HelpTextWrapper,
  HeaderCell,
  HelperCheckBox,
  MailBox,
  MainTitleCell,
  NoBorderCell,
  NoBorderSubTitleCell,
} from './basicStyledComponents/customerDetails.css';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { AccountNotificationPutRequest } from '../../../../logic/api/account';
import { evaluateErrorMessage } from '../../../../logic/helper/Common';
import { withPersonAndAccount } from '../../../../components/hocs/withPersonAndAccount';

enum NotificationProcess {
  SendMoney = 'send_money',
  ReceiveMoney = 'receive_money',
  RequestMoney = 'request_money',
  TakeOver = 'take_over',
  SplitMoney = 'split_money',
}

enum NotificationChannel {
  Email = 'E',
  Push = 'P',
}

interface INotitificationStatus {
  [key: string]: string;
}

interface IProps extends IInitProps {
  person?: Ident.Person;
  currentAccount?: IItfAccount;
}
interface IState extends IStatusState, IInitState {
  languageSettings?: Settings;
  notificationSettings?: Array<Account.NotificationGroup>;
  notificationChanged?: { [key: string]: Account.Notification };
  keyForLoadingSpinner?: number;
  openMailSettings?: boolean;
  openPushSettings?: boolean;
  success: INotitificationStatus;
  setAll?: boolean;
  allP?: boolean;
  allE?: boolean;
  dataChanged?: boolean;
}

const StyledViewMain = styled(FlexBox)`
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
`;
const StyledContentBox = styled(FlexBox)`
  flex-direction: column;
  padding: 0px 32px;
`;
//TODO THHIS COMPONENT NEEDS A COMPLETE REWORK; THIS IS WAY TO COMPLICATED IMPLEMENTED
class CustomerDetailsPreferences extends InitialDataComponent<IProps, IState> {
  private redoCount = 0;
  constructor(props: IProps) {
    super(props);

    this.state = {
      openMailSettings: false,
      openPushSettings: false,
      success: {},
      notificationChanged: {},
      setAll: false,
      allP: false,
      allE: false,
      dataChanged: false,
    };

    this.fetchPersonSettings = this.fetchPersonSettings.bind(this);
    this.getSingleNotificationSetting = this.getSingleNotificationSetting.bind(this);
    this.fetchPersonSettings();
  }

  reviveState(newState: { [key: string]: any }): void {
    this.setState({
      ...newState,
      success: this.state.success,
    });
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.person?.person_id !== this.props.person?.person_id) {
      this.fetchPersonSettings();
    }
  }

  onChangeSetting(notification: Account.Notification, keyOfJson?: string) {
    if (
      this.props.currentAccount != null &&
      this.props.person != null &&
      this.props.person.state !== Ident.PersonStateEnum.C
    ) {
      const req: AccountNotificationPutRequest = {
        account_number: this.props.currentAccount.account_number,
        person_id: this.props.person.person_id,
        Notification: notification,
      };
      api
        .asyncRequest<void>(req, apis.DefaultApi, 'accountNotificationPut')
        .then(() => {
          MessageHandler.onSuccess(Reporter['preferences.save']);
          this.fetchPersonSettings();
        })
        .catch(() => {
          MessageHandler.onError(Reporter['preferences.save']);
        });
    }
  }

  setAll(value: boolean) {
    this.onChangeSetting({ enabled: value });
  }

  fetchPersonSettings(success?: INotitificationStatus): void {
    if (this.props.person == null) {
      // TODO set state languageSettings und notificationSettings zu undefined
      return undefined;
    }

    api
      .asyncRequest<Settings>(
        { person_id: this.props.person.person_id },
        apis.MaintenanceApi,
        'personSettingsGet',
      )
      .then(response => {
        this.setState({
          languageSettings: response,
        });
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
        MessageHandler.onError(
          Reporter['customer.details.settings.fetch'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
      });

    api
      .asyncRequest<Array<Account.NotificationGroup>>(
        {
          account_number:
            this.props.currentAccount == null ? -1 : this.props.currentAccount.account_number,
          person_id: this.props.person.person_id,
        },
        apis.DefaultApi,
        'accountNotificationGet',
      )
      .then(response => {
        const allVals = this.checkAll(response);
        this.setState({
          notificationSettings: response,
          success: success != null ? success : this.state.success,
          setAll: allVals.setAll,
          allE: allVals.allE,
          allP: allVals.allP,
        });
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
        //TODO: Error
      });
  }

  checkAll(data: Array<Account.NotificationGroup>) {
    let all: boolean = false;
    let allE: boolean = true;
    let allP: boolean = true;

    for (const o of data) {
      if (o.group === Account.NotificationGroupGroupEnum.Ident) {
        continue;
      }
      for (const e of o.notifications) {
        if (e.channel === Account.NotificationChannel.E && allE) {
          if (true != e.enabled) {
            allE = false;
          }
        } else if (e.channel === Account.NotificationChannel.P && allP) {
          if (true !== e.enabled) {
            allP = false;
          }
        }
      }
    }
    if (allE && allP) {
      all = true;
    }
    return {
      setAll: all,
      allE: allE,
      allP: allP,
    };
  }

  getSingleNotificationSetting(
    group: Account.NotificationGroupGroupEnum,
    process: string,
    channel: string,
  ): boolean {
    if (
      this.state.notificationSettings == null ||
      this.state.notificationSettings.length === 0
    ) {
      return false;
    } else {
      for (const elem of this.state.notificationSettings) {
        //@ts-ignore //TODO
        if (elem.group === group) {
          //@ts-ignore //TODO
          for (const notification of elem.notifications) {
            if (
              notification.process === process &&
              notification.channel === channel &&
              notification.enabled === true
            ) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  render() {
    /* if (!PermissionStore.hasPermission(permissionGet)) {
            return null;
        } */
    return (
      <StyledContentBox>
        <StyledViewMain>
          <div style={{ width: '45%' }}>
            <div style={{ maxWidth: '600px', width: '100%', marginTop: '32px' }}>
              <HeaderCell>{translate('customers.notificationSettings')}</HeaderCell>
            </div>
            <Table
              style={{
                maxWidth: '1000px',
                borderSpacing: '0',
                paddingRight: '32px',
              }}
            >
              <tbody>
                <CustomRow>
                  <CustomCell>
                    <MainTitleCell style={{ marginLeft: '36px' }}>
                      {translate('customers.activateAll')}
                    </MainTitleCell>
                  </CustomCell>
                  <CustomCell>
                    <HelpTextWrapper>
                      <CheckboxWrapper>
                        <CheckBox
                          id="all"
                          disabled={
                            this.props.person != null &&
                            this.props.person.state === Ident.PersonStateEnum.C
                          }
                          value={this.state.setAll}
                          onChange={value => this.setAll(value)}
                        />
                      </CheckboxWrapper>
                      <HelperCheckBox>
                        {
                          this.state.success[
                            Account.NotificationChannel.P.toString() +
                              Account.NotificationProcess.SendMoney.toString()
                          ]
                        }
                      </HelperCheckBox>
                    </HelpTextWrapper>
                  </CustomCell>
                </CustomRow>
              </tbody>
            </Table>
            <Table
              style={{
                maxWidth: '1000px',
                paddingRight: '32px',
                borderSpacing: '0',
              }}
            >
              <tbody>
                <CustomRow>
                  <CustomCell>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                      onClick={() =>
                        this.setState({
                          openPushSettings: !this.state.openPushSettings,
                        })
                      }
                    >
                      {this.state.openPushSettings === true
                        ? Icons.arrowFacingUp()
                        : Icons.arrowFacingDown()}
                      <MainTitleCell>{translate('customers.messageFriends')}</MainTitleCell>
                    </div>
                  </CustomCell>
                </CustomRow>
              </tbody>
            </Table>
            <MailBox detailsOpen={this.state.openPushSettings === true} maxHeight="400px">
              <Table
                style={{
                  maxWidth: '1000px',
                  paddingRight: '32px',
                  borderSpacing: '0',
                }}
              >
                <tbody>
                  <CustomRow>
                    <NoBorderSubTitleCell>
                      <TitleCell>{translate('customers.activateAll')}</TitleCell>
                    </NoBorderSubTitleCell>
                    <NoBorderCell>
                      <HelpTextWrapper>
                        <CheckboxWrapper>
                          <CheckBox
                            id="allP"
                            disabled={
                              this.props.person != null &&
                              this.props.person.state === Ident.PersonStateEnum.C
                            }
                            value={this.state.allP}
                            onChange={value => {
                              this.onChangeSetting({
                                enabled: value,
                                channel: Account.NotificationChannel.P,
                              });
                            }}
                          />
                        </CheckboxWrapper>
                        <HelperCheckBox>
                          {
                            this.state.success[
                              Account.NotificationChannel.P.toString() +
                                Account.NotificationProcess.SendMoney.toString()
                            ]
                          }
                        </HelperCheckBox>
                      </HelpTextWrapper>
                    </NoBorderCell>
                  </CustomRow>
                  <CustomRow>
                    <NoBorderSubTitleCell>
                      <TitleCell>{translate('customers.sendMoney')}</TitleCell>
                    </NoBorderSubTitleCell>
                    <NoBorderCell>
                      <HelpTextWrapper>
                        <CheckboxWrapper>
                          <CheckBox
                            id="sendMoneyMail"
                            disabled={
                              this.props.person != null &&
                              this.props.person.state === Ident.PersonStateEnum.C
                            }
                            value={this.getSingleNotificationSetting(
                              Account.NotificationGroupGroupEnum.Account,
                              NotificationProcess.SendMoney,
                              NotificationChannel.Push,
                            )}
                            onChange={value =>
                              this.onChangeSetting({
                                enabled: value,
                                process: Account.NotificationProcess.SendMoney,
                                channel: Account.NotificationChannel.P,
                              })
                            }
                          />
                        </CheckboxWrapper>
                        <HelperCheckBox>
                          {
                            this.state.success[
                              Account.NotificationChannel.P.toString() +
                                Account.NotificationProcess.SendMoney.toString()
                            ]
                          }
                        </HelperCheckBox>
                      </HelpTextWrapper>
                    </NoBorderCell>
                  </CustomRow>
                  <CustomRow>
                    <NoBorderSubTitleCell>
                      <TitleCell>{translate('customers.receiveMoney')}</TitleCell>
                    </NoBorderSubTitleCell>
                    <NoBorderCell>
                      <HelpTextWrapper>
                        <CheckboxWrapper>
                          <CheckBox
                            id="receiveMoneyMail"
                            disabled={
                              this.props.person != null &&
                              this.props.person.state === Ident.PersonStateEnum.C
                            }
                            value={this.getSingleNotificationSetting(
                              Account.NotificationGroupGroupEnum.Account,
                              NotificationProcess.ReceiveMoney,
                              NotificationChannel.Push,
                            )}
                            onChange={value =>
                              this.onChangeSetting({
                                enabled: value,
                                process: Account.NotificationProcess.ReceiveMoney,
                                channel: Account.NotificationChannel.P,
                              })
                            }
                          />
                        </CheckboxWrapper>
                        <HelperCheckBox>
                          {
                            this.state.success[
                              Account.NotificationChannel.P.toString() +
                                Account.NotificationProcess.ReceiveMoney.toString()
                            ]
                          }
                        </HelperCheckBox>
                      </HelpTextWrapper>
                    </NoBorderCell>
                  </CustomRow>
                  <CustomRow>
                    <NoBorderSubTitleCell>
                      <TitleCell>{translate('customers.requestMoney')}</TitleCell>
                    </NoBorderSubTitleCell>
                    <NoBorderCell>
                      <HelpTextWrapper>
                        <CheckboxWrapper>
                          <CheckBox
                            id="requestMoneyMail"
                            disabled={
                              this.props.person != null &&
                              this.props.person.state === Ident.PersonStateEnum.C
                            }
                            value={this.getSingleNotificationSetting(
                              Account.NotificationGroupGroupEnum.Friends,
                              NotificationProcess.RequestMoney,
                              NotificationChannel.Push,
                            )}
                            onChange={value =>
                              this.onChangeSetting({
                                enabled: value,
                                process: Account.NotificationProcess.RequestMoney,
                                channel: Account.NotificationChannel.P,
                              })
                            }
                          />
                        </CheckboxWrapper>
                        <HelperCheckBox>
                          {
                            this.state.success[
                              Account.NotificationChannel.P.toString() +
                                Account.NotificationProcess.RequestMoney.toString()
                            ]
                          }
                        </HelperCheckBox>
                      </HelpTextWrapper>
                    </NoBorderCell>
                  </CustomRow>
                  <CustomRow>
                    <NoBorderSubTitleCell>
                      <TitleCell>{translate('customers.takeOver')}</TitleCell>
                    </NoBorderSubTitleCell>
                    <NoBorderCell>
                      <HelpTextWrapper>
                        <CheckboxWrapper>
                          <CheckBox
                            id="takeOverMail"
                            disabled={
                              this.props.person != null &&
                              this.props.person.state === Ident.PersonStateEnum.C
                            }
                            value={this.getSingleNotificationSetting(
                              Account.NotificationGroupGroupEnum.Friends,
                              NotificationProcess.TakeOver,
                              NotificationChannel.Push,
                            )}
                            onChange={value =>
                              this.onChangeSetting({
                                enabled: value,
                                process: Account.NotificationProcess.TakeOver,
                                channel: Account.NotificationChannel.P,
                              })
                            }
                          />
                        </CheckboxWrapper>
                        <HelperCheckBox>
                          {
                            this.state.success[
                              Account.NotificationChannel.P.toString() +
                                Account.NotificationProcess.TakeOver.toString()
                            ]
                          }
                        </HelperCheckBox>
                      </HelpTextWrapper>
                    </NoBorderCell>
                  </CustomRow>
                  <CustomRow>
                    <NoBorderSubTitleCell>
                      <TitleCell>{translate('customers.splitMoney')}</TitleCell>
                    </NoBorderSubTitleCell>
                    <NoBorderCell>
                      <HelpTextWrapper>
                        <CheckboxWrapper>
                          <CheckBox
                            id="splitMoneyMail"
                            disabled={
                              this.props.person != null &&
                              this.props.person.state === Ident.PersonStateEnum.C
                            }
                            value={this.getSingleNotificationSetting(
                              Account.NotificationGroupGroupEnum.Friends,
                              NotificationProcess.SplitMoney,
                              NotificationChannel.Push,
                            )}
                            onChange={value =>
                              this.onChangeSetting({
                                enabled: value,
                                process: Account.NotificationProcess.SplitMoney,
                                channel: Account.NotificationChannel.P,
                              })
                            }
                          />
                        </CheckboxWrapper>
                        <HelperCheckBox>
                          {
                            this.state.success[
                              Account.NotificationChannel.P.toString() +
                                Account.NotificationProcess.SplitMoney.toString()
                            ]
                          }
                        </HelperCheckBox>
                      </HelpTextWrapper>
                    </NoBorderCell>
                  </CustomRow>
                </tbody>
              </Table>
            </MailBox>
            <Table
              style={{
                maxWidth: '1000px',
                paddingRight: '32px',
                borderSpacing: '0',
              }}
            >
              <tbody>
                <CustomRow>
                  <CustomCell>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                      onClick={() =>
                        this.setState({
                          openMailSettings: !this.state.openMailSettings,
                        })
                      }
                    >
                      {this.state.openMailSettings === true
                        ? Icons.arrowFacingUp()
                        : Icons.arrowFacingDown()}
                      <MainTitleCell>{translate('customers.accountActivity')}</MainTitleCell>
                    </div>
                  </CustomCell>
                </CustomRow>
              </tbody>
            </Table>
            <MailBox detailsOpen={this.state.openMailSettings === true} maxHeight="400px">
              <Table
                style={{
                  maxWidth: '1000px',
                  paddingRight: '32px',
                  borderSpacing: '0',
                }}
              >
                <tbody>
                  <CustomRow>
                    <NoBorderSubTitleCell>
                      <TitleCell>{translate('customers.activateAll')}</TitleCell>
                    </NoBorderSubTitleCell>
                    <NoBorderCell>
                      <HelpTextWrapper>
                        <CheckboxWrapper>
                          <CheckBox
                            id="allP"
                            disabled={
                              this.props.person != null &&
                              this.props.person.state === Ident.PersonStateEnum.C
                            }
                            value={this.state.allE}
                            onChange={value => {
                              this.onChangeSetting({
                                enabled: value,
                                channel: Account.NotificationChannel.E,
                              });
                            }}
                          />
                        </CheckboxWrapper>
                        <HelperCheckBox>
                          {
                            this.state.success[
                              Account.NotificationChannel.P.toString() +
                                Account.NotificationProcess.SendMoney.toString()
                            ]
                          }
                        </HelperCheckBox>
                      </HelpTextWrapper>
                    </NoBorderCell>
                  </CustomRow>
                  <CustomRow>
                    <NoBorderSubTitleCell>
                      <TitleCell>{translate('customers.sendMoney')}</TitleCell>
                    </NoBorderSubTitleCell>
                    <NoBorderCell>
                      <HelpTextWrapper>
                        <CheckboxWrapper>
                          <CheckBox
                            id="sendMoneyMail"
                            disabled={
                              this.props.person != null &&
                              this.props.person.state === Ident.PersonStateEnum.C
                            }
                            value={this.getSingleNotificationSetting(
                              Account.NotificationGroupGroupEnum.Account,
                              NotificationProcess.SendMoney,
                              NotificationChannel.Email,
                            )}
                            onChange={value =>
                              this.onChangeSetting({
                                enabled: value,
                                process: Account.NotificationProcess.SendMoney,
                                channel: Account.NotificationChannel.E,
                              })
                            }
                          />
                        </CheckboxWrapper>
                        <HelperCheckBox>
                          {
                            this.state.success[
                              Account.NotificationChannel.E.toString() +
                                Account.NotificationProcess.SendMoney.toString()
                            ]
                          }
                        </HelperCheckBox>
                      </HelpTextWrapper>
                    </NoBorderCell>
                  </CustomRow>
                  <CustomRow>
                    <NoBorderSubTitleCell>
                      <TitleCell>{translate('customers.receiveMoney')}</TitleCell>
                    </NoBorderSubTitleCell>
                    <NoBorderCell>
                      <HelpTextWrapper>
                        <CheckboxWrapper>
                          <CheckBox
                            id="receiveMoneyMail"
                            disabled={
                              this.props.person != null &&
                              this.props.person.state === Ident.PersonStateEnum.C
                            }
                            value={this.getSingleNotificationSetting(
                              Account.NotificationGroupGroupEnum.Account,
                              NotificationProcess.ReceiveMoney,
                              NotificationChannel.Email,
                            )}
                            onChange={value =>
                              this.onChangeSetting({
                                enabled: value,
                                process: Account.NotificationProcess.ReceiveMoney,
                                channel: Account.NotificationChannel.E,
                              })
                            }
                          />
                        </CheckboxWrapper>
                        <HelperCheckBox>
                          {
                            this.state.success[
                              Account.NotificationChannel.E.toString() +
                                Account.NotificationProcess.ReceiveMoney.toString()
                            ]
                          }
                        </HelperCheckBox>
                      </HelpTextWrapper>
                    </NoBorderCell>
                  </CustomRow>
                  <CustomRow>
                    <NoBorderSubTitleCell>
                      <TitleCell>{translate('customers.requestMoney')}</TitleCell>
                    </NoBorderSubTitleCell>
                    <NoBorderCell>
                      <HelpTextWrapper>
                        <CheckboxWrapper>
                          <CheckBox
                            id="requestMoneyMail"
                            disabled={
                              this.props.person != null &&
                              this.props.person.state === Ident.PersonStateEnum.C
                            }
                            value={this.getSingleNotificationSetting(
                              Account.NotificationGroupGroupEnum.Friends,
                              NotificationProcess.RequestMoney,
                              NotificationChannel.Email,
                            )}
                            onChange={value =>
                              this.onChangeSetting({
                                enabled: value,
                                process: Account.NotificationProcess.RequestMoney,
                                channel: Account.NotificationChannel.E,
                              })
                            }
                          />
                        </CheckboxWrapper>
                        <HelperCheckBox>
                          {
                            this.state.success[
                              Account.NotificationChannel.E.toString() +
                                Account.NotificationProcess.RequestMoney.toString()
                            ]
                          }
                        </HelperCheckBox>
                      </HelpTextWrapper>
                    </NoBorderCell>
                  </CustomRow>
                  <CustomRow>
                    <NoBorderSubTitleCell>
                      <TitleCell>{translate('customers.takeOver')}</TitleCell>
                    </NoBorderSubTitleCell>
                    <NoBorderCell>
                      <HelpTextWrapper>
                        <CheckboxWrapper>
                          <CheckBox
                            id="takeOverMail"
                            disabled={
                              this.props.person != null &&
                              this.props.person.state === Ident.PersonStateEnum.C
                            }
                            value={this.getSingleNotificationSetting(
                              Account.NotificationGroupGroupEnum.Friends,
                              NotificationProcess.TakeOver,
                              NotificationChannel.Email,
                            )}
                            onChange={value =>
                              this.onChangeSetting({
                                enabled: value,
                                process: Account.NotificationProcess.TakeOver,
                                channel: Account.NotificationChannel.E,
                              })
                            }
                          />
                        </CheckboxWrapper>
                        <HelperCheckBox>
                          {
                            this.state.success[
                              Account.NotificationChannel.E.toString() +
                                Account.NotificationProcess.TakeOver.toString()
                            ]
                          }
                        </HelperCheckBox>
                      </HelpTextWrapper>
                    </NoBorderCell>
                  </CustomRow>
                  <CustomRow>
                    <NoBorderSubTitleCell>
                      <TitleCell>{translate('customers.splitMoney')}</TitleCell>
                    </NoBorderSubTitleCell>
                    <NoBorderCell>
                      <HelpTextWrapper>
                        <CheckboxWrapper>
                          <CheckBox
                            id="splitMoneyMail"
                            disabled={
                              this.props.person != null &&
                              this.props.person.state === Ident.PersonStateEnum.C
                            }
                            value={this.getSingleNotificationSetting(
                              Account.NotificationGroupGroupEnum.Friends,
                              NotificationProcess.SplitMoney,
                              NotificationChannel.Email,
                            )}
                            onChange={value =>
                              this.onChangeSetting({
                                enabled: value,
                                process: Account.NotificationProcess.SplitMoney,
                                channel: Account.NotificationChannel.E,
                              })
                            }
                          />
                        </CheckboxWrapper>
                        <HelperCheckBox>
                          {
                            this.state.success[
                              Account.NotificationChannel.E.toString() +
                                Account.NotificationProcess.SplitMoney.toString()
                            ]
                          }
                        </HelperCheckBox>
                      </HelpTextWrapper>
                    </NoBorderCell>
                  </CustomRow>
                </tbody>
              </Table>
            </MailBox>
          </div>
          <div style={{ width: '45%' }}>
            <div style={{ maxWidth: '600px', width: '100%', marginTop: '32px' }}>
              <MainTitleCell style={{ fontSize: '14px', color: '#98a9bc' }}>
                {translate('customers.languageSettings')}
              </MainTitleCell>
            </div>
            <Table style={{ maxWidth: '1000px', borderSpacing: '0' }}>
              <tbody>
                <CustomRow>
                  <CustomCell>
                    <MainTitleCell>{translate('customers.language')}</MainTitleCell>
                  </CustomCell>
                  <CustomCell colSpan={3} style={{ textAlign: 'right' }}>
                    {this.state.languageSettings != null
                      ? translate(
                          'usermenu.language.' + this.state.languageSettings.language,
                          this.state.languageSettings.language,
                        )
                      : null}
                  </CustomCell>
                </CustomRow>
                <CustomRow>
                  <CustomCell>
                    <MainTitleCell>
                      {translate('customers.details.merchant.timezone')}
                    </MainTitleCell>
                  </CustomCell>
                  <CustomCell colSpan={3} style={{ textAlign: 'right' }}>
                    {this.state.languageSettings != null
                      ? this.state.languageSettings.timezone
                      : null}
                  </CustomCell>
                </CustomRow>
              </tbody>
            </Table>
          </div>
        </StyledViewMain>
      </StyledContentBox>
    );
  }
}

export default withPersonAndAccount(CustomerDetailsPreferences);
