// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Sex,
} from './index';

/**
 * @export
 * @interface PersonUpdateRequest
 */
export interface PersonUpdateRequest {
    /**
     * @type {Sex}
     * @memberof PersonUpdateRequest
     */
    sex?: Sex;
    /**
     * the persons birth date to change
     * @type {Date}
     * @memberof PersonUpdateRequest
     */
    birth_date?: Date;
    /**
     * City or municipality
     * @type {string}
     * @memberof PersonUpdateRequest
     */
    birth_city?: string;
    /**
     * indicates if the pertson is an us person
     * @type {boolean}
     * @memberof PersonUpdateRequest
     */
    us_person?: boolean;
    /**
     * indicates if the pertson is at least_18 years or older
     * @type {boolean}
     * @memberof PersonUpdateRequest
     */
    age_check?: boolean;
    /**
     * person claims to act on own behalf
     * @type {boolean}
     * @memberof PersonUpdateRequest
     */
    own_behalf?: boolean;
    /**
     * indicates if the person is non resident
     * @type {boolean}
     * @memberof PersonUpdateRequest
     */
    resident?: boolean;
    /**
     * indicates if the person is pep
     * @type {boolean}
     * @memberof PersonUpdateRequest
     */
    pep?: boolean;
    /**
     * indicates if the person is fatca
     * @type {boolean}
     * @memberof PersonUpdateRequest
     */
    fatca?: boolean;
    /**
     * @type {string}
     * @memberof PersonUpdateRequest
     */
    name?: string;
    /**
     * @type {string}
     * @memberof PersonUpdateRequest
     */
    given_name?: string;
    /**
     * @type {string}
     * @memberof PersonUpdateRequest
     */
    user_id?: string;
    /**
     * @type {string}
     * @memberof PersonUpdateRequest
     */
    user_name?: string;
    /**
     * @type {string}
     * @memberof PersonUpdateRequest
     */
    family_relation?: PersonUpdateRequestFamilyRelationEnum;
    /**
     * @type {boolean}
     * @memberof PersonUpdateRequest
     */
    is_staff?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum PersonUpdateRequestFamilyRelationEnum {
    Junior = 'junior',
    FamilyMember = 'family_member',
    Pending = 'pending'
}

