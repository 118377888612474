import React, { ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Icons } from '../../../images';
import TouchableOpacity from '../../atomiccompoents/buttons/touchableOpacity';
import {
  OverlayHandler,
  Overlays,
} from '../../../logic/handler/overlayhandler/overlayHandler';
import { translate } from '../../../common/language/translate';
import { IImageData } from '../../../content/dashboard/content/customers/customerDetailsInterfaces';

interface IProps {
  callback: (imageId: number, docType: string) => void;
  images: Array<IImageData>;
}

interface IState {}

export default class ImageSelectComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  reviveState() {}

  render(): ReactNode {
    return (
      <Main>
        <TouchableOpacity
          onClick={(event, domRect) => {
            event.preventDefault();
            event.stopPropagation();
            OverlayHandler.showOverlay(Overlays.imageSelectOverlay, {
              posX: domRect == null ? 0 : domRect.x - 2,
              posY: domRect == null ? 0 : domRect.y + 42,
              images: this.props.images,
              callback: this.props.callback,
            });
          }}
        >
          <Main>
            <div
              onClick={(event: SyntheticEvent) => {
                if (this.props.images.length > 0) {
                  const fileTypeSplit = this.props.images[0].name.split('.');
                  this.props.callback(
                    this.props.images[0].id,
                    fileTypeSplit[fileTypeSplit.length - 1],
                  );
                }
              }}
              style={{ marginRight: '15px', marginTop: '6px' }}
            >
              {Icons.collection()}{' '}
            </div>
            <Wrapper title={this.props.images.length > 0 ? this.props.images[0].name : ''}>
              {this.props.images.length > 0
                ? this.props.images[0].name
                : translate('customers.noDocuments')}
            </Wrapper>
          </Main>
        </TouchableOpacity>
      </Main>
    );
  }
}
const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
  @media (max-width: 1830px) {
    max-width: 150px;
  }
`;
