import React, { ReactElement, SyntheticEvent } from 'react';
import {
  StyledLine,
  StyledOpacityEntry,
  StyledViewEntryText,
  StyledUserIcon,
} from '../content/dashboard/content/backoffice/basicStyledComponents/styled';
import { Icons } from '../images';

interface IProps {
  title: string;
  subTitle: string;
  image?: ReactElement;
  onClick: (event: SyntheticEvent) => void;
  entity?: string;
}

interface IState {}

export class SearchResultItem extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <div>
        <StyledOpacityEntry onClick={this.props.onClick}>
          <StyledViewEntryText>
            <div>{this.props.title}</div>
            <div>{this.props.subTitle}</div>
            <div>{this.props.entity}</div>
          </StyledViewEntryText>
        </StyledOpacityEntry>
        <StyledLine />
      </div>
    );
  }
}
