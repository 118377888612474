import React, { ReactNode } from 'react';
import { translate } from '../../common/language/translate';
import { Ident, api } from '../../logic/api';
import {
  INotifyAbleUserData,
  INotifyAbleLogoutTime,
  LogoutTimerStore,
  UserDataStore,
} from '../../logic/flux';
import { Log, Logs } from '../../logic/log';
import styled from 'styled-components';
import { OverlayHandler, Overlays } from '../../logic/handler/overlayhandler/overlayHandler';
import { Config } from '../../config';

interface IProps {
  format?: string;
  onExpire?: () => void;
  showTimer?: boolean;
}

interface IState {
  logoutTime?: number; // Idle logout time in seconds
  user?: Ident.Person;
}

export class LogoutTimer extends React.Component<IProps, IState>
  implements INotifyAbleUserData, INotifyAbleLogoutTime {
  private interval: number | undefined;
  private lastLogout: string | undefined = undefined;

  constructor(props: IProps) {
    super(props);

    this.state = {};

    this._onChangeLogoutTime = this._onChangeLogoutTime.bind(this);
    this._onChangeUserData = this._onChangeUserData.bind(this);
    if (window.sessionStorage.getItem('logout') === 'true') {
      window.sessionStorage.removeItem('logout');
      api.logout().then(() => {
        window.location.reload();
      });
    }
  }

  _onChangeLogoutTime(): void {
    this.setState({
      logoutTime: LogoutTimerStore.getSecondsUntilLogout(),
    });
  }

  _onChangeUserData(): void {
    this.setState({
      user: UserDataStore.getUser(),
    });
  }

  componentDidMount(): void {
    LogoutTimerStore.addChangeListener(this._onChangeLogoutTime);
    this._onChangeLogoutTime();
    UserDataStore.addChangeListener(this._onChangeUserData);
    this._onChangeUserData();

    this.interval = window.setInterval(() => {
      const logoutTime: number | undefined = this.state.logoutTime;
      if (logoutTime == null) {
        return;
      }

      this.setState({
        logoutTime: Math.max(logoutTime - 1, 0),
      });
      if (logoutTime - 1 <= 0) {
        clearInterval(this.interval);
        if (this.props.onExpire != null) {
          this.props.onExpire();
        }
        if (this.state.user != null) {
          if (Config.logOutConfirm === true) {
            window.sessionStorage.setItem('logout', 'true');
            OverlayHandler.showOverlay(Overlays.warningOverlay, {
              doSelfClose: false,
              confirm: () => {
                window.sessionStorage.removeItem('logout');
                api.logout().then(() => {
                  window.location.reload();
                  OverlayHandler.closeSpecific(Overlays.warningOverlay);
                });
              },
              heading: translate('logOut.heading'),
              message: translate('logOut.message'),
            });
          } else {
            api.logout();
          }
        }
      }
    }, 1000);
  }

  componentWillUnmount(): void {
    clearInterval(this.interval);
    LogoutTimerStore.removeChangeListener(this._onChangeLogoutTime);
    UserDataStore.removeChangeListener(this._onChangeUserData);
  }

  private formatTime(
    seconds: number | undefined,
    format: string = '%cm',
    hideWhenZero: boolean = false,
  ): string {
    if (seconds == null || (seconds <= 0 && hideWhenZero === true)) {
      return '';
    }

    const minutes: number = Math.floor((seconds + 1) / 60);
    const ceiledMinutes: number = Math.ceil((seconds + 1) / 60);
    const secs = Math.round((seconds + 1) % 60);
    const mm: string = minutes.toString().length === 1 ? '0' + minutes : minutes.toString();
    const ss: string = secs.toString().length === 1 ? '0' + secs : secs.toString();

    const formatted = format
      .replace('%mm', mm)
      .replace('%ss', ss)
      .replace('%cm', ceiledMinutes.toString())
      .replace('%m', minutes.toString())
      .replace('%s', secs.toString());
    const debugTime = '%m.%ss'.replace('%m', minutes.toString()).replace('%ss', ss);

    if (debugTime !== this.lastLogout) {
      if (secs % 15 === 0) {
        Log.debug(Logs.SYSTEM, 'Logout in: ' + debugTime);
      }
      this.lastLogout = debugTime;
    }

    return formatted;
  }

  render(): ReactNode {
    if (this.state.user == null) {
      return (
        <span>
          {this.props.showTimer !== false
            ? this.formatTime(this.state.logoutTime, ' ( %m:%ss )', true)
            : ''}
        </span>
      );
    } else {
      return (
        <StyledText>
          {translate('usermenu.logoutTimer', '', {
            minutes: this.formatTime(this.state.logoutTime),
          })}
        </StyledText>
      );
    }
  }
}

const StyledText = styled.span`
  display: block;
  padding: 0 15px 0 15px;
  max-width: 225px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
`;
