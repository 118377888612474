import { ReactNode, SyntheticEvent } from 'react';
import {
  IInitProps,
  IInitState,
  InitialDataComponent,
} from '../../../logic/handler/initialdatahandler/initialDataComponent';

export interface IOverlayComponentProps extends IInitProps {
  width?: string;
  height?: string;
  selfClose: () => void;
}

export interface IOverlayComponentState extends IInitState {
  width?: string;
  height?: string;
}

export abstract class OverlayComponent<
  P extends IOverlayComponentProps,
  S extends IOverlayComponentState
> extends InitialDataComponent<P, S> {
  abstract defineContent(): ReactNode;

  handleClick(event: SyntheticEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  abstract isCentered(): boolean;
}
