import React from 'react';
import { GenericElementType } from './IGenericElement';

export interface IGenericElementProps {
  title: string;
  type: GenericElementType;
  validator?: RegExp;
  data?: Array<any>;

  id: string;
  index: number;
  requestBodyUuid: string;
  isRequired?: boolean;
  showError?: boolean;
  errorMessage?: string;
  notification: (
    requestBodyUuid: string,
    value: any,
    index?: number,
    hasError?: boolean,
  ) => void;
}

export enum showErrorModeEnum {
  whileTyping = 'whileTyping',
  onSubmit = 'onSubmit',
}

export interface IGenericElementState {
  showErrorMode: showErrorModeEnum;
  error?: string | undefined;
}

export default abstract class AGenericElement<
  P extends IGenericElementProps,
  S extends IGenericElementState
> extends React.Component<P, S> {}
