//@ts-nocheck
import React, { ReactElement, ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { UserDataStore } from '../../logic/flux/stores/userdataStore';
import { INotifyAbleUserData } from '../../logic/flux/notifyAbles/notifyAbleUserData';
import { Person } from '../../logic/api/ident';
import { INotifyAbleUserImage, UserImageStore, Actions } from '../../logic/flux';
import { translate } from '../../common/language/translate';
import { format } from '../../logic/helper/format';
import { Ident, ApiError, api, apis } from '../../logic/api';
import { Log, Logs } from '../../logic/log';
import i18next from 'i18next';
import { IOverlayNotification } from './IOverlayNotification';
import { Icons } from '../../images';
import {
  IPropsOver,
  IStateOver,
  AOverlay,
} from '../../logic/handler/overlayhandler/globaloverlays/aOverlay';
import ButtonOk from '../atomiccompoents/buttons/buttonOk';
import Switch from '../atomiccompoents/switch';
import { DeveloperStore } from '../../logic/flux/stores/DeveloperStore';
import { getUserImageOrPlaceholder } from '../../logic/helper/Common';
import { OverlayHandler, Overlays } from '../../logic/handler/overlayhandler/overlayHandler';
import { MessageHandler } from '../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../logic/handler/messagehandler/messageHandlerConfig';

export interface IMenuEntry {
  action?: () => void;
  actionJson?: string;
  subMenu?: Array<IMenuEntry>;
  title: string;
  switch?: boolean;
  gotoSubComponent?: boolean;
  subComponent?: ReactNode;
}

interface IProps extends IPropsOver {
  toggleComponents?: ReactElement[];
  toggleOverlay?: (overlay: IOverlayNotification) => void;
  menuEntries: Array<IMenuEntry>;
  showMetaBox?: boolean;
}

interface IState extends IStateOver {
  currentComponent: number;
  menuEntries: Array<IMenuEntry>;
  isSubmenu?: boolean;
  touchID: boolean;
  userImage?: Blob;
  user?: Ident.Person;
  showComponent?: boolean;
  subComponent?: ReactNode;
  language?: string;
}

export class UserMenu extends AOverlay<IProps, IState>
  implements INotifyAbleUserData, INotifyAbleUserImage {
  private person: Person | null = null;

  constructor(props: IProps) {
    super(props);
    this.state = {
      currentComponent: 0,
      menuEntries: props.menuEntries,
      isSubmenu: false,
      touchID: false,
    };

    this._onChangeUserData = this._onChangeUserData.bind(this);
    this._onChangeUserImage = this._onChangeUserImage.bind(this);
    this.touchIDChange = this.touchIDChange.bind(this);
    this.hideComponent = this.hideComponent.bind(this);
    this._onLanguageChanged = this._onLanguageChanged.bind(this);
  }

  _onChangeUserData() {
    this.setState({
      currentComponent: UserDataStore.getMenuComponent(),
      user: UserDataStore.getUser(),
    });
  }

  _onChangeUserImage() {
    this.setState({
      userImage: UserImageStore.getUserImage(),
    });
  }

  componentDidMount() {
    UserDataStore.addChangeListener(this._onChangeUserData);
    UserImageStore.addChangeListener(this._onChangeUserImage);
    this._onChangeUserData();
    this._onChangeUserImage();
    i18next.on('languageChanged', this._onLanguageChanged);
  }

  componentWillUnmount() {
    UserDataStore.removeChangeListener(this._onChangeUserData);
    UserImageStore.removeChangeListener(this._onChangeUserImage);
    i18next.off('languageChanged', this._onLanguageChanged);
  }

  _onLanguageChanged(lng: string): void {
    this.setState({
      language: lng,
    });
  }

  private touchIDChange(): void {
    this.setState({ touchID: !this.state.touchID });
  }

  reviveState(): void {}

  private showSubmenu(submenu?: Array<IMenuEntry>): void {
    if (submenu == null) {
      return;
    }
    this.setState({ menuEntries: submenu, isSubmenu: true });
  }

  private showParentMenu(): void {
    this.setState({ menuEntries: this.props.menuEntries, isSubmenu: false });
  }

  private showComponent(subComponent: ReactNode): void {
    this.setState({
      showComponent: true,
      subComponent: subComponent,
    });
  }

  private hideComponent(): void {
    this.setState({ showComponent: false });
  }

  private logOut(event: SyntheticEvent) {
    event.persist();
    event.stopPropagation();
    event.preventDefault();
    api
      .logout()
      .then(() => {
        window.location.reload();
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error.statusText);
      });
  }

  private stopProp(event: SyntheticEvent) {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
  }

  uploadUserImage(file: any) {
    const req = {
      face_image: file,
    };
    api
      .asyncRequest<any>(req, apis.SelfServiceApi, 'selfFacePost')
      .then(() => {
        api
          .asyncRequest<any>(req, apis.SelfServiceApi, 'selfFaceGetCurrent')
          .then((response: Blob) => {
            //TODO: Delete old images after api-interface is prepared accordingly
            Actions.userImageChanged(response);
            MessageHandler.onSuccess(Reporter['user.profile.picture.request']);
          })
          .catch(() => {
            MessageHandler.onError(Reporter['user.profile.picture.request']);
          });
      })
      .catch(() => {
        MessageHandler.onError(Reporter['user.profile.picture.request']);
      });
  }

  defineContent() {
    const userImage = getUserImageOrPlaceholder(this.state.userImage);
    return (
      <UserMenuBox onClick={this.stopProp}>
        <Header>{translate('usermenu.settings')}</Header>
        <UserInfo
          onClick={() => {
            OverlayHandler.showOverlay(Overlays.fileChooserOverlay, {
              callback: (file: any) => {
                this.uploadUserImage(file);
              },
              title: translate('usermenu.selectImage'),
            });
          }}
        >
          <DivWrapper
            onDragStart={(e: React.DragEvent<HTMLDivElement>) => {
              DeveloperStore.setDraggingUserImage(true);
              e.dataTransfer.setData(
                this.state.userImage == null ? 'image/svg' : 'image/*',
                userImage == null ? '' : userImage.toString(),
              );
            }}
            onDragEnd={() => DeveloperStore.setDraggingUserImage(false)}
            draggable
          >
            {userImage}
          </DivWrapper>
        </UserInfo>
        <UserName>
          {this.state.user == null
            ? ''
            : format.nameByParts(
                this.state.user.given_name,
                this.state.user.name,
                this.state.user.primary_email_address,
              )}
        </UserName>
        <ButtonView onClick={this.stopProp}>
          <ButtonOk id="btnLogout" style={{ width: '150px' }} onClick={this.logOut}>
            {translate('usermenu.logout')}
          </ButtonOk>
        </ButtonView>
        {this.props.showMetaBox !== false ? (
          <MetaBox>
            <MetaTable>
              <MetaRow>
                <span>{translate('usermenu.membersince')} </span>
                <span>
                  {this.state.user == null
                    ? ''
                    : format.datetime(this.state.user.datetime_update)}
                </span>
              </MetaRow>
              <MetaRow>
                <span>{translate('usermenu.entity')} </span>
                <span>{this.state.user == null ? '' : this.state.user.entity}</span>
              </MetaRow>
            </MetaTable>
          </MetaBox>
        ) : null}

        {this.state.showComponent === true ? (
          <StyledViewSubcomponent>
            {this.state.subComponent != null && React.isValidElement(this.state.subComponent)
              ? React.cloneElement(this.state.subComponent, {
                  notification: this.hideComponent,
                })
              : null}
          </StyledViewSubcomponent>
        ) : (
          <StyledView>
            <MenuBox>
              {this.state.isSubmenu === true ? (
                <Frame onClick={() => this.showParentMenu()}>
                  <TextLink> {'←'}</TextLink>
                </Frame>
              ) : null}

              {this.state.menuEntries.map((entry: IMenuEntry) => {
                if (entry.subMenu != null) {
                  return (
                    <Frame onClick={() => this.showSubmenu(entry.subMenu)}>
                      <TextLink key={entry.title}>{translate(entry.title)} </TextLink>
                      {Icons.expandArrow()}
                    </Frame>
                  );
                } else if (entry.action != null) {
                  return (
                    <Frame onClick={entry.action}>
                      <TextLink key={entry.title}>{translate(entry.title)}</TextLink>
                    </Frame>
                  );
                } else if (entry.switch != null) {
                  return (
                    <Frame>
                      <TextLink key={entry.title}>{translate(entry.title)} </TextLink>{' '}
                      <InlineSwitch
                        id={'touchIdSwitch'}
                        defaultValue={this.state.touchID}
                        onChange={this.touchIDChange}
                      />
                    </Frame>
                  );
                } else if (entry.gotoSubComponent === true) {
                  return (
                    <Frame onClick={() => this.showComponent(entry.subComponent)}>
                      <TextLink key={entry.title}>{translate(entry.title)}</TextLink>
                    </Frame>
                  );
                } else {
                  return null;
                }
              })}
            </MenuBox>
          </StyledView>
        )}
      </UserMenuBox>
    );
  }
}
// <SwitchWrapper><InlineSwitch thumbColor="${props => props.theme.primaryColor}" value={this.state.touchID} onValueChange={this.touchIDChange}/></SwitchWrapper>

const UserMenuBox = styled.div`
  position: fixed;
  width: 352px;
  top: 64px;
  height: calc(100vh - 112px);
  right: 0px;
  z-index: 170;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
`;

const UserInfo = styled.div`
  width: 100%;
  height: 120px;
  background-color: white;
  overflow: hidden;
  box-sizing: border-box;
  line-height: 80px;
  font-size: 14px;
  color: #98a9bc;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;
const Header = styled.span`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  font-family: Roboto;
  font-size: 24px;
  line-height: 28px;
  color: black;
  margin-top: 50px;
  margin-bottom: 15px;
`;
const UserName = styled.span`
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: ${props => props.theme.Global.lightFontColor}
	text-align: center;
`;

const ButtonView = styled.div`
  margin-bottom: 15px;
  text-align: center;
  height: 50px;
  color: #ffffff;
  line-height: 35px;
  text-align: center;
  vertical-align: middle;
`;
const MetaBox = styled.div`
  width: 312px;

  padding: 12px;

  background: #fafafa;
  border-radius: 10px;
`;
const MetaTable = styled.div`
  width: 100%;

  td {
    line-height: 24px;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.Global.darkFontColor};
  }
`;
const MetaRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  & + & {
    margin-top: 8px;
  }

  span:last-child {
    flex-direction: row;
    flex-grow: 1;
    text-align: right;
  }
`;

const DivWrapper = styled.div`
  height: 110px;
  width: 110px;
  flex-direction: row;
  justify-content: center;
  margin: auto;

  & > img,
  & > svg {
    border-radius: 50%;
    height: 110px;
    width: 110px;
  }
`;

const StyledView = styled.div`
  width: 100%;
  background: white;
  align-items: left;
  margin-top: 15px;
  margin-left: 35px;
`;

const InlineSwitch = styled(Switch)`
  display: inline;
  margin-left: 265px;
  margin-top: -25px;
`;
const StyledViewSubcomponent = styled.div`
  width: 100%;
`;
const Frame = styled.div`
  display: inline;
  margin-left: 0px;
  margin-top: 20px;
  padding: 0px;
  cursor: pointer;

  height: 21px;
  line-height: 21px;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`;
const MenuBox = styled.div`
  display: block;
  margin-top: 10px;
  background-color: #ffffff;
  text-align: left;
`;
const TextLink = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: 'red';
  width: 318px;
  display: inline-block;
  cursor: pointer;
`;
