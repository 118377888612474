import React, { ReactElement } from 'react';
import { Account, api, apis, ApiError, Ident } from '../../../../logic/api';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { Log, Logs } from '../../../../logic/log';
import { Reporter } from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { RowType } from '../../../../components/compositcomponents/table/tableTypes';
import { Container, ContainerText } from '../transfer/transfersComponent.css';
import { translate } from '../../../../common/language/translate';
import { Table } from '../../../../components/compositcomponents/table/table';
import styled from 'styled-components';
import { PagingComponent } from '../../../../components/paging';
import { format } from '../../../../logic/helper/format';
import { CheckBox } from '../../../../components/atomiccompoents/form/checkbox';
import { CustomerDataStore, PermissionStore } from '../../../../logic/flux';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import ApprovalDetails from './approvalDetails';
import Title from '../../../../components/compositcomponents/title';
import { Icons } from '../../../../images';
import { IOption } from '../../../../components/atomiccompoents/form';
import { StyledSelect } from '../basicStyledComponents/customersComponent.css';
import {
  Helpertext,
  IconWrapper,
} from '../customers/basicStyledComponents/customerDetails.css';
import { compareDate, evaluateErrorMessage } from '../../../../logic/helper/Common';
import { IItfAccount } from '../../../../logic/types';
import AccountSelector from '../../../../components/accountSelector';

const Main = styled.div`
    display: flex,
    flex-direction: column;
    align-items: center;
`;
const PagingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const ButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  width: 100%;
`;
const CheckboxContainer = styled(Container)``;
const SplitViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const SplitView = styled.div`
  width: 50%;
  padding: 16px;
  height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
`;
const OptionBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  border-bottom: 1px solid #dedede;
  margin-bottom: 4px;
  margin-left: 16px;
`;

const CheckboxBox = styled(OptionBox)`
  width: 50%;
  padding: 8px;
  margin: 8px;
  margin-left: 16px;
  border: none;
  justify-content: flex-start;
`;

const DetailsTitleWrapper = styled.div`
  margin-left: 64px;
  margin-top: -4px;
  width: 100%;
  display: flex;
  align-items: center;
  svg {
    fill: ${props => props.theme.Global.primaryColor};
  }
`;

interface IApprovalData {
  source: 'c' | 'i';
  release_requests_id: number;
  datetime_create: Date;
  requested_by_person_id: number;
  requested_by_person_name?: string;
  requested_by_person_given_name?: string;
  requested_by_person_email?: string;
  client: string;
  status: Ident.ReleaseStatus | Account.ReleaseStatus;
  required_releases: number;
  release_count: number;
  type: Ident.ReleaseType | Account.ReleaseType;
  last_modified?: Date;
  last_modified_by_person_id?: number;
  last_modified_by_person_name?: string;
  last_modified_by_person_given_name?: string;
  last_modified_by_person_email?: string;
  operation_id?: string;
  account_numbers?: Array<string>;
  error?: string;
  person_id_cust?: number;
}

interface IProps {
  callback: (path: string) => void;
  isCustomerView?: boolean;
}

interface IState {
  approvalDataCore?: Array<IApprovalData>;
  approvalDataIdent?: Array<IApprovalData>;

  toApproveCore: { [key: string]: number };
  toApproveIdent: { [key: string]: number };

  selectedApproval?: number;
  selectedPersonId?: number;

  selectedAccounts?: Array<string>;
  selectedType?: 'c' | 'i';
  selectedError?: string;
  selectedApprover?: string;

  fetchReleaseStatuses: Array<Account.ReleaseStatus>;
  fetchReleaseTypes: Account.ReleaseType | Ident.ReleaseType | 'all';

  currAccount?: IItfAccount;
  person_id?: number;
}

class BackofficeApprovals extends React.Component<IProps, IState> {
  private approveTracker: Array<boolean> = [];
  private approveTrackerIdent: Array<boolean> = [];
  private tableRef: Table<IApprovalData> | null = null;
  converter(line: IApprovalData, index: number, fields: Array<string>): RowType {
    const row: RowType = { cells: [], ref: line };
    if (row.cells == null) {
      return row;
    }

    const keys: Array<string> = Object.keys(line);
    const releaseId: number = line.release_requests_id == null ? -1 : line.release_requests_id;
    for (let i = 0; i < fields.length; i++) {
      const field: string = fields[i];
      switch (field) {
        case 'release_requests_id': {
          row.cells.push({
            value: line.release_requests_id,
            display: (
              <CheckboxContainer
                style={{
                  justifyContent: 'center',
                  width: '100%',
                  display: 'flex',
                }}
              >
                <CheckBox
                  id={line.release_requests_id.toString()}
                  disabled={!PermissionStore.hasPermission(Account.OperationId.ReleasesPut)}
                  value={
                    line.source === 'i'
                      ? this.state.toApproveIdent[line.release_requests_id.toString()] != null
                      : this.state.toApproveCore[line.release_requests_id.toString()] != null
                  }
                  onChange={(value: boolean) =>
                    this.setToApprove(
                      line.release_requests_id,
                      value,
                      line.source,
                      line.error,
                      line.account_numbers,
                      line.person_id_cust,
                      line.status === 'R' ? (
                        line.last_modified_by_person_given_name + ' ' + line.last_modified_by_person_name
                      ) : null,
                    )
                  }
                />
              </CheckboxContainer>
            ),
            copyVal: line.release_requests_id.toString(),
            methods: {
              approvalCallback: () =>
                this.doAction(
                  line.source === 'i' ? Ident.ReleaseAction.R : Account.ReleaseAction.R,
                  releaseId,
                  true,
                  line.source === 'i' ? apis.ReleaseApiIdent : apis.ReleaseApi,
                ),
              cancelCallback: () =>
                this.doAction(
                  line.source === 'i' ? Ident.ReleaseAction.B : Account.ReleaseAction.B,
                  releaseId,
                  true,
                  line.source === 'i' ? apis.ReleaseApiIdent : apis.ReleaseApi,
                ),
            },
          });
          break;
        }
        case 'client': {
          row.cells.push({
            value: line.release_requests_id,
            display: (
              <Container
                onClick={() => {
                  this.setState({
                    selectedApproval: releaseId,
                    selectedType: line.source,
                    selectedError: line.error,
                    selectedAccounts: line.account_numbers,
                    selectedPersonId: line.person_id_cust,
                    selectedApprover: line.status === 'R' ? (
                      line.last_modified_by_person_given_name + ' ' + line.last_modified_by_person_name
                    ) : null,
                  });
                }}
              >
                <ContainerText title={line.release_requests_id.toString()}>
                  {line.release_requests_id}
                </ContainerText>
              </Container>
            ),
            copyVal: line.release_requests_id.toString(),
            methods: {
              approvalCallback: () =>
                this.doAction(
                  Account.ReleaseAction.R,
                  releaseId,
                  true,
                  line.source === 'i' ? apis.ReleaseApiIdent : apis.ReleaseApi,
                ),
              cancelCallback: () =>
                this.doAction(
                  Account.ReleaseAction.B,
                  releaseId,
                  true,
                  line.source === 'i' ? apis.ReleaseApiIdent : apis.ReleaseApi,
                ),
            },
          });
          break;
        }
        case 'status': {
          const status = translate('backoffice.approvals.status.' + line.status, line.status);
          row.cells.push({
            value: status,
            display: (
              <Container
                onClick={() => {
                  this.setState({
                    selectedApproval: releaseId,
                    selectedType: line.source,
                    selectedError: line.error,
                    selectedAccounts: line.account_numbers,
                    selectedPersonId: line.person_id_cust,
                    selectedApprover: line.status === 'R' ? (
                      line.last_modified_by_person_given_name + ' ' + line.last_modified_by_person_name
                    ) : null,
                  });
                }}
              >
                <ContainerText title={status}>{status}</ContainerText>
              </Container>
            ),
            copyVal: status,
            methods: {
              approvalCallback: () =>
                this.doAction(
                  Account.ReleaseAction.R,
                  releaseId,
                  true,
                  line.source === 'i' ? apis.ReleaseApiIdent : apis.ReleaseApi,
                ),
              cancelCallback: () =>
                this.doAction(
                  Account.ReleaseAction.B,
                  releaseId,
                  true,
                  line.source === 'i' ? apis.ReleaseApiIdent : apis.ReleaseApi,
                ),
            },
          });
          break;
        }
        case 'requested_by_person_given_name': {
          const value =
            line.requested_by_person_given_name + ' ' + line.requested_by_person_name;
          row.cells.push({
            value: value,
            display: (
              <Container
                onClick={() => {
                  this.setState({
                    selectedApproval: releaseId,
                    selectedType: line.source,
                    selectedError: line.error,
                    selectedAccounts: line.account_numbers,
                    selectedPersonId: line.person_id_cust,
                    selectedApprover: line.status === 'R' ? (
                      line.last_modified_by_person_given_name + ' ' + line.last_modified_by_person_name
                    ) : null,
                  });
                }}
              >
                <ContainerText title={value}>{value}</ContainerText>
              </Container>
            ),
            copyVal: value,
            methods: {
              approvalCallback: () =>
                this.doAction(
                  Account.ReleaseAction.R,
                  releaseId,
                  true,
                  line.source === 'i' ? apis.ReleaseApiIdent : apis.ReleaseApi,
                ),
              cancelCallback: () =>
                this.doAction(
                  Account.ReleaseAction.B,
                  releaseId,
                  true,
                  line.source === 'i' ? apis.ReleaseApiIdent : apis.ReleaseApi,
                ),
            },
          });
          break;
        }
        case 'datetime_create':
        case 'last_modified': {
          const valueDate: string | undefined = format.date(line[field]);
          row.cells.push({
            value: valueDate == null ? '-' : valueDate,
            display: (
              <Container
                onClick={() => {
                  this.setState({
                    selectedApproval: releaseId,
                    selectedType: line.source,
                    selectedError: line.error,
                    selectedAccounts: line.account_numbers,
                    selectedPersonId: line.person_id_cust,
                    selectedApprover: line.status === 'R' ? (
                      line.last_modified_by_person_given_name + ' ' + line.last_modified_by_person_name
                    ) : null,
                  });
                }}
              >
                <ContainerText title={valueDate}>
                  {valueDate == null ? '-' : valueDate}
                </ContainerText>
              </Container>
            ),
            copyVal: valueDate != null ? valueDate.toString() : '-',
            methods: {
              approvalCallback: () =>
                this.doAction(
                  Account.ReleaseAction.R,
                  releaseId,
                  true,
                  line.source === 'i' ? apis.ReleaseApiIdent : apis.ReleaseApi,
                ),
              cancelCallback: () =>
                this.doAction(
                  Account.ReleaseAction.B,
                  releaseId,
                  true,
                  line.source === 'i' ? apis.ReleaseApiIdent : apis.ReleaseApi,
                ),
            },
          });
          break;
        }
        default: {
          if (Object.prototype.hasOwnProperty.call(line, field)) {
            row.cells.push({
              value: Object.values(line)[keys.indexOf(field)],
              display: (
                <Container
                  onClick={() => {
                    this.setState({
                      selectedApproval: releaseId,
                      selectedType: line.source,
                      selectedError: line.error,
                      selectedAccounts: line.account_numbers,
                      selectedPersonId: line.person_id_cust,
                      selectedApprover: line.status === 'R' ? (
                        line.last_modified_by_person_given_name + ' ' + line.last_modified_by_person_name
                      ) : null,
                    });
                  }}
                >
                  <ContainerText title={Object.values(line)[keys.indexOf(field)]}>
                    {Object.values(line)[keys.indexOf(field)]}
                  </ContainerText>
                </Container>
              ),
              copyVal: Object.values(line)[keys.indexOf(field)],
              methods: {
                approvalCallback: () =>
                  this.doAction(
                    Account.ReleaseAction.R,
                    releaseId,
                    true,
                    line.source === 'i' ? apis.ReleaseApiIdent : apis.ReleaseApi,
                  ),
                cancelCallback: () =>
                  this.doAction(
                    Account.ReleaseAction.B,
                    releaseId,
                    true,
                    line.source === 'i' ? apis.ReleaseApiIdent : apis.ReleaseApi,
                  ),
              },
            });
          } else {
            row.cells.push({ value: '' });
          }
          break;
        }
      }
    }

    return row;
  }
  private resetIndex = false;

  constructor(props: IProps) {
    super(props);
    this.state = {
      toApproveCore: {},
      toApproveIdent: {},
      fetchReleaseStatuses: [
        Account.ReleaseStatus.N,
        Account.ReleaseStatus.C,
        Account.ReleaseStatus.W,
      ],
      fetchReleaseTypes: 'all',
    };
    this.converter = this.converter.bind(this);
    this.doAction = this.doAction.bind(this);
    this.setUpStatusCheckboxes = this.setUpStatusCheckboxes.bind(this);
    this.fetchAllReleases = this.fetchAllReleases.bind(this);
    this.handleStatusSelect = this.handleStatusSelect.bind(this);
    this.handleTypeSelect = this.handleTypeSelect.bind(this);
    this.setTableRef = this.setTableRef.bind(this);
    this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
  }

  componentWillUnmount() {
    CustomerDataStore.removeChangeListener(this._onChangeCustomerData);
  }

  componentDidMount() {
    if (this.props.isCustomerView === true) {
      CustomerDataStore.addChangeListener(this._onChangeCustomerData);
      this._onChangeCustomerData();
    } else {
      this.fetchAllReleases();
    }
  }

  _onChangeCustomerData() {
    const tempAccount = CustomerDataStore.getCurrentAccount();
    const tempPers = CustomerDataStore.getUser();
    this.setState(
      {
        currAccount: tempAccount,
        person_id: tempPers != null ? tempPers.person_id : undefined,
      },
      () => this.fetchAllReleases(),
    );
  }

  doAction(
    action: Account.ReleaseAction | Ident.ReleaseAction,
    releaseId: number,
    directFetch: boolean,
    apiType: apis.ReleaseApi | apis.ReleaseApiIdent,
  ) {
    const req: Account.ReleasesPutRequest = {
      ReleasePut: {
        release_action: action,
        release_request_id: releaseId,
      },
    };
    api
      .asyncRequest<any>(req, apiType, 'releasesPut')
      .then((response: Account.ReleaseTriggered | Ident.ReleaseTriggered) => {
        if (response != null) {
          if (directFetch !== false) {
            //directly show outcome of request
            if (response.release != null && response.release.status.toString() === 'C') {
              //  //successfully canceled
              MessageHandler.onSuccess(Reporter['backoffice.relaeses.put.cancel']);
              this.fetchAllReleases();
            } else if (response.triggered_state === Account.ReleaseTriggerState.S) {
              MessageHandler.onSuccess(Reporter['backoffice.relaeses.put']);
              this.fetchAllReleases();
            } else if (response.triggered_state === Account.ReleaseTriggerState.N) {
              MessageHandler.onSuccess(Reporter['backoffice.relaeses.put.new_release']);
            } else {
              MessageHandler.onSuccess(
                Reporter['backoffice.relaeses.put.trigger'],
                //@ts-ignore
                'Approved but:' + response.triggered_event?.error_text,
              );
            }
          }
        }
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
        MessageHandler.onError(
          Reporter['backoffice.relaeses.put'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
      });
  }

  doActionMult(
    action: Account.ReleaseAction | Ident.ReleaseAction,
    releaseId: number,
    apiType: apis.ReleaseApi | apis.ReleaseApiIdent,
  ) {
    const req: Account.ReleasesPutRequest = {
      ReleasePut: {
        release_action: action,
        release_request_id: releaseId,
      },
    };
    return api.asyncRequest<any>(req, apiType, 'releasesPut');
  }

  setToApprove(
    id: number,
    set: boolean,
    source: 'c' | 'i',
    error?: string,
    account_numbers?: Array<string>,
    person_id?: number,
    approver_name?: string,
  ) {
    const approve = source === 'c' ? this.state.toApproveCore : this.state.toApproveIdent;
    if (set) {
      approve[id.toString()] = id;
    } else {
      delete approve[id.toString()];
    }
    this.setState({
      toApproveCore: source === 'c' ? approve : this.state.toApproveCore,
      toApproveIdent: source === 'i' ? approve : this.state.toApproveIdent,
      selectedApproval: set ? id : this.state.selectedApproval,
      selectedType: set ? source : this.state.selectedType,
      selectedError: set && error != null ? error : this.state.selectedError,
      selectedAccounts:
        set && account_numbers != null ? account_numbers : this.state.selectedAccounts,
      selectedPersonId: person_id,
      selectedApprover: set ? approver_name : this.state.selectedApprover,
    });
  }

  handleAllSelected(status: Account.ReleaseAction | Ident.ReleaseAction) {
    const toApprove = this.state.toApproveCore;
    const keys = Object.keys(toApprove);

    const toApproveId = this.state.toApproveIdent;
    const keysident = Object.keys(toApproveId);

    if (keys.length > 0) {
      const promises = Object.entries(toApprove).map(keyValue =>
        this.doActionMult(status, keyValue[1], apis.ReleaseApi),
      );
      let combinedError: string | null = null;
      Promise.all(promises)
        .then(values => {
          let message: string = 'backoffice.relaeses.put';
          for (const o of values) {
            if (o.triggered_state === Account.ReleaseTriggerState.N) {
              message = 'backoffice.relaeses.put.retriggered.' + status.toString();
            } else if (o.triggered_state === Account.ReleaseTriggerState.E) {
              message = 'backoffice.relaeses.put.not_all';
              combinedError =
                combinedError == null
                  ? 'Approved but: ' + o.triggered_event.error_text
                  : combinedError + ' , ' + o.triggered_event.error_text;
            }
          }
          //@ts-ignore
          MessageHandler.onSuccess(Reporter[message], combinedError);
        })
        .catch(error => {
          Log.error(Logs.API, error);
          MessageHandler.onError(
            Reporter['backoffice.relaeses.put'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
        });
    }
    if (keysident.length > 0) {
      const promisesId = Object.entries(toApproveId).map(keyValue =>
        this.doActionMult(status, keyValue[1], apis.ReleaseApiIdent),
      );
      Promise.all(promisesId)
        .then(values => {
          let message: string = 'backoffice.relaeses.put.ident';
          for (const o of values) {
            if (o.triggered_state === Ident.ReleaseTriggerState.N) {
              message = 'backoffice.relaeses.put.retriggered.' + status.toString();
            } else if (o.triggered_state === Ident.ReleaseTriggerState.E) {
              message = 'backoffice.relaeses.put.not_all';
            }
          }
          //@ts-ignore
          MessageHandler.onSuccess(Reporter[message as keyof Reporter]);
        })
        .catch(error => {
          Log.error(Logs.API, error);
          MessageHandler.onError(
            Reporter['backoffice.relaeses.put'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
        });
    }
    this.setState(
      {
        toApproveCore: {},
        toApproveIdent: {},
      },
      () => {
        this.fetchAllReleases();
        this.approveTracker = [];
        this.approveTrackerIdent = [];
      },
    );
  }

  handleStatusSelect(value: boolean, status: Account.ReleaseStatus) {
    const statuses = this.state.fetchReleaseStatuses;
    const idx = statuses.indexOf(status);
    if (idx < 0 && value === true) {
      statuses.push(status);
      this.setState(
        {
          fetchReleaseStatuses: statuses,
        },
        () => this.fetchAllReleases(),
      );
    } else if (idx >= 0 && value === false) {
      const newStatus = [];
      for (const value in statuses) {
        if (statuses[value] !== status) {
          newStatus.push(statuses[value]);
        }
      }
      this.setState(
        {
          fetchReleaseStatuses: newStatus,
        },
        () => this.fetchAllReleases(),
      );
    }
  }

  setUpStatusCheckboxes(): Array<ReactElement> {
    const out = [];
    for (const value in Account.ReleaseStatus) {
      out.push(
        <CheckboxContainer
          style={{
            margin: '1px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            width: '60px',
          }}
        >
          <CheckBox
            id={'release_status'}
            disabled={!PermissionStore.hasPermission(Account.OperationId.ReleasesPut)}
            value={
              this.state.fetchReleaseStatuses.indexOf(value as Account.ReleaseStatus) >= 0
            }
            onChange={(valueBool: boolean) =>
              this.handleStatusSelect(valueBool, value as Account.ReleaseStatus)
            }
          />
          <Helpertext style={{ width: '90%', marginTop: '8px' }}>
            {translate('backoffice.approvals.status.' + value, value)}{' '}
          </Helpertext>
        </CheckboxContainer>,
      );
    }
    return out;
  }

  getTypeOptions() {
    const enumValues: Array<IOption> = [];
    enumValues.push({
      key: 'defaultOptionKey all',
      name: translate('backoffice.approvals.all', 'All'),
      value: 'all',
    });
    for (const value in Account.ReleaseType) {
      //@ts-ignore
      if (
        this.props.isCustomerView !== true &&
        Account.ReleaseType[value] === Account.ReleaseType._2fa.toString()
      ) {
        continue;
      }
      enumValues.push({
        key: 'defaultOptionKeyCore' + value,
        name: translate('backoffice.approvals.' + value, value),
        //@ts-ignore
        value: Account.ReleaseType[value],
      });
    }
    if (this.props.isCustomerView !== true) {
      for (const value in Ident.ReleaseType) {
        enumValues.push({
          key: 'defaultOptionKeyIdent' + value,
          name: translate('backoffice.approvals.' + value, value),
          //@ts-ignore
          value: Ident.ReleaseType[value],
        });
      }
    } else {
      enumValues.push({
        key: 'defaultOptionKeyIdentAdvancedKYC',
        name: translate('backoffice.approvals.advancedKYC', 'ApproveAdvancedKyc'),
        //@ts-ignore
        value: Ident.ReleaseType.ApproveAdvancedKyc,
      });
      enumValues.push({
        key: 'defaultOptionKeyIdentAdressUpdate',
        name: translate('backoffice.approvals.adressUpdate', 'AddressUpdate'),
        //@ts-ignore
        value: Ident.ReleaseType.AddressUpdate,
      });
      enumValues.push({
        key: 'defaultOptionKeyIdentAdressSave',
        name: translate('backoffice.approvals.adressSave', 'AddressSave'),
        //@ts-ignore
        value: Ident.ReleaseType.AddressSave,
      });
    }
    return enumValues;
  }

  handleTypeSelect(message: any) {
    this.resetIndex = true;
    this.setState(
      {
        fetchReleaseTypes: message.type,
      },
      () => {
        this.fetchAllReleases();
      },
    );
  }

  fetchAllReleases() {
    if (
      this.state.fetchReleaseTypes === 'all' ||
      this.state.fetchReleaseTypes === 'transaction' ||
      this.state.fetchReleaseTypes === 'reversal' ||
      this.state.fetchReleaseTypes === '2fa'
    ) {
      this.fetchCoreReleases();
    } else {
      this.fetchIdentReleases();
    }
  }
  fetchCoreReleases() {
    const req: Account.ReleasesGetRequest | Ident.ReleasesGetRequest = {
      release_status: this.state.fetchReleaseStatuses,
      release_type:
        this.state.fetchReleaseTypes === 'all' ? undefined : this.state.fetchReleaseTypes,
      qaccount_number:
        this.props.isCustomerView === true && this.state.currAccount != null
          ? this.state.currAccount.account_number
          : undefined,
    };
    api
      .asyncRequest<any>(req, apis.ReleaseApi, 'releasesGet')
      .then(response => {
        if (response != null) {
          const res = [];
          for (const i in response) {
            res.push({
              source: 'c',
              ...response[i],
            });
          }
          this.setState(
            {
              approvalDataCore: res,
              approvalDataIdent:
                this.state.fetchReleaseTypes === 'all' ? this.state.approvalDataIdent : [],
            },
            () => {
              if (this.state.fetchReleaseTypes === 'all') {
                this.fetchIdentReleases();
                this.resetIndex = false;
              } else {
                if (this.tableRef != null) {
                  this.tableRef.resetPaging();
                }
                this.resetIndex = false;
              }
            },
          );
        }
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
        MessageHandler.onError(
          Reporter['backoffice.relaeses.fetch'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
      });
  }

  fetchIdentReleases() {
    const req: Account.ReleasesGetRequest | Ident.ReleasesGetRequest = {
      release_status: this.state.fetchReleaseStatuses,
      release_type:
        this.state.fetchReleaseTypes === 'all' ? undefined : this.state.fetchReleaseTypes,
      qperson_id_cust:
        this.props.isCustomerView && this.state.person_id != null
          ? this.state.person_id
          : undefined,
    };
    api
      .asyncRequest<any>(req, apis.ReleaseApiIdent, 'releasesGet')
      .then(response => {
        if (response != null) {
          const res = [];
          for (const i in response) {
            res.push({
              source: 'i',
              ...response[i],
            });
          }
          this.setState(
            {
              approvalDataIdent: res,
              approvalDataCore:
                this.state.fetchReleaseTypes === 'all' ? this.state.approvalDataCore : [],
            },
            () => {
              this.resetIndex = false;
              if (this.tableRef != null) {
                this.tableRef.resetPaging(0);
              }
            },
          );
        }
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
        MessageHandler.onError(
          Reporter['backoffice.relaeses.fetch'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
      });
  }

  compareData(a: IApprovalData, b: IApprovalData): number {
    return -compareDate(a.datetime_create, b.datetime_create);
  }

  private setTableRef(element: Table<IApprovalData>): void {
    this.tableRef = element;
  }

  private setupSrcData(): Array<IApprovalData> {
    const cordData = this.state.approvalDataCore != null ? this.state.approvalDataCore : [];
    const identData = this.state.approvalDataIdent != null ? this.state.approvalDataIdent : [];
    const unsortedData: Array<IApprovalData> = cordData.concat(identData);
    const srcData = unsortedData.sort(this.compareData);
    return srcData;
  }

  render() {
    const srcData = this.setupSrcData();
    return (
      <Main>
        {this.props.isCustomerView === true ? <AccountSelector /> : null}
        <SplitViewWrapper>
          <SplitView>
            <DetailsTitleWrapper style={{ marginLeft: '4px' }}>
              {Icons.options()} <Title title="Options" />
            </DetailsTitleWrapper>
            <OptionBox>
              <CheckboxBox>{this.setUpStatusCheckboxes()}</CheckboxBox>
              <StyledSelect
                helptext={translate('backoffice.approvals.type', 'Type')}
                id="type"
                options={this.getTypeOptions()}
                notification={this.handleTypeSelect}
                wrapperStyle={{
                  margin: 'initial',
                  maxWidth: '320px',
                  height: '88px',
                }}
              />
            </OptionBox>
            <div style={{ display: 'flex' }}>
              <IconWrapper
                style={{ alignSelf: 'flex-start' }}
                onClick={() => this.fetchAllReleases()}
              >
                {Icons.refresh()}
              </IconWrapper>
              <PagingWrapper>
                <PagingComponent
                  numberOfEntries={srcData.length}
                  rowsPerPage={5}
                  resetIndex={this.resetIndex}
                />
              </PagingWrapper>
            </div>
            <div style={{ maxHeight: 'calc(100vh - 400px', overflowY: 'auto' }}>
              {srcData == null ? null : (
                <Table<IApprovalData>
                  header={[
                    translate('backoffice.approvals.header.approval'),
                    'ID',
                    translate('backoffice.approvals.header.created'),
                    translate('backoffice.approvals.header.status'),
                    translate('backoffice.approvals.header.name'),
                    translate('backoffice.approvals.header.type'),
                  ]}
                  sourceData={srcData}
                  fields={[
                    'release_requests_id',
                    'client',
                    'datetime_create',
                    'status',
                    'requested_by_person_given_name',
                    'type',
                  ]}
                  stickyHeader={true}
                  dataConverter={this.converter}
                  dynamicPaging={true}
                  externalPaging={true}
                  ref={this.setTableRef}
                />
              )}
            </div>

            <ButtonWrapper>
              <ButtonOk
                disableSpinner={true}
                onClick={event => {
                  event.stopPropagation();
                  this.handleAllSelected(Account.ReleaseAction.R);
                }}
                disabled={
                  Object.keys(this.state.toApproveCore).length === 0 &&
                  Object.keys(this.state.toApproveIdent).length === 0
                }
              >
                {translate('backoffice.approvals.approve')}
              </ButtonOk>
              <div style={{ width: '32px' }} />
              <ButtonOk
                disableSpinner={true}
                onClick={event => {
                  event.stopPropagation();
                  this.handleAllSelected(Account.ReleaseAction.B);
                }}
                disabled={
                  Object.keys(this.state.toApproveCore).length === 0 &&
                  Object.keys(this.state.toApproveIdent).length === 0
                }
              >
                {translate('backoffice.approvals.reject')}
              </ButtonOk>
            </ButtonWrapper>
          </SplitView>
          <SplitView>
            <DetailsTitleWrapper>
              {Icons.info()} <Title title={'Details'} />
            </DetailsTitleWrapper>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <ApprovalDetails
                callback={this.props.callback}
                releaseRequestId={
                  this.state.selectedApproval != null ? this.state.selectedApproval : -1
                }
                error={this.state.selectedError}
                account_numbers={this.state.selectedAccounts}
                api={this.state.selectedType === 'c' ? apis.ReleaseApi : apis.ReleaseApiIdent}
                person_id={this.state.selectedPersonId}
                approver={this.state.selectedApprover}
              />
            </div>
          </SplitView>
        </SplitViewWrapper>
      </Main>
    );
  }
}

export default BackofficeApprovals;
