import React from 'react';
import styled from 'styled-components';
import { translate } from '../../../../../common/language/translate';
import { FlexBox } from '../../../../auth/auth.css';
import Title from '../../../../../components/compositcomponents/title';
import {
  AOverlay,
  IPropsOver,
} from '../../../../../logic/handler/overlayhandler/globaloverlays/aOverlay';
import {
  KeyField,
  KeyValueBlock,
  ValueField,
} from '../basicStyledComponents/customerDetails.css';
import { Icons } from '../../../../../images';

const StyledDivMain = styled(FlexBox)`
  position: fixed; /* Sit on top of the page content */
  top: calc(50% - 280px);
  left: calc(50% - 300px);
  z-index: 300;

  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: white;

  font-family: Roboto, 'Helvetica Neue', sans-serif;
  border: 1px solid ${props => props.theme.Button.backgroundColor};
  border-radius: 2px;

  padding: 16px;
  width: 300px;
  height: 280px;
  #box-sizing: border-box;
`;

const StyledDivFirstLine = styled(FlexBox)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.Global.darkFontColor};
  text-align: center;
  padding-bottom: 20px;
`;

const LimitBox = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ILimitProps extends IPropsOver {
  acc_nr: string;
  check_limit?: boolean;
  write_limit?: boolean;
  check_balance?: boolean;
}

interface IState {}
export default class LimitInfoOverlay extends AOverlay<ILimitProps, IState> {
  reviveState() {}
  defineContent() {
    return (
      <StyledDivMain onClick={event => event.stopPropagation()}>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
          onClick={() => {
            this.props.selfClose();
          }}
        >
          {Icons.closingCross()}
        </div>
        <Title title={translate('customers.limits.applied') + '(' + this.props.acc_nr + ')'} />
        <StyledDivFirstLine />
        <LimitBox>
          <KeyValueBlock style={{ flexDirection: 'row', height: '42px' }}>
            <KeyField style={{ width: '50%' }}>{translate('customers.limits.check')}</KeyField>
            <ValueField
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {this.props.check_limit === false ? Icons.cancelCross() : Icons.checkBoxIcon()}
            </ValueField>
          </KeyValueBlock>
          <KeyValueBlock style={{ flexDirection: 'row', height: '42px' }}>
            <KeyField style={{ width: '50%' }}>{translate('customers.limits.write')}</KeyField>
            <ValueField
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {this.props.check_limit === false ? Icons.cancelCross() : Icons.checkBoxIcon()}
            </ValueField>
          </KeyValueBlock>
          <KeyValueBlock style={{ flexDirection: 'row', height: '42px' }}>
            <KeyField style={{ width: '50%' }}>
              {translate('customers.limits.balance')}
            </KeyField>
            <ValueField
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {this.props.check_limit === false ? Icons.cancelCross() : Icons.checkBoxIcon()}
            </ValueField>
          </KeyValueBlock>
        </LimitBox>
      </StyledDivMain>
    );
  }
}
