import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { translate } from '../../common/language/translate';
import { FlexBox } from '../../content/auth/auth.css';
import { compareDate } from '../../logic/helper/Common';

interface IProps {
  date: Date;
  selectedDay?: number;
  callback: (day: number, month?: number) => void;
  maxDate?: Date;
  minDate?: Date;
}

interface IState {
  year: number;
  month: number;
  day: number;
  selectedDay: number;
}
const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export class DayComponent extends React.Component<IProps, IState> {
  private daysOfMonth: Array<number>;

  constructor(props: IProps) {
    super(props);
    const initDate = new Date();
    this.state = {
      year: props.date != null ? props.date.getFullYear() : initDate.getFullYear(),
      month: props.date != null ? props.date.getMonth() : initDate.getMonth(),
      day: props.date != null ? props.date.getDate() : initDate.getDate(),
      selectedDay: props.date != null ? props.date.getDate() : initDate.getDate(),
    };
    this.daysOfMonth = [
      31,
      this.isLeapYear(this.state.year) ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];

    this.getDaysBlock = this.getDaysBlock.bind(this);
    this.getInactiveDays = this.getInactiveDays.bind(this);
  }

  componentWillReceiveProps(props: IProps) {
    const initDate = new Date();
    this.setState({
      year: props.date != null ? props.date.getFullYear() : initDate.getFullYear(),
      month: props.date != null ? props.date.getMonth() : initDate.getMonth(),
      day: props.date != null ? props.date.getDate() : initDate.getDate(),
      selectedDay: props.selectedDay != null ? props.selectedDay : this.state.selectedDay,
    });
    this.daysOfMonth = [
      31,
      this.isLeapYear(props.date != null ? props.date.getFullYear() : initDate.getFullYear())
        ? 29
        : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];
  }

  getInactiveDays() {
    const date =
      this.props.date != null ? new Date(this.props.date) : new Date(this.props.date);
    date.setDate(1);
    const weekday = date.getDay();
    if (weekday === 0) {
      return 7;
    }
    return ((weekday % 7) + 7) % 7; // wtf?
  }

  getNextMonthoffset() {
    const date = this.props.date != null ? new Date(this.props.date) : new Date();
    date.setDate(this.daysOfMonth[this.state.month]);
    const weekday = date.getDay();
    return 7 - weekday;
  }
  mod(n: number, m: number): number {
    return ((n % m) + m) % m;
  }

  setDay(day: number, month: number = 0) {
    this.setState({
      day: day,
      selectedDay: day,
    });
    this.props.callback(day, month);
  }

  isLeapYear(year: number) {
    if (year % 4 !== 0) {
      return false;
    }
    if (year % 100 === 0 && year % 400 !== 0) {
      return false;
    }
    return true;
  }

  checkAvailable(date: Date): boolean {
    if (this.props.maxDate != null && compareDate(this.props.maxDate, date) < 0) {
      return false;
    }
    if (this.props.minDate != null && compareDate(this.props.minDate, date) > 0) {
      return false;
    }
    return true;
  }

  getDaysBlock() {
    const offSet = this.getInactiveDays();
    const daysLastMonth = this.daysOfMonth[this.mod(this.state.month - 1, 12)];
    const firtsBlock = [];
    let daysCount = 0;
    if (this.props.date != null) {
      let checkDate = new Date(this.props.date);
      checkDate.setMonth(checkDate.getMonth() - 1);
      for (let i = daysLastMonth - offSet + 1; i < daysLastMonth; i++) {
        checkDate.setDate(i + 1);
        const checked = this.checkAvailable(checkDate);
        firtsBlock.push(
          <DayOfMonth key={'dayOfLastMonth_' + i}>
            {checked ? (
              <LastMonthText key={'lastMonthText_' + i} onClick={() => this.setDay(i + 1, -1)}>
                {i + 1}
              </LastMonthText>
            ) : (
              <NotAvailableTest
                onClick={event => event.stopPropagation()}
                key={'lastMonthText_' + i}
              >
                {i + 1}
              </NotAvailableTest>
            )}
          </DayOfMonth>,
        );
        ++daysCount;
      }
      const rest = this.daysOfMonth[this.state.month];
      for (let i = 0; i < rest; i++) {
        checkDate = new Date(this.props.date);
        checkDate.setDate(i + 1);
        const checked = this.checkAvailable(checkDate);
        if (i + 1 === this.state.selectedDay && checked) {
          firtsBlock.push(
            <SelectedDay key={'selectedDay_' + i}>
              {checked ? (
                <SelectedText onClick={() => this.setDay(i + 1)}>{i + 1}</SelectedText>
              ) : (
                <NotAvailableTest
                  onClick={event => event.stopPropagation()}
                  key={'lastMonthText_' + i}
                >
                  {i + 1}
                </NotAvailableTest>
              )}
            </SelectedDay>,
          );
        } else {
          if (daysCount === 5 || daysCount === 6) {
            firtsBlock.push(
              <WeekendDay key={'weekendDay_' + i}>
                {checked ? (
                  <WeekEndText onClick={() => this.setDay(i + 1)}>{i + 1}</WeekEndText>
                ) : (
                  <NotAvailableTest
                    onClick={event => event.stopPropagation()}
                    key={'lastMonthText_' + i}
                  >
                    {i + 1}
                  </NotAvailableTest>
                )}
              </WeekendDay>,
            );
          } else {
            firtsBlock.push(
              <DayOfMonth key={'dayOfMonth_' + i}>
                {checked ? (
                  <HoverText onClick={() => this.setDay(i + 1)}>{i + 1}</HoverText>
                ) : (
                  <NotAvailableTest
                    onClick={event => event.stopPropagation()}
                    key={'lastMonthText_' + i}
                  >
                    {i + 1}
                  </NotAvailableTest>
                )}
              </DayOfMonth>,
            );
          }
        }
        daysCount = daysCount > 5 ? 0 : daysCount + 1;
      }

      const lastOffset = this.getNextMonthoffset();
      checkDate.setMonth(checkDate.getMonth() + 1);
      for (let i = 0; i < lastOffset; i++) {
        checkDate.setDate(i + 1);
        const checked = this.checkAvailable(checkDate);
        if (firtsBlock.length % 7 === 0) {
          break;
        }
        firtsBlock.push(
          <DayOfMonth key={'dayOfNextMonth_' + i} onClick={() => this.setDay(i + 1, 1)}>
            {checked ? (
              <LastMonthText>{i + 1}</LastMonthText>
            ) : (
              <NotAvailableTest key={'lastMonthText_' + i}>{i + 1}</NotAvailableTest>
            )}
          </DayOfMonth>,
        );
      }
    }
    return firtsBlock;
  }

  getWeekDayBlock(): Array<ReactElement> {
    const block: Array<ReactElement> = [];
    for (const i in weekDays) {
      block.push(
        <WekkdayWrapper key={'weekdayWrapper_' + i}>
          <WekkDayText key={'weekdayText_' + i}>
            {translate('date.' + weekDays[i] + '.short')}
          </WekkDayText>
        </WekkdayWrapper>,
      );
    }
    return block;
  }
  render() {
    return (
      <Main>
        <WeekDayBlock>{this.getWeekDayBlock()}</WeekDayBlock>
        <DaysBlock>{this.getDaysBlock()}</DaysBlock>
      </Main>
    );
  }
}

const Main = styled(FlexBox)`
  box-sizing: border-box;
  padding-bottom: 5px;
  border-bottom: 1px solid #c0c0c0;
  margin-top: 10px;
`;

const DayOfMonth = styled(FlexBox)`
  width: 23px;
  height: 23px;
  background-color: #ffffff;
  border: ${props => props.theme.Box.border};
  text-align: center;
  margin-bottom: 5px;
  margin-right: 3px;
  margin-left: 3px;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
`;

const SelectedDay = styled(DayOfMonth)`
  background-color: ${props => props.theme.Button.backgroundColor};
`;

const WeekendDay = styled(DayOfMonth)`
  background-color: #f2f1f1;
`;

const WekkdayWrapper = styled(FlexBox)`
  width: 25px;
  margin-right: 3px;
  margin-left: 2px;
  display: inline-block;
  text-align: center;
`;
const WekkDayText = styled.span`
  font-color: #888888;
  font-size: 10px;
`;

const DaysBlock = styled(FlexBox)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 215px;
`;
const WeekDayBlock = styled(DaysBlock)`
  margin-bottom: 10px;
`;
const HoverText = styled.span`
  font-size: 12px;
  &:hover {
    color: ${props => props.theme.Button.backgroundColor};
    cursor: pointer;
  }
`;
const NotAvailableTest = styled(HoverText)`
  color: #e5e0e0;
  &:hover {
    cursor: default;
  }
`;

const LastMonthText = styled(HoverText)`
  color: #a9a9a9;
`;

const WeekEndText = styled(HoverText)`
  color: #464e51;
`;

const SelectedText = styled(HoverText)`
  color: #ffffff;
`;
