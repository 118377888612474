//@ts-nocheck
import React, { ReactNode } from 'react';
import AuthScreen, { IAuthScreenState, IAuthScreenProps } from '../authScreen';
import { Images } from '../../../images/images/images';
import { withRouter } from 'react-router';
import { StyledAuthComponent } from './auth.css';
import { translate } from '../../../common/language/translate';
import { Ident } from '../../../logic/api';
import ButtonOk from '../../../components/atomiccompoents/buttons/buttonOk';

interface IState extends IAuthScreenState {}

interface IProps extends IAuthScreenProps {}

class FaceLoginComponentClass extends AuthScreen<IProps, IState> {
  onSubmit(): void {}

  defineContent(): ReactNode {
    return null;
  }

  reviveState(): void {}

  render() {
    return (
      <StyledAuthComponent>
        <div>{translate(this.getTitle())}</div>
        <div>{translate(this.getSubtitle() as string)}</div>

        {Images.faceIdFace()}

        <ButtonOk disableSpinner={true} id="startRecognition">
          {translate('button.faceLogin')}
        </ButtonOk>

        {this.getLinkForConnection(Ident.AuthConnection.LoginUserPassword)}
      </StyledAuthComponent>
    );
  }
}

export const FaceLoginComponent = withRouter(FaceLoginComponentClass);
