//@ts-nocheck
import React, { ReactNode, SyntheticEvent } from 'react';
import { Ident, Account, api, apis } from '../../../../logic/api';
import styled from 'styled-components';
import { Icons } from '../../../../images';
import { translate } from '../../../../common/language/translate';
import TouchableOpacity from '../../../../components/atomiccompoents/buttons/touchableOpacity';
import { Td, Tr } from './basicStyledComponents/customerDetails.css';
import { Logs, Log } from '../../../../logic/log';
import { BigInput } from '../../../../components/atomiccompoents/form/inputs.css';
import IconPicker from '../../../../components/atomiccompoents/iconPicker/iconPicker';
import IconPickerItem from '../../../../components/atomiccompoents/iconPicker/iconPickerItem';
import ButtonCancel from '../../../../components/atomiccompoents/buttons/buttonCancel';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../logic/handler/messagehandler/messageHandlerConfig';

type SecurityCodeNameType = 'CID/CSC' | 'CVC' | 'CVV';

interface IProps {
  externalAccount: Account.ExternalAccount;
  selectedUser?: Ident.Person;
}

interface IState {
  editMode?: boolean;
  regexMap?: Map<Account.CardBrand, RegExp>;
  selectedUser?: Ident.Person;
  securityCodeName?: SecurityCodeNameType;
  keyForLoadingSpinner?: number;

  holderError?: string;
  numberError?: string;
  brandError?: string;
  codeError?: string;
  expiryError?: string;
  inlineError?: boolean;

  card_brand?: Account.CardBrand;
  card_number?: string;
  expiry?: string;
  name?: string;
  unsupportedCardBrand?: boolean;
  security_code?: string;
}

export default class ExternalAcocuntListBox extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const cardBrand = this.convertAcceptedCardBrand(props.externalAccount.card_brand);
    const securityCode = this.getSecurityCodeName(cardBrand);

    this.state = {
      selectedUser: props.selectedUser,
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),

      card_brand: cardBrand,
      card_number: props.externalAccount.card_number,
      expiry: props.externalAccount.expiry,
      name: props.externalAccount.name,
      securityCodeName: securityCode,
    };
  }

  static getDerivedStateFromProps(props: IProps, state: IState): IState | null {
    if (props.selectedUser !== state.selectedUser) {
      return {
        selectedUser: props.selectedUser,
      };
    }

    return null;
  }

  private _onCancel(event: SyntheticEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this.setState({
      card_brand: this.convertAcceptedCardBrand(this.props.externalAccount.card_brand),
      card_number: this.props.externalAccount.card_number,
      expiry: this.props.externalAccount.expiry,
      unsupportedCardBrand: true,
      name: this.props.externalAccount.name,
      editMode: false,
      securityCodeName: undefined,
    });
  }

  private _onChangeCardBrand(value?: string | number): void {
    if (value == null) {
      this.setState({
        card_brand: undefined,
        securityCodeName: undefined,
      });

      return;
    }
    if (typeof value === 'number') {
      let i = 0;
      for (const entry of Object.entries(Account.CardBrand)) {
        if (value === i) {
          this.setState({
            card_brand: entry[1],
            unsupportedCardBrand: this.convertCardBrand(entry[1]) == null,
            securityCodeName: this.getSecurityCodeName(entry[1]),
          });
          break;
        }

        i++;
      }
    } else {
      for (const entry of Object.entries(Account.CardBrand)) {
        if (value.toLowerCase() === entry[1].toString().toLowerCase()) {
          this.setState({
            card_brand: entry[1],
            unsupportedCardBrand: this.convertCardBrand(entry[1]) == null,
            securityCodeName: this.getSecurityCodeName(entry[1]),
          });
          break;
        }
      }
    }
  }

  private _onChangeCardExpiry(value?: string): void {
    //TODO: Format / validation, maybe datepicker?
    this.setState({
      expiry: value,
    });
  }

  private _onChangeCardHolder(value?: string): void {
    this.setState({
      name: value,
    });
  }

  private _onChangeCardNumber(value?: string): void {
    let card_brand: Account.CardBrand | undefined = this.state.card_brand;
    let unsupportedCardBrand: boolean = this.state.unsupportedCardBrand === true;

    if (value != null && this.state.regexMap != null) {
      for (const el of this.state.regexMap) {
        if (value.match(el[1])) {
          card_brand = el[0];
          unsupportedCardBrand = this.convertCardBrand(el[0]) == null;
          break;
        }
      }
    }

    this.setState({
      card_number: value,
      unsupportedCardBrand: unsupportedCardBrand,
      card_brand: card_brand,
      securityCodeName: this.getSecurityCodeName(card_brand),
    });
  }

  private _onChangeSecurityCode(value?: string): void {
    //TODO: Format / validation
    this.setState({
      security_code: value,
    });
  }

  private _onSubmit(event: SyntheticEvent): void {
    event.stopPropagation();
    event.preventDefault();

    let error: boolean = false;
    let cardBrand: Account.AcceptedCardBrand;
    const acceptedCardBrand = this.convertCardBrand(this.state.card_brand);
    if (acceptedCardBrand == null) {
      this.setState({
        brandError: translate('customers.details.externalAccounts.unsupportedBrand'),
      });
      error = true;

      //Unnecessary, but needed due to eslint
      cardBrand = Account.AcceptedCardBrand.MasterCard;
    } else {
      if (this.state.brandError != null) {
        this.setState({
          brandError: undefined,
        });
      }
      cardBrand = acceptedCardBrand;
    }

    if (this.state.card_number == null) {
      this.setState({
        numberError: translate('customers.details.externalAccounts.missingCardNumber'),
      });
      error = true;
    } else if (this.state.card_number.match(/^[0-9]{8,19}$/) == null) {
      this.setState({
        numberError: translate('customers.details.externalAccounts.invalidCardNumber'),
      });
      error = true;
    } else {
      if (this.state.numberError != null) {
        this.setState({
          numberError: undefined,
        });
      }
    }

    if (this.state.expiry == null || this.state.expiry.trim() === '') {
      this.setState({
        expiryError: translate('customers.details.externalAccounts.missingExpiryDate'),
      });
      error = true;
    } else {
      const match: RegExpMatchArray | null = this.state.expiry.match(
        /^([0-9]{2,2})\/([0-9]{2,2})$/,
      );

      if (match == null) {
        this.setState({
          expiryError: translate('customers.details.externalAccounts.invalidExpiryDate'),
        });
        error = true;
      } else {
        const date: Date = new Date();
        const year: number = date.getFullYear() % 100;
        const month: number = date.getMonth() + 1;

        if (
          (year === Number.parseInt(match[2]) && month > Number.parseInt(match[1])) ||
          year > Number.parseInt(match[2])
        ) {
          this.setState({
            expiryError: translate('customers.details.externalAccounts.expiredDate'),
          });
          error = true;
        } else {
          if (this.state.expiryError != null) {
            this.setState({
              expiryError: undefined,
            });
          }
        }
      }
    }
    if (this.state.name == null || this.state.name.trim() === '') {
      this.setState({
        holderError: translate('customers.details.externalAccounts.missingName'),
      });
      error = true;
    } else {
      if (this.state.holderError != null) {
        this.setState({
          holderError: undefined,
        });
      }
    }
    if (
      this.state.security_code == null ||
      this.state.security_code.match(/^[0-9]{3,4}$/) == null
    ) {
      this.setState({
        codeError: translate('customers.details.externalAccounts.invalidCode'),
      });
      error = true;
    } else {
      if (this.state.codeError != null) {
        this.setState({
          codeError: undefined,
        });
      }
    }
    if (error === true) {
      const displayMethods = MessageHandler.onError(
        Reporter['customer.details.externalAccounts.invalidData'],
      ).errorMethods;
      this.setState({
        inlineError: displayMethods != null && displayMethods.inline === true,
        keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
      });
      return;
    }

    const params: Account.PersonExternalAccountPutRequest = {
      external_account_id: this.props.externalAccount.external_account_id,
      NewExternalAccount: {
        card_brand: cardBrand,
        card_number: this.state.card_number == null ? '' : this.state.card_number,
        expiry: this.state.expiry == null ? '' : this.state.expiry,
        name: this.state.name == null ? '' : this.state.name,
        security_code: this.state.security_code == null ? '' : this.state.security_code,
      },
      person_id:
        this.props.selectedUser == null ? undefined : this.props.selectedUser.person_id,
    };
    api
      .asyncRequest<Account.ExternalAccountID>(
        params,
        apis.DefaultApi,
        'personExternalAccountPut',
      )
      .then(() => {
        //TODO: Update external account id
        const displayMethods = MessageHandler.onSuccess(
          Reporter['customer.details.externalAccounts.submit'],
        ).successMethods;
        this.setState({
          keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          editMode: false,
          inlineError: displayMethods == null ? false : true,
        });
      })
      .catch(() => {
        const displayMethods = MessageHandler.onError(
          Reporter['customer.details.externalAccounts.submit'],
        ).successMethods;
        this.setState({
          keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          inlineError: displayMethods == null ? false : true,
        });
      });
  }

  private convertAcceptedCardBrand(
    cardBrand?: Account.AcceptedCardBrand,
  ): Account.CardBrand | undefined {
    if (cardBrand == null) {
      return undefined;
    }

    switch (cardBrand) {
      case Account.AcceptedCardBrand.Visa:
        return Account.CardBrand.Visa;
      case Account.AcceptedCardBrand.MasterCard:
        return Account.CardBrand.MasterCard;
      default:
        return undefined;
    }
  }

  private convertCardBrand(
    cardBrand?: Account.CardBrand,
  ): Account.AcceptedCardBrand | undefined {
    switch (cardBrand) {
      case Account.CardBrand.Visa:
        return Account.AcceptedCardBrand.Visa;
      case Account.CardBrand.MasterCard:
        return Account.AcceptedCardBrand.MasterCard;
      default:
        return undefined;
    }
  }

  private getCardNumberRegex(): Promise<Map<Account.CardBrand, RegExp>> {
    return new Promise<Map<Account.CardBrand, RegExp>>((resolve, reject) => {
      const storageKey = 'debitCardRegex';
      const regex = window.sessionStorage.getItem(storageKey);
      if (regex != null) {
        const regexs: Map<Account.CardBrand, RegExp> = JSON.parse(regex);
        if (regexs != null) {
          return resolve(regexs);
        }
      }

      api
        .asyncRequest<Array<Account.ExternalAccountValidCardBrand>>(
          {},
          apis.DefaultApi,
          'personExternalAccountValidCardBrandsGet',
        )
        .then(response => {
          Log.debug(Logs.COMPONENT, response);
          const regexMap: Map<Account.CardBrand, RegExp> = new Map();
          response.forEach(r => {
            regexMap.set(r.card_brand, new RegExp(r.prefix_pattern));
          });
          return resolve(regexMap);
        })
        .catch(error => {
          Log.error(Logs.COMPONENT, error);
          return reject(undefined);
        });
    });
  }

  private getSecurityCodeName(
    cardBrand?: Account.CardBrand,
  ): SecurityCodeNameType | undefined {
    if (cardBrand == null) {
      return undefined;
    }

    switch (cardBrand) {
      case Account.CardBrand.AmericanExpress:
        return 'CID/CSC';
      case Account.CardBrand.Discover:
        return 'CVV';
      case Account.CardBrand.Maestro:
        return 'CVV';
      case Account.CardBrand.MasterCard:
        return 'CVC';
      case Account.CardBrand.Visa:
        return 'CVV';
      default:
        return undefined;
    }
  }

  private editWindow(): ReactNode {
    return (
      <Tr>
        <Td colSpan={3}>
          <BigInput
            id="debit_card_name"
            type="text"
            value={this.state.name}
            label={translate('customers.details.externalAccounts.input.name.label')}
            error={this.state.holderError}
            placeHolder={translate(
              'customers.details.externalAccounts.input.name.placeholder',
            )}
            toolTip={this.state.inlineError === true}
            showLabel={true}
            showPlaceholder={true}
            defaultValue={this.state.name}
            onChange={value => this._onChangeCardHolder(value)}
          />

          <BigInput
            id="debit_card_number"
            type="text"
            value={this.state.card_number}
            label={translate('customers.details.externalAccounts.input.cardNumber.label')}
            error={this.state.numberError}
            placeHolder={translate(
              'customers.details.externalAccounts.input.cardNumber.placeholder',
            )}
            toolTip={this.state.inlineError === true}
            showLabel={true}
            showPlaceholder={true}
            defaultValue={this.state.card_number}
            onChange={value => this._onChangeCardNumber(value)}
          />
          <BrandCvvWrapper>
            <IconPicker
              defaultValue={this.state.card_brand}
              onValueChange={value => this._onChangeCardBrand(value)}
              error={this.state.brandError}
              toolTip={true}
              value={this.state.card_brand}
            >
              {Object.values(Account.CardBrand).map(brand => {
                return (
                  <IconPickerItem value={brand.toString()} key={'iconPicker_' + brand}>
                    <IconWrapper>{Icons.creditCard(brand)}</IconWrapper>
                  </IconPickerItem>
                );
              })}
            </IconPicker>
            <BigInput
              containerStyle={{ marginLeft: '22px' }}
              id="debit_card_security_code"
              type="text"
              value={this.state.security_code}
              error={this.state.codeError}
              placeHolder={
                this.state.securityCodeName == null
                  ? translate(
                      'customers.details.externalAccounts.input.securityCode.placeholder',
                    )
                  : this.state.securityCodeName
              }
              toolTip={this.state.inlineError === true}
              showLabel={true}
              showPlaceholder={true}
              defaultValue={this.state.security_code}
              onChange={value => this._onChangeSecurityCode(value)}
            />
          </BrandCvvWrapper>

          <BigInput
            id="debit_card_expiry"
            type="text"
            value={this.state.expiry}
            label={translate('customers.details.externalAccounts.input.expiry.label')}
            error={this.state.expiryError}
            placeHolder={translate(
              'customers.details.externalAccounts.input.expiry.placeholder',
            )}
            toolTip={this.state.inlineError === true}
            showLabel={true}
            showPlaceholder={true}
            defaultValue={this.state.expiry}
            onChange={value => this._onChangeCardExpiry(value)}
          />

          <ButtonWrapper>
            <ButtonCancel
              style={{ marginRight: '15px' }}
              onClick={event => this._onCancel(event)}
            >
              {translate('button.cancel')}
            </ButtonCancel>
            <ButtonOk
              key={this.state.keyForLoadingSpinner}
              onClick={event => this._onSubmit(event)}
              disabled={this.state.unsupportedCardBrand === true}
            >
              {translate('button.save')}
            </ButtonOk>
          </ButtonWrapper>
        </Td>
        <Td />
      </Tr>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Tr>
          <Td style={{ verticalAlign: 'middle' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                height: '100%',
              }}
            >
              <IconWrapper>{Icons.creditCard(this.state.card_brand)}</IconWrapper>
            </div>
          </Td>
          <Td>
            <div>{this.state.name}</div>
            <div>{this.state.card_number}</div>
          </Td>
          <Td>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                height: '100%',
              }}
            >
              {translate('customers.validUntil') + ' ' + this.state.expiry}
              <TouchableOpacity
                onClick={() => {
                  this.getCardNumberRegex()
                    .then(regexMap => {
                      this.setState({
                        editMode: this.state.editMode !== true,
                        regexMap: regexMap,
                      });
                    })
                    .catch(() => {
                      this.setState({
                        editMode: this.state.editMode !== true,
                      });
                    });
                }}
                containerStyle={{
                  display: 'inline',
                  width: '28px',
                  height: '29px',
                  marginLeft: '15px',
                }}
              >
                {Icons.edit()}
              </TouchableOpacity>
            </div>
          </Td>
        </Tr>
        {this.state.editMode === true ? this.editWindow() : null}
      </React.Fragment>
    );
  }
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: bottom;
  margin-bottom: 15px;
`;

const BrandCvvWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: bottom;
  width: 100%;
`;

const IconWrapper = styled.div`
  width: 26px;
  height: 24px;
  svg {
    width: 36px;
    height: 24px;
  }
`;
