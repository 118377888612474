// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, throwIfRequired, encodeURI } from '../runtime';
import {
    ApprovedRegulaDocument,
    MatchRequest,
    MatchResponsePlusExtractedFields,
    ProcessRequest,
    ProcessResponseAndMatchResponse,
    ProcessResponsePlusExtractedFields,
    RegulaDocument,
} from '../models/index';

export interface RegulaforensicsApiMatchPostRequest {
    MatchRequest: MatchRequest;
}

export interface RegulaforensicsApiProcessGetRequest {
    release_requests_id: number;
}

export interface RegulaforensicsApiProcessPostRequest {
    ProcessRequest: ProcessRequest;
}

export interface RegulaforensicsApiProcessPutRequest {
    person_id: number;
    regula_document_id: number;
    ApprovedRegulaDocument: ApprovedRegulaDocument;
}

export interface RegulaforensicsDocumentAndFaceMatchPostRequest {
    ProcessResponseAndMatchResponse: ProcessResponseAndMatchResponse;
}

/**
 * no description
 */
export class RegulaforensicsApi extends BaseAPI implements IOperationIdMap {

    /**
     */
    regulaforensicsApiMatchPost = (requestParameters: RegulaforensicsApiMatchPostRequest): Observable<MatchResponsePlusExtractedFields> => {
        throwIfRequired(requestParameters, 'MatchRequest', 'regulaforensicsApiMatchPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<MatchResponsePlusExtractedFields>({
            path: '/faceapi.regulaforensics.com/api/match',
            method: 'POST',
            headers,
            body: requestParameters.MatchRequest,
        });
    };

    /**
     */
    regulaforensicsApiProcessGet = (requestParameters: RegulaforensicsApiProcessGetRequest): Observable<RegulaDocument> => {
        throwIfRequired(requestParameters, 'release_requests_id', 'regulaforensicsApiProcessGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<RegulaDocument>({
            path: '/api.regulaforensics.com/api/process/{release_requests_id}'.replace('{release_requests_id}', encodeURI(requestParameters.release_requests_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     */
    regulaforensicsApiProcessPost = (requestParameters: RegulaforensicsApiProcessPostRequest): Observable<ProcessResponsePlusExtractedFields> => {
        throwIfRequired(requestParameters, 'ProcessRequest', 'regulaforensicsApiProcessPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<ProcessResponsePlusExtractedFields>({
            path: '/api.regulaforensics.com/api/process',
            method: 'POST',
            headers,
            body: requestParameters.ProcessRequest,
        });
    };

    /**
     * approve the data from regula document scan
     */
    regulaforensicsApiProcessPut = (requestParameters: RegulaforensicsApiProcessPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'regulaforensicsApiProcessPut');
        throwIfRequired(requestParameters, 'regula_document_id', 'regulaforensicsApiProcessPut');
        throwIfRequired(requestParameters, 'ApprovedRegulaDocument', 'regulaforensicsApiProcessPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/regula/document/{regula_document_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{regula_document_id}', encodeURI(requestParameters.regula_document_id)),
            method: 'PUT',
            headers,
            body: requestParameters.ApprovedRegulaDocument,
        });
    };

    /**
     * save data from document scan and face match in backend
     */
    regulaforensicsDocumentAndFaceMatchPost = (requestParameters: RegulaforensicsDocumentAndFaceMatchPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ProcessResponseAndMatchResponse', 'regulaforensicsDocumentAndFaceMatchPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/regulaforensics/document_and_face_match',
            method: 'POST',
            headers,
            body: requestParameters.ProcessResponseAndMatchResponse,
        });
    };

    /**
     * save permission to save ID and face image data
     */
    regulaforensicsPermissionPost = (): Observable<void> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/regulaforensics/permission',
            method: 'POST',
            headers,
        });
    };


    operationToOperationId = {
	    "regulaforensicsApiMatchPost": "regulaforensics_api_match_post" ,
	    "regulaforensicsApiProcessGet": "regulaforensics_api_process_get" ,
	    "regulaforensicsApiProcessPost": "regulaforensics_api_process_post" ,
	    "regulaforensicsApiProcessPut": "regulaforensics_api_process_put" ,
	    "regulaforensicsDocumentAndFaceMatchPost": "regulaforensics_document_and_face_match_post" ,
	    "regulaforensicsPermissionPost": "regulaforensics_permission_post"
    }
}
