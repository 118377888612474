export const ClientConfig = {
  name_change: true,
  address_approval: true,
  webTitle: 'OneFor',
  editPassport: false,
  showMerchants: false,
  showVouchers: false,
  showDispo: true,
  kycButton: true,
  operationIdForClosure: 'personDelete',
  getParnterIds: false,
  showLimits: true,
  skipReasons: [''],
  showAddressApproval: true,
  clearingFIles: true,
  advancedKyc: true,
  editEid: true,
  configuration: true,
  showClientStats: true,
  showCardStatus: true,
  bookFee: true,
  showEid: true,
  kycEdit: false,
  fetchEid: true,
  videoqueue: false,
  roles: {
    admin: 'Admin',
    user: 'Backoffice',
    support: 'Customer service',
    audit: 'Audit',
    media: 'Media',
    service_center: 'Service Center',
  },
  noFeePackageCategories: ['companion'],
  monitoring: true,
  productMaintenance: false,
  jnrAccount: true,
  onfido: true,
};
