import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { translate } from '../../../../../common/language/translate';
import { BigInput } from '../../../../../components/atomiccompoents/form/inputs.css';
import Title from '../../../../../components/compositcomponents/title';
import { Icons } from '../../../../../images';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import {
  IPropsCenter,
  IStateCenter,
  ACenteredOverlay,
} from '../../../../../logic/handler/overlayhandler/globaloverlays/aCenteredOverlay';
import {
  OverlayHandler,
  Overlays,
} from '../../../../../logic/handler/overlayhandler/overlayHandler';
import { FlexBox } from '../../../../auth/auth.css';
import { Account, api, ApiError, apis } from '../../../../../logic/api';
import { Log, Logs } from '../../../../../logic/log';
import { StyledLine } from '../../backoffice/basicStyledComponents/styled';
import { evaluateErrorMessage } from '../../../../../logic/helper/Common';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import { OutgoingMessage } from 'http';

const StyledViewMain = styled(FlexBox)`
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  border: 1px solid ${props => props.theme.Button.backgroundColor};
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  z-index: 1000;
  padding: 16px;
  width: 80%;
  height: 380px;

  box-sizing: border-box;
`;
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  margin-top: 12px;
  justify-content: flex-start;
  box-sizing: border-box;
  & > div {
    justify-self: flex-start;
  }
  & > div > div > input {
    width: 320px;
    height: 37px;
    position: absolute;
    z-index: 10000;
    left: 0;
    top: 0;
    cursor: pointer;
    opacity: 0;
  }
`;
const SelectBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -100%;
`;
const SelectButton = styled.button`
  content: 'Browse';
  height: 37px;
  width: 37px;
  margin-left: 2px;
  margin-top: 12px;
  padding: 1px;
  line-height: 16px;
  color: #555;
  background-color: ${props => props.theme.Global.primaryColor};
  border: 0.075rem solid #ddd;
  border-radius: 4px;
  box-shadow: ${props => props.theme.Box.boxShadow};
`;

const InvInput = styled(BigInput)`
  div {
    width: 0;
  }
`;
const Upload = styled.form`
  border: none;
  width: 75%;
`;

const ImageView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  flex-wrap: nowrap;
  margin: 16px;
`;

const ImageWrapper = styled.div<{ selected?: boolean }>`
  max-height: 64px;
  display: flex;
  border: ${props =>
    props.selected === true ? '4px solid ' + props.theme.Button.backgroundColor : '0px'};
  margin: 16px;
  margin-left: 32px;
  :hover {
    cursor: pointer;
  }
`;

interface IProps extends IPropsCenter {
  callback: (imgs: Array<{ merchant_product_image_id: number; url: string }>) => void;
  title?: string;
  images?: Array<{ url: string; merchant_product_image_id: number }>;
}

interface IState extends IStateCenter {
  images?: Array<Account.ProductImage>;
  selectedImages?: Array<{ url: string; merchant_product_image_id: number }>;
}

export class SelectProductImageOverlay extends ACenteredOverlay<IProps, IState> {
  private ButtonStyle = {
    width: '100%',
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedImages: props.images,
    };
    this.close = this.close.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.getImages();
  }

  handleClick(event: SyntheticEvent): void {
    event.stopPropagation();
  }
  reviveState(): void {}

  fileChange(path: string) {
    //@ts-ignore
    const elem = document!!.getElementById('filechooser-input-overlay')!!;
    //@ts-ignore
    let file = path;
    //@ts-ignore
    if (elem.files != null && elem.files[0] != null) {
      //@ts-ignore
      file = elem.files[0];
      //@ts-ignore
      if (!file.type.match('image.*')) {
        MessageHandler.onError(Reporter['person.merchant.document.wrongtype']);
        return;
      }
    }
    this.uploadFile(file);
  }

  uploadFile(file: any) {
    const req: Account.ProductImagePostRequest = {
      product_image: file,
    };
    api
      .asyncRequest<any>(req, apis.ProductApi, 'productImagePost')
      .then((response: any) => {
        MessageHandler.onSuccess(Reporter['customers.product.image']);
        this.getImages();
      })
      .catch((error: ApiError) => {
        MessageHandler.onError(
          Reporter['customers.product.image'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
        Log.error(Logs.API, error.statusText, error);
      });
  }

  getImages() {
    api
      .asyncRequest<Array<Account.ProductImage>>({}, apis.ProductApi, 'productImagesList')
      .then((response: Array<Account.ProductImage>) => {
        this.setState({
          images: response,
        });
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error.statusText, error);
      });
  }

  generateImageBar() {
    if (this.state.images == null) {
      return [<div>No images found</div>];
    } else {
      const out = [];
      for (const o of this.state.images) {
        let idx = -1;
        for (const a in this.state.selectedImages) {
          if (this.state.selectedImages[a].merchant_product_image_id === o.product_image_id) {
            idx = parseInt(a);
          }
        }
        out.push(
          <ImageWrapper
            title={o.filename}
            selected={idx >= 0}
            onClick={() => {
              const imgs = this.state.selectedImages;

              if (idx >= 0) {
                imgs.splice(idx, 1);
              } else {
                imgs.push({
                  merchant_product_image_id: o.product_image_id,
                  url: o.image,
                });
              }
              this.setState({
                selectedImages: imgs,
              });
            }}
          >
            <img style={{ height: '64px', maxWidth: '96x' }} src={o.image} alt="" />
            <div
              onClick={(event: SyntheticEvent) => {
                event.stopPropagation();
                this.deleteImage(o.product_image_id);
              }}
              style={{ marginLeft: '-16px' }}
            >
              {Icons.deleteCross()}
            </div>
          </ImageWrapper>,
        );
      }
      return out;
    }
  }

  deleteImage(product_image_id: number) {
    api
      .asyncRequest<Array<Account.ProductImage>>(
        { product_image_id: product_image_id },
        apis.ProductApi,
        'productImageDelete',
      )
      .then(() => {
        const imgs = this.state.selectedImages;
        for (const o in imgs) {
          if (imgs[o].merchant_product_image_id === product_image_id) {
            imgs.splice(parseInt(o), 1);
          }
          this.setState({
            selectedImages: imgs,
          });
        }
        this.getImages();
        MessageHandler.onSuccess(Reporter['product.image.delete']);
      })
      .catch((error: ApiError) => {
        MessageHandler.onError(
          Reporter['product.image.delete'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
        Log.error(Logs.API, error.statusText, error);
      });
  }

  defineContent() {
    return (
      <StyledViewMain>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            height: '16px',
          }}
        >
          <div
            onClick={() => {
              this.close();
            }}
          >
            {Icons.closingCross()}
          </div>
        </div>
        <Title
          title={
            this.props.title != null ? this.props.title : translate('customers.selectImage')
          }
        />
        <ImageView>
          <ImageView> {this.generateImageBar()} </ImageView>
        </ImageView>
        <StyledLine />
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '32px', marginTop: '16px' }}>
            <Title title={translate('customers.newImage')} />
          </div>
          <Upload enc-type="multipart/form-data">
            <Wrapper>
              <InvInput
                onChange={this.fileChange}
                id="filechooser-input-overlay"
                type="file"
              />
              <SelectBox>
                <BigInput
                  valueFromState={true}
                  id="replacer"
                  type="text"
                  value={''}
                  label="Select file..."
                  placeHolder={translate('customers.details.merchant.select')}
                />
                <SelectButton onClick={event => event.preventDefault}>
                  {Icons.browse()}
                </SelectButton>
              </SelectBox>
            </Wrapper>
          </Upload>
        </div>
        <ButtonOk
          id="btnSaveImgs"
          onClick={() => {
            this.props.callback(this.state.selectedImages);
            this.close();
          }}
        >
          {translate('button.save')}
        </ButtonOk>
      </StyledViewMain>
    );
  }

  close() {
    OverlayHandler.closeSpecific(Overlays.selectProductImageOverlay);
  }
}
