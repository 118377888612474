import React from 'react';
import { CustomerSearch } from './customers/customerSearch';
import { PersonFound } from '../../../logic/api/ident';
import TransfersComponent from './transfersComponent';
import PostingTemplateComponent from './multipleUsageComponents/postingTemplateComponent';
import ReportsComponent from './reports/reportsComponent';
import { Ident, api, ApiError, apis } from '../../../logic/api';
import history from '../../../common/history';
import {
  INotifyAbleCustomerData,
  CustomerDataStore,
  Actions,
  UserDataStore,
} from '../../../logic/flux';
import Tabs, { ITabvalues } from '../../../components/atomiccompoents/tabs/tabs';
import CustomerAddComponent from './customers/CustomersAddComponent';
import AccountDetailsBox from './customers/AccountDetailsBox';
import { translate } from '../../../common/language/translate';
import { CustomerDetailsPersonBox } from './customers/CustomerDetailsPersonBox';
import CustomerDetailsExternalAccountsBox from './customers/customerDetailsExternalAccountsBox';
import CustomerDetailsPreferences from './customers/CustomerDetailsPreferences';
import { Accounts } from '../../../logic/accounts';
import { IItfAccount } from '../../../logic/types';
import CustomerDetailsKYC from './customers/CustomerDetailsKYC';
import CustomerMerchants from './customers/CustomerMerchants';
import AccountDetailsLimitBox from './customers/AccountDetailsLimitsBox';
import CustomerDetailsCardBox from './customers/customerDetailsCardsBox';
import CustomerFeesBox from './customers/cutomerFeesBox';
import CustomesConnections from './customers/customersConnections';
import CustomerTransactionSearch from './customers/customerTransactionSearch';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { ClientConfig } from '../../../common/config';
import CustomerVouchers from './customers/CustomerVouchers';
import BackofficeApprovals from './backoffice/backofficeApprovals';
import CustomerOpenApprovals from './customers/CustomerOpenApprovals';
import CustomerDetailsAdvancedKyc from './customers/CustomerDetailsAdvancedKyc';
import CustomerDetailsEID from './customers/CustomerDetailsEID';
import CustomerDetailsIdentifcationDistributor from './customers/identification/customerDetailsIdentificationDistributor';
import { ProductMaintenanceApi } from '../../../logic/api/account';
import ProductMaintenanceComponent from './customers/productMaintenanceComponent';
import JuniorAccountData from './customers/JuniorAccountData';
import { getFullPerson } from '../../../logic/helper/Common';

interface IProps extends RouteComponentProps {}

interface IState {
  showDetails: boolean;
  personId?: number;
  firstName?: string | undefined;
  lastName?: string | undefined;
  email?: string | undefined;
  selectedUser?: Ident.Person;
  instandID?: number;
}

class CustomerComponent extends React.Component<IProps, IState>
  implements INotifyAbleCustomerData {
  private firstname: string | undefined = undefined;
  private lastname: string | undefined = undefined;
  private email: string | undefined = undefined;
  private birthdate: Date | undefined = undefined;
  private data: Array<Ident.PersonFound> | undefined = undefined;
  constructor(props: IProps) {
    super(props);

    this.state = {
      showDetails: false,
      selectedUser: CustomerDataStore.getUser(),
    };
    this.upIDateStateAndStorage = this.upIDateStateAndStorage.bind(this);
    this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  componentDidMount(): void {
    CustomerDataStore.addChangeListener(this._onChangeCustomerData);
    this._onChangeCustomerData();
  }

  componentWillUnmount(): void {
    CustomerDataStore.removeChangeListener(this._onChangeCustomerData);
  }

  _onChangeCustomerData(): void {
    this.setState({
      selectedUser: CustomerDataStore.getUser(),
    });
  }

  fetchPersonOnAdd(personId: number) {
    const personSearchRequest: Ident.PersonSearchRequest = {
      search_id: personId.toString(),
    };

    api.request(
      personSearchRequest,
      apis.MaintenanceApi,
      'personSearch',
      (error: ApiError, response) => {
        if (response != null) {
          if (response.length === 1) {
            getFullPerson(response[0].person_id).then((result: Ident.Person) => {
              Accounts.getAccountsByPersonId(result.person_id).then(accounts => {
                let currentAccount: IItfAccount | undefined = undefined;
                if (accounts.length > 0) {
                  currentAccount = accounts[0];
                }
                Actions.setCustomerData({
                  accounts: accounts,
                  currentAccount: currentAccount,
                  selectedUser: result as Ident.Person,
                });
              });
            });
          }
        }
      },
    );
  }

  upIDateStateAndStorage(
    person: PersonFound,
    data: {
      firstname?: string;
      lastname?: string;
      email?: string;
      birthdate?: Date;
      data?: Array<Ident.PersonFound>;
    },
  ) {
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.email = data.email;
    this.birthdate = data.birthdate;
    this.data = data.data;

    history.push({
      pathname: '/customers/details',
      state: {
        showDetails: true,
        personId: person.person_id,
        firstName: person.given_name,
        lastName: person.name,
        email: person.primary_email_address,
      },
    });
  }

  redirect(path: string) {
    this.props.history.push(path);
  }

  render() {
    const roles = UserDataStore.getRoles();
    const service_center = roles.indexOf('service_center') > -1 && roles.length === 1;
    if (service_center) {
      return <Redirect to="/servicecenter/idqueue" />;
    }
    const tabValueNoPerson: Array<ITabvalues> = [
      {
        title: 'Search Customer',
        path: '/customers/search',
        name: 'search',
        callback: () => {
          return (
            <CustomerSearch
              notification={this.upIDateStateAndStorage}
              firstname={this.firstname}
              lastname={this.lastname}
              email={this.email}
              birthdate={this.birthdate}
              data={this.data}
              instantID={this.state.instandID}
              addCallback={() => {
                history.push({
                  pathname: '/customers/add',
                });
              }}
            />
          );
        },
      },
      {
        title: translate('backoffice.search.searchTrans'),
        path: '/customers/transactionlookup',
        name: 'transactionlookup',
        callback: () => {
          return <CustomerTransactionSearch callback={this.redirect} />;
        },
      },
      {
        title: 'Add Customer',
        path: '/customers/add',
        name: 'add',
        callback: () => {
          return <CustomerAddComponent createCallback={this.fetchPersonOnAdd} />;
        },
      },
    ];

    const tabValuesPerson: Array<ITabvalues> = [
      {
        name: 'accountList',
        title: 'Accounts',
        path: '/customers/details/accountList',
        callback: props => {
          if (this.state.selectedUser == null) {
            props.history.push('/customers');
            return null;
          }
          return <AccountDetailsBox />;
        },
      },
      {
        name: 'transactions',
        title: translate('nav.transactions'),
        path: '/customers/details/transactions',
        callback: props => {
          if (this.state.selectedUser == null) {
            props.history.push('/transactions');
            return null;
          }
          return <TransfersComponent redirectCallback={this.redirect} />;
        },
      },
      {
        name: 'limits',
        title: translate('nav.limits', 'Limits'),
        path: '/customers/details/limits',
        callback: props => {
          if (this.state.selectedUser == null) {
            props.history.push('/customers');
            return null;
          }
          return <AccountDetailsLimitBox />;
        },
      },
      {
        name: 'personDetails',
        title: translate('customers.customerDetails'),
        path: '/customers/details/personDetails',
        callback: props => {
          if (this.state.selectedUser == null) {
            props.history.push('/customers');
            return null;
          }
          return <CustomerDetailsPersonBox />;
        },
      },
      {
        name: 'identification',
        title: translate('customers.identification'),
        path: '/customers/details/identification',
        callback: props => {
          if (this.state.selectedUser == null) {
            props.history.push('/customers');
            return null;
          }
          return <CustomerDetailsIdentifcationDistributor />;
        },
      },
      {
        name: 'externalAccounts',
        title: translate('customers.details.externalAccounts.externalAccounts'),
        path: '/customers/details/externalAccounts',
        callback: props => {
          if (this.state.selectedUser == null) {
            props.history.push('/customers');
            return null;
          }
          return <CustomerDetailsExternalAccountsBox />;
        },
      },
      {
        name: 'cards',
        title: translate('customers.cards', 'Cards'),
        path: '/customers/details/cards',
        callback: props => {
          if (this.state.selectedUser == null) {
            props.history.push('/customers');
            return null;
          }
          return <CustomerDetailsCardBox />;
        },
      },
      {
        name: 'fees',
        title: translate('customers.fees'),
        path: '/customers/details/fees',
        callback: props => {
          if (this.state.selectedUser == null) {
            props.history.push('/customers');
            return null;
          }
          return <CustomerFeesBox />;
        },
      },
      {
        name: 'preferences',
        title: translate('customers.preferences'),
        path: '/customers/details/preferences',
        callback: props => {
          if (this.state.selectedUser == null) {
            props.history.push('/customers');
            return null;
          }
          return <CustomerDetailsPreferences />;
        },
      },
      {
        name: 'postingTemplates',
        title: translate('nav.postingTemplates'),
        path: '/customers/details/postingTemplates',
        callback: props => {
          if (this.state.selectedUser == null) {
            props.history.push('/postingTemplates');
            return null;
          }
          return <PostingTemplateComponent />;
        },
      },
      {
        name: 'reports',
        title: translate('nav.reports'),
        path: '/customers/details/reports',
        callback: props => {
          if (this.state.selectedUser == null) {
            props.history.push('/reports');
            return null;
          }
          return <ReportsComponent />;
        },
      },
      {
        name: 'connections',
        title: translate('nav.connections'),
        path: '/customers/details/connections',
        callback: props => {
          if (this.state.selectedUser == null) {
            props.history.push('/customers');
            return null;
          }
          return <CustomesConnections />;
        },
      },
    ];
    if (ClientConfig.jnrAccount) {
      tabValuesPerson.push({
        title: translate('customers.junior', 'Junior Account'),
        path: '/customers/details/junioraccount',
        name: 'junioraccount',
        callback: () => {
          return <JuniorAccountData />;
        },
      });
    }
    if (ClientConfig.advancedKyc) {
      tabValuesPerson.push({
        title: translate('customers.advancedKyc.advancedKyc', 'Advanced KYC'),
        path: '/customers/details/advancedKyc',
        name: 'advancedKyc',
        callback: () => {
          return <CustomerDetailsAdvancedKyc />;
        },
      });
    }
    if (ClientConfig.showVouchers) {
      tabValuesPerson.push({
        name: 'vouchers',
        title: translate('customers.vouchers'),
        path: '/customers/details/vouchers',
        callback: props => {
          if (this.state.selectedUser == null) {
            props.history.push('/customers');
            return null;
          }
          return <CustomerVouchers isBackoffice={false} />;
        },
      });
    }
    if (ClientConfig.showMerchants) {
      tabValuesPerson.push({
        name: 'merchants',
        title: translate('customers.details.merchant.merchant'),
        path: '/customers/details/merchants',
        callback: props => {
          if (this.state.selectedUser == null) {
            props.history.push('/customers');
            return null;
          }
          return <CustomerMerchants showPreview={true} />;
        },
      });
    }
    if (ClientConfig.showEid) {
      tabValuesPerson.push({
        name: 'eid',
        title: 'EID',
        path: '/customers/details/eid',
        callback: props => {
          if (this.state.selectedUser == null) {
            props.history.push('/customers');
            return null;
          }
          return <CustomerDetailsEID />;
        },
      });
    }
    tabValuesPerson.push({
      name: 'approvals',
      title: translate('backoffice.approvals.title'),
      path: '/customers/details/approvals',
      callback: props => {
        if (this.state.selectedUser == null) {
          props.history.push('customers');
          return null;
        }
        return <BackofficeApprovals isCustomerView={true} callback={this.redirect} />;
      },
    });
    tabValuesPerson.push({
      name: 'status',
      title: translate('customers.details.KYC.status'),
      path: '/customers/details/status',
      callback: props => {
        if (this.state.selectedUser == null) {
          props.history.push('customers');
          return null;
        }
        return <CustomerDetailsKYC />;
      },
    });
    if (this.state.selectedUser == null) {
      return (
        <Tabs
          activeTab="customers"
          basePath="/customers/"
          redicrect={'/customers/search'}
          tabList={tabValueNoPerson}
        />
      );
    } else {
      return (
        <Tabs
          activeTab="accountList"
          tabList={tabValuesPerson}
          redicrect="/customers/details/accountList"
          basePath="/customers/details/"
        />
      );
    }
  }
}

export default withRouter(CustomerComponent);
