const defaultTheme: ITheme = {
  ActivityIndicator: {
    color: '#0000ff',
  },
  Box: {
    background: '#FFFFFF',
    border: '1px solid #E8ECEF',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#E8ECEF',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  },
  Button: {
    backgroundColor: '#256E86',
    color: 'white',
    active: '#F7A62B',
    width: '206px',
    hover: '#5a9cb7',
    visited: '#00435a',
  },
  ButtonDisabled: {
    backgroundColor: '#E1E2E1',
    color: 'white',
  },
  ButtonCancel: {
    backgroundColor: '#F2F4F6',
    color: '#778CA2',
    hover: 'rgba(202,204,206, 1)',
    visited: '#F2F4F6',
    active: {
      backgroundColor: '#E7EBEE',
    },
  },
  AuthComponentButton: {
    marginTop: '60px',
    width: '100%',
  },
  Customer: {
    Details: {
      leftCell: {
        color: '',
      },
      rightCell: {
        color: '',
      },
    },
  },
  Input: {
    background: '#FFFFFF',
    disabled: '#F2F4F6',
    border: '1px solid #E8ECEF',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    height: '52px',
  },
  SearchCell: {
    backgroundColor: '#FFAB2B',
  },
  SearchText: {
    color: '#FFAB2B',
  },
  Select: {
    border: '1px solid #E8ECEF',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  },
  Global: {
    backgroundColor: '#F8FAFB',
    secondaryColor: '#100db2',
    primaryColor: '#060544',
    lightFontColor: '#382c70',
    darkFontColor: '#00001f',
    keyFontColor: '#98a9bc',
  },
  Table: {
    width: '100%',
    textTransform: 'none',

    THead: {
      background: '#FBFBFB',
      boxShadow: 'none',
      borderRadius: '4px 4px 0px 0px',
      boxSizing: 'border-box',
      height: '66px',
      alignItems: 'flex-end',
      margin: '0 4px 4px 4px',
      textTransform: 'none',

      Td: {
        boxSizing: 'border-box',
        border: '1px solid #E8ECEF',
        borderSize: '0 0 0 0',
        padding: '0 16px',
        color: '#778ca2',
        lineHeight: '21px',
        fontSize: '14px',
        letterSpacing: 'normal',
        textTransform: 'none',
      },
    },
    TBody: {
      background: '#FFFFFF',
      boxShadow: 'none',
      borderRadius: '4px 4px 0px 0px',
      boxSizing: 'border-box',
      height: '62px',
      alignItems: 'flex-start',
      textTransform: 'none',

      Td: {
        boxSizing: 'border-box',
        border: 'none',
        borderSize: '0 0 1px 0',
        padding: '0 16px',
        color: '#252631',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: 'normal',
        textTransform: 'none',
      },
    },
  },
  Title: {
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#778CA2',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '35px',
    spaceBetween: '0px',
    line: '1px solid #F2F4F6',
  },
};
defaultTheme.Button.backgroundColor = defaultTheme.Global.primaryColor;
defaultTheme.Button.hover = defaultTheme.Global.secondaryColor;

defaultTheme.Customer = {
  Details: {
    leftCell: {
      color: defaultTheme.Global.lightFontColor,
    },
    rightCell: {
      color: defaultTheme.Global.darkFontColor,
    },
  },
};

export const theme = defaultTheme;

//TODO: Apply ThemeInterface and change object to ThemeInterface
export const themeMap = new Map<string, ITheme>([['default', theme]]);

type AlignItemsType =
  | 'stretch'
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'baseline'
  | 'initial'
  | 'inherit';
type BorderRadiusType = string;
type BorderStyleType =
  | 'none'
  | 'hidden'
  | 'dotted'
  | 'dashed'
  | 'solid'
  | 'double'
  | 'groove'
  | 'ridge'
  | 'inset'
  | 'outset'
  | 'initial'
  | 'inherit';
type BorderType = string;
type BoxShadowType = string;
type BoxSizingType = 'content-box' | 'border-box' | 'initial' | 'inherit';
type ColourType = string;
type FontSizeType =
  | 'medium'
  | 'xx-small'
  | 'x-small'
  | 'small'
  | 'large'
  | 'x-large'
  | 'xx-large'
  | 'smaller'
  | 'larger'
  | string
  | 'initial'
  | 'inherit';
type FontStyleType = 'normal' | 'italic' | 'oblique' | 'initial' | 'inherit';
type FontWeightType =
  | 'normal'
  | 'bold'
  | 'bolder'
  | 'lighter'
  | number
  | 'initial'
  | 'inherit';
type LetterSpacingType = 'normal' | string | 'initial' | 'inherit';
type LineHeightType = 'normal' | number | string | 'initial' | 'inherit';
type SizeType = string;
type TextTransformType =
  | 'none'
  | 'capitalize'
  | 'uppercase'
  | 'lowercase'
  | 'initial'
  | 'inherit';

export interface ITheme {
  ActivityIndicator: {
    color: ColourType;
  };
  Box: {
    background: ColourType;
    border: BorderType;
    borderColor: ColourType;
    borderStyle: BorderStyleType;
    borderWidth: BorderType;
    boxSizing: BoxSizingType;
    boxShadow: BoxShadowType;
    borderRadius: BorderRadiusType;
  };
  Button: {
    backgroundColor: ColourType;
    color: ColourType;
    active: ColourType;
    width: SizeType;
    hover: ColourType;
    visited: ColourType;
  };
  ButtonDisabled: {
    backgroundColor: ColourType;
    color: ColourType;
  };
  ButtonCancel: {
    backgroundColor: ColourType;
    color: ColourType;
    hover: ColourType;
    visited: ColourType;
    active: {
      backgroundColor: ColourType;
    };
  };
  AuthComponentButton: {
    marginTop: SizeType;
    width: SizeType;
  };
  Customer: {
    Details: {
      leftCell: {
        color: ColourType;
      };
      rightCell: {
        color: ColourType;
      };
    };
  };
  Input: {
    background: ColourType;
    disabled: ColourType;
    border: BorderType;
    boxSizing: BoxSizingType;
    boxShadow: BoxShadowType;
    borderRadius: BorderRadiusType;
    height: SizeType;
  };
  SearchCell: {
    backgroundColor: ColourType;
  };
  SearchText: {
    color: ColourType;
  };
  Select: {
    border: BorderType;
    boxSizing: BoxSizingType;
    boxShadow: BoxShadowType;
    borderRadius: BorderRadiusType;
  };
  Global: {
    backgroundColor: ColourType;
    secondaryColor: ColourType;
    primaryColor: ColourType;
    lightFontColor: ColourType;
    darkFontColor: ColourType;
    keyFontColor: ColourType;
  };
  Table: {
    width: SizeType;
    textTransform: TextTransformType;

    THead: {
      background: ColourType;
      boxShadow: BoxShadowType;
      borderRadius: BorderRadiusType;
      boxSizing: BoxSizingType;
      height: SizeType;
      alignItems: AlignItemsType;
      margin: SizeType;
      textTransform: TextTransformType;

      Td: {
        boxSizing: BoxSizingType;
        border: BorderType;
        borderSize: BorderType;
        padding: SizeType;
        color: ColourType;
        lineHeight: LineHeightType;
        fontSize: FontSizeType;
        letterSpacing: LetterSpacingType;
        textTransform: TextTransformType;
      };
    };
    TBody: {
      background: ColourType;
      boxShadow: BoxShadowType;
      borderRadius: BorderRadiusType;
      boxSizing: BoxSizingType;
      height: SizeType;
      alignItems: AlignItemsType;
      textTransform: TextTransformType;

      Td: {
        boxSizing: BoxSizingType;
        border: BorderType;
        borderSize: BorderType;
        padding: SizeType;
        color: ColourType;
        fontSize: FontSizeType;
        lineHeight: LineHeightType;
        letterSpacing: LetterSpacingType;
        textTransform: TextTransformType;
      };
    };
  };
  Title: {
    fontWeight: FontWeightType;
    textTransform: TextTransformType;
    color: ColourType;
    fontStyle: FontStyleType;
    fontSize: FontSizeType;
    lineHeight: LineHeightType;
    spaceBetween: SizeType;
    line: BorderType;
  };
}
