import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Icons } from '../../../images';

interface IProps {
  children: Array<ReactElement> | ReactElement;
  valid?: boolean;
}

export const ValidationStateIndicator = styled.div`
  position: relative;
  & > div {
    margin-left: 20px;
    width: auto;
  }
  & > div.icon-wrapper {
    position: absolute;
    text-align: center;
    display: inline-block;
    margin-left: 0;
    margin-top: 4px;
    height: 16px;
    width: 16px;
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

export function ValidationIndicator(props: IProps) {
  return (
    <ValidationStateIndicator>
      {props.valid !== undefined ? (
        <div className="icon-wrapper">
          {props.valid === false ? Icons.warning() : Icons.checkBoxIcon()}
        </div>
      ) : null}
      {props.children}
    </ValidationStateIndicator>
  );
}
