import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Box } from '../boxes/box';
import { NavLink, Route, Redirect, Switch, useHistory } from 'react-router-dom';
import { FlexBox } from '../../../content/auth/auth.css';

const StyledViewMain = styled.div<{ inner?: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  // margin-top: 75px;
  margin-bottom: 4px;
  max-height: calc(100vh - 150px);
  overflow-x: ${props => (props.inner === true ? 'none' : 'auto')};
`;

const StyledBox = styled(Box)<{ inner?: boolean; minwidthNeeded?: boolean }>`
  width: 100%;
  min-width: ${props =>
    props.inner === true ? '1382px' : props.minwidthNeeded === false ? '0' : '1480px'};
  margin-top: -1px;
`;

const TabBar = styled.div`
  display: flex;
  flex-direction: row;
`;

const TabEntry = styled.div<{ active?: boolean }>`
  display: block;
  margin: 8px;
  height: 20px;
  font-size: 14px;
  color: ${props => props.theme.Global.keyFontColor};
  white-space: nowrap;
  outline: none;
  font-weight: ${props => (props.active === true ? 'bold' : 'normal')};
  &:hover {
    opacity: 0.6;
  }
`;

const StyledLink = styled(({ active, ...rest }) => <NavLink {...rest} />)`
  height: ${props => (props.active !== true ? '36px' : undefined)};
  text-decoration: none;
  background-color: ${props => props.theme.Box.background};
  border: none;
  outline: none;
  color: ${props => props.theme.Global.keyFontColor};
  border-bottom: ${props =>
    props.active === true ? '4px solid ' + props.theme.Global.primaryColor : 'none'};
  border-radius: 6px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  box-sizing: ${props => props.theme.Box.boxSizing};
  // box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25)
  hover {
    color: ${props => props.theme.Global.keyFontColor};
  }
`;

export interface ITabvalues {
  name: string;
  title: string;
  path: string;
  callback: (props: any) => JSX.Element | null;
  hidden?: boolean;
  ref?: boolean;
}
interface IProps {
  notification?: (tabName: string) => void;
  tabList: Array<ITabvalues>;
  basePath: string;
  activeTab: string;
  redicrect: string;
  aditionalComponent?: ReactElement;
  fireRef?: boolean;
  innerTab?: boolean;
  minwidthNeeded?: boolean;
  onUrlChange?: () => void;
}

interface IState {}

class Tabs extends React.Component<IProps, IState> {
  private ref = React.createRef<NavLink>();
  constructor(props: IProps) {
    super(props);

    const n: IState = {};

    this.state = n;
  }

  getLinkComponent(tabName: string, tabTitle: string, ref?: boolean): ReactElement {
    return (
      <StyledLink
        ref={ref === true ? this.ref : undefined}
        to={this.props.basePath + tabName}
        key={tabName}
        active={window.location.href.includes(tabName)}
      >
        <TabEntry active={window.location.href.includes(tabName)}>{tabTitle}</TabEntry>
      </StyledLink>
    );
  }

  buildLinkComponents() {
    const out: Array<JSX.Element> = [];
    for (const i in this.props.tabList) {
      if (this.props.tabList[i].hidden != null && this.props.tabList[i].hidden === true) {
        continue;
      }
      out.push(
        this.getLinkComponent(
          this.props.tabList[i].name,
          this.props.tabList[i].title,
          this.props.tabList[i].ref,
        ),
      );
    }
    if (this.props.aditionalComponent != null) {
      out.push(this.props.aditionalComponent);
    }
    return out;
  }

  buildRouteComponent() {
    const out: Array<JSX.Element> = [];
    for (const i in this.props.tabList) {
      out.push(
        <Route
          path={this.props.tabList[i].path}
          render={this.props.tabList[i].callback}
          key={this.props.tabList[i].name}
        />,
      );
    }
    return out;
  }

  render() {
    return (
      <React.Fragment>
        <StyledViewMain inner={this.props.innerTab}>
          <TabBar
            onClick={() => {
              if (this.props.onUrlChange != null) {
                this.props.onUrlChange();
              }
            }}
          >
            {this.buildLinkComponents()}
          </TabBar>
          <StyledBox inner={this.props.innerTab} minwidthNeeded={this.props.minwidthNeeded}>
            <FlexBox>
              <Switch>
                {this.buildRouteComponent()}
                <Redirect to={this.props.redicrect} />
              </Switch>
            </FlexBox>
          </StyledBox>
        </StyledViewMain>
      </React.Fragment>
    );
  }
}

export default Tabs;
