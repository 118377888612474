import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';
import { ReactElement } from 'react';

class IBubbleStore extends EventEmitter {
  bubble?: ReactElement = undefined;

  private static instance: IBubbleStore | null;

  constructor() {
    super();
    IDispatcher.register(this.registerActions.bind(this));
  }
  static getInstance(): IBubbleStore {
    if (IBubbleStore.instance == null) {
      IBubbleStore.instance = new IBubbleStore();
    }

    return IBubbleStore.instance;
  }

  registerActions(action: any) {
    switch (action.actionType) {
      default:
        break;
      case Constants.CLEAR_ALL:
        this.bubble = undefined;
        this.emit(CHANGE_EVENT);
        break;
      case Constants.BUBBLE_ADD:
        this.setBubble(action.bubble);
        this.emit(CHANGE_EVENT);
        break;
    }
    return true;
  }

  setBubble(bubble: ReactElement) {
    this.bubble = bubble;
  }

  getBubble() {
    return this.bubble;
  }

  addChangeListener(callback: any) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback: any) {
    this.removeListener(CHANGE_EVENT, callback);
  }
}

export const BubbleStore = IBubbleStore.getInstance();
