import { ReactNode } from 'react';
import {
  OverlayComponent,
  IOverlayComponentState,
  IOverlayComponentProps,
} from '../../../../components/compositcomponents/popup/overlayComponent';

export interface IRelativeProps extends IOverlayComponentProps {
  posX: string;
  posY: string;
}

export interface IRelativeState extends IOverlayComponentState {}

export abstract class RelativeOverlay<
  P extends IRelativeProps,
  S extends IRelativeState
> extends OverlayComponent<P, S> {
  abstract defineContent(): ReactNode;

  isCentered(): boolean {
    return false;
  }

  render() {
    return this.defineContent();
  }
}
