import React from 'react';
import styled from 'styled-components';
import { api, apis, ApiError, Ident } from '../../../../logic/api';
import {
  IInitProps,
  IInitState,
  InitialDataComponent,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import { IStatusState } from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { INotifyAbleCustomerData, CustomerDataStore } from '../../../../logic/flux';
import { translate } from '../../../../common/language/translate';
import { FlexBox } from '../../../auth/auth.css';
import { CustomCell, Helpertext } from './basicStyledComponents/customerDetails.css';
import { format } from '../../../../logic/helper/format';
import { ContainerText } from '../transfer/transfersComponent.css';
import Title from '../../../../components/compositcomponents/title';
import { Table as CoreTable } from '../../../../components/compositcomponents/table/table';
import { RowType } from '../../../../components/compositcomponents/table/tableTypes';
import { StyledLine } from '../backoffice/basicStyledComponents/styled';

interface IProps extends IInitProps {
  personId?: number;
}
interface IState extends IStatusState, IInitState {
  eidRequests?: Array<Ident.EidRequest>;
  eidFailed?: Array<Ident.EidVideoidFailed>;
  webhook?: Array<Ident.EidWebhookNotification>;
}

const StyledContentBox = styled(FlexBox)`
  flex-direction: column;
  padding: 0px 32px;
  overflow-x: auto;
`;

const ResposnsiveTableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1400px) {
    max-width: 800px;
    align-items: flex-start;
  }
  @media (max-width: 1100px) {
    max-width: 500px;
    align-items: flex-start;
  }
`;
export default class CustomerDetailsEID extends InitialDataComponent<IProps, IState>
  implements INotifyAbleCustomerData {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
  }
  private converter = function(line: any, index: number, fields: Array<string>): RowType {
    const row: RowType = { cells: [], ref: line };
    if (row.cells == null) {
      return row;
    }
    const keys: Array<string> = Object.keys(line);

    for (let i = 0; i < fields.length; i++) {
      const field: string = fields[i];
      switch (field) {
        default: {
          const val: string | Date = Object.values(line)[keys.indexOf(field)] as string | Date;
          const disp: any =
            val instanceof Date || field.includes('datetime')
              ? format.datetime(new Date(val))
              : val;
          if (Object.prototype.hasOwnProperty.call(line, field)) {
            row.cells.push({
              value: String(Object.values(line)[keys.indexOf(field)]),
              display: <ContainerText title={disp}>{disp}</ContainerText>,
              methods: {},
              copyVal: disp,
            });
          } else {
            row.cells.push({ value: '' });
          }
          break;
        }
      }
    }
    return row;
  };

  _onChangeCustomerData() {
    const tempUser = CustomerDataStore.getUser();
    if (tempUser != null) {
      this.getEIdRequests(tempUser.person_id);
      this.getFailedVideoIDs(tempUser.person_id);
      this.getNotifications(tempUser.person_id);
    }
  }

  getEIdRequests(personId: number) {
    const params: Ident.PersonElectronicidRequestListRequest = {
      person_id: personId,
    };
    api.request(
      params,
      apis.MaintenanceApi,
      'personElectronicidRequestList',
      (error: ApiError, response: Array<Ident.EidRequest>) => {
        if (response != null) {
          this.setState({
            eidRequests: response,
          });
        }
      },
    );
  }

  getFailedVideoIDs(personId: number) {
    const params: Ident.PersonElectronicidVideoidFailedListRequest = {
      person_id: personId,
    };
    api.request(
      params,
      apis.MaintenanceApi,
      'personElectronicidVideoidFailedList',
      (error: ApiError, response: Array<Ident.EidVideoidFailed>) => {
        if (response != null) {
          this.setState({
            eidFailed: response,
          });
        }
      },
    );
  }

  getNotifications(personId: number) {
    const params: Ident.PersonElectronicidWebhookNotificationListRequest = {
      person_id: personId,
    };
    api.request(
      params,
      apis.MaintenanceApi,
      'personElectronicidWebhookNotificationList',
      (error: ApiError, response: Array<Ident.EidWebhookNotification>) => {
        if (response != null) {
          this.setState({
            webhook: response,
          });
        }
      },
    );
  }

  reviveState(newState: { [key: string]: any }): void {}

  componentDidMount(): void {
    CustomerDataStore.addChangeListener(this._onChangeCustomerData);
    this._onChangeCustomerData();
  }

  componentWillUnmount() {
    CustomerDataStore.removeChangeListener(this._onChangeCustomerData);
  }
  render() {
    return (
      <StyledContentBox>
        <ResposnsiveTableWrapper>
          <Title title="Electronic ID" />
          <StyledLine />
          <TableBoxCoreData>
            <Title title={translate('customers.details.KYC.eid.requests', 'KYC')} />
            <CoreTable
              sourceData={this.state.eidRequests != null ? this.state.eidRequests : []}
              fields={[
                'url',
                'datetime_create',
                'session_id',
                'datetime_video_id',
                'video_id',
              ]}
              dataConverter={this.converter}
              hidePaging={true}
              header={[
                translate('customers.details.KYC.eid.request.url'),
                translate('customers.details.KYC.eid.request.datetime_create'),
                translate('customers.details.KYC.eid.request.session_id'),
                translate('customers.details.KYC.eid.request.datetime_video_id'),
                translate('customers.details.KYC.eid.request.video_id'),
              ]}
            />
          </TableBoxCoreData>
          <TableBoxCoreData>
            <Title title={translate('customers.details.KYC.eid.faileds', 'KYC')} />
            <CoreTable
              sourceData={this.state.eidFailed != null ? this.state.eidFailed : []}
              fields={['datetime_create', 'session_id', 'failure_reason', 'failure_category']}
              dataConverter={this.converter}
              hidePaging={true}
              header={[
                translate('customers.details.KYC.eid.failed.datetime_create'),
                translate('customers.details.KYC.eid.failed.session_id'),
                translate('customers.details.KYC.eid.failed.failure_reason'),
                translate('customers.details.KYC.eid.failed.failure_category'),
              ]}
            />
          </TableBoxCoreData>
          <TableBoxCoreData>
            <Title title={translate('customers.details.KYC.eid.webhooks', 'KYC')} />
            <CoreTable
              sourceData={this.state.webhook != null ? this.state.webhook : []}
              fields={[
                'datetime_create',
                'notification_id',
                'verification_id',
                'video_id',
                'verification_request_id',
              ]}
              dataConverter={this.converter}
              hidePaging={true}
              header={[
                translate('customers.details.KYC.eid.webhook.datetime_create'),
                translate('customers.details.KYC.eid.webhook.notification_id'),
                translate('customers.details.KYC.eid.webhook.verification_id'),
                translate('customers.details.KYC.eid.webhook.video_id'),
                translate('customers.details.KYC.eid.webhook.verification_request_id'),
              ]}
            />
          </TableBoxCoreData>
        </ResposnsiveTableWrapper>
      </StyledContentBox>
    );
  }
}

const TableBoxCoreData = styled.div`
  overflow-y: auto;
  margin-top: 16px;
  margin-bottom: 16px;
`;
