import React from 'react';
import styled from 'styled-components';
import { OverlayHandler } from '../../../logic/handler/overlayhandler/overlayHandler';

interface IProps {
  message?: string;
  warning?: boolean;
  bubblecount: number;
  confirmation?: boolean;
  callback?: () => void;
}

interface IState {
  callback: () => void;
  clicked?: boolean;
}

export class MessageBubble extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      callback: props.callback != null ? props.callback : () => {},
      clicked: false,
    };
    this.close = this.close.bind(this);
  }

  close() {
    OverlayHandler.closeSpecific('bubble');
  }

  render() {
    return (
      <StyledDivMain>
        <BubbleBody
          warning={this.props.warning === true}
          confirm={this.props.confirmation === true}
        >
          {this.props.message}
          {this.props.confirmation === true && this.props.callback != null ? (
            <ConfirmationBlock>
              <ConfirmField
                onClick={
                  //@ts-ignore
                  () => {
                    if (this.state.clicked !== true) {
                      this.state.callback();
                      this.setState({ clicked: true });
                    }
                  }
                }
              >
                {' '}
                Undo{' '}
              </ConfirmField>
            </ConfirmationBlock>
          ) : null}
        </BubbleBody>
      </StyledDivMain>
    );
  }
}

const BubbleBody = styled.div<{ warning: boolean; confirm: boolean }>`
  background: ${props =>
    !props.warning
      ? 'rgba(117, 178, 71, 0.91)'
      : props.confirm
      ? props.theme.Global.primaryColor
      : '#860802'};
  border-radius: 0.4em;
  padding: 12px;
  width: 180px;
  box-sizing: border-box;

  font-size: 14px;
  line-height: 14px;
  min-height: 28px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: #ffffff;
  text-align: center;

  :after,
  :before {
    left: 98.5%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  :after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: ${props =>
      !props.warning
        ? 'rgba(117, 178, 71, 0.91)'
        : props.confirm
        ? props.theme.Global.primaryColor
        : '#860802'};
    border-width: 10px;
    margin-top: -20px;
  }
  :before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: ${props =>
      !props.warning
        ? 'rgba(117, 178, 71, 0.91)'
        : props.confirm
        ? props.theme.Global.primaryColor
        : '#860802'};
    border-width: 14px;
    margin-top: -20px;
  }

  -webkit-animation: fadein 1s;
  -moz-animation: fadein 1s;
  -ms-animation: fadein 1s;
  -o-animation: fadein 1s;
  animation: fadein 1s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const StyledDivMain = styled.div``;
const ConfirmationBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 50%;
  margin-left: 25%;
`;
const ConfirmField = styled.div`
  color: #eeeeee;
  font-weight: 600;
  margin-top: 8px;
  padding: 4px;
  border-radius: 3px;
  background-color: ${props => props.theme.Global.lightFontColor};
  &: hover {
    cursor: pointer;
    color: grey;
  }
`;
