//@ts-nocheck
import styled from 'styled-components';
import { Select } from '../../../../../components/atomiccompoents/form/select';
import { BigInput } from '../../../../../components/atomiccompoents/form/inputs.css';
import { FlexBox } from '../../../../auth/auth.css';
import { TransitionBox } from '../../basicStyledComponents/contentsComponent.css';
import { Box } from '../../../../../components/atomiccompoents/boxes/box';
import { StyledSelect as CustomerSelect } from '../../basicStyledComponents/customersComponent.css';

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 15px 0px;
  font-size: 14px;
  margin-top: 26px;
  max-width: 600px;

  tr:last-child:not(:only-child) > td {
    border: none;
  }

  input#name,
  input#birth_date,
  input#place_of_birth,
  input#primary_email_address,
  input#given_name {
    background-color: #f2f4f6;
  }
`;
export const IconWrapper = styled.div`
  margin-left: 16px;
  min-width: 32px;
  :hover {
    cursor: pointer;
  }
  svg {
    margin-top: 16px;
  }
`;
export const StyledSmallInput = styled(BigInput)`
  width: 48%;
  margin-right: 12px;
`;

export const StyledInputView = styled(FlexBox)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const StyledSelect = styled(Select)`
  margin-top: 14px;
  margin-bottom: 14px;
  text-align: left;
  color: ${props => props.theme.Global.darkFontColor};
  line-height: 20px;
  background-color: rgba(255, 255, 255, 0);
  font-size: 14px;
  margin-right: 10px;
  height: 20px;
  width: 100%;
  font-weight: 300;
  font-family: Roboto;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
`;

export const StyledRawSelect = styled(StyledSelect)`
  margin-top: 0px;
  margin-bottom: 0px;
  width: 150px;
`;

export const NoMarginSelect = styled(StyledSelect)`
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const SmallStyledSelect = styled(Select)`
  max-width: 600px;
  margin-top: 16px;
  select {
    height: 40px;
    width: 96%;
    border-radius: 5px;
    border: 1px solid ${props => props.theme.Global.secondaryColor};
    padding-left: 15px;
    margin-bottom: 10px;
  }
  div {
    width: 96%;
  }
`;

export const TinySelect = styled(Select)`
  max-width: 80px;
  margin-top: 16px;
  select {
    height: 40px;
    width: 96%;
    border-radius: 5px;
    border: 1px solid ${props => props.theme.Global.secondaryColor};
    padding-left: 15px;
    margin-bottom: 10px;
  }
  div {
    width: 96%;
  }
`;

export const RawRowBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const RawColumnBox = styled(RawRowBox)`
  flex-direction: column;
`;

export const DocumentsWrapper = styled(FlexBox)`
  flex-direction: row;
  justify-content: space-between;
  max-height: 24px;
`;
export const AddWrapper = styled(FlexBox)`
  flex-direction: row;
  align-items: center;
  :hover {
    cursor: pointer;
    svg {
      fill: ${props => props.theme.Global.lightFontColor};
    }
  }
`;

export const CustomCell = styled.td`
  box-sizing: ${props => props.theme.Table.TBody.Td.boxSizing};
  border-bottom: 1px solid #dedede;
  padding: ${props => props.theme.Table.TBody.Td.padding};
  color: ${props => props.theme.Table.TBody.Td.color};
  line-height: ${props => props.theme.Table.TBody.Td.lineHeight};
  font-size: ${props => props.theme.Table.TBody.Td.fontSize};
  letter-spacing: ${props => props.theme.Table.TBody.Td.letterSpacing};
  text-transform: ${props => props.theme.Table.TBody.Td.textTransform};
  vertical-align: middle;
  width: 50%;
  & > span {
    text-align: right !important;
    color: #4a4a4a;
  }
`;

export const CustomRow = styled.tr`
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  background: rgba(0, 0, 0, 0);
  box-shadow: ${props => props.theme.Table.TBody.boxShadow};
  border-radius: ${props => props.theme.Table.TBody.borderRadius};
  box-sizing: ${props => props.theme.Table.TBody.boxSizing};
  height: ${props => props.theme.Table.TBody.height};
  align-items: ${props => props.theme.Table.TBody.alignItems};
  text-transform: ${props => props.theme.Table.TBody.textTransform};
`;
export const EditableWrapper = styled.div`
  margin-left: -16px;
  width: 450px;
  @media (max-height: 945px), {
    width: 450px;
  }
  @media (max-height: 845px), {
    width: 250px;
  }
  @media (max-height: 845px) and (max-width: 1354px) {
    width: 200px;
  }
  @media (max-width: 1830px) {
    width: 250px;
  }
`;
export const TitleCell = styled.div`
  font-weight: 600;
  color: ${props => props.theme.Global.keyFontColor};
`;
export const ContentCell = styled.div`
  width: 100%;
  text-align: right;
  color: ${props => props.theme.Global.darkFontColor};
`;
export const SmallCell = styled(CustomCell)`
  width: 25%;
`;
export const CheckboxCell = styled(SmallCell)`
  text-align: center !important;
`;
export const NoBorderCell = styled(CheckboxCell)`
  border: none;
  align-items: center;
  width: 50%;
  min-width: 0px;
`;
export const NoBorderSubTitleCell = styled(SmallCell)`
  & > div {
    margin-left: 16px;
  }
  border: none;
  width: 50%;
`;
export const MainTitleCell = styled(TitleCell)`
  color: ${props => props.theme.Global.darkFontColor};
  width: 50%;
  margin-left: 16px;
`;
export const HeaderCell = styled(MainTitleCell)`
  color: ${props => props.theme.Global.keyFontColor};
  font-size: 14px;
`;

export const MailBox = styled(TransitionBox)``;

export const Tr = styled.tr`
  width: 100%;
  border-bottom: 1px solid #ddd;
  height: 40px;

  & > td:last-child {
    text-align: right;
    color: ${props => props.theme.Customer.Details.rightCell.color};

    div,
    input {
      color: ${props => props.theme.Customer.Details.rightCell.color};
      text-align: right;
    }
  }
`;

export const Td = styled.td`
  line-height: 40px;
  white-space: nowrap;
  color: ${props => props.theme.Customer.Details.leftCell.color};

  & > div {
    color: ${props => props.theme.Customer.Details.leftCell.color};
    width: 100%;
  }

  & > span {
    word-break: normal;
    word-wrap: normal;
    line-height: 40px;
  }

  input {
    width: 100%;
  }
`;

export const CheckboxWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: initial;
  box-sizing: border-box;
`;

export const Helpertext = styled.div`
  font-size: 10px;
  color: ${props => props.theme.Button.backgroundColor};
`;

export const HelperCheckBox = styled(Helpertext)`
  height: 12px;
  margin-top: -4px;
  width: 170px;
`;

export const HelpTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 400px);
  flex-wrap: wrap;
  align-items: center;
  width: 90%;
  max-width: calc(100vw - 200px);
  min-height: 450px;
  flex: 1 1 52px;
`;

export const KeyValueBlock = styled.div<{ maxWidth?: string }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 450px;
  border-bottom: 1px solid #dedede;
  padding: ${props => props.theme.Table.TBody.Td.padding};
  margin: 8px;
  padding: 8px;
  @media (max-height: 945px), {
    max-width: ${props => (props.maxWidth != null ? props.maxWidth : '450px')};
  }
  @media (max-height: 845px), {
    max-width: 250px;
  }
  @media (max-height: 845px) and (max-width: 1354px) {
    max-width: 200px;
  }
`;

export const KeyField = styled.div`
  font-weight: 500;
  color: ${props => props.theme.Global.keyFontColor};
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ValueField = styled.div`
  text-align: left;
  min-height: 24px;
  font-size: 14px;
  color: ${props => props.theme.Global.darkFontColor};
  line-height: 24px;
  font-weight: 400;
`;
export const StyledBox = styled(Box)`
  margin-right: 24px;
  box-shadow: 4px 0px 4px -4px rgba(0, 0, 0, 0.25);
`;
export const SimpleHover = styled.div`
  :hover {
    cursor: pointer;
  }
`;
export const ResetWrapper = styled.div`
  margin-left: auto;
  margin-right: 8px;
  &:hover {
    cursor: pointer;
  }
`;
export const ImageCircle = styled.div`
  width: 168px;
  height: 168px;
  margin: 32px;
  border-radius: 50%;
  background-color: ${props => props.theme.Global.lightFontColor};
  color: white;
  font-weight: 550;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FloatingCircle = styled.div`
  border-radius: 50%;
  background-color: ${props => props.theme.Button.backgroundColor};
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 35px;
  :hover {
    cursor: pointer;
  }
`;

export const LargeKeyValueBlock = styled(KeyValueBlock)`
  height: 62px;
  max-width: 450px;
  @media (max-width: 1830px) {
    max-width: 250px;
  }
`;

export const StyledViewLeft = styled(FlexBox)`
  display: block;
  flex-grow: 1;
  max-width: 50%;
`;

export const NoMaxWidthSelect = styled(CustomerSelect)`
  max-width: 1700px;
  select {
    max-width: 1700px;
  }
`;
export const StyledViewButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 32px;
  margin-top: 40px;
  width: 100%;
  margin-bottom: 24px;
`;

export const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const MainField = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  padding: 16px;
  border-bottom: 1px solid #dedede;
  align-items: center;
`;
export const NameField = styled.div`
  width: 33%;
  margin-right: 16px;
  font-size: 18px;
`;

export const DateField = styled.div`
  width: 35%;
  fonts-size: 16px;
`;

export const IconWrapperPadding = styled.div`
  width: 32px;
  padding: 8px;
  svg {
    height: 16px;
    width: 16px;
  }
  :hover {
    cursor: pointer;
  }
`;
export const DataBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 85%;
`;
export const KeyValueRowBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  margin-top: 4px;
  width: 100%;
`;

export const KeyBlock = styled.div`
  width: 50%;
  color: ${props => props.theme.Global.keyFontColor};
  font-size: 1em;
  font-weight: 500;
  line-height: 1.5;
`;

export const ValueBlock = styled.div<{ isMax?: boolean }>`
  width: 50%;
  color: ${props => (props.isMax === true ? 'red' : props.theme.Global.darkFontColor)};
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
`;

export const ListEntry = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  margin: auto;
  width: 40%;
  display: flex;
  justify-content: space-between;
`;

export const NoWrapBlock = styled.div`
  width: 50%;
  overflow: hidden;
  text_overflow: ellipsis;
  whitespace: no_wrap;
`;

export const IconValueField = styled(ValueField)`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  svg {
    fill: black;
    margin-top: -6px;
  }
  :hover {
    cursor: pointer;
    svg {
      fill: ${props => props.theme.Global.lightFontColor};
    }
  }
`;
