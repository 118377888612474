//@ts-nocheck
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { IContextMenuType } from './tableTypes';
import { DisplayMenuComponent, IContextmenuMethods } from '../displayMenuComponent';

interface IProps {
  columnIndex: number;
  data?: ReactElement | string | number | IContextMenuType;
  highlightCellOnSearch?: boolean;
  highlightTextOnSearch?: boolean;
  onSort?: (columnIndex: number, sort: 'ASC' | 'DESC' | 'none') => void;
  searchValue?: string;
  isMatching?: boolean;
  isHeader?: boolean;
  rowIndex: number;
  colIndex: number;
  methods?: IContextmenuMethods;
  copyValue?: string;
  resize?: boolean;
  id?: string;
}

interface IState {
  data?: ReactElement | string | number | IContextMenuType;
  searchValue?: string;
}

export class Cell extends React.Component<IProps, IState> {
  private sort: 'ASC' | 'DESC' | 'none' = 'none';

  private StyledText = styled.div<{ resize?: boolean }>`
    flex-grow: 1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    mix-blend-mode: normal;
    opacity: 0.5;
    min-width: 50px;
    width: '100%';
    white-space: nowrap;
    z-index: 1;
    overflow: hidden !important;
    text-overflow: ellipsis;
    max-width: ${props => (props.resize === true ? 'none' : '250px')};
    background-color: rgba(0, 0, 0, 0);
    :hover {
      resize: ${props => (props.resize === true ? 'horizontal' : 'none')};
      -webkit-resizer {
        background: transparent;
      }
    }
  `;

  private Handle = styled.div`
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 16px;
    height: 16px;
    background: white;
    pointer-events: none;
    :hover {
      background-color: rgba(220, 220, 220, 0.2);
    }
  `;

  private MatchingCell = styled(this.StyledText)`
    background-color: ${props => props.theme.SearchCell.backgroundColor};
  `;

  constructor(props: IProps) {
    super(props);

    if (props.isHeader === true) {
      this.StyledText = styled(this.StyledText).attrs({
        className: 'row' + props.rowIndex + ' col' + props.colIndex,
      })`
        box-sizing: ${props => props.theme.Table.THead.Td.boxSizing};
        border: ${props => props.theme.Table.THead.Td.border};
        border-width: ${props => props.theme.Table.THead.Td.borderSize};
        padding: ${props => props.theme.Table.THead.Td.padding};
        color: ${props => props.theme.Table.THead.Td.color};
        line-height: ${props => props.theme.Table.THead.Td.lineHeight};
        font-size: ${props => props.theme.Table.THead.Td.fontSize};
        letter-spacing: ${props => props.theme.Table.THead.Td.letterSpacing};
        text-transform: ${props => props.theme.Table.THead.Td.textTransform};
        font-weight: 600;
        display: inline;
      `;

      this.MatchingCell = styled(this.MatchingCell).attrs({
        className: 'row' + props.rowIndex + ' col' + props.colIndex,
      })`
        box-sizing: ${props => props.theme.Table.THead.Td.boxSizing};
        border: ${props => props.theme.Table.THead.Td.border};
        border-width: ${props => props.theme.Table.THead.Td.borderSize};
        padding: ${props => props.theme.Table.THead.Td.padding};
        color: ${props => props.theme.Table.THead.Td.color};
        line-height: ${props => props.theme.Table.THead.Td.lineHeight};
        font-size: ${props => props.theme.Table.THead.Td.fontSize};
        letter-spacing: ${props => props.theme.Table.THead.Td.letterSpacing};
        text-transform: ${props => props.theme.Table.THead.Td.textTransform};
      `;
    } else {
      this.StyledText = styled(this.StyledText).attrs({
        className: 'row' + props.rowIndex + ' col' + props.colIndex,
      })`
        box-sizing: ${props => props.theme.Table.TBody.Td.boxSizing};
        border: ${props => props.theme.Table.TBody.Td.border};
        border-width: ${props => props.theme.Table.TBody.Td.borderSize};
        padding: ${props => props.theme.Table.TBody.Td.padding};
        color: ${props => props.theme.Table.TBody.Td.color};
        line-height: ${props => props.theme.Table.TBody.Td.lineHeight};
        font-size: ${props => props.theme.Table.TBody.Td.fontSize};
        letter-spacing: ${props => props.theme.Table.TBody.Td.letterSpacing};
        text-transform: ${props => props.theme.Table.TBody.Td.textTransform};
        font-weight: 500;
      `;

      this.MatchingCell = styled(this.MatchingCell).attrs({
        className: 'row' + props.rowIndex + ' col' + props.colIndex,
      })`
        box-sizing: ${props => props.theme.Table.TBody.Td.boxSizing};
        border: ${props => props.theme.Table.TBody.Td.border};
        border-width: ${props => props.theme.Table.TBody.Td.borderSize};
        padding: ${props => props.theme.Table.TBody.Td.padding};
        color: ${props => props.theme.Table.TBody.Td.color};
        line-height: ${props => props.theme.Table.TBody.Td.lineHeight};
        font-size: ${props => props.theme.Table.TBody.Td.fontSize};
        letter-spacing: ${props => props.theme.Table.TBody.Td.letterSpacing};
        text-transform: ${props => props.theme.Table.TBody.Td.textTransform};
      `;
    }

    this.state = {
      data: props.data,
    };
  }

  static getDerivedStateFromProps(props: IProps, state: IState): IState | null {
    if (props.searchValue !== state.searchValue || props.data !== state.data) {
      return {
        data: props.data,
        searchValue: props.searchValue,
      };
    }

    return null;
  }

  private highlight(
    data?: ReactElement | string | number | IContextMenuType,
  ): Array<ReactElement> | ReactElement | string | number | null | IContextMenuType {
    if (data == null) {
      return null;
    }
    if (
      this.props.highlightTextOnSearch === true &&
      this.state.searchValue != null &&
      (typeof data === 'string' || typeof data === 'number')
    ) {
      const start: number = data
        .toString()
        .toLowerCase()
        .indexOf(this.state.searchValue);
      if (start >= 0) {
        const before = data.toString().substr(0, start);
        const match = data.toString().substr(start, this.state.searchValue.length);
        const after = data.toString().substring(start + this.state.searchValue.length);
        return [
          <UnMatchText>
            <UnMatchText
              key={'cell_' + this.props.colIndex + '_' + this.props.rowIndex + '_before'}
            >
              {before}
            </UnMatchText>
            <MatchText
              key={'cell_' + this.props.colIndex + '_' + this.props.rowIndex + '_match'}
            >
              {match}
            </MatchText>
            <UnMatchText
              key={'cell_' + this.props.colIndex + '_' + this.props.rowIndex + '_after'}
            >
              {after}
            </UnMatchText>
          </UnMatchText>,
        ];
      } else {
        return data;
      }
    } else {
      return data;
    }
  }

  private isMatched(): boolean {
    if (
      this.props.highlightCellOnSearch !== true ||
      this.state.searchValue == null ||
      this.state.searchValue === '' ||
      this.state.data == null ||
      this.props.isHeader === true
    ) {
      return false;
    } else {
      return (
        this.state.data
          .toString()
          .toLowerCase()
          .indexOf(this.state.searchValue) >= 0
      );
    }
  }

  private onSort(): void {
    if (this.props.onSort != null) {
      switch (this.sort) {
        default:
        case 'none':
          this.sort = 'ASC';
          break;
        case 'ASC':
          this.sort = 'DESC';
          break;
        case 'DESC':
          this.sort = 'none';
          break;
      }
      this.props.onSort(this.props.columnIndex, this.sort);
    }
  }

  render() {
    if (this.isMatched()) {
      return (
        <TableData>
          <this.MatchingCell
            onClick={this.props.onSort == null ? undefined : () => this.onSort()}
            key={'cell_' + this.props.colIndex + '_' + this.props.rowIndex}
          >
            {this.highlight(this.state.data)}
          </this.MatchingCell>
        </TableData>
      );
    } else {
      if (this.props.isHeader === true) {
        return (
          <TableHeader>
            <this.StyledText
              resize={this.props.resize}
              title={this.state.data != null ? this.state.data.toString() : ''}
              onClick={this.props.onSort == null ? undefined : () => this.onSort()}
              key={'cell_' + this.props.colIndex + '_' + this.props.rowIndex}
            >
              {this.highlight(this.state.data)}
            </this.StyledText>
          </TableHeader>
        );
      } else {
        if (this.props.methods != null) {
          return (
            <TableData>
              <DisplayMenuComponent
                elementKey={'menu' + this.props.rowIndex + this.props.colIndex + this.props.id}
                methods={this.props.methods}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '62px',
                }}
                copyValue={this.props.copyValue != null ? this.props.copyValue : ''}
              >
                <this.StyledText
                  resize={this.props.resize}
                  onClick={this.props.onSort == null ? undefined : () => this.onSort()}
                  key={
                    'cell_' + this.props.colIndex + '_' + this.props.rowIndex + this.props.id
                  }
                >
                  {this.highlight(this.state.data)}
                </this.StyledText>
              </DisplayMenuComponent>
            </TableData>
          );
        } else {
          return (
            <TableData>
              <this.StyledText
                resize={this.props.resize}
                onClick={this.props.onSort == null ? undefined : () => this.onSort()}
                key={'cell_' + this.props.colIndex + '_' + this.props.rowIndex}
              >
                {this.highlight(this.state.data)}
              </this.StyledText>
            </TableData>
          );
        }
      }
    }
  }
}

const MatchText = styled.div`
  color: ${props => props.theme.SearchText.color};
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  min-width: 1px;
  display: flex;
  flex-direction: row;
`;

const TableData = styled.td`
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  vertical-align: middle;
  border-bottom: 1px solid #e8ecef;
  position: relative;
`;

const TableHeader = styled.th`
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-decoration: none;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 200;
`;
const UnMatchText = styled.div`
  display: flex;
  flex-direction: row;
`;
