import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { api, ApiError, apis, Ident } from '../../../../logic/api';
import { translate } from '../../../../common/language/translate';
import { FlexBox } from '../../../auth/auth.css';
import {
  Table,
  CustomRow,
  CustomCell,
  TitleCell,
  ContentCell,
} from '../customers/basicStyledComponents/customerDetails.css';
import {
  flattenJsonObject,
  getFullPerson,
  getPersonForAccount,
  loadAndShowImage,
  updatePerson,
} from '../../../../logic/helper/Common';
import { Icons } from '../../../../images';
import {
  OverlayHandler,
  Overlays,
} from '../../../../logic/handler/overlayhandler/overlayHandler';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { Log, Logs } from '../../../../logic/log';

const Main = styled(FlexBox)`
  max-height: calc(100vh - 300px);
  overflow: auto;
`;

const StyledViewMain = styled(FlexBox)`
  padding-left: 30px;
  padding-right: 30px;
`;

const Hover = styled.div`
  :hover {
    cursor: pointer;
  }
  svg {
    fill: ${props => props.theme.Global.primaryColor};
    width: 16px;
    height: 16px;
  }
`;

export interface IApprovalDetailsProps {
  releaseRequestId: number;
  error?: string;
  account_numbers?: Array<string>;
  person_id?: number;
  approver?: string;
  callback: (path: string) => void;
  api: apis.ReleaseApi | apis.ReleaseApiIdent;
}

interface IState {
  data?: any;
  additionalData?: boolean;
  releaseRequestId: number;
  type?: string;
  addData?: any;
  name?: string;
}

class ApprovalDetails extends React.Component<IApprovalDetailsProps, IState> {
  constructor(props: IApprovalDetailsProps) {
    super(props);
    this.state = {
      releaseRequestId: props.releaseRequestId,
      additionalData: false,
    };
    this.createTableContent = this.createTableContent.bind(this);
    this.fetchTransactionDetails = this.fetchTransactionDetails.bind(this);
    this.fetchTransactionDetails();
  }

  componentWillReceiveProps(props: IApprovalDetailsProps) {
    this.setState(
      {
        releaseRequestId: props.releaseRequestId,
        additionalData: false,
      },
      () => {
        this.fetchTransactionDetails();
        if (props.person_id != null) {
          this.getPersonData(props.person_id);
        } else {
          this.setState({
            name: undefined,
          });
        }
      },
    );
  }

  getPersonData(person_id: number) {
    getFullPerson(person_id)
      .then((response: Ident.Person) => {
        this.setState({
          name: response.given_name + ' ' + response.name,
        });
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
      });
  }

  fetchTransactionDetails() {
    if (this.state.releaseRequestId === -1) {
      this.setState({
        data: undefined,
        additionalData: false,
      });
      return;
    }
    const req = {
      release_requests_id: this.state.releaseRequestId,
    };
    api
      .asyncRequest<any>(req, this.props.api, 'releasesDetailsGet')
      .then((response: any) => {
        this.setState({
          data: response.ReleasesDetails[0].release_details,
          addData: response.ReleasesDetails[0].additional_data,
          type: response.ReleasesDetails[0].release_type,
          additionalData:
            response.ReleasesDetails[0].release_type === 'reversal' ? true : false,
        });
      })
      .catch(error => {
        this.setState({
          data: undefined,
        });
      });
  }

  createTableContent() {
    if (this.state.data != null) {
      const data = this.state.data;
      switch (this.state.type) {
        default:
          return this.createTransactionContent(data);
      }
    }
  }

  createAddData() {
    const out = [];
    if (this.state.addData != null) {
      const flatData = flattenJsonObject(this.state.addData) as any;
      const keys = Object.keys(flatData);
      for (const i in keys) {
        if (keys[i] === 'document' && this.props.person_id != null) {
          out.push(
            <CustomRow key={i + '_' + keys[i]}>
              <CustomCell key={i + 'cellkey'} style={{ borderBottom: 'none' }}>
                <TitleCell style={{ marginLeft: '32px' }}>
                  {translate('backoffice.approvals.details.' + keys[i], keys[i])}
                </TitleCell>
              </CustomCell>
              <CustomCell key={i + 'cellvalue'} style={{ borderBottom: 'none' }}>
                <Hover
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                  onClick={() => {
                    if (this.props.person_id != null) {
                      loadAndShowImage(this.props.person_id, flatData[keys[i]]);
                    }
                  }}
                >
                  {Icons.collection()}
                </Hover>
              </CustomCell>
            </CustomRow>,
          );
        } else if (keys[i].startsWith('source_of_income.')) {
          if (keys[i] === 'source_of_income.0') {
            const sourceOfIncomeString = this.state.addData.source_of_income.reduce(
              (accum, soi) => (accum !== null ? accum + ', ' : '') + soi.toString(),
              null
            );
            out.push(
              <CustomRow key={i + '_source_of_income'}>
                <CustomCell key={i + 'cellkey'} style={{ borderBottom: 'none' }}>
                  <TitleCell style={{ marginLeft: '32px' }}>
                    {translate('backoffice.approvals.details.source_of_income', 'source_of_income')}
                  </TitleCell>
                </CustomCell>
                <CustomCell key={i + 'cellvalue'} style={{ borderBottom: 'none' }}>
                  <ContentCell>{sourceOfIncomeString}</ContentCell>
                </CustomCell>
              </CustomRow>,
            );
          }
          // Ignore all other source_of_income keys
        } else {
          out.push(
            <CustomRow key={i + '_' + keys[i]}>
              <CustomCell key={i + 'cellkey'} style={{ borderBottom: 'none' }}>
                <TitleCell style={{ marginLeft: '32px' }}>
                  {translate('backoffice.approvals.details.' + keys[i], keys[i])}
                </TitleCell>
              </CustomCell>
              <CustomCell key={i + 'cellvalue'} style={{ borderBottom: 'none' }}>
                <ContentCell>{flatData[keys[i]].toString()}</ContentCell>
              </CustomCell>
            </CustomRow>,
          );
        }
      }
    }
    return out;
  }
  createTransactionContent(data: any): Array<ReactElement> {
    const out: Array<ReactElement> = [];
    const flatData = flattenJsonObject(data) as any;
    const keys = Object.keys(flatData);
    if (this.state.type === 'reversal') {
      return [
        <CustomRow key={'transactionID'}>
          <CustomCell key={'transactionID_title'}>
            <TitleCell style={{ marginLeft: '32px' }}>
              {translate('backoffice.approvals.details.transaction_id')}
            </TitleCell>
          </CustomCell>
          <CustomCell key={'ta_idcellvalue'} style={{ borderBottom: 'none' }}>
            <ContentCell>
              {data.Reversal != null
                ? data.Reversal.ta_id + '/' + data.Reversal.transaction_id
                : flatData.ta_id + '/' + flatData.transaction_id}
            </ContentCell>
          </CustomCell>
        </CustomRow>,
      ];
    }
    for (const i in keys) {
      if (
        keys[i] === 'face' ||
        keys[i] === 'document_sides_front' ||
        keys[i] === 'document_sides_back'
      ) {
        continue;
      }
      out.push(
        <CustomRow key={i + '_' + keys[i]}>
          <CustomCell key={i + 'cellkey'} style={{ borderBottom: 'none' }}>
            <TitleCell style={{ marginLeft: '32px' }}>
              {translate('backoffice.approvals.details.' + keys[i], keys[i])}
            </TitleCell>
          </CustomCell>
          <CustomCell key={i + 'cellvalue'} style={{ borderBottom: 'none' }}>
            <ContentCell>{flatData[keys[i]].toString()}</ContentCell>
          </CustomCell>
        </CustomRow>,
      );
    }
    if (this.state.name != null && this.state.type !== 'approve_address') {
      out.push(
        <CustomRow key={'name'}>
          <CustomCell key={'cellkeyName'} style={{ borderBottom: 'none' }}>
            <TitleCell style={{ marginLeft: '32px' }}>{'Name'}</TitleCell>
          </CustomCell>
          <CustomCell key={'cellvalueName'} style={{ borderBottom: 'none' }}>
            <ContentCell>{this.state.name}</ContentCell>
          </CustomCell>
        </CustomRow>,
      );
    }
    return out;
  }

  reviveState(): void {}

  generateAdditionalFields() {
    if (this.state.type === 'reversal') {
      const ta_id =
        this.state.data.Reversal != null
          ? this.state.data.Reversal.ta_id
          : this.state.data.ta_id;
      const transaction_id =
        this.state.data.Reversal != null
          ? this.state.data.Reversal.transaction_id
          : this.state.data.transaction_id;
      return (
        <Hover
          style={{ display: 'flex' }}
          onClick={() => {
            OverlayHandler.showOverlay(Overlays.postingJournal, {
              taId: ta_id,
              transactionId: transaction_id,
              openedFromSearch: true,
              callback: this.props.callback,
              boCallback: this.props.callback,
            });
          }}
        >
          <div style={{ display: 'flex', marginRight: '16px' }}>{Icons.detailOverlay()}</div>
          PostingJournal
        </Hover>
      );
    }
  }

  createAccountNumberView(numbers: Array<string>) {
    const out = [];
    for (const o in numbers) {
      out.push(
        <Hover
          onClick={() => {
            getPersonForAccount(numbers[o])
              .then((result: Array<number>) => {
                if (result.length === 1) {
                  updatePerson(
                    result[0],
                    '/customers/details/transactions/customertransactions',
                    this.props.callback,
                  );
                } else {
                  MessageHandler.onError(Reporter['account.person.get.multipleResults']);
                }
              })
              .catch((error: ApiError) => {
                MessageHandler.onError(Reporter['account.person.get']);
              });
          }}
        >
          {' '}
          {numbers[o] + ' '}
        </Hover>,
      );
    }
    return out;
  }

  render() {
    return (
      <Main>
        <FlexBox>
          <StyledViewMain>
            <ScrollView>
              {this.state.data != null ? (
                <Table style={{ marginTop: '12px' }}>
                  <tbody>
                    {this.createTableContent()}
                    {this.createAddData()}
                    {this.props.error != null ? (
                      <CustomRow>
                        <CustomCell key={'errorcellkey'} style={{ borderBottom: 'none' }}>
                          <TitleCell
                            style={{
                              marginLeft: '32px',
                              color: '#a64452',
                            }}
                          >
                            {translate('backoffice.approvals.details.error')}
                          </TitleCell>
                        </CustomCell>
                        <CustomCell key={'errorcellvalue'} style={{ borderBottom: 'none' }}>
                          <ContentCell>{this.props.error}</ContentCell>
                        </CustomCell>
                      </CustomRow>
                    ) : null}
                    {this.props.account_numbers != null ? (
                      <CustomRow>
                        <CustomCell key={'accNrcell'} style={{ borderBottom: 'none' }}>
                          <TitleCell
                            style={{
                              marginLeft: '32px',
                              color: '#a64452',
                            }}
                          >
                            {translate('backoffice.approvals.details.account_numbers')}
                          </TitleCell>
                        </CustomCell>
                        <CustomCell key={'accNrcellvalue'} style={{ borderBottom: 'none' }}>
                          <ContentCell>
                            {this.createAccountNumberView(this.props.account_numbers)}
                          </ContentCell>
                        </CustomCell>
                      </CustomRow>
                    ) : null}
                    {this.props.person_id != null ? (
                      <CustomRow>
                        <CustomCell key={'personIdcell'} style={{ borderBottom: 'none' }}>
                          <TitleCell
                            style={{
                              marginLeft: '32px',
                              color: '#a64452',
                            }}
                          >
                            <Hover
                              onClick={() => {
                                updatePerson(
                                  this.props.person_id != null ? this.props.person_id : -1,
                                  '/customers/details/accountList',
                                  this.props.callback,
                                );
                              }}
                            >
                              {translate('backoffice.approvals.details.openPerson')}
                            </Hover>
                          </TitleCell>
                        </CustomCell>
                        <CustomCell key={'personIdcellvalue'} style={{ borderBottom: 'none' }}>
                          <ContentCell></ContentCell>
                        </CustomCell>
                      </CustomRow>
                    ) : null}
                    {this.props.approver != null ? (
                      <CustomRow>
                        <CustomCell key={'approvercell'} style={{ borderBottom: 'none' }}>
                          <TitleCell
                            style={{
                              marginLeft: '32px',
                              color: '#a64452',
                            }}
                          >
                            {translate('backoffice.approvals.details.approver')}
                          </TitleCell>
                        </CustomCell>
                        <CustomCell key={'approvercellvalue'} style={{ borderBottom: 'none' }}>
                          <ContentCell>
                            {this.props.approver}
                          </ContentCell>
                        </CustomCell>
                      </CustomRow>
                    ) : null}
                    {this.state.additionalData !== false ? (
                      <CustomRow>
                        <TitleCell style={{ marginLeft: '32px' }}>
                          <AdditionalFields>
                            {this.generateAdditionalFields()}
                          </AdditionalFields>
                        </TitleCell>
                      </CustomRow>
                    ) : null}
                  </tbody>
                </Table>
              ) : (
                <Table>
                  <tbody>
                    <CustomRow>
                      <CustomCell key={'cellkey'} style={{ borderBottom: 'none' }}>
                        <TitleCell style={{ marginLeft: '32px' }}></TitleCell>
                      </CustomCell>
                      <CustomCell key={'cellvalue'} style={{ borderBottom: 'none' }}>
                        <ContentCell></ContentCell>
                      </CustomCell>
                    </CustomRow>
                  </tbody>
                </Table>
              )}
            </ScrollView>
          </StyledViewMain>
        </FlexBox>
      </Main>
    );
  }
}

const ScrollView = styled(FlexBox)`
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 20px;
`;

const AdditionalFields = styled.td``;
export default ApprovalDetails;
