import React, { ReactElement } from 'react';
import { PersonFound } from '../../../../logic/api/ident';
import { Ident, Account } from '../../../../logic/api';
import { OverlayHandler } from '../../../../logic/handler/overlayhandler/overlayHandler';
import { Accounts } from '../../../../logic/accounts';
import { IItfAccount } from '../../../../logic/types';
import { PermissionStore, Actions } from '../../../../logic/flux';
import { SearchResultBox, IResultProps } from '../../../../components/seachResultBox';
import { SearchResultItem } from '../../../../components/searchResultItem';
import { getFullPerson } from '../../../../logic/helper/Common';
import styled from 'styled-components';
import { Icons } from '../../../../images';
import { StyledLine } from '../backoffice/basicStyledComponents/styled';

interface IProps extends IResultProps {
  notification?: (person: PersonFound) => void;
  data: Array<Ident.PersonFound>;
  nextCallback?: () => void;
  prevCallback?: () => void;
}

class CustomerResultsBox extends SearchResultBox<IProps> {
  notify(person: PersonFound) {
    const permissions = [
      Account.OperationId.PersonAccountsPersonIdGet,
      Account.OperationId.AccountGet,
      Ident.OperationId.PersonAddressGet,
    ];
    if (!PermissionStore.hasOnePermissionOf(permissions)) {
      return null;
    }

    OverlayHandler.updateApp();
    getFullPerson(person.person_id).then((result: Ident.Person) => {
      Accounts.getAccountsByPersonId(person.person_id).then(accounts => {
        let currentAccount: IItfAccount | undefined = undefined;
        if (accounts.length > 0) {
          currentAccount = accounts[0];
        }
        Actions.setCustomerData({
          accounts: accounts,
          currentAccount: currentAccount,
          selectedUser: result as Ident.Person,
        });
      });
    });
    // return this.props.notification != null ? this.props.notification(person) : () => {};
  }
  createData() {
    const data: ReactElement[] = [];
    if (this.props.nextCallback != null || this.props.prevCallback != null) {
      data.push(
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            margin: '16px',
          }}
        >
          {this.props.prevCallback != null ? (
            <Hover
              onClick={() => {
                if (this.props.prevCallback == null) {
                  return;
                }
                this.props.prevCallback();
              }}
            >
              {Icons.pagingArrowLeft()}
            </Hover>
          ) : null}
          {this.props.nextCallback != null ? (
            <Hover
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: '32px',
              }}
              onClick={() => {
                if (this.props.nextCallback == null) {
                  return;
                }
                this.props.nextCallback();
              }}
            >
              {Icons.pagingArrowRight()}
            </Hover>
          ) : null}
        </div>,
      );
      data.push(<StyledLine style={{ marginTop: '2px', marginBottom: '8px' }} />);
    }

    if (this.props.data != null && this.props.data.length > 0) {
      for (const person in this.props.data) {
        data.push(
          <SearchResultItem
            title={this.props.data[person].given_name + ' ' + this.props.data[person].name}
            subTitle={this.props.data[person].primary_email_address}
            onClick={() => this.notify(this.props.data[person])}
            entity={this.props.data[person].entity}
            key={this.props.data[person].person_id}
          />,
        );
      }
    }
    return data;
  }
}

const Hover = styled.div`
  width: 64px;
  :hover {
    cursor: pointer;
  }
  svg {
    fill: ${props => props.theme.Global.primaryColor};
  }
`;

export default CustomerResultsBox;
