import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { api, apis, ApiError, Ident, VoidResponse } from '../../../../../logic/api';
import { Log, Logs } from '../../../../../logic/log';
import { format } from '../../../../../logic/helper/format';
import { coalesce, evaluateErrorMessage } from '../../../../../logic/helper/Common';
import { translate } from '../../../../../common/language/translate';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import { RowType } from '../../../../../components/compositcomponents/table/tableTypes';
import {
  AccessKey,
  AccessKeyStateEnum,
  PersonPersonIdAccessKeyDeleteRequest,
  PersonPersonIdAccessKeyGetRequest,
} from '../../../../../logic/api/ident';
import { PersonAccountsAccessKeyPostRequest } from '../../../../../logic/api/account';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import { Table } from '../../../../../components/compositcomponents/table/table';
import Switch from '../../../../../components/atomiccompoents/switch';

const ActionBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 90px;
`;

const AccessKeyTableView = styled.div`
  margin-top: 16px;
  overflow-y: auto;
  table {
    table-layout: fixed;
  }
  table > tbody > tr > td {
    border: 1px solid #e8ecef;
    border-width: 0 0 1px 0;
  }
  table > thead > tr > th:nth-child(1) {
    width: 25%;
  }
  table > tbody > tr > td:nth-child(1) > div,
  table > thead > tr > th:nth-child(1) > div {
    min-width: 100%;
  }
  table > thead > tr > th:nth-child(4) > div,
  table > thead > tr > th:nth-child(4) {
    width: 100px;
  }
  table > tbody > tr > td:nth-child(4) > div > div {
    width: 100px;
    justify-content: center;
  }
  table > thead > tr > th:nth-child(2) {
    width: 13%;
  }
  table > thead > tr > th:nth-child(2) > div,
  table > tbody > tr > td:nth-child(2) > div {
    min-width: 100%;
  }
`;

const AccessComponent = styled.div`
  margin-left: 24px;
  margin-top: 24px;
`;

interface IProps {
  currentAccount: string;
  user: Ident.Person;
}

export const AccessKeyComponent: FunctionComponent<IProps> = (props: IProps) => {
  const converter = (line: AccessKey, index: number, fields: Array<string>): RowType => {
    const row: RowType = { cells: [], ref: line };
    if (row.cells == null) {
      return row;
    }
    const keys: Array<string> = Object.keys(line);
    for (let i = 0; i < fields.length; i++) {
      const field: string = fields[i];
      switch (field) {
        case 'access_key':
          row.cells.push({
            value: line.access_key + '',
            display: <span>{line.access_key}</span>,
          });
          break;
        case 'state':
          row.cells.push({
            value:
              line.state === AccessKeyStateEnum.A
                ? 'active'
                : line.state === AccessKeyStateEnum.C
                ? 'created'
                : 'inactive',
            display: (
              <span>
                {line.state === AccessKeyStateEnum.A
                  ? 'active'
                  : line.state === AccessKeyStateEnum.C
                  ? 'created'
                  : 'inactive'}
              </span>
            ),
          });
          break;
        case 'datetime_create':
          row.cells.push({
            value: line.datetime_create + '',
            display: <span>{format.datetime(line.datetime_create)}</span>,
          });
          break;
        case 'datetime_deactivate':
          row.cells.push({
            value: line.datetime_deactivate + '',
            display: <span>{format.datetime(line.datetime_deactivate)}</span>,
          });
          break;
        case 'actions':
          row.cells.push({
            value: line.access_key + '',
            display: (
              <ActionBox>
                <Switch
                  id={'deleteAccessKeySwitch'}
                  defaultValue={line.state === AccessKeyStateEnum.D ? false : true}
                  disabled={line.state === AccessKeyStateEnum.D}
                  onChange={() => deleteAccessKey(line.access_key, line.state)}
                />
              </ActionBox>
            ),
          });
          break;
        default:
          if (Object.prototype.hasOwnProperty.call(line, field)) {
            row.cells.push({
              value: Object.values(line)[keys.indexOf(field)],
              display: <span>{Object.values(line)[keys.indexOf(field)]}</span>,
            });
          } else {
            row.cells.push({ value: '' });
          }
          break;
      }
    }
    return row;
  };

  const [accessKeys, setAccessKeys] = useState<Array<Ident.AccessKey | any>>([]);
  const [keyForLoadingSpinner, setKey] = useState(Math.floor(Math.random() * 10000000));

  function getAccessKeys() {
    const req: PersonPersonIdAccessKeyGetRequest = {
      person_id: props.user.person_id,
      show_deactivated: true,
    };

    api
      .asyncRequest<Array<AccessKey>>(req, apis.MaintenanceApi, 'personPersonIdAccessKeyGet')
      .then(response => {
        if (response != null) {
          setAccessKeys(response);
        }
      })
      .catch((error: ApiError) => {
        Log.debug(Logs.API, error);
        MessageHandler.onError(
          Reporter['person.merchant.put.error'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
        setKey(Math.floor(Math.random() * 10000000));
      });
  }

  /**
   * deactivates selected acccessKey and reloads data
   * @param accessKey to delete
   * @param state state of key, for checking whether it is already deactivated
   */
  function deleteAccessKey(accessKey: string | undefined, state: string | undefined) {
    if (props.user != null && accessKey != null && state !== AccessKeyStateEnum.D) {
      const req: PersonPersonIdAccessKeyDeleteRequest = {
        access_key: accessKey,
        person_id: props.user.person_id,
      };
      api
        .asyncRequest<VoidResponse>(req, apis.MaintenanceApi, 'personPersonIdAccessKeyDelete')
        .then(response => {
          if (response != null && props.user != null) {
            getAccessKeys();
          }
        })
        .catch((error: ApiError) => {
          Log.debug(Logs.API, error);
          MessageHandler.onError(
            Reporter['person.merchant.put.error'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
          setKey(Math.floor(Math.random() * 10000000));
        });
    }
  }
  /**
   * generates new accesskey and reloads data
   */
  function generateAccessKey() {
    if (props.user != null && props.currentAccount != null) {
      const req: PersonAccountsAccessKeyPostRequest = {
        account_number: props.currentAccount,
        person_id: props.user.person_id,
        AccessKeyName: { name: 'test' },
      };
      api
        .asyncRequest<VoidResponse>(req, apis.DefaultApi, 'personAccountsAccessKeyPost')
        .then(response => {
          if (response != null) {
            getAccessKeys();
            MessageHandler.onSuccess(Reporter['person.merchant.sucesskey']);
          }
        })
        .catch((error: ApiError) => {
          Log.debug(Logs.API, error);
          MessageHandler.onError(
            Reporter['person.merchant.put.error'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
          setKey(Math.floor(Math.random() * 10000000));
        });
    } else {
      MessageHandler.onError(Reporter['person.merchant.put.error']);
      setKey(Math.floor(Math.random() * 10000000));
    }
  }

  useEffect(() => {
    getAccessKeys();
  }, []);

  /**
   * expand responsedata by actionfield
   */
  function addActions() {
    const data = accessKeys;
    for (const i in data) {
      data[i].actions = 'delete';
    }
    return data;
  }

  return (
    <AccessComponent>
      <ButtonOk
        id="btnGenerateAccKey"
        key={keyForLoadingSpinner}
        onClick={() => generateAccessKey()}
      >
        {translate('customers.details.merchant.generateAccessKey')}
      </ButtonOk>
      <AccessKeyTableView>
        <Table
          sourceData={addActions()}
          header={[
            translate('customers.details.merchant.accessKeyId'),
            translate('customers.details.merchant.status'),
            translate('customers.details.merchant.created'),
            translate('customers.details.merchant.makeInactive'),
            translate('customers.details.merchant.deactivated'),
          ]}
          fields={['access_key', 'state', 'datetime_create', 'actions', 'datetime_deactivate']}
          dataConverter={converter}
          sortDirection="DESC"
          sortColumn={2}
        />
      </AccessKeyTableView>
    </AccessComponent>
  );
};
