//@ts-nocheck
import React, { ReactElement } from 'react';
import * as C from './content/';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Log, Logs } from '../../logic/log';
import { APages } from '../../components/nav';
import { UserDataStore } from '../../logic/flux';

const PATH = process.env.REACT_APP_ROOT_PATH;

// conflict with import, app wont build with this block uncommented ++++++++++++++++++++++
// export interface IPage {
//     display: boolean;
//     active: boolean;
//     icon: string | null;
//     text: string;
//     showText: boolean;
//     showIcon: boolean;
//     url: string;
//     category?: string;
//     addButton?: boolean;
//     addComponent?: ReactElement | null;
// }

export default class Pages extends APages {
  getActiveComponent(currentUrl: string): ReactElement {
    Log.debug(
      Logs.SYSTEM,
      'Computing component for path: ' + currentUrl + ', respecting root path: ' + PATH,
    );

    const lastPath = window.sessionStorage.getItem('lastPath');
    const searchPath = window.sessionStorage.getItem('lastSearchPath');
    if (lastPath != null || searchPath != null) {
      window.sessionStorage.removeItem('lastPath');
      window.sessionStorage.removeItem('latsSearchPath');
    }

    let routes: Array<ReactElement> = [];
    const roles = UserDataStore.getRoles();
    if (roles.indexOf('service_center') === -1 || roles.length > 1) {
      routes = [
        <Route default path="/customers" component={C.CustomersComponent} />,

        <Route path="/backoffice" component={C.BackOfficeComponent} />,

        <Route
          path="/backoffice/postingTemplates"
          component={C.BackOfficeTemplateComponent}
        />,
        <Route path="/administration" component={C.AdministrationComponent} />,
        <Route path="/dashboard" component={C.DashboardComponent} />,
        <Route path="/servicecenter" component={C.ServiceCenterComponent} />,
        <Route path="/productmanagement" component={C.ProductManagementComponent} />,
        <Route exact path="/">
          <Redirect
            from="/"
            to={
              lastPath == null
                ? searchPath == null
                  ? '/customers'
                  : '/customers' + searchPath
                : lastPath + searchPath
            }
          />
        </Route>,
      ];
    } else {
      routes = [
        <Route default path="/servicecenter" component={C.ServiceCenterComponent} />,
        <Route path="/*">
          <Redirect to="/servicecenter" />
        </Route>,
      ];
    }

    return (
      <Route basename={PATH}>
        <Switch>{routes}</Switch>
      </Route>
    );
  }
}
