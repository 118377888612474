import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';

class IUserImageStore extends EventEmitter {
  private userImage: Blob | undefined = undefined;

  private static instance: IUserImageStore | null;

  constructor() {
    super();

    IDispatcher.register(this.registerActions.bind(this));

    this.setUserImage = this.setUserImage.bind(this);
  }

  static getInstance(): IUserImageStore {
    if (IUserImageStore.instance == null) {
      IUserImageStore.instance = new IUserImageStore();
    }
    return IUserImageStore.instance;
  }

  registerActions(action: any) {
    switch (action.actionType) {
      default:
        break;
      case Constants.CLEAR_ALL:
        this.userImage = undefined;
        this.emit(CHANGE_EVENT);
        break;
      case Constants.USERIMAGE_CHANGED:
        this.setUserImage(action.userImage);
        this.emit(CHANGE_EVENT);
        break;
    }
  }

  getUserImage(): Blob | undefined {
    return this.userImage;
  }

  setUserImage(userImage?: Blob): void {
    this.userImage = userImage;
  }

  addChangeListener(callback: any) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback: any) {
    this.removeListener(CHANGE_EVENT, callback);
  }
}

export const UserImageStore = IUserImageStore.getInstance();
