//@ts-nocheck
import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { CountryCodeAlpha2 } from '../../../../../logic/api/ident';
import {
  IStatusState,
  Reporter,
} from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import { api, apis, ApiError, Ident } from '../../../../../logic/api';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { translate } from '../../../../../common/language/translate';
import { Icons } from '../../../../../images';
import {
  IStateOver,
  IPropsOver,
  AOverlay,
} from '../../../../../logic/handler/overlayhandler/globaloverlays/aOverlay';
import {
  IInitProps,
  IInitState,
} from '../../../../../logic/handler/initialdatahandler/initialDataComponent';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import ButtonCancel from '../../../../../components/atomiccompoents/buttons/buttonCancel';
import Title from '../../../../../components/compositcomponents/title';
import {
  OverlayHandler,
  Overlays,
} from '../../../../../logic/handler/overlayhandler/overlayHandler';
import {
  StyledInputView,
  StyledSmallInput,
} from '../basicStyledComponents/customerDetails.css';
import { IOption } from '../../../../../components/atomiccompoents/form';
import { StyledSelect } from '../../basicStyledComponents/customersComponent.css';
import { evaluateErrorMessage, getCountryOptions } from '../../../../../logic/helper/Common';

const StyledOverlay = styled.div`
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 50px;
  padding-top: 20;
  display: flex;
  flex-direction: column;
  width: 690px;
  height: 850px;
  align-items: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  overflow-y: auto;
`;

interface IProps extends IInitProps, IPropsOver {
  person: Ident.Person;
  callback: () => void;
}
interface IState extends IStatusState, IInitState, IStateOver {
  data: Ident.AdvancedKYCData;
  document?: Blob;
  income?: string;
  keyForLoadingSpinner: number;
}

export default class KycDataOverlay extends AOverlay<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: {},
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    };
    this.setCurrentCountry = this.setCurrentCountry.bind(this);
    this.setSourceOfIncomme = this.setSourceOfIncomme.bind(this);
    this.setMonthlyIncomme = this.setMonthlyIncomme.bind(this);
  }
  reviveState() {}
  stopPropagation(event: SyntheticEvent) {
    event.stopPropagation();
  }

  getSourceOfIncommeOptions() {
    const enumValues: Array<IOption> = [];
    enumValues.push({
      key: 'defaultOptionKey ',
      name: '-',
      value: '',
    });
    for (const value in Ident.SourceOfIncome) {
      enumValues.push({
        key: 'defaultOptionKey ' + value,
        name: translate('customers.advancedKyc.income.' + value),
        //@ts-ignore
        value: Ident.SourceOfIncome[value],
      });
    }
    return enumValues;
  }

  getMonthlyIncommeOptions() {
    const enumValues: Array<IOption> = [];
    enumValues.push({
      key: 'defaultOptionKey ',
      name: '-',
      value: '',
    });
    for (const value in Ident.AdvancedKYCDataAverageMonthlyIncomeEnum) {
      enumValues.push({
        key: 'defaultOptionKey ' + value,
        //@ts-ignore
        name: translate(
          'customers.advancedKyc.avgIncome.' +
            Ident.AdvancedKYCDataAverageMonthlyIncomeEnum[value],
          Ident.AdvancedKYCDataAverageMonthlyIncomeEnum[value],
        ),
        //@ts-ignore
        value: Ident.AdvancedKYCDataAverageMonthlyIncomeEnum[value],
      });
    }
    return enumValues;
  }

  setMonthlyIncomme(message: any) {
    const dats = this.state.data;
    dats.average_monthly_income = message.monthly_income;
    this.setState({
      data: dats,
    });
  }

  setCurrentCountry(message: any) {
    const dats = this.state.data;
    if (dats.employer_address == null) {
      dats.employer_address = {
        city: '',
        country: message.country,
      };
    } else {
      dats.employer_address.country = message.country;
    }
    this.setState({
      data: dats,
    });
  }

  setSourceOfIncomme(message: any) {
    const dats = this.state.data;
    dats.source_of_incomme = message.source_of_income;
    this.setState({
      data: dats,
    });
  }

  dataPost() {
    if (this.props.person.advanced_kyc === true) {
      OverlayHandler.showOverlay(Overlays.ConfirmationRequestOverlay, {
        confirm: (callback: (success: boolean, close?: boolean) => void) => {
          this.sendData();
        },
        heading: translate('customers.advancedKyc.advancedKycHeader'),
        message: translate('customers.advancedKyc.advancedKycWarning'),
      });
    } else {
      this.sendData();
    }
  }

  sendData() {
    const adv = JSON.stringify({
      average_monthly_income: this.state.data.average_monthly_income,
      employer_name: this.state.data.employer_name,
      source_of_incomme: this.state.data.source_of_incomme,
      employer_address: {
        country:
          this.state.data.employer_address != null
            ? this.state.data.employer_address.country
            : CountryCodeAlpha2.DE,
        city:
          this.state.data.employer_address != null
            ? this.state.data.employer_address.city
            : '',
        building_identifier:
          this.state.data.employer_address != null
            ? this.state.data.employer_address.building_identifier
            : '',
        street:
          this.state.data.employer_address != null
            ? this.state.data.employer_address.street
            : '',
        post_code:
          this.state.data.employer_address != null
            ? this.state.data.employer_address.post_code
            : '',
      },
    });
    const req: Ident.PersonRequestAdvancedKycPostRequest = {
      person_id: this.props.person.person_id,
      document: this.state.document,
      //@ts-ignore
      advanced_kyc_data: adv,
    };
    api
      .asyncRequest<void>(req, apis.MaintenanceApi, 'personRequestAdvancedKycPost')
      .then(() => {
        MessageHandler.onSuccess(Reporter['customer.advancedKyc.post']);
        this.props.callback();
        this.props.selfClose();
      })
      .catch((error: ApiError) => {
        MessageHandler.onError(
          Reporter['customer.advancedKyc.post'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
        this.setState({
          keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
        });
      });
  }

  getKYCFields() {
    return (
      <React.Fragment>
        <StyledInputView>
          <StyledSmallInput
            label={translate('customers.advancedKyc.input.employer.placeholder')}
            placeHolder={translate('customers.advancedKyc.input.employer.label')}
            type="name"
            id="name"
            onSubmit={() => {
              return;
            }}
            valueFromState={true}
            value={this.state.data.employer_name != null ? this.state.data.employer_name : ''}
            notification={n => {
              const dats = this.state.data;
              dats.employer_name = n.name.value;
              this.setState({
                data: dats,
              });
            }}
          />
        </StyledInputView>
        <StyledInputView>
          <StyledSmallInput
            label={translate('customers.advancedKyc.input.employer_street.placeholder')}
            placeHolder={translate('customers.advancedKyc.input.employer_street.label')}
            type="name"
            id="street"
            onSubmit={() => {
              return;
            }}
            valueFromState={true}
            value={
              this.state.data.employer_address != null &&
              this.state.data.employer_address.street != null
                ? this.state.data.employer_address.street
                : ''
            }
            notification={n => {
              const dats = this.state.data;
              if (dats.employer_address == null) {
                dats.employer_address = {
                  city: '',
                  country: CountryCodeAlpha2.DE,
                };
              }
              dats.employer_address.street = n.street.value;
              this.setState({
                data: dats,
              });
            }}
          />
        </StyledInputView>
        <StyledInputView>
          <StyledSmallInput
            label={translate('customers.advancedKyc.input.employer_city.placeholder')}
            placeHolder={translate('customers.advancedKyc.input.employer_city.label')}
            type="name"
            id="city"
            onSubmit={() => {
              return;
            }}
            valueFromState={true}
            value={
              this.state.data.employer_address != null &&
              this.state.data.employer_address.city != null
                ? this.state.data.employer_address.city
                : ''
            }
            notification={n => {
              const dats = this.state.data;
              if (dats.employer_address == null) {
                dats.employer_address = {
                  city: '',
                  country: CountryCodeAlpha2.DE,
                };
              }
              dats.employer_address.city = n.city.value;
              this.setState({
                data: dats,
              });
            }}
          />
        </StyledInputView>
        <StyledInputView>
          <StyledSelect
            helptext={translate('customers.advancedKyc.input.employer_country.placeholder')}
            id="country"
            options={getCountryOptions()}
            notification={this.setCurrentCountry}
            current={
              this.state.data.employer_address != null
                ? this.state.data.employer_address.country
                : CountryCodeAlpha2.DE
            }
          />
        </StyledInputView>
        <StyledInputView>
          <StyledSmallInput
            label={translate('customers.advancedKyc.input.employer_post_code.placeholder')}
            placeHolder={translate('customers.advancedKyc.input.employer_post_code.label')}
            type="name"
            id="post_code"
            onSubmit={() => {
              return;
            }}
            valueFromState={true}
            value={
              this.state.data.employer_address != null &&
              this.state.data.employer_address.post_code != null
                ? this.state.data.employer_address.post_code
                : ''
            }
            notification={n => {
              const dats = this.state.data;
              if (dats.employer_address == null) {
                dats.employer_address = {
                  city: '',
                  country: CountryCodeAlpha2.DE,
                };
              }
              dats.employer_address.post_code = n.post_code.value;
              this.setState({
                data: dats,
              });
            }}
          />
          <div style={{ width: '16px' }} />
          <StyledSmallInput
            label={translate(
              'customers.advancedKyc.input.employer_building_identifier.placeholder',
            )}
            placeHolder={translate(
              'customers.advancedKyc.input.employer_building_identifier.label',
            )}
            type="name"
            id="building_identifier"
            onSubmit={() => {
              return;
            }}
            valueFromState={true}
            value={
              this.state.data.employer_address != null &&
              this.state.data.employer_address.building_identifier != null
                ? this.state.data.employer_address.building_identifier
                : ''
            }
            notification={n => {
              const dats = this.state.data;
              if (dats.employer_address == null) {
                dats.employer_address = {
                  city: '',
                  country: CountryCodeAlpha2.DE,
                };
              }
              dats.employer_address.building_identifier = n.building_identifier.value;
              this.setState({
                data: dats,
              });
            }}
          />
        </StyledInputView>
        <StyledInputView>
          <StyledSelect
            helptext={translate('customers.advancedKyc.input.monthly_income.placeholder')}
            id="monthly_income"
            options={this.getMonthlyIncommeOptions()}
            notification={this.setMonthlyIncomme}
            current={
              this.state.data.average_monthly_income != null
                ? this.state.data.average_monthly_income
                : ''
            }
          />
        </StyledInputView>
        <StyledInputView>
          <StyledSelect
            helptext={translate('customers.advancedKyc.input.source_of_income.placeholder')}
            id="source_of_income"
            options={this.getSourceOfIncommeOptions()}
            notification={this.setSourceOfIncomme}
            current={
              this.state.data.source_of_incomme != null
                ? this.state.data.source_of_incomme
                : ''
            }
          />
        </StyledInputView>
        <StyledInputView
          onClick={() =>
            OverlayHandler.showOverlay(Overlays.fileChooserOverlay, {
              fileType: 'all',
              callback: (file: Blob) => {
                this.setState({
                  document: file,
                });
              },
            })
          }
        >
          <Hover>
            {'Upload document '}{' '}
            {this.state.document != null ? (
              <div style={{ marginTop: '8px' }}>{Icons.checkBoxIcon()}</div>
            ) : null}
          </Hover>
        </StyledInputView>
        <StyledViewButtons>
          <ButtonCancel
            id="btnAddCustomerAdvKYCCancel"
            style={{ marginRight: '48px' }}
            onClick={() => {
              OverlayHandler.closeSpecific(Overlays.kycDataOverlay);
            }}
          >
            {translate('button.cancel')}
          </ButtonCancel>
          <ButtonOk
            key={this.state.keyForLoadingSpinner}
            disabled={this.state.document == null}
            id="btnAddadvKCrAddressOk"
            onClick={() => {
              this.dataPost();
            }}
          >
            {translate('button.create')}
          </ButtonOk>
        </StyledViewButtons>
      </React.Fragment>
    );
  }

  defineContent() {
    return (
      <StyledOverlay onClick={event => event.stopPropagation()}>
        <HeaderBlock>
          <Title title={translate('customers.advancedKyc.advancedKyc')} />
        </HeaderBlock>
        <AddressBlock>{this.getKYCFields()}</AddressBlock>
      </StyledOverlay>
    );
  }
}

const AddressBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
const HeaderBlock = styled.div`
  display: flex;
  flex-direction: row;
`;
const StyledViewButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 32px;
  margin-top: 40px;
  margin-bottom: 24px;
`;
const Hover = styled.div`
  :hover {
    cursor: pointer;
  }
`;
