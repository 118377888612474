import React from 'react';
import styled from 'styled-components';

const StyledView = styled.div`
  flex-direction: column;
  margin-top: 6px;
  background-color: #f2f4f6;
  width: 100%;
  height: 6px;
  border-radius: 1px;

  div {
    background-color: hsla(295, 100%, 64%, 0.774);
    height: 100%;
  }
`;

interface IProps {
  active: boolean | undefined;
  strength: number;
  refresh?: () => void;
}

interface IState {
  active: boolean;
  state: {
    strength: number;
    divStyle: object;
    active: boolean;
  };
}

export class StrengthBar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const state = this.calculateBar(props);
    this.state = {
      active: props.active === false ? false : true,
      state: state,
    };
  }

  calculateBar(props: IProps): { strength: number; divStyle: object; active: boolean } {
    const strength = props.strength == null ? 0 : props.strength * 100;
    const divStyle = {
      backgroundColor: this.numberToColorHsl(strength),
      width: strength + '%',
    };
    return {
      strength: strength,
      divStyle: divStyle,
      active: props.active === false ? false : true,
    };
  }

  UNSAFE_componentWillReceiveProps(props: IProps) {
    const state: {
      strength: number;
      divStyle: object;
      active: boolean;
    } = this.calculateBar(props);

    const newState: IState = {
      state: {
        ...state,
      },
      active: this.state.active,
    };
    this.setState(newState);
  }

  numberToColorHsl(i: number) {
    // as the function expects a value between 0 and 1, and red = 0° and green = 120°
    // we convert the input to the appropriate hue value
    const hue = (i * 1.2) / 360;
    // we convert hsl to rgb (saturation 100%, lightness 50%)
    const rgb = this.hslToRgb(hue, 1, 0.5);
    // we format to css value and return
    return 'rgb(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ')';
  }

  /**
   * Converts an HSL color value to RGB. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
   * Assumes h, s, and l are contained in the set [0, 1] and
   * returns r, g, and b in the set [0, 255].
   *
   * @param   {number}  h       The hue
   * @param   {number}  s       The saturation
   * @param   {number}  l       The lightness
   * @return  {Array}           The RGB representation
   */
  hslToRgb(h: number, s: number, l: number) {
    let r, g, b: number;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = function hue2rgb(p: number, q: number, t: number) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }

  render() {
    if (!this.state.active || this.state.state.strength == null) {
      return null;
    }

    return (
      <StyledView>
        <div style={this.state.state.divStyle} />
      </StyledView>
    );
  }
}
