import styled from 'styled-components';

export const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  outline: none;
`;
export const RowBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  outline: none;
`;
//@ts-ignore
export const Container = styled(FlexBox)`
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
`;

export const AuthLeft = styled(FlexBox)`
  width: 50%;
  height: 100%;
  left: 0px;
  position: absolute;
  background-color: #fbfbfb;
  overflow: auto;

  @media (max-width: 1000px) {
    width: 100%;
  }

  & > div {
    margin: auto;
    width: 360px;
  }
`;

export const PageWrapperOuter = styled(FlexBox)`
  min-height: 100vh;
  position: relative;
`;

export const PageWrapperInner = styled(FlexBox)`
  height: 100%;
  padding-top: 68px;
  padding-bottom: 60px;

  & > div:nth-child(1) {
    margin-bottom: 100px;
  }
`;

export const AuthRight = styled(FlexBox)`
  height: 100%;
  position: absolute;
  overflow: hidden;
  width: 50%;
  right: 0;
  @media (max-width: 1000px) {
    display: none;
  }

  img {
    display: block;
    padding: 0;
    margin: 0;
    height: 100vh;
    overflow: hidden;
    object-fit: cover;
    object-position: 50% 50%;
  }
`;

export const Wrapper = styled(FlexBox)`
  width: 360px;
  margin: auto;
  text-align: left;
`;

export const Powered = styled(FlexBox)`
  width: 109px;
  margin: auto;
  margin-top: 2.5vh;

  div {
    margin-top: 2px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #8e8e8e;
    margin-bottom: 0.8vh;
  }

  img {
    width: 100%;
  }
`;

export const PageLink = styled(FlexBox)`
  width: 100%;
  text-align: center;
  color: #98a9bc;
  line-height: 1.5;
  margin-top: 6vh;

  a {
    color: #98a9bc;
    text-decoration: none;
  }
  span > a {
    color: ${props => props.theme.Global.darkFontColor};
    text-decoration: none;
  }

  a:hover,
  span > a:hover {
    color: #ff6100;
    text-decoration: none;
  }

  span {
    color: ${props => props.theme.Global.darkFontColor};
  }
`;

export const Footer = styled(FlexBox)`
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  margin-bottom: 30px;

  & > div {
    font-family: Roboto;
    font-weight: normal;
    color: #98a9bc;
  }

  a {
    margin-left: 10px;
    text-decoration: underline;
    font-family: Roboto;
    font-weight: normal;
    color: ${props => props.theme.Global.darkFontColor};
  }
  a:hover {
    text-decoration: none;
  }
`;

export const H1 = styled.h1`
  margin-top: 13%;
`;

export const H2 = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.2px;

  color: #1b1e24;
  margin-top: 131px;
  text-align: left;
`;

export const H3 = styled.h3`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  color: ${props => props.theme.Global.darkFontColor};
  text-align: left;
  margin-bottom: 61px;
`;

export const StyledDivHeading1 = styled(FlexBox)`
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.2px;

  color: #1b1e24;
  margin-top: 14vh;
  text-align: left;
`;

export const StyledDivHeading2 = styled(FlexBox)`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  color: ${props => props.theme.Global.darkFontColor};
  text-align: left;
  margin-top: 1vh;
  margin-bottom: 6.5vh;
`;

export const Invrs = styled(FlexBox)`
  img,
  svg {
    margin-top: 68px;
    width: 111px;
    height: 32px;
    margin-left: 0px;
    position: relative;
  }
`;
