// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Iso4217Currency } from './index';

/**
 * @export
 * @interface NewProductVoucher
 */
export interface NewProductVoucher {
  /**
   * the merchants account_number
   * @type {string}
   * @memberof NewProductVoucher
   */
  merchant_account_number?: string;
  /**
   * The amount given with fractional digits, where fractions must be compliant to the currency definition. Up to 14 significant figures. Negative amounts are signed by minus. The decimal separator is a dot.  **Example:** Valid representations for EUR with up to two decimals are:    * 1056   * 5768.2   * -1.50   * 5877.78
   * @type {string}
   * @memberof NewProductVoucher
   */
  amount: string;
  /**
   * @type {Iso4217Currency}
   * @memberof NewProductVoucher
   */
  currency: Iso4217Currency;
  /**
   * @type {Date}
   * @memberof NewProductVoucher
   */
  valid_until: Date;
  /**
   * email address of the customer receiving the voucher email
   * @type {string}
   * @memberof NewProductVoucher
   */
  receiver?: string;
  /**
   * @type {number}
   * @memberof NewProductVoucher
   */
  product_id?: number;
  /**
   * @type {string}
   * @memberof NewProductVoucher
   */
  voucher_type?: NewProductVoucherVoucherTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum NewProductVoucherVoucherTypeEnum {
  OnceOnly = 'onceOnly',
  Unlimited = 'unlimited',
}
