import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ClientConfig } from '../../../common/config';
import { translate } from '../../../common/language/translate';
import Tabs, { ITabvalues } from '../../../components/atomiccompoents/tabs/tabs';
import { Ident } from '../../../logic/api';
import { updatePerson } from '../../../logic/helper/Common';
import { Last25 } from './dashboard/last25';
import { OnfidoComponent } from './dashboard/onfido';
import SystemStateComponent from './dashboard/systemstate';
import UserStatsComponent from './dashboard/userstats';

interface IProps extends RouteComponentProps {}

export default class DashboardComponent extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
    this.redirect = this.redirect.bind(this);
  }

  redirect(path: string) {
    this.props.history.push(path);
  }

  render() {
    const tabValues: Array<ITabvalues> = [
      {
        name: 'systemstate',
        title: 'Booking Overview',
        path: '/dashboard/systemstate',
        callback: props => {
          return <SystemStateComponent />;
        },
      },
    ];

    if (ClientConfig.showClientStats) {
      tabValues.push({
        name: 'userstats',
        title: translate('administration.systemstate.userstats'),
        path: '/dashboard/userstats',
        callback: props => {
          return <UserStatsComponent />;
        },
      });
    }
    if (ClientConfig.showClientStats) {
      tabValues.push({
        name: 'last25',
        title: translate('dashboard.last25.last25', 'Last 25'),
        path: '/dashboard/last25',
        callback: props => {
          return (
            <Last25
              notification={(person: Ident.PersonFound) => {
                updatePerson(person.person_id);
              }}
            />
          );
        },
      });
    }
    if (ClientConfig.onfido) {
      tabValues.push({
        name: 'onfido',
        title: 'Onfido',
        path: '/dashboard/onfido',
        callback: props => {
          return (
            <OnfidoComponent
              notification={(personId: number) => {
                updatePerson(personId, '/customers/details/accountList', this.redirect);
              }}
            />
          );
        },
      });
    }
    return (
      <Tabs
        activeTab="systemstate"
        tabList={tabValues}
        redicrect="/dashboard/systemstate"
        basePath="/dashboard/"
        minwidthNeeded={false}
      />
    );
  }
}
