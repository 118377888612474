export const Config = {
  defaultLocale: 'en',
  defaultErrorMessage: 'Something went wrong',
  defaultSuccessMessage: 'Success',
  logOutTime: 60,
  logOutConfirm: true,
  table: {
    rowserPerPageDefault: 10,
    transactionLimit: null,
    getNewValuesTrigger: 2,
  },
  datePicker: {
    datepickerHeight: 311,
    datePickerWidth: 250,
    toggleWidth: 200,
    datePickerCenterOffset: 150,
    datepickerTopSpace: 290,
    datePickerDefaultMargin: 8,
    moveOffsetX: 105,
    moveOffsetY: 20,
  },
  input: {
    variationMultiplierLower: 0.15,
    variationMultiplierUpper: 0.15,
    variationMultiplierNumeric: 0.3,
    variationMultiplierspecial: 0.4,
  },
  iconPicker: {
    defaultWidth: 100,
  },
  paging: {
    defaultIndex: 1,
    numbersBeforeDot: 1,
    numbersBetweenDot: 3,
    numbersAfterDot: 1,
    defaultResults: 100,
  },
  auth: {
    defaultPageIndex: 1,
  },
  userConfig: {
    exampleValue: 'example',
  },
  deltaVideoSpeed: 2,
  videoMaxSpeed: 5,
};
