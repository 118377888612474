import React from 'react';
import { Input, CheckBox } from '../../../components/atomiccompoents/form';
import {
  DefaultLoginType,
  DeveloperStore,
  CustomerPageType,
} from '../../../logic/flux/stores/DeveloperStore';
import ButtonOk from '../../../components/atomiccompoents/buttons/buttonOk';
import { INotifyAbleDeveloperData } from '../../../logic/flux/notifyAbles/notifyAbleDeveloperData';
import { Actions } from '../../../logic/flux';
import styled from 'styled-components';
import { Icons } from '../../../images';
import { Log, Logs } from '../../../logic/log';

interface IProps {}

interface IState {
  username?: string;
  password?: string;
  keyForLoadingSpinner?: number;
  customerPage?: CustomerPageType;

  defaultLogin?: DefaultLoginType;
  defaultCustomer?: number;
  defaultBackofficeAccount?: string;
}

export default class DeveloperAutomation extends React.Component<IProps, IState>
  implements INotifyAbleDeveloperData {
  constructor(props: IProps) {
    super(props);

    this.state = {
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    };

    this.saveDefaultLogin = this.saveDefaultLogin.bind(this);
    this._onChangePassword = this._onChangePassword.bind(this);
    this._onChangeUsername = this._onChangeUsername.bind(this);
    this._onChangeDeveloperData = this._onChangeDeveloperData.bind(this);
  }

  _onChangeDeveloperData(): void {
    const defaultLogin: DefaultLoginType = DeveloperStore.getDefaultLogin();
    this.setState({
      defaultLogin: defaultLogin,
      defaultCustomer: DeveloperStore.getDefaultCustomer(),
      defaultBackofficeAccount: DeveloperStore.getDefaultBackofficeAccount(),
      username: defaultLogin == null ? undefined : defaultLogin.username,
      password: defaultLogin == null ? undefined : defaultLogin.password,
      customerPage: DeveloperStore.getDefaultCustomerPage(),
    });
  }

  componentDidMount(): void {
    DeveloperStore.addChangeListener(this._onChangeDeveloperData);
    this._onChangeDeveloperData();
  }

  componentWillUnmount(): void {
    DeveloperStore.removeChangeListener(this._onChangeDeveloperData);
  }

  private _onChangeCustomerPage(page?: CustomerPageType): void {
    Log.info(Logs.COMPONENT, '_onChange default customer page set to', page);
    if (page == null) {
      Actions.setDefaultCustomerPage();
    } else {
      Actions.setDefaultCustomerPage(page);
    }
  }

  private _onChangePassword(value: string): void {
    this.setState({
      password: value,
    });
  }

  private _onChangeUsername(value: string): void {
    this.setState({
      username: value,
    });
  }

  private saveDefaultLogin(): void {
    if (this.state.username == null || this.state.password == null) {
      Actions.setDeveloperLogin(undefined);
    } else {
      Actions.setDeveloperLogin({
        username: this.state.username,
        password: this.state.password,
      });
    }

    this.setState({
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    });
  }

  render() {
    return (
      <div>
        <h2>Automation</h2>

        <StyledTable>
          <thead>
            <tr>
              <td>Automated Login</td>
              <td>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Warning>
                      {Icons.warning()}Your password will be stored as plain text
                      {Icons.warning()}
                    </Warning>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      Username:{' '}
                      <Input
                        id="username"
                        onChange={this._onChangeUsername}
                        value={this.state.username}
                        onSubmit={() => this.saveDefaultLogin()}
                        valueFromState={true}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      Password:{' '}
                      <Input
                        label=""
                        placeHolder=""
                        type="password"
                        id="password"
                        onChange={this._onChangePassword}
                        value={this.state.password}
                        onSubmit={() => this.saveDefaultLogin()}
                        valueFromState={true}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ButtonOk
                      style={
                        this.state.defaultLogin != null ? { backgroundColor: 'green' } : {}
                      }
                      onClick={this.saveDefaultLogin}
                      key={this.state.keyForLoadingSpinner}
                    >
                      Save
                    </ButtonOk>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Auto select customer</td>
              <td>{this.state.defaultCustomer || 'Disabled'}</td>
            </tr>
            <tr>
              <td>Customer page</td>
              <td>
                <CheckBox
                  label="Details"
                  id="customer_page_details"
                  value={this.state.customerPage === 'details'}
                  onChange={value =>
                    this._onChangeCustomerPage(value === true ? 'details' : undefined)
                  }
                />
                <CheckBox
                  label="Transactions"
                  id="customer_page_transactions"
                  value={this.state.customerPage === 'transactions'}
                  onChange={value =>
                    this._onChangeCustomerPage(value === true ? 'transactions' : undefined)
                  }
                />
                <CheckBox
                  label="Posting Templates"
                  id="customer_page_postingTemplates"
                  value={this.state.customerPage === 'postingTemplates'}
                  onChange={value =>
                    this._onChangeCustomerPage(value === true ? 'postingTemplates' : undefined)
                  }
                />
                <CheckBox
                  label="Reports"
                  id="customer_page_reports"
                  value={this.state.customerPage === 'reports'}
                  onChange={value =>
                    this._onChangeCustomerPage(value === true ? 'reports' : undefined)
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Auto select backoffice account</td>
              <td>{this.state.defaultBackofficeAccount || 'Disabled'}</td>
            </tr>
          </thead>
        </StyledTable>
      </div>
    );
  }
}

const Warning = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg,
  img {
    height: 20px;
  }
`;

const StyledTable = styled.table`
  border: 1px solid black;
  border-collapse: collapse;

  td {
    border: 1px solid black;
    padding: 6px;
    vertical-align: top;
  }
`;
