import React from 'react';
import styled from 'styled-components';
import { IStatusState } from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { IItfAccount } from '../../../../logic/types';
import { Ident, api, apis } from '../../../../logic/api';
import { translate } from '../../../../common/language/translate';
import {
  IInitProps,
  IInitState,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import { EditableWrapper, KeyField } from './basicStyledComponents/customerDetails.css';
import { FlexBox } from '../../../auth/auth.css';
import { compareObject } from '../../../../logic/helper/Common';
import { Log, Logs } from '../../../../logic/log';
import { withPersonAndAccount } from '../../../../components/hocs/withPersonAndAccount';
import IconButton from '../../../../components/atomiccompoents/buttons/iconButton';
import { Icons } from '../../../../images';
import Title from '../../../../components/compositcomponents/title';
import EditableComponent from '../../../../components/atomiccompoents/editableComponent/editableComponent';
import KeyDateComponent from '../../../../components/atomiccompoents/keyDateComponent';
import { CompanionCodeDetailExt } from '../../../../logic/api/ident';
import Expandable from '../../../../components/compositcomponents/expandable/expandable';
import ImageBox, {
  IImageBoxElement,
} from '../../../../components/compositcomponents/imageBox';

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TitlePersonType = styled.span`
  color: #c9d1d9;
  margin-right: 1ex;
`;
const TitlePersonId = styled.span`
  display: inline;
  color: #c9d1d9;
  font-style: italic;
  font-weight: normal;
  font-size: smaller;
  margin-left: 1ex;
  vertical-align: baseline;
`;
const WrapperOuter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
`;
const ButtonWrapper = styled.div`
  margin: auto 0.5em auto auto;
  width: 5em;
  display: flex;
  justify-content: space-between;
`;
interface IJuniorDetails extends Ident.CompanionCodeDetailExt {
  turns18?: Date;
  date_of_birth: Date;
  relative_type: string;
}
interface IAccountDetailsProps extends IInitProps {
  person?: Ident.Person;
  currentAccount?: IItfAccount;
}

interface IState extends IStatusState, IInitState {
  companions?: Array<Ident.Companions>;
  lastSavedPersons?: Array<IJuniorDetails>;
  persons?: Array<IJuniorDetails>;
  dirty?: Array<boolean>;
  saving?: Array<boolean>;
  images?: Array<Array<Ident.PersonDocument>>;
}

class IJuniorAccountData extends React.Component<IAccountDetailsProps, IState> {
  constructor(props: IAccountDetailsProps) {
    super(props);

    const n: IState = {};
    this.state = n;

    this._onChangeCustomerData();
  }

  _onChangeCustomerData() {
    this.getJuniorData();
  }

  async getJuniorData() {
    if (this.props.person == null) {
      return;
    }
    const req = {
      person_id: this.props.person.person_id,
    };
    const companions = await api.asyncRequest<Array<Ident.Companions>>(req, apis.MaintenanceApi, 'personCompanionList')

    try {
      const lastSavedPersons = [];
      const persons = [];
      const dirty = [];
      const saving = [];
      const images = [];
      if (companions == null || companions.length < 1) {
        return;
      }
      this.setState({ companions, lastSavedPersons, persons, dirty, saving, images });

      const juniorDetailsPromises = companions.map(async companion => {
        let details: IJuniorDetails = {
          person_id: null,
          given_name: null,
          name: null,
          date_of_birth: null,
          relative_type: null,
        };
        try {
          if(companion.relation_type !== 'C') {
            // This isn't a companion; fetch person details instead of junior details.
            details = await this.getPersonDetails(companion.person_id);
          } else {
            try {
              details = await this.getJuniorDetails(companion.companion_code);
            } catch(error) {
              Log.error(Logs.API, error);
              // This isn't a companion; fetch person details instead of junior details.
              details = await this.getPersonDetails(companion.person_id);
            }
          }
        } catch(error) {
          Log.error(Logs.API, error);
        }

        switch(companion.family_relation) {
          case 'junior':
            switch(companion.relation_type) {
              case 'C':
                details.relative_type = 'Junior';
                break;
              case 'P':
                details.relative_type = 'Parent';
                break;
              default:
                Log.error(Logs.API, 'Unrecognized relation_type value ' + companion.relation_type + '; companion:', companion);
                details.relative_type = 'Unknown';
            }
            break;
          case 'family_member':
          case undefined:
            switch(companion.relation_type) {
              case 'C':
                details.relative_type = 'Companion';
                break;
              case 'P':
                details.relative_type = 'Primary';
                break;
              default:
                Log.error(Logs.API, 'Unrecognized relation_type value ' + companion.relation_type + '; companion:', companion);
                details.relative_type = 'Unknown';
            }
            break;
          default:
            Log.error(Logs.API, 'Unrecognized family_relation value ' + companion.family_relation + '; companion:', companion);
            details.relative_type = 'Unknown';
        }

        lastSavedPersons.push(details);
        persons.push({ ...details }); // Make a copy for persons so we can edit without changing lastSavedPersons
        dirty.push(false);
        saving.push(false);
      });

      const personDocumentPromises = companions.map(async companion => {
        if(companion.relation_type !== 'C') {
          // This isn't a companion; don't fetch junior documents.
          return;
        }
        try {
          const personDocument = await this.getJuniorDocuments(companion.companion_code);
          images.push(personDocument);
        } catch(error) {
          Log.error(Logs.API, error);
          images.push([]);
        }
      });
      await Promise.allSettled(juniorDetailsPromises);
      await Promise.allSettled(personDocumentPromises);

      lastSavedPersons.sort((a, b) => a.person_id - b.person_id);
      persons.sort((a, b) => a.person_id - b.person_id);

      this.setState({ companions, lastSavedPersons, persons, dirty, saving, images });
    } catch(error) {
      Log.error(Logs.API, error);
    }
  }

  async getPersonDetails(person_id): Promise<IJuniorDetails> {
    const person = await api.asyncRequest<Ident.Person>(
      { person_id },
      apis.MaintenanceApi,
      'personPersonIdGet',
    );

    return this.calcTurns18({
      person_id: person.person_id,
      given_name: person.given_name,
      name: person.name,
      email_address: person.primary_email_address,
      phone_number: person.primary_phone_number,
      //phone_number_type: person.phone_number_type, // Not available from person_person_id_get
      //face_url: person.face_url, // Not available from person_person_id_get
      family_relation: person.family_relation as Ident.CompanionCodeDetailExtFamilyRelationEnum,
      date_of_birth: person.birth_date,
      relative_type: 'Primary',
    } as IJuniorDetails);
  }

  async getJuniorDetails(companion_code) {
    const companionCodeDetail = await api.asyncRequest<CompanionCodeDetailExt>(
      { companion_code },
      apis.OpenIDConnectApi,
      'oauthCompanionCodeDetail',
    );

    return this.calcTurns18(companionCodeDetail as IJuniorDetails);
  }

  calcTurns18(person: IJuniorDetails) {
    if (person.date_of_birth != null) {
      const fullyeardate = new Date(person.date_of_birth);
      fullyeardate.setFullYear(fullyeardate.getFullYear() + 18);
      person.turns18 = fullyeardate;
    }
    return person;
  }

  async getJuniorDocuments(companion_code) {
    const juniorDocumentList = await api.asyncRequest<Array<Ident.PersonDocument>>(
      {
        companion_code,
        person_id: this.props.person.person_id,
      },
      apis.MaintenanceApi,
      'personJuniorDocumentList',
    );

    return juniorDocumentList;
  }

  generateImages(index: number) {
    const imgs = this.state.images[index];
    const out: Array<IImageBoxElement> = [];
    if (imgs == null || imgs.length < 1) {
      return out;
    }
    for (const personDocument of imgs) {
      if (personDocument.person_document_id == null) {
        continue;
      }
      out.push({
        url: `/ident/person/${this.props.person.person_id}/document/${personDocument.person_document_id}`,
        title: personDocument.filename,
      });
    }
    return out;
  }

  componentDidUpdate(prevProps: IAccountDetailsProps) {
    if (!compareObject(prevProps, this.props)) {
      this._onChangeCustomerData();
    }
  }

  onEdit(index: number, value: string | Date, key?: string) {
    const persons = this.state.persons;
    const dirty = this.state.dirty;

    if(persons[index][key] !== value) {
      persons[index][key] = value;
      dirty[index] = true;

      this.setState({ persons, dirty });
    }
  }

  async onSave(index: number) {
    let saving = this.state.saving;
    saving[index] = true;

    const updatedPerson = this.state.persons[index];

    this.setState({ saving });
    await this.updatePersonDetails(index);

    const lastSavedPersons = this.state.lastSavedPersons;
    lastSavedPersons[index] = { ...updatedPerson };

    const dirty = this.state.dirty;
    dirty[index] = false;

    saving = this.state.saving;
    saving[index] = false;

    this.setState({ lastSavedPersons, dirty, saving });
  }

  async updatePersonDetails(index: number) {
    const lastSavedPerson = this.state.lastSavedPersons[index];
    const {
      person_id,
      given_name,
      name,
      email_address,
      phone_number,
      //phone_number_type,
      family_relation,
      date_of_birth,
    } = this.state.persons[index];

    if(person_id !== lastSavedPerson.person_id) {
      throw Error("Can't change person ID.");
    }

    await api.asyncRequest(
      {
        person_id,
        PersonUpdateRequest: {
          given_name,
          name,
          family_relation,
          birth_date: date_of_birth.toISOString().split('T')[0],
        }
      },
      apis.MaintenanceApi,
      'personPut',
    );

    if(email_address !== lastSavedPerson.email_address) {
      // TODO: There is also a POST version of this that adds an email address instead of changing "the email address".
      // Is this what we want?
      await api.asyncRequest(
        {
          person_id,
          NewEmailAddress: {
            email_address,
          },
        },
        apis.MaintenanceApi,
        'personEmailAddressPut',
      );
    }

    if(phone_number !== lastSavedPerson.phone_number) {
      // TODO: This _adds_ a phone number; do we want to do anything to the existing phone number?
      await api.asyncRequest(
        {
          person_id,
          NewPhone: {
            phone_number,
          },
        },
        apis.MaintenanceApi,
        'personPhonePost',
      );
    }
  }

  onCancel(index: number) {
    const persons = this.state.persons;
    const dirty = this.state.dirty;

    persons[index] = { ...this.state.lastSavedPersons[index] };
    dirty[index] = false;

    this.setState({ persons, dirty });
  }

  renderTitle(person, index) {
    //TODO: Translate person.relative_type and "Person ID"
    return (
      <>
        <TitlePersonType>{person.relative_type}:</TitlePersonType>
        {person.given_name} {person.name}
        <TitlePersonId>[Person ID {person.person_id}]</TitlePersonId>
        {this.state.dirty[index] ? <>&nbsp;&#x25CF;</> : null}
      </>
    );
  }

  renderData() {
    const out = [];
    this.state.persons.forEach((person: IJuniorDetails, index: number) => {
      if (person.person_id == null) {
        return;
      }
      out.push(
        <Expandable title={this.renderTitle(person, index)} open={index === 0} key={person.person_id}>
          <WrapperOuter>
            {this.state.dirty[index] ? <ButtonWrapper>
              <IconButton
                id={'btnEditCompanionCancel' + index}
                disabled={!this.state.dirty[index]}
                onClick={() => this.onCancel(index)}
                tooltip={translate('button.cancel')}
              >
                {Icons.undo()}
              </IconButton>
              <IconButton
                disabled={!this.state.dirty[index]}
                onClick={() => this.onSave(index)}
                loading={this.state.saving[index]}
                tooltip={translate('button.save')}
              >
                {Icons.save()}
              </IconButton>
            </ButtonWrapper> : null}
            <EditableWrapper>
              <EditableComponent
                translationkey="customers.name"
                id="name"
                initText={person.name ?? ''}
                changeCallback={(value: string, key: string) => {
                  this.onEdit(index, value, key);
                }}
                enterCallback={(value: string, key: string) => {
                  this.onEdit(index, value, key);
                }}
                permission={Ident.OperationId.PersonPut}
              />
            </EditableWrapper>
            <EditableWrapper>
              <EditableComponent
                translationkey="customers.givenName"
                id="given_name"
                initText={person.given_name ?? ''}
                changeCallback={(value: string, key: string) => {
                  this.onEdit(index, value, key);
                }}
                enterCallback={(value: string, key: string) => {
                  this.onEdit(index, value, key);
                }}
                permission={Ident.OperationId.PersonPut}
              />
            </EditableWrapper>
            <EditableWrapper>
              <KeyDateComponent
                translationkey="customers.birthdate"
                value={
                  person?.date_of_birth != null ? new Date(person.date_of_birth) : undefined
                }
                id="date_of_birth"
                onChange={(key: string, date: Date) => {
                  this.onEdit(index, date, key);
                }}
                wrapperStyle={{ maxWidth: '240px' }}
                number={0}
              />
            </EditableWrapper>
            <EditableWrapper>
              <KeyDateComponent
                translationkey="customers.juniorAccount.turns18"
                value={person?.turns18 != null ? new Date(person.turns18) : undefined}
                id="date_of_birth"
                onChange={(key: string, date: Date) => {
                  this.onEdit(index, date, key);
                }}
                disabled={true}
                wrapperStyle={{ maxWidth: '240px' }}
                number={0}
              />
            </EditableWrapper>
            <EditableWrapper>
              <EditableComponent
                translationkey="customers.details.companions.email"
                id="email_address"
                initText={person.email_address ?? ''}
                changeCallback={(value: string, key: string) => {
                  this.onEdit(index, value, key);
                }}
                enterCallback={(value: string, key: string) => {
                  this.onEdit(index, value, key);
                }}
                permission={Ident.OperationId.PersonEmailAddressPut}
              />
            </EditableWrapper>
            <EditableWrapper>
              <EditableComponent
                translationkey="customers.details.companions.phone"
                id="phone_number"
                initText={person.phone_number ?? ''}
                changeCallback={(value: string, key: string) => {
                  this.onEdit(index, value, key);
                }}
                enterCallback={(value: string, key: string) => {
                  this.onEdit(index, value, key);
                }}
                permission={Ident.OperationId.PersonPhonePost}
              />
            </EditableWrapper>
            <EditableWrapper>
              <EditableComponent
                translationkey="customers.details.companions.relation"
                id="family_relation"
                initText={person.family_relation ?? ''}
                changeCallback={(value: string, key: string) => {
                  this.onEdit(index, value, key);
                }}
                enterCallback={(value: string, key: string) => {
                  this.onEdit(index, value, key);
                }}
                permission={Ident.OperationId.PersonPut}
              />
            </EditableWrapper>
          </WrapperOuter>
          <TitleWrapper>
            <KeyField>{translate('customers.details.companions.documents')}:</KeyField>
            <FlexBox
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                overflowX: 'auto',
                border: '1px solid grey',
                padding: '6px',
                width: '98%',
              }}
            >
              {this.state.images != null && this.state.images[index] != null ? (
                <ImageBox images={this.generateImages(index)} />
              ) : null}
            </FlexBox>
          </TitleWrapper>
        </Expandable>,
      );
    });
    return out;
  }

  render() {
    return (
      <FlexBox>
        <TitleWrapper>
          <Title title={translate('customers.juniorAccount.title')} />
        </TitleWrapper>
        {this.state.persons != null && this.state.persons.length > 0
          ? this.renderData()
          : 'No data given'}
      </FlexBox>
    );
  }
}

export default withPersonAndAccount(IJuniorAccountData);
