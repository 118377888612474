// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders } from '../runtime';
import { PermissionResponse } from '../models/index';

/**
 * no description
 */
export class OpenIDConnectApi extends BaseAPI implements IOperationIdMap {
  /**
   * get all operations a person can access
   */
  personAuthorizationGet = (): Observable<PermissionResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.username &&
        this.configuration.password && {
          Authorization: `Basic ${btoa(
            this.configuration.username + ':' + this.configuration.password,
          )}`,
        }),
      ...(this.configuration.apiKey && {
        Authorization: this.configuration.apiKey('Authorization'),
      }), // biometryAuth authentication
      ...(this.configuration.apiKey && {
        'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data'),
      }), // oidc_header authentication
    };

    return this.request<PermissionResponse>({
      path: '/person/authorization',
      method: 'GET',
      headers,
    });
  };

  operationToOperationId = {
    personAuthorizationGet: 'person_authorization_get',
  };
}
