import React, { PureComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { theme } from '../../../../common/theme';
import {
  IStatusState,
  IMessageConfig,
  Reporter,
  getFormComponents,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { IItfAccount } from '../../../../logic/types';
import { Ident, Account, api, apis, ApiError, VoidResponse } from '../../../../logic/api';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { translate } from '../../../../common/language/translate';
import { format } from '../../../../logic/helper/format';
import {
  IInitProps,
  IInitState,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import { CustomerDataStore, Actions, PermissionStore } from '../../../../logic/flux';
import { InitComponentHandler } from '../../../../logic/handler/initialdatahandler/InitComponentHandler';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import ButtonCancel from '../../../../components/atomiccompoents/buttons/buttonCancel';
import {
  ColumnBox,
  KeyValueBlock,
  KeyField,
  ValueField,
  IconValueField,
  IconWrapper,
} from './basicStyledComponents/customerDetails.css';
import Switch from '../../../../components/atomiccompoents/switch';
import { FlexBox } from '../../../auth/auth.css';
import { DisplayMenuComponent } from '../../../../components/compositcomponents/displayMenuComponent';
import { IOption } from '../../../../components/atomiccompoents/form';
import { Icons } from '../../../../images';
import {
  compareObject,
  downloadFile,
  evaluateErrorMessage,
  updateAccount,
  updatePerson,
} from '../../../../logic/helper/Common';
import { StyledSelect } from '../basicStyledComponents/customersComponent.css';
import { BigInput } from '../../../../components/atomiccompoents/form/inputs.css';
import { Closure, ClosureReasonEnum } from '../../../../logic/api/ident/models/Closure';
import { Log, Logs } from '../../../../logic/log';
import AccountSelector from '../../../../components/accountSelector';
import { ClientConfig } from '../../../../common/config';
import { withPersonAndAccount } from '../../../../components/hocs/withPersonAndAccount';
import { throws } from 'assert';
import { ThumbsDownIcon } from 'react-line-awesome';
import { common } from '@material-ui/core/colors';

const MessageBox = styled.div``;

const StyledHeader = styled.h2`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  color: #ff6100;
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ReasonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const WrapperOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WrapperInner = styled.div`
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface IAccountDetailsProps extends IInitProps {
  person?: Ident.Person;
  currentAccount?: IItfAccount;
}

interface IState extends IStatusState, IInitState {
  blocked?: boolean;
  closed?: boolean;
  editable?: boolean;
  currentDetailsError?: string;
  showInlineError?: boolean;
  keyForLoadingSpinner?: number;
  dataChanged?: boolean;
  limitsExpaned?: boolean;
  selectedReason:
    | Ident.PersonPartnerTransferDeleteReasonEnum
    | ''
    | ClosureReasonEnum
    | Ident.PersonDeleteReasonEnum;
  blockReasons: Array<any>;
  additionalReason?: string;
  delReasons: Array<number>;
  newReason?: string;
}

const ENTITY_ID_XK = 2;

class IAccountDetailsBox extends React.Component<IAccountDetailsProps, IState> {
  private OkButtonStyle: React.CSSProperties = {
    marginLeft: '16px',
    display: 'inline',
    float: 'right',
    width: theme.Button.width,
  };
  private CancelButtonStyle = {
    width: theme.Button.width,
  };

  constructor(props: IAccountDetailsProps) {
    super(props);

    const n: IState = {
      editable: false,
      blocked: props.currentAccount?.state === Account.AccountDetailStateEnum.B,
      blockReasons: props.currentAccount?.block_reasons ?? [],
      closed: props.currentAccount?.state === Account.AccountDetailStateEnum.C,
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
      dataChanged: false,
      limitsExpaned: false,
      selectedReason: '',
      delReasons: [],
      newReason: '',
    };

    this.state = n;

    this.onBlockAccount = this.onBlockAccount.bind(this);
    this.closeAccount = this.closeAccount.bind(this);
    this.editClicked = this.editClicked.bind(this);
    this.saveClicked = this.saveClicked.bind(this);
    this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
    this.setLimitsExpanded = this.setLimitsExpanded.bind(this);
    this.handleReasonSelect = this.handleReasonSelect.bind(this);
    this._onChangeCustomerData();
  }

  _onChangeCustomerData() {
    if (
      this.props.person != null &&
      (this.props.person.in_closure === true ||
        this.props.person.state === Ident.PersonStateEnum.C)
    ) {
      this.getReasonForClosure(this.props.person.person_id);
    }
    this.setState({
      blocked: this.props.currentAccount?.state === Account.AccountDetailStateEnum.B,
      blockReasons: this.props.currentAccount?.block_reasons ?? [],
    });
  }

  componentDidUpdate(prevProps: IAccountDetailsProps) {
    if (!compareObject(prevProps, this.props)) {
      this._onChangeCustomerData();
    }
  }

  getReasonForClosure(person_id: number) {
    api
      .asyncRequest<Closure>(
        { person_id: person_id },
        apis.MaintenanceApi,
        'personReasonForClosureGet',
      )
      .then((response: Closure) => {
        this.setState({
          selectedReason: response.reason != null ? response.reason : '',
          additionalReason: response.comment,
          closed: true,
        });
      })
      .catch((err: ApiError) => {
        Log.error(Logs.API, err);
      });
  }

  editClicked() {
    this.setState({
      editable: !this.state.editable,
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    });
  }

  saveClicked() {
    //  InitComponentHandler.cleanUp(this.constructor.name);
    this.setState({
      editable: false,
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    });
    let accNr: string;
    if (
      this.props.currentAccount == null ||
      this.props.currentAccount.account_number == null
    ) {
      return;
    } else {
      accNr = this.props.currentAccount.account_number;
    }
    if (
      (this.state.blocked &&
        this.props.currentAccount.state === Account.AccountDetailStateEnum.A) ||
      this.state.blockReasons.length > 0
    ) {
      this.blockAccount(accNr);
    }
    if (
      (!this.state.blocked &&
        this.props.currentAccount.state === Account.AccountDetailStateEnum.B) ||
      this.state.delReasons.length > 0
    ) {
      this.unblockAccount(accNr);
    }
    if (
      this.state.closed &&
      this.props.currentAccount.state !== Account.AccountDetailStateEnum.C
    ) {
      this.closeAccount();
    } else if (
      !this.state.closed &&
      this.props.currentAccount.state === Account.AccountDetailStateEnum.C
    ) {
      this.unCloseAccount();
    }
  }

  unCloseAccount() {
    // not implemented yet
  }

  setAccountToClose() {
    if (this.props.currentAccount) {
      //  if (!this.state.closed) {
      this.setState({
        closed: !this.state.closed,
        dataChanged: true,
      });
      // }
    }
  }

  onBlockAccount() {
    this.setState({
      blocked: !this.state.blocked,
      dataChanged: true,
    });
  }

  handleReasonSelect(message: any) {
    this.setState({
      selectedReason: message.reason,
    });
  }

  getReasonOptions(): Array<IOption> {
    const options = [];
    options.push({
      key: 'defaultOptionKey',
      name: 'select Reason',
      value: '',
    });
    for (const o in Ident.PersonPartnerTransferDeleteReasonEnum) {
      if (ClientConfig.skipReasons.indexOf(o) > -1) {
        continue;
      }
      options.push({
        key: 'defaultOptionKey' + o,
        name: translate('customers.close.' + o),
        value: o,
      });
    }
    return options;
  }

  createCheckboxes(): Array<ReactElement> {
    const out = [];
    for (const o of this.state.blockReasons) {
      if (o['display'] === false) {
        continue;
      }
      out.push(
        <ReasonWrapper>
          <ValueField style={{ marginTop: '8px' }}>{o.block_reason}</ValueField>
          <IconWrapper
            onClick={() => {
              const reasons = this.state.blockReasons.filter((item: any) => {
                return item.block_reason !== o.block_reason;
              });

              const delReasons = this.state.delReasons;
              delReasons.push(o.block_account_id);
              this.setState({
                delReasons: delReasons,
                blockReasons: reasons,
                blocked: reasons.length !== 0,
              });
            }}
          >
            {Icons.del()}
          </IconWrapper>
        </ReasonWrapper>,
      );
    }
    return out;
  }

  blockAccount(accNr: string) {
    return new Promise((resolve, reject) => {
      const blockRequest: Account.AccountAccountNumberBlockPutRequest = {
        account_number: accNr,
        BlockAccount: {
          block_reasons:
            this.state.blockReasons.length > 0
              ? this.state.blockReasons.map((elem: any) => {
                  return elem.block_reason;
                })
              : undefined,
        },
      };
      api.request(
        blockRequest,
        apis.DefaultApi,
        'accountAccountNumberBlockPut',
        (error: ApiError, response: VoidResponse) => {
          if (response != null && this.props.currentAccount != null) {
            // Update cached account
            InitComponentHandler.cleanUp(this.constructor.name);
            const acc: IItfAccount = this.props.currentAccount;
            acc.state = Account.AccountDetailStateEnum.B;
            updatePerson(this.props.person.person_id);
            return resolve(response);
          } else {
            const config: IMessageConfig = MessageHandler.onError(
              Reporter['account.details.accountnumber.block.put.error'],
              error.statusText,
            );
            this.setState({
              showInlineError: config.errorMethods.inline,
              key: config.translationKey != null ? config.translationKey + '.error' : '',
              errorMessage:
                config.errorMessage != null ? config.errorMessage : error.statusText,
              keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
              dataChanged: false,
            });
            return reject(error);
          }
        },
      );
    });
  }

  unblockAccount(accNr: string) {
    return new Promise((resolve, reject) => {
      const blockRequest: Account.AccountAccountNumberBlockDeleteRequest = {
        account_number: accNr,
        request_body:
          this.state.delReasons.length > 0
            ? this.state.delReasons.filter((elem: number) => {
                return elem > -1;
              })
            : [],
      };
      if (blockRequest.request_body?.length <= 0) {
        return;
      }
      api.request(
        blockRequest,
        apis.DefaultApi,
        'accountAccountNumberBlockDelete',
        (error, response) => {
          if (response != null && this.props.currentAccount != null) {
            // Update cached account
            InitComponentHandler.cleanUp(this.constructor.name);
            const acc: IItfAccount = this.props.currentAccount;
            updatePerson(this.props.person.person_id);
            return resolve(response);
          } else {
            const config: IMessageConfig = MessageHandler.onError(
              Reporter['account.details.accountnumber.block.delete.error'],
              error.statusText,
            );
            this.setState({
              showInlineError: config.errorMethods.inline,
              errorMessage:
                config.errorMessage != null ? config.errorMessage : error.statusText,
              key: config.translationKey != null ? config.translationKey + '.error' : '',
              keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            });
            return reject(error);
          }
        },
      );
    });
  }

  setLimitsExpanded(value: boolean) {
    this.setState({
      limitsExpaned: value,
    });
  }

  doCloseRequest(partnerId?: number) {
    if (this.state.selectedReason !== '') {
      const params: any = {
        partner_id: partnerId,
        person_id: this.props.person?.person_id,
        reason: this.state.selectedReason as Ident.PersonPartnerTransferDeleteReasonEnum,
        comment: this.state.additionalReason,
      };

      api
        .asyncRequest<Ident.TransferId>(
          params,
          apis.MaintenanceApi,
          ClientConfig.operationIdForClosure,
        )
        .then((response: any) => {
          if (response.release_request_id != null) {
            MessageHandler.onSuccess(Reporter['customer.close.release']);
          } else {
            MessageHandler.onSuccess(Reporter['customer.close.noRelease']);
            this.setState({
              closed: true,
              keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            });

            const accounts = CustomerDataStore.getAccounts().map((el: IItfAccount) => {
              el.state = Account.AccountDetailStateEnum.C;
              return el;
            });
            Actions.setCustomerAccounts(accounts);
            if (this.props.person != null) {
              updatePerson(this.props.person.person_id);
            }
          }
        })
        .catch((error: ApiError) => {
          const config: IMessageConfig = MessageHandler.onError(
            Reporter['person.partner.transfer.delete'],
            evaluateErrorMessage(error, true),
            evaluateErrorMessage(error, false),
          );
          this.setState({
            showInlineError: config.errorMethods.inline,
            closed: false,
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          });
          return;
        });
    }
  }

  closeAccount() {
    if (this.state.additionalReason != null && this.state.additionalReason.length > 100) {
      MessageHandler.onError(Reporter['customer.close.reason.maxlength']);
      return;
    }

    if (this.props.currentAccount) {
      if (
        this.props.currentAccount == null ||
        this.props.currentAccount.account_number == null
      ) {
        return;
      }

      if (this.props.person == null || this.state.selectedReason === '') {
        this.setState({ closed: false });
        const config: IMessageConfig = MessageHandler.onError(
          Reporter['account.close.selectedUser'],
        );
        this.setState({
          showInlineError: config.errorMethods.inline,
          key: config.translationKey != null ? config.translationKey + '.error' : '',
          errorMessage: config.errorMessage != null ? config.errorMessage : '',
          keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
        });
        return;
      }

      if (this.props.currentAccount.balance !== 0) {
        this.setState({ closed: false });
        const config: IMessageConfig = MessageHandler.onError(
          Reporter['account.close.nonzeroBalance'],
        );
        this.setState({
          showInlineError: config.errorMethods.inline,
          key: config.translationKey != null ? config.translationKey + '.error' : '',
          errorMessage: config.errorMessage != null ? config.errorMessage : "Can't close account - Account has a non-zero balance!",
          keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
        });
        return;
      }

      const partnerParam: Ident.PartnerGetRequest = {
        person_id: this.props.person.person_id,
      };
      if (ClientConfig.getParnterIds) {
        api
          .asyncRequest<Array<Ident.Partner>>(partnerParam, apis.PartnerApi, 'partnerGet')
          .then(response => {
            const partner: Ident.Partner | undefined = response.find(
              (partner: Ident.Partner) => partner.partner_name === 'ndPay',
            );
            InitComponentHandler.cleanUp(this.constructor.name);
            if (this.props.person == null) {
              const config: IMessageConfig = MessageHandler.onError(
                Reporter['account.close.selectedUser'],
              );
              this.setState({
                showInlineError: config.errorMethods.inline,
                key: config.translationKey != null ? config.translationKey + '.error' : '',
                errorMessage: config.errorMessage != null ? config.errorMessage : '',
                keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
              });
              return;
            }

            if (partner == null) {
              const config: IMessageConfig = MessageHandler.onError(
                Reporter['account.details.partner.get.request'],
              );
              this.setState({
                keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
              });
              return;
            }
            this.doCloseRequest(partner.partner_id);
          })
          .catch(() => {
            const config: IMessageConfig = MessageHandler.onError(
              Reporter['account.details.partner.transfer.get.request'],
            );
            this.setState({
              showInlineError: config.errorMethods.inline,
              closed: false,
              key: config.translationKey != null ? config.translationKey + '.error' : '',
              errorMessage: config.errorMessage != null ? config.errorMessage : '',
              keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            });
          });
      } else {
        this.doCloseRequest();
      }
    }
  }

  customerHasKosovoEntity() {
    return this.props.person?.entity_id === ENTITY_ID_XK;
  }

  downloadQR() {
    if (this.props.currentAccount == null) {
      return;
    }
    api
      .genericRequest<Blob>(
        '/account/' + this.props.currentAccount.account_number + '/qr.png',
        'GET',
        {
          'Content-Type': 'application/json',
          response_type: 'blob',
        },
        undefined,
        undefined,
        false,
      )
      .then((response: Blob) => {
        downloadFile(
          response,
          'qr_code_' +
            (this.props.currentAccount != null
              ? this.props.currentAccount.account_number
              : ''),
        );
      })
      .catch((error: ApiError) => {
        MessageHandler.onError(
          Reporter['account.qrcode.png.get'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
      });
  }

  render() {
    const permissionBlock =
      this.props.currentAccount != null &&
      this.props.currentAccount?.state === Account.AccountDetailStateEnum.B
        ? Account.OperationId.AccountAccountNumberBlockDelete
        : Account.OperationId.AccountAccountNumberBlockPut;
    if (this.props.currentAccount == null) {
      return null;
    }

    return (
      <FlexBox>
        <WrapperOuter style={{ width: '80%', margin: 'auto' }}>
          <AccountSelector />
        </WrapperOuter>
        {this.props.currentAccount.state === Account.AccountDetailStateEnum.B ? (
          <StyledHeader>{translate('customers.isBlocked')}</StyledHeader>
        ) : null}
        {this.props.person != null && this.props.person.state === Ident.PersonStateEnum.C ? (
          <StyledHeader>{translate('customers.closed')}</StyledHeader>
        ) : null}
        {this.props.person != null &&
        this.props.person.in_closure === true &&
        this.props.person.state !== Ident.PersonStateEnum.C ? (
          <StyledHeader>{translate('customers.inClosure')}</StyledHeader>
        ) : null}

        <WrapperOuter>
          <WrapperInner>
            <ColumnBox style={{ minWidth: '1050px' }}>
              <KeyValueBlock maxWidth={this.state.limitsExpaned === true ? '300px' : '450px'}>
                <KeyField>{translate('customers.accountNumber')}:</KeyField>
                <DisplayMenuComponent
                  elementKey="accountNumber"
                  copyValue={this.props.currentAccount?.account_number ?? ''}
                  methods={{}}
                >
                  <ValueField>{this.props.currentAccount?.account_number}</ValueField>
                </DisplayMenuComponent>
              </KeyValueBlock>
              <KeyValueBlock maxWidth={this.state.limitsExpaned === true ? '300px' : '450px'}>
                <KeyField>{translate('customers.iban')}:</KeyField>
                <DisplayMenuComponent
                  elementKey="iban"
                  copyValue={
                    this.props.currentAccount.iban != null
                      ? this.props.currentAccount.iban
                      : ''
                  }
                  methods={{}}
                >
                  <ValueField>{this.props.currentAccount.iban}</ValueField>
                </DisplayMenuComponent>
              </KeyValueBlock>
              {this.customerHasKosovoEntity() ? (
                <KeyValueBlock maxWidth={this.state.limitsExpaned === true ? '300px' : '450px'}>
                  <KeyField>{translate('customers.localIban')}:</KeyField>
                  <DisplayMenuComponent
                    elementKey="local_iban"
                    copyValue={
                      this.props.currentAccount.local_iban != null
                        ? this.props.currentAccount.local_iban
                        : ''
                    }
                    methods={{}}
                  >
                    <ValueField>{this.props.currentAccount.local_iban}</ValueField>
                  </DisplayMenuComponent>
                </KeyValueBlock>
              ) : null}
              <KeyValueBlock maxWidth={this.state.limitsExpaned === true ? '300px' : '450px'}>
                <KeyField>{translate('customers.interestRate')}:</KeyField>
                <DisplayMenuComponent
                  elementKey="interest"
                  copyValue={this.props.currentAccount?.interest_rate?.toString() ?? ''}
                  methods={{}}
                >
                  <ValueField>{this.props.currentAccount.interest_rate}</ValueField>
                </DisplayMenuComponent>
              </KeyValueBlock>
              <KeyValueBlock maxWidth={this.state.limitsExpaned === true ? '300px' : '450px'}>
                <KeyField>{translate('customers.accountType')}:</KeyField>
                <DisplayMenuComponent
                  elementKey="accountType"
                  copyValue={this.props.currentAccount?.account_type}
                  methods={{}}
                >
                  <ValueField>{this.props.currentAccount.account_type}</ValueField>
                </DisplayMenuComponent>
              </KeyValueBlock>
              <KeyValueBlock maxWidth={this.state.limitsExpaned === true ? '300px' : '450px'}>
                <KeyField>{translate('customers.accountOpeningDate')}:</KeyField>
                <DisplayMenuComponent
                  elementKey="openingDate"
                  copyValue={format.datetime(this.props.currentAccount?.opening_date) ?? ''}
                  methods={{}}
                >
                  <ValueField>
                    {format.datetime(this.props.currentAccount?.opening_date)}
                  </ValueField>
                </DisplayMenuComponent>
              </KeyValueBlock>
              {this.props.currentAccount != null &&
              this.props.currentAccount.state_change_date != null &&
              this.props.currentAccount.state === Account.AccountDetailStateEnum.C ? (
                <KeyValueBlock
                  maxWidth={this.state.limitsExpaned === true ? '300px' : '450px'}
                >
                  <KeyField>{translate('customers.accountclosingDate')}:</KeyField>
                  <DisplayMenuComponent
                    elementKey="openingDate"
                    copyValue={
                      format.datetime(this.props.currentAccount?.state_change_date) ?? ''
                    }
                    methods={{}}
                  >
                    <ValueField>
                      {format.datetime(this.props.currentAccount?.state_change_date)}
                    </ValueField>
                  </DisplayMenuComponent>
                </KeyValueBlock>
              ) : null}
              <KeyValueBlock maxWidth={this.state.limitsExpaned === true ? '300px' : '450px'}>
                <KeyField>{translate('customers.lastTransaction')}:</KeyField>
                <DisplayMenuComponent
                  elementKey="lastTransaction"
                  copyValue={
                    format.datetime(this.props.currentAccount?.last_transaction) ?? ''
                  }
                  methods={{}}
                >
                  <ValueField>
                    {format.datetime(this.props.currentAccount?.last_transaction)}
                  </ValueField>
                </DisplayMenuComponent>
              </KeyValueBlock>
              {this.props.currentAccount.state === Account.AccountDetailStateEnum.B ? (
                <KeyValueBlock
                  maxWidth={this.state.limitsExpaned === true ? '300px' : '450px'}
                >
                  <KeyField>{translate('customers.blockedSince')}</KeyField>
                  <ValueField>
                    {format.datetime(this.props.currentAccount?.state_change_date)}
                  </ValueField>
                </KeyValueBlock>
              ) : null}
              <KeyValueBlock
                maxWidth={this.state.limitsExpaned === true ? '300px' : '450px'}
                style={{ flexDirection: 'column' }}
              >
                <div style={{ display: 'flex' }}>
                  <KeyField style={{ width: '50%' }}>
                    {translate('customers.blocked')}
                  </KeyField>
                  <SwitchWrapper style={{ width: '50%' }}>
                    <Switch
                      id={'blockAccountSwitch'}
                      disabled={
                        !PermissionStore.hasPermission(permissionBlock) ||
                        (this.props.currentAccount != null &&
                          this.props.currentAccount.state === Account.AccountDetailStateEnum.C)
                      }
                      onChange={() => {
                        this.onBlockAccount();
                      }}
                      defaultValue={this.state.blocked === true}
                    />
                  </SwitchWrapper>
                </div>
                {this.state.blocked ? (
                  <div>
                    {this.createCheckboxes()}
                    <ReasonWrapper>
                      <BigInput
                        placeHolder={translate('customers.close.block.placeholder')}
                        label={translate('customers.close.block.label')}
                        type="street-address"
                        id="blocked_reason"
                        onSubmit={() => {}}
                        valueFromState={true}
                        value={this.state.newReason}
                        notification={n => {
                          this.setState({
                            newReason: n.blocked_reason.value,
                          });
                        }}
                      />
                      <IconWrapper
                        onClick={() => {
                          const reasons = this.state.blockReasons ?? [];
                          reasons.push({
                            block_reason: this.state.newReason,
                            account_block_id: -1,
                            display: true,
                          });
                          this.setState({
                            blockReasons: reasons,
                            newReason: '',
                          });
                        }}
                      >
                        {Icons.addPackage()}
                      </IconWrapper>
                    </ReasonWrapper>
                  </div>
                ) : null}
              </KeyValueBlock>
              <KeyValueBlock
                maxWidth={this.state.limitsExpaned === true ? '300px' : '450px'}
                style={{
                  flexDirection: 'column',
                  height: this.state.closed === true ? '186px' : '62px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                  }}
                >
                  <KeyField style={{ width: '50%' }}>
                    {translate('customers.closeAccount')}
                  </KeyField>
                  <SwitchWrapper style={{ width: '50%' }}>
                    <Switch
                      id={'closeAccountSwitch'}
                      disabled={
                        this.props.currentAccount.state === Account.AccountDetailStateEnum.C ||
                        (this.props.person != null && this.props.person.in_closure)
                      }
                      onChange={() => {
                        this.setAccountToClose();
                      }}
                      defaultValue={
                        this.state.closed === true ||
                        this.props.currentAccount.state === Account.AccountDetailStateEnum.C
                      }
                    />
                  </SwitchWrapper>
                </div>
                {this.state.closed === true ? (
                  <React.Fragment>
                    <StyledSelect
                      helptext={translate('customers.close.reason')}
                      id="reason"
                      options={this.getReasonOptions()}
                      notification={this.handleReasonSelect}
                      current={this.state.selectedReason}
                    />
                    <BigInput
                      placeHolder={translate('customers.close.additional.placeholder')}
                      label={translate('customers.close.additional.label')}
                      type="street-address"
                      id="additional"
                      onSubmit={() => {}}
                      valueFromState={true}
                      value={this.state.additionalReason}
                      notification={n => {
                        this.setState({
                          additionalReason: n.additional.value,
                        });
                      }}
                    />
                  </React.Fragment>
                ) : null}
              </KeyValueBlock>
              <KeyValueBlock maxWidth={this.state.limitsExpaned === true ? '300px' : '450px'}>
                <KeyField>{translate('customers.currency')}:</KeyField>
                <DisplayMenuComponent
                  elementKey="currency"
                  copyValue={this.props.currentAccount?.currency ?? ''}
                  methods={{}}
                >
                  <ValueField>{this.props.currentAccount.currency}</ValueField>
                </DisplayMenuComponent>
              </KeyValueBlock>
              {process.env.REACT_APP_CLIENT === 'ndpay' ? (
                <KeyValueBlock
                  maxWidth={this.state.limitsExpaned === true ? '300px' : '450px'}
                  style={{ flexDirection: 'row', height: '62px' }}
                >
                  <KeyField style={{ width: '50%' }}>
                    {translate('customers.downloadQR')}:
                  </KeyField>
                  <IconValueField onClick={() => this.downloadQR()}>
                    {Icons.downloadIcon()}
                  </IconValueField>
                </KeyValueBlock>
              ) : null}
            </ColumnBox>
            <MessageBox>
              {getFormComponents(
                this.state.showInlineError,
                this.state.showInlineSuccess,
                this.state.errorMessage,
                this.state.successMessage,
                this.state.key,
              )}
            </MessageBox>
          </WrapperInner>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '40%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '65px',
                justifyContent: 'flex-start',
              }}
            >
              <ButtonCancel
                id="btnEditCustomerAccountDetailsCancel"
                onClick={this.editClicked}
                style={this.CancelButtonStyle}
              >
                {translate('button.cancel')}
              </ButtonCancel>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '65px',
                justifyContent: 'flex-start',
              }}
            >
              <ButtonOk
                key={this.state.keyForLoadingSpinner}
                onClick={this.saveClicked}
                disabled={
                  (this.state.dataChanged === false ||
                    (this.state.closed === true && this.state.selectedReason === '')) &&
                  this.state.blockReasons.length > 0 &&
                  this.state.delReasons.length === 0
                }
                id="btnEditCustomerAccountDetailsOk"
                style={this.OkButtonStyle}
              >
                {translate('button.save')}
              </ButtonOk>
            </div>
          </div>
        </WrapperOuter>
      </FlexBox>
    );
  }
}

export default withPersonAndAccount(IAccountDetailsBox);
