import React, { ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';
import {
  RelativeOverlay,
  IRelativeProps,
  IRelativeState,
} from '../../../logic/handler/overlayhandler/globaloverlays/aRelativeOverlay';
import TouchableOpacity from '../buttons/touchableOpacity';

export interface IIconPickerOverlayProps extends IRelativeProps {
  children: Array<ReactElement> | ReactElement;
  onPress: (itemValue: string | number, index: number) => void;
}

interface IState extends IRelativeState {}

export default class IconPickerOverlay extends RelativeOverlay<
  IIconPickerOverlayProps,
  IState
> {
  constructor(props: IIconPickerOverlayProps) {
    super(props);

    this.state = {};
  }

  private _onSelect(event: SyntheticEvent, index: number): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.props.onPress != null) {
      let itemValue: string | number;
      const children: Array<ReactElement> | ReactElement = this.props.children;
      if (Array.isArray(children)) {
        itemValue = children[index].props.value;
      } else {
        itemValue = children.props.value;
      }
      this.props.onPress(itemValue, index);
    }
    if (this.props.selfClose != null) {
      this.props.selfClose();
    }
  }

  private createContainer(child: ReactElement, index: number): ReactElement {
    return (
      <TouchableOpacity
        onClick={event => this._onSelect(event, index)}
        containerStyle={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '4px',
        }}
      >
        {child}
      </TouchableOpacity>
    );
  }

  reviveState() {}

  defineContent() {
    let childElements: Array<ReactElement> | ReactElement | null;
    const props: Array<ReactElement> | ReactElement = this.props.children;
    if (props == null) {
      childElements = null;
    } else if (Array.isArray(props)) {
      childElements = props.map((child: ReactElement, index: number) => {
        return this.createContainer(child, index);
      });
    } else {
      childElements = this.createContainer(props, 0);
    }
    return (
      <Container posX={this.props.posX} posY={this.props.posY} width={this.props.width}>
        {childElements}
      </Container>
    );
  }
}

const Container = styled.div<{ posX?: string; posY?: string; width?: string }>`
  position: absolute;
  left: ${props => (props.posX == null ? '0px' : props.posX)};
  top: ${props => (props.posY == null ? '0px' : props.posY)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${props => (props.width == null ? '100px' : props.width)};
  background-color: white;
  border: 1px solid #e8ecef;
  border-radius: 4px;
`;
