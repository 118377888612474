import { Images } from '../../images';
import { ReactElement, ReactNode } from 'react';
import i18next from 'i18next';
import { api, apis, Ident, Account, ApiError } from '../api';
import { Log, Logs } from '../log';
import { Actions } from '../flux';
import currencySymbols from './currencySymbolMap';
import React from 'react';
import { Accounts } from '../accounts';
import { IItfAccount } from '../types';
import { MessageHandler } from '../handler/messagehandler/messageHandler';
import { Reporter } from '../handler/messagehandler/messageHandlerConfig';
import { OverlayHandler, Overlays } from '../handler/overlayhandler/overlayHandler';
import { translate } from '../../common/language/translate';
import { en } from '../../common/language/en';
import { sq } from '../../common/language/sq';
import { de } from '../../common/language/de';
import TouchableOpacity from '../../components/atomiccompoents/buttons/touchableOpacity';
import { FloatingCircle } from '../../content/dashboard/content/customers/basicStyledComponents/customerDetails.css';
import { IOption } from '../../components/atomiccompoents/form';
import { CountryCodeAlpha2, CountryCodeAlpha3PlusKosovo } from '../api/ident';
import { getCountry } from '../../common/language/getCountry';

/**
 * [Merge]
 * Merges two arrays, the resulting array will contain all values from both arrays.
 * @param a An array to be merged, may be null or undefined.
 * @param b An array to be merged, may be null or undefined.
 *
 * @returns Merge of a & b, if a is null b is returned, if b is null a is returned, if both are null an empty array is returned.
 */
export const mergeArray = (
  a: Array<any> | undefined | null,
  b: Array<any> | undefined | null,
): Array<any> => {
  if (a == null && b == null) {
    return [];
  } else if (a != null && b == null) {
    return a;
  } else if (b != null && a == null) {
    return b;
  }

  if (a == null) {
    return [];
  }

  return a.concat(b);
};

export const createDynamicLink = async (referalLink: string) => {
  const data = {
    dynamicLinkInfo: {
      domainUriPrefix: 'https://onefor.page.link',
      link: referalLink,
    },
  };
  const response = await fetch(
    `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCtBeOBQP4ZHWFhQFmp729RQZASENER4qs`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );
  try {
    const linkData = await response.json();
    return linkData?.shortLink;
  } catch (error) {
    console.log('error:', error);
  }
};

/**
 * [Symmetric difference]
 * Merges two arrays, the resulting array will have all values unique.
 * @param a An array to be merged, may be null or undefined.
 * @param b An array to be merged, may be null or undefined.
 *
 * @returns Unique merge of a & b, if a is null b is returned, if b is null a is returned, if both are null an empty array is returned.
 */
export const mergeArrayUnique = (
  a: Array<any> | undefined | null,
  b: Array<any> | undefined | null,
): Array<any> => {
  if (a == null && b == null) {
    return [];
  } else if (a != null && b == null) {
    return uniqueArray(a);
  } else if (b != null && a == null) {
    return uniqueArray(b);
  }

  if (a == null) {
    return [];
  }

  a = uniqueArray(a);
  b = uniqueArray(b, a);
  return a.concat(b);
};
/**
 * method used to flatten a json object
 * @param object object to flatten
 */
export const flattenJsonObject = (object: {}, parentKey?: string): {} => {
  let newObject = {};
  for (const key in object) {
    const parsedKey = key as keyof object;
    let aggregatedKey;
    if (parentKey != null) {
      aggregatedKey = parentKey + '.' + parsedKey;
    } else {
      aggregatedKey = parsedKey;
    }
    const newKey = aggregatedKey as keyof object;
    if ((object[parsedKey] as any) instanceof Date) {
      //@ts-ignore
      newObject[newKey] = new Date(object[parsedKey]);
    }
    if (typeof object[parsedKey] === 'object') {
      newObject = {
        ...newObject,
        ...flattenJsonObject(object[parsedKey], newKey),
      };
    } else {
      //@ts-ignore
      newObject[newKey] = object[parsedKey];
    }
  }
  return newObject;
};
/**
 * method for cloning an object completely without having reference-issues
 * @param object  object co clone
 */
export const deepCopy = (object: Object): Object => {
  if (React.isValidElement(object)) {
    return React.cloneElement(object);
  } else if (object instanceof Date) {
    return new Date(object);
  } else if (object instanceof Array) {
    const clonedArray = [];
    for (const i in object) {
      clonedArray.push(deepCopy(object[i]));
    }
    return clonedArray;
  } else if (typeof object === 'object') {
    const newObject: Object = {};
    for (const key in object) {
      const validKey = key as keyof Object;
      if (typeof object[validKey] === 'object') {
        //@ts-ignore
        newObject[validKey] = deepCopy(object[validKey]);
      } else {
        //@ts-ignore
        newObject[validKey] = object[validKey];
      }
    }
    return newObject;
  } else {
    return object;
  }
};

export const compareObject = (a: Object | undefined, b: Object | undefined): boolean => {
  let out = true;
  if (a === null || b === null) {
    return false;
  }
  if (typeof a !== typeof b) {
    return false;
  }
  if (a instanceof Date && b instanceof Date) {
    return compareDate(a, b) === 0;
  } else if (a instanceof Array && b instanceof Array) {
    if (a.length !== b.length) {
      return false;
    }
    for (const i in a) {
      if (!compareObject(a[i], b[i])) {
        return false;
      }
    }
  } else if (typeof a === 'object') {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    out = compareObject(aKeys, bKeys);
    if (!out) {
      return false;
    }
    for (const i in aKeys) {
      //@ts-ignore
      if (!compareObject(a[aKeys[i]], b[bKeys[i]])) {
        return false;
      }
    }
  } else if (typeof a === 'number' || typeof a === 'string') {
    return a === b;
  }
  return out;
};
/**
 * method to login a user
 * @param response resonsedata from permissionrequest
 */
export const performLogin = (response: Ident.PermissionResponse): Promise<boolean> => {
  Actions.setLogoutTime(response.inactivity_timeout);
  //@ts-ignore
  if (response.amzn_oidc_data != null && response.amzn_oidc_data.user_roles != null) {
    //@ts-ignore
    Actions.setRoles(response.amzn_oidc_data.user_roles);
  }
  return new Promise<boolean>((resolve, reject) => {
    api
      .asyncRequest<Account.PermissionResponse>(
        [],
        apis.OpenIDConnectAccount,
        'personAuthorizationGet',
      )
      .then(accountResponse => {
        Actions.identPermissionsChanged(response.operation_ids);
        Actions.accountPermissionsChanged(accountResponse.operation_ids);
        Actions.userChanged(response.person);

        api
          .asyncRequest({}, apis.SelfServiceApi, 'selfSettingsGet')
          .then((response: any) => {
            if (response != null && response.language != null) {
              i18next.changeLanguage(response.language.toString());
            }
          })
          .catch((error: ApiError) => {
            Log.debug(Logs.API, error);
            return reject(error);
          });

        return resolve(true);
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
        return reject(error);
      });
  });
};

export const performErrorCodeUpdate = () => {
  loadData('/ident/api-docs/error_codes.json', response => {
    const cmpEn = en;
    const cmpEnKeys = Object.keys(en.backend);
    const cmpDe = de;
    const cmpSq = sq;
    try {
      const parsedResp = JSON.parse(response);
      for (const o of parsedResp) {
        if (cmpEnKeys.indexOf(o.error_code.toString()) === -1) {
          cmpEn.backend[o.error_code.toString()] = o.error_text;
          cmpDe.backend[o.error_code.toString()] = o.error_text;
          cmpSq.backend[o.error_code.toString()] = o.error_text;
        }
      }
    } catch (e) {
      console.log(response, e);
    }
  });
};

export const loadData = (url: string, callback: (response: any) => void): any => {
  fetch(url)
    .then(response => response.text())
    .then(text => callback(text))
    .catch((error: any) => {
      Log.error(Logs.PARSER, error);
      callback(undefined);
    });
};

export const getPersonForAccount = (account_number: string) => {
  const req: Account.AccountAccountNumberPersonGetRequest = {
    account_number: account_number,
  };
  return api.asyncRequest<Array<number>>(
    req,
    apis.ReplicationApi,
    'accountAccountNumberPersonGet',
  );
};

export const getFullPerson = (person_id: number) => {
  const personSearchRequest: Ident.PersonPersonIdGetRequest = {
    person_id: person_id,
  };

  return api.asyncRequest<Ident.Person>(
    personSearchRequest,
    apis.MaintenanceApi,
    'personPersonIdGet',
  );
};

export const getFullAddress = (person_id: number) => {
  const addressParams: Ident.PersonAddressGetRequest = {
    person_id: person_id,
  };
  return api.asyncRequest<Array<Ident.Address>>(
    addressParams,
    apis.MaintenanceApi,
    'personAddressGet',
  );
};

export const getPhone = (person_id: number) => {
  const addressParams: Ident.PersonPhoneListRequest = {
    person_id: person_id,
  };
  return api.asyncRequest<Array<Ident.Phone>>(
    addressParams,
    apis.MaintenanceApi,
    'personPhoneList',
  );
};

export const getEmailList = (person_id: number) => {
  const req: Ident.PersonEmailaddressGetRequest = {
    person_id,
    get_not_verified: true,
  };
  return api.asyncRequest<Array<Ident.EmailAddress>>(
    req,
    apis.MaintenanceApi,
    'personEmailaddressGet',
  );
};

export const updateAccount = (
  accountNumber: string,
  redirectPath?: string,
  redirectCallback?: (path: string) => void,
) => {
  const params: Account.AccountSearchRequest = {
    account_number: accountNumber,
  };
  api
    .asyncRequest<Array<Account.AccountParams>>(params, apis.DefaultApi, 'accountSearch')
    .then(response => {
      if (response.length !== 1) {
        MessageHandler.onError(Reporter['account.person.get.multipleResults']);
        return;
      }
      Accounts.getAccountById(response[0].account_number)
        .then(response => {
          Log.debug(Logs.API, 'Found account ' + response.name);
          Actions.backofficeChanged(response);
          setTimeout(() => {
            if (redirectPath != null && redirectCallback != null) {
              redirectCallback(redirectPath);
            }
          }, 300);
        })
        .catch((error: ApiError) => {
          Log.error(Logs.API, error);
        });
    })
    .catch(() => {
      MessageHandler.onError(Reporter['account.person.get']);
    });
};

export const loadAndShowImage = (person_id: number, imageId: number) => {
  const params: Ident.PersonDocumentGetRequest = {
    person_id: person_id,
    document_id: imageId,
  };
  api.request(
    params,
    apis.MaintenanceApi,
    'personDocumentGet',
    (error: ApiError, response: Blob) => {
      if (response != null) {
        if (response.type.match('pdf')) {
          downloadFile(response, 'document.pdf');
        } else {
          OverlayHandler.showOverlay(Overlays.imagePreview, {
            image: response,
            alt: 'Image',
          });
        }
      } else {
        MessageHandler.onError(Reporter['image.not.found']);
      }
    },
  );
};

export const updatePerson = (
  personId: number,
  redirectPath?: string,
  redirectCallback?: (path: string) => void,
) => {
  const personSearchRequest: Ident.PersonSearchRequest = {
    search_id: personId.toString(),
  };

  return api
    .asyncRequest<Array<Ident.PersonFound>>(
      personSearchRequest,
      apis.MaintenanceApi,
      'personSearch',
    )
    .then((response: Array<Ident.PersonFound>) => {
      if (response != null) {
        if (response.length === 1) {
          getFullPerson(response[0].person_id).then((person: Ident.Person) => {
            Accounts.getAccountsByPersonId(response[0].person_id).then(accounts => {
              let currentAccount: IItfAccount | undefined = undefined;
              if (accounts.length > 0) {
                currentAccount = accounts[0];
              }
              Actions.setCustomerData({
                accounts: accounts,
                currentAccount: currentAccount,
                selectedUser: person,
              });
              setTimeout(() => {
                if (redirectPath != null && redirectCallback != null) {
                  redirectCallback(redirectPath);
                }
              }, 300);
            });
            return;
          });
        }
      }
    });
};

export const TimeRangeOverlap = (
  startA: string,
  endA: string,
  startB: string,
  endB: string,
): boolean => {
  if (
    inRange(startA, startB, endB) ||
    inRange(startB, startA, endA) ||
    inRange(endA, startB, endB) ||
    inRange(endB, startA, endA)
  ) {
    return true;
  }
  return false;
};

export const inRange = (time: string, startA: string, startB: string): boolean => {
  const timeParsed = time.split(':');
  const startAParsed = startA.split(':');
  const startBParsed = startB.split(':');
  const timeHours = parseInt(timeParsed[0]);
  const timeMinutes = parseInt(timeParsed[1]);

  const startAHours = parseInt(startAParsed[0]);
  const startAMinutes = parseInt(startAParsed[1]);

  const startBHours = parseInt(startBParsed[0]);
  const startBMinutes = parseInt(startBParsed[1]);

  if (
    timeHours < startAHours ||
    (timeHours === startAHours && timeMinutes < startAMinutes) ||
    (timeHours === startBHours && timeMinutes > startBMinutes) ||
    timeHours > startBHours
  ) {
    return false;
  }
  return true;
};

/**
 * [unique]
 * @param a An array to unique all items in it
 * @param b An optional array, if provided, all values provided in b are stripped from a
 * @returns An array with unqie items in it
 */
export const uniqueArray = (a: Array<any> | undefined | null, b?: Array<any>): Array<any> => {
  if (a == null) {
    return [];
  }

  if (b != null) {
    return a.filter((el: any) => b.indexOf(el) < 0);
  }
  return a.filter((el: any, index: number) => a.indexOf(el) === index);
};
/**
 * build a unique uuid for replacing values in parser class
 */
export const uuid = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, char => {
    const random = (Math.random() * 16) | 0; // Nachkommastellen abschneiden
    const value = char === 'x' ? random : (random % 4) + 8; // Bei x Random 0-15 (0-F), bei y Random 0-3 + 8 = 8-11 (8-b) gemäss RFC 4122
    return value.toString(16); // Hexadezimales Zeichen zurückgeben
  });
};
/**
 *
 * @param userImage image of user if existing
 * @return either userimage or placeholder for nouserImage
 */
export const getUserImageOrPlaceholder = (userImage?: Blob): ReactNode => {
  if (userImage != null) {
    const src = URL.createObjectURL(userImage);
    return <img alt="" src={src} />;
  }

  return Images.noUserImage();
};

export const floatingMenuButton = (
  callback: (data: any) => void,
  deltaX: number,
  deltaY: number,
  data: any,
  exceptions?: Array<string> | Array<number>,
  containerStyle?: {},
): ReactElement => {
  return (
    <div
      style={{
        display: 'inline-block',
        position: 'fixed',
        zIndex: 10000,
        bottom: '90px',
        right: '64px',
      }}
    >
      <TouchableOpacity
        onClick={(event, domRect) =>
          OverlayHandler.showOverlay(Overlays.popupMenuComponent, {
            callback: callback,
            posX: domRect == null ? 0 : domRect.x - deltaX,
            posY: domRect == null ? 0 : Math.max(domRect.y - deltaY, 0),
            width: '300px',
            data: data,
            exceptions: exceptions,
          })
        }
        containerStyle={containerStyle}
      >
        <FloatingCircle style={{ marginBottom: '15px' }}>+</FloatingCircle>
      </TouchableOpacity>
    </div>
  );
};

export const coalesce = (alt: any, ...args: Array<string | undefined>): any => {
  for (const o of args) {
    if (o != null) {
      return o;
    }
  }
  return alt;
};
export const valueFromNullableObject = (
  keys: string[],
  object?: { [key: string]: any },
): any | undefined => {
  if (object == null) {
    return undefined;
  }
  for (const o of keys) {
    if (object[o] != null) {
      return object[o];
    }
  }
  return undefined;
};

/**
 *
 * @param a first Date to compare
 * @param b second Date to compare
 * @return 1 if a >b, -1 if b > a, 0 if a = b
 */
export const compareDate = (a?: Date | string, b?: Date | string): -1 | 0 | 1 => {
  if (a == null) {
    if (b == null) {
      return 0;
    } else {
      return -1;
    }
  }
  if (b == null) {
    if (a == null) {
      return 0;
    } else {
      return 1;
    }
  }
  const parsedA = typeof a === 'string' ? new Date(Date.parse(a)) : new Date(a);
  const parsedB = typeof b === 'string' ? new Date(Date.parse(b)) : new Date(b);
  const dateA: Date = new Date(
    parsedA.getFullYear(),
    parsedA.getMonth(),
    parsedA.getDate(),
    0,
    0,
    0,
  );
  const dateB: Date = new Date(
    parsedB.getFullYear(),
    parsedB.getMonth(),
    parsedB.getDate(),
    0,
    0,
    0,
  );
  if (dateA.getTime() > dateB.getTime()) {
    return 1;
  } else if (dateA.getTime() < dateB.getTime()) {
    return -1;
  } else {
    return 0;
  }
};
/**
 *
 * @param a first dateTime to compare
 * @param b second dateTime to compare
 * @return 1 if a >b, -1 if b > a, 0 if a = b
 */
export const compareDatetime = (a?: Date | string, b?: Date | string): -1 | 0 | 1 => {
  if (a == null) {
    if (b == null) {
      return 0;
    } else {
      return -1;
    }
  }
  if (b == null) {
    if (a == null) {
      return 0;
    } else {
      return 1;
    }
  }

  const dateA = typeof a === 'string' ? new Date(Date.parse(a)) : new Date(a);
  const dateB = typeof b === 'string' ? new Date(Date.parse(b)) : new Date(b);

  if (dateA.getTime() > dateB.getTime()) {
    return 1;
  } else if (dateA.getTime() < dateB.getTime()) {
    return -1;
  } else {
    return 0;
  }
};
/**
 * get User OS
 * @return string containing the OS
 */
export const getOS = (): 'Mac OS' | 'iOS' | 'Windows' | 'Android' | 'Linux' | null => {
  const userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os: 'Mac OS' | 'iOS' | 'Windows' | 'Android' | 'Linux' | null = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
};
/**
 *
 * @param countryCode country to get Currency of
 * @return either undefined, if countrycode doenst exist in list or currencysymbol matching the corresponding country
 */
export const currencySymbol = (countryCode?: string): string | undefined => {
  if (countryCode == null || currencySymbols.has(countryCode.toUpperCase()) !== true) {
    Log.debug(Logs.SYSTEM, `Country code ${countryCode} not found in symbol list`);
    return undefined;
  }

  return currencySymbols.get(countryCode.toUpperCase());
};
/**
 * create downloadaction for specific file
 * @param blob file to download
 * @param filename  name of file to download
 */
export const downloadFile = (blob: Blob | null, filename: string): void => {
  if (blob == null) {
    return;
  }

  const anchor = document.body.appendChild(document.createElement('a'));
  //@ts-ignore
  if (navigator.msSaveOrOpenBlob) {
    //@ts-ignore
    navigator.msSaveOrOpenBlob(blob, filename);
  } else if ('download' in anchor) {
    anchor.download = filename;
    anchor.href = URL.createObjectURL(blob);
    anchor.click();
  }
};

export const revertTransaction = (
  ta_id: number,
  transaction_id: number,
  callbackOnSuccess?: () => void,
): void => {
  const req: Account.PaymentsReversalPostRequest = {
    Reversal: {
      ta_id: ta_id,
      transaction_id: transaction_id,
    },
  };
  OverlayHandler.showOverlay(Overlays.ConfirmationRequestOverlay, {
    confirm: (callback: (success: boolean, close?: boolean) => void) => {
      api
        .asyncRequest<void>(req, apis.TransactionBoApi, 'paymentsReversalPost')
        .then(() => {
          MessageHandler.onSuccess(Reporter['customer.transactions.cancel']);
          OverlayHandler.closeSpecific(Overlays.ConfirmationRequestOverlay);
          if (callbackOnSuccess != null) {
            callbackOnSuccess();
          }
        })
        .catch(() => {
          MessageHandler.onSuccess(Reporter['customer.transactions.cancel']);
        });
    },
    heading: translate('transfer.details.cancelTransactionHeader'),
    message: translate('transfer.details.cancelTransaction'),
  });
};

export const camelToSnake = (s: string) => {
  return s
    .replace(/[\w]([A-Z])/g, function(m) {
      return m[0] + '_' + m[1];
    })
    .toLowerCase();
};

export const snakeToCamel = (s: string) => {
  return s.replace(/(_\w)/g, function(m) {
    return m[1].toUpperCase();
  });
};

export const evaluateErrorMessage = (error: ApiError, errorCode: boolean) => {
  if (error.response != null && error.response.response != null) {
    return errorCode ? error.response.response.error_code : error.response.response.error_text;
  } else if (error.statusText != null && !errorCode) {
    return error.statusText;
  } else if (error instanceof Error && !errorCode) {
    return error.message;
  } else {
    return '';
  }
};

export const firstLettterLowerCase = (value: string): string => {
  return value.charAt(0).toLowerCase() + value.slice(1);
};

export const getTitleOptions = (defaultField?: boolean): Array<IOption> => {
  const options: Array<IOption> = [];
  if (defaultField !== false) {
    options.push({
      key: 'defaultOptionKey_not_set',
      name: translate('customers.title.notSet'),
      value: '',
    });
  }
  options.push({
    key: 'defaultOptionKey mr',
    name: translate('customers.title.mr'),
    value: Ident.Sex.M,
  });
  options.push({
    key: 'defaultOptionKey mrs',
    name: translate('customers.title.mrs'),
    value: Ident.Sex.F,
  });
  options.push({
    key: 'defaultOptionKey undefined',
    name: translate('customers.title.undefined'),
    value: Ident.Sex.X,
  });
  return options;
};

export const getCountryOptions = (defaultField?: boolean): Array<IOption> => {
  const enumValues: Array<IOption> = [];
  if (defaultField !== false) {
    enumValues.push({
      key: 'defaultOptionKey',
      name: '',
      value: '',
    });
  }
  for (const value in CountryCodeAlpha2) {
    const country = getCountry(value);
    enumValues.push({
      key: 'defaultOptionKey' + value,
      name: country != null ? country : value,
      value: value,
    });
  }
  return enumValues.sort((a: IOption, b: IOption): number => {
    if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    }
    return 0;
  });
};

export const getCountryOptionsAlph3 = (defaultField?: boolean): Array<IOption> => {
  const enumValues: Array<IOption> = [];
  if (defaultField !== false) {
    enumValues.push({
      key: 'defaultOptionKey',
      name: '',
      value: '',
    });
  }
  for (const value in CountryCodeAlpha3PlusKosovo) {
    const country = getCountry(value);
    enumValues.push({
      key: 'defaultOptionKey' + value,
      name: country != null ? country : value,
      value: value,
    });
  }
  return enumValues.sort((a: IOption, b: IOption): number => {
    if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    }
    return 0;
  });
};
