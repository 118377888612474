import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { StyledLine, StyledSelect } from '../../basicStyledComponents/customersComponent.css';
import { translate } from '../../../../../common/language/translate';
import { FlexBox } from '../../../../auth/auth.css';
import Title from '../../../../../components/compositcomponents/title';
import { Account } from '../../../../../logic/api';
import { IOption } from '../../../../../components/atomiccompoents/form';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import EditableComponent from '../../../../../components/atomiccompoents/editableComponent/editableComponent';
import {
  AOverlay,
  IPropsOver,
  IStateOver,
} from '../../../../../logic/handler/overlayhandler/globaloverlays/aOverlay';
import { CustomDatePicker } from '../../../../../components/datepicker/tfdatepicker';
import { Icons } from '../../../../../images/icons/icons';

const StyledDivMain = styled(FlexBox)`
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 50px;
  padding-top: 20;
  display: flex;
  flex-direction: column;
  width: 480px;
  height: 240px;
  align-items: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  overflow-y: auto;
`;

interface IProps extends IPropsOver {
  callback: (item: Date) => void;
}
interface IState extends IStateOver {
  item: Date;
  keyForLoadingSpinner: number;
}

export default class addCalendarItemExcludeOverlay extends AOverlay<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      item: new Date(),
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    };
  }

  reviveState() {}

  defineContent() {
    return (
      <StyledDivMain onClick={(event: SyntheticEvent) => event.stopPropagation()}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <div onClick={() => this.props.selfClose()}>{Icons.closingCross()}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Title title={translate('customers.details.merchant.add')} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              height: '52px',
              marginBottom: '16px',
            }}
          >
            <CustomDatePicker
              selectedValue={this.state.item}
              onChange={(date: Date) => {
                this.setState({
                  item: date,
                });
              }}
              displayDate={true}
              resetCallback={() => {}}
              iconCallback={Icons.calendarExpand}
              boxStyle={{
                boxShadow: 'none',
                borderRadius: '0px',
                height: '24px',
                border: 'none',
                margin: 'auto',
                justifyContent: 'flex-start',
              }}
              textStyle={{
                color: '#4a4a4a',
                textAlign: 'left',
                margin: '0px',
                marginRight: '8px',
                fontWeight: 500,
                fontSize: '18px',
              }}
              smallText={true}
            />
          </div>
        </div>
        <ButtonOk
          key={this.state.keyForLoadingSpinner}
          id="btnAddItem"
          onClick={() => {
            this.props.callback(this.state.item);
            this.setState({
              keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            });
          }}
        >
          {translate('button.create')}
        </ButtonOk>
      </StyledDivMain>
    );
  }
}
