// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface EyesonRecordingValidation
 */
export interface EyesonRecordingValidation {
  /**
   * @type {boolean}
   * @memberof EyesonRecordingValidation
   */
  valid: boolean;
  /**
   * @type {boolean}
   * @memberof EyesonRecordingValidation
   */
  open_testkit_during_video: boolean;
  /**
   * @type {boolean}
   * @memberof EyesonRecordingValidation
   */
  person_performs_test: boolean;
  /**
   * @type {boolean}
   * @memberof EyesonRecordingValidation
   */
  testkit_always_in_video: boolean;
  /**
   * @type {boolean}
   * @memberof EyesonRecordingValidation
   */
  video_not_cut: boolean;
  /**
   * @type {boolean}
   * @memberof EyesonRecordingValidation
   */
  result_readable: boolean;
  /**
   * @type {boolean}
   * @memberof EyesonRecordingValidation
   */
  performing_person_and_testkit_match: boolean;
  /**
   * @type {boolean}
   * @memberof EyesonRecordingValidation
   */
  barcode_readable?: boolean;
  /**
   * @type {string}
   * @memberof EyesonRecordingValidation
   */
  test_result?: EyesonRecordingValidationTestResultEnum;
  /**
   * @type {string}
   * @memberof EyesonRecordingValidation
   */
  test_id?: string;
  /**
   * @type {string}
   * @memberof EyesonRecordingValidation
   */
  comment?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum EyesonRecordingValidationTestResultEnum {
  Positive = 'positive',
  Negative = 'negative',
  Void = 'void',
}
