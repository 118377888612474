import React, { ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { translate } from '../../../common/language/translate';
import ActivityIndicator from '../activityIndicator';
import { ITheme } from '../../../common/theme';
import MaterialIconButton from '@material-ui/core/IconButton';

interface IButtonProps {
  loading?: boolean;
  disableSpinner?: boolean;
  onClick?: (event: SyntheticEvent) => void;
  children?: ReactNode;
  theme?: ITheme;
  disabled?: boolean;
  id?: string;
  style?: {};
  tooltip?: string;
}
interface IButtonState {}

const StyledButton = styled(MaterialIconButton)`
  && {
    border: none;
    background-color: ${(props) => props.theme.ButtonCancel.backgroundColor};
    color: ${(props) =>
      props.disabled ? props.theme.ButtonDisabled.color : props.theme.ButtonCancel.color};

    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    :disabled {
      background-color: ${(props) => props.theme.ButtonDisabled.backgroundColor};
    }
    &:hover {
      background-color: ${(props) => props.theme.ButtonCancel.hover};
    }
  }
`;

export default class IconButton extends React.Component<IButtonProps, IButtonState> {
  constructor(props: IButtonProps) {
    super(props);
    this.state = {};
    this.onClick = this.onClick.bind(this);
  }

  onClick(event: SyntheticEvent): void {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();

    if (this.props.disabled !== true && this.props.loading !== true) {
      if (this.props.onClick != null) {
        this.props.onClick(event);
      }
    }
  }

  render() {
    return (
      <StyledButton
        style={this.props.style}
        size="small"
        onClick={(event: SyntheticEvent) => this.onClick(event)}
        disabled={this.props.disabled}
        aria-label={this.props.tooltip}
        title={this.props.tooltip}
      >
        {this.props.loading === true && this.props.disableSpinner !== true ? (
          <ActivityIndicator
            animating={this.props.loading}
            size="small"
            color={this.props.theme?.Button?.color}
          />
        ) : (
          this.props.children == null ? translate('button.cancel') : this.props.children
        )}
      </StyledButton>
    );
  }
}
