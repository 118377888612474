import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';
import { Log, Logs } from '../../log';

export type DefaultLoginType = { username: string; password: string } | undefined;
export type CustomerPageType = 'details' | 'transactions' | 'postingTemplates' | 'reports';

const AUTO_SELECT_CUSTOMER_KEY = 'developer_select_customer';
const AUTO_SELECT_CUSTOMER_PAGE_KEY = 'developer_select_customer_page';
const AUTO_SELECT_BACKOFFICE_KEY = 'developer_select_backoffice';
const DEFAULT_LOGIN_KEY = 'developer_default_login';
const DEVELOPERMODE_KEY = 'developerMode';

class IDeveloperStore extends EventEmitter {
  private static instance: IDeveloperStore | null;

  private developerMode: boolean = false;
  private deviceToken: string | undefined = undefined;
  private draggingUserImage: boolean = false;
  private defaultBackofficeAccount: string | undefined = undefined;
  private defaultCustomer: number | undefined = undefined;
  private defaultCustomerPage: CustomerPageType | undefined = undefined;

  private defaultLogin: DefaultLoginType = undefined;

  constructor() {
    super();
    IDispatcher.register(this.registerActions.bind(this));

    const devMode = window.sessionStorage.getItem(DEVELOPERMODE_KEY);
    this.developerMode = devMode != null && devMode === 'true';

    const defLogin = window.sessionStorage.getItem(DEFAULT_LOGIN_KEY);
    if (defLogin != null) {
      this.defaultLogin = JSON.parse(defLogin);
    }

    const defCustomer = window.sessionStorage.getItem(AUTO_SELECT_CUSTOMER_KEY);
    if (defCustomer != null) {
      this.defaultCustomer = JSON.parse(defCustomer);
    }

    const defBackoffice = window.sessionStorage.getItem(AUTO_SELECT_BACKOFFICE_KEY);
    if (defBackoffice != null) {
      this.defaultBackofficeAccount = defBackoffice;
    }

    const defCustomerPage = window.sessionStorage.getItem(AUTO_SELECT_CUSTOMER_PAGE_KEY);
    if (defCustomerPage != null) {
      switch (defCustomerPage) {
        default:
        case 'details':
          this.defaultCustomerPage = 'details';
          break;
        case 'transactions':
          this.defaultCustomerPage = 'transactions';
          break;
        case 'postingTemplates':
          this.defaultCustomerPage = 'postingTemplates';
          break;
        case 'reports':
          this.defaultCustomerPage = 'reports';
          break;
      }
    }
  }

  static getInstance(): IDeveloperStore {
    if (IDeveloperStore.instance == null) {
      IDeveloperStore.instance = new IDeveloperStore();
    }

    return IDeveloperStore.instance;
  }

  registerActions(action: any) {
    switch (action.actionType) {
      default:
        break;
      case Constants.CLEAR_ALL:
        this.defaultLogin = undefined;
        this.developerMode = false;
        this.deviceToken = undefined;
        this.draggingUserImage = false;
        this.emit(CHANGE_EVENT);
        break;
      case Constants.ENABLE_DEVELOPER:
        this.enableDeveloperMode();
        this.emit(CHANGE_EVENT);
        break;
      case Constants.DISABLE_DEVELOPER:
        this.disableDeveloperMode();
        this.emit(CHANGE_EVENT);
        break;
      case Constants.DEVICE_TOKEN:
        this.setDeviceToken(action.deviceToken);
        this.emit(CHANGE_EVENT);
        break;
      case Constants.DEFAULT_BACKOFFICE_ACCOUNT:
        this.setDefaultBackofficeAccount(action.accountNumber);
        this.emit(CHANGE_EVENT);
        break;
      case Constants.DEFAULT_CUSTOMER:
        this.setDefaultCustomer(action.personId);
        this.emit(CHANGE_EVENT);
        break;
      case Constants.DEVELOPER_LOGIN:
        this.setDefaultLogin(action.defaultLogin);
        this.emit(CHANGE_EVENT);
        break;
      case Constants.DEFAULT_CUSTOMER_PAGE:
        this.setDefaultCustomerPage(action.page);
        this.emit(CHANGE_EVENT);
        break;
    }
    return true;
  }

  disableDeveloperMode(): void {
    this.defaultLogin = undefined;
    this.developerMode = false;
    this.deviceToken = undefined;
    this.draggingUserImage = false;
    this.defaultBackofficeAccount = undefined;
    this.defaultCustomer = undefined;
    window.sessionStorage.removeItem(DEVELOPERMODE_KEY);
    window.sessionStorage.removeItem(DEFAULT_LOGIN_KEY);
    window.sessionStorage.removeItem(AUTO_SELECT_CUSTOMER_KEY);
    window.sessionStorage.removeItem(AUTO_SELECT_BACKOFFICE_KEY);
  }

  enableDeveloperMode(): void {
    this.developerMode = true;
    window.sessionStorage.setItem(DEVELOPERMODE_KEY, 'true');
  }

  getDefaultBackofficeAccount(): string | undefined {
    return this.defaultBackofficeAccount;
  }

  getDefaultCustomer(): number | undefined {
    return this.defaultCustomer;
  }

  getDefaultCustomerPage(): CustomerPageType | undefined {
    return this.defaultCustomerPage;
  }

  getDefaultLogin(): DefaultLoginType {
    return this.defaultLogin;
  }

  getDeviceToken(): string | undefined {
    return this.deviceToken;
  }

  isDeveloperModeEnabled(): boolean {
    return this.developerMode;
  }

  isDraggingUserImage(): boolean {
    return this.draggingUserImage;
  }

  setDefaultBackofficeAccount(accountNumber?: string): void {
    if (accountNumber == null) {
      window.sessionStorage.removeItem(AUTO_SELECT_BACKOFFICE_KEY);
    } else {
      window.sessionStorage.setItem(AUTO_SELECT_BACKOFFICE_KEY, accountNumber);
    }

    this.defaultBackofficeAccount = accountNumber;
  }

  setDefaultCustomer(personId?: number): void {
    if (personId == null) {
      window.sessionStorage.removeItem(AUTO_SELECT_CUSTOMER_KEY);
    } else {
      window.sessionStorage.setItem(AUTO_SELECT_CUSTOMER_KEY, JSON.stringify(personId));
    }

    this.defaultCustomer = personId;
  }

  setDefaultCustomerPage(page?: CustomerPageType): void {
    Log.info(Logs.COMPONENT, 'Default customer page set to', page);
    if (page == null) {
      window.sessionStorage.removeItem(AUTO_SELECT_CUSTOMER_PAGE_KEY);
    } else {
      window.sessionStorage.setItem(AUTO_SELECT_CUSTOMER_PAGE_KEY, page);
    }

    this.defaultCustomerPage = page;
  }

  setDefaultLogin(defaultLogin?: DefaultLoginType): void {
    if (defaultLogin == null) {
      window.sessionStorage.removeItem(DEFAULT_LOGIN_KEY);
    } else {
      window.sessionStorage.setItem(DEFAULT_LOGIN_KEY, JSON.stringify(defaultLogin));
    }
    this.defaultLogin = defaultLogin;
  }

  setDeviceToken(deviceToken?: string): void {
    this.deviceToken = deviceToken;
  }

  setDraggingUserImage(value: boolean): void {
    this.draggingUserImage = value;
  }

  addChangeListener(callback: any) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback: any) {
    this.removeListener(CHANGE_EVENT, callback);
  }
}

export const DeveloperStore = IDeveloperStore.getInstance();
