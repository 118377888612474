import React from 'react';
import styled from 'styled-components';
import {
  IPropsCenter,
  IStateCenter,
} from '../../../logic/handler/overlayhandler/globaloverlays/aCenteredOverlay';
import { Icons } from '../../../images';
import TouchableOpacity from '../../atomiccompoents/buttons/touchableOpacity';
import { FlexBox } from '../../../content/auth/auth.css';
import { AOverlay } from '../../../logic/handler/overlayhandler/globaloverlays/aOverlay';
import { Document, Page, pdfjs } from 'react-pdf';

import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const StyledViewMain = styled(FlexBox)`
  margin: auto;
  position: fixed;
  top: -40px;
  right: 0;
  bottom: 0;
  left: 0;

  box-shadow: 2px 2px 1px -1px rgba(0, 0, 0, 0.2), 1px 1px 1px 1px rgba(0, 0, 0, 0.14),
    1px 1px 3px 1px rgba(0, 0, 0, 0.12);
  background-color: white;
  display: inline-block;
  flex-direction: row;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  padding: 8px;
  box-sizing: border-box;
  width: fit-content;
  height: fit-content;
  border-radius: 6px;
`;

const StyledOpacityIcon: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  padding: '8px',
  marginTop: '8px',
  marginLeft: '4px',
};

const Spacer = styled.div`
  width: 16px;
  padding: 8px;
  margin-right: 8px;
`;

interface IProps extends IPropsCenter {
  file?: any;
  url?: string;
}

interface IState extends IStateCenter {
  numpages: number;
  pageNumber: number;
  pdf?: Blob;
}

export class PDFViewerOverlay extends AOverlay<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      pageNumber: 1,
      numpages: 0,
      pdf: props.file,
    };
    this.close = this.close.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.changePage = this.changePage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    if (props.file == null) {
      this.getDocument(props.url);
    }
  }

  close() {
    this.props.selfClose();
  }
  //@ts-ignore
  onDocumentLoadSuccess({ numPages: nextNumPages }) {
    this.setState({
      numpages: nextNumPages,
      pageNumber: 1,
    });
  }

  async getDocument(url: string) {
    const resp = await fetch(url);
    const pdf = await resp.blob();
    this.setState({
      pdf: pdf,
    });
  }

  reviveState(): void {}

  changePage(offset: number) {
    this.setState({
      pageNumber: this.state.pageNumber + offset,
    });
  }

  previousPage() {
    this.changePage(-1);
  }

  nextPage() {
    this.changePage(1);
  }

  defineContent() {
    return (
      <StyledViewMain
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <Spacer />
        <Document
          file={this.state.pdf}
          options={{ workerSrc: '/pdf.worker.js' }}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          <Page pageNumber={this.state.pageNumber} />
        </Document>
        <div>
          <p>
            Page {this.state.pageNumber || (this.state.numpages ? 1 : '--')} of{' '}
            {this.state.numpages || '--'}
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: 'auto',
              maxWidth: '150px',
            }}
          >
            <div onClick={this.previousPage}>{Icons.arrowInCircleLeft()}</div>
            <div onClick={this.nextPage}>{Icons.arrowInCircleRight()}</div>
          </div>
        </div>
        <TouchableOpacity
          onClick={() => {
            this.close();
          }}
          containerStyle={StyledOpacityIcon}
        >
          {Icons.closingCross()}
        </TouchableOpacity>
      </StyledViewMain>
    );
  }
}
