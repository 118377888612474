import React, { ReactNode } from 'react';
import {
  IStateCenter,
  IPropsCenter,
  ACenteredOverlay,
} from '../../../logic/handler/overlayhandler/globaloverlays/aCenteredOverlay';
import styled from 'styled-components';
import { Icons } from '../../../images';
import DeveloperAutomation from './developerAutomation';
import DeveloperVariables from './developerVariables';
import Title from '../../../components/compositcomponents/title';
import { Box } from '../../../components/atomiccompoents/boxes/box';

interface IProps extends IPropsCenter {
  _onMinimize?: () => void;
  _onClose?: () => void;
}

interface IState extends IStateCenter {
  tabContent?: ReactNode;
}

enum DevTabs {
  Automation,
  Variables,
}

export default class DeveloperOverlay extends ACenteredOverlay<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {
    this.findTab();
  }

  private findTab(tab?: DevTabs): void {
    let content: ReactNode = undefined;

    switch (tab) {
      default:
      case DevTabs.Variables:
        content = <DeveloperVariables />;
        break;
      case DevTabs.Automation:
        content = <DeveloperAutomation />;
        break;
    }

    this.setState({
      tabContent: content,
    });
  }

  private _onMinimize(): void {
    if (this.props._onMinimize != null && typeof this.props._onMinimize === 'function') {
      this.props._onMinimize();
    }
  }

  private _onClose(): void {
    if (this.props._onClose != null && typeof this.props._onClose === 'function') {
      this.props._onClose();
    }
  }

  reviveState() {}

  defineContent() {
    return (
      <Container>
        <Title
          title="Developer menu"
          icon={Icons.developer()}
          minimize
          close
          onMinimize={() => this._onMinimize()}
          onClose={() => this._onClose()}
        />

        <Tabs>
          <Tab onClick={() => this.findTab(DevTabs.Variables)}>Variables</Tab>
          <Tab onClick={() => this.findTab(DevTabs.Automation)}>Automation</Tab>
        </Tabs>

        {this.state.tabContent}
      </Container>
    );
  }
}

const Container = styled(Box)`
  min-width: 800px;
  min-height: 600px;
`;

const Tabs = styled.div`
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;

  & > button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;

    :hover {
      background-color: #ddd;
    }

    :active {
      background-color: #ccc;
    }
  }
`;

const Tab = styled.button``;
