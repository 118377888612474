//@ts-nocheck
import React, { ReactElement } from 'react';
import styled from 'styled-components';

import {
  IStatusState,
  Reporter,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { CustomDatePicker } from '../../../../components/datepicker';
import { translate } from '../../../../common/language/translate';
import { FlexBox } from '../../../auth/auth.css';
import { Box } from '../../../../components/atomiccompoents/boxes/box';

import { format } from '../../../../logic/helper/format';
import Title from '../../../../components/compositcomponents/title';

import { api, apis, ApiError, Account } from '../../../../logic/api/index';
import { Log, Logs } from '../../../../logic/log';
import { Icons } from '../../../../images';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { evaluateErrorMessage } from '../../../../logic/helper/Common';
import { IOption } from '../../../../components/atomiccompoents/form';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import { ContainerText, StyledHr } from '../transfer/transfersComponent.css';
import { StyledSelect } from '../basicStyledComponents/customersComponent.css';
import { RowType } from '../../../../components/compositcomponents/table/tableTypes';
import { Table } from '../../../../components/compositcomponents/table/table';
import { NameField } from '../customers/basicStyledComponents/customerDetails.css';
import { BigInput } from '../../../../components/atomiccompoents/form/inputs.css';

interface IProps {}
interface IFeeAssignment extends Account.FeeAssignment {
  delete?: boolean;
}
interface IState extends IStatusState {
  currentConfig?: Array<IFeeAssignment>;
  amount?: string;
  newEntry?: Account.FeeAssignment;
  packages?: Array<Account.FeeEntry>;
  keyForLoadingSpinner: number;
}

export default class ConfigurationSetup extends React.Component<IProps, IState> {
  private converter = (
    line: IFeeAssignment,
    index: number,
    fields: Array<string>,
  ): RowType => {
    const row: RowType = { cells: [], ref: line };
    if (row.cells == null) {
      return row;
    }
    const keys: Array<string> = Object.keys(line);
    for (let i = 0; i < fields.length; i++) {
      const field: string = fields[i];
      switch (field) {
        case 'delete':
          {
            const val = 'deactivate';
            row.cells.push({
              value: val,
              display: (
                <ContainerText title={val}>
                  <Hover
                    onClick={() => {
                      if (line.memo != null && line.event != null) {
                        if (line.automatic_fee_assignment_id != null) {
                          this.deleteConfig(line.automatic_fee_assignment_id);
                        }
                      }
                    }}
                  >
                    {Icons.del()}
                  </Hover>
                </ContainerText>
              ),
              methods: {},
              copyVal: val,
            });
          }
          break;
        case 'valid_from':
          {
            const val = format.date(line.valid_from);
            row.cells.push({
              value: val != null ? val : '',
              display: <ContainerText>{val}</ContainerText>,
              methods: {},
              copyVal: val,
            });
          }
          break;
        case 'event':
          {
            const val = translate('administration.configuration.events.' + line.event);
            row.cells.push({
              value: val != null ? val : '',
              display: <ContainerText>{val}</ContainerText>,
              methods: {},
              copyVal: val,
            });
          }
          break;
        case 'invalid_from':
          {
            const val = format.date(line.invalid_from);
            row.cells.push({
              value: val != null ? val : '',
              display: <ContainerText>{val != null ? val : 'Infinity'}</ContainerText>,
              methods: {},
              copyVal: val,
            });
          }
          break;
        default: {
          if (Object.prototype.hasOwnProperty.call(line, field)) {
            row.cells.push({
              value: String(Object.values(line)[keys.indexOf(field)]),
              display: (
                <ContainerText title={String(Object.values(line)[keys.indexOf(field)])}>
                  {String(Object.values(line)[keys.indexOf(field)])}
                </ContainerText>
              ),
              methods: {},
              copyVal: String(Object.values(line)[keys.indexOf(field)]),
            });
          } else {
            row.cells.push({ value: '' });
          }
          break;
        }
      }
    }
    return row;
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    };
    this.getCurrentConfig();
    this.getPackages();
    this.setEventOption = this.setEventOption.bind(this);
    this.setFeeOption = this.setFeeOption.bind(this);
    this.setConfig = this.setConfig.bind(this);
  }

  getCurrentConfig() {
    api
      .asyncRequest<Array<Account.FeeAssignment>>(
        {},
        apis.ConfigurationApi,
        'configurationAutomaticFeeAssignmentGet',
      )
      .then((response: Array<Account.FeeAssignment>) => {
        const addData: Array<IFeeAssignment> = response;
        for (const i in addData) {
          addData[i].delete = true;
        }
        this.setState({
          currentConfig: addData,
        });
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
      });
  }

  getPackages() {
    api
      .asyncRequest<Array<Account.FeeEntry>>(
        {},
        apis.ConfigurationApi,
        'configurationFeePackagesGet',
      )
      .then((response: Array<Account.FeeEntry>) => {
        this.setState({
          packages: response,
        });
      })
      .catch((error: ApiError) => {
        Log.error(Logs.API, error);
      });
  }

  deleteConfig(automatic_fee_assignment_id: number) {
    api
      .asyncRequest(
        { automatic_fee_assignment_id: automatic_fee_assignment_id },
        apis.ConfigurationApi,
        'configurationAutomaticFeeAssignmentDelete',
      )
      .then(() => {
        MessageHandler.onSuccess(Reporter['administration.feepackages.delete']);
        this.getCurrentConfig();
      })
      .catch((error: ApiError) => {
        MessageHandler.onError(
          Reporter['administration.feepackages.delete'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
      });
  }

  setConfig() {
    if (this.state.newEntry == null) {
      return;
    }
    const req: Account.ConfigurationAutomaticFeeAssignmentPutRequest = {
      FeeAssignment: {
        memo: this.state.newEntry.memo,
        event: this.state.newEntry.event,
        valid_from: this.state.newEntry.valid_from,
        invalid_from: this.state.newEntry.invalid_from,
        amount:
          this.state.newEntry.event === Account.FeeAssignmentEventEnum.V
            ? parseInt(this.state.amount != null ? this.state.amount : '0')
            : undefined,
      },
    };
    api
      .asyncRequest(req, apis.ConfigurationApi, 'configurationAutomaticFeeAssignmentPut')
      .then(() => {
        MessageHandler.onSuccess(Reporter['administration.feepackages.put']);
        this.setState({
          keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
          newEntry: {},
        });
        this.getCurrentConfig();
      })
      .catch((error: ApiError) => {
        MessageHandler.onError(
          Reporter['administration.feepackages.put'],
          evaluateErrorMessage(error, true),
          evaluateErrorMessage(error, false),
        );
        this.setState({
          keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
        });
      });
  }

  getFeeOptions(): Array<IOption> {
    const out: Array<IOption> = [];
    out.push({
      name: '-',
      value: undefined,
      key: '-',
    });
    if (this.state.packages == null) {
      return out;
    }
    for (const o of this.state.packages) {
      out.push({
        name: o.description != null ? o.description : '',
        value: o.memo,
        key: o.description,
      });
    }
    return out;
  }

  setFeeOption(message: any) {
    let val = this.state.newEntry;
    if (val == null) {
      val = {};
    }
    val.memo = message.memo;
    this.setState({
      newEntry: val,
    });
  }

  getEventOptions(): Array<IOption> {
    const out: Array<IOption> = [];
    out.push({
      name: '-',
      value: undefined,
      key: '-',
    });
    for (const o in Account.FeeAssignmentEventEnum) {
      out.push({
        name: translate('administration.configuration.events.' + o),
        value: o,
        key: o,
      });
    }
    return out;
  }

  generateFields(): Array<ReactElement> {
    const out: Array<ReactElement> = [];

    if (this.state.currentConfig == null) {
      return out;
    }

    for (const o of this.state.currentConfig) {
      out.push(
        <FieldsBox>
          <ConfigTitle>{translate('administration.configuration.memo')}:</ConfigTitle>
          <ConfigField>{o.memo}</ConfigField>
          <ConfigTitle>{translate('administration.configuration.event')}:</ConfigTitle>
          <ConfigField>
            {translate('administration.configuration.events.' + o.event)}
          </ConfigField>
          <ConfigTitle>{translate('administration.configuration.valid_from')}:</ConfigTitle>
          <ConfigField>{format.date(o.valid_from)}</ConfigField>
          <ConfigTitle>{translate('administration.configuration.invalid_from')}:</ConfigTitle>
          <ConfigField>
            {o.invalid_from != null ? format.date(o.invalid_from) : 'inf'}
          </ConfigField>
        </FieldsBox>,
      );
      out.push(<StyledHr />);
    }
    return out;
  }

  setEventOption(message: any) {
    let val = this.state.newEntry;
    if (val == null) {
      val = {};
    }
    val.event = message.event;
    this.setState({
      newEntry: val,
    });
  }

  render() {
    return (
      <FlexBox>
        <Box>
          <div>
            <Title title={translate('administration.configuration.header')} />
          </div>
          <StyledHr />

          <StyledHeaderLine>
            <BigInputWrapper>
              <StyledMarginSelect
                helptext={translate('administration.configuration.memo')}
                id="memo"
                options={this.getFeeOptions()}
                notification={this.setFeeOption}
                current={
                  this.state.newEntry != null && this.state.newEntry.memo != null
                    ? this.state.newEntry.memo
                    : undefined
                }
                wrapperStyle={{ margin: 'initial' }}
              />
            </BigInputWrapper>
            <BigInputWrapper>
              <StyledMarginSelect
                helptext={translate('administration.configuration.event')}
                id="event"
                options={this.getEventOptions()}
                notification={this.setEventOption}
                current={
                  this.state.newEntry != null && this.state.newEntry.event != null
                    ? this.state.newEntry.event
                    : undefined
                }
                wrapperStyle={{ margin: 'initial' }}
              />
            </BigInputWrapper>
            <div
              style={{
                maxWidth: '350px',
                minWidth: '250px',
                marginRight: '32px',
              }}
            >
              <CustomDatePicker
                title={translate('administration.configuration.valid_from')}
                selectedValue={
                  this.state.newEntry != null && this.state.newEntry.valid_from != null
                    ? this.state.newEntry.valid_from
                    : undefined
                }
                onChange={(date: Date) => {
                  let val = this.state.newEntry;
                  if (val == null) {
                    val = {};
                  }
                  date.setHours(0, 0, 0);
                  val.valid_from = date;
                  this.setState({
                    newEntry: val,
                  });
                }}
                iconCallback={Icons.calendarExpand}
                displayDate={
                  this.state.newEntry != null && this.state.newEntry.valid_from != null
                }
                resetCallback={() => {
                  let val = this.state.newEntry;
                  if (val == null) {
                    val = {};
                  }
                  val.valid_from = undefined;
                  this.setState({
                    newEntry: val,
                  });
                }}
                isInput={true}
                zIndex={5000}
                helperText={translate('administration.configuration.valid_from')}
              />
            </div>
            <div
              style={{
                maxWidth: '350px',
                minWidth: '250px',
                marginRight: '32px',
              }}
            >
              <CustomDatePicker
                title={translate('administration.configuration.invalid_from')}
                selectedValue={
                  this.state.newEntry != null && this.state.newEntry.invalid_from != null
                    ? this.state.newEntry.invalid_from
                    : undefined
                }
                onChange={(date: Date) => {
                  let val = this.state.newEntry;
                  if (val == null) {
                    val = {};
                  }
                  date.setHours(23, 59, 59);
                  val.invalid_from = date;
                  this.setState({
                    newEntry: val,
                  });
                }}
                iconCallback={Icons.calendarExpand}
                displayDate={
                  this.state.newEntry != null && this.state.newEntry.invalid_from != null
                }
                resetCallback={() => {
                  let val = this.state.newEntry;
                  if (val == null) {
                    val = {};
                  }
                  val.invalid_from = undefined;
                  this.setState({
                    newEntry: val,
                  });
                }}
                isInput={true}
                zIndex={5000}
                helperText={translate('administration.configuration.invalid_from')}
              />
            </div>
            {this.state.newEntry != null &&
            this.state.newEntry.event === Account.FeeAssignmentEventEnum.V ? (
              <BigInputWrapper>
                <div
                  style={{
                    maxWidth: '250px',
                    minWidth: '100px',
                    marginRight: '32px',
                    marginTop: '-4px',
                  }}
                >
                  <BigInput
                    label={translate('administration.configuration.amount')}
                    placeHolder={translate('administration.configuration.enterAmount')}
                    type="text"
                    id="amount"
                    valueFromState={true}
                    value={this.state.amount}
                    notification={n => {
                      this.setState({
                        amount: n.amount.value,
                      });
                    }}
                  />
                </div>
              </BigInputWrapper>
            ) : null}
          </StyledHeaderLine>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
              marginBottom: '16px',
            }}
          >
            <ButtonOk
              key={this.state.keyForLoadingSpinner}
              disabled={
                this.state.newEntry == null ||
                this.state.newEntry.memo == null ||
                this.state.newEntry.event == null
              }
              id="btnAddConfig"
              onClick={() => {
                this.setConfig();
              }}
            >
              {'Ok'}
            </ButtonOk>
          </div>

          <StyledHr />
          <Wrapper>
            <Table<IFeeAssignment>
              header={[
                translate('administration.configuration.memo'),
                translate('administration.configuration.event'),
                translate('administration.configuration.valid_from'),
                translate('administration.configuration.invalid_from'),
                translate('administration.configuration.amount'),
                translate('administration.configuration.delete'),
              ]}
              sourceData={this.state.currentConfig != null ? this.state.currentConfig : []}
              fields={['memo', 'event', 'valid_from', 'invalid_from', 'amount', 'delete']}
              stickyHeader={true}
              highlightTextOnSearch={true}
              dataConverter={this.converter}
            />
          </Wrapper>
        </Box>
      </FlexBox>
    );
  }
}

const ConfigField = styled(NameField)`
  margin-right: 16px;
  max-width: 150px;
`;

const ConfigTitle = styled(ConfigField)`
  font-weight: 500;
`;

const FieldsBox = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 90%;
  margin-bottom: 32px;
  margin-top: 32px;
  border-bottom: ${props => props.theme.Button.backgroundColor};
`;
const BigInputWrapper = styled.div`
  max-width: 350px;
  margin-right: 16px;
  margin-left: 16px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Hover = styled.div`
  :hover {
    cursor: pointer;
  }
  margin-top: 8px;
  margin-left: 32px;
`;
const PagingWrapper = styled.div`
  justify-self: flex-end;
  margin: auto;
  margin-right: 12px;
  max-height: 32px;
`;
const StyledMarginSelect = styled(StyledSelect)`
  max-width: 450px;

  select {
    min-width: 200px;
    max-width: 350px;
    margin-bottom: 8px;
    margin-top: 9px;
    margin-right: 32px;
  }
`;
const StyledHeaderLine = styled.div`
  display: flex;
  flex-direction: row
  width: 90%;
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;
`;
