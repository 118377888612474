//@ts-nocheck
import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { IOverlayMessage } from './overlay';
import {
  ACenteredOverlay,
  IPropsCenter,
  IStateCenter,
} from '../../../logic/handler/overlayhandler/globaloverlays/aCenteredOverlay';
import { translate } from '../../../common/language/translate';
import ButtonCancel from '../../atomiccompoents/buttons/buttonCancel';
import ButtonOk from '../../atomiccompoents/buttons/buttonOk';
import { Log, Logs } from '../../../logic/log';
import { FlexBox } from '../../../content/auth/auth.css';
import { MessageHandler } from '../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../logic/handler/messagehandler/messageHandlerConfig';
import { IOption } from '../../atomiccompoents/form';
import { StyledSelect } from '../../../content/dashboard/content/basicStyledComponents/customersComponent.css';
import { Ident } from '../../../logic/api';

const StyledViewMain = styled(FlexBox)`
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  width: 500px;
  height: 320px;
  background-color: #ffffff;
  border-radius: 4px;

  padding: 32px 27px;
  box-sizing: border-box;

  a#cancel {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 10px;

    :hover {
      text-decoration: underline;
    }
  }
`;

const BigParagraph = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  text-align: left;
  margin-bottom: 18px;
`;

const StyledTextParagraph = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.499689);
  text-align: left;
  margin-bottom: 25px;
  width: 100%;
  height: 50%;
`;
const StyledButtonView = styled(FlexBox)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export interface IConfirmationOverlayWithReasonProps extends IPropsCenter {
  notification?: () => void;
  notify?: (n: IOverlayMessage) => void;
  confirm: (
    callback: (success: boolean, close?: boolean) => void,
    reason: Ident.PersonPartnerTransferDeleteReasonEnum,
  ) => void;
  heading: string;
  message: string;
  buttonText?: string;
}
interface IState extends IStateCenter {
  keyForLoadingSpinner?: number;
  selectedReason: Ident.PersonPartnerTransferDeleteReasonEnum;
}

export default class ConfirmationRequestOverlayWithReason extends ACenteredOverlay<
  IConfirmationOverlayWithReasonProps,
  IState
> {
  constructor(props: IConfirmationOverlayWithReasonProps) {
    super(props);

    this.state = {
      width: props.width,
      height: props.height,
      keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
      selectedReason: Ident.PersonPartnerTransferDeleteReasonEnum.ClientRequest,
    };

    this.performConfirm = this.performConfirm.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleReasonSelect = this.handleReasonSelect.bind(this);
  }

  private performConfirm(event: SyntheticEvent) {
    event.stopPropagation();
    event.preventDefault();

    Log.debug(Logs.COMPONENT, 'performConfirm called');
    this.props.confirm((success, close) => {
      this.setState({
        keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
      });
      if (success && this.props.notify != null) {
        this.props.notify({
          changePopUpComponent: null,
          isShowing: false,
          messageForParentObject: false,
          newPopUpComponent: null,
        });
      }
      if (!success) {
        MessageHandler.onError(Reporter['confirmation.nosuccess.error']);
      }

      if (
        close === true &&
        this.props.selfClose != null &&
        typeof this.props.selfClose === 'function'
      ) {
        this.props.selfClose();
      }
    }, this.state.selectedReason);
  }

  private cancel() {
    if (this.props.notify != null) {
      this.props.notify({
        changePopUpComponent: null,
        isShowing: false,
        messageForParentObject: false,
        newPopUpComponent: null,
      });
    }
    if (this.props.notification != null) {
      this.props.notification();
    }
    this.props.selfClose();
  }

  getReasonOptions(): Array<IOption> {
    const options = [];
    for (const o in Ident.PersonPartnerTransferDeleteReasonEnum) {
      options.push({
        key: 'defaultOptionKey' + o,
        name: translate('customers.close.' + o),
        value: o,
      });
    }
    return options;
  }

  handleReasonSelect(message: any) {
    this.setState({
      selectedReason: message.reason,
    });
  }

  reviveState(): void {}

  defineContent() {
    return (
      <StyledViewMain>
        <BigParagraph>{this.props.heading} </BigParagraph>
        <StyledTextParagraph>{this.props.message}</StyledTextParagraph>
        <StyledSelect
          helptext={translate('customers.close.reason')}
          id="reason"
          options={this.getReasonOptions()}
          notification={this.handleReasonSelect}
          current={this.state.selectedReason}
          wrapperStyle={{ margin: 'initial' }}
        />
        <StyledButtonView>
          <ButtonCancel id="btnConfirmationRequestOverlayCancel" onClick={this.cancel}>
            {translate('button.cancel')}
          </ButtonCancel>
          <ButtonOk
            id="btnConfirmationRequestOverlayOk"
            key={this.state.keyForLoadingSpinner}
            disabled={false}
            onClick={this.performConfirm}
          >
            {this.props.buttonText == null ? translate('button.ok') : this.props.buttonText}
          </ButtonOk>
        </StyledButtonView>
      </StyledViewMain>
    );
  }
}
